export const CREATE_COURSE = 'CREATE_COURSE'
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAILED = 'CREATE_COURSE_FAILED'

export const CREATE_SECTION = 'CREATE_SECTION'
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS'
export const CREATE_SECTION_FAILED = 'CREATE_SECTION_FAILED'

export const CREATE_LESSON = 'CREATE_LESSON'
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS'
export const CREATE_LESSON_FAILED = 'CREATE_LESSON_FAILED'

export const CREATE_QUIZ = 'CREATE_QUIZ'
export const CREATE_QUIZ_SUCCESS = 'CREATE_QUIZ_SUCCESS'
export const CREATE_QUIZ_FAILED = 'CREATE_QUIZ_FAILED'

export const LOAD_TAGS = 'LOAD_TAGS'
export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS'
export const LOAD_TAGS_FAILED = 'LOAD_TAGS_FAILED'

export const LOAD_SLIDE_TAGS = 'LOAD_SLIDE_TAGS'
export const LOAD_SLIDE_TAGS_SUCCESS = 'LOAD_SLIDE_TAGS_SUCCESS'
export const LOAD_SLIDE_TAGS_FAILED = 'LOAD_SLIDE_TAGS_FAILED'

export const LOAD_COURSE_TYPE = 'LOAD_COURSE_TYPE'
export const LOAD_COURSE_TYPE_SUCCESS = 'LOAD_COURSE_TYPE_SUCCESS'
export const LOAD_COURSE_TYPE_FAILED = 'LOAD_COURSE_TYPE_FAILED'

export const SET_SELECTED_SECTION = 'SET_SELECTED_SECTION'

export const CREATE_ZOOM_LINK = 'CREATE_ZOOM_LINK'
export const CREATE_ZOOM_LINK_SUCCESS = 'CREATE_ZOOM_LINK_SUCCESS'
export const CREATE_ZOOM_LINK_FAILED = 'CREATE_ZOOM_LINK_FAILED'

export const SAVE_ZOOM_LINK = 'SAVE_ZOOM_LINK'
export const SAVE_ZOOM_LINK_SUCCESS = 'SAVE_ZOOM_LINK_SUCCESS'
export const SAVE_ZOOM_LINK_FAILED = 'SAVE_ZOOM_LINK_FAILED'

export const SET_GLOBAL_COURSE = 'SET_GLOBAL_COURSE'
export const SET_GLOBAL_COURSE_SUCCESS = 'SET_GLOBAL_COURSE_SUCCESS'
export const SET_GLOBAL_COURSE_FAILED = 'SET_GLOBAL_COURSE_FAILED'

export const LOAD_LIST_USER_ANSWER = 'LOAD_LIST_USER_ANSWER'
export const LOAD_LIST_USER_ANSWER_SUCCESS = 'LOAD_LIST_USER_ANSWER_SUCCESS'
export const LOAD_LIST_USER_ANSWER_FAILED = 'LOAD_LIST_USER_ANSWER_FAILED'

export const SET_ANSWER_COMPETENT = 'SET_ANSWER_COMPETENT'
export const SET_ANSWER_COMPETENT_SUCCESS = 'SET_ANSWER_COMPETENT_SUCCESS'
export const SET_ANSWER_COMPETENT_FAILED = 'SET_ANSWER_COMPETENT_FAILED'

export const MARK_ANSWER_COMPETENT = 'MARK_ANSWER_COMPETENT'
export const MARK_ANSWER_COMPETENT_SUCCESS = 'MARK_ANSWER_COMPETENT_SUCCESS'
export const MARK_ANSWER_COMPETENT_FAILED = 'MARK_ANSWER_COMPETENT_FAILED'

export const CREATE_ACCOUNT_STRIPE = 'CREATE_ACCOUNT_STRIPE'
export const CREATE_ACCOUNT_STRIPE_SUCCESS = 'CREATE_ACCOUNT_STRIPE_SUCCESS'
export const CREATE_ACCOUNT_STRIPE_FAILED = 'CREATE_ACCOUNT_STRIPE_FAILED'

export const GET_ACCOUNT_STRIPE = 'GET_ACCOUNT_STRIPE'
export const GET_ACCOUNT_STRIPE_SUCCESS = 'GET_ACCOUNT_STRIPE_SUCCESS'
export const GET_ACCOUNT_STRIPE_FAILED = 'GET_ACCOUNT_STRIPE_FAILED'

export const ADD_SCHEDULE = 'ADD_SCHEDULE'
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS'
export const ADD_SCHEDULE_FAILED = 'ADD_SCHEDULE_FAILED'

export const GET_SCHEDULE = 'GET_SCHEDULE'
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_FAILED = 'GET_SCHEDULE_FAILED'

export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS'
export const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED'

export const DELETE_COURSE = 'DELETE_COURSE'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAILED = 'DELETE_COURSE_FAILED'

export const DELETE_LESSON = 'DELETE_LESSON'
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS'
export const DELETE_LESSON_FAILED = 'DELETE_LESSON_FAILED'

export const LOAD_LIST_LEARNING_OUTCOME = 'LOAD_LIST_LEARNING_OUTCOME'
export const LOAD_LIST_LEARNING_OUTCOME_SUCCESS =
  'LOAD_LIST_LEARNING_OUTCOME_SUCCESS'
export const LOAD_LIST_LEARNING_OUTCOME_FAILED =
  'LOAD_LIST_LEARNING_OUTCOME_FAILED'

export const CREATE_LEARNING_OUTCOME = 'CREATE_LEARNING_OUTCOME'
export const CREATE_LEARNING_OUTCOME_SUCCESS = 'CREATE_LEARNING_OUTCOME_SUCCESS'
export const CREATE_LEARNING_OUTCOME_FAILED =
  'LOAD_LIST_LEARNING_OUTCOME_FAILED'

export const DELETE_LEARNING_OUTCOME = 'DELETE_LEARNING_OUTCOME'
export const DELETE_LEARNING_OUTCOME_SUCCESS = 'DELETE_LEARNING_OUTCOME_SUCCESS'
export const DELETE_LEARNING_OUTCOME_FAILED = 'DELETE_LEARNING_OUTCOME_FAILED'

export const LOAD_ASSESSMENT = 'LOAD_ASSESSMENT'
export const LOAD_ASSESSMENT_SUCCESS = 'LOAD_ASSESSMENT_SUCCESS'
export const LOAD_ASSESSMENT_FAILED = 'LOAD_LIST_LEARNING_OUTCOME_FAILED'

export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT'
export const CREATE_ASSESSMENT_SUCCESS = 'CREATE_ASSESSMENT_SUCCESS'
export const CREATE_ASSESSMENT_FAILED = 'CREATE_ASSESSMENT_FAILED'

export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT'
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS'
export const DELETE_ASSESSMENT_FAILED = 'DELETE_ASSESSMENT_FAILED'

export const LOAD_ATTACHMENT = 'LOAD_ATTACHMENT'
export const LOAD_ATTACHMENT_SUCCESS = 'LOAD_ATTACHMENT_SUCCESS'
export const LOAD_ATTACHMENT_FAILED = 'LOAD_ATTACHMENT_FAILED'

export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT'
export const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS'
export const CREATE_ATTACHMENT_FAILED = 'CREATE_ATTACHMENT_FAILED'

export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS'
export const DELETE_ATTACHMENT_FAILED = 'DELETE_ATTACHMENT_FAILED'

export const LOAD_ZOOMS = 'LOAD_ZOOMS'
export const LOAD_ZOOMS_SUCCESS = 'LOAD_ZOOMS_SUCCESS'
export const LOAD_ZOOMS_FAILED = 'LOAD_ZOOMS_FAILED'

export const DELETE_SCHEDULE_ITEM = 'DELETE_SCHEDULE_ITEM'
export const DELETE_SCHEDULE_ITEM_SUCCESS = 'DELETE_SCHEDULE_ITEM_SUCCESS'
export const DELETE_SCHEDULE_ITEM_FAILED = 'DELETE_SCHEDULE_ITEM_FAILED'

export const LOAD_LIST_USER = 'LOAD_LIST_USER'
export const LOAD_LIST_USER_SUCCESS = 'LOAD_LIST_USER_SUCCESS'
export const LOAD_LIST_USER_FAILED = 'LOAD_LIST_USER_FAILED'

export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'
export const GET_USER_DATA_FAILED = 'GET_USER_DATA_FAILED'

export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED'

export const GET_LIST_SCHEDULE_BY_EMAIL = 'GET_LIST_SCHEDULE_BY_EMAIL'
export const GET_LIST_SCHEDULE_BY_EMAIL_SUCCESS =
  'GET_LIST_SCHEDULE_BY_EMAIL_SUCCESS'
export const GET_LIST_SCHEDULE_BY_EMAIL_FAILED =
  'GET_LIST_SCHEDULE_BY_EMAIL_FAILED'

export const ADD_SCHEDULE_TO_USER_EMAIL = 'ADD_SCHEDULE_TO_USER_EMAIL'
export const ADD_SCHEDULE_TO_USER_EMAIL_SUCCESS =
  'ADD_SCHEDULE_TO_USER_EMAIL_SUCCESS'
export const ADD_SCHEDULE_TO_USER_EMAIL_FAILED =
  'ADD_SCHEDULE_TO_USER_EMAIL_FAILED'

export const DELETE_SCHEDULE_OF_USER = 'DELETE_SCHEDULE_OF_USER'
export const DELETE_SCHEDULE_OF_USER_SUCCESS = 'DELETE_SCHEDULE_OF_USER_SUCCESS'
export const DELETE_SCHEDULE_OF_USER_FAILED = 'DELETE_SCHEDULE_OF_USER_FAILED'

export const SET_REMARKS_ANSWER = 'SET_REMARKS_ANSWER'
export const SET_REMARKS_ANSWER_SUCCESS = 'SET_REMARKS_ANSWER_SUCCESS'
export const SET_REMARKS_ANSWER_FAILED = 'SET_REMARKS_ANSWER_FAILED'

export const GET_USER_BY_COURSE_AND_SCHEDULE = 'GET_USER_BY_COURSE_AND_SCHEDULE'
export const GET_USER_BY_COURSE_AND_SCHEDULE_SUCCESS =
  'GET_USER_BY_COURSE_AND_SCHEDULE_SUCCESS'
export const GET_USER_BY_COURSE_AND_SCHEDULE_FAILED =
  'GET_USER_BY_COURSE_AND_SCHEDULE_FAILED'

export const GET_LEARNING_OUTCOME_BY_USER = 'GET_LEARNING_OUTCOME_BY_USER'
export const GET_LEARNING_OUTCOME_BY_USER_SUCCESS =
  'GET_LEARNING_OUTCOME_BY_USER_SUCCESS'
export const GET_LEARNING_OUTCOME_BY_USER_FAILED =
  'GET_LEARNING_OUTCOME_BY_USER_FAILED'

export const SET_COMPETENT_LEARNING_OUTCOME = 'SET_COMPETENT_LEARNING_OUTCOME'
export const SET_COMPETENT_LEARNING_OUTCOME_SUCCESS =
  'SET_COMPETENT_LEARNING_OUTCOME_SUCCESS'
export const SET_COMPETENT_LEARNING_OUTCOME_FAILED =
  'SET_COMPETENT_LEARNING_OUTCOME_FAILED'

export const GET_LIST_SET_OUTCOME_STUDENTS = 'GET_LIST_SET_OUTCOME_STUDENTS'
export const GET_LIST_SET_OUTCOME_STUDENTS_SUCCESS =
  'GET_LIST_SET_OUTCOME_STUDENTS_SUCCESS'
export const GET_LIST_SET_OUTCOME_STUDENTS_FAILED =
  'GET_LIST_SET_OUTCOME_STUDENTS_FAILED'

export const ADD_OUTCOME_COURSE = 'ADD_OUTCOME_COURSE'
export const ADD_OUTCOME_COURSE_SUCCESS = 'ADD_OUTCOME_COURSE_SUCCESS'
export const ADD_OUTCOME_COURSE_FAILED = 'ADD_OUTCOME_COURSE_FAILED'

export const GET_LIST_OUTCOME_STUDENT = 'GET_LIST_OUTCOME_STUDENT'
export const GET_LIST_OUTCOME_STUDENT_SUCCESS =
  'GET_LIST_OUTCOME_STUDENT_SUCCESS'
export const GET_LIST_OUTCOME_STUDENT_FAILED = 'GET_LIST_OUTCOME_STUDENT_FAILED'

export const SET_LIST_OUTCOME_CUSTOM = 'SET_LIST_OUTCOME_CUSTOM'
export const SET_LIST_OUTCOME_CUSTOM_SUCCESS = 'SET_LIST_OUTCOME_CUSTOM_SUCCESS'
export const SET_LIST_OUTCOME_CUSTOM_FAILED = 'SET_LIST_OUTCOME_CUSTOM_FAILED'

export const GET_LIST_SET_OUTCOME_CUSTOM = 'GET_LIST_SET_OUTCOME_CUSTOM'
export const GET_LIST_SET_OUTCOME_CUSTOM_SUCCESS =
  'GET_LIST_SET_OUTCOME_CUSTOM_SUCCESS'
export const GET_LIST_SET_OUTCOME_CUSTOM_FAILED =
  'GET_LIST_SET_OUTCOME_CUSTOM_FAILED'

export const LOAD_PAYMENT_STATUS = 'LOAD_PAYMENT_STATUS'
export const LOAD_PAYMENT_STATUS_SUCCESS = 'LOAD_PAYMENT_STATUS_SUCCESS'
export const LOAD_PAYMENT_STATUS_FAILED = 'LOAD_PAYMENT_STATUS_FAILED'

export const SET_QUESTIONS = 'SET_QUESTIONS'

export const DELETE_CUSTOM_OUTCOME = 'DELETE_CUSTOM_OUTCOME'
export const DELETE_CUSTOM_OUTCOME_SUCCESS = 'DELETE_CUSTOM_OUTCOME_SUCCESS'
export const DELETE_CUSTOM_OUTCOME_FAILED = 'DELETE_CUSTOM_OUTCOME_FAILED'
