import React from 'react'
import styled from 'styled-components'
import { Layout } from 'src/customs/ClassCompare/styles'
import { Body } from '../../../../customs/ClassCompare/styles'
import images from 'Themes/Images'
import { useMediaQuery } from 'react-responsive'

const AboutWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  .logo_section {
    &__wrap {
      display: flex;
      @media (max-width: 767px) {
        display: block;
        padding-left: 0;
        padding-bottom: 20px;
      }
    }
  }
  h1 {
    color: #f8f8f4;
    font-weight: normal;
    font-size: 24px;
    font-style: normal;
    letter-spacing: 0.02em;
    line-height: 1;
    font-weight: 500;
  }
  h2 {
    color: #f8f8f4;
    font-weight: bold;
    font-size: 76px;
    font-style: normal;
    text-align: left;
    letter-spacing: 0em;
    line-height: 0.9;
    min-height: 0vw;
  }
  h3 {
    color: #f8f8f4;
    font-weight: 500;
    font-size: 48px;
    font-style: normal;
    text-align: left;
    letter-spacing: 0em;
    line-height: 1;
  }
  h4 {
    font-size: 20px;
    color: #000;
    font-weight: bold;
  }

  p {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  span {
    color: #f8f8f4;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    letter-spacing: 0em;
    line-height: 1.9;
  }
  .about {
    &__top_wrapper {
      background-color: rgba(62, 61, 53);
      padding: 40px 60px 80px 60px;
      @media (max-width: 768px) {
        padding: 70px 20px;
      }
    }
    &__head {
      display: flex;
      margin-bottom: 50px;
      @media (max-width: 768px) {
        display: block;
      }
      span {
        color: #f8f8f4;
        font-weight: normal;
        font-size: 16px;
        font-style: normal;
        padding-left: 30px;
        @media (max-width: 768px) {
          padding-left: 0px;
        }
      }
    }
    &__main {
      display: flex;
      @media (max-width: 768px) {
        display: block;
      }
      &__top {
        display: flex;
        justify-content: space-between;
        & > div:first-child {
          max-width: 640px;
          margin-right: 220px;
        }

        @media (max-width: 1440px) {
          & > div:first-child {
            max-width: 550px;
            margin-right: 180px;
          }
        }

        @media (max-width: 1024px) {
          & > div:first-child {
            max-width: 400px;
            margin-right: 120px;
          }
        }

        @media (max-width: 768px) {
          display: block;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          & > div:first-child {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
      &__bottom {
        display: flex;
        & > div:first-child {
          max-width: 640px;
          margin-right: 220px;
        }
        @media (max-width: 1440px) {
          & > div:first-child {
            max-width: 550px;
            margin-right: 180px;
          }
        }
        @media (max-width: 1024px) {
          & > div:first-child {
            max-width: 400px;
            margin-right: 120px;
          }
        }
        @media (max-width: 768px) {
          display: block;
          & > div:first-child {
            max-width: 100%;
            margin-right: 0;
          }
        }
      }
      &__section {
        margin-bottom: 40px;
        @media (max-width: 768px) {
          max-width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    &__body_wrapper {
      padding: 60px 80px;
      background: rgba(248, 248, 244);
      @media (max-width: 768px) {
        padding: 20px;
      }
    }

    &__explain {
      padding-bottom: 60px;
      border-bottom: 1px solid rgb(24, 24, 24);
      margin-bottom: 60px;
    }
    &__explain-2 {
      border-top: 1px solid rgb(24, 24, 24);
      display: flex;
      @media (max-width: 768px) {
        display: block;
      }
      & span {
        color: #181818;
      }

      & > div {
        width: 33.33%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &__left {
        padding: 40px 60px 40px 40px;
        border-right: 1px solid rgb(24, 24, 24);
        border-image: linear-gradient(
            transparent 0%,
            rgb(24, 24, 24) 0%,
            rgb(24, 24, 24) 60%,
            transparent 60%
          )
          0 1 0 0 / 1px / 0 stretch;

        @media (max-width: 768px) {
          border: unset;
          padding: 10px 0;
        }
      }
      &__top {
        padding: 40px 60px 40px 40px;
        border-right: 1px solid rgb(24, 24, 24);
        border-image: linear-gradient(
            transparent 0%,
            rgb(24, 24, 24) 0% 60%,
            transparent 60%
          )
          0 1 0 0 / 1px;
        @media (max-width: 768px) {
          border: unset;
          padding: 10px 0;
        }
      }

      &__center {
        padding: 40px 60px 40px 40px;
        border-right: 1px solid rgb(24, 24, 24);
        @media (max-width: 768px) {
          border: unset;
          padding: 10px 0;
        }
      }
      &__right {
        padding: 40px 60px 40px 40px;
        @media (max-width: 768px) {
          padding: 10px 0;
        }
      }
    }
    &__explain div,
    &__explain span {
      color: rgba(62, 61, 53);
      font-size: 25px;
    }

    &__introduce {
      display: flex;
      height: 460px;
      background-color: #fff;
      justify-content: space-between;
      padding: 0px 80px 80px 80px;
      @media (max-width: 768px) {
        display: block;
        height: unset;
        padding: 20px 0;
      }
      &__section {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        @media (max-width: 768px) {
          margin-bottom: 40px;
        }
      }
    }
  }
`

const AboutPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isMediumDesktop = useMediaQuery({ maxWidth: 1024 })
  return (
    <Layout paddingBottom={0}>
      <AboutWrapper>
        <div className="about__top_wrapper">
          <div className="about__main__top">
            <div>
              <h3>About Us</h3>
              <div style={{ display: 'flex' }}>
                <span>
                  Singapore Skills Academies represent the best joint effort
                  among Singapore’s SkillsSG Ventures group of elite training
                  providers in the digitalization and internationalization of
                  skills development programs. Each Academy comprises
                  high-quality, industry-relevant courses to help you skill,
                  reskill and upskill in your career. It empowers you to stay
                  relevant in your job, progress up the organizational
                  hierarchy, or switch to a new field altogether when interests
                  change and opportunities arise.
                  <br />
                  We have launched several new Academies in October 2021 and
                  will continue to widen and deepen the spectrum of choices over
                  time.
                </span>
              </div>
            </div>
            <div>
              <div className="about__main__section">
                <h3>Vision</h3>
                <p>
                  To be a global skills accelerator for upskilling and
                  empowerment.
                </p>
                <span>
                  We empower a global community of learners in your personal
                  upgrading and career progression, focusing on and accelerating
                  online skills development programs
                </span>
              </div>
              <div className="about__main__section">
                <h3>Mission</h3>
                <p>
                  To deliver high-quality and industry-relevant programs that
                  reflect Singapore's best-in-class and innovative learning.
                </p>
                <span>
                  We provide high-quality, best-in-class and innovative
                  learning, hallmarks of the Singapore brand and renown. All
                  programs are industry-relevant: practical for learners,
                  valuable to employers, contributing to the economy.
                </span>
              </div>
            </div>
          </div>
          <div className="about__main__bottom">
            <div>
              <h3 className="logo_section">Logo</h3>
              <div className="logo_section__wrap">
                <div>
                  <span>
                    The shape of the letter "S" with red top associates with
                    Singapore brand and quality. Clean lines form a network of
                    triangles which represent elite training providers.
                  </span>
                </div>
                <div>
                  <img
                    src={images.singapore_skills_logo}
                    width="140"
                    className="shape"
                  />
                </div>
              </div>
            </div>
            <div className="about__main__section">
              <h3>Motto</h3>
              <p>We empower your future.</p>
              <span>
                We connect with you through skills development programs to
                empower and enable you with job abilities, opportunities and
                outcomes, and to future-proof your career.
              </span>
            </div>
          </div>
        </div>
        <div className="about__body_wrapper">
          {/* <div className="about__explain">
            <div>
              <span style={{ textDecoration: 'underline' }}>Brought</span> to
              you by Singapore Skills Academies.
            </div>
            <div>
              <span style={{ textDecoration: 'underline' }}>Driven</span> by
              SkillsSG Ventures group of training providers.
            </div>
            <div>
              <span style={{ textDecoration: 'underline' }}>Powered</span>{' '}
              online by strategic partner Hypeschools.
            </div>
          </div> */}

          <div className="about__explain-2">
            <div className="about__explain-2__left">
              <h4>For Singaporeans</h4>
              <span>
                This is an exciting platform to continue your lifelong learning
                journey for career advancement and personal enrichment. You will
                also enjoy Singapore Government’s subsidies and SkillsFuture
                Credit deductions in eligible courses.
              </span>
            </div>
            <div className="about__explain-2__center">
              <h4>For Global Learners</h4>
              <span>
                In any country of residence, you will benefit from Singapore's
                best-in-class and innovative learning programs developed and
                refined over the past decades. Right in the comfort of your
                home, school or workplace. All you need is a laptop or mobile
                device with stable internet connection. And a willing heart and
                mind to learn with our top trainers. Across geographical
                boundaries and cultural divide. Joining the growing community of
                fellow-learners, specialist trainers, potential employers, human
                capital consultancies and manpower recruiters.
              </span>
            </div>
            <div className="about__explain-2__right">
              <h4>For Business Partners</h4>
              <span>
                We welcome universities, corporations, human capital
                consultancies and manpower recruiters as our partners. To
                collaborate in empowering students, alumni, staff and applicants
                with job-ready and industry-relevant skills. For that placement
                or progression they are pursuing. For the livelihood of
                individuals, organisations and society. For the advancement of
                the local economy.
              </span>
            </div>
          </div>
        </div>

        <div className="about__introduce">
          <div className="about__introduce__section">
            <img src={images.singapore} width="320" />
            <h4>We empower your future.</h4>
            <a
              href="https://singaporeskillsacademies.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://singaporeskillsacademies.com
            </a>
          </div>
          <div className="about__introduce__section">
            <img src={images.skillsg} width="220" />
            <h4 style={{ textAlign: 'center' }}>
              Upgrading skills. Enabling progress.
            </h4>
            <a
              href="https://skillssg.global"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://skillssg.global
            </a>
          </div>
          <div className="about__introduce__section">
            <img
              src={images.hypeschools}
              width={isMobile ? 320 : isMediumDesktop ? 320 : 420}
            />
            <h4>Supercharge your Enrolments.</h4>
            <a
              href="https://hypeschools.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://hypeschools.com
            </a>
          </div>
        </div>
      </AboutWrapper>
    </Layout>
  )
}

export default AboutPage
