import React from 'react'
import { get } from 'lodash'
import dayjs from 'dayjs'
import images from 'Themes/Images'
import { isEmpty } from 'lodash'
import { deleteScheduleItem } from '../../../../actions'
import { Select } from 'antd'

const ScheduleItem = ({
  item,
  schedules,
  listSchedules,
  setListSchedules,
  index,
  dispatch,
  newSchedules,
  formik,
}) => {
  const handleSelectSchedule = (data) => {
    if (!Array.isArray(listSchedules)) {
      return
    }
    const newData = [...listSchedules]
    if (newData[index]) {
      newData[index] = { ...newData[index], schedule_id: data }
    } else {
      newData[index] = { schedule_id: data }
    }
    setListSchedules(newData)
    formik.submitForm()
  }

  const handleRemoveSchedule = () => {
    if (!isEmpty(item)) {
      dispatch(deleteScheduleItem(item.id))
    } else {
      const newData = listSchedules.filter(
        (data) => get(data, 'id') !== get(item, 'id')
      )

      setListSchedules(newData)
    }
  }

  const handleMapValue = () => {
    const schedule = schedules.find((i) => i.id == item.schedule_id)
    if (schedule) {
      const id = get(schedule, 'id', '')
      const startDate = get(schedule, 'start_date', '')
      const endDate = get(schedule, 'end_date', '')
      const classCode = get(schedule, 'class_code', '')
      const code = `${
        classCode ? ` / Class Code: ${get(schedule, 'class_code', '')}` : ''
      }`
      const label = schedule.isAlwaysAvailable
        ? `Always Available ${code}`
        : `${dayjs(startDate).format('DD MMM')} - ${dayjs(endDate).format(
            'DD MMM YYYY'
          )} ${code}`
      return {
        value: id,
        label,
      }
    } else {
      return null
    }
  }

  const mapScheduleToDropDownExist = (
    schedules,
    classCode = false,
    active = false,
    classCodeV2 = false
  ) => {
    if (!schedules) {
      return []
    }

    if (Array.isArray(schedules) && schedules.length === 0) {
      return []
    }

    const data = schedules
      .filter((item) => {
        const isActive = get(item, 'active')
        if (!active) {
          return item
        }
        if (active && isActive) {
          return item
        }
      })
      .map((schedule) => {
        const id = get(schedule, 'id', '')
        const startDate = get(schedule, 'start_date', '')
        const endDate = get(schedule, 'end_date', '')
        const code = `${
          classCode ? ` / Class Code: ${get(schedule, 'class_code', '')}` : ''
        }`

        const code2 = `${
          classCodeV2 ? `(${get(schedule, 'class_code', '')})` : ''
        }`
        const label = schedule.isAlwaysAvailable
          ? `${code2} Always Available ${code}`
          : `${code2} ${dayjs(startDate).format('DD MMM')} - ${dayjs(
              endDate
            ).format('DD MMM YYYY')} ${code}`
        const obj = {
          value: id,
          label,
          class_code: get(schedule, 'class_code', ''),
        }
        return obj
      })
    return data
  }

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <div
      style={{
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '60%',
      }}
    >
      <Select
        className="selectSchedule"
        showSearch
        placeholder="Select a schedule"
        optionFilterProp="children"
        onChange={handleSelectSchedule}
        value={handleMapValue()}
        filterOption={filterOption}
        options={mapScheduleToDropDownExist(newSchedules, true)}
      />
      <div
        style={{ cursor: 'pointer', marginLeft: 15 }}
        onClick={handleRemoveSchedule}
      >
        <img src={images.admin.trash_icon} alt="" />
      </div>
    </div>
  )
}

export default ScheduleItem
