import React from 'react'
import { Modal } from 'antd'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import images from 'Themes/Images'
import './styles.scss'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { makeSelectSchedules } from '../../../Admin/selectors'
import Schedules from '../../../Home/screens/CoursePreview/components/Schedules'
import { makeSelectCourse } from 'container/Home/selectors'
import { validSchedules } from 'utils/helper'
import { useHistory } from 'react-router-dom'
import { hideSelectScheduleModule } from '../../actions'

const ModalWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;

  p.ld-modal__label {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #666666;
    text-align: left;
    margin-bottom: 25px;
  }
`

const SelectScheduleModal = ({ dispatch, selectSchedule }) => {
  const currentUser = useSelector(makeSelectCurrentUser())
  const schedules = useSelector(makeSelectSchedules())
  const course = useSelector(makeSelectCourse())
  const history = useHistory()
  const handleCancel = () => {
    dispatch(hideSelectScheduleModule())
  }
  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={handleCancel}
      closeIcon={<img src={images.admin.x_normal} alt="" />}
      className="hs-modal"
      width={800}
    >
      <ModalWrapper>
        <p className="ld-modal__label">Select your Schedule</p>
        <Schedules
          currentUser={currentUser}
          schedules={validSchedules(schedules)}
          dispatch={dispatch}
          history={history}
          course={course}
          classCompare
        />
      </ModalWrapper>
    </Modal>
  )
}

export default SelectScheduleModal
