import React, { Component } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-hypeschools/build/ckeditor'
import config from 'src/global-config'

class TextEditor extends Component {
  render() {
    const { value, onChange } = this.props // <- Dont mind this, just handling objects from props because Im using this as a shared component.

    const custom_config = {
      mediaEmbed: {
        previewsInData: true,
      },
      extraPlugins: [MyCustomUploadAdapterPlugin],
      toolbarId: '1',
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'htmlEmbed',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'undo',
          'redo',
          '|',
          'alignment',
          'fontBackgroundColor',
          'fontColor',
          'fontSize',
          'fontFamily',
          'highlight',
          'horizontalLine',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableProperties',
          'tableCellProperties',
        ],
        tableToolbar: ['tableclass-responsive', 'tableclass-none-border'],
      },
      TableClasses: {
        items: [
          { id: 'responsive', classes: 'table-responsive' },
          { id: 'none-border', classes: 'table-none-border' },
        ],
      },
      link: {
        addTargetToExternalLinks: true,
      },
    }

    return (
      <CKEditor
        required
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          if (editor) {
            editor.setData(value)
          }
        }}
        editor={Editor}
        config={custom_config}
        data={value}
        isReadOnly={true}
        onChange={(event, editor) => {
          editor.ui.focusTracker.on(
            'change:isFocused',
            (evt, name, isFocused) => {
              if (!isFocused) {
                const data = editor.getData()
                onChange(data)
              }
            }
          )
        }}
      />
    )
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props
    // URL where to send files.
    this.url = `${config.api}/api/media`
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest()
          this._initListeners(resolve, reject, file)
          this._sendRequest(file)
        })
    )
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest())

    xhr.open('POST', this.url, true)
    xhr.responseType = 'json'
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr
    const loader = this.loader
    const genericErrorText = `Couldn't upload file:  ${file.name}.`
    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const response = xhr.response
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        )
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: response.data.url + '=s0',
      })
    })

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    const data = new FormData()

    data.append('name', file.name)
    data.append('type', 'image')
    data.append('file', file)
    console.log('file', file)
    this.xhr.send(data)
  }
}

export default TextEditor
