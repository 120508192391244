import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import { makeSelectCurrentUser } from '../../../../selectos'
import { createAccountStripe } from 'container/Admin/actions'
import { makeSelectAccountStripe } from 'container/Admin/selectors'
import { getAccountStripe } from 'container/Admin/actions'
import { makeSelectDomain } from 'container/Home/selectors'
import { Switch } from 'antd'
import './styles.scss'
import { parse } from 'query-string'
import { useLocation } from 'react-router'
import { showError } from 'utils/notification'
import { loadDomain } from 'container/Home/actions'
import { patchSchoolSettings } from '../Branding/handler'

const Payments = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { redirect } = parse(search)
  const currentUser = useSelector(makeSelectCurrentUser())
  const domain = useSelector(makeSelectDomain())
  const accountStripe = useSelector(makeSelectAccountStripe())

  const chargesEnabled = get(domain, 'company.charges_enabled', false)
  const stripeEnabled = get(domain, 'allow_stripe_payment', false)

  useEffect(() => {
    if (redirect) {
      dispatch(
        createAccountStripe({
          id: currentUser.uid,
          redirect: true
        })
      )
    }
  }, [redirect])

  useEffect(() => {
    dispatch(getAccountStripe())
  }, [])

  const handleLinkAccount = () => {
    if (currentUser) {
      dispatch(createAccountStripe({ id: currentUser.uid }))
    }
  }
  const handleSwitch = async (checked) => {
    if (chargesEnabled) {
      const schoolId = get(domain, 'id', '')
      await patchSchoolSettings(
        {
          name: get(domain, 'name', ''),
          logo: get(domain, 'logo', ''),
          banner: get(domain, 'banner', ''),
          page_title: get(domain, 'page_title', ''),
          page_description: get(domain, 'page_description', ''),
          allow_stripe_payment: checked
        },
        schoolId
      )
      await dispatch(loadDomain())
    } else {
      showError('You need to connect stripe account first!')
    }
  }

  return (
    <div className="admin__channel branding__wrapper">
      <div
        className="channel__head"
        style={{ borderBottom: '1px solid #d0cad8' }}
      >
        <div className="channel__head__left">
          <div className="channel__chapter">Connect Payments</div>
        </div>
      </div>

      <div
        className="channel__left admin__channel__left"
        style={{ padding: 0 }}
      >
        <div
          className="add-course__heading"
          style={{ borderTop: 'unset', fontSize: '12px' }}
        >
          Stripe
        </div>
      </div>

      <div className="stripe__wrapper">
        <div className="inner_container">
          <div className="stripe__header">
            <h3>
              Stripe lets you accept credit, debit cards, Google Pay and Apple
              Pay payments. Click connect to get started.
            </h3>
            <div>
              <Switch
                size={25}
                checked={stripeEnabled}
                onChange={handleSwitch}
              />
            </div>
          </div>{' '}
          <div className="stripe__connect">
            <BtnPrimary
              handleClick={handleLinkAccount}
              name="Connect"
              style={{ backgroundColor: '#6773e5' }}
              icon={images.admin.stripe}
              styleIcon={{ marginBottom: 0 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payments
