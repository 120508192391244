import React, { useState, useEffect, useRef } from 'react'
import { uploadImage } from 'src/container/CMS/hooks.js'
import { get } from 'lodash'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { makeSelectDomain } from 'container/Home/selectors'
import { patchSchoolSettings } from './handler'
import { showSuccess } from 'utils/notification'

import images from 'Themes/Images'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { loadDomain } from 'container/Home/actions'
import './styles.scss'

const Branding = ({ setSubmit, formikRef }) => {
  const school = useSelector(makeSelectDomain())
  const schoolId = get(school, 'id')
  const dispatch = useDispatch()
  const logoFileRef = useRef(null)
  const photoFileRef = useRef(null)
  const [logo, setLogo] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [editTitle, setEditTitle] = useState(false)
  const [pageTitle, setPageTitle] = useState('')
  const [pageDesc, setPageDesc] = useState('')

  useEffect(() => {
    if (school) {
      formik.setFieldValue('name', school.name)
      setPageTitle(school.page_title)
      setPageDesc(school.page_description)
      setLogo({
        image_url: school.logo
      })
      setPhoto({
        image_url: school.banner
      })
    }
  }, [school])

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())
      const isSuccess = await patchSchoolSettings(
        {
          name: values.name,
          logo: get(logo, 'image_url', ''),
          banner: get(photo, 'image_url', ''),
          page_title: pageTitle,
          page_description: pageDesc,
          allow_stripe_payment: get(school, 'allow_stripe_payment', false)
        },
        schoolId
      )

      if (isSuccess) {
        dispatch(loadDomain())
        showSuccess('Update School Branding Success!')
      }
      setEditTitle(false)
      dispatch(hideGlobalLoading())
    }
  })

  useEffect(() => {
    if (!formikRef.current) {
      formikRef.current = formik
    }
  }, [formik, formikRef])

  const renderLogo = () => {
    if (logo && logo.image_url) {
      return logo.image_url + '=s0'
    }

    return images.logo_example
  }

  const renderPhoto = () => {
    if (photo && photo.image_url) {
      return photo.image_url + '=s0'
    }

    return images.admin.course_img_xample
  }

  const handleClick = () => {
    logoFileRef.current.click()
  }

  const handleClickPhoto = () => {
    photoFileRef.current.click()
  }

  const handleChangeLogo = async (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      dispatch(showGlobalLoading())
      const imageUrl = await uploadImage(fileUploaded)
      if (imageUrl) {
        setLogo({
          name: imageUrl.name,
          image_url: imageUrl.url,
          size: fileUploaded.size
        })
        formik.submitForm()
      }
      dispatch(hideGlobalLoading())
    }
  }

  const handleChangePhoto = async (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      dispatch(showGlobalLoading())
      const imageUrl = await uploadImage(fileUploaded)
      if (imageUrl) {
        setPhoto({
          name: imageUrl.name,
          image_url: imageUrl.url,
          size: fileUploaded.size
        })
        formik.submitForm()
      }
      dispatch(hideGlobalLoading())
    }
  }

  const handleRemove = () => {
    setLogo(null)
    formik.submitForm()
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    formik.submitForm()
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="admin__channel branding__wrapper"
    >
      <div
        className="channel__head"
        style={{ borderBottom: '1px solid #d0cad8' }}
      >
        <div className="channel__head__left">
          <div className="channel__chapter">School Name</div>
          <div
            className="channel__title"
            style={{ marginTop: 10 }}
            onClick={() => setEditTitle(true)}
          >
            {school && !editTitle ? (
              <div className="admin__channel__course-name">
                {school.name}
                <img src={images.admin.pen_alt_active} alt="" />
              </div>
            ) : (
              <input
                id="name"
                name="name"
                placeholder="Course Title"
                type="text"
                autoComplete="off"
                className={`add-course__input ${
                  formik.touched.name && formik.errors.name
                    ? 'form__input__error'
                    : ''
                }`}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            )}
          </div>
          {formik.touched.name && formik.errors.name && (
            <div className="form__error">{formik.errors.name}</div>
          )}
        </div>
      </div>
      <div
        className="channel__body admin__channel__body"
        style={{
          borderTop: 'unset',
          display: 'block'
        }}
      >
        <div
          className="channel__left admin__channel__left"
          style={{ padding: 0 }}
        >
          <div
            className="add-course__heading"
            style={{ borderTop: 'unset', fontSize: '12px' }}
          >
            School Branding
          </div>

          <div className="add-course__head" style={{ padding: '0 24px' }}>
            LOGO ( Recommended Dimensions: 500x500 )
          </div>
          <div className="inner_container">
            <div className="add-course__upload-wrap-first">
              <div
                className="add-course__img"
                style={{ marginRight: 30, width: 150, height: 150 }}
              >
                <img
                  src={renderLogo()}
                  alt="logo schools"
                  style={{
                    objectFit: 'contain'
                  }}
                />
              </div>
              <div className="add-course__file-type">
                {logo && logo.name && (
                  <div className="add-course__file-name"> {logo.name}</div>
                )}
                {logo && logo.size && (
                  <div className="add-course__file-size">
                    {logo.size + 'kb'}
                  </div>
                )}
                <div className="add-course__file-upload">
                  <button
                    className="tertiary-btn"
                    type="button"
                    onClick={handleClick}
                    style={{ width: '111px!important' }}
                  >
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={logoFileRef}
                      onChange={handleChangeLogo}
                    />
                    <img src={images.header.image_none_bg} alt="" />
                    <span>{!logo && !school ? 'Upload' : 'Reupload'}</span>
                  </button>
                </div>
                <div className="add-course__file-remove" onClick={handleRemove}>
                  <button className="tertiary-btn" type="button">
                    <img src={images.admin.trash_icon} alt="" />{' '}
                    <span>Discard</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="add-course__head" style={{ padding: '0 24px' }}>
            COVER PHOTO ( Recommended Dimensions: 1440×474 )
          </div>
          <div className="inner_container">
            <div className="add-course__upload-wrap-first">
              <div className="add-course__img" style={{ marginRight: 30 }}>
                <img src={renderPhoto()} alt="" />
              </div>
              <div className="add-course__file-type">
                {photo && photo.name && (
                  <div className="add-course__file-name"> {photo.name}</div>
                )}
                {photo && photo.size && (
                  <div className="add-course__file-size">
                    {photo.size + 'kb'}
                  </div>
                )}
                <div className="add-course__file-upload">
                  <button
                    className="tertiary-btn"
                    type="button"
                    onClick={handleClickPhoto}
                    style={{ width: '111px!important' }}
                  >
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={photoFileRef}
                      onChange={handleChangePhoto}
                    />
                    <img src={images.header.image_none_bg} alt="" />
                    <span>{!photo && !school ? 'Upload' : 'Reupload'}</span>
                  </button>
                </div>
                <div
                  className="add-course__file-remove"
                  onClick={handleRemovePhoto}
                >
                  <button className="tertiary-btn" type="button">
                    <img src={images.admin.trash_icon} alt="" />{' '}
                    <span>Discard</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="add-course__head" style={{ padding: '0 24px' }}>
            Page title
          </div>
          <div className="inner_container">
            <div className="add-course__upload-wrap-first">
              <input
                type="text"
                className="add-course__input"
                placeholder="Enter Page Title"
                value={pageTitle}
                onChange={(e) => setPageTitle(e.currentTarget.value)}
              />
            </div>
          </div>

          <div className="add-course__head" style={{ padding: '0 24px' }}>
            Page Description
          </div>
          <div className="inner_container">
            <div className="add-course__upload-wrap-first">
              <textarea
                className="add-course__textarea mgbt-0"
                placeholder="Enter Page Description"
                rows="4"
                cols="50"
                value={pageDesc}
                onChange={(e) => setPageDesc(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Branding
