import { useEffect, useRef } from 'react'

const useFirstRender = () => {
  const isFirstRender = useRef(true)
  useEffect(() => {
    isFirstRender.current = false
  }, [isFirstRender.current])
  return isFirstRender.current
}

export default useFirstRender
