import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import images from 'Themes/Images'
import { Progress } from 'antd'
import config from '../../global-config'
import axios, { CancelToken, isCancel } from 'axios'
import { get } from 'lodash'

const Wrapper = styled.div`
  .cancel {
    margin-left: 20px;
    margin-right: 20px;
    color: #042580;
    cursor: pointer;
  }
`

const UploadVideoWrapper = styled.div`
  width: 218px;
  height: 130px;
  border-radius: 4px;
  border: 1px solid #e7ebf4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .head {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #5a6686;
  }
  .text {
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #8793b0;
  }
  .cancel {
    cursor: pointer;
    font-size: 18px;
    width: 100px;
  }
`

const UploadFileVideo = ({ setVideoUrl }) => {
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const refFile = useRef(null)
  const cancelFileUpload = useRef(null)
  const handleClickFile = () => {
    if (refFile && refFile.current) {
      refFile.current.click()
    }
  }

  const handleChangeUploadVideo = async (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      let data = new FormData()
      data.append('file', fileUploaded)
      data.append('type', 'video')
      data.append('name', fileUploaded.name)

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent

          let percent = Math.floor((loaded * 100) / total)

          if (percent < 100) {
            setUploadPercentage(percent)
          }
        },
        cancelToken: new CancelToken(
          (cancel) => (cancelFileUpload.current = cancel)
        )
      }

      axios
        .post(`${config.uploadApi}/api/media`, data, options)
        .then((res) => {
          const data = get(res, 'data.data.url', '')
          setUploadPercentage(100)

          setVideoUrl(data)
          setTimeout(() => {
            setUploadPercentage(0)
          }, 2000)
        })
        .catch((err) => {
          console.log(err)

          if (isCancel(err)) {
            alert(err.message)
          }
          setUploadPercentage(0)
        })
    }
  }

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current('Canceled the file upload.')
  }
  const handleRemoveFileVideo = () => {
    setVideoUrl('')
  }
  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <UploadVideoWrapper onClick={handleClickFile}>
          <img src={images.admin.video_upload} alt="upload" />
          <div className="head">Upload a video</div>
          <div className="text">Only MP4 or MOV accepted</div>
        </UploadVideoWrapper>
        <img
          src={images.admin.trash_icon}
          style={{ marginLeft: 10, marginTop: 10, cursor: 'pointer' }}
          onClick={handleRemoveFileVideo}
          alt="remove"
        />
      </div>
      <input
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        style={{ display: 'none' }}
        ref={refFile}
        onChange={handleChangeUploadVideo}
      />
      {uploadPercentage > 0 && (
        <div className="row">
          <Progress percent={uploadPercentage} strokeWidth={18} />
          <div className="cancel" onClick={() => cancelUpload()}>
            Cancel
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default UploadFileVideo
