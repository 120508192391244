import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import ReactPlayer from 'react-player'
import parseHtml from 'html-react-parser'

import {
  BackgroundDetail,
  BannerDetail,
  Body,
  Layout,
  ReactPlayerWrapper
} from '../../../styles'
import Button from '../../components/Button'
import styled from 'styled-components'
import CourseInfo from '../../components/CourseInfo'
import CourseEnroll from '../../components/CourseEnroll/index'
import NavbarClassCompare from '../../components/NavbarClassCompare'

import { loadCourse } from 'container/Home/actions'
import { makeSelectCourse } from 'container/Home/selectors'
import { loadCourseSuccess, getUserInfo } from 'container/Home/actions'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { isEnrollCourse } from 'utils/helper'
import { makeSelectCourseType } from 'container/Admin/selectors'
import { getSchedules } from 'container/Admin/actions'
import { showSelectScheduleModule } from 'container/Modal/actions'
import images from '../../../assets/images'
import { CKEditorWrapper } from 'container/CMS/screens/Article/index'
import { makeSelectUserInfo } from 'container/Home/selectors'
import { isAdminRole } from 'utils/userUtils'
import './styles.scss'

const MainDescription = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    display: block;
    margin-top: 90px;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #666666;
    margin: 0;
    padding: 0;
    padding-right: 20px;
    @media (max-width: 767px) {
      font-size: 34px;
    }
  }
`

export const CourseTitleSection = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    display: block;
  }

  h1 {
    width: 650px;
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 38px;
      width: 100%;
    }
  }
  .course__payment {
    margin-left: 10px;
    flex: 1;
    &__text {
      font-size: 18px;
      line-height: 26px;
      color: #666666;
      margin-bottom: 20px;
    }
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
`

const CourseDetail = () => {
  const dispatch = useDispatch()
  const [playVideo, setPlayVideo] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { id } = useParams()
  const history = useHistory()
  const course = useSelector(makeSelectCourse())
  const courseTypes = useSelector(makeSelectCourseType())
  const previewUrl = get(course, 'preview_url', '')
  const partners = get(course, 'partners', [])

  const userInfo = useSelector(makeSelectUserInfo())
  const isEnroll = isEnrollCourse(partners, course) && !isEmpty(userInfo)

  const courseTypeId = get(course, 'course_type')
  const courseType = useMemo(
    () => courseTypes && courseTypes.find((type) => type.id === courseTypeId),
    [courseTypes, courseTypeId]
  )

  const shortDescription = get(course, 'short_description', '')
  const imageUrl = get(course, 'image_url', '')
  const name = get(course, 'name', '')
  const description = get(course, 'description', '')
  const optionPrices = useMemo(() => {
    return get(course, 'prices', []).sort((a, b) => a.price - b.price)
  }, [course])

  const currentUser = useSelector(makeSelectCurrentUser())
  const userGroups = get(currentUser, 'user_groups', [])
  const isAdmin = isAdminRole(userGroups)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    }, 500)
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(loadCourse(id))
      dispatch(getSchedules(id))
      dispatch(getUserInfo())
    }
  }, [id])

  useEffect(() => {
    if (course && !course.is_published && !isAdmin) {
      history.push('/')
    }
  }, [course, isAdmin])

  const handleEnroll = () => {
    dispatch(loadCourseSuccess(course))
    dispatch(showSelectScheduleModule())
  }

  const renderTitle = () => {
    return 'Enrol now'
  }

  const renderButtonText = () => {
    return isEnroll ? 'Enrolled' : 'View Course Schedule'
  }
  const handlePlayVideo = () => {
    setPlayVideo(true)
  }
  return (
    <Layout>
      <BackgroundDetail height={isMobile ? 290 : 584} blur={30}>
        <div className="blur" />
        <div className="banner_img">
          <img src={imageUrl} />
        </div>
      </BackgroundDetail>
      <Body paddingBottom={70}>
        <NavbarClassCompare />
        {!playVideo && (
          <BannerDetail mgT={40} showPlayButton={previewUrl}>
            <img src={imageUrl + '=s0'} />
            {previewUrl && (
              <div
                className="play_btn"
                onClick={handlePlayVideo}
                style={{ width: 80, height: 80 }}
              >
                <img src={images.playBtn} alt="play button" />
              </div>
            )}
          </BannerDetail>
        )}
        {previewUrl && playVideo && (
          <ReactPlayerWrapper marginTop={60}>
            <ReactPlayer
              className="react-player"
              url={previewUrl}
              width="100%"
              height="100%"
              playing={true}
              onEnded={() => setPlayVideo(false)}
            />
          </ReactPlayerWrapper>
        )}

        <MainDescription>
          <CourseTitleSection>
            <h1>{name}</h1>
            <div className="course__payment">
              <div className="course__payment__text">{shortDescription}</div>
              <Button
                title={isEnroll ? 'Enrolled' : 'View Course Schedule'}
                color="#fff"
                bgColor="#FF0D6B"
                minWidth="352"
                height="50"
                handleClick={isEnroll ? () => {} : handleEnroll}
                fullWidth={isMobile}
              />
            </div>
          </CourseTitleSection>
        </MainDescription>
        <CourseInfo
          course={course}
          optionPrices={optionPrices}
          courseType={courseType}
        />
        <CKEditorWrapper>
          {description ? parseHtml(description) : ''}
        </CKEditorWrapper>
        <CourseEnroll
          isMobile={isMobile}
          renderTitle={renderTitle}
          handleEnroll={handleEnroll}
          isEnroll={isEnroll}
          renderButtonText={renderButtonText}
        />
      </Body>
    </Layout>
  )
}

export default CourseDetail
