import React from 'react'
import { Modal } from 'antd'
import { get } from 'lodash'
import images from 'Themes/Images'
import { hideImportUsers, hideProcessingModal } from '../../actions'

const ProcessingModal = ({
  dispatch,
  countUserImported,
  countUserEnrolled,
  countUserAssigned,
  countUserAssignedFolder,
  processingModalPayload,
  showProcessingModal,
}) => {
  const handleCancel = () => {
    dispatch(hideImportUsers())
    dispatch(hideProcessingModal())
  }

  const status = get(processingModalPayload, 'status', '')
  return (
    <Modal visible={showProcessingModal} footer={null} onCancel={handleCancel}>
      <div className="ld-modal">
        <div className="ld-modal__icon" style={{ textAlign: 'center' }}>
          <img src={images.working} alt="" width="80%" />
        </div>
        <div className="ld-modal__warning" style={{ lineHeight: 1.8 }}>
          {status === 'completed'
            ? 'Import is complete, you may close this popup.'
            : `Import in progress, do not close this popup until it's done.`}
        </div>
        <div className="ld-modal__label">
          {countUserImported} users have been successfully registered or
          updated.
        </div>
        {countUserAssigned ? (
          <div className="ld-modal__label">
            {countUserAssigned} users have been successfully assigned to the
            client.
          </div>
        ) : null}
        {countUserAssignedFolder ? (
          <div className="ld-modal__label">
            {countUserAssignedFolder} folders have been successfully assigned to
            the users.
          </div>
        ) : null}
        <div className="ld-modal__label">
          {countUserEnrolled} enrollment have been successfully.
        </div>
      </div>
    </Modal>
  )
}

export default ProcessingModal
