import React, { useEffect } from 'react'
import classNames from 'classnames'
import images from 'Themes/Images'
import './styles.scss'

const InputSmall = ({
  value,
  onChange,
  text,
  iconLeft,
  type = 'text',
  name,
  className,
  labelStyle,
  style,
  placeholder,
  formik,
  noScroll,
  allowApplicationFee = true,
  isEdit,
  handleEdit,
  editing,
  title,
  description = '',
  handleChangeTitle,
  handleRemove,
  isSFC = false,
  styleWrapper
}) => {
  const fieldRef = React.useRef(null)

  useEffect(() => {
    if (
      !noScroll &&
      formik &&
      formik.touched[name] &&
      formik.errors[name] &&
      formik.isSubmitting &&
      fieldRef
    ) {
      return window.scrollTo({
        behavior: 'smooth',
        top: fieldRef.current.offsetTop + 100
      })
    }
  }, [formik])

  return (
    <div>
      {editing ? (
        <>
          <div style={{ width: 150 }}>
            <input
              value={title}
              name="title"
              className="input-small__label"
              onChange={handleChangeTitle}
              placeholder="Enter title"
            />
          </div>
          {!isSFC && (
            <div style={{ width: 150 }}>
              <input
                value={description}
                name="description"
                className="input-small__label"
                onChange={handleChangeTitle}
                placeholder="Enter description"
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="input-small__text__wrapper" style={styleWrapper}>
            <div
              className={classNames('input-small__text', {
                'input-small__text-inactive': !allowApplicationFee
              })}
              style={labelStyle}
            >
              <div style={{ color: '#042580' }}>{text}</div>
            </div>
            {isEdit && (
              <div style={{ display: 'flex' }}>
                <img
                  src={images.admin.pen_alt_active}
                  className="input-small__pen"
                  alt=""
                  onClick={handleEdit}
                />
                <img
                  src={images.admin.trash_icon}
                  className="input-small__trash"
                  alt=""
                  onClick={handleRemove}
                />
              </div>
            )}
          </div>
          {description && (
            <div
              className={classNames('input-small__text', {
                'input-small__text-inactive': !allowApplicationFee
              })}
              style={labelStyle}
            >
              <div style={{ color: '#ff0d6b' }}>{description}</div>
            </div>
          )}
        </>
      )}

      <div className="input-small__left" ref={fieldRef}>
        <input
          type={type}
          value={allowApplicationFee ? value : '0'}
          className={classNames('input-small__input', `${className}`, {
            'input-small__text-inactive': !allowApplicationFee,
            form__input__error:
              formik && formik.touched[name] && formik.errors[name]
          })}
          onChange={onChange}
          name={name}
          style={style}
          placeholder={placeholder}
        />
        <img
          src={iconLeft}
          alt=""
          className={classNames({
            'input-small__text-inactive': !allowApplicationFee
          })}
        />
      </div>

      {formik && formik.touched[name] && formik.errors[name] && (
        <div className="form__error">{formik.errors[name]}</div>
      )}
    </div>
  )
}

export default InputSmall
