import { useEffect, useState } from 'react'
import { getResponseData } from '../utils/helper'
import axiosInstance from '../utils/axiosInstance'
import { stringify } from 'query-string'

const defaultFilter = {
  per_page: 10000,
  ignore_per_page: true,
  order_by: 'id desc',
}

const useGetFolders = () => {
  const [folders, setFolders] = useState(null)

  const getFolders = async () => {
    try {
      const params = stringify({ ...defaultFilter })
      const res = await axiosInstance.get(`/api/folders?${params}`)
      const data = getResponseData(res)

      setFolders(data)
    } catch (error) {
      setFolders([])
      console.log('error', error)
    }
  }

  useEffect(() => {
    getFolders()
  }, [])

  return folders
}

export default useGetFolders
