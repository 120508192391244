import React from 'react'
import { Row, Col, Radio, DatePicker } from 'antd'
import Dropdown from 'react-dropdown'
import { initialNational, initialLevel } from 'utils/constants'
import { get, isEmpty } from 'lodash'

import Input from 'component/Input'
import config from 'src/global-config'
import { renderColValue } from 'utils/helper'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { showSuccess } from 'utils/notification'
import { getAccessToken } from 'utils/request'

const AgbEducation = ({
  formik,
  editable,
  userProfile,
  userData,
  dispatch,
  userId,
  setEditable
}) => {
  const name = get(userProfile, 'name', '') || get(userData, 'partner.name', '')
  const passport = get(userProfile, 'passport', '')
  const phone = get(userProfile, 'phone', '')

  const handleChangePhoneNum = ({ currentTarget }) => {
    var regExp = /^[0-9]{1,13}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue('phone', currentTarget.value)
    }
  }

  return (
    <div>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          NAME (ACCORDING TO ID/PASSPORT):
        </Col>
        <Col xs={renderColValue(24, 10)} md={6} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="name"
                placeHolder="Enter your full name"
                formik={formik}
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            name
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          ID/Passport No. (eg. NRIC):
        </Col>
        <Col md={6} xs={10} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="passport"
                placeHolder="Enter ID/Passport"
                formik={formik}
                value={formik.values.passport}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            passport
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Phone No.:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="phone"
                placeHolder="Enter Phone No."
                formik={formik}
                value={formik.values.phone}
                onChange={handleChangePhoneNum}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            phone
          )}
        </Col>
      </Row>
    </div>
  )
}

export default AgbEducation
