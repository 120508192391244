import React from 'react'
import { useDispatch } from 'react-redux'
import { showForgotPasswordModal } from 'container/Modal/actions'

const ForgotPasswordBtn = () => {
  const dispatch = useDispatch()

  const handleForgotPassword = () => {
    dispatch(showForgotPasswordModal())
  }

  return (
    <div className="login-form__forgot" onClick={handleForgotPassword}>
      forgot password?
    </div>
  )
}

export default ForgotPasswordBtn
