import React from 'react'
import images from 'Themes/Images'
import styled from 'styled-components'
import { deleteCourseOfSeller } from '../../container/AdminActor/actions'
import { get } from 'lodash'
import AffiliateLinkCopy from 'component/AffiliateLinkCopy'
import { Switch } from 'antd'
import config from 'src/global-config'
import { loadListCourse } from 'container/Home/actions'
import { loadListCourseOfSeller } from 'src/customs/SuperAdmin/container/AdminActor/actions'
import { getAccessToken } from 'utils/request'

const CourseSellingWrapper = styled.div`
  padding: 12px 13px;
  border: 1px solid #e7ebf4;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5a6686;
  margin-bottom: 10px;
  .course__name {
    display: flex;
    justify-content: space-between;
  }
  span.course_name {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 16px;
    letter-spacing: 0.02em;
    margin-left: 5px;
    color: #a0abc3;
  }
`

const UserCourseSelling = ({
  listCourseOfSeller,
  dispatch,
  user,
  listCourses,
  listDomain
}) => {
  const handleDeleteCourseOfSeller = (course) => {
    dispatch(
      deleteCourseOfSeller({
        id: get(course, 'id'),
        userId: get(user, 'id')
      })
    )
  }

  const setPriorityCourse = async (course, checked) => {
    let requestURL = `${config.baseUrl}/api/lms/courses/channels/${course.id}`
    const isPriority = get(course, 'is_priority', false)

    try {
      const res = await fetch(requestURL, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify({
          ...course,
          is_priority: !isPriority,
          prices: [],
          sfc_prices: []
        })
      })
      await res.json()

      dispatch(loadListCourse({ page: 1, superAdmin: true }))
      dispatch(loadListCourseOfSeller({ userId: get(user, 'id', '') }))
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleTurnFeatureCourse = (course) => {
    setPriorityCourse(course)
  }

  return (
    listCourseOfSeller &&
    listCourseOfSeller.map((course) => {
      const channelId = get(course, 'channel_id')
      const matchCourse =
        listCourses && listCourses.find((item) => item.id === channelId)
      const isPriority = get(matchCourse, 'is_priority', false)

      const matchDomain =
        listDomain &&
        listDomain.find((item) => item.id === get(matchCourse, 'website_id'))
      const affiliateLink = `https://classcompare.sg/course/preview/${channelId}?ref=${get(
        user,
        'id'
      )}`

      const secondAffiliateLink = `${
        matchDomain?.domain
      }course/preview/${channelId}?ref=${get(user, 'id')}`

      return (
        <CourseSellingWrapper key={course.id}>
          <div className="course__name">
            <div style={{ width: 500 }}>
              {get(matchCourse, 'name', '')}{' '}
              <span className="course_name">
                by {get(matchDomain, 'name', '')}
              </span>
            </div>
            <Switch
              unCheckedChildren="Feature"
              checkedChildren="Featured"
              onChange={(checked) =>
                handleTurnFeatureCourse(matchCourse, checked)
              }
              checked={isPriority}
              style={{ marginRight: 12, color: '#fff', fontWeight: 'bold' }}
            />

            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleDeleteCourseOfSeller(course)}
            >
              <img src={images.admin.x_inactive} alt="" />
            </div>
          </div>

          <div>
            <AffiliateLinkCopy
              affiliateLink={affiliateLink}
              styleLink={{ maxWidth: 500 }}
            />
          </div>
          <div>
            {matchDomain?.domain && (
              <AffiliateLinkCopy
                affiliateLink={secondAffiliateLink}
                styleLink={{ maxWidth: 500 }}
              />
            )}
          </div>
        </CourseSellingWrapper>
      )
    })
  )
}

export default UserCourseSelling
