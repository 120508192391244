import React from 'react'
import images from 'Themes/Images'
import CourseCurriculumItem from '../CourseCurriculumItem'
import { CourseCurriculumListWrap } from './styles'
import { get } from 'lodash'

const CourseCurriculumList = ({ section, handleToggleSection }) => {
  return (
    <CourseCurriculumListWrap>
      <div
        className="curriculum__head"
        onClick={() => handleToggleSection(section)}
      >
        <div className="curriculum__section">
          <div>
            {get(section, 'is_toggle', false) ? (
              <img src={images.close} />
            ) : (
              <img src={images.open} />
            )}{' '}
          </div>
          <div>{section.name}</div>
        </div>
      </div>
      {get(section, 'is_toggle', false) &&
        section.items.map((lesson) => (
          <CourseCurriculumItem lesson={lesson} section={section} />
        ))}
    </CourseCurriculumListWrap>
  )
}

export default CourseCurriculumList
