import React from 'react'
import { get } from 'lodash'
import { Radio } from 'antd'
import GlobalLoading from 'container/Modal/components/GlobalLoading'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import images from 'Themes/Images'
import classNames from 'classnames'

const PaypalButton = ({
  course,
  history,
  formik,
  dispatch,
  applicationFee,
  specialPrice,
  allowSpecialPrice,
  isAllowApplicationFee
}) => {
  const [{ isPending }] = usePayPalScriptReducer()
  let amount = isAllowApplicationFee ? applicationFee : get(course, 'price', 0)
  const name = get(course, 'name', '')
  const courseId = get(course, 'id', '')

  if (allowSpecialPrice) {
    amount = specialPrice
  }

  if (!name && !amount) {
    return <GlobalLoading />
  }

  const createOrder = (data, actions) => {
    formik.setFieldValue('payment_method', 'paypal')
    return actions.order.create({
      purchase_units: [
        {
          description: name,
          amount: {
            value: amount
          }
        }
      ]
    })
  }

  const onApprove = async (data, actions) => {
    dispatch(showGlobalLoading())
    const order = await actions.order.capture()

    if (order && order.status === 'COMPLETED' && order.id) {
      formik.setFieldValue('orderId', order.id)
      formik.setFieldValue('paypal_success', true)
      formik.submitForm()
    } else {
      formik.setFieldValue('paypal_success', false)
      dispatch(hideGlobalLoading())
      history.push(`/checkout/${courseId}/fail`)
    }
  }
  return (
    <>
      {isPending ? (
        <GlobalLoading />
      ) : (
        <div>
          <div className="payment_method_block">
            <Radio value="paypal">
              <span className="hs-checkout__heading-2">Pay using Paypal</span>
            </Radio>
            <div className="payment_method_toggle">
              <img
                src={
                  formik.values.payment_method === 'paypal'
                    ? images.admin.angle_up
                    : images.admin.angle_down
                }
                alt=""
              />
            </div>
          </div>
          <div
            className={classNames('payment_method', {
              toggle_payment_method: formik.values.payment_method === 'paypal'
            })}
          >
            <PayPalButtons
              onError={(error) => {
                history.push(`/checkout/${courseId}/fail`)
              }}
              onApprove={onApprove}
              createOrder={createOrder}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default PaypalButton
