import React from 'react'
import images from 'Themes/Images'
import Input from 'component/Input'
import BtnPrimary from 'component/BtnPrimary'
import { Row, Col } from 'antd'
import { get } from 'lodash'
import { formatDate, formatMoney } from 'utils/helper'
import './styles.scss'
import CartSummarySFC from './CartSummarySFC'

const CartSummary = ({
  course,
  formik,
  discount,
  setDiscount,
  applicationFee,
  isAllowApplicationFee,
  selectedSchedule,
  specialPrice,
  allowSpecialPrice,
  selectedPriceSFC,
  remainingAmount,
  enableSelectedPrice,
  ascendoDomain,
  therapadaDomain
}) => {
  const currency = therapadaDomain ? 'USD' : 'SGD'
  const name = get(course, 'name', '')
  const price = get(course, 'price', 0)
  const isAlwaysAvailable = get(selectedSchedule, 'isAlwaysAvailable', '')
  const startDate = get(selectedSchedule, 'start_date', '')
  const endDate = get(selectedSchedule, 'end_date', '')
  const startTime = get(selectedSchedule, 'start_time', '')
  const endTime = get(selectedSchedule, 'end_time', '')
  const venue = get(selectedSchedule, 'venue', '')
  const discountPricePercent = Math.round(
    ((price - specialPrice) / price) * 100
  )

  const listVoucher = [
    {
      code: 'learnsmm',
      percentage: 100,
    },
  ]
  const handleApplyVoucher = () => {
    if (discount) {
      formik.setFieldValue('voucher', '')
      return setDiscount(0)
    }
    const validVoucher = listVoucher.find(
      (item) => item.code === formik.values.voucher
    )

    if (validVoucher) {
      setDiscount(validVoucher.percentage)
      formik.setFieldError('voucher', 'Your voucher is applicable.')
    } else {
      formik.setFieldError('voucher', 'Sorry, voucher is not applicable.')
    }
    setTimeout(() => {
      formik.setFieldError('voucher', '')
    }, 2000)
  }

  const renderDateBetween = () => {
    if (isAlwaysAvailable) {
      return 'Always Available'
    }

    return formatDate(startDate, endDate)
  }

  const renderFee = () => {
    if (isAllowApplicationFee) {
      return (
        <div className="cart-summary__num">
          {formatMoney(applicationFee, false, currency)}
        </div>
      )
    } else if (allowSpecialPrice) {
      return (
        <div className="cart-summary__num">
          {formatMoney(specialPrice, true, currency)}
        </div>
      )
    } else {
      return (
        <div className="cart-summary__num">
          {formatMoney(price, false, currency)}
        </div>
      )
    }
  }

  const renderTotal = () => {
    if (isAllowApplicationFee) {
      return (
        <div className="cart-summary__num-total">
          {formatMoney(
            applicationFee - (applicationFee * discount) / 100,
            false,
            currency
          )}
        </div>
      )
    } else if (allowSpecialPrice) {
      return (
        <div className="cart-summary__num-total">
          {formatMoney(
            specialPrice - (specialPrice * discount) / 100,
            true,
            currency
          )}
        </div>
      )
    } else {
      return (
        <div className="cart-summary__num-total">
          {formatMoney(price - (price * discount) / 100, false, currency)}
        </div>
      )
    }
  }

  const renderPercent = () => {
    if (allowSpecialPrice && discount) {
      if (discount + discountPricePercent > 100) {
        return '-100%'
      }
      return '-' + discountPricePercent + discount + '%'
    }

    if (allowSpecialPrice && !discount) {
      return '-' + discountPricePercent + '%'
    }

    return discount ? `-${discount}%` : '$0.00'
  }
  const renderMainPrice = () => {
    if (isAllowApplicationFee) {
      return (
        <>
          <div className="cart-summary__head">
            <div className="cart-summary__text" style={{ width: '70%' }}>
              <span>Application Fee</span>
            </div>
            <div className="cart-summary__price">
              {formatMoney(applicationFee, false, currency)}
            </div>
          </div>
          <div className="cart-summary__application-fee__text">
            Full course fee will be collected separately.
          </div>
        </>
      )
    }

    return (
      <div className="cart-summary__head">
        <div className="cart-summary__text" style={{ width: '70%' }}>
          <span>{name}</span>
        </div>
        <div className="cart-summary__price">
          {formatMoney(price, false, currency)}
        </div>
      </div>
    )
  }

  return (
    <div>
      {enableSelectedPrice ? (
        <CartSummarySFC
          name={name}
          selectedPriceSFC={selectedPriceSFC?.price}
          renderDateBetween={renderDateBetween}
          isAlwaysAvailable={isAlwaysAvailable}
          startTime={startTime}
          endTime={endTime}
          remainingAmount={remainingAmount}
          ascendoDomain={ascendoDomain}
        />
      ) : (
        <div className="cart-summary">
          <div className="cart-summary__top">
            <h3 className="hs-checkout__heading-2">Checkout</h3>

            {renderMainPrice()}

            <div className="cart-summary__schedule">
              <span>{renderDateBetween()}</span>
              <span>
                {isAlwaysAvailable
                  ? ''
                  : startTime && endTime
                  ? startTime + ' - ' + endTime
                  : ''}
              </span>
            </div>
          </div>

          <Row
            justify="space-between"
            className="cart-summary__coupon-wrap"
            gutter={6}
          >
            <Col xs={16} md={18}>
              <Input
                placeHolder="Enter voucher code"
                formik={formik}
                name="voucher"
                className={discount ? 'disable__input' : ''}
                value={formik.values.voucher}
                onChange={discount ? () => {} : formik.handleChange}
              />
              {formik && formik.errors.voucher && (
                <div
                  className={`form__error ${discount ? 'valid__coupon' : ''}`}
                >
                  {formik.errors.voucher}
                </div>
              )}
            </Col>
            <Col md={6}>
              <BtnPrimary
                name={`${discount ? 'Edit' : 'Apply'}`}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#E7EBF4',
                  color: '#00122A',
                  fontWeight: 600,
                }}
                type="button"
                handleClick={handleApplyVoucher}
              />
            </Col>
          </Row>

          <div className="cart-summary__total_wrap">
            <div className="cart-summary__total">
              <div className="cart-summary__text">
                <span>Sub-total</span>
              </div>
              {renderFee()}
            </div>
            <div className="cart-summary__total" style={{ marginBottom: 18 }}>
              <div className="cart-summary__text">
                <span>Discount applied</span>
              </div>
              <div className="cart-summary__num">{renderPercent()}</div>
            </div>
            <div className="cart-summary__total">
              <div className="cart-summary__text">
                <img
                  src={images.admin.shopping_bag}
                  alt=""
                  style={{ marginRight: 8, marginBottom: 5 }}
                />

                <span>
                  {ascendoDomain
                    ? 'Order total (inclusive of GST)'
                    : 'Your total:'}
                </span>
              </div>
              {renderTotal()}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CartSummary
