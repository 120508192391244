import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Progress } from 'antd'
import { cloneDeep } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

import SideBarList from './SideBarList'
import { setSelectedSlide } from 'container/Home/actions'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { loadSlidesSuccess, putUserLog } from '../../container/Home/actions'
import {
  makeSelectLessonLength,
  makeSelectUserLogCurrentState,
} from '../../container/Home/selectors'
import ZoomLink from 'component/ZoomLink'
import './styles.scss'
import dayjs from 'dayjs'
import { loadZooms } from '../../container/Admin/actions'
import { makeSelectZooms } from 'container/Admin/selectors'

const SideBar = ({
  slides,
  selectedSlide,
  previews,
  meeting,
  partnerId,
  courseId,
  scheduleId,
  setPageNumber,
  userLogByCourse,
  preview,
  selectedSchedule,
  needToScroll,
}) => {
  const sidebarRef = useRef({})
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const lessonLength = useSelector(makeSelectLessonLength())
  const currentUser = useSelector(makeSelectCurrentUser())
  const userLogCurrentState = useSelector(makeSelectUserLogCurrentState())
  const zooms = useSelector(makeSelectZooms())

  useEffect(() => {
    if (needToScroll && !!sidebarRef.current && slides && slides.length !== 0) {
      const lesson = sidebarRef.current[selectedSlide?.id]
      const sidebarParent = document.getElementById('sidebar__scroll-parent')

      if (!!lesson && !!sidebarParent) {
        sidebarParent.scrollTop = lesson.offsetTop - 350
      }
    }
  }, [sidebarRef, slides, needToScroll])

  useEffect(() => {
    dispatch(loadZooms())
  }, [])

  const calculatePercent = (a, b) => {
    if (!a || !b) {
      return 0
    }
    if (a === 0 || b === 0) {
      return 0
    }
    return Math.round((a / b) * 100)
  }

  const percent =
    previews &&
    calculatePercent(
      previews?.filter(
        (x) =>
          slides?.find((i) => i?.items?.[0]?.id === x?.slide_id) && x?.completed
      ).length,
      lessonLength
    )
  const isCompleteAllLesson = percent === 100

  useEffect(() => {
    if (isCompleteAllLesson) {
      localStorage.removeItem('submittedAnswer')
    }
  }, [isCompleteAllLesson])

  const handleClickSlide = (item) => {
    dispatch(setSelectedSlide(item))
    history.push(`${pathname}${preview ? '?preview=true' : ''}`)
  }

  const handleClickQuiz = (item) => {
    dispatch(setSelectedSlide(item))
    history.push(
      `${pathname}?quiz=true${preview ? '&preview=true' : ''}${
        selectedSchedule?.value ? `&schedule=${selectedSchedule.value}` : ''
      }`
    )
  }

  const handleToggle = (slide) => {
    const cloneSlides = cloneDeep(slides)
    const data = cloneSlides.map((item) => ({
      ...item,
      is_toggle: item.id === slide.id ? !item.is_toggle : item.is_toggle,
    }))

    let lessonLength = 0
    data.forEach((item) => {
      if (Array.isArray(item.items) && item.items.length !== 0) {
        lessonLength += item.items.reduce(
          (acc, curr) => acc + (!curr.is_category ? 1 : 0),
          0
        )
      }
    })

    dispatch(loadSlidesSuccess(data, lessonLength))
  }

  useEffect(() => {
    if (
      isCompleteAllLesson &&
      ((userLogByCourse && !userLogByCourse?.course_completion_date) ||
        (userLogCurrentState && !userLogCurrentState?.course_completion_date))
    ) {
      dispatch(
        putUserLog(
          userLogCurrentState
            ? {
                ...userLogCurrentState,
                status: 'Complete',
                course_completion_date: dayjs(),
              }
            : {
                ...userLogByCourse,
                status: 'Complete',
                course_completion_date: dayjs(),
              },
          true
        )
      )
    }
  }, [isCompleteAllLesson])

  return (
    <div className="sidebar">
      {zooms && meeting && (
        <ZoomLink
          meeting={meeting}
          partnerId={partnerId}
          courseId={courseId}
          scheduleId={scheduleId}
          zooms={zooms}
        />
      )}
      {slides && previews && (
        <div className="sidebar__block">
          <div
            className="sidebar__title"
            style={{ fontWeight: 600, textTransform: 'none', color: '#27284d' }}
          >
            Progress ({percent}% completed)
          </div>

          <div className="sidebar__progressbar">
            <Progress
              percent={percent}
              showInfo={false}
              size="small"
              strokeColor="#FF0D6B"
              trailColor="rgba(193,199,216,0.5)"
              style={{ height: '4px' }}
            />
          </div>
        </div>
      )}

      <div className="sidebar__scroll" id="sidebar__scroll-parent">
        {slides &&
          slides.map((slide) => (
            <SideBarList
              slide={slide}
              key={slide.name}
              previews={previews}
              dispatch={dispatch}
              sidebarRef={sidebarRef}
              useSelector={useSelector}
              currentUser={currentUser}
              handleToggle={handleToggle}
              selectedSlide={selectedSlide}
              handleClickQuiz={handleClickQuiz}
              handleClickSlide={handleClickSlide}
              setPageNumber={setPageNumber}
            />
          ))}
      </div>
    </div>
  )
}

export default SideBar
