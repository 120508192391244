import React, { useEffect, useState, useMemo } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import config from '../../global-config'
import Alert from './components/Alert'
import Failed from './screens/Failed'
import Confirm from './screens/Confirm'
import Billing from './screens/Billing'
import Complete from './screens/Complete'
import Login_SignUp from './screens/Login&Signup'
import CompleteProfile from './screens/CompleteProfile'
import * as homeSelectors from '../Home/selectors'
import * as adminSelectors from '../Admin/selectors'
import { makeSelectDomain } from 'container/Home/selectors'
import { makeSelectCurrentUser } from '../Auth/selectos'
import { hideGlobalLoading } from '../Modal/actions'
import { getSchedules } from '../Admin/actions'
import { loadCourse } from '../Home/actions'
import {
  isEnrollCourse,
  getPaymentMethodByCourse,
  parseSafe,
  parseOptionsPrice,
} from 'utils/helper'
import { showSuccess } from 'utils/notification'
import { DOMAIN, DOMAIN_ID } from '../../routes'
import './styles.scss'
import { makeSelectSelectedSchedule } from './selectors'
import { setSelectedPriceSFC, setSelectedSchedule } from './actions'
import NavbarClassCompare from 'src/customs/ClassCompare/src/components/NavbarClassCompare'
import { getAccessToken } from 'utils/request'

loadStripe.setLoadParameters({ advancedFraudSignals: false })

const Wrapper = styled.div`
  margin: 0 20px;
  margin-bottom: ${(props) => (props.mgBt ? '30px' : '0')};
`

const Checkout = ({ classCompare }) => {
  const selectedSchedule = useSelector(makeSelectSelectedSchedule())
  const [complete, setComplete] = useState(1)
  const [alert, setAlert] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { path } = useRouteMatch()
  const { search, pathname } = useLocation()
  const { schedule, ref } = parse(search)
  const domain = useSelector(makeSelectDomain())
  const currentUser = useSelector(makeSelectCurrentUser())
  const course = useSelector(homeSelectors.makeSelectCourse())
  const allowSpecialPrice = get(course, 'is_allow_special_price', false)
  const specialPrice = get(course, 'special_price', 0)
  const applicationFee = get(course, 'application_fee', null)
  const enableApplicationFee = get(course, 'is_application_fee', false)
  const isAllowApplicationFee = enableApplicationFee && applicationFee !== null
  const userInfo = useSelector(homeSelectors.makeSelectUserInfo())
  const refId = useSelector(homeSelectors.makeSelectRefId())
  const requiredSchedule = get(course, 'is_schedule', false)

  const schoolId = get(course, 'website_id')

  const userPaymentMethod = useSelector(
    homeSelectors.makeSelectUserPaymentMethod()
  )
  const includeStatus = ['claimed', 'success']
  const paymentMethod = useMemo(
    () =>
      Array.isArray(userPaymentMethod) &&
      userPaymentMethod.filter(
        (payment) =>
          payment.channel_id === parseInt(id) &&
          includeStatus.includes(payment.status.toLowerCase())
      ),
    [userPaymentMethod, id]
  )

  const additionalData = parseSafe(
    get(paymentMethod, '[0].additional_info', '')
  )
  const courseFee = additionalData?.course_fee
  const sfcPrices = get(course, 'sfc_prices', [])
  const courseCode = get(sfcPrices, '[0].course_code')
  const priceOptions = parseOptionsPrice(get(sfcPrices, '[0].price_options'))

  const isComplete = get(sfcPrices, 'length') === complete

  const matchSelectedPrice = useMemo(
    () =>
      Array.isArray(priceOptions) &&
      priceOptions.find((payment) => payment.price === courseFee),
    [priceOptions, courseFee]
  )

  const remainingAmount = useMemo(() => {
    return (
      Array.isArray(paymentMethod) &&
      paymentMethod.reduce((prev, curr) => {
        if (curr?.payment_method === 'credit' && curr.status === 'claimed') {
          return prev + curr?.amount
        }
        return prev
      }, 0)
    )
  }, [paymentMethod])

  useEffect(() => {
    if (matchSelectedPrice && courseCode) {
      dispatch(
        setSelectedPriceSFC({
          ...matchSelectedPrice,
          course_code: courseCode,
          price: matchSelectedPrice.price,
          is_finished: isComplete,
        })
      )
    }
  }, [matchSelectedPrice, courseCode])

  const userPaymentByCourse = useMemo(
    () => getPaymentMethodByCourse(userPaymentMethod, parseInt(id)),
    [userPaymentMethod, id]
  )
  const partnersArr = get(course, 'partners', [])
  const isEnrol = isEnrollCourse(partnersArr, course)

  const schedules = useSelector(adminSelectors.makeSelectSchedules())

  const isUnicornDomain =
    get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN ||
    schoolId === DOMAIN_ID.UNICORN_DOMAIN

  const isDigitalWorkForceDomain =
    get(domain, 'domain', '') === DOMAIN.DIGITALWORKFORCE

  const isClassCompareDomain =
    get(domain, 'domain', '') === DOMAIN.COURSES_CLASS_COMPARE

  const isArtofnumbersDomain =
    get(domain, 'domain', '') === DOMAIN.ARTOFUNUMBERS_DOMAIN ||
    schoolId === DOMAIN_ID.ARTOFUNUMBERS_DOMAIN

  const isAscendoDomain =
    get(domain, 'domain', '') === DOMAIN.ASCENDO ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO_2 ||
    schoolId === DOMAIN_ID.ASCENDO

  const isViaFrontiers =
    get(domain, 'domain', '') === DOMAIN.VIAFRONTIERS ||
    schoolId === DOMAIN_ID.VIAFRONTIERS

  const firstMediaDomain =
    get(domain, 'domain', '') === DOMAIN.FIRSTMEDIA ||
    schoolId === DOMAIN_ID.FIRSTMEDIA

  const isAgbEducation =
    get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION ||
    schoolId === DOMAIN_ID.AGB_EDUCATION

  const allQuantDomain =
    get(domain, 'domain', '') === DOMAIN.ALLQUANT ||
    schoolId === DOMAIN_ID.ALLQUANT

  const therapadaDomain =
    get(domain, 'domain', '') === DOMAIN.THERAPADA ||
    schoolId === DOMAIN_ID.THERAPADA

  const imadviserDomain =
    get(domain, 'domain', '') === DOMAIN.IMADVISER ||
    schoolId === DOMAIN_ID.IMADVISER

  const coursesDomain =
    get(domain, 'domain', '') === DOMAIN.COURSES ||
    schoolId === DOMAIN_ID.COURSES

  const cyberQuoteDomain =
    get(domain, 'domain', '') === DOMAIN.CYBERQUOTE ||
    schoolId === DOMAIN_ID.CYBERQUOTE

  const isSingaporeSkillsAcademies =
    domain && domain.domain === DOMAIN.SINGAPORESKILLS

  const isFlcDomain =
    get(domain, 'domain', '') === DOMAIN.FLC || schoolId === DOMAIN_ID.FLC

  const isPortalDomain = domain && domain.domain === DOMAIN.PORTAL

  const isBellDomain = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION

  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  const URL_CHECKOUT_REDIRECT = [
    `/checkout/${id}/confirm`,
    `/checkout/${id}/billing`,
  ]

  const URL_GET_SCHEDULES = [
    `/checkout/${id}/auth`,
    `/checkout/${id}/confirm`,
    `/checkout/${id}/billing`,
    `/checkout/${id}/complete-profile`,
  ]

  useEffect(() => {
    if (!currentUser && course) {
      history.push(`/checkout/${get(course, 'id', '')}/auth`)
    }
  }, [currentUser, pathname, course])

  useEffect(() => {
    // 1
    if (
      !isSingaporeSkillsAcademies &&
      course &&
      URL_CHECKOUT_REDIRECT.includes(pathname)
    ) {
      const partners = get(course, 'partners', [])
      const isEnroll = isEnrollCourse(partners, course)
      if (isEnroll) {
        setTimeout(() => {
          showSuccess(
            'You have enrolled already, please continue to go to the course page'
          )
          history.push(`/checkout/${get(course, 'id', '')}/complete`)
        }, 500)
      }
    }
  }, [course, pathname, isSingaporeSkillsAcademies])

  useEffect(() => {
    if (schedule && Array.isArray(schedules)) {
      const enrollSchedule = schedules.find(
        (item) => item.id === +schedule && item.active
      )

      if (enrollSchedule) {
        dispatch(setSelectedSchedule(enrollSchedule))
      }
    }
  }, [schedule, schedules, pathname])

  useEffect(() => {
    if (id && pathname) {
      dispatch(loadCourse(id))
    }
  }, [id, pathname])

  useEffect(() => {
    if (id && course && URL_GET_SCHEDULES.includes(pathname)) {
      dispatch(getSchedules(id, false, null, true, true))
    }
  }, [id, course, pathname])

  const handlePaymentMethodFreeCourse = async (uid) => {
    const userId = uid
      ? uid
      : get(currentUser, 'uid', '') || get(currentUser, 'id', '')
    const body = {
      user_id: userId,
      channel_id: get(course, 'id', ''),
      payment_method: 'free_payment',
      domain: get(domain, 'domain', ''),
    }

    if (refId) {
      body.ref_id = parseFloat(refId)
    }

    try {
      const res = await fetch(`${config.baseUrl}/api/payments/payment-method`, {
        method: 'PUT',
        headers: {
          'Content-type': 'Application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      })

      if (res.status !== 200) {
        return history.push(`/checkout/${get(course, 'id')}/fail`)
      }
      const data = await res.json()
      const id = get(data, 'data.id', null)

      if (id) {
        return true
      }

      dispatch(loadCourse(get(course, 'id')))
      dispatch(hideGlobalLoading())
      return true
    } catch (err) {
      dispatch(hideGlobalLoading())
      return false
    }
  }

  const handleNextStepConfirm = async () => {
    if (!get(course, 'id')) {
      return
    }
    const scheduleId = get(selectedSchedule, 'id')

    if (scheduleId) {
      return history.push(
        `/checkout/${course.id}/complete-profile?schedule=${selectedSchedule.id}`
      )
    }
  }

  const getKeyStripe = () => {
    if (isAscendoDomain) {
      return config.ascendoKey
    }
    if (allQuantDomain) {
      return config.secretAllQuant
    }

    if (therapadaDomain) {
      return config.therapadaKey
    }
    return config.secret
  }
  const stripePromise = loadStripe(getKeyStripe())

  return (
    <div style={{ minHeight: '85vh' }}>
      {course && (
        <>
          <Helmet>
            <title>Checkout</title>
          </Helmet>
          {classCompare && <NavbarClassCompare cmsMode checkoutPage />}
          {alert && (
            <Alert
              type={alert}
              message={
                alert === 'success'
                  ? 'YOUR PAYMENT WAS SUCCESSFUL.'
                  : 'PAYMENT FAILED. PLEASE TRY AGAIN.'
              }
            />
          )}
          <div className="hs-checkout">
            <div className="hs-checkout__wrap">
              <Switch>
                <Route exact path={`${path}/auth`}>
                  <Wrapper>
                    <Login_SignUp
                      course={course}
                      refId={refId}
                      history={history}
                      schedule={schedule}
                      dispatch={dispatch}
                      isUnicornDomain={isUnicornDomain}
                      cyberQuoteDomain={cyberQuoteDomain}
                      firstMediaDomain={firstMediaDomain}
                      currentUser={currentUser}
                      isArtofnumbersDomain={isArtofnumbersDomain}
                      allQuantDomain={allQuantDomain}
                      requiredSchedule={requiredSchedule}
                      isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
                      handlePaymentMethodFreeCourse={
                        handlePaymentMethodFreeCourse
                      }
                    />
                  </Wrapper>
                </Route>

                <Route exact path={`${path}/confirm`}>
                  <Confirm
                    dispatch={dispatch}
                    course={course}
                    schedules={schedules}
                    isUnicornDomain={isUnicornDomain}
                    cyberQuoteDomain={cyberQuoteDomain}
                    firstMediaDomain={firstMediaDomain}
                    selectedSchedule={selectedSchedule}
                    handleNextStep={handleNextStepConfirm}
                    requiredSchedule={requiredSchedule}
                    isArtofnumbersDomain={isArtofnumbersDomain}
                  />
                </Route>

                <Route exact path={`${path}/complete-profile`}>
                  <Wrapper mgBt>
                    <CompleteProfile
                      ref={ref}
                      course={course}
                      isEnrol={isEnrol}
                      history={history}
                      dispatch={dispatch}
                      schedule={schedule}
                      userInfo={userInfo}
                      currentUser={currentUser}
                      isAgbEducation={isAgbEducation}
                      isUnicornDomain={isUnicornDomain}
                      selectedSchedule={selectedSchedule}
                      firstMediaDomain={firstMediaDomain}
                      coursesDomain={coursesDomain}
                      allQuantDomain={allQuantDomain}
                      userPaymentMethod={userPaymentMethod}
                      isArtofnumbersDomain={isArtofnumbersDomain}
                      cyberQuoteDomain={cyberQuoteDomain}
                      isFlcDomain={isFlcDomain}
                      isPortalDomain={isPortalDomain}
                      domain={domain}
                      requiredSchedule={requiredSchedule}
                      isDigitalWorkForceDomain={isDigitalWorkForceDomain}
                      isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
                      handlePaymentMethodFreeCourse={
                        handlePaymentMethodFreeCourse
                      }
                      ascendoDomain={isAscendoDomain}
                    />
                  </Wrapper>
                </Route>

                <Route exact path={`${path}/billing`}>
                  <Elements stripe={stripePromise}>
                    <Billing
                      id={id}
                      ref={ref}
                      course={course}
                      userInfo={userInfo}
                      history={history}
                      dispatch={dispatch}
                      setAlert={setAlert}
                      schedules={schedules}
                      applicationFee={applicationFee}
                      userPaymentMethod={userPaymentMethod}
                      userPaymentByCourse={userPaymentByCourse}
                      isAllowApplicationFee={isAllowApplicationFee}
                      isClassCompareDomain={isClassCompareDomain}
                      isViaFrontiers={isViaFrontiers}
                      isUnicornDomain={isUnicornDomain}
                      selectedSchedule={selectedSchedule}
                      firstMediaDomain={firstMediaDomain}
                      isFlcDomain={isFlcDomain}
                      isPortalDomain={isPortalDomain}
                      currentUser={currentUser}
                      isArtofnumbersDomain={isArtofnumbersDomain}
                      allQuantDomain={allQuantDomain}
                      ascendoDomain={isAscendoDomain}
                      imadviserDomain={imadviserDomain}
                      cyberQuoteDomain={cyberQuoteDomain}
                      handlePaymentMethodFreeCourse={
                        handlePaymentMethodFreeCourse
                      }
                      allowSpecialPrice={allowSpecialPrice}
                      specialPrice={specialPrice}
                      requiredSchedule={requiredSchedule}
                      sfcPrices={sfcPrices}
                      priceOptions={priceOptions}
                      remainingAmount={remainingAmount}
                      complete={complete}
                      setComplete={setComplete}
                      therapadaDomain={therapadaDomain}
                      isBellDomain={isBellDomain}
                    />
                  </Elements>
                </Route>

                <Route exact path={`${path}/complete`}>
                  <Wrapper>
                    <Complete
                      refId={refId}
                      dispatch={dispatch}
                      course={course}
                      history={history}
                      cyberQuoteDomain={cyberQuoteDomain}
                      isUnicornDomain={isUnicornDomain}
                      firstMediaDomain={firstMediaDomain}
                      requiredSchedule={requiredSchedule}
                      isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
                      wfaDomain={wfaDomain}
                    />
                  </Wrapper>
                </Route>

                <Route exact path={`${path}/fail`}>
                  <Failed
                    course={course}
                    history={history}
                    requiredSchedule={requiredSchedule}
                    isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
                  />
                </Route>

                <Redirect to="/" />
              </Switch>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Checkout
