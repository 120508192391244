// eslint-disable-next-line
import produce from 'immer'
import * as constants from './constants'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'

export const initialState = {
  tags: null,
  slideTags: null,
  courseType: null,
  selectedSection: null,
  zoomLink: '',
  listUserAnswer: null,
  accountStripe: null,
  schedules: null,
  listOutcome: null,
  assessment: null,
  attachments: null,
  zooms: null,
  listUser: null,
  userData: null,
  userProfile: null,
  listSchedule: null,
  listUserByCourse: null,
  learningOutComeByUser: null,
  listSetOutComeStudents: null,
  listCustomOutcomeStudent: null,
  listSetOutcomeCustom: null,
  paymentStatus: null,
  questions: [],
  isAdminClient: false,
}

const persistConfig = {
  key: 'admin',
  storage: localStorage,
  whitelist: ['slideTags', 'tags'],
}

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      //POST COURSE
      case constants.CREATE_COURSE:
        break
      case constants.CREATE_COURSE_SUCCESS:
        break
      case constants.CREATE_COURSE_FAILED:
        break
      //POST SECTION
      case constants.CREATE_SECTION:
        break
      case constants.CREATE_SECTION_SUCCESS:
        break
      case constants.CREATE_SECTION_FAILED:
        break

      //POST LESSONS
      case constants.CREATE_LESSON:
        break
      case constants.CREATE_LESSON_SUCCESS:
        break
      case constants.CREATE_LESSON_FAILED:
        break

      //POST LESSONS
      case constants.CREATE_QUIZ:
        break
      case constants.CREATE_QUIZ_SUCCESS:
        break
      case constants.CREATE_QUIZ_FAILED:
        break
      //LOAD TAGS
      case constants.LOAD_TAGS:
        break
      case constants.LOAD_TAGS_SUCCESS:
        newState.tags = action.payload
        break
      case constants.LOAD_TAGS_FAILED:
        break
      //LOAD SLIDE TAGS
      case constants.LOAD_SLIDE_TAGS:
        break
      case constants.LOAD_SLIDE_TAGS_SUCCESS:
        newState.slideTags = action.payload
        break
      case constants.LOAD_SLIDE_TAGS_FAILED:
        break
      //LOAD COURSE TYPE
      case constants.LOAD_COURSE_TYPE:
        break
      case constants.LOAD_COURSE_TYPE_SUCCESS:
        newState.courseType = action.payload
        break
      case constants.LOAD_COURSE_TYPE_FAILED:
        break

      //CREATE ZOOM LINK
      case constants.CREATE_ZOOM_LINK:
        break
      case constants.CREATE_ZOOM_LINK_SUCCESS:
        newState.zoomLink = action.payload
        break
      case constants.CREATE_ZOOM_LINK_FAILED:
        break
      //SAVE ZOOM LINK
      case constants.SAVE_ZOOM_LINK:
        break
      case constants.SAVE_ZOOM_LINK_SUCCESS:
        break
      case constants.SAVE_ZOOM_LINK_FAILED:
        break

      //SAVE ZOOM LINK
      case constants.SET_GLOBAL_COURSE:
        break
      case constants.SET_GLOBAL_COURSE_SUCCESS:
        break
      case constants.SET_GLOBAL_COURSE_FAILED:
        break

      //SAVE ZOOM LINK
      case constants.LOAD_LIST_USER_ANSWER:
        break
      case constants.LOAD_LIST_USER_ANSWER_SUCCESS:
        newState.listUserAnswer = action.payload
        break
      case constants.LOAD_LIST_USER_ANSWER_FAILED:
        break
      //SET_ANSWER_COMPETENT
      case constants.SET_ANSWER_COMPETENT:
        break
      case constants.SET_ANSWER_COMPETENT_SUCCESS:
        break
      case constants.SET_ANSWER_COMPETENT_FAILED:
        break
      //MARK_ANSWER_COMPETENT
      case constants.MARK_ANSWER_COMPETENT:
        break
      case constants.MARK_ANSWER_COMPETENT_SUCCESS:
        break
      case constants.MARK_ANSWER_COMPETENT_FAILED:
        break
      //MARK_ANSWER_COMPETENT
      case constants.CREATE_ACCOUNT_STRIPE:
        break
      case constants.CREATE_ACCOUNT_STRIPE_SUCCESS:
        break
      case constants.CREATE_ACCOUNT_STRIPE_FAILED:
        break

      //MARK_ANSWER_COMPETENT
      case constants.GET_ACCOUNT_STRIPE:
        break
      case constants.GET_ACCOUNT_STRIPE_SUCCESS:
        newState.accountStripe = action.payload
        break
      case constants.GET_ACCOUNT_STRIPE_FAILED:
        break
      //MARK_ANSWER_COMPETENT
      case constants.ADD_SCHEDULE:
        break
      case constants.ADD_SCHEDULE_SUCCESS:
        break
      case constants.ADD_SCHEDULE_FAILED:
        break
      //MARK_ANSWER_COMPETENT
      case constants.GET_SCHEDULE:
        break
      case constants.GET_SCHEDULE_SUCCESS:
        newState.schedules = action.payload
        break
      case constants.GET_SCHEDULE_FAILED:
        break

      //LOAD_LIST_LEARNING_OUTCOME_SUCCESS
      case constants.LOAD_LIST_LEARNING_OUTCOME:
        break
      case constants.LOAD_LIST_LEARNING_OUTCOME_SUCCESS:
        newState.listOutcome = action.payload
        break
      case constants.LOAD_LIST_LEARNING_OUTCOME_FAILED:
        break

      //LOAD ATTACHMENT
      case constants.LOAD_ATTACHMENT:
        break
      case constants.LOAD_ATTACHMENT_SUCCESS:
        newState.attachments = action.payload
        break
      case constants.LOAD_ATTACHMENT_FAILED:
        break
      //MARK_ANSWER_COMPETENT
      case constants.LOAD_ASSESSMENT:
        break
      case constants.LOAD_ASSESSMENT_SUCCESS:
        newState.assessment = action.payload
        break
      case constants.LOAD_ASSESSMENT_FAILED:
        newState.assessment = null
        break
      //LOAD COURSE TYPE
      case constants.SET_SELECTED_SECTION:
        newState.selectedSection = action.payload
        break
      //MARK_ANSWER_COMPETENT
      case constants.LOAD_ZOOMS:
        break
      case constants.LOAD_ZOOMS_SUCCESS:
        newState.zooms = action.payload
        break
      case constants.LOAD_ZOOMS_FAILED:
        newState.zooms = null
        break

      //LOAD LIST USER
      case constants.LOAD_LIST_USER:
        break
      case constants.LOAD_LIST_USER_SUCCESS:
        newState.listUser = action.payload
        break
      case constants.LOAD_LIST_USER_FAILED:
        newState.listUser = null
        break
      //LOAD LIST USER
      case constants.GET_USER_DATA:
        break
      case constants.GET_USER_DATA_SUCCESS:
        newState.userData = action.payload
        break
      case constants.GET_USER_DATA_FAILED:
        newState.userData = null
        break

      //LOAD LIST USER
      case constants.GET_USER_PROFILE:
        break
      case constants.GET_USER_PROFILE_SUCCESS:
        newState.userProfile = action.payload
        break
      case constants.GET_USER_PROFILE_FAILED:
        newState.userProfile = null
        break

      //LOAD LIST USER
      case constants.GET_LIST_SCHEDULE_BY_EMAIL:
        break
      case constants.GET_LIST_SCHEDULE_BY_EMAIL_SUCCESS:
        newState.listSchedule = action.payload
        break
      case constants.GET_LIST_SCHEDULE_BY_EMAIL_FAILED:
        newState.listSchedule = null
        break
      //LOAD LIST USER BY COURSE AND SCHEDULE
      case constants.GET_USER_BY_COURSE_AND_SCHEDULE:
        break
      case constants.GET_USER_BY_COURSE_AND_SCHEDULE_SUCCESS:
        newState.listUserByCourse = action.payload
        break
      case constants.GET_USER_BY_COURSE_AND_SCHEDULE_FAILED:
        newState.listUserByCourse = null
        break
      //GET_LEARNING_OUTCOME_BY_USER
      case constants.GET_LEARNING_OUTCOME_BY_USER:
        break
      case constants.GET_LEARNING_OUTCOME_BY_USER_SUCCESS:
        newState.learningOutComeByUser = action.payload
        break
      case constants.GET_LEARNING_OUTCOME_BY_USER_FAILED:
        newState.learningOutComeByUser = null
        break

      //GET_LIST_SET_OUTCOME_STUDENTS
      case constants.GET_LIST_SET_OUTCOME_STUDENTS:
        break
      case constants.GET_LIST_SET_OUTCOME_STUDENTS_SUCCESS:
        newState.listSetOutComeStudents = action.payload
        break
      case constants.GET_LIST_SET_OUTCOME_STUDENTS_FAILED:
        newState.listSetOutComeStudents = null
        break

      //GET_LIST_CUSTOM_OUTCOME_STUDENT
      case constants.GET_LIST_OUTCOME_STUDENT:
        break
      case constants.GET_LIST_OUTCOME_STUDENT_SUCCESS:
        newState.listCustomOutcomeStudent = action.payload
        break
      case constants.GET_LIST_OUTCOME_STUDENT_FAILED:
        newState.listCustomOutcomeStudent = null
        break
      //GET_LIST_SET_CUSTOM_OUTCOME_STUDENT
      case constants.GET_LIST_SET_OUTCOME_CUSTOM:
        break
      case constants.GET_LIST_SET_OUTCOME_CUSTOM_SUCCESS:
        newState.listSetOutcomeCustom = action.payload
        break
      case constants.GET_LIST_SET_OUTCOME_CUSTOM_FAILED:
        newState.listSetOutcomeCustom = null
        break

      //GET_LIST_SET_CUSTOM_OUTCOME_STUDENT
      case constants.LOAD_PAYMENT_STATUS:
        break
      case constants.LOAD_PAYMENT_STATUS_SUCCESS:
        newState.paymentStatus = action.payload
        break
      case constants.LOAD_PAYMENT_STATUS_FAILED:
        newState.paymentStatus = null
        break

      case constants.SET_QUESTIONS:
        newState.questions = action.payload
        break
    }
  })

export default persistReducer(persistConfig, reducer)
