import React from 'react'
import { get } from 'lodash'

const Competent = ({
  handleSelectUser,
  item,
  questions,
  listUserAnswer,
  currentUser,
}) => {
  const listUserCompetent = get(listUserAnswer, `[${item}]`)
  const competent = get(listUserCompetent, '[0].user.answer.competent', null)
  const name = get(listUserCompetent, '[0].user.partner.name', '')

  return (
    <div
      className={`quiz__item-user ${
        currentUser === item ? 'quiz__item-user-active' : ''
      }`}
      onClick={() => handleSelectUser(item)}
    >
      <div className="quiz__item-name">{name}</div>
      {competent !== null && (
        <div className="quiz__item-status quiz__item-status-c">Marked</div>
      )}
    </div>
  )
}

export default Competent
