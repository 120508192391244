import React from 'react'
import { get } from 'lodash'
import styled from 'styled-components'

import images from 'Themes/Images'
import InputSmall from '../InputSmall'
import { checkInput } from 'utils/helper'
import { createCourse } from 'container/Admin/actions'
import { parseOptionsPrice } from '../../utils/helper'

const OptionPricingItemWrapper = styled.div`
  margin-right: 30px;
  margin-bottom: 24px;
`

const SFCOptionsPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
  .plus-icon {
    cursor: pointer;
    align-self: center;
  }
`

const SFCPriceItem = ({
  option,
  index,
  listOption,
  formik,
  course,
  dispatch,
  saveRef
}) => {
  const courseCode = get(option, 'course_code', '')
  const isEditing = get(option, 'is_edit', false)
  let priceOptions = parseOptionsPrice(get(option, 'price_options'))

  const handleEdit = (i) => {
    const newList = [...listOption]
    priceOptions[i].is_edit = true
    newList[index].price_options = JSON.stringify(priceOptions)
    formik.setFieldValue('sfc_prices', newList)
  }

  const handleChangeInput = (e, i) => {
    if (checkInput(e)) {
      const newList = [...listOption]
      const value = e.currentTarget.value
      const optionsPrice = [...priceOptions]
      optionsPrice[i]['price'] = value
      newList[index].price_options = JSON.stringify(optionsPrice)
      formik.setFieldValue('sfc_prices', newList)
    }
  }

  const handleChangeField = (e, i) => {
    const name = e.currentTarget.name
    const newList = [...listOption]
    const value = e.currentTarget.value
    if (name === 'course_code') {
      newList[index][name] = value
    } else {
      const optionsPrice = [...priceOptions]
      optionsPrice[i][name] = value
      newList[index].price_options = JSON.stringify(optionsPrice)
    }

    formik.setFieldValue('sfc_prices', newList)
  }

  const handleRemove = (isDeleteOptions, i) => {
    delete course.prices

    let payload = {}

    if (isDeleteOptions) {
      const sfcPrices = [...listOption]
      priceOptions = priceOptions.filter((item, index) => index !== i)
      sfcPrices[index].price_options = JSON.stringify(priceOptions)
      const listIdRemove = listOption.map((item) => item.id)
      payload = {
        ...course,
        sfc_prices: sfcPrices,
        old_sfc_price_ids: listIdRemove
      }
    } else {
      delete course.sfc_prices
      payload = { ...course, old_sfc_price_ids: [option.id] }
    }

    dispatch(createCourse(payload))
  }

  const handleEditCourseCode = () => {
    const newList = [...listOption]
    newList[index].is_edit = true
    formik.setFieldValue('sfc_prices', newList)
  }

  const handleAddMorePrice = () => {
    const newList = [...listOption]
    priceOptions.push({ title: '', price: 0, is_edit: true })
    newList[index]['price_options'] = JSON.stringify(priceOptions)
    formik.setFieldValue('sfc_prices', newList)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (
        saveRef?.current &&
        typeof saveRef?.current?.submitForm === 'function'
      ) {
        return saveRef?.current?.submitForm()
      }
    }
  }
  return (
    <OptionPricingItemWrapper>
      {isEditing ? (
        <input
          value={courseCode}
          name="course_code"
          className="input-small__label"
          onChange={handleChangeField}
          onKeyDown={handleKeyPress}
          placeholder="Enter Course Code"
        />
      ) : (
        <div
          className="input-small__text__wrapper"
          style={{ alignItems: 'flex-start' }}
        >
          <div className="input-small__text">
            <div style={{ color: '#042580' }}>{courseCode}</div>
          </div>
          <div>
            <img
              src={images.admin.pen_alt_active}
              className="input-small__pen"
              alt=""
              onClick={handleEditCourseCode}
            />
            <img
              src={images.admin.trash_icon}
              className="input-small__trash"
              alt=""
              onClick={() => handleRemove()}
            />
          </div>
        </div>
      )}

      <SFCOptionsPriceWrapper>
        {Array.isArray(priceOptions) &&
          priceOptions.map((item, index) => {
            const title = get(item, 'title', '')
            const price = get(item, 'price', '')
            const isEditTitle = get(item, 'is_edit', false)
            return (
              <div style={{ display: 'flex', marginTop: 10 }} key={index}>
                <InputSmall
                  isEdit={true}
                  editing={isEditTitle}
                  text={title}
                  iconLeft={images.admin.sgd}
                  onChange={(e) => handleChangeInput(e, index)}
                  index={index}
                  title={title}
                  handleEdit={() => handleEdit(index)}
                  value={price}
                  type="text"
                  handleChangeTitle={(e) => handleChangeField(e, index)}
                  handleRemove={() => handleRemove(true, index)}
                  isSFC
                />
              </div>
            )
          })}

        <div onClick={handleAddMorePrice} className="plus-icon">
          <img
            src={images.admin.plus_normal}
            alt=""
            style={{ paddingBottom: 3, paddingRight: 3 }}
          />{' '}
          <span className="quiz-addnew__text">Add SFC Price</span>
        </div>
      </SFCOptionsPriceWrapper>
    </OptionPricingItemWrapper>
  )
}

export default SFCPriceItem
