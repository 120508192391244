export const SLIDE_TYPE = {
  INFOGRAPHIC: 'infographic',
  VIDEO: 'video',
  PRESENTATION: 'presentation',
  WEBPAGE: 'webpage',
  STREAM: 'stream',
  SCORM: 'scorm',
}

export const ERROR_MESSAGE = 'This field is required.'

export const FILE_SIZE = 1000

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

export const SUPPORTED_FORMATS_DOCUMENT = [
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/zip',
  'application/x-zip-compressed',
]

export const SUPPORTED_FORMATS_DOCUMENT_LESSON = [
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
]

export const SUPPORTED_ALL_FORMATS = [
  'text/csv',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'application/x-zip-compressed',
]

export const initialQuestion = {
  question: '',
  sequence: 1,
  types: '',
  error: '',
}

export const initialChoicesQuestion = {
  question: '',
  sequence: 1,
  types: '',
  error: '',
  multiple_choices: '[{"order":0,"text":"","is_correct":false}]',
}

export const initialNational = [
  { value: '1', label: 'Singapore Citizen' },
  { value: '2', label: 'Permanent Resident' },
  { value: '3', label: 'Other' },
]

export const initialSalutation = [
  { value: '1', label: 'DR' },
  { value: '2', label: 'MR' },
  { value: '3', label: 'MRS' },
  { value: '4', label: 'MS' },
  { value: '5', label: 'MDM' },
  { value: '6', label: 'PROF' },
]

export const initialIDType = [
  { value: '1', label: 'NRIC' },
  { value: '2', label: 'FIN' },
  { value: '3', label: 'Passsport' },
]
export const initialLevel = [
  { value: '1', label: 'GCE N-Level' },
  { value: '2', label: 'GCE O-Level' },
  { value: '3', label: 'NITEC/ Higher NITEC' },
  { value: '4', label: 'GCE A-Level' },
  { value: '5', label: 'Diploma' },
  { value: '6', label: 'Degree' },
  { value: '7', label: 'Other' },
]

export const initialDay = [
  { value: 'Mon', label: 'Monday' },
  { value: 'Tue', label: 'Tuesday' },
  { value: 'Wed', label: 'Wednesday' },
  { value: 'Thu', label: 'Thursday' },
  { value: 'Fri', label: 'Friday' },
  { value: 'Sat', label: 'Saturday' },
  { value: 'Sun', label: 'Sunday' },
]

export const initialHourBreak = [
  { value: '1', label: '1 hour' },
  { value: '2', label: '2 hour' },
]

export const domainDev = {
  id: 1,
  create_date: '2020-08-21T12:31:26.53298Z',
  write_date: '2022-02-03T03:31:05.270236Z',
  name: 'BELLS Academic Group',
  domain: 'https://bells.hypeschools.com/',
  zoom_email: 'hello2@hypeschools.com',
  company_id: 5,
  user_id: 14,
  default_lang_id: 1,
  social_twitter: 'https://twitter.com/Odoo',
  social_facebook: 'https://www.facebook.com/Odoo',
  social_github: 'https://github.com/odoo',
  social_linkedin: 'https://www.linkedin.com/company/odoo',
  social_youtube: 'https://www.youtube.com/user/OpenERPonline',
  social_instagram: 'https://www.instagram.com/explore/tags/odoo/',
  company: {
    id: 5,
    create_date: '2020-08-21T12:30:14.404582Z',
    write_date: '2022-02-03T03:30:35.347323Z',
    name: 'AGB Education',
    email: 'pmet@agbeducation.com',
    account_id: '',
    partner_id: 49,
    currency_id: 37,
    charges_enabled: false,
    account_link_url: '',
  },
  sfc_key: '',
  sfc_pem: '',
  logo: 'https://lh3.googleusercontent.com/KCDdliFJLr4z05I_BS3-xFaGhr2u58NC2PVYRI_ZF3wTu78G0G8_3ZXnWxQuC0tfOnDsOrOv6YKPR7T38b7bWbt9p4fVQbgUfVJKi51HyBUpYxg',
  has_logo: false,
  banner:
    'https://lh3.googleusercontent.com/V0H5lLZ50oXRh5sFPtWtCbW7VP2F_DV7vIBAeIqngDd3SA0eGr-Nv4nwhYA-qGy-f0jnpr4V9f3oC5FWXVoqIrbU0ctx5poluznRnvNjxnGSGPM',
  page_title: '',
  page_description: '',
  picking_fields: '',
  allow_stripe_payment: false,
}

export const CUSTOM_COURSE = process.env.REACT_APP_ENV === 'develop' ? 295 : 111

export const CUSTOM_COURSE_2 =
  process.env.REACT_APP_ENV === 'develop' ? 295 : 340

export const fileUrl = `https://flc.hypeschools.com/web/content/13646`
