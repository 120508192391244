import React from 'react'
import styled from 'styled-components'
import images from '../../../assets/images'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { LoginSuccess } from 'container/Auth/actions'

const NavBarWrapper = styled.div`
  background-color: ${(props) => (props.checkoutPage ? '#f6f6f9' : '')};
  @media (max-width: 767px) {
    display: block;
  }
  div.search_wrap {
    display: flex;
  }
  h1 {
    color: #ffffff;
    font-weight: 600;
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    align-self: center;
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }
  .logout {
    text-decoration: underline;
  }
  h2.cms-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    img {
      margin-bottom: 2px;
    }
    span {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #5a6686;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
`

const Wrap = styled.div`
  display: flex;
  @media (max-width: 767px) {
    display: ${(props) =>
      props.cmsMode && !props.articlePage ? 'flex' : 'block'};
  }
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${(props) => (props.checkoutPage ? '1080px' : 'unset')};
  padding-top: ${(props) => (props.checkoutPage ? '30px' : 'unset')};
`

const NavTabWrapper = styled.div`
  display: flex;
  & > div {
    cursor: pointer;
    &:not(:last-child) {
      padding-right: 40px;
      @media (max-width: 767px) {
        padding-right: 10px;
      }
    }
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: ${(props) => (props.isFe ? '#5a6686' : '#fff;')};
  }
  .logged {
    & > span {
      font-weight: 600;
      font-size: 18px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
`

const NavBar = ({ cmsMode, isFe, checkoutPage, articlePage, privacy }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector(makeSelectCurrentUser())
  const handleClick = () => {
    if (checkoutPage) {
      return history.push('/')
    }
    if (privacy) {
      return history.push('/')
    }
    if (cmsMode && !isFe) {
      history.push('/cms')
    } else {
      history.push('/')
    }
  }

  const handleLogin = () => {
    history.push('/auth?login=true&student=true')
  }

  const handleLogout = () => {
    localStorage.removeItem('submittedAnswer')
    localStorage.removeItem('clientID')
    localStorage.removeItem('clientName')
    dispatch(LoginSuccess(null))
  }

  const renderNavTab = () => {
    if (checkoutPage) {
      return (
        <h2 className="cms-text" onClick={handleClick}>
          <img src={images.arrow_left} alt="" />{' '}
          <span>Back To Course Page</span>
        </h2>
      )
    }
    if (privacy) {
      return <h2 className="cms-text">Privacy</h2>
    }
    if (cmsMode && !isFe) {
      return <h2 className="cms-text">CMS</h2>
    }

    return (
      <NavTabWrapper isFe={isFe}>
        {/* {isFe && <div onClick={handleBrowse}>Browse</div>} */}
        {currentUser ? (
          <div className={`${isFe ? 'logged' : ''}`}>
            <span onClick={handleLogin}>
              Hi {get(currentUser, 'name', '')},&nbsp;
            </span>
            <span className="logout" onClick={handleLogout}>
              log out
            </span>
          </div>
        ) : (
          <div onClick={handleLogin}>Log in</div>
        )}
      </NavTabWrapper>
    )
  }

  return (
    <NavBarWrapper
      cmsMode={cmsMode}
      checkoutPage={checkoutPage}
      articlePage={articlePage}
      privacy={privacy}
    >
      <Wrap checkoutPage={checkoutPage} cmsMode={cmsMode} privacy={privacy}>
        <img
          src={
            cmsMode || privacy
              ? images.logo_cms
              : 'https://lh3.googleusercontent.com/giCuopZmzUbcFidpPKergjxUmlAhDd3QwD5PFDuNoUlXY7Q3mKKYYJaop8YZBDqhmRVzEDupz52vw4tJBa0auaC7llC-ryEzR_WdYdVl3rADNYY'
          }
          alt="ClassCompare"
          width="185"
          height="25"
          onClick={handleClick}
        />
        <div>
          {/* <SearchBar>
          <span className="icon_search">
            <img src={images.search}></img>
          </span>
          <input placeholder="Search..."></input>
        </SearchBar> */}
          {renderNavTab()}
        </div>
      </Wrap>
    </NavBarWrapper>
  )
}

export default NavBar
