import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import classNames from 'classnames'
import { setQuestions } from '../../container/Admin/actions'
import { cloneDeep } from 'lodash'
import images from '../../Themes/Images'

const ChoiceItemWrapper = styled.div`
  .correct_answer {
    margin: 10px auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .active {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 4px;
    top: 4px;
    background: #2c2c2d;
    border-radius: 50%;
  }
`

const Item = styled.div`
  width: 90%;
  background: ${(props) =>
    props.correct ? 'rgba(115, 208, 139, 0.08)' : '#fff'};
  border: 1px solid #e7ebf4;
  border-radius: 4px;
  padding-left: 40px;
  padding-right: 15px;
  margin-bottom: 10px;

  .order {
    position: absolute;
    top: 14px;
    left: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;

    font-size: 9px;
    text-transform: uppercase;
    color: #586a83;

    font-weight: bold;
  }
  .choice_input {
    width: 100%;
    padding: 10px 0;
    border: none;
    outline: none;
    background: none;
    color: #5a6686;
    font-family: 'Montserrat', sans-serif;

    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    &::placeholder {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      font-weight: 400;
      color: #bac1d3;
    }
  }
`

export const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
const ChoiceItem = ({
  choice,
  index,
  dispatch,
  questions,
  questionIndex,
  listChoice
}) => {
  const handleChangeText = ({ currentTarget }) => {
    const newQuestions = cloneDeep(questions)
    const newListChoice = cloneDeep(listChoice)
    const { value } = currentTarget

    newListChoice[index].text = value
    newQuestions[questionIndex].multiple_choices = JSON.stringify(newListChoice)
    dispatch(setQuestions(newQuestions))
  }

  const handleActiveAnswer = () => {
    const newQuestions = cloneDeep(questions)
    const newListChoice = cloneDeep(listChoice)

    newListChoice[index].is_correct = !newListChoice[index].is_correct
    newQuestions[questionIndex].multiple_choices = JSON.stringify(newListChoice)
    dispatch(setQuestions(newQuestions))
  }

  return (
    <ChoiceItemWrapper>
      <Row>
        <Col sm={20}>
          <Item correct={choice.is_correct}>
            <span className="order">{alphabet[choice.order]}</span>
            <input
              value={choice.text}
              placeholder="Type a choice"
              className="choice_input"
              onChange={handleChangeText}
            />
          </Item>
        </Col>
        <Col sm={4}>
          <div className="correct_answer" onClick={handleActiveAnswer}>
            <div>
              {choice.is_correct ? (
                <img src={images.correct_choice} />
              ) : (
                <img src={images.wrong_choice} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </ChoiceItemWrapper>
  )
}

export default ChoiceItem
