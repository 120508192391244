export const SET_SELECTED_SLIDE = 'SET_SELECTED_SLIDE'
export const SET_SELECTED_QUIZ = 'SET_SELECTED_QUIZ'

export const LOAD_LIST_COURSE = 'LOAD_LIST_COURSE'
export const LOAD_LIST_COURSE_SUCCESS = 'LOAD_LIST_COURSE_SUCCESS'
export const LOAD_LIST_COURSE_FAILED = 'LOAD_LIST_COURSE_FAILED'

export const LOAD_COURSE = 'LOAD_COURSE'
export const LOAD_COURSE_SUCCESS = 'LOAD_COURSE_SUCCESS'
export const LOAD_COURSE_FAILED = 'LOAD_COURSE_FAILED'

export const LOAD_SLIDES = 'LOAD_SLIDES'
export const LOAD_SLIDES_SUCCESS = 'LOAD_SLIDES_SUCCESS'
export const LOAD_ORIGINAL_SLIDES_SUCCESS = 'LOAD_ORIGINAL_SLIDES_SUCCESS'
export const LOAD_SLIDES_FAILED = 'LOAD_SLIDES_FAILED'

export const LOAD_SLIDE_DETAILS = 'LOAD_SLIDE_DETAILS'
export const LOAD_SLIDE_DETAILS_SUCCESS = 'LOAD_SLIDE_DETAILS_SUCCESS'
export const LOADING_SLIDE_DETAILS = 'LOADING_SLIDE_DETAILS'
export const LOAD_SLIDE_DETAILS_FAILED = 'LOAD_SLIDE_DETAILS_FAILED'

// load preview
export const LOAD_PREVIEW_BY_CHANNEL_ID = 'LOAD_PREVIEW_BY_CHANNEL_ID'
export const LOAD_PREVIEW_BY_CHANNEL_ID_SUCCESS =
  'LOAD_PREVIEW_BY_CHANNEL_ID_SUCCESS'
export const LOAD_PREVIEW_BY_CHANNEL_ID_FAILED =
  'LOAD_PREVIEW_BY_CHANNEL_ID_FAILED'

export const GET_DOMAIN_NAME = 'GET_DOMAIN_NAME'
export const GET_DOMAIN_NAME_SUCCESS = 'GET_DOMAIN_NAME_SUCCESS'
export const GET_DOMAIN_NAME_FAILED = 'GET_DOMAIN_NAME_FAILED'

// set preview
export const SET_PREVIEW_COMPLETE = 'SET_PREVIEW_COMPLETE;'

export const POST_USER_ANSWER = 'POST_USER_ANSWER'
export const POST_USER_ANSWER_SUCCESS = 'POST_USER_ANSWER_SUCCESS'
export const POST_USER_ANSWER_FAILED = 'POST_USER_ANSWER_FAILED'

export const LOAD_USER_ANSWER = 'LOAD_USER_ANSWER'
export const LOAD_USER_ANSWER_SUCCESS = 'LOAD_USER_ANSWER_SUCCESS'
export const LOAD_USER_ANSWER_FAILED = 'LOAD_USER_ANSWER_FAILED'

export const LOAD_LIST_CATEGORY = 'LOAD_LIST_CATEGORY'
export const LOAD_LIST_CATEGORY_SUCCESS = 'LOAD_LIST_CATEGORY_SUCCESS'
export const LOAD_LIST_CATEGORY_FAILED = 'LOAD_LIST_CATEGORY_FAILED'

export const LOAD_DOMAIN = 'LOAD_DOMAIN'
export const LOAD_DOMAIN_SUCCESS = 'LOAD_DOMAIN_SUCCESS'
export const LOAD_LIST_DOMAIN_SUCCESS = 'LOAD_LIST_DOMAIN_SUCCESS'
export const LOAD_DOMAIN_FAILED = 'LOAD_DOMAIN_FAILED'

export const LOAD_USER_LOCATION = 'LOAD_USER_LOCATION'
export const LOAD_USER_LOCATION_SUCCESS = 'LOAD_USER_LOCATION_SUCCESS'
export const LOAD_USER_LOCATION_FAILED = 'LOAD_USER_LOCATION_FAILED'

export const POST_ENROLL = 'POST_ENROLL'
export const POST_ENROLL_SUCCESS = 'POST_ENROLL_SUCCESS'
export const POST_ENROLL_FAILED = 'POST_ENROLL_FAILED'

export const UPDATE_ENROLL = 'UPDATE_ENROLL'
export const UPDATE_ENROLL_SUCCESS = 'UPDATE_ENROLL_SUCCESS'
export const UPDATE_ENROLL_FAILED = 'UPDATE_ENROLL_FAILED'

export const DELETE_ENROLL = 'DELETE_ENROLL'
export const DELETE_ENROLL_SUCCESS = 'DELETE_ENROLL_SUCCESS'
export const DELETE_ENROLL_FAILED = 'DELETE_ENROLL_FAILED'

export const GET_ANSWER_TIME = 'GET_ANSWER_TIME'
export const GET_ANSWER_TIME_SUCCESS = 'GET_ANSWER_TIME_SUCCESS'
export const GET_ANSWER_TIME_FAILED = 'GET_ANSWER_TIME_FAILED'

export const PUT_ANSWER_TIME = 'PUT_ANSWER_TIME'

export const GET_USER_PAYMENT_METHOD = 'GET_USER_PAYMENT_METHOD'
export const GET_USER_PAYMENT_METHOD_SUCCESS = 'GET_USER_PAYMENT_METHOD_SUCCESS'
export const GET_USER_PAYMENT_METHOD_FAILED = 'GET_USER_PAYMENT_METHOD_FAILED'

export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED'

export const GET_CUSTOMER_INFO = 'GET_CUSTOMER_INFO'
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS'
export const GET_CUSTOMER_INFO_FAILED = 'GET_CUSTOMER_INFO_FAILED'

export const SET_REF = 'SET_REF'

export const PUT_USER_LOG = 'PUT_USER_LOG'
export const PUT_USER_LOG_SUCCESS = 'PUT_USER_LOG_SUCCESS'
export const PUT_USER_LOG_FAILED = 'PUT_USER_LOG_FAILED'

export const GET_USER_LOG = 'GET_USER_LOG'
export const GET_USER_LOG_SUCCESS = 'GET_USER_LOG_SUCCESS'
export const GET_USER_LOG_FAILED = 'GET_USER_LOG_FAILED'

export const PREVENT_LOAD_RELATED_API = 'PREVENT_LOAD_RELATED_API'
