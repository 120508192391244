import React from 'react'
import { Col, Checkbox } from 'antd'
import InputTypePassword from 'component/InputTypePassword'
import images from 'Themes/Images'
import ClientSelect from '../../../components/ClientSelect'
import GroupSelect from '../../ClassManagement/GroupSelect'

const UserForm = ({
  formik,
  edit,
  error,
  userData,
  clients,
  userClients,
  handleCheckAdmin,
  handleCheckTrainer,
  currentUserClients,
  isAscendoDomain,
  isAdmin,
  folders,
  selectedGroup,
  setSelectedGroup,
}) => {
  return (
    <Col md={24}>
      <div>
        <div className="u-manage__head">
          <h1 className="u-manage__heading">{edit ? 'Edit' : 'Create'} User</h1>
        </div>
        <div className="create-user">
          <form
            onSubmit={formik.handleSubmit}
            style={{ marginBottom: '30px' }}
            autoComplete="off"
          >
            {error && <div className="login-form__error">{error}</div>}

            <div className="form__field">
              <label htmlFor="email" className="form__label">
                NAME (ACCORDING TO ID/PASSPORT)
              </label>
              <input
                name="create_name"
                className="form__input"
                placeholder="Enter your full name"
                autoComplete="off"
                onChange={formik.handleChange}
                value={formik.values.create_name}
              />
              {formik.touched.create_name && formik.errors.create_name && (
                <div className="form__error">{formik.errors.create_name}</div>
              )}
            </div>

            <div className="form__field">
              <label htmlFor="email" className="form__label">
                EMAIL ADDRESS
              </label>

              <input
                name="create"
                className={`form__input ${error && 'form__input__error'}`}
                placeholder="Enter your email address"
                autoComplete="off"
                onChange={(e) =>
                  formik.setFieldValue(
                    'create',
                    e.currentTarget.value.trim().toLowerCase()
                  )
                }
                value={formik.values.create}
              />
              {formik.touched.create && formik.errors.create && (
                <div className="form__error">{formik.errors.create}</div>
              )}
            </div>

            <div className="form__field">
              <label htmlFor="email" className="form__label">
                {userData ? 'CHANGE' : 'CREATE'} YOUR PASSWORD
              </label>

              <InputTypePassword
                placeholder="Enter your password"
                onChange={(e) =>
                  formik.setFieldValue(
                    'create_password',
                    e.currentTarget.value.trim()
                  )
                }
                autoComplete="new-password"
                value={formik.values.create_password}
                name="create_password"
                className="form__input"
                superAdminClass="eye"
              />
              {formik.touched.create_password &&
                formik.errors.create_password && (
                  <div className="form__error">
                    {formik.errors.create_password}
                  </div>
                )}
            </div>

            {isAscendoDomain && (
              <div className="form__field">
                <label htmlFor="email" className="form__label">
                  Select Client
                </label>

                <ClientSelect
                  clients={clients}
                  formik={formik}
                  user={userData}
                  currentUserClients={currentUserClients}
                  userClients={userClients}
                />
              </div>
            )}

            {isAscendoDomain && (
              <div className="form__field">
                <label htmlFor="email" className="form__label">
                  Select Group
                </label>

                <GroupSelect
                  multiple={false}
                  value={selectedGroup}
                  setValue={setSelectedGroup}
                  onChange={(values) => {
                    setSelectedGroup(values)
                  }}
                  data={folders}
                />
              </div>
            )}

            {isAdmin && (
              <div style={{ display: 'flex', marginBottom: 20 }}>
                <Checkbox
                  onChange={handleCheckAdmin}
                  checked={formik.values.admin}
                >
                  Admin
                </Checkbox>

                <Checkbox
                  onChange={handleCheckTrainer}
                  checked={formik.values.trainer}
                >
                  Trainer
                </Checkbox>
              </div>
            )}

            <button
              type="submit"
              className={`primary-btn `}
              style={{ width: '100%' }}
            >
              <span>{userData ? 'Save' : 'Create'}</span>
              <img src={images.course.arrow_right_active} alt="" />
            </button>
          </form>
        </div>
      </div>
    </Col>
  )
}

export default UserForm
