import React, { useState } from 'react'
import styled from 'styled-components'
import { deleteCourse, updateCourse } from './api'
import { get } from 'lodash'
import { loadListCourse } from 'container/Home/actions'
import { Modal } from 'antd'
import images from 'Themes/Images'
import { showError, showSuccess } from 'utils/notification'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import dayjs from 'dayjs'

const TableActionsWrapper = styled.div`
  width: 100%;
  background-color: #ff0d6b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0 30px;
  color: #fff;
  @media (max-width: 767px) {
    display: block;
    padding: 20px 10px;
    height: unset;
  }
`

const ButtonAction = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  background-color: #ffffff;
  color: ${(props) => (props.active ? '#ec376c' : '#00122A')};
  padding: 6px 12px;
  margin-right: 14px;
  border-radius: 4px;
`

const TableActions = ({
  selectedCourses,
  dispatch,
  page,
  perPage: perpage,
  isSelectAll,
  setSelectedCourses,
  handleSelectAll
}) => {
  const [showDelete, setShowDelete] = useState(false)
  const handleDelete = async () => {
    dispatch(showGlobalLoading())
    if (!isSelectedCourses) {
      dispatch(hideGlobalLoading())
      return showError('You need to select at least one course')
    }
    const requests = []

    for (let i = 0; i < selectedCourses.length; i++) {
      const courseId = get(selectedCourses, `[${i}].id`)
      requests.push(deleteCourse(courseId))
    }
    await Promise.all(requests)
    dispatch(
      loadListCourse({
        admin: true,
        filter: 'sequence',
        page,
        perpage
      })
    )
    setShowDelete(false)
    showSuccess(
      `Deleted ${selectedCourses.length} ${
        selectedCourses.length > 1 ? 'courses' : 'course'
      }!`
    )
    setSelectedCourses([])
    dispatch(hideGlobalLoading())
  }

  const handlePublish = async () => {
    dispatch(showGlobalLoading())
    if (!isSelectedCourses) {
      dispatch(hideGlobalLoading())
      return showError('You need to select at least one course')
    }
    const requests = []

    for (let i = 0; i < selectedCourses.length; i++) {
      const course = get(selectedCourses, `[${i}]`)
      const id = get(course, 'id')
      requests.push(
        updateCourse(
          {
            ...selectedCourses[i],
            last_published: dayjs().format()
          },
          id,
          true
        )
      )
    }
    await Promise.all(requests)
    dispatch(loadListCourse({ admin: true, filter: 'sequence', page, perpage }))
    showSuccess(
      `Published ${selectedCourses.length} ${
        selectedCourses.length > 1 ? 'courses' : 'course'
      }!`
    )
    setSelectedCourses([])
    dispatch(hideGlobalLoading())
  }
  const handleUnpublish = async () => {
    dispatch(showGlobalLoading())
    if (!isSelectedCourses) {
      dispatch(hideGlobalLoading())
      return showError('You need to select at least one course')
    }
    const requests = []

    for (let i = 0; i < selectedCourses.length; i++) {
      const course = get(selectedCourses, `[${i}]`)
      const id = get(course, 'id')
      requests.push(updateCourse({ ...selectedCourses[i] }, id, false))
    }
    await Promise.all(requests)
    dispatch(loadListCourse({ admin: true, filter: 'sequence', page, perpage }))
    showSuccess(
      `Unpublished ${selectedCourses.length} ${
        selectedCourses.length > 1 ? 'courses' : 'course'
      }!`
    )
    setSelectedCourses([])
    dispatch(showGlobalLoading())
  }

  const isSelectedCourses = () => selectedCourses.length === 0
  const handleCancel = () => setShowDelete(false)
  return (
    <>
      <TableActionsWrapper>
        <div>
          {selectedCourses.length !== 0
            ? `${selectedCourses.length} Selected`
            : ''}
        </div>
        <div className="btn_wrapper">
          <ButtonAction
            active
            onClick={() => {
              if (isSelectedCourses()) {
                return showError('You need to select at least one course')
              } else {
                setShowDelete(true)
              }
            }}
          >
            Delete
          </ButtonAction>
          <ButtonAction onClick={handlePublish}>Publish</ButtonAction>
          <ButtonAction onClick={handleUnpublish}>Unpublish</ButtonAction>
          <ButtonAction onClick={handleSelectAll}>
            {isSelectAll ? 'Unselect All' : 'Select All'}
          </ButtonAction>
        </div>
      </TableActionsWrapper>
      <Modal visible={showDelete} footer={null} onCancel={handleCancel}>
        <div className="ld-modal">
          <div className="ld-modal__icon">
            <img src={images.admin.trash_icon} alt="" width="25" height="29" />
          </div>
          <div className="ld-modal__warning">
            Are you sure you want to delete all these courses?
          </div>
          <div className="ld-modal__label">
            You will not be able to recover this later.
          </div>
          <div className="ld-modal__btn__ok">
            <button className="delete-btn" onClick={handleDelete}>
              <img
                src={images.admin.trash_white_icon}
                alt=""
                style={{ fill: '#fff' }}
                className="trash-icon"
              />
              <span>Delete forever</span>
            </button>
          </div>
          <div className="ld-modal__btn__cancel">
            <button className="tertiary-btn" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default TableActions
