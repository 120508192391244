import React, { useEffect, useState } from 'react'
import images from 'Themes/Images'
import { get } from 'lodash'
import useSignatureHook from 'src/hooks/useSignatureHook'
import './styles.scss'
import { useSelector } from 'react-redux'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import config from 'src/global-config'
import axios from 'axios'
import { isTrainerRole, isAdminRole } from 'utils/userUtils'

const ZoomLink = ({ meeting, partnerId, courseId, scheduleId, zooms }) => {
  const zoomToken = get(zooms, '[0].token', '')
  const [zakToken, setZakToken] = useState('')
  const currentUser = useSelector(makeSelectCurrentUser())
  const meetingUrl = get(meeting, 'url', '')
  const meetingId = get(meeting, 'meeting_id')
  const zoomId = get(meeting, 'zoom_id')
  const password = get(meeting, 'password', '')
  const conferenceUrl = get(meeting, 'conference_url', '')
  const userGroups = get(currentUser, 'user_groups', [])
  const isTrainer = isTrainerRole(userGroups)
  const isAdmin = isAdminRole(userGroups)
  const isAdminOrTrainer = isAdmin || isTrainer

  const { signature } = useSignatureHook(meetingId, zoomId)

  useEffect(() => {
    const refreshToken = async (token) => {
      try {
        const requestURL = `${config.baseUrl}/api/notifications/zooms/${zoomId}/refresh-token`
        const res = await axios.get(requestURL, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        setZakToken(res?.data?.data?.token)
      } catch (error) {
        console.log('🚀 ~ error:', error)
      }
    }
    const getZKToken = async (token) => {
      try {
        const requestURL = `${config.baseUrl}/api/notifications/zooms/${zoomId}/zak`
        const res = await axios.get(requestURL, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        setZakToken(res?.data?.data.token)
      } catch (error) {
        await refreshToken()
        console.log('🚀 ~ error:', error)
      }
    }
    if (zoomToken) {
      getZKToken(zoomToken)
    }
  }, [zoomToken])

  const handleOpenZoomApp = () => {
    window.open(meetingUrl, '_blank')
  }

  const handleOpenZoomBrowser = () => {
    window.open(
      config.conferenceUrl +
        `?meeting_id=${meetingId}&is_debug=true${
          isAdminOrTrainer ? `&zak_token=${zakToken}` : ''
        }&key=${get(signature, 'key', '')}&signature=${get(
          signature,
          'signature',
          ''
        )}&user_name=${get(currentUser, 'name', '')}&user_email=${get(
          currentUser,
          'username'
        )}&password=${password}&leave_url=${
          window.location.href
        }&is_debug=true`,
      '_blank'
    )
  }

  return (
    <div className="zoom">
      <div className="zoom__icon">
        <img src={images.admin.zoom_icon} alt="Zoom Icon" />
      </div>
      {meetingUrl ? (
        <div className="zoom__text-class">Class is in session</div>
      ) : (
        <div className="zoom__text">You have no class right now</div>
      )}
      <div>
        {meetingUrl && (
          <div className="zoom__app" onClick={handleOpenZoomApp}>
            <div>
              <img
                src={images.sidebar.zoom_copy}
                alt=""
                className="zoom__copy"
              />
            </div>
            <div>Start meeting in Zoom</div>
          </div>
        )}
        {conferenceUrl && (
          <div className="zoom__browser" onClick={handleOpenZoomBrowser}>
            Or <span>Open in Browser</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default ZoomLink
