import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import './styles.scss'
import { makeSelectDomain } from 'container/Home/selectors'
import NavbarClassCompare from 'src/customs/ClassCompare/src/components/NavbarClassCompare'
import { DOMAIN } from 'src/routes'
import { DOMAIN_ID } from '../../routes'
import { useLocation } from 'react-router'
import { parse } from 'query-string'

const PrivacyPolicy = () => {
  const { search } = useLocation()
  const { school_id, custom } = parse(search)
  const domain = useSelector(makeSelectDomain())
  const schoolId = school_id ? parseInt(school_id) : ''

  const isClassCompare =
    get(domain, 'domain', '') === DOMAIN.COURSES_CLASS_COMPARE

  const isUnicornDomain =
    get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN ||
    schoolId === DOMAIN_ID.UNICORN_DOMAIN

  const isFirstMediaDomain =
    get(domain, 'domain', '') === DOMAIN.FIRSTMEDIA ||
    schoolId === DOMAIN_ID.FIRSTMEDIA

  const isFlcDomain =
    get(domain, 'domain', '') === DOMAIN.FLC || schoolId === DOMAIN_ID.FLC

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    }, 1000)
  }, [])

  if (isUnicornDomain) {
    return (
      <div className="privacy">
        {schoolId && <NavbarClassCompare privacy />}
        <h1 className="privacy__heading">Terms and Conditions</h1>
        <h2 className="privacy__section">Entry requirement:</h2>
        <ul className="privacy__list-style-2">
          <li>- &nbsp; GCE ‘O’ Level English D7 and Above</li>
          <li>
            - &nbsp; or Workplace Literacy at WPL Level 5 or above (any 2 from
            the 4 WPL SOAs);{' '}
          </li>
          <li>- &nbsp; and Workplace Numeracy at WPN Level 5 or above.</li>
        </ul>
        <h2 className="privacy__section">Disclaimer</h2>
        <ul className="privacy__list-style-2">
          <li>
            - &nbsp; Unicorn Financial Solutions Pte Limited (UFS) will exercise
            all possible care to run the confirmed courses, however, in the
            event of unforeseen circumstances, UFS reserves the right to cancel
            or defer the courses, and/or change venue, lecturer and fees. UFS
            will take all reasonable efforts to notify participants of changes,
            should they happen.
          </li>
          <li>
            - &nbsp; UFS reserves the right to reschedule and cancel classes
            with less than a minimum number of 20 students per class.
          </li>
        </ul>
        <h2 className="privacy__section">
          Registration, Enrolment & Confirmation
        </h2>
        <ul className="privacy__list-style-2">
          <li>- &nbsp; There is a registration fee which is non-refundable.</li>
          <li>
            - &nbsp; UFS will ascertain the applicant’s suitability for the
            course by reviewing the application and/or interviewing the
            applicant.
          </li>
          <li>
            - &nbsp; UFS reserves the right to accept and to reject any
            application.
          </li>
          <li>
            - &nbsp; Confirmation of enrolment for the course is subject to
            applicant’s suitability for the course.
          </li>
        </ul>
        <h2 className="privacy__section">Graduation Requirements</h2>
        <ul className="privacy__list-style-2">
          <li>
            - &nbsp; The requirements to graduate from the course are 1)
            attained 75% attendance, and 2) passed all the assessments of the
            course.
          </li>
        </ul>
        <h2 className="privacy__section">Withdrawal and Fee Liability</h2>
        <ul className="privacy__list-style-2">
          <li>
            - &nbsp; Trainees seeking course withdrawal are to submit course
            withdrawal form or email to UFS.
          </li>
        </ul>
        <h2 className="privacy__section">Course Deferment Period</h2>
        <ul className="privacy__list-style-2">
          <li>
            - &nbsp; The maximum duration allowed for course deferment is 6
            months from the first scheduled course commencement date.
          </li>
        </ul>
        <h2 className="privacy__section">Certificate Collection</h2>
        <ul className="privacy__list-style-2">
          <li>
            - &nbsp; Upon completion of the course, trainees will be able to
            receive a certificate.
          </li>
        </ul>
        <h2 className="privacy__section-2">
          ● &nbsp; The course and the website provided are for informational
          purposes only. You acknowledge and agree that any information posted
          in the Course, in the Materials, or on the Website is not intended to
          be legal advice, financial advice and no fiduciary relationship has
          been created between you and us. You further agree that your
          participation in the Course is at its own risk. We do not assume
          responsibility or liability for any advice or other information given
          in the Course, in the Materials or the website.
        </h2>
        <h1 className="privacy__heading" style={{ marginTop: 30 }}>
          PDPA consent form
        </h1>
        <h2 className="privacy__section-2">
          ● &nbsp; In compliance with the Personal Data Protection Act ("PDPA"),
          Unicorn Financial Solutions Pte Limited (UFS) seeks your consent to
          collect and use your personal data (i.e. Name, NRIC/Passport number,
          contact numbers, mailing and email addresses) in order to maintain the
          student enrolment registers and to disclose such personal data to the
          relevant educational authorities where necessary ("Purposes").
        </h2>

        <h2 className="privacy__section-2">
          ● &nbsp; UFS will also collect and use your personal data to provide
          you with information on our activities such as upcoming events,
          seminars, workshops, conferences and training programmes organised by
          UFS and its affiliated organisations which may be relevant to you
          ("Services").
        </h2>

        <h2 className="privacy__section-2">
          ● &nbsp; UFS respects your privacy and assures that your personal data
          will be kept securely according to PDPA.
        </h2>

        <h2 className="privacy__section-2">
          ● &nbsp; I hereby give my acknowledgement and consent to UFS to use my
          personal data for the aforesaid Purposes and Services. In the event
          that I have registered my Singapore telephone numbers(s) with the Do
          Not Call Registry and wish to withhold or withdraw my consent to UFS
          in respect of receiving telephone calls and/or SMS, I endeavour to
          provide sufficient notice in writing to UFS of such as soon as
          reasonably practicable. I further agree to indemnify UFS against any
          financial penalties imposed by the Personal Data Protection Commission
          or any court of law in Singapore as a direct or indirect result of my
          failure to inform UFS of my registration with the Do Not Call
          Registry.
        </h2>
        <h2 className="privacy__section-2">
          ● &nbsp; I agree that my consent will remain in place until my
          withdrawal by officially notifying UFS in writing.
        </h2>
      </div>
    )
  }

  if (isFirstMediaDomain) {
    return (
      <div className="privacy">
        {schoolId && <NavbarClassCompare privacy />}
        <h1 className="privacy__heading">Terms and Conditions</h1>
        <h2 className="privacy__section">1. Schedule and Amendments</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. First Media Design School (FMDS) will take reasonable effort to
            run the confirmed courses.
          </li>
          <li>
            b. In the event of unforeseen circumstances, FMDS reserves the right
            to cancel or to amend the course arrangement
            <br />
            including, but not limited to, the venue, time, and lecturer.
          </li>
          <li>
            c. FMDS will take reasonable effort to notify all participants
            involved of any changes made, should they happen.
          </li>
        </ul>
        <h2 className="privacy__section">2. Registration and Enrolment</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. FMDS will ascertain the applicant’s suitability for the course by
            reviewing the application <br />
            or interviewing the applicant.
          </li>
          <li>
            b. Confirmation of enrolment is subject to full payment of course
            fee and applicant’s suitability for the course.
          </li>
        </ul>
        <h2 className="privacy__section">3. SSG Subsidy</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. FMDS will apply for the SSG subsidies on the behalf of the
            learners.
          </li>
          <li>
            b. In the event that SSG concluded that the learner is ineligible
            for the subsidy,
          </li>
          <li>
            &nbsp; &nbsp;&nbsp; i. the self-sponsored learner will be liable for
            the full course fee, and
          </li>
          <li>
            &nbsp; &nbsp;&nbsp; ii. the sponsoring company of the
            company-sponsored learner will be liable for the full course fee.
          </li>
        </ul>
        <h2 className="privacy__section">4. Refund</h2>
        <ul className="privacy__list-style-2">
          <li>
            Once a course has been confirmed and the fees paid for, the
            following policy will apply: % of Full Fees If learner’s written
            notice of withdrawal is received
          </li>
          <li>
            90% more than [10] working days before the Course Commencement Date
          </li>
          <li>
            50% before, but not more than [10] working days before the Course
            Commencement Date 0% on and after the Course Commencement Date
          </li>
        </ul>
        <h2 className="privacy__section">5. Compulsory Requirements</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. Learners are required to attain no less than 75% attendance and
            passed all assessments in the course.
          </li>
          <li>
            b. Learners who do not meet these requirements will be required to
            pay the full course fees at a non-subsidised rate.
          </li>
        </ul>
        <h2 className="privacy__section">6. Course Deferment</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. There is a deferment fee $53.50 per course. This fee is
            non-refundable.
          </li>
          <li>
            b. Any application for deferment after course commencement will be
            subjected to Management approval.
          </li>
          <li>
            c. The maximum duration allowed for course deferment is 3 months
            from the first scheduled course start date.
          </li>
          <li>
            d. Learners seeking deferment of more than 3 months are deemed to
            have withdrawn from the course and are liable to pay the full
            non-subsidised course fee.
          </li>
        </ul>
        <h2 className="privacy__section">7. Assessment Result Appeal</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. There is a result appeal fee of $53.50 per course. This fee is
            non-refundable.
          </li>
          <li>
            b. Appeal to review the assessment outcome must be done within 5
            calendar days after the release of result.
          </li>
          <li>
            c. Appeal outcomes will be communicated within 5 working days from
            submission. Application Form Version 4.2 Page 2 of 2
          </li>
        </ul>
        <h2 className="privacy__section">
          8. Rescheduling of Assessment & Reassessment
        </h2>
        <ul className="privacy__list-style-2">
          <li>
            a. The fee for the first scheduled assessment is included in the
            course fee.
          </li>
          <li>
            b. Subsequent rescheduling/reassessment fee of $53.50 per module.
            This fee is non-refundable.
          </li>
          <li>
            c. Any application for reassessment will be subjected to FMDS
            approval.
          </li>
          <li>
            d. The date for reassessment shall be no more than 1 month from
            original assessment date.
          </li>
        </ul>

        <h2 className="privacy__section">9. Certificate</h2>
        <ul className="privacy__list-style-2">
          <li>
            Upon graduation from WSG course, learners will be able to retrieve
            their electronic certificates via SSG portal.
          </li>
        </ul>

        <h2 className="privacy__section">10. Damage to School Property</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. FMDS will in its sole discretion identify if any loaned equipment
            (e.g laptop, etc) is returned incomplete or defective
          </li>
          <li>
            b. Learner is liable to pay the cost as determined by FMDS for
            repairing or replacing the incomplete or defective equipment
          </li>
        </ul>

        <h2 className="privacy__section">
          11. Personal Data Protection Statement
        </h2>
        <ul className="privacy__list-style-2">
          <li>
            a. Personal data, including but not limited to photographic images,
            videos, audio clips which FMDS collect from you
            <br />
            may be used for training matters such as sending courses details,
            administration, and course-related surveys.
          </li>
          <li>
            b. FMDS reserves the rights to use any learner projects, images and
            other forms of media taken during the course,
            <br />
            including but not limited to photographic images, video and audio
            clips for marketing and promotional purposes.
          </li>
        </ul>

        <h2 className="privacy__section">12. Clarification or Disagreement</h2>
        <ul className="privacy__list-style-2">
          <li>
            a. FMDS takes a strong stand against any defamatory comments or
            submission of unsubstantiated claims against the school. It will not
            hesitate to take legal action against anyone who make unfounded
            claims or misleading statements.
          </li>
          <li>
            b. FMDS reserves the right to refuse and/or terminate any
            registration and enrolment without prior notice if any of these
            terms and conditions is violated or if FMDS decide in its sole
            discretion that it would be in its best interest to do so.
          </li>
          <li>
            c. FMDS may vary these Terms and Conditions at any time if deemed
            appropriate in its best interest.
          </li>
          <li>
            d. For any clarification or disagreement pertaining to the course,
            do feedback at feedback@firstmedia.edu.sg.
          </li>
        </ul>
      </div>
    )
  }

  if (isFlcDomain) {
    if (custom) {
      return (
        <div className="privacy" style={{ minHeight: '80vh' }}>
          {schoolId && <NavbarClassCompare privacy />}
          <h1
            className="privacy__heading"
            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
          >
            Terms and Conditions
          </h1>
          <h2
            className="privacy__section"
            style={{ textDecoration: 'underline' }}
          >
            A) Payment of Course fees
          </h2>
          <ul className="privacy__list-style-2">
            <li>
              1. Your seat for the workshop will be reserved upon receipt of the
              registration & payment. The company reserves the right to decline
              any registration without giving any reasons.
            </li>
            <li>
              2. Confirmation of the workshop is subject to a minimum group
              size. An e-mail confirmation will be sent to the participants at
              least 3 days before the date of the workshop.
            </li>
            <li>
              3. No refund of the registration fees will be allowed for
              cancellation/withdrawal of registration one week before the date
              of the workshop save for cancellation or postponement of the
              workshop. Participants must set aside the 3 days to complete the
              workshop failing which there will be a clawback of the subsidized
              course fees from the participants..No refund, whether full or
              partial, or replacement sessions will be made for absentees during
              the workshop duration save for medical reasons or extenuating
              circumstances.
            </li>
          </ul>

          <h2
            className="privacy__section"
            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
          >
            B) PDPA
          </h2>
          <ul className="privacy__list-style-2">
            <li>
              By your registration, you consent to the collection, use and
              disclosure of your personal data as well as the print screens and
              recording of the Zoom meetings to us and we only collect and use
              your personal data and the recordings & print screens which are
              relevant and required for audit purposes, workshop promotions as
              well as keeping you updated about future workshops, resources,
              courses or events that may be of interest to you as our clients.
            </li>
          </ul>

          {/* <h2 className="privacy__heading-2" style={{ marginBottom: 10 }}>
            Financial Life Coaching Pte Ltd reserves the rights to amend the
            above terms and conditions from time to time where necessary.
          </h2>
          <h2 className="privacy__heading-2" style={{ marginBottom: 10 }}>
            Materials given in this course including the presentation slides,
            videos, model excel templates, and model files are copyrighted.
            Reproduction and distribution of any of these materials without the
            written permission of its owner, AllQuant, is prohibited.
          </h2>
          <h2 className="privacy__heading-2" style={{ marginBottom: 10 }}>
            Copyright ©️ 2021 AllQuant. All Rights Reserved.
          </h2> */}
        </div>
      )
    }
    return (
      <div className="privacy">
        {schoolId && <NavbarClassCompare privacy />}
        <h1
          className="privacy__heading"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          Terms and Conditions
        </h1>
        <h2 className="privacy__heading-2">
          By signing up for the 3-Day workshop, you agree to be bound by the
          following terms & conditions:
        </h2>
        <h2
          className="privacy__section"
          style={{ textDecoration: 'underline' }}
        >
          A) Payment of Course fees & Refunds
        </h2>
        <ul className="privacy__list-style-2">
          <li>
            1. For Cash payment for{' '}
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              Self-Sponsored participants
            </span>{' '}
            , the net amount should be paid to “Financial Life Coaching Pte Ltd”
            via bank transfer or PayNow to:
          </li>
          <li>Financial Life Coaching Pte Ltd</li>
          <li>
            <span style={{ fontWeight: 'bold' }}>DBS Bank Ltd</span>{' '}
          </li>
          <li>
            <span style={{ fontWeight: 'bold' }}>
              Bank Account No: 027-002238-3
            </span>{' '}
          </li>
          <li>or</li>
          <li>
            <span style={{ fontWeight: 'bold' }}>
              PayNow to: UEN NO: 198101881G
            </span>
          </li>
          <li>
            2. For{' '}
            <span style={{ fontWeight: 'bold' }}>
              Company sponsored participants,
            </span>{' '}
            Financial Life Coaching Pte Ltd will liaise directly with the
            respective companies for the payment arrangements for the net
            amount.
          </li>
          <li>
            3. Your seat for the workshop will be reserved upon receipt of the
            registration & payment. The company reserves the right to decline
            any registration without giving any reasons.
          </li>
          <li>
            4. Confirmation of the workshop is subject to a minimum group size.
            An e-mail confirmation will be sent to the participants at least 3
            days before the date of the workshop.
          </li>
          <li>
            5. No refund of the registration fees will be allowed for
            cancellation/withdrawal of registration one week before the date of
            the workshop save for cancellation or postponement of the workshop.
            No refund, whether full or partial, or replacement sessions will be
            made for absentees during the workshop duration save for medical
            reasons or extenuating circumstances.
          </li>
        </ul>
        <h2
          className="privacy__section"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          B) IBF Standards Training Scheme (IBF-STS) Funding Criteria
        </h2>
        <ul className="privacy__list-style-2">
          <li>
            1. All self-sponsored participants/Sponsoring Companies are bound by
            the terms and conditions as laid down by the relevant government
            agencies.
          </li>
          <li>
            2. The Self-Sponsored participant/Sponsoring Company will be liable
            to pay the full course fees to Financial Life Coaching Pte Ltd
            and/or the relevant Agencies if the funding application claim is
            rejected because the participant/Sponsoring Company fails to meet
            the requirements set out below:
          </li>
          <li>
            a) the participant is not a Singaporean or Permanent Resident
            physically based in Singapore;
          </li>
          <li>
            b) the participant fails to complete the course successfully and
            pass the assessment. All participants who failed the assessment are
            entitled to re-take the assessments again.
          </li>
        </ul>

        <h2
          className="privacy__section"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          C) Changes & Cancellation
        </h2>
        <ul className="privacy__list-style-2">
          <li>
            Financial Life Coaching Pte Ltd reserves the rights to make changes
            to the Course Program, date, time, venue or cancel a course due to
            unforeseen circumstances. All efforts will be made to inform
            participants promptly of the changes and a full refund will be made
            for such cancellations.
          </li>
        </ul>

        <h2
          className="privacy__section"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          D) Important Disclaimers
        </h2>
        <ul className="privacy__list-style-2">
          <li>
            1. Financial Life Coaching Pte Ltd & its training partner (AllQuant)
            are an educational provider and not investment or financial
            advisers. All the information, materials and services rendered
            <span style={{ fontWeight: 'bold' }}>in this course</span>
            and <span style={{ fontWeight: 'bold' }}>after the course</span> are
            provided for educational or informational purposes only. They are
            not intended to be, nor shall they be construed as, investment or
            financial advice, an offer, or a solicitation of an offer, to buy or
            sell an interest in any investment product.
          </li>

          <li>
            2. Nothing{' '}
            <span style={{ fontWeight: 'bold' }}>in this course</span> and{' '}
            <span style={{ fontWeight: 'bold' }}>after the course </span>or any
            of our services constitutes accounting, regulatory, tax, investment
            or other advice. All investment strategies and inputs taught in this
            Course are given in absence of consideration for your suitability
            towards them. Please consult your investment adviser on your
            suitability before running our investment strategies.
          </li>
          <li>
            3. All investments are subject to investment risks including the
            possible loss of the principal amount invested. Any performance
            shown in the course or on our Site (or affiliated Sites), whether
            live historical, hypothetical or modelled, is not necessarily
            indicative nor a guarantee on future performance. You should make
            your own assessment of the relevance, accuracy and adequacy of the
            information and consult your independent advisers where necessary.
          </li>
          <li>
            4. While the information provided in this course and{' '}
            <span style={{ fontWeight: 'bold' }}>after the course</span> is
            believed to be reliable, Financial Life Coaching and its training
            partner (AllQuant)/the trainers make no representation or warranty,
            whether expressed or implied, and accept no responsibility for its
            completeness, accuracy or reliability.
          </li>
          <li>
            5. Under no circumstances will Financial Life Coaching Pte Ltd and
            its training partner (AllQuant) and the trainers be liable for any
            losses whatsoever with respect to use of the content in this Course
            and after the Course, its Site (or affiliated Sites) and their
            products and services, whether directly or indirectly.
          </li>
        </ul>

        <h2
          className="privacy__section"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          E) PDPA
        </h2>
        <ul className="privacy__list-style-2">
          <li>
            1) By your registration, you agree to be included as a member of the
            Workshop What's App Group that will be set up to coordinate the
            running of the Workshop, Q & A etc.;
          </li>
          <li>
            2) By your registration, you consent to the collection, use and
            disclosure of your personal data to us and we only collect and use
            your personal data which is relevant and required for purpose of
            audit or informing you about coaching clinics, future workshops,
            resources, courses or events or software tools that may be of
            interest to you as our clients.
          </li>
          <li>
            3) By your registration, you consent to the use and release of the
            Zoom video recordings and/or print screen images for audit purposes
            and workshop promotions;
          </li>
        </ul>

        <h2 className="privacy__heading-2" style={{ marginBottom: 10 }}>
          Financial Life Coaching Pte Ltd reserves the rights to amend the above
          terms and conditions from time to time where necessary.
        </h2>
        <h2 className="privacy__heading-2" style={{ marginBottom: 10 }}>
          Materials given in this course including the presentation slides,
          videos, model excel templates, and model files are copyrighted.
          Reproduction and distribution of any of these materials without the
          written permission of its owner, AllQuant, is prohibited.
        </h2>
        <h2 className="privacy__heading-2" style={{ marginBottom: 10 }}>
          Copyright ©️ 2021 AllQuant. All Rights Reserved.
        </h2>
      </div>
    )
  }

  if (isClassCompare) {
    return (
      <div className="privacy">
        <NavbarClassCompare privacy />
        <h1 className="privacy__heading" style={{ marginTop: 50 }}>
          PRIVACY POLICY
        </h1>
        <h2 className="privacy__section">PERSONAL DATA</h2>
        <ol type="1" className="privacy__list-style-2">
          <li className="show-li">
            As used in this Notice:
            <br />
            “customer” means an individual who (a) has contacted us through any
            means to find out more about any goods or services we provide, or
            (b) may, or has, entered into a contract with us for the supply of
            any goods or services by us; and “personal data” means data, whether
            true or not, about a customer who can be identified:
            <br />
            (a) from that data; or (b) from that data and other information to
            which we have or are likely to have access.
          </li>
          <li className="show-li">
            Depending on the nature of your interaction with us, some examples
            of personal data which we may collect from you include your name and
            identification information such as your NRIC number, contact
            information such as your address, email address or telephone number,
            nationality, gender, date of birth, marital status, photographs and
            other audio-visual information, employment information and financial
            information such as credit card numbers, debit card numbers or bank
            account information.
          </li>
          <li className="show-li">
            Other terms used in this Notice shall have the meanings given to
            them in the PDPA (where the context so permits).
          </li>
        </ol>
        <h2 className="privacy__section">
          COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA
        </h2>
        <ol start="4" className="privacy__list-style-2">
          <li className="show-li">
            We generally do not collect your personal data unless (a) it is
            provided to us voluntarily by you directly or via a third party who
            has been duly authorised by you to disclose your personal data to us
            (your “authorised representative”) after (i) you (or your authorised
            representative) have been notified of the purposes for which the
            data is collected, and (ii) you (or your authorised representative)
            have provided written consent to the collection and usage of your
            personal data for those purposes, or (b) collection and use of
            personal data without consent is permitted or required by the PDPA
            or other laws. We shall seek your consent before collecting any
            additional personal data and before using your personal data for a
            purpose which has not been notified to you (except where permitted
            or authorised by law).
            <ul className="privacy__list-style-2">
              <li className="show-li">
                We may collect and use your personal data for any or all of the
                following purposes: (a) performing obligations in the course of
                or in connection with our provision of the goods and/or services
                requested by you;
                <br />
                (b) verifying your identity;
                <br />
                (c) responding to, handling, and processing queries, requests,
                applications, complaints, and feedback from you;
                <br />
                (d) managing your relationship with us;
                <br />
                (e) processing payment or credit transactions;
                <br />
                (f) sending your marketing information about our goods or
                services including notifying you of our marketing events,
                initiatives and promotions, lucky draws, membership and rewards
                schemes and other promotions;
                <br />
                (g) complying with any applicable laws, regulations, codes of
                practice, guidelines, or rules, or to assist in law enforcement
                and investigations conducted by any governmental and/or
                regulatory authority;
                <br />
                (h) any other purposes for which you have provided the
                information;
                <br />
                (i) transmitting to any unaffiliated third parties including our
                third party service
                <br />
                providers and agents, and relevant governmental and/or
                regulatory authorities,
                <br />
                whether in Singapore or abroad, for the aforementioned purposes;
                and
                <br />
                (j) any other incidental business purposes related to or in
                connection with the above.
              </li>
              <li className="show-li">
                We may disclose your personal data:
                <br /> (a) where such disclosure is required for performing
                obligations in the course of or in connection with our provision
                of the goods or services requested by you; or
                <br />
                (b) to third party service providers, agents and other
                organisations we have engaged to perform any of the functions
                listed in clause 5 above for us.
              </li>
              <li className="show-li">
                The purposes listed in the above clauses may continue to apply
                even in situations where your relationship with us (for example,
                pursuant to a contract) has been terminated or altered in any
                way, for a reasonable period thereafter (including, where
                applicable, a period to enable us to enforce our rights under
                any contract with you).
              </li>
            </ul>
          </li>
        </ol>
        <h2 className="privacy__section">WITHDRAWING YOUR CONSENT</h2>
        <ul className="privacy__list-style-2">
          <li className="show-li">
            The consent that you provide for the collection, use and disclosure
            of your personal data will remain valid until such time it is being
            withdrawn by you in writing. You may withdraw consent and request us
            to stop using and/or disclosing your personal data for any or all of
            the purposes listed above by submitting your request in writing or
            via email to our Data Protection Officer at the contact details
            provided below.
          </li>
          <li className="show-li">
            Upon receipt of your written request to withdraw your consent, we
            may require reasonable time (depending on the complexity of the
            request and its impact on our relationship with you) for your
            request to be processed and for us to notify you of the consequences
            of us acceding to the same, including any legal consequences which
            may affect your rights and liabilities to us. In general, we shall
            seek to process your request within ten (10) business days of
            receiving it.
          </li>
          <li className="show-li">
            Whilst we respect your decision to withdraw your consent, please
            note that depending on the nature and scope of your request, we may
            not be in a position to continue providing our goods or services to
            you and we shall, in such circumstances, notify you before
            completing the processing of your request. Should you decide to
            cancel your withdrawal of consent, please inform us in writing in
            the manner described in clause 8 above.
          </li>
          <li className="show-li">
            Please note that withdrawing consent does not affect our right to
            continue to collect, use and disclose personal data where such
            collection, use and disclose without consent is permitted or
            required under applicable laws.
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className="privacy">
      <h1 className="privacy__heading">Privacy Policy</h1>

      <h2 className="privacy__section">1. Introduction</h2>
      <p className="privacy__text">
        This Privacy Policy explains how Hypeschools and its clients and
        affiliates, handle information collected and received in the course of
        your use of our websites, mobile application and services (collectively,
        the “<span className="privacy__bold">Services</span>”). It also governs
        the collection, use and disclosure of your information. 
      </p>

      <h2 className="privacy__section">
        2. Information we collect and receive
      </h2>
      <p className="privacy__text">
        We may collect and receive information from you in the following
        situations:
      </p>
      <ul className="privacy__list-style">
        <li>
          When you register for an account with us (“
          <span className="privacy__bold">Account</span>”).
        </li>
        <li>
          If you contact us, for example, with a query or to report a problem.
        </li>
      </ul>
      <p className="privacy__text">
        You are under no obligation to provide the information to us. However,
        if you choose to withhold the information or to revoke permission for us
        to receive the information, we may be unable to provide certain aspects
        of our Services to you.
      </p>
      <p className="privacy__text">
        By providing us with any information relating to a third party (e.g.
        information of your spouse, children, parents, and/or employees), you
        represent to us that you have validly obtained the consent of the third
        party to provide us with their information for the respective purposes.
      </p>
      <p className="privacy__text">
        We may collect and receive the following information. Please note that
        not all of our Services collect all of the information listed below:
      </p>
      <ul className="privacy__list-style">
        <li>
          Profile information such as, name, identification number, gender,
          email address, profile photo, mobile number, date of birth.
        </li>
        <li>
          Any other information disclosed by you in any of the above-mentioned
          methods and/or through the use our Services.
        </li>
        <li>Aggregate data collected through the use of our Services.</li>
        <li>
          Any communications between you and another user of our Services.
        </li>
      </ul>

      <h2 className="privacy__section">3. Your interactions with others</h2>
      <p className="privacy__text">
        Our Services offer features which allow users to connect and communicate
        in groups which are public or semi-public, and to share information with
        each other to complete a transaction. Please use common sense and
        exercise good judgement when sharing information in these groups or with
        others. We generally do not monitor or remove the information which you
        share and such information may remain available on our Services after
        your Account has been deactivated, terminated or is otherwise closed.
        You are responsible for the information you choose to share in these
        groups or with other users.
      </p>

      <h2 className="privacy__section">
        4. Why we collect the information and how we use it
      </h2>
      <p className="privacy__text">
        We may use the information collected and received from you for the
        following purposes:
      </p>
      <ul className="privacy__list-style">
        <li>
          To verify your identity, update our records and generally maintain
          your Account with us.
        </li>
        <li>Allow clients to provide courses and services to you.</li>
        <li>To process transactions.</li>
        <li>To process or arrange payment.</li>
        <li>
          To process or resolve any dispute arising from or in connection with
          any transactions, in accordance with our Terms of Service.
        </li>
        <li>
          To detect or monitor any frauds or other prohibited content,
          businesses or activities.   
        </li>
        <li>Responding to your queries and resolving complaints.</li>
        <li>Responding to your queries and resolving complaints.</li>
        <li>
          Carry out polls, surveys, analysis and research on how our Services
          are being used and how we can improve them.
        </li>
        <li>
          To send you marketing and/or promotional materials on the latest
          products and services of Hypeschools and its clients that you have
          subscribed to. These updates will be sent by email and/or through push
          notifications and you can choose to unsubscribe from these marketing
          communications via the link in the email you have received
          and/or through the “Settings” page of your Account. These marketing
          communications may also be communicated to you through text messages
          and/or voice calls if you have indicated that you wish to receive such
          updates through these methods. For more information on anonymous
          advertising, including to understand what opting-out means and your
          opt out choices, please see the Section 5 below.
        </li>
      </ul>

      <h2 className="privacy__section">
        5. Who we share your information with
      </h2>
      <p className="privacy__text">
        We may share information about you in the following ways:
      </p>
      <ul className="privacy__list-style">
        <li>
          When you register for an Account, the information which you make
          available on your profile may be viewed by Hypeschools clients which
          you have subscribed to.
        </li>
        <li>
          We may share the information with third party service providers who
          assist us in performing certain aspects of our Services on our behalf,
          such as processing transactions, payment, fulfilling requests for
          information, receiving and sending communications, updating marketing
          lists, analysing data, providing support services or in other tasks.
          Such service providers will only have access to your personal
          information to the extent necessary to perform their functions.
        </li>
        <li>
          We may share the information with our related and associate companies
          and affiliates for the purposes of performing certain aspects of our
          Services and for the purposes as set out in this Privacy Policy.
        </li>
        <li>
          We may share the information with our potential partners, investors
          and other parties with a view to a potential business partnership,
          collaboration, joint venture or otherwise in furtherance of our
          business.
        </li>
        <li>
          We may share any information collected in accordance with this Privacy
          Policy with: (i) governmental and other regulatory authorities or the
          courts in any jurisdiction; (ii) any third party claimants or
          potential third party claimants; or (iii) your card issuing bank,
          financial institution and/or payment service provider, to process or
          resolve any chargeback, payment reversal and/or dispute arising from
          or in connection with a transaction using our Services in accordance
          with our Terms of Service.
        </li>
      </ul>
      <p className="privacy__text">
        Other third party service providers we work with includes:
      </p>
      <ul className="privacy__list-style">
        <li>
          <a
            href="https://stripe.com/en-sg/privacy"
            className="privacy__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stripe 
          </a>
           - payment service provider
        </li>
      </ul>
      <p className="privacy__text">
        You may click on the names of these third parties to access their
        privacy policies.
      </p>
      <p className="privacy__text">
        We respect your privacy and we 
        <span className="privacy__bold">will not</span> sell your personal
        information to any third party. We may disclose data and aggregate
        statistics about users of our Services and sales to prospective
        partners, advertisers, sponsors and other reputable third parties in
        order to describe our Services, deliver targeted advertisements or for
        other lawful purposes, but these data and statistics will not include
        information which can be used to identify you.
      </p>

      <h2 className="privacy__section">6. Transfer of information</h2>
      <p className="privacy__text">
        By using our Services, you authorise us to use your information in
        Singapore, the United States, Taiwan and other countries where
        Hypeschools operates for the purposes mentioned above. We will ensure
        that your information is transferred in accordance with this Privacy
        Policy and protected in accordance with applicable laws on personal data
        protection (including, but not limited to, the Personal Data Protection
        Act 2012 of Singapore).
      </p>
      <p className="privacy__text">
        If you are a user located in Australia, the following information will
        apply to you:
      </p>
      <ul className="privacy__list-style">
        <li>your information will no longer be governed by Australian law;</li>
        <li>you will not be able to seek redress under Australian law; and</li>
        <li>
          foreign laws will govern the handling of information collected and
          received in the course of your use of our Services and also governs
          the collection, use and disclosure of your information.
        </li>
      </ul>
      <h2 className="privacy__section">7. Protecting your information</h2>
      <p className="privacy__text">
        The security of your information is important to us. We have security
        measures in place to protect against the loss, misuse and alteration of
        information under our control.  We also follow generally accepted
        industry standards to protect the information transmitted to us over the
        Internet, both during transmission and once we receive it. Sensitive
        information (such as credit card numbers) are encrypted using secure
        socket layer technology (SSL). However, no method of transmission over
        the Internet, or method of electronic storage, is 100% secure.
        Therefore, while we strive to use commercially acceptable means to
        protect your information, we cannot guarantee its absolute security.
      </p>
      <p className="privacy__text">
        It is important that you protect against unauthorised access of your
        Account and information by choosing your password carefully, and keeping
        your password and Account secure (e.g. by signing out after using our
        Services).
      </p>

      <h2 className="privacy__section">8. Tracking technologies</h2>
      <p className="privacy__text">
        We use tracking technologies on our Services. These enable us to
        understand how you use our Services which, in turn, helps us to provide
        and improve our Services. However, the tracking technologies do not
        access, receive or collect information which can be used to identify
        you. We have listed below some examples of the tracking technologies
        used on our Services:
      </p>
      <ul className="privacy__list-style">
        <li>
          Cookies. A cookie is a small data file sent from a website to your
          browser that is stored on your device. Cookies are used for a number
          of purposes, including to display the most appropriate content based
          on your interests and activity on our Services, estimate and report
          our Service's total audience size and traffic, and conduct research to
          improve our Services. You can configure your device's settings to
          reflect your preference to accept or reject cookies. If you reject all
          cookies, you will not be able to take full advantage of our Services. 
        </li>
        <li>
          Pixels. We may from time to time use certain{' '}
          <a
            href="https://www.facebook.com/legal/terms/businesstools"
            className="privacy__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook Business Tools 
          </a>{' '}
           such as the {' '}
          <a
            href="https://www.facebook.com/business/help/742478679120153?id=1205376682832142"
            className="privacy__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook Pixel
          </a>{' '}
          . This tool allows Hypeschools to keep track of what users do after
          they see or click on a Facebook advertisement and enables us to
          monitor the effectiveness of Facebook ads for purposes of statistics
          and market research. Hypeschools will be able to better understand and
          deliver ads and make them more relevant to you. The collected data
          remains anonymous and Hypeschools cannot see the personal data of any
          individual user. 
        </li>
        <li>
          However, the collected data is saved and processed by Facebook.
          Facebook may be able to connect the data with your Facebook account
          and use the data for their own advertising purposes and in accordance
          with Facebook’s {' '}
          <a
            href="https://www.facebook.com/about/privacy/"
            className="privacy__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Data Use Policy
          </a>{' '}
          . Facebook has ultimate control of the information gathered through
          the Facebook Business Tools and communications. You can opt-out of
          Facebook’s use of cookies and Facebook Pixel through settings on your
          Facebook Account or withdraw your consent {' '}
          <a
            href="https://www.facebook.com/ds/preferences/?entry_product=ad_settings_screen&expand_ad_settings=1"
            className="privacy__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{' '}
          .
        </li>
      </ul>

      <h2 className="privacy__section">9. Changes to this Privacy Policy</h2>
      <p className="privacy__text">
        We reserve the right to make changes to this Privacy Policy at any time
        and all changes will be posted here. If we believe that the changes are
        material, we will notify you of the changes by posting a notice on our
        Services or by email. You are responsible for reviewing the changes
        which we make this Privacy Policy. Your continued use of our Services
        constitutes your acceptance of the updated Privacy Policy.
      </p>

      <h2 className="privacy__section">10. Third party sites and resources</h2>
      <p className="privacy__text">
        Our Services may, from time to time, contain links to external sites or
        resources which are operated by third parties. We have no control over
        the content and privacy practices of such sites or resources. You are
        advised to review the privacy policies of these sites and resources
        operated by third parties and understand how your information may be
        used by those third parties.
      </p>

      <h2 className="privacy__section">11. Contact us</h2>
      <p className="privacy__text">
        If you have any questions, complaints, concerns or comments on our
        Privacy Policy, we welcome you to contact us by sending an email to
        hello@hypeschools.com. Your indication at the subject header would
        assist us in attending to your email speedily by passing it on to the
        relevant staff in our organisation. For example, you could insert the
        subject header as "Accessing Personal Data".  
      </p>

      <h2 className="privacy__section">12. Conflict</h2>
      <p className="privacy__text">
        Please kindly note that where this Privacy Policy has been translated to
        any other non-English language, in the event of any inconsistencies in
        the meaning of any provision of the English language text of this
        Privacy Policy, the English language text shall prevail.
      </p>
      <p className="privacy__text">Last updated on 8 October 2020</p>
    </div>
  )
}

export default PrivacyPolicy
