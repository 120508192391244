'use strict'

function API_1484_11(slide, slide_type) {
  this.values = {}

  this.Initialize = function () {
    return 'true'
  }
  this.SetValue = function (element, value) {
    console.log('SetValue', element)
    console.log('value', value)
    this.values[element] = value
    if (
      element == 'cmi.completion_status' &&
      ['completed', 'passed'].includes(value)
    ) {
    }
    return 'true'
  }
  this.GetValue = function (element) {
    console.log('GetValue', element)
    return this.values[element]
  }
  this.GetLastError = function () {
    return 0
  }
  this.GetErrorString = function (errorCode) {
    console.log('errorCode', errorCode)
    return 'error string'
  }
  this.GetDiagnostic = function (errorCode) {
    return 'diagnostic string'
  }
  this.Commit = function () {
    console.log('Commit')
    this.GetValue('')
    return 'true'
  }
  this.Terminate = function () {
    console.log('Terminate')
    return 'true'
  }
}

function API(slide, slide_type) {
  this.values = {}

  this.LMSInitialize = function () {
    return 'true'
  }
  this.LMSSetValue = function (element, value) {
    console.log('element', element)
    console.log('value', value)
    this.values[element] = value
    // rpc.query({
    //   route: '/slide/slide/set_session_info',
    //   params: {
    //     slide_id: slide,
    //     element: element,
    //     value: value
    //   }
    // })
    if (
      element == 'cmi.completion_status' &&
      ['completed', 'passed'].includes(value)
    ) {
      // rpc
      //   .query({
      //     route: '/slides/slide/set_completed_scorm',
      //     params: {
      //       slide_id: slide,
      //       completion_type: value
      //     }
      //   })
      //   .then((data) => {
      //     var elem = document.querySelectorAll(
      //       '#o_wslides_lesson_aside_slide_check_' + slide
      //     )
      //     elem
      //       .removeClass('fa-circle text-600')
      //       .addClass('text-success fa-check-circle')
      //     var channelCompletion = data.channel_completion
      //     var completion = Math.min(100, channelCompletion)
      //     document
      //       .querySelectorAll('.progress-bar')
      //       .css('width', completion + '%')
      //     document.querySelectorAll('.text-white-50').text(completion + ' %')
      //   })
    }
    return 'true'
  }
  this.LMSGetValue = function (element) {
    console.log('LMSGetValue', element)
    return this.values[element]
  }
  this.LMSGetLastError = function () {
    return 0
  }
  this.LMSGetErrorString = function (errorCode) {
    return 'error string'
  }
  this.LMSGetDiagnostic = function (errorCode) {
    return 'diagnostic string'
  }
  this.LMSCommit = function () {
    console.log('LMSCommit')
    this.LMSGetValue('')
    return 'true'
  }
  this.LMSFinish = function () {
    console.log('LMSFinish')
    return 'true'
  }
}

export default function initialScorm(scormVersion, isAdmin) {
  if (isAdmin) {
    window.API = new API('', 'scorm')
    window.API_1484_11 = new API_1484_11('', 'scorm')
  } else {
    if (scormVersion === 'scorm11') {
      window.API = new API('', 'scorm')
    }
    if (scormVersion === 'scorm2004') {
      window.API_1484_11 = new API_1484_11('', 'scorm')
    }
  }
}
