import React from 'react'
import { Modal, Button } from 'antd'
import { get } from 'lodash'
import images from 'Themes/Images'
import Input from 'component/Input'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ERROR_MESSAGE } from 'utils/constants'
import {
  hideForgotPasswordModal,
  showGlobalLoading,
  hideGlobalLoading
} from '../../actions'
import config from 'src/global-config'
import { showSuccess, showError } from 'utils/notification'

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(ERROR_MESSAGE)
})

const ForgotPasswordModal = ({ dispatch, showForgotPasswordModal }) => {
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())
      try {
        const request = `${config.baseUrl}/api/users/reset-password`
        const res = await fetch(request, {
          method: 'POST',
          body: JSON.stringify({ login: values.email })
        })
        const data = await res.json()
        const error =
          get(data, 'errors.errors.login[0]', '') ||
          get(data, 'errors.message', '')
        if (error) {
          formik.setFieldError('email', error)
          dispatch(hideGlobalLoading())
          return showError(error)
        }

        showSuccess('Please check your mail box to reset your password')
        dispatch(hideForgotPasswordModal())
        dispatch(hideGlobalLoading())
      } catch (error) {
        console.log('error', error)
        dispatch(hideGlobalLoading())
      }
    }
  })

  const handleCancel = () => {
    dispatch(hideForgotPasswordModal())
  }
  return (
    <Modal
      visible={showForgotPasswordModal}
      footer={null}
      onCancel={handleCancel}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="">
          <div className="ld-modal__warning">Forgot Password</div>
          <div className="ld-modal__label">
            <Input
              name="email"
              placeHolder="Enter Email Address"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue(
                  'email',
                  e.currentTarget.value.trim().toLowerCase()
                )
              }
              value={formik.values.email}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div className="ld-modal__btn__ok">
              <button className="delete-btn" type="submit">
                <img
                  src={images.admin.trash_white_icon}
                  alt=""
                  style={{ fill: '#fff' }}
                  className="trash-icon"
                />
                <span>Send Email</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ForgotPasswordModal
