import { createSelector } from 'reselect'

/**
 * Direct selector to the authorize state domain
 */

const selectHomeDomain = (state) => state.home
export const makeSelectSelectedSlide = () =>
  createSelector(selectHomeDomain, (subState) => subState.selectedSlide)

export const makeSelectSelectedQuiz = () =>
  createSelector(selectHomeDomain, (subState) => subState.selectedQuiz)

export const makeSelectListCourse = () =>
  createSelector(selectHomeDomain, (subState) => subState.listCourse)

export const makeSelectCourse = () =>
  createSelector(selectHomeDomain, (subState) => subState.course)

export const makeSelectSlides = () =>
  createSelector(selectHomeDomain, (subState) => subState.slides)

export const makeSelectSlideDetails = () =>
  createSelector(selectHomeDomain, (subState) => subState.slideDetails)

export const makeSelectPreviewBySlideId = () =>
  createSelector(selectHomeDomain, (subState) => subState.previews)

export const makeSelectUserAnswer = () =>
  createSelector(selectHomeDomain, (subState) => subState.userAnswer)

export const makeSelectLessonLength = () =>
  createSelector(selectHomeDomain, (subState) => subState.lessonLength)

export const makeSelectDomain = () =>
  createSelector(selectHomeDomain, (subState) => subState.domain)

export const makeSelectUserLocation = () =>
  createSelector(selectHomeDomain, (subState) => subState.userLocation)

export const makeSelectAnswerTime = () =>
  createSelector(selectHomeDomain, (subState) => subState.answerTime)

export const makeSelectUserPaymentMethod = () =>
  createSelector(selectHomeDomain, (subState) => subState.userPaymentMethod)

export const makeSelectUserInfo = () =>
  createSelector(selectHomeDomain, (subState) => subState.userInfo)

export const makeSelectSlidesOriginal = () =>
  createSelector(selectHomeDomain, (subState) => subState.slidesOriginal)

export const makeSelectListDomain = () =>
  createSelector(selectHomeDomain, (subState) => subState.listDomain)

export const makeSelectProfile = () =>
  createSelector(selectHomeDomain, (subState) => subState.profile)

export const makeSelectRefId = () =>
  createSelector(selectHomeDomain, (subState) => subState.refId)

export const makeSelectListCategory = () =>
  createSelector(selectHomeDomain, (subState) => subState.listCategory)

export const makeSelectUserLog = () =>
  createSelector(selectHomeDomain, (subState) => subState.userLog)

export const makeSelectUserLogCurrentState = () =>
  createSelector(selectHomeDomain, (subState) => subState.userLogCurrentState)

export const makeSelectPreventLoadRelatedApi = () =>
  createSelector(selectHomeDomain, (subState) => subState.preventLoadRelatedApi)
