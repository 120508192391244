const images = {
  search: require('../assets/images/search.svg'),
  homeBg: require('../assets/images/homebg.svg'),
  logo: require('../assets/images/logo.svg'),
  logo_cms: require('../assets/images/logo_cms.svg'),
  logo_reviewer: require('../assets/images/logo_reviewer.svg'),
  logo_reviewer_2: require('../assets/images/logo_reviewer_2.svg'),
  logo_checkout: require('../assets/images/logo_checkout.svg'),
  banner: require('../assets/images/banner.svg'),
  banner_finance: require('../assets/images/banner_finance.png'),
  banner_marketing: require('../assets/images/banner_marketing.png'),
  banner_creative: require('../assets/images/banner_creative.png'),
  list_course_banner: require('../assets/images/listcourse_banner.svg'),
  course_detail_sample: require('../assets/images/course_detail_sample.svg'),
  course_sample: require('../assets/images/course_sample.svg'),
  cat_sample: require('../assets/images/cat_sample.svg'),
  creative: require('../assets/images/creative.png'),
  finance: require('../assets/images/finance.png'),
  lifestyle: require('../assets/images/lifestyle.png'),
  marketing: require('../assets/images/marketing.png'),
  miscellaneous: require('../assets/images/miscellaneous.png'),
  technology: require('../assets/images/technology.png'),
  linkedin: require('../assets/images/linkedin.svg'),
  facebook: require('../assets/images/facebook.svg'),
  twitter: require('../assets/images/twitter.svg'),
  playBtn: require('../assets/images/playbtn.svg'),
  playBtnSmall: require('../assets/images/playbtn_small.svg'),
  arrow_left: require('../assets/images/arrow_left.svg')
}

export default images
