import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'

const Css = styled.div`
  background: #fff;
  min-height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 24px;
`
const SimpleLoading = () => {
  return (
    <Css>
      <Spin size="large" />
    </Css>
  )
}
export default SimpleLoading
