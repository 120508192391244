import React from 'react'
import { Modal } from 'antd'
import { useSelector } from 'react-redux'

import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import { hideMarkingModal } from '../../actions'
import { markAnswerCompetent } from 'container/Admin/actions'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { makeSelectSlideDetails } from 'container/Home/selectors'
import { get } from 'lodash'
import { format } from 'utils/helper'
import { showError } from 'utils/notification'
import axiosInstance from 'utils/axiosInstance'
import config from 'src/global-config'
import './styles.scss'

const MarkingModal = ({
  dispatch,
  userAnswer,
  showMarkingModal,
  selectedSchedule,
  remarks,
}) => {
  const adminUser = useSelector(makeSelectCurrentUser())
  const slideDetails = useSelector(makeSelectSlideDetails())
  const allQuestionsNumber = get(slideDetails, 'questions.length')
  const quizPassRate = get(slideDetails, 'quiz_pass_rate')

  const handleCancel = () => {
    dispatch(hideMarkingModal())
  }

  let wrongAnswer = ''
  let correctAnswer = ''
  let isCompetent = null
  let percentPassed = ''
  if (Array.isArray(userAnswer) && userAnswer.length > 0) {
    wrongAnswer = userAnswer.reduce(
      (acc, curr) =>
        acc +
        (!(
          curr.value === '' &&
          curr.url === '' &&
          !curr.attachments &&
          !curr.file_attachments
        ) && curr.competent === false
          ? 1
          : 0),
      0
    )

    correctAnswer = userAnswer.reduce(
      (acc, curr) =>
        acc +
        (!(
          curr.value === '' &&
          curr.url === '' &&
          !curr.attachments &&
          !curr.file_attachments
        ) && curr.competent === true
          ? 1
          : 0),
      0
    )

    percentPassed = Number((correctAnswer / allQuestionsNumber) * 100)
    if (correctAnswer === 0 && wrongAnswer === 0) {
      return null
    } else if (quizPassRate) {
      if (parseFloat(percentPassed) >= quizPassRate) {
        isCompetent = true
      } else {
        isCompetent = false
      }
    } else if (wrongAnswer === 0 && correctAnswer >= allQuestionsNumber) {
      isCompetent = true
    } else if (wrongAnswer) {
      isCompetent = false
    }
  }

  const handleSetRemarksAnswer = async (item) => {
    try {
      const requestURL = `${config.baseUrl}/api/lms/courses/slides/answers/${item?.id}`
      await axiosInstance.patch(requestURL, {
        remark: item?.remark,
      })
    } catch (error) {
      console.log('~ error', error)
    }
  }

  const handleOk = async () => {
    const totalCompetentAnswer = correctAnswer + wrongAnswer
    if (totalCompetentAnswer < allQuestionsNumber) {
      dispatch(hideMarkingModal())
      return showError(
        'You need to mark all quiz answers before submit the marking'
      )
    }
    if (Array.isArray(userAnswer) && userAnswer.length > 0) {
      dispatch(
        markAnswerCompetent({
          user_id: userAnswer[0].user_id,
          slide_id: userAnswer[0].slide_id,
          competent: isCompetent,
          selectedSchedule,
          currentUser: adminUser,
          percent_quiz_pass: format(percentPassed),
          total_passed: correctAnswer,
        })
      )

      const requests = []
      for (let i = 0; i < remarks?.length; i++) {
        requests.push(handleSetRemarksAnswer(remarks[i]))
      }

      await Promise.all(requests)
    }
    dispatch(hideMarkingModal())
  }

  return (
    <Modal visible={showMarkingModal} footer={null} onCancel={handleCancel}>
      <div className="ld-modal">
        <div className="ld-modal__warning" style={{ marginBottom: 6 }}>
          {get(userAnswer, '[0].user.partner.name', '')} scored:
        </div>
        <div className="ld-modal__content-wrap">
          <div className="ld-modal__content">
            <div className="ld-modal__number">{wrongAnswer}</div>
            <div className="ld-modal__result">Wrong</div>
          </div>
          <div className="ld-modal__content">
            <div className="ld-modal__number">{correctAnswer}</div>
            <div className="ld-modal__result">Correct</div>
          </div>
        </div>
        {isCompetent ? (
          <div className="quiz__item-status quiz__item-status-c">COMPETENT</div>
        ) : (
          <div className="quiz__item-status quiz__item-status-nc">
            NOT COMPETENT
          </div>
        )}

        <div className="ld-modal__confirm">
          <div className="ld-modal__confirm__left">Confirm this score?</div>
          <div className="ld-modal__confirm__right">
            <BtnPrimary
              name="Submit"
              handleClick={handleOk}
              style={{ fontWeight: 'bold', padding: '12px 20px' }}
              icon={images.header.check}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MarkingModal
