import { combineReducers } from 'redux'
import homeReducer from './container/Home/reducer'
import authReducer from './container/Auth/reducer'
import adminReducer from './container/Admin/reducer'
import modalReducer from './container/Modal/reducer'
import checkoutReducer from './container/Checkout/reducer'
import superAdminReducer from './customs/SuperAdmin/container/AdminActor/reducer'

export default () =>
  combineReducers({
    home: homeReducer,
    auth: authReducer,
    admin: adminReducer,
    modal: modalReducer,
    checkout: checkoutReducer,
    superAdmin: superAdminReducer
  })
