import React from 'react'
import images from 'Themes/Images'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import './styles.scss'

const HeaderAdminCRM = () => {
  const dispatch = useDispatch()
  return (
    <div className="header__courses">
      <div className="header__intro">
        <div className="header__course">
          <div className="header__course__level">HOME</div>
          <div className="header__course__title__box">
            <div className="header__course__title admin-courses">
              <div className="admin-courses__title">
                Overview
                {/* <img src={images.header.caret_down} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="header__action">
          <div className="header__summary__wrap">
            <div className="header__summary"></div>
            <div className="header__summary"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderAdminCRM
