import React from 'react'
import { get } from 'lodash'
import './styles.scss'

const HeaderCourseView = ({ course }) => {
  return (
    <div className="header__courses">
      <div className="header__intro" style={{ padding: '0 48px' }}>
        <div className="header__course">
          <div className="header__course__title__box">
            <div className="header__course__title mg_right-32">
              {get(course, 'name', '')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderCourseView
