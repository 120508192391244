import React, { useEffect, useState, useMemo } from 'react'
import { Modal, Select } from 'antd'
import jsPDF from 'jspdf'
import JSZip from 'jszip'
import dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import { saveAs } from 'file-saver'

import BtnPrimary from 'component/BtnPrimary'
import {
  hideExportRegistrations,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../actions'
import { loadListCourse } from 'container/Home/actions'
import { useSelector } from 'react-redux'
import { makeSelectListCourse } from 'container/Home/selectors'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { mapListCourseToDropDown, mapScheduleToDropDown } from 'utils/helper'
import { getSchedules } from 'container/Admin/actions'
import { getStudentEnroll } from 'container/Admin/saga'
import { getUserInfo, getUserPaymentMethod } from 'utils/request'
import { DOMAIN } from 'src/routes'
import { getImageAttachment, getTypeAttachment } from 'utils/helper'
import { makeSelectDomain } from 'container/Home/selectors'
import { parseSafe } from 'utils/helper'

const ExportRegistrations = ({ dispatch, showExportRegistrations }) => {
  const zip = new JSZip()
  const folderZip = zip.folder('registrations')
  const domain = useSelector(makeSelectDomain())
  const isUnicornDomain = domain && domain.domain === DOMAIN.UNICORN_DOMAIN
  const isLMADomain = domain && domain.domain === DOMAIN.LMA
  const isFirstMediaDomain = domain && domain.domain === DOMAIN.FIRSTMEDIA
  const isFlcDomain = domain && domain.domain === DOMAIN.FLC
  const schoolName = get(domain, 'name', '')
  const courses = useSelector(makeSelectListCourse())
  const schedules = useSelector(makeSelectSchedules())
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)

  const course = useMemo(() => {
    if (selectedCourse && courses) {
      return courses.find((course) => course.id === selectedCourse)
    }
  }, [selectedCourse, courses])
  const requireSchedule = get(course, 'is_schedule', false)

  const handleCancel = () => {
    dispatch(hideExportRegistrations())
  }

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getSchedules(selectedCourse, true, null, true, true))
    }
  }, [selectedCourse])

  useEffect(() => {
    dispatch(
      loadListCourse({
        admin: true,
        filter: 'sequence',
        newFilter: 'per_page=1000&ignore_per_page=true',
      })
    )
  }, [])

  const handleExport = async () => {
    dispatch(showGlobalLoading())
    const course = courses.find((item) => item.id === selectedCourse)
    const users = await getStudentEnroll(
      get(selectedSchedule, 'value', 0),
      selectedCourse
    )

    for (let i = 0; i < users.data.length; i++) {
      const doc = new jsPDF('p', 'pt')
      const userId = get(users, `data[${i}].user.id`)
      const profile = await getUserInfo(userId)
      const paymentMethod = await getUserPaymentMethod(userId)
      const partner = users.data[i]

      const name = get(partner, 'user.partner.name', '')

      exportPdf(doc, paymentMethod, partner, profile, course, name)
      if (typeof doc !== 'undefined') {
        try {
          folderZip.file(
            `${name.trim().replace(/\//, '').replace(/ +/g, '_')}.pdf`,
            doc.output('blob')
          )
        } catch {
          console.error('Something went wrong!')
        }
      }
    }
    folderZip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'reports.zip')
    })
    dispatch(hideGlobalLoading())
    dispatch(hideExportRegistrations())
  }

  const nextBodyMapping = (paymentMethod) => {
    const nextBody = paymentMethod?.map((item, index) => {
      return [
        'SFC Claim ID or Bank transfer reference',
        `${get(item, 'value', '')}`,
        'Payment Screenshot',
        {
          content: '',
          dataKey: `payment_pic_${index}`,
          styles: {
            minCellHeight: get(item, 'attachment_name') ? 440 : 10,
          },
        },
      ]
    })
    return nextBody
  }

  const exportPdf = (doc, paymentMethod, partner, profile, course, name) => {
    if (isLMADomain) {
      const defaultX = 30
      doc.setFontSize(18)
      doc.setFont('Sora-Bold', 'bold')
      doc.text('Registration Form', defaultX + 10, 60)

      doc.setFontSize(12)
      doc.setFont('Sora-Light', 'light')
      doc.text(schoolName, defaultX + 10, 80)

      doc.autoTable({
        margin: { left: defaultX },
        startY: 100,
        startX: defaultX,
        styles: {
          fontSize: 9,
          textColor: '#000',
        },
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
        },
        body: [
          [
            {
              content: 'Personal Particulars',
              colSpan: 2,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
          ['Name (as per NRIC):', 'Email Address:'],
          [
            `${get(profile, 'name', '') || name}`,
            `${get(partner, 'user.login', '')}`,
          ],
        ],
        didParseCell: (data) => {
          if (data.row.index !== 0 && data.row.index % 2 !== 0) {
            data.cell.styles.fillColor = '#d9d9d9'
          } else if (data.row.index !== 0 && data.row.index % 2 === 0) {
            data.cell.styles.fillColor = '#ffffff'
          }
        },
      })
      return
    }
    if (isFlcDomain) {
      const additionalData = parseSafe(get(profile, 'additional_info', ''))
      const company = get(additionalData, 'company', '')
      const referred_co = get(additionalData, 'referred_co', '')
      const referred_name = get(additionalData, 'referred_name', '')
      const referred_mobile = get(additionalData, 'referred_mobile', '')

      const defaultX = 30
      doc.setFontSize(18)
      doc.setFont('Sora-Bold', 'bold')
      doc.text('Registration Form', defaultX + 10, 60)

      doc.setFontSize(12)
      doc.setFont('Sora-Light', 'light')
      doc.text(schoolName, defaultX + 10, 80)

      doc.autoTable({
        margin: { left: defaultX },
        startY: 100,
        startX: defaultX,
        styles: {
          fontSize: 9,
          textColor: '#000',
        },
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
        },
        body: [
          [
            {
              content: 'Personal Particulars',
              colSpan: 3,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
          ['Name (as per NRIC):', 'NRIC number:', 'Nationality'],
          [
            `${get(profile, 'name', '')}`,
            `${get(profile, 'passport', '')}`,
            `${get(profile, 'nationality', '')}`,
          ],
          ['Mobile No:', 'Date of Birth:', 'Gender:'],
          [
            `${get(profile, 'phone', '')}`,
            `${get(profile, 'birthday', '')}`,
            `${get(profile, 'gender', '')}`,
          ],
          ['Email:', 'Residential Address'],
          [
            `${get(partner, 'user.login', '')}`,
            `${get(profile, 'postal_address', '')}`,
          ],
          [
            {
              content: 'Company Full Name',
              colSpan: 3,
            },
          ],
          [
            {
              content: company,
              colSpan: 3,
            },
          ],
          ['Referrer Full Name:', 'Referrer Mobile No:', 'Referrer Co:'],
          [`${referred_name}`, `${referred_mobile}`, `${referred_co}`],
        ],
        didParseCell: (data) => {
          if (data.row.index !== 0 && data.row.index % 2 !== 0) {
            data.cell.styles.fillColor = '#d9d9d9'
          } else if (data.row.index !== 0 && data.row.index % 2 === 0) {
            data.cell.styles.fillColor = '#ffffff'
          }
        },
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          fontSize: 9,
          textColor: '#000',
        },
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
        },
        columnStyles: {
          0: { cellWidth: 262.6 },
          1: { cellWidth: 262.6 },
        },
        body: [
          [
            {
              content: 'Course Application',
              colSpan: 2,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
              dataKey: 'header',
            },
          ],
          [
            {
              content: 'Course Code and Course Title',
              dataKey: 'course_title',
            },
            `${get(course, 'course_code', '')} ${get(course, 'name', '')}`,
          ],
          [
            {
              content: 'Class Code',
              dataKey: 'course_title',
            },
            {
              content: `${get(partner, 'schedule.class_code', '')}`,
              dataKey: 'class_code',
            },
          ],
          [
            {
              content: 'Terms and Condition',
              styles: { fontStyle: 'bold', textColor: '#fff' },
              colSpan: 2,
              dataKey: 'header',
            },
          ],
        ],
        didParseCell: (data) => {
          if (data.row.index !== 0 && data.row.index % 2 !== 0) {
            data.cell.styles.fillColor = '#ffffff'
          } else if (data.row.index !== 4 && data.row.index % 2 === 0) {
            data.cell.styles.fillColor = '#d9d9d9'
          }

          if (data.cell.raw.dataKey === 'course_title') {
            data.cell.styles.fillColor = '#d9d9d9'
          }
          if (data.cell.raw.dataKey === 'class_code') {
            data.cell.styles.fillColor = '#ffffff'
          }
          if (data.cell.raw.dataKey === 'header') {
            data.cell.styles.fillColor = '#000'
          }
        },
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          cellPadding: 3,
          textColor: '#000',
          fontSize: 9,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        theme: 'plain',
        body: [
          [''],
          [
            'By signing up for the 3-Day workshop, you agree to be bound by the following terms & conditions:',
          ],
          [
            {
              content: 'A) Payment of Course fees & Refunds',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      1. For Cash payment for Self-Sponsored participants , the net amount should be paid to "Financial Life Coaching Pte Ltd" via bank transfer or PayNow to:`,
          ],
          ['      Financial Life Coaching Pte Ltd'],
          [
            {
              content: '      DBS Bank Ltd',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            {
              content: '      Bank Account No: 027-002238-3',
              styles: { fontStyle: 'bold' },
            },
          ],
          ['      or'],
          [
            {
              content: '      PayNow to: UEN NO: 198101881G',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            '      2. For Company sponsored participants, Financial Life Coaching Pte Ltd will liaise directly with the respective companies for the payment arrangements for the net amount.',
          ],
          [
            '      3. Your seat for the workshop will be reserved upon receipt of the registration & payment. The company reserves the right to decline any registration without giving any reasons.',
          ],
          [
            `      4. Confirmation of the workshop is subject to a minimum group size. An e-mail confirmation will be sent to the participants at least 3 days before the date of the workshop.`,
          ],
          [
            '      5. No refund of the registration fees will be allowed for cancellation/withdrawal of registration one week before the date of the workshop save for cancellation or postponement of the workshop. No refund, whether full or partial, or replacement sessions will be made for absentees during the workshop duration save for medical reasons or extenuating circumstances.',
          ],
          [''],
          [
            {
              content:
                'B) IBF Standards Training Scheme (IBF-STS) Funding Criteria',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      1. All self-sponsored participants/Sponsoring Companies are bound by the terms and conditions as laid down by the relevant government agencies.`,
          ],
        ],
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          cellPadding: 3,
          textColor: '#000',
          fontSize: 9,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        theme: 'plain',
        pageBreak: 'avoid',
        body: [
          [
            `      2. The Self-Sponsored participant/Sponsoring Company will be liable to pay the full course fees to Financial Life Coaching Pte Ltd and/or the relevant Agencies if the funding application claim is rejected because the participant/Sponsoring Company fails to meet the requirements set out below:`,
          ],
          [
            '      a) the participant is not a Singaporean or Permanent Resident physically based in Singapore;',
          ],
          [
            '      b) the participant fails to complete the course successfully and pass the assessment. All participants who failed the assessment are entitled to re-take the assessments again.',
          ],
          [''],
          [
            {
              content: 'C) Changes & Cancellation',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      Financial Life Coaching Pte Ltd reserves the rights to make changes to the Course Program, date, time, venue or cancel a course due to unforeseen circumstances. All efforts will be made to inform participants promptly of the changes and a full refund will be made for such cancellations.`,
          ],
          [''],
          [
            {
              content: 'D) Important Disclaimers',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      1. Financial Life Coaching Pte Ltd & its training partner (AllQuant) are an educational provider and not investment or financial advisers. All the information, materials and services renderedin this courseand after the course are provided for educational or informational purposes only. They are not intended to be, nor shall they be construed as, investment or financial advice, an offer, or a solicitation of an offer, to buy or sell an interest in any investment product.`,
          ],
          [
            `      2. Nothing in this course and after the course or any of our services constitutes accounting, regulatory, tax, investment or other advice. All investment strategies and inputs taught in this Course are given in absence of consideration for your suitability towards them. Please consult your investment adviser on your suitability before running our investment strategies.`,
          ],
          [
            '      3. All investments are subject to investment risks including the possible loss of the principal amount invested. Any performance shown in the course or on our Site (or affiliated Sites), whether live historical, hypothetical or modelled, is not necessarily indicative nor a guarantee on future performance. You should make your own assessment of the relevance, accuracy and adequacy of the information and consult your independent advisers where necessary.',
          ],
          [
            '      4. While the information provided in this course and after the course is believed to be reliable, Financial Life Coaching and its training partner (AllQuant)/the trainers make no representation or warranty, whether expressed or implied, and accept no responsibility for its completeness, accuracy or reliability.',
          ],
          [
            '      5. Under no circumstances will Financial Life Coaching Pte Ltd and its training partner (AllQuant) and the trainers be liable for any losses whatsoever with respect to use of the content in this Course and after the Course, its Site (or affiliated Sites) and their products and services, whether directly or indirectly.',
          ],
          [
            {
              content: 'Personal Data Protection Act - Consent Form',
              styles: {
                fillColor: '#000',
                fontStyle: 'bold',
                textColor: '#fff',
              },
            },
          ],
          [''],
          [
            {
              content: 'E) PDPA',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      1) By your registration, you agree to be included as a member of the Workshop What's App Group that will be set up to coordinate the running of the Workshop, Q & A etc.;`,
          ],
          [
            `      2) By your registration, you consent to the collection, use and disclosure of your personal data to us and we only collect and use your personal data which is relevant and required for purpose of audit or informing you about coaching clinics, future workshops, resources, courses or events or software tools that may be of interest to you as our clients.`,
          ],
          [
            '      3) By your registration, you consent to the use and release of the Zoom video recordings and/or print screen images for audit purposes and workshop promotions;',
          ],
          [''],
          [
            {
              content:
                'Financial Life Coaching Pte Ltd reserves the rights to amend the above terms and conditions from time to time where necessary.',
              styles: { fontStyle: 'bold' },
            },
          ],
          [''],
          [
            {
              content: `Applicant's Acknowledgement`,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
          ['By registering for this course, I declare that:'],
          [
            '      I have read, understood and agreed to abide by the above terms and conditions.',
          ],
          [
            '      I consent to the use of my personal information as set out above.',
          ],
          [
            '      The information provided by me in the application is true and correct.',
          ],
        ],
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
          fontSize: 9,
          textColor: '#000',
        },
        columnStyles: {
          0: { cellWidth: 131.3 },
          1: { cellWidth: 131.3 },
          2: { cellWidth: 131.3 },
          3: { cellWidth: 131.3 },
        },
        body: [
          [
            `Applicant's Name`,
            `${get(partner, 'user.partner.name', '')}`,
            `Date:`,
            `${dayjs(get(partner, 'create_date', '')).format('DD-MM-YYYY')}`,
          ],
        ],
        didParseCell: (data) => {
          data.cell.styles.fillColor = '#ffffff'
        },
      })
      const nextBody = nextBodyMapping(paymentMethod)
      const fileHasScreenShot = paymentMethod?.find(
        (i) =>
          i?.payment_method === 'paynow' &&
          i?.attachment?.mimetype !== 'application/pdf'
      )
      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
          fontSize: 9,
          textColor: '#000',
        },
        pageBreak: 'avoid',
        columnStyles:
          get(fileHasScreenShot, 'attachment') &&
          get(fileHasScreenShot, 'attachment.mimetype') !== 'application/pdf'
            ? {
                0: { cellWidth: 60 },
                1: { cellWidth: 60 },
                2: { cellWidth: 60 },
                3: { cellWidth: 345.2 },
              }
            : {
                0: { cellWidth: 131.3 },
                1: { cellWidth: 131.3 },
                2: { cellWidth: 131.3 },
                3: { cellWidth: 131.3 },
              },
        // 525.2
        body: [
          [
            {
              content: 'Course Fee Payment',
              colSpan: 4,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
          ...nextBody,
        ],
        didParseCell: (data) => {
          if (data.row.index !== 0) {
            data.cell.styles.fillColor = '#ffffff'
          }
        },
        didDrawCell: (data) => {
          paymentMethod.forEach((item, index) => {
            if (
              data.cell.raw.dataKey === `payment_pic_${index}` &&
              get(item, 'attachment') &&
              get(item, 'attachment.mimetype') !== 'application/pdf'
            ) {
              const url = getImageAttachment(item.attachment)
              const type = getTypeAttachment(item.attachment)
              if (url && type) {
                doc.addImage(
                  url,
                  type,
                  data.cursor.x + 25,
                  data.cursor.y + 15,
                  280,
                  400
                )
              }
            }
          })
        },
      })
      return
    }

    if (isUnicornDomain) {
      const defaultX = 30
      doc.setFontSize(18)
      doc.setFont('Sora-Bold', 'bold')
      doc.text('Registration Form', defaultX + 10, 60)

      doc.setFontSize(12)
      doc.setFont('Sora-Light', 'light')
      doc.text(schoolName, defaultX + 10, 80)

      doc.autoTable({
        margin: { left: defaultX },
        startY: 100,
        startX: defaultX,
        styles: {
          fontSize: 9,
          textColor: '#000',
        },
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
        },
        body: [
          [
            {
              content: 'Personal Particulars',
              colSpan: 3,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
          ['Name (as per NRIC):', 'Gender:', 'Date of Birth:'],
          [
            `${get(profile, 'name', '')}`,
            `${get(profile, 'gender', '')}`,
            `${get(profile, 'birthday', '')}`,
          ],
          [`Nationality:`, `NRIC number:`, `Race:`],
          [
            `${get(profile, 'nationality', '')}`,
            `${get(profile, 'passport', '')}`,
            `${get(profile, 'race', '')}`,
          ],
          [`Email Address:`, `Contact Number:`, `Alternate Contact Number:`],
          [
            `${get(partner, 'user.login', '')}`,
            `${get(profile, 'phone', '')}`,
            `${get(profile, 'contact', '')}`,
          ],
        ],
        didParseCell: (data) => {
          if (data.row.index !== 0 && data.row.index % 2 !== 0) {
            data.cell.styles.fillColor = '#d9d9d9'
          } else if (data.row.index !== 0 && data.row.index % 2 === 0) {
            data.cell.styles.fillColor = '#ffffff'
          }
        },
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          fontSize: 9,
          textColor: '#000',
        },
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
        },
        columnStyles: {
          0: { cellWidth: 262.6 },
          1: { cellWidth: 262.6 },
        },
        body: [
          ['Residential Address:', `Postal Code:`],
          [
            `${get(profile, 'postal_address', '')}`,
            `${get(profile, 'postal_code', '')}`,
          ],
          [`Occupation:`, `Highest Educational Level:`],
          [
            `${get(profile, 'occupation', '')}`,
            `${get(profile, 'education_level', '')}`,
          ],
          [
            {
              content: 'Course Application',
              colSpan: 2,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
          [
            {
              content: 'Course Code and Course Title',
              dataKey: 'course_title',
            },
            `${get(course, 'course_code', '')} ${get(course, 'name', '')}`,
          ],
          [
            `Class Code`,
            {
              content: `${get(partner, 'schedule.class_code', '')}`,
              dataKey: 'class_code',
            },
          ],
          [
            {
              content: 'Terms and Condition',
              styles: { fontStyle: 'bold', textColor: '#fff' },
              colSpan: 2,
            },
          ],
        ],
        didParseCell: (data) => {
          if (data.row.index !== 0 && data.row.index % 2 !== 0) {
            data.cell.styles.fillColor = '#ffffff'
          } else if (data.row.index !== 4 && data.row.index % 2 === 0) {
            data.cell.styles.fillColor = '#d9d9d9'
          }

          if (data.cell.raw.dataKey === 'course_title') {
            data.cell.styles.fillColor = '#d9d9d9'
          }
          if (data.cell.raw.dataKey === 'class_code') {
            data.cell.styles.fillColor = '#ffffff'
          }
          if (data.row.index === 7) {
            data.cell.styles.fillColor = '#000'
          }
        },
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          cellPadding: 3,
          textColor: '#000',
          fontSize: 9,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        theme: 'plain',
        body: [
          [''],
          [{ content: 'Disclaimer', styles: { fontStyle: 'bold' } }],
          [
            `      Unicorn Financial Solutions Pte Ltd (UFS) will exercise all possible care to run the confirmed courses,`,
          ],
          [
            '      however, in the event of unforeseen circumstances, UFS reserves the right to cancel or defer the courses,',
          ],
          [
            '      and/or change venue, lecturer and fees. UFS will take all reasonable efforts to notify participants of',
          ],
          ['      changes, should they happen.'],
          [
            `      UFS reserves the right to reschedule and cancel classes with less than a minimum number of 20 students`,
          ],
          ['      per class'],
          [''],
          [
            {
              content: 'Registration, Enrolment & Confirmation',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      The applicant would be paying $30 registration fee. This fee is refundable when the applicant completes the course.`,
          ],
          [
            `      UFS will ascertain the applicant's suitability for the course by reviewing the application and/or`,
          ],
          ['      interviewing the applicant.'],
          [
            '      UFS reserves the right to accept and to reject any application.',
          ],
          [
            `      Confirmation of enrolment for the course is subject to applicant's suitability for the course.`,
          ],
        ],
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          cellPadding: 3,
          textColor: '#000',
          fontSize: 9,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        theme: 'plain',
        pageBreak: 'avoid',
        body: [
          [
            {
              content: 'Graduation Requirements',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      The requirements to graduate from the course are 1) attained at least 75% attendance, and 2) passed all`,
          ],
          ['      the assessments of the course.'],
          [''],
          [
            {
              content: 'Withdrawal and Fee Liability',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      Trainees seeking course withdrawal are to submit course withdrawal form or email to WDS.`,
          ],
          [''],
          [
            {
              content: 'Course Deferment Period',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      The maximum duration allowed for course deferment is 6 months from the first scheduled course`,
          ],
          ['      commencement date.'],
          [''],
          [
            {
              content: 'Trainees Location During The Period of Training',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      Trainees were residing in Singapore during the period of training.`,
          ],
          [''],
          [
            {
              content: 'Certificate Collection',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            `      Upon completion of the course, trainees will be able to receive a certificate.`,
          ],
          [
            {
              content: 'Personal Data Protection Act - Consent Form',
              styles: {
                fillColor: '#000',
                fontStyle: 'bold',
                textColor: '#fff',
              },
            },
          ],
        ],
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          cellPadding: 3,
          textColor: '#000',
          fontSize: 9,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        theme: 'plain',
        pageBreak: 'avoid',
        body: [
          [
            `      In compliance with the Personal Data Protection Act ("PDPA"), Unicorn Financial Solutions Pte Ltd (UFS)`,
          ],
          [
            '      seeks your consent to collect and use your personal data (i.e. Name, NRIC/Passport number, contact',
          ],
          [
            '      numbers, mailing and email addresses) in order to maintain the student enrolment registers and to disclose',
          ],
          [
            '      such personal data to the relevant educational authorities where necessary ("Purposes").',
          ],
          [''],
          [
            '      UFS will also collect and use your personal data to provide you with information on our activities such as',
          ],
          [
            '      upcoming events, seminars, workshops, conferences and training programmes organised by UFS and its',
          ],
          [
            '      affiliated organisations which may be relevant to you ("Services").',
          ],
          [''],
          [
            '      UFS respects your privacy and assures that your personal data will be kept securely according to PDPA.',
          ],
          [''],
          [
            '      I hereby give my acknowledgement and consent to UFS to use my personal data for the aforesaid Purposes',
          ],
          [
            '      and Services. In the event that I have registered my Singapore telephone numbers(s) with the Do Not Call',
          ],
          [
            '      and Services. In the event that I have registered my Singapore telephone numbers(s) with the Do Not Call',
          ],
          [
            '      Registry and wish to withhold or withdraw my consent to UFS in respect of receiving telephone calls',
          ],
          [
            '      and/or SMS, I endeavour to provide sufficient notice in writing to UFS of such as soon as reasonably',
          ],
          [
            '      practicable. I further agree to indemnify UFS against any financial penalties imposed by the Personal Data',
          ],
          [
            '      Protection Commission or any court of law in Singapore as a direct or indirect result of my failure to inform',
          ],
          ['      UFS of my registration with the Do Not Call Registry.'],
          [''],
          [
            '      I agree that my consent will remain in place until my withdrawal by officially notifying UFS in writing.',
          ],
          [
            {
              content: `Applicant's Acknowledgement`,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
        ],
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        styles: {
          cellPadding: 3,
          textColor: '#000',
          fontSize: 9,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        theme: 'plain',
        pageBreak: 'avoid',
        body: [
          ['By registering for this course, I declare that:'],
          [
            '      I hereby acknowledge, understood and agreed to abide by the above terms and conditions including the rules and regulations',
          ],
          ['      from Training Provider and IBF.'],
          [
            '      I consent to the use of my personal information as set out above.',
          ],
          [
            '      The information provided by me in the application is true and correct.',
          ],
        ],
      })

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
          fontSize: 9,
          textColor: '#000',
        },
        columnStyles: {
          0: { cellWidth: 131.3 },
          1: { cellWidth: 131.3 },
          2: { cellWidth: 131.3 },
          3: { cellWidth: 131.3 },
        },
        body: [
          [
            `Applicant's Name`,
            `${get(partner, 'user.partner.name', '')}`,
            `Date:`,
            `${dayjs(get(partner, 'create_date', '')).format('DD-MM-YYYY')}`,
          ],
        ],
        didParseCell: (data) => {
          data.cell.styles.fillColor = '#ffffff'
        },
      })

      const nextBody = nextBodyMapping(paymentMethod)
      const fileHasScreenShot = paymentMethod?.find(
        (i) =>
          i?.payment_method === 'paynow' &&
          i?.attachment?.mimetype !== 'application/pdf'
      )

      doc.autoTable({
        margin: { left: defaultX },
        startY: doc.autoTable.previous.finalY,
        bodyStyles: {
          lineWidth: 1,
          lineColor: 12,
          fontSize: 9,
          textColor: '#000',
        },
        pageBreak: 'avoid',
        columnStyles:
          get(fileHasScreenShot, 'attachment') &&
          get(fileHasScreenShot, 'attachment.mimetype') !== 'application/pdf'
            ? {
                0: { cellWidth: 60 },
                1: { cellWidth: 60 },
                2: { cellWidth: 60 },
                3: { cellWidth: 345.2 },
              }
            : {
                0: { cellWidth: 131.3 },
                1: { cellWidth: 131.3 },
                2: { cellWidth: 131.3 },
                3: { cellWidth: 131.3 },
              },
        // 525.2
        body: [
          [
            {
              content: 'Course Fee Payment',
              colSpan: 4,
              styles: {
                fillColor: 'black',
                textColor: 'white',
                fontStyle: 'bold',
              },
            },
          ],
          ...nextBody,
        ],
        didParseCell: (data) => {
          if (data.row.index !== 0) {
            data.cell.styles.fillColor = '#ffffff'
          }
        },
        didDrawCell: (data) => {
          paymentMethod.forEach((item, index) => {
            if (
              data.cell.raw.dataKey === `payment_pic_${index}` &&
              get(item, 'attachment') &&
              get(item, 'attachment.mimetype') !== 'application/pdf'
            ) {
              const url = getImageAttachment(item.attachment)
              const type = getTypeAttachment(item.attachment)
              if (url && type) {
                doc.addImage(
                  url,
                  type,
                  data.cursor.x + 25,
                  data.cursor.y + 15,
                  280,
                  400
                )
              }
            }
          })
        },
      })

      return
    }

    const defaultX = 30
    doc.setFontSize(18)
    doc.setFont('Sora-Bold', 'bold')
    doc.text('Registration Form', defaultX + 10, 60)

    doc.setFontSize(12)
    doc.setFont('Sora-Light', 'light')
    doc.text(schoolName, defaultX + 10, 80)

    doc.autoTable({
      margin: { left: defaultX },
      startY: 100,
      startX: defaultX,
      styles: {
        fontSize: 9,
        textColor: '#000',
      },
      bodyStyles: {
        lineWidth: 1,
        lineColor: 12,
      },
      body: [
        [
          {
            content: 'Personal Particulars',
            colSpan: 3,
            styles: {
              fillColor: 'black',
              textColor: 'white',
              fontStyle: 'bold',
            },
          },
        ],
        ['Name (as per NRIC):', 'NRIC number:', 'Nationality'],
        [
          `${get(profile, 'name', '')}`,
          `${get(profile, 'passport', '')}`,
          `${get(profile, 'nationality', '')}`,
        ],
        ['Mobile No:', 'Date of Birth:', 'Gender:'],
        [
          `${get(profile, 'phone', '')}`,
          `${get(profile, 'birthday', '')}`,
          `${get(profile, 'gender', '')}`,
        ],
        ['Email:', 'Residential Address'],
        [
          `${get(partner, 'user.login', '')}`,
          `${get(profile, 'postal_address', '')}`,
        ],
      ],
      didParseCell: (data) => {
        if (data.row.index !== 0 && data.row.index % 2 !== 0) {
          data.cell.styles.fillColor = '#d9d9d9'
        } else if (data.row.index !== 0 && data.row.index % 2 === 0) {
          data.cell.styles.fillColor = '#ffffff'
        }
      },
    })

    doc.autoTable({
      margin: { left: defaultX },
      startY: doc.autoTable.previous.finalY,
      styles: {
        fontSize: 9,
        textColor: '#000',
      },
      bodyStyles: {
        lineWidth: 1,
        lineColor: 12,
      },
      columnStyles: {
        0: { cellWidth: 262.6 },
        1: { cellWidth: 262.6 },
      },
      body: [
        [`Occupation:`, `Highest Educational Level:`],
        [
          `${get(profile, 'occupation', '')}`,
          `${get(profile, 'education_level', '')}`,
        ],
        [
          {
            content: 'Course Application',
            colSpan: 2,
            styles: {
              fillColor: 'black',
              textColor: 'white',
              fontStyle: 'bold',
            },
            dataKey: 'header',
          },
        ],
        [
          {
            content: 'Course Code and Course Title',
            dataKey: 'course_title',
          },
          `${get(course, 'course_code', '')} ${get(course, 'name', '')}`,
        ],
        [
          {
            content: 'Class Code',
            dataKey: 'course_title',
          },
          {
            content: `${get(partner, 'schedule.class_code', '')}`,
            dataKey: 'class_code',
          },
        ],
      ],
      didParseCell: (data) => {
        if (data.row.index !== 0 && data.row.index % 2 !== 0) {
          data.cell.styles.fillColor = '#ffffff'
        } else if (data.row.index !== 4 && data.row.index % 2 === 0) {
          data.cell.styles.fillColor = '#d9d9d9'
        }

        if (data.cell.raw.dataKey === 'course_title') {
          data.cell.styles.fillColor = '#d9d9d9'
        }
        if (data.cell.raw.dataKey === 'class_code') {
          data.cell.styles.fillColor = '#ffffff'
        }
        if (data.cell.raw.dataKey === 'header') {
          data.cell.styles.fillColor = '#000'
        }
      },
    })

    doc.autoTable({
      margin: { left: defaultX },
      startY: doc.autoTable.previous.finalY,
      styles: {
        cellPadding: 3,
        textColor: '#000',
        fontSize: 9,
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 1,
      theme: 'plain',
      pageBreak: 'avoid',
      body: [
        [
          {
            content: `Applicant's Acknowledgement`,
            styles: {
              fillColor: 'black',
              textColor: 'white',
              fontStyle: 'bold',
            },
          },
        ],
        ['By registering for this course, I declare that:'],
        [
          '      I have read, understood and agreed to abide by the above terms and conditions.',
        ],
        [
          '      I consent to the use of my personal information as set out above.',
        ],
        [
          '      The information provided by me in the application is true and correct.',
        ],
      ],
    })

    doc.autoTable({
      margin: { left: defaultX },
      startY: doc.autoTable.previous.finalY,
      bodyStyles: {
        lineWidth: 1,
        lineColor: 12,
        fontSize: 9,
        textColor: '#000',
      },
      columnStyles: {
        0: { cellWidth: 131.3 },
        1: { cellWidth: 131.3 },
        2: { cellWidth: 131.3 },
        3: { cellWidth: 131.3 },
      },
      body: [
        [
          `Applicant's Name`,
          `${get(partner, 'user.partner.name', '')}`,
          `Date:`,
          `${dayjs(get(partner, 'create_date', '')).format('DD-MM-YYYY')}`,
        ],
      ],
      didParseCell: (data) => {
        data.cell.styles.fillColor = '#ffffff'
      },
    })

    const nextBody = nextBodyMapping(paymentMethod)
    const fileHasScreenShot = paymentMethod?.find(
      (i) =>
        i?.payment_method === 'paynow' &&
        i?.attachment?.mimetype !== 'application/pdf'
    )

    doc.autoTable({
      margin: { left: defaultX },
      startY: doc.autoTable.previous.finalY,
      bodyStyles: {
        lineWidth: 1,
        lineColor: 12,
        fontSize: 9,
        textColor: '#000',
      },
      pageBreak: 'avoid',
      columnStyles:
        get(fileHasScreenShot, 'attachment') &&
        get(fileHasScreenShot, 'attachment.mimetype') !== 'application/pdf'
          ? {
              0: { cellWidth: 60 },
              1: { cellWidth: 60 },
              2: { cellWidth: 60 },
              3: { cellWidth: 345.2 },
            }
          : {
              0: { cellWidth: 131.3 },
              1: { cellWidth: 131.3 },
              2: { cellWidth: 131.3 },
              3: { cellWidth: 131.3 },
            },
      // 525.2
      body: [
        [
          {
            content: 'Course Fee Payment',
            colSpan: 4,
            styles: {
              fillColor: 'black',
              textColor: 'white',
              fontStyle: 'bold',
            },
          },
        ],
        ...nextBody,
      ],
      didParseCell: (data) => {
        if (data.row.index !== 0) {
          data.cell.styles.fillColor = '#ffffff'
        }
      },
      didDrawCell: (data) => {
        paymentMethod.forEach((item, index) => {
          if (
            data.cell.raw.dataKey === `payment_pic_${index}` &&
            get(item, 'attachment') &&
            get(item, 'attachment.mimetype') !== 'application/pdf'
          ) {
            const url = getImageAttachment(item.attachment)
            const type = getTypeAttachment(item.attachment)
            if (url && type) {
              doc.addImage(
                url,
                type,
                data.cursor.x + 25,
                data.cursor.y + 15,
                280,
                400
              )
            }
          }
        })
      },
    })
    return
  }

  return (
    <Modal
      visible={showExportRegistrations}
      footer={null}
      onCancel={handleCancel}
    >
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Export Registrations Details
        </div>
        <div className="sc-modal__label">Courses</div>

        <Select
          className="selectSchedule"
          showSearch
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={(course) => {
            setSelectedCourse(course)
            setSelectedSchedule(null)
          }}
          value={selectedCourse ? selectedCourse : null}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={mapListCourseToDropDown(courses)}
        />

        {requireSchedule && schedules && (
          <>
            <div className="sc-modal__label" style={{ marginTop: 20 }}>
              Schedules
            </div>

            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={(_, data) => {
                setSelectedSchedule(data)
              }}
              value={!isEmpty(selectedSchedule) ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, false, true)}
            />
          </>
        )}
        {selectedCourse && (
          <BtnPrimary
            name="Export"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginTop: 20,
            }}
            handleClick={handleExport}
          />
        )}
      </div>
    </Modal>
  )
}

export default ExportRegistrations
