import React, { useEffect, useMemo, useRef, useState } from 'react'
import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import styled from 'styled-components'
import Dropdown from 'react-dropdown'
import { useFormik } from 'formik'
import { loadCourse } from 'container/Home/actions'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import SignaturePad from 'react-signature-canvas'
import './styles.scss'

import Input from 'component/Input'
import { INITIAL_TYPE_OUTCOME } from '../EditType'
import {
  addOutcomeCourse,
  getUserByCourseAndSchedule,
} from '../../../../actions'
import { get, isEmpty, uniqBy, uniqueId } from 'lodash'
import { TimePicker, Space, Table, Modal, Button } from 'antd'
import config from 'src/global-config'
import axiosInstance from 'utils/axiosInstance'
import { showSuccess } from 'utils/notification'
import { parseSafe } from 'utils/helper'
import dayjs from 'dayjs'
import { showError } from 'src/utils/notification'

const OutcomeWrapper = styled.div`
  margin: 0px;
`

const Wrapper = styled.div`
  margin: 20px 0;
`

const FlexWrapper = styled.div`
  display: flex;
`

const AddOutcomeCourse = ({
  dispatch,
  currentUser,
  id,
  course,
  selectedSchedule,
  listSetOutcomeCustom,
  learningOutcome,
  listCustomOutcomeUser,
  isUnicornOrBellOrAscendo = false,
  setSignatureTrainerName,
  setSignatureStudentName,
  signatureTrainerName,
  signatureStudentName,
  userProfile,
  isBellDomainOnly = false,
}) => {
  const [currentDurations, setCurrentDurations] = useState([])
  const [visibleDuration, setVisibleDuration] = useState(false)
  const [isEditDuration, setIsEditDuration] = useState(false)
  const sigCanvas = useRef({})

  const [visiblePad, setVisiblePad] = useState(false)
  const [isStudentName, setIsStudentName] = useState(false)

  const titleButtonTrainer = useMemo(
    () => `Signature - ${userProfile?.name}`,
    [userProfile]
  )

  const titleButtonStudent = useMemo(
    () =>
      `Signature - ${
        currentUser?.user?.partner?.name
          ? currentUser?.user?.partner?.name
          : 'Student'
      }`,
    [currentUser]
  )

  const clear = () => sigCanvas.current.clear()

  const handleSaveSignature = (isStudentName = false) => {
    const img = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    isStudentName ? setSignatureStudentName(img) : setSignatureTrainerName(img)
    clear()
    setVisiblePad(false)
  }

  useEffect(() => {
    formik.setFieldValue('assessment_type', '')
    formik.setFieldValue('assesment_duration', '')
    formik.setFieldValue('allocated_duration', '')
    formik.setFieldValue('start_time', '')
    formik.setFieldValue('end_time', '')
  }, [visibleDuration])
  useEffect(() => {
    setVisibleDuration(false)
  }, [currentUser])

  const nextDataLearningOutCome = useMemo(
    () =>
      Array.isArray(listCustomOutcomeUser) && listCustomOutcomeUser.length > 0
        ? [...learningOutcome, ...listCustomOutcomeUser]
        : learningOutcome,
    [listCustomOutcomeUser, learningOutcome]
  )

  const assessmentType = useMemo(() => {
    if (currentDurations?.length > 0) {
      if (isEditDuration) {
        return currentDurations?.reduce((prev, curr) => {
          if (curr?.assessment_type) {
            return [
              ...prev,
              { value: curr?.assessment_type, label: curr?.assessment_type },
            ]
          }
          return prev
        }, [])
      } else {
        const dataAssessmentType =
          currentDurations?.length &&
          nextDataLearningOutCome?.length &&
          nextDataLearningOutCome?.reduce((prev, curr) => {
            const existingItems = currentDurations?.find(
              (i) => i?.assessment_type === curr?.type
            )

            if (curr?.type && !existingItems) {
              return uniqBy(
                [...prev, { value: curr?.type, label: curr?.type }],
                'value'
              )
            }
            return prev
          }, [])
        return dataAssessmentType
      }
    } else {
      return (
        nextDataLearningOutCome?.length &&
        uniqBy(
          nextDataLearningOutCome?.map((i) => {
            return { value: i?.type, label: i?.type }
          }),
          'value'
        )
      )
    }
  }, [nextDataLearningOutCome, currentDurations, isEditDuration])

  const listSetOutcomCustomByUser = useMemo(
    () =>
      listSetOutcomeCustom &&
      currentUser &&
      listSetOutcomeCustom.filter(
        (item) => item.user_id === get(currentUser, 'user.id')
      ),
    [listSetOutcomeCustom, currentUser]
  )

  const [addOutcome, setAddOutcome] = useState(false)

  const formik = useFormik({
    initialValues: {
      learning_outcome: '',
      assessment_criteria: '',
      competent: null,
      remark: '',
      type: '',
      allocated_duration: '',
      start_time: '',
      end_time: '',
      assessment_type: '',
    },

    onSubmit: (values) => {
      dispatch(
        addOutcomeCourse({
          ...values,
          channel_id: parseInt(id),
          user_id: get(currentUser, 'create_uid'),
        })
      )
      setAddOutcome(false)
    },
  })

  useEffect(() => {
    if (!isEmpty(course) && course?.course_instructor) {
      const additionalData = parseSafe(course?.course_instructor)
      const durations = additionalData?.durations
      const durationBySchedule =
        selectedSchedule?.value &&
        durations?.find(
          (item) =>
            item?.schedule_id && item?.schedule_id === selectedSchedule?.value
        )
      const nextDurationBySchedule =
        (+selectedSchedule?.value >= 0 || +currentUser?.schedule_id >= 0) &&
        durations?.reduce((prev, curr) => {
          if (
            +curr?.schedule_id >= 0 &&
            curr?.schedule_id ===
              (selectedSchedule?.value || currentUser?.schedule_id)
          ) {
            const currentData = curr?.list_users?.find(
              (i) => i?.user_id === currentUser?.user?.id
            )
            return [
              ...prev,
              {
                allocated_duration: curr?.allocated_duration,
                assessment_type: curr?.assessment_type,
                schedule_id: curr?.schedule_id,
                ...currentData,
              },
            ]
          }
          return prev
        }, [])

      setCurrentDurations(
        nextDurationBySchedule?.length
          ? nextDurationBySchedule?.filter((i) => i?.assessment_type)
          : durations?.filter(
              (item) => item?.assessment_type && !item?.schedule_id
            )
      )
      handleSetDuration(durationBySchedule)
    } else {
      setCurrentDurations(null)
    }
  }, [course, selectedSchedule, currentUser])

  useEffect(() => {
    if (!isEmpty(listSetOutcomCustomByUser) && !isUnicornOrBellOrAscendo) {
      formik.setFieldValue(
        'start_time',
        listSetOutcomCustomByUser?.[0]?.start_time
          ? dayjs(listSetOutcomCustomByUser?.[0]?.start_time)
          : ''
      )
      formik.setFieldValue(
        'end_time',
        listSetOutcomCustomByUser?.[0]?.end_time
          ? dayjs(listSetOutcomCustomByUser?.[0]?.end_time)
          : ''
      )
      formik.setFieldValue(
        'assesment_duration',
        listSetOutcomCustomByUser?.[0]?.allocated_duration
          ? listSetOutcomCustomByUser?.[0]?.allocated_duration
          : ''
      )
    } else {
      formik.setFieldValue('assesment_duration', '')
      formik.setFieldValue('start_time', '')
      formik.setFieldValue('end_time', '')
    }
  }, [listSetOutcomCustomByUser, isUnicornOrBellOrAscendo])

  const handleSetDuration = (durationBySchedule) => {
    formik.setFieldValue(
      'allocated_duration',
      durationBySchedule?.allocated_duration || ''
    )
    formik.setFieldValue(
      'allocated_duration_original',
      durationBySchedule?.allocated_duration || ''
    )
  }

  const onSelect = (data) => {
    formik.setFieldValue('type', data.value)
  }

  const onSelectAssessmentType = (data) => {
    formik.setFieldValue('assessment_type', data.value)

    if (isEditDuration && currentDurations) {
      const selectedItem = currentDurations?.find(
        (item) => item?.assessment_type === data?.value
      )
      formik.setFieldValue(
        'allocated_duration',
        selectedItem?.allocated_duration
      )
      formik.setFieldValue(
        'assesment_duration',
        selectedItem?.assesment_duration
      )
      formik.setFieldValue(
        'start_time',
        selectedItem?.start_time ? dayjs(selectedItem?.start_time) : ''
      )
      formik.setFieldValue(
        'end_time',
        selectedItem?.end_time ? dayjs(selectedItem?.end_time) : ''
      )
    }
  }

  const handleMarking = () => {
    formik.setFieldValue('competent', true)
  }
  const handleUnMarking = () => {
    formik.setFieldValue('competent', false)
  }

  const handleShowAddOutcome = () => {
    setAddOutcome(true)
    formik.resetForm()
    const additionalData = parseSafe(course?.course_instructor)
    const durations = additionalData?.durations
    const durationBySchedule = durations?.find(
      (item) => item?.schedule_id === selectedSchedule?.value
    )

    handleSetDuration(durationBySchedule)
  }

  const handleNumberInput = (currentTarget, field) => {
    let regExp = /^[0-9]{1,15}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      if (field === 'assesment_duration' && formik.values?.start_time) {
        const newTime = dayjs(formik.values?.start_time).minute(
          currentTarget.value
        )
        formik.setFieldValue('end_time', newTime)
      }
      formik.setFieldValue(field, currentTarget.value)
    }
  }

  const updateCourseInstructors = async (course) => {
    let requestURL = `${config.baseUrl}/api/lms/courses/channels/${course?.id}/course-instructors`
    try {
      const res = await axiosInstance.patch(requestURL, {
        course_instructor: course?.course_instructor,
      })
      return res?.data
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleSaveAdditional = async (isEdit = false, isDuration = false) => {
    dispatch(showGlobalLoading())
    const values = formik.values
    if (!values.assessment_type && isUnicornOrBellOrAscendo) {
      dispatch(hideGlobalLoading())
      return showError(
        `Please select Assessment Type to ${isEdit ? 'edit' : 'add'}!`
      )
    }
    if (+values?.assesment_duration > +values?.allocated_duration) {
      dispatch(hideGlobalLoading())

      formik.setFieldValue(
        'allocated_duration',
        values?.allocated_duration_original
      )

      return showError(
        'Duration of Assessment should not be more than Allocated Duration'
      )
    }
    const additionalData = parseSafe(course?.course_instructor)
    let durations = additionalData?.durations || []

    const matchSchedule =
      selectedSchedule?.value &&
      durations?.findIndex(
        (item) =>
          item?.schedule_id && item?.schedule_id === selectedSchedule?.value
      )

    if (matchSchedule !== -1) {
      if (isUnicornOrBellOrAscendo) {
        if (isEdit) {
          const existingItems = durations?.filter(
            (item) =>
              item?.assessment_type !== values.assessment_type ||
              item?.schedule_id !==
                (selectedSchedule?.value || currentUser?.schedule_id)
          )
          const existingUsers = durations?.reduce((prev, curr) => {
            if (
              curr?.assessment_type === values.assessment_type &&
              curr?.schedule_id ===
                (selectedSchedule?.value || currentUser?.schedule_id)
            ) {
              const listUsers =
                (curr?.list_users?.length > 0 &&
                  curr?.list_users?.filter(
                    (i) => i?.user_id !== currentUser?.user?.id
                  )) ||
                []

              return [...prev, ...listUsers]
            }
            return prev
          }, [])

          durations = [
            ...existingItems,
            {
              allocated_duration: values.allocated_duration,
              assessment_type: values.assessment_type,
              list_users: [
                ...existingUsers,
                {
                  start_time: values.start_time,
                  end_time: values.end_time,
                  assesment_duration: values.assesment_duration,
                  user_id: currentUser?.user?.id,
                },
              ],
              schedule_id: selectedSchedule?.value || currentUser?.schedule_id,
            },
          ]
        } else {
          durations = [
            ...durations,
            {
              allocated_duration: values.allocated_duration,
              assessment_type: values.assessment_type,
              list_users: [
                {
                  start_time: values.start_time,
                  end_time: values.end_time,
                  assesment_duration: values.assesment_duration,
                  user_id: currentUser?.user?.id,
                },
              ],
              schedule_id: selectedSchedule?.value || currentUser?.schedule_id,
            },
          ]
        }
      } else {
        durations[matchSchedule] = {
          allocated_duration: values.allocated_duration,
          schedule_id: selectedSchedule?.value || currentUser?.schedule_id,
        }
      }
    } else {
      if (isUnicornOrBellOrAscendo) {
        durations = [
          ...durations,
          {
            allocated_duration: values.allocated_duration,
            assessment_type: values.assessment_type,
            list_users: [
              {
                start_time: values.start_time,
                end_time: values.end_time,
                assesment_duration: values.assesment_duration,
                user_id: currentUser?.user?.id,
              },
            ],
            schedule_id: selectedSchedule?.value || currentUser?.schedule_id,
          },
        ]
      } else {
        durations = [
          ...durations,
          {
            allocated_duration: values.allocated_duration,
            schedule_id: selectedSchedule?.value || currentUser?.schedule_id,
          },
        ]
      }
    }

    const res = await updateCourseInstructors({
      ...course,
      course_instructor: JSON.stringify({ durations }),
      //prices: [],
      // sfc_prices: [],
    })
    if (!listSetOutcomCustomByUser.length && !isDuration) {
      dispatch(hideGlobalLoading())
      return showError('Please grade learning outcomes to set!')
    }
    const requests = []
    for (let i = 0; i < listSetOutcomCustomByUser?.length; i++) {
      requests.push(
        handleSetRemarksOutcome(listSetOutcomCustomByUser[i], values)
      )
    }

    await Promise.all(requests)

    if (res?.data?.id) {
      showSuccess('Update success!')
      dispatch(loadCourse(id))
      dispatch(
        getUserByCourseAndSchedule({
          id: id,
          schedule: selectedSchedule,
        })
      )
      isUnicornOrBellOrAscendo && setVisibleDuration(false)
    }
    dispatch(hideGlobalLoading())
  }

  const handleSetRemarksOutcome = async (item, values) => {
    try {
      const nextPayload = {
        allocated_duration: values?.assesment_duration,
        start_time: values?.start_time,
        end_time: values?.end_time,
      }
      const body = {
        ...item,
        create_uid: get(currentUser, 'uid', 0),
        ...(!isUnicornOrBellOrAscendo && nextPayload),
      }

      const requestURL = `${config.baseUrl}/api/learning-outcome/custom-channels/users`
      await axiosInstance.put(requestURL, body)
    } catch (error) {
      console.log('~ error', error)
    }
  }

  const competent = formik.values.competent

  const columns = [
    {
      key: 'assessmentType',
      title: 'Assessment Type',
      width: 200,
      ellipsis: true,
      render: (record) => record?.assessment_type || '',
    },
    {
      key: 'allocatedDuration',
      title: 'Allocated Duration',
      width: 150,
      ellipsis: true,
      render: (record) => record?.allocated_duration || '',
    },
    {
      key: 'allocatedAssessment',
      title: 'Duration Of Assessment',
      width: 150,
      ellipsis: true,
      render: (record) => record?.assesment_duration || '',
    },
    {
      key: 'startTime',
      title: 'Start Time',
      width: 100,
      ellipsis: true,
      render: (record) =>
        record?.start_time ? dayjs(record?.start_time).format('HH:mm') : '',
    },
    {
      key: 'endTime',
      title: 'End Time',
      width: 100,
      ellipsis: true,
      render: (record) =>
        record?.end_time ? dayjs(record?.end_time).format('HH:mm') : '',
    },
  ]
  return (
    <OutcomeWrapper>
      {!addOutcome && (
        <BtnPrimary
          name="Add a outcome"
          style={{
            backgroundColor: '#F6F6F9',
            fontWeight: 'bold',
            color: '#27284D',
            padding: '8px 10px',
            margin: '20px',
          }}
          handleClick={handleShowAddOutcome}
          iconLeft={images.admin.plus}
        />
      )}

      {addOutcome && (
        <Wrapper style={{ margin: '20px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Input
              name="learning_outcome"
              placeHolder="Enter Learning Outcome"
              formik={formik}
              value={formik.values.learning_outcome}
              onChange={formik.handleChange}
              autoComplete="off"
              label="Learning Outcome"
            />
            <div>
              <div className="field__label">Assessment Criteria</div>
              <textarea
                className="add-course__textarea mgbt-0"
                name="assessment_criteria"
                cols="30"
                rows="6"
                placeholder="Enter Assessment Criteria"
                value={formik.values.assessment_criteria}
                onChange={formik.handleChange}
              ></textarea>
            </div>
            <div>
              <div className="field__label">Type</div>
              <Dropdown
                options={INITIAL_TYPE_OUTCOME}
                onChange={onSelect}
                value={formik.values.type}
                placeholder="All Types"
                className="dropdownScheduleModal"
              />
            </div>

            <div>
              <div className="field__label">Marking</div>
              <FlexWrapper>
                <div
                  className={`quiz__head__check ${
                    competent === true ? 'quiz__head__check-active' : ''
                  }`}
                  onClick={handleMarking}
                >
                  <img
                    src={
                      competent === true || competent === null
                        ? images.header.check
                        : images.header.check_inactive
                    }
                    alt=""
                  />
                </div>
                <div
                  className={`quiz__head__check ${
                    competent === false ? 'quiz__head__check__x-active' : ''
                  }`}
                  onClick={handleUnMarking}
                  style={{ marginLeft: 15 }}
                >
                  <img
                    src={
                      competent === false || competent === null
                        ? images.admin.x
                        : images.admin.x_inactive
                    }
                    alt=""
                  />
                </div>
              </FlexWrapper>
            </div>
            <BtnPrimary
              name="Save"
              style={{
                backgroundColor: '#F6F6F9',
                fontWeight: 'bold',
                color: '#27284D',
                padding: '8px 10px',
                marginTop: 8,
              }}
              type="submit"
              iconLeft={images.admin.plus}
            />
          </form>
        </Wrapper>
      )}

      <div>
        {isUnicornOrBellOrAscendo && (
          <>
            {currentDurations?.length > 0 ? (
              <Table
                dataSource={currentDurations}
                columns={columns}
                rowKey={uniqueId}
                pagination={false}
                className="outcome-table"
              />
            ) : (
              <div className="field__label" style={{ margin: '0 20px' }}>
                Duration
              </div>
            )}
          </>
        )}
        {((isUnicornOrBellOrAscendo && visibleDuration) ||
          !isUnicornOrBellOrAscendo) && (
          <>
            {isUnicornOrBellOrAscendo && (
              <>
                <div style={{ margin: '20px' }}>
                  <div className="field__label">Assessment Type</div>
                  <Dropdown
                    options={assessmentType}
                    onChange={onSelectAssessmentType}
                    placeholder={
                      isEditDuration
                        ? 'Select assessment type to edit'
                        : 'Select assessment type to add duration'
                    }
                    className="dropdownScheduleModal"
                    name="assessment_type"
                  />
                </div>
              </>
            )}
            <div style={{ margin: '20px' }}>
              <div className="field__label">Allocated Duration</div>
              <Input
                name="allocated_duration"
                className="mg-small"
                placeHolder="Enter allocated duration (mins)"
                formik={formik}
                onChange={({ currentTarget }) => {
                  handleNumberInput(currentTarget, 'allocated_duration')
                }}
                style={{ width: 320 }}
                value={formik.values.allocated_duration}
              />
            </div>
            <div style={{ margin: '20px' }}>
              <div className="field__label">Duration of assessment</div>
              <Input
                name="assesment_duration"
                className="mg-small"
                placeHolder="Enter duration of assessment (mins)"
                formik={formik}
                onChange={({ currentTarget }) => {
                  handleNumberInput(currentTarget, 'assesment_duration')
                }}
                style={{ width: 320 }}
                value={formik.values.assesment_duration}
              />
            </div>
            <div className="sc-modal__select-date" style={{ margin: '20px' }}>
              <div className="sc-modal__date">
                <div className="sc-modal__label">START TIME</div>
                <TimePicker
                  format="HH:mm"
                  onChange={(time) => {
                    if (time && formik.values?.assesment_duration) {
                      const newTime = dayjs(time).minute(
                        formik.values?.assesment_duration
                      )
                      formik.setFieldValue('end_time', newTime)
                    }
                    formik.setFieldValue('start_time', time)
                  }}
                  value={formik.values.start_time}
                  allowClear
                  placeholder="Add start time"
                  className="sc-modal__datepicker"
                />
              </div>
              <div className="sc-modal__date mgR-0" style={{ marginRight: 80 }}>
                <div className="sc-modal__label">END TIME</div>
                <TimePicker
                  format="HH:mm"
                  onChange={(time) => {
                    if (time && formik.values?.assesment_duration) {
                      const newTime = dayjs(time).subtract(
                        formik.values?.assesment_duration,
                        'm'
                      )
                      formik.setFieldValue('start_time', newTime)
                    }
                    formik.setFieldValue('end_time', time)
                  }}
                  value={formik.values.end_time}
                  allowClear
                  placeholder="Add end time"
                  className="sc-modal__datepicker"
                />
              </div>
            </div>{' '}
            {isUnicornOrBellOrAscendo && (
              <Space style={{ margin: '0 20px' }}>
                <BtnPrimary
                  name="Cancel"
                  style={{
                    backgroundColor: '#F6F6F9',
                    fontWeight: 'bold',
                    color: '#27284D',
                    padding: '8px 10px',
                    marginTop: 8,
                  }}
                  handleClick={() => {
                    setVisibleDuration(false)
                    setIsEditDuration(false)
                  }}
                />
                <BtnPrimary
                  name="Save"
                  style={{
                    backgroundColor: '#F6F6F9',
                    fontWeight: 'bold',
                    color: '#27284D',
                    padding: '8px 10px',
                    marginTop: 8,
                  }}
                  handleClick={() =>
                    handleSaveAdditional(isEditDuration, isBellDomainOnly)
                  }
                  iconLeft={images.admin.plus}
                />
              </Space>
            )}
          </>
        )}

        {isUnicornOrBellOrAscendo ? (
          !visibleDuration && (
            <>
              <Space style={{ margin: '20px' }}>
                {currentDurations?.length > 0 && (
                  <BtnPrimary
                    name="Edit Duration"
                    style={{
                      backgroundColor: '#F6F6F9',
                      fontWeight: 'bold',
                      color: '#27284D',
                      padding: '8px 10px',
                    }}
                    handleClick={() => {
                      setVisibleDuration(true)
                      setIsEditDuration(true)
                    }}
                    iconLeft={images.admin.pen_alt_active_red}
                    styleIconLeft={{ color: '#27284D' }}
                  />
                )}

                <BtnPrimary
                  name="Add Duration"
                  style={{
                    backgroundColor: '#F6F6F9',
                    fontWeight: 'bold',
                    color: '#27284D',
                    padding: '8px 10px',
                  }}
                  handleClick={() => {
                    setVisibleDuration(true)
                    setIsEditDuration(false)
                  }}
                  iconLeft={images.admin.plus}
                />
              </Space>
            </>
          )
        ) : (
          <BtnPrimary
            name="Save"
            style={{
              backgroundColor: '#F6F6F9',
              fontWeight: 'bold',
              color: '#27284D',
              padding: '8px 10px',
              marginTop: 8,
            }}
            handleClick={handleSaveAdditional}
            iconLeft={images.admin.plus}
          />
        )}
      </div>
      {isBellDomainOnly && currentUser && (
        <div style={{ marginLeft: '20px' }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '250px',
                height: '200px',
                marginTop: '10px',
                marginRight: '20px',
                border: '1px solid rgb(179, 184, 191)',
              }}
              onClick={() => {
                setVisiblePad(true)
                setIsStudentName(true)
              }}
            >
              {signatureStudentName ? (
                <img
                  src={signatureStudentName}
                  alt="signature student's name"
                  style={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              ) : null}
            </div>
            <div
              style={{
                width: '250px',
                height: '200px',
                marginTop: '10px',
                border: '1px solid rgb(179, 184, 191)',
              }}
              onClick={() => {
                setVisiblePad(true)
                setIsStudentName(false)
              }}
            >
              {signatureTrainerName ? (
                <img
                  src={signatureTrainerName}
                  alt="signature trainer's name"
                  style={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              ) : null}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginTop: '10px' }}>
            <BtnPrimary
              name={
                titleButtonStudent
                  ? titleButtonStudent
                  : "Signature Student's Name"
              }
              style={{
                backgroundColor: '#F6F6F9',
                fontWeight: 'bold',
                color: '#27284D',
                padding: '8px 10px',
                width: '250px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                textAlign: 'center',
                marginRight: '12px',
              }}
              handleClick={() => {
                setVisiblePad(true)
                setIsStudentName(true)
              }}
              iconLeft={images.admin.pen_alt_active_red}
              styleIconLeft={{ color: '#27284D' }}
            />
            <BtnPrimary
              name={
                titleButtonTrainer
                  ? titleButtonTrainer
                  : "Signature Trainer's Name"
              }
              style={{
                backgroundColor: '#F6F6F9',
                fontWeight: 'bold',
                color: '#27284D',
                padding: '8px 10px',
                width: '250px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                textAlign: 'center',
              }}
              handleClick={() => {
                setVisiblePad(true)
                setIsStudentName(false)
              }}
              iconLeft={images.admin.pen_alt_active_red}
              styleIconLeft={{ color: '#27284D' }}
            />
          </div>

          <Modal
            visible={visiblePad}
            title={
              <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {isStudentName
                  ? titleButtonStudent
                    ? titleButtonStudent
                    : "Signature Student's Name"
                  : titleButtonTrainer
                  ? titleButtonTrainer
                  : "Signature Trainer's Name"}
              </div>
            }
            footer={null}
            width={'50vw'}
            onCancel={() => {
              setVisiblePad(false)
              clear()
            }}
          >
            <div>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  className: 'signatureCanvas',
                }}
              />
              <Space style={{ display: 'flex', justifyContent: 'end' }}>
                <BtnPrimary
                  name="Clear"
                  style={{
                    backgroundColor: '#F6F6F9',
                    fontWeight: 'bold',
                    color: '#27284D',
                    padding: '8px 10px',
                    marginTop: 8,
                  }}
                  handleClick={() => clear()}
                />
                <BtnPrimary
                  name="Save"
                  style={{
                    backgroundColor: '#F6F6F9',
                    fontWeight: 'bold',
                    color: '#27284D',
                    padding: '8px 10px',
                    marginTop: 8,
                  }}
                  handleClick={() => handleSaveSignature(isStudentName)}
                  iconLeft={images.admin.plus}
                />
              </Space>
            </div>
          </Modal>
        </div>
      )}
    </OutcomeWrapper>
  )
}

export default AddOutcomeCourse
