import React from 'react'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import images from 'Themes/Images'
import { getOptionPrice } from 'utils/helper'
import { formatMoney } from 'utils/helper'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { makeSelectCourseType } from 'container/Admin/selectors'
import { getRawDescription, getDescriptionData } from 'utils/helper'
import { makeSelectListDomain } from 'container/Home/selectors'

import { loadCourseSuccess } from 'container/Home/actions'
import { showVideoPlayer } from 'container/Modal/actions'
import { CourseItemWrap } from '../../customs/ClassCompare/src/components/CourseItem/index'
import imagesClassCompare from '../../customs/ClassCompare/assets/images'
import { isTrainerRole, isAdminRole } from 'utils/userUtils'
import './styles.scss'

const CourseItem = ({ course }) => {
  const currentUser = useSelector(makeSelectCurrentUser())
  const listDomain = useSelector(makeSelectListDomain())

  const userGroups = get(currentUser, 'user_groups', [])
  const isTrainer = isTrainerRole(userGroups)
  const isAdmin = isAdminRole(userGroups)
  const isAdminOrTrainer = isAdmin || isTrainer

  const dispatch = useDispatch()
  const history = useHistory()
  const prices = get(course, 'prices', [])
  const imageUrl = get(course, 'image_url', '')

  const websiteId = get(course, 'website_id')
  const courseDomain =
    listDomain && listDomain.find((item) => item.id === websiteId)

  const courseId = get(course, 'id', '')

  // const price = get(course, 'price', 0)
  // const allowSpecialPrice = get(course, 'is_allow_special_price', false)
  // const specialPrice = get(course, 'special_price', 0)

  const name = get(course, 'name', '')
  const description = getDescriptionData(get(course, 'description', ''))
  const shortDescription = get(course, 'short_description', '')
  const rawDescription = getRawDescription(description)
  const channel_tags = get(course, 'channel_tags', [])
  const course_type = get(course, 'course_type')
  const previewUrl = get(course, 'preview_url', '')

  const courseTypes = useSelector(makeSelectCourseType())
  const type =
    Array.isArray(courseTypes) &&
    courseTypes.find((tag) => tag.id === course_type)
  const course_type_name = get(type, 'name', '')

  const lowestPrice = getOptionPrice(prices)
  const highestPrice = getOptionPrice(prices, 'highest', true)

  const handleClickCourse = () => {
    if (isAdminOrTrainer) {
      return history.push(`/course/channel/${courseId}`)
    } else {
      return history.push(`/course/preview/${courseId}`)
    }
  }

  const handlePlayVideo = () => {
    dispatch(loadCourseSuccess(course))
    dispatch(showVideoPlayer())
  }

  return (
    <CourseItemWrap>
      <div className="course">
        <div
          className="course_img"
          onClick={previewUrl ? () => {} : handleClickCourse}
        >
          <img
            src={imageUrl ? imageUrl : images.course.default_banner}
            alt={name}
          />
          {previewUrl && (
            <div
              className="play_btn"
              onClick={handlePlayVideo}
              style={{ width: 50, height: 50 }}
            >
              <img src={imagesClassCompare.playBtnSmall} alt="play button" />
            </div>
          )}
        </div>
        <div className="course__list-tag">
          {channel_tags.map((tag) => (
            <div className="course__tag" key={tag.tag_id}>
              <span>{tag.tag.name}</span>
            </div>
          ))}
        </div>
        <div className="course_info" onClick={handleClickCourse}>
          <div className="course_cat">{course_type_name}</div>
          <div className="course_title">{name}</div>
          <div className="course_desc" style={{ marginBottom: 85 }}>
            {shortDescription || rawDescription}
          </div>

          <div className="course_fee">
            {highestPrice ? (
              <div className="course_full-fee">
                <span className="course_fee__name">FULL FEE</span>
                <strong>{formatMoney(highestPrice?.price, null)}</strong>
              </div>
            ) : (
              ''
            )}
            {lowestPrice ? (
              <div className="course_subside-fee">
                <span className="course_fee__name">SUBSIDISED FEE</span>
                <strong>{formatMoney(lowestPrice, null)}</strong>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </CourseItemWrap>
  )
}

export default CourseItem
