import { get, isEmpty } from 'lodash'
import bells from './bells_2.jpg'
import { replaceTextForExporting } from 'utils/helper'
import { parseSafe } from 'utils/helper'
import dayjs from 'dayjs'

export const assessmentItemTemplateBells = ({
  doc,
  data,
  listUserProfile,
  listUserStatus,
  allTypes,
  proficieny_name,
  proficieny_id,
  listOutcome,
  listSetOutcomeCustom,
  mergeLearningOutcome,
  listSetOutcomeStudents,
  courseName,
  selectedSchedule,
  course,
  userStatus,
  trainerName,
  trainerPassport,
}) => {
  let countC = 0
  const user = get(data, 'user', '')
  const scheduleID = get(data, 'schedule_id', '')
  const userId = get(user, 'id', '')
  const passport = get(listUserProfile, `[${userId}].passport`, '').slice(-4)
  const name =
    get(listUserProfile, `[${userId}].name`, '') || user?.partner?.name || ''

  const submitMarkDate = get(userStatus, 'write_date', '')
  const additionalData = parseSafe(course?.course_instructor)
  const durations = additionalData?.durations

  // const durationBySchedule =
  //   (selectedSchedule?.value || scheduleID) &&
  //   durations?.filter(
  //     (item) =>
  //       item?.schedule_id &&
  //       item?.schedule_id === (selectedSchedule?.value || scheduleID) &&
  //       item?.user_id === userId
  //   )
  const durationBySchedule =
    (+selectedSchedule?.value >= 0 || +scheduleID >= 0) &&
    durations?.reduce((prev, curr) => {
      if (
        +curr?.schedule_id >= 0 &&
        curr?.schedule_id === (selectedSchedule?.value || scheduleID)
      ) {
        const currentData = curr?.list_users?.find((i) => i?.user_id === userId)
        return [
          ...prev,
          {
            allocated_duration: curr?.allocated_duration,
            assessment_type: curr?.assessment_type,
            schedule_id: curr?.schedule_id,
            ...currentData,
          },
        ]
      }
      return prev
    }, [])

  doc.setFontSize(9)
  doc.text(`<${courseName}>`, 60, 32)
  doc.text(`ASSESSMENT RECORD`, 60, 45)
  doc.addImage(bells, 'JPG', 490, 10, 40, 40)
  doc.setFontSize(12)
  doc.text(
    '_______________________________________________________________________',
    60,
    50
  )
  doc.addImage(bells, 'JPG', 180, 60, 220, 220)

  doc.setFontSize(32)
  doc.text('Assessment Record', 155, 310)
  doc.setFontSize(18)
  doc.text('For:', 270, 340)

  doc.setFontSize(22)
  doc.text(
    `${courseName}
    
${proficieny_name}
${proficieny_id}`,
    300,
    370,
    { maxWidth: 380, align: 'center' }
  )

  doc.autoTable({
    margin: { left: 60, right: 60 },
    startY: 550,
    theme: 'grid',
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
      fontSize: 18,
      textColor: [32, 32, 32],
    },
    body: [
      [
        {
          content: `Approved Assessment Centre: 
BELLS Institute of Higher Learning Pte Ltd`,
        },
      ],
      [
        `Candidate Name: 
${name}`,
      ],
    ],
  })
  doc.addPage()

  doc.setFontSize(24)
  doc.text('Version Control Record', 60, 80)
  doc.autoTable({
    margin: { left: 60 },
    startY: 100,
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 110 },
      1: { cellWidth: 110 },
      2: { cellWidth: 110 },
      3: { cellWidth: 110 },
    },
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
      valign: 'middle',
      halign: 'center',
    },
    headStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      valign: 'middle',
      halign: 'center',
      fillColor: [217, 217, 217],
      textColor: [32, 32, 32],
    },
    head: [
      [
        {
          content: 'Version',
        },
        { content: 'Effective Date' },
        { content: 'Changes' },
        { content: 'Author' },
      ],
    ],
    body: [
      ['1.0', '1 August 2020', 'First Edition', 'Gustin Low'],
      ['1.0', '1 Feb 2021', 'No Change', 'Rudolf Liu'],
      [
        '1.1',
        '3 Feb 2021',
        'Assessment Record changed to reflect advice to learners to use masked NRIC number, indicates Start and End time for each assessment method.',
        'Rudolf Liu',
      ],
      [
        '1.11',
        '31 Mar 2021',
        'Assessment Summary Record final page updated',
        'Rudolf Liu',
      ],
      ['1.0', '22 Sep 2021', 'Changes to ATO name and logo', 'Rudolf Liu'],
    ],
  })

  doc.addPage()

  const trainerId = listSetOutcomeCustom?.find(
    (item) => item.user_id === userId
  )?.create_uid

  const outcome = listSetOutcomeCustom.find(
    (item) => item.user_id === userId && item?.channel_id === course?.id
  )

  let i = 0
  for (let key in allTypes) {
    if (Array.isArray(allTypes[key]) && allTypes[key].length > 0) {
      if (i !== 0) doc.addPage()
      const firstRender = i === 0
      const oldData =
        durationBySchedule?.length &&
        durationBySchedule?.find((item) => item?.assessment_type === key)

      const nextDuration =
        durationBySchedule?.length > 0
          ? oldData
            ? oldData
            : durationBySchedule?.find((item) => !item?.assessment_type)
          : durations?.find(
              (item) => item?.assessment_type === key && !item?.schedule_id
            )
      doc.setFontSize(14)
      doc.text(key === 'null' ? '' : key, 20, firstRender ? 180 : 80)

      doc.setFontSize(11)
      doc.text(
        'This document is to record the evidence gathered for the competency assessment of the following',
        20,
        firstRender ? 220 : 140
      )

      const nricTrainer = get(
        listUserProfile,
        `[${trainerId}].passport`,
        ''
      ).slice(-4)

      doc.setFontSize(11)
      doc.text(
        `candidate for the skill standard, ${proficieny_name} (${proficieny_id}).`,
        20,
        firstRender ? 240 : 160
      )
      // table

      doc.autoTable({
        margin: { left: 20 },
        startY: firstRender ? 280 : 180,
        startX: 20,
        theme: 'plain',
        styles: {},
        columnStyles: {
          0: { cellWidth: 220 },
          1: { cellWidth: 220 },
        },
        bodyStyles: {
          lineWidth: 0.6,
          lineColor: 12,
          minCellHeight: 20,
        },
        body: [
          [
            `Candidate's Name (as in NRIC):`,
            `${get(user, 'partner.name', '')}`,
          ],
          [
            `Candidate's NRIC
(Please use last four figures of NRIC, e.g "SXXXX123A")`,
            `${
              passport
                ? `S/T XXXX${passport.slice(-4).slice(0, 3)}/${passport
                    .slice(-4)
                    .slice(-1)}`
                : ''
            }`,
          ],
          [`Assessor's Name:`, `${trainerName}`],
          [
            `Assessor's NRIC
(Please use last four figures of NRIC, e.g "SXXXX123A")`,
            `${
              trainerPassport
                ? `S/T XXXX${trainerPassport.slice(
                    trainerPassport?.length - 4,
                    trainerPassport?.length - 1
                  )}/${trainerPassport.slice(-1)}`
                : ``
            }`,
          ],
          [
            'Allocated Duration',
            nextDuration?.allocated_duration
              ? `${nextDuration?.allocated_duration} mins`
              : '',
          ],
          [
            'Duration of Assessment',
            nextDuration?.assesment_duration
              ? `${nextDuration?.assesment_duration} mins`
              : outcome?.allocated_duration
              ? `${outcome?.allocated_duration} mins`
              : '',
          ],
          [
            'Start Time',
            nextDuration?.start_time
              ? dayjs(nextDuration?.start_time).format('HH:mm')
              : outcome?.start_time
              ? dayjs(outcome?.start_time).format('HH:mm')
              : '',
          ],
          [
            'End Time',
            nextDuration?.end_time
              ? dayjs(nextDuration?.end_time).format('HH:mm')
              : outcome?.end_time
              ? dayjs(outcome?.end_time).format('HH:mm')
              : '',
          ],
        ],
      })

      // table 1

      doc.autoTable({
        margin: { left: 20 },
        startY: 30 + doc.autoTable.previous.finalY || 0,
        showHead: 'firstPage',
        theme: 'grid',
        rowPageBreak: 'avoid',
        columnStyles: {
          0: { cellWidth: 170 },
          1: { cellWidth: 170 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 115 },
        },
        bodyStyles: {
          lineWidth: 0.6,
          lineColor: 12,
        },
        headStyles: {
          lineWidth: 0.6,
          lineColor: 12,
          valign: 'middle',
          halign: 'center',
          fillColor: [217, 217, 217],
          textColor: [32, 32, 32],
        },
        head: [
          [
            {
              content: 'Learning Outcome',
              rowSpan: 2,
            },
            { content: 'Assessment Criteria', rowSpan: 2 },
            { content: 'Please tick', colSpan: 2 },
            { content: 'Remarks', rowSpan: 2 },
          ],
        ],
        body: [
          [
            '',
            '',
            {
              content: 'C',
              styles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [217, 217, 217],
                textColor: [32, 32, 32],
                fontStyle: 'bold',
              },
            },
            {
              content: 'NYC',
              styles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [217, 217, 217],
                textColor: [32, 32, 32],
                fontStyle: 'bold',
              },
            },
            '',
          ],
          // eslint-disable-next-line no-loop-func
          ...allTypes[key].map((outcome) => {
            const isCustom = outcome.is_custom
            const assessment_criteria = get(outcome, 'assessment_criteria', {})
            const learningOutcome = listOutcome.find(
              (item) => item.id === assessment_criteria.learning_outcome_id
            )
            const customAnswer = listSetOutcomeCustom.find(
              (item) =>
                item.user_id === userId &&
                item.learning_outcome_channel_custom_id === outcome.id
            )

            const answer = listSetOutcomeStudents.find(
              (item) =>
                item.learning_outcome_id === outcome.id &&
                item.user_id === userId
            )

            countC += isCustom
              ? get(customAnswer, 'competent')
                ? 1
                : 0
              : get(answer, 'competent')
              ? 1
              : 0

            let nullAnswer = isCustom
              ? get(customAnswer, 'competent') === null
              : get(answer, 'competent') === null

            if (isCustom && !customAnswer) {
              nullAnswer = true
            }

            if (!isCustom && !answer) {
              nullAnswer = true
            }
            return [
              {
                content: `${
                  isCustom
                    ? get(outcome, 'learning_outcome')
                    : get(learningOutcome, 'outcome', '')
                } `,
              },
              {
                content: `${
                  isCustom
                    ? replaceTextForExporting(
                        get(outcome, 'assessment_criteria')
                      )
                    : replaceTextForExporting(
                        get(assessment_criteria, 'criteria', '')
                      )
                } `,
              },
              {
                content: nullAnswer
                  ? ''
                  : isCustom
                  ? get(customAnswer, 'competent')
                    ? 'C'
                    : ''
                  : get(answer, 'competent')
                  ? 'C'
                  : '',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                },
              },
              {
                content: nullAnswer
                  ? ''
                  : isCustom
                  ? !get(customAnswer, 'competent')
                    ? 'NYC'
                    : ''
                  : !get(answer, 'competent')
                  ? 'NYC'
                  : '',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                },
              },
              {
                content: `${
                  isCustom
                    ? get(customAnswer, 'remark', '')
                    : get(answer, 'remark', '')
                } `,
              },
            ]
          }),
        ],
      })
      i++
    }
  }

  // table 2

  addSummaryPage({
    doc,
    courseName,
    passport,
    user,
    mergeLearningOutcome,
    allTypes,
    listSetOutcomeCustom,
    userId,
    course,
    proficieny_id,
    proficieny_name,
  })
}

export const addSummaryPage = ({
  doc,
  courseName,
  passport,
  user,
  allTypes,
  listSetOutcomeCustom,
  userId,
  course,
  proficieny_id,
  proficieny_name,
}) => {
  doc.addPage()
  doc.setFontSize(9)
  doc.text(`<${courseName}>`, 20, 32)
  doc.text(`ASSESSMENT RECORD`, 20, 45)
  doc.addImage(bells, 'JPG', 490, 10, 40, 40)
  doc.setFontSize(12)
  doc.text(
    '_______________________________________________________________________________',
    20,
    50
  )

  doc.setFontSize(18)
  doc.text(`Assessment Summary Record`, 20, 100)
  doc.setFontSize(12)
  doc.text(`Framework:`, 20, 130)
  doc.text(`Information Technology`, 90, 130)
  doc.setFontSize(12)
  doc.text(`TSC:`, 20, 150)
  doc.text(proficieny_name, 90, 150)
  doc.setFontSize(12)
  doc.text(`TSC Code:`, 20, 170)
  doc.text(proficieny_id, 90, 170)

  doc.autoTable({
    margin: { left: 20 },
    startY: 200,
    startX: 20,
    theme: 'plain',
    styles: {},
    columnStyles: {
      0: { cellWidth: 220 },
      1: { cellWidth: 220 },
    },
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 20,
    },
    body: [
      [`Candidate's Name (as in NRIC):`, `${get(user, 'partner.name', '')}`],
      [
        `Candidate's NRIC
(Please use last four figures of NRIC, e.g "SXXXX123A")`,
        `${
          passport
            ? `S/T XXXX${passport.slice(0, 3)}/${passport.slice(-1)}`
            : ''
        }`,
      ],
    ],
  })

  doc.autoTable({
    margin: { left: 20 },
    startY: doc.autoTable.previous.finalY + 30 || 0,
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 80 },
      1: { cellWidth: 250 },
      2: { cellWidth: 80 },
      3: { cellWidth: 80 },
    },

    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
    },
    headStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      valign: 'middle',
      halign: 'center',
      fillColor: [217, 217, 217],
      textColor: [32, 32, 32],
    },
    head: [
      [
        {
          content: 'Domain',
        },
        {
          content: 'Domain Outcomes',
        },
        {
          content: `Overall Result
C/NYC`,
        },
        { content: 'Remarks' },
      ],
    ],
    body: summaryBody({ allTypes, listSetOutcomeCustom, userId, course }),
    createdCell: function (cell) {
      if (cell?.cell?.rowSpan > 1) {
        cell.cell.styles.fillColor = '#D9D9D9'
      }
    },
  })
}

const summaryBody = ({ allTypes, listSetOutcomeCustom, userId, course }) => {
  const arr = []
  let countC = 0
  for (let key in allTypes) {
    for (let i = 0; i < allTypes[key]?.length; i++) {
      const customAnswer = listSetOutcomeCustom.find(
        (item) =>
          item.user_id === userId &&
          item.learning_outcome_channel_custom_id === allTypes[key][i]?.id
      )

      countC += get(customAnswer, 'competent') ? 1 : 0

      let nullAnswer = get(customAnswer, 'competent') === null

      if (!customAnswer) {
        nullAnswer = true
      }

      if (i === 0) {
        arr.push([
          {
            content: key,
            rowSpan: allTypes[key]?.length,
          },
          allTypes[key][i]?.learning_outcome,
          nullAnswer ? '' : get(customAnswer, 'competent') ? 'C' : 'NYC',

          customAnswer?.remark,
        ])
      } else {
        // eslint-disable-next-line no-sparse-arrays
        arr.push([
          allTypes[key][i]?.learning_outcome,
          nullAnswer ? '' : get(customAnswer, 'competent') ? 'C' : 'NYC',
          customAnswer?.remark,
        ])
      }
    }
  }
  return arr
}
