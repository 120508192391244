import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'
import images from 'Themes/Images'
import styled from 'styled-components'

const AffiliateTrackingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
  color: #ff0d6b;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;

  & .link_to_copy {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #ff0d6b;
    overflow-wrap: anywhere;
    max-width: 260px;
    @media (max-width: 767px) {
      max-width: 240px;
    }
  }
`

const AffiliateLinkCopy = ({ affiliateLink, style, styleLink }) => {
  return (
    <CopyToClipboard
      text={affiliateLink}
      onCopy={() => {
        message.success('Copied')
      }}
    >
      <AffiliateTrackingWrapper style={style}>
        <div className="link_to_copy" style={styleLink}>
          {affiliateLink}
        </div>
        <div>
          <img src={images.share.copy_normal} alt="" />
        </div>
      </AffiliateTrackingWrapper>
    </CopyToClipboard>
  )
}

export default AffiliateLinkCopy
