import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { useSelector } from 'react-redux'
import { isTrainerRole } from 'utils/userUtils'

const useGetTrainer = () => {
  const currentUser = useSelector(makeSelectCurrentUser())
  const userGroups = currentUser?.user_groups || []

  return {
    isTrainer: isTrainerRole(userGroups),
  }
}

export default useGetTrainer
