import React from 'react'
import { useDispatch } from 'react-redux'
import { loadSlidesSuccess } from '../../../../actions'
import CourseCurriculumList from '../CourseCurriculumList'

const CourseCurriculum = ({ slides }) => {
  const dispatch = useDispatch()
  const handleToggleSection = (section) => {
    const newSlides = slides.map((item) => {
      if (item.id === section.id) {
        return { ...item, is_toggle: !item.is_toggle }
      }
      return item
    })

    dispatch(loadSlidesSuccess(newSlides))
  }

  return (
    <div>
      {slides &&
        slides.map((item) => (
          <CourseCurriculumList
            section={item}
            handleToggleSection={handleToggleSection}
          />
        ))}
    </div>
  )
}

export default CourseCurriculum
