import React from 'react'
import { Row, Col, Radio, DatePicker } from 'antd'
import {
  initialNational,
  initialIDType,
  initialSalutation
} from 'utils/constants'
import Dropdown from 'react-dropdown'

import Input from 'component/Input'

const ProfileCourses = ({ formik, handleNumberInput }) => {
  return (
    <>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">
            Full Name (Ensure name is exactly according to NRIC to be eligible
            for grant funding):
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="name"
              placeHolder="Enter your full name"
              formik={formik}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Gender:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Radio.Group
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
              value={formik.values.gender}
              name="add-course__radio-type"
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Date of Birth:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__date-picker">
            <DatePicker
              onChange={(date, dateString) => {
                formik.setFieldValue('date_of_birth', date)
              }}
              allowClear
              value={formik.values.date_of_birth || ''}
              placeholder="Date of Birth"
              className={`sc-modal__datepicker ${
                formik.touched.date_of_birth &&
                formik.errors.date_of_birth &&
                'datepicker__error'
              }`}
            />
            {formik.touched.date_of_birth && formik.errors.date_of_birth && (
              <div className="form__error">{formik.errors.date_of_birth}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Nationality:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Dropdown
              options={initialNational}
              onChange={(value) =>
                formik.setFieldValue('nationality', value.label)
              }
              value={formik.values.nationality}
              placeholder="Select Nationality"
              className="dropdownScheduleModal dropdown"
            />

            {formik.values.nationality === 'Other' && (
              <div style={{ marginTop: 20 }}>
                <Input
                  name="other_nationality"
                  placeHolder="Type another nationality here"
                  formik={formik}
                  value={formik.values.other_nationality}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            {formik.touched.nationality && formik.errors.nationality && (
              <div className="form__error">{formik.errors.nationality}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Salutation:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Dropdown
              options={initialSalutation}
              onChange={(value) =>
                formik.setFieldValue('salutation', value.label)
              }
              value={formik.values.salutation}
              placeholder="Select Salutation"
              className="dropdownScheduleModal dropdown"
            />

            {formik.touched.salutation && formik.errors.salutation && (
              <div className="form__error">{formik.errors.salutation}</div>
            )}
          </div>
        </Col>
      </Row>
      <Row className="profile__row" gutter={30} justify="center" align="top">
        <Col xs={24} md={8}>
          <div className="profile__name">ID Type & Number:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input mgB-20">
            <Dropdown
              options={initialIDType}
              onChange={(value) => formik.setFieldValue('id_type', value.label)}
              value={formik.values.id_type}
              placeholder="Select ID type"
              className="dropdownScheduleModal dropdown"
            />

            {formik.touched.id_type && formik.errors.id_type && (
              <div className="form__error">{formik.errors.id_type}</div>
            )}
          </div>

          <div className="profile__input">
            <Input
              name="passport"
              placeHolder="Enter ID number"
              formik={formik}
              value={formik.values.passport}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Mobile:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="phone"
              placeHolder="Enter Phone No."
              formik={formik}
              value={formik.values.phone}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'phone')
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">
            Do you have any experience in investing:
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Radio.Group
              onChange={(e) => {
                console.log('e', e.target.value)
                formik.setFieldValue('is_experience', e.target.value)
              }}
              value={formik.values.is_experience}
              name="add-course__radio-type"
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProfileCourses
