import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import LeadsPage from './screens/LeadsPage'
import UserManagement from './screens/UserManagement'

const AdminActor = () => {
  const { path } = useRouteMatch()

  return (
    <div className="admin__container">
      <Switch>
        <Route exact path={path}>
          <LeadsPage />
        </Route>
        <Route exact path={`${path}/user-management`}>
          <UserManagement />
        </Route>
      </Switch>
    </div>
  )
}

export default AdminActor
