import React, { useEffect, useState } from 'react'
import { getUserById } from '../../container/Home/saga'
import { get } from 'lodash'

const CourseAffiliateItem = ({ item, refId, listDomain }) => {
  const [accountRef, setAccountRef] = useState()
  const courseId = get(item, 'id')
  const domainCourseId = get(item, 'website_id')
  const courseDomain =
    listDomain && listDomain.find((item) => item.id === domainCourseId)
  const urlCourseDomain = get(courseDomain, 'domain', '')

  const affiliateLink = `${urlCourseDomain}course/preview/${courseId}?ref=${refId}`

  useEffect(() => {
    const getAccount = async () => {
      const account = await getUserById(refId)
      return account
    }
    if (refId) {
      getAccount().then((account) => {
        if (account.data && account.data.length > 0) {
          setAccountRef(account.data[0])
        }
      })
    }
  }, [refId])

  return (
    <div className="block">
      <div className="lead-enroll__label">{item.name}</div>
      <div className="lead-enroll__sources">
        {get(accountRef, 'partner.name', '')}
      </div>
      <div className="lead-enroll__link">{affiliateLink}</div>
    </div>
  )
}

export default CourseAffiliateItem
