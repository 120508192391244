import React, { useEffect, useState, useMemo } from 'react'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { parse } from 'query-string'
import dayjs from 'dayjs'
import Dropdown from 'react-dropdown'
import download from 'downloadjs'
import axios from 'axios'

import images from 'Themes/Images'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'

import HeaderCourseView from '../../screens/CourseView/HeaderCourseView'
import {
  makeSelectCourse,
  makeSelectSlidesOriginal,
} from 'container/Home/selectors'
import { loadCourse, loadSlides } from 'container/Home/actions'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import { mapScheduleToDropDown } from 'utils/helper'
import './styles.scss'
import TableCertificate from './components/TableCertificate/index'
import { showGlobalLoading } from '../../../Modal/actions'
import { hideGlobalLoading } from 'container/Modal/actions'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes'

const CertificatesReviews = () => {
  const dispatch = useDispatch()
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [userStatus, setUserStatus] = useState(null)
  const [listUserStatus, setListUserStatus] = useState([])
  const [profiles, setProfiles] = useState(null)
  const course = useSelector(makeSelectCourse())
  const domain = useSelector(makeSelectDomain())

  const isAscendoDomain =
    get(domain, 'domain', '') === DOMAIN.ASCENDO ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO_2
  const history = useHistory()
  const { pathname, search } = useLocation()
  const parsedSearch = parse(search)
  const requiredSchedule = get(course, 'is_schedule', false)

  const schedules = useSelector(selectors.makeSelectSchedules())
  const slidesOriginal = useSelector(makeSelectSlidesOriginal())
  const listUserByCourse = useSelector(selectors.makeSelectListUserByCourse())

  const initialSelectSchedule = mapScheduleToDropDown(
    schedules,
    false,
    false,
    true
  )
  const { id } = useParams()

  useEffect(() => {
    if (Array.isArray(schedules) && parsedSearch && parsedSearch.schedule) {
      const schedule = schedules.find(
        (item) => item.id === parseInt(parsedSearch.schedule)
      )
      const id = get(schedule, 'id')
      const startDate = get(schedule, 'start_date', '')
      const endDate = get(schedule, 'end_date', '')
      const label = `${dayjs(startDate).format('DD MMM')} - ${dayjs(
        endDate
      ).format('DD MMM YYYY')}`
      setSelectedSchedule({ value: id, label })
    }
  }, [parsedSearch.schedule, schedules])

  useEffect(() => {
    if (id) {
      const isAdmin = true
      dispatch(loadCourse(id))
      dispatch(actions.getSchedules(id, isAdmin))
    }
  }, [id])

  useEffect(() => {
    dispatch(actions.loadListLearningOutcome())
  }, [])

  useEffect(() => {
    if (parsedSearch && parsedSearch.user && Array.isArray(listUserByCourse)) {
      const user = listUserByCourse.find(
        (item) => item.id === parseInt(parsedSearch.user)
      )

      setCurrentUser(user)
    }
  }, [parsedSearch.user, listUserByCourse])

  useEffect(() => {
    const getUserStatus = async (userId) => {
      try {
        const requestUrl = `${config.baseUrl}/api/learning-outcome/channels/users/${userId}`
        const res = await fetch(requestUrl, { method: 'GET' })
        const data = await res.json()

        if (data.data) {
          const status = data.data.find((item) => item.channel_id == id)
          return status
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    const getListUserStatus = async (listUser) => {
      try {
        const requests = []
        for (let i = 0; i < listUser.length; i++) {
          const userId = get(listUser, `[${i}].user.id`, '')
          requests.push(getUserStatus(userId))
        }

        const res = await Promise.all(requests)
        setListUserStatus(res)
      } catch (error) {
        console.log('error', error)
      }
    }

    if (id && listUserByCourse) {
      getListUserStatus(listUserByCourse)
    }
  }, [listUserByCourse, id])

  useEffect(() => {
    if (Array.isArray(listUserByCourse) && listUserByCourse.length > 0) {
      const listUid = listUserByCourse.reduce(
        (acc, curr) =>
          curr.user && curr.user.id
            ? acc + `&user_ids=${get(curr, 'user.id', '')}`
            : acc,
        ''
      )

      dispatch(actions.getListSetOutcomeStudents({ channel_id: id, listUid }))
    }
  }, [listUserByCourse])

  useEffect(() => {
    if (id) {
      dispatch(
        actions.getUserByCourseAndSchedule({ id, schedule: selectedSchedule })
      )
      dispatch(loadSlides(id, false, null, get(selectedSchedule, 'value', '')))
    }
  }, [id, selectedSchedule])

  useEffect(() => {
    if (currentUser && id) {
      const channel_id = id
      const user_id = get(currentUser, 'user.id', '')
      const params = {
        channel_id,
        user_id,
      }

      dispatch(actions.getLearningOutComeByUser(params))
      dispatch(actions.getListSetOutcomeCustom(params))
      dispatch(actions.getListOutcomeStudent(params))
    }
  }, [currentUser, id])

  useEffect(() => {
    const getUserStatus = async (userId) => {
      try {
        const requestUrl = `${config.baseUrl}/api/learning-outcome/channels/users/${userId}`
        const res = await fetch(requestUrl, { method: 'GET' })
        const data = await res.json()

        if (Array.isArray(data.data)) {
          const status = data.data.find((item) => item.channel_id === +id)
          setUserStatus(status)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    if (currentUser && id) {
      getUserStatus(get(currentUser, 'user.id', ''))
    }
  }, [currentUser, id])

  const handleBathGenerate = async () => {
    const data = []
    if (listUserByCourse && listUserByCourse.length > 0) {
      dispatch(showGlobalLoading())
      for (let i = 0; i < listUserByCourse.length; i++) {
        const record = listUserByCourse[i]
        const scheduleStart = get(record, 'schedule.start_date', '')
        const scheduleEnd = get(record, 'schedule.end_date', '')
        const date = `${dayjs(scheduleStart).format('D')}-${dayjs(
          scheduleEnd
        ).format('Do MMMM YYYY')}`
        const totalHours = get(course, 'total_days', '')
        const courseName = get(course, 'name', '')

        const profileName =
          get(profiles, `[${i}][0].name`, '') ||
          get(record, 'user.partner.name', '')

        data.push({ courseName, date, totalHours, profileName })
      }
    }

    let requestUrl = `${config.api}/send-mail/generate-pdf`
    if (isAscendoDomain) {
      requestUrl = `${config.api}/send-mail/generate-pdf-ascendo`
    }
    await axios
      .post(
        requestUrl,
        { data },
        {
          responseType: 'arraybuffer',
        }
      )
      .then(async (response) => {
        download(response.data, 'certificates.zip', 'application/zip')
        return response.data
      })
    dispatch(hideGlobalLoading())
  }

  return (
    <div>
      <HeaderCourseView course={course} />
      <div className="oc-views">
        <div className="channel" style={{ margin: 0, height: 720 }}>
          <div className="channel__head">
            <div className="channel__head__left">
              <div className="channel__chapter">
                COURSE: {get(course, 'name', '')}
              </div>
              <div className="channel__title">Certificate Management</div>
            </div>
            <div
              className="channel__head__right"
              style={{ alignItems: 'center' }}
            >
              <div
                className="channel__head__right__button"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {listUserByCourse && (
                  <div style={{ marginRight: 20 }}>
                    <BtnPrimary
                      name="Batch Generate"
                      icon={images.admin.download}
                      style={{
                        color: '#042580',
                        backgroundColor: '#f6f6f9',
                      }}
                      handleClick={handleBathGenerate}
                      type="button"
                    />
                  </div>
                )}
                {requiredSchedule && (
                  <>
                    <div
                      style={{
                        marginRight: 15,
                        whiteSpace: 'nowrap',
                        fontWeight: '600',
                        fontSize: 12,
                        color: '#27284D',
                      }}
                    >
                      Select batch
                    </div>
                    <div style={{ width: 230 }}>
                      {initialSelectSchedule && (
                        <Dropdown
                          options={initialSelectSchedule}
                          onChange={(value) => {
                            setCurrentUser(null)
                            history.push(pathname + `?schedule=${value.value}`)
                          }}
                          value={selectedSchedule}
                          placeholder="All Schedules"
                          className="dropdownScheduleModal"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <Row>
            <Col xs={24} md={24}>
              <div className="quiz__right-answer-2" style={{ height: 685 }}>
                <TableCertificate
                  course={course}
                  listUserByCourse={listUserByCourse}
                  listUserStatus={listUserStatus}
                  profiles={profiles}
                  setProfiles={setProfiles}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default CertificatesReviews
