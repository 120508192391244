import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'

import CRM from './screens/CRM'
import Courses from './screens/Courses'
import CourseDetails from './screens/CourseDetails'
import CourseView from './screens/CourseView'
import './styles.scss'
import UserManagement from './screens/UserManagement'
import CreateUser from './screens/CreateUser'
import ClientManagement from './screens/ClientManagement'
import OutComeReviews from './components/OutComeReviews'
import CertificatesReviews from './components/CertificatesReviews'
import AdminSettings from '../Auth/screens/AdminSettings/index'
import { Helmet } from 'react-helmet'
import BadgesReview from './components/BadgesReview'
import ClassManagement from './screens/ClassManagement'

const Admin = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <div className="admin__container">
        <Switch>
          <Route exact path={path}>
            <Courses />
          </Route>
          <Route path={`${path}/course/channel/:id`}>
            <CourseDetails />
          </Route>
          <Route path={`${path}/course/channel-views/:id`}>
            <CourseView />
          </Route>
          <Route path={`${path}/course/outcome-views/:id`}>
            <OutComeReviews />
          </Route>
          <Route path={`${path}/course/certificates-views/:id`}>
            <CertificatesReviews />
          </Route>
          <Route path={`${path}/course/badges-views/:id`}>
            <BadgesReview />
          </Route>
          <Route path={`${path}/crm`}>
            <CRM />
          </Route>
          <Route path={`${path}/settings`}>
            <AdminSettings />
          </Route>
          <Route exact path={`${path}/user-management`}>
            <UserManagement />
          </Route>
          <Route exact path={`${path}/user-management/create`}>
            <CreateUser />
          </Route>
          <Route exact path={`${path}/client-management`}>
            <ClientManagement />
          </Route>
          <Route exact path={`${path}/class-management`}>
            <ClassManagement />
          </Route>
        </Switch>
      </div>
    </>
  )
}

export default Admin
