import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import { DOMAIN } from 'src/routes'
import './styles.scss'

const Footer = ({ domain }) => {
  const { search } = useLocation()
  const { student, signup } = parse(search)
  const history = useHistory()

  const isSalesDomain =
    domain.domain === DOMAIN.SALES_AGENT ||
    domain.domain === DOMAIN.SALES_AGENT_STAGING
  const isSuperAdmin =
    domain.domain === DOMAIN.SUPER_ADMIN ||
    domain.domain === DOMAIN.SUPER_ADMIN_STAGING
  const isAscendoDomain =
    domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO

  const isClassCompare = domain.domain === DOMAIN.COURSES_CLASS_COMPARE

  const renderContent = () => {
    if (isSalesDomain) {
      return <div className="footer-wrap" style={{ padding: '8px 0' }} />
    }

    return (
      <div className="footer-wrap">
        <div>
          <div className="footer__terms">
            <div className="footer__copy-right">
              Copyright © 2021 hypeschools
            </div>
            <div className="terms" onClick={handlePrivacy}>
              Privacy Policy
            </div>
            {!isClassCompare && (
              <div className="terms" onClick={handlePrivacy}>
                Terms
              </div>
            )}
          </div>
        </div>
        <div className="footer__powered-by">
          <div className="footer__powered-by-text">Powered by</div>
          <div>
            <img
              src="https://lh3.googleusercontent.com/qIXiX8Q5jQn7vDYu5akJlDcEYrI8Dao22LtXt0-h1PQe-bB-DB5cl4sUvWogL1llurFn3tEvAFA9yqwUMtGxFL8zgWQ-2MGchN-Gg6j5ci2tyWE"
              alt="hypeSchools"
              width="146"
              height="22"
            />
          </div>
        </div>
      </div>
    )
  }
  const handlePrivacy = () => {
    history.push('/terms')
  }
  const renderFooter = () => {
    if (isAscendoDomain) return null
    return (
      <footer
        className={`footer ${
          !student && !signup && isSuperAdmin && 'footer__school'
        }`}
      >
        {renderContent()}
      </footer>
    )
  }
  return renderFooter()
}

export default Footer
