import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'

import images from 'Themes/Images'
import BtnPrimary from '../BtnPrimary'
import { loadListCourse } from 'container/Home/actions'
import { showFormUserModal } from 'container/Modal/actions'
import { loadListUser } from 'container/Admin/actions'
import './styles.scss'

const HeadFilter = ({
  keyWord,
  admin,
  isSuperAdmin,
  userManagement,
  leadsPage,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const history = useHistory()
  const dispatch = useDispatch()

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.currentTarget.value)
  }

  const handelSearch = () => {
    if (leadsPage) {
      return history.push(`/admin?page=1&keyword=${searchValue}`)
    }

    if (isSuperAdmin) {
      dispatch(
        loadListUser({ search: searchValue, superAdmin: true, current: 0 })
      )
    }

    if (admin) {
      dispatch(loadListCourse({ keyword: searchValue, filter: 'sequence' }))
      setSearchValue('')
    } else {
      history.push(`/coursesearch/result/?keyword=${searchValue}`)
      setSearchValue('')
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (leadsPage) {
        return history.push(`/admin?page=1&keyword=${searchValue}`)
      }

      if (isSuperAdmin) {
        dispatch(
          loadListUser({ search: searchValue, superAdmin: true, current: 0 })
        )
      }

      if (admin) {
        dispatch(loadListCourse({ keyword: searchValue, filter: 'sequence' }))
        setSearchValue('')
      } else {
        history.push(`/coursesearch/result/?keyword=${searchValue}`)
        setSearchValue('')
      }
    }
  }

  const handleAddNewUser = () => {
    dispatch(showFormUserModal())
  }

  const renderHeader = () => {
    if (leadsPage) {
      return 'Leads'
    }
    return keyWord ? `Search Results “${keyWord}”` : 'All Courses'
  }

  const renderPlaceholder = () => {
    if (leadsPage) {
      return 'Search Leads'
    }

    return userManagement ? 'Search users' : 'Search Courses'
  }

  return (
    <div
      className="head-filter__container"
      style={{ height: isMobile ? 170 : 130 }}
    >
      <div
        className="head-filter__wrap"
        style={{ paddingTop: 25, maxWidth: userManagement ? 1210 : 1240 }}
      >
        <Row className="head-filter__row">
          <Col xs={0} xl={4}></Col>
          <Col xs={24} xl={userManagement ? 24 : 20}>
            <div className="head-filter">
              <div className="head-filter__left">
                {isSuperAdmin ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 5,
                    }}
                  >
                    <div
                      className="head-filter__title"
                      style={{ marginTop: 8 }}
                    >
                      User management
                    </div>
                    <div
                      className="head-filter__select"
                      style={{ marginLeft: 25 }}
                    >
                      <BtnPrimary
                        name="Add new user"
                        style={{
                          fontWeight: 'bold',
                          padding: '8px 10px',
                        }}
                        handleClick={handleAddNewUser}
                        iconLeft={images.admin.plus}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="head-filter__title">{renderHeader()}</div>
                    <div className="head-filter__select">
                      {!isMobile && (
                        <div className="head-filter__right">
                          <div className="head-filter__search">
                            <img
                              src={images.header.search}
                              alt="search"
                              onClick={handelSearch}
                            />
                            <input
                              type="text"
                              placeholder={renderPlaceholder()}
                              onChange={handleChangeSearchValue}
                              value={searchValue}
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {isMobile && (
              <div className="head-filter__right" style={{ marginTop: 15 }}>
                <div className="head-filter__search">
                  <img
                    src={images.header.search}
                    alt="search"
                    onClick={handelSearch}
                  />
                  <input
                    type="text"
                    placeholder={renderPlaceholder()}
                    onChange={handleChangeSearchValue}
                    value={searchValue}
                    onKeyPress={handleKeyPress}
                    style={{ width: '98%' }}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default HeadFilter
