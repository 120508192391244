import React from 'react'
import images from 'Themes/Images'
import config from '../global-config'
import DefaultRoutes from './DefaultRoutes'
import SuperAdminRoutes from './SuperAdminRoutes'
import ClassCompareRoutes from './ClassCompareRoutes'
import { GA4React } from 'ga-4-react'
import WhatsAppWidget from 'src/components/Whatsapp/src'
import styled from 'styled-components'

export const DOMAIN = {
  PORTAL: 'https://portal.hypeschools.com/',
  ASCENDO_2: 'https://lms.ascendo.edu.sg/',
  ASCENDO: 'https://ascendo.hypeschools.com/',
  SUPER_ADMIN: 'https://admin.hypeschools.com/',
  SUPER_ADMIN_STAGING: 'https://admin-staging.hypeschools.com/',
  ARTOFUNUMBERS_DOMAIN: 'https://artofnumbers.hypeschools.com/',
  UNICORN_DOMAIN: 'https://unicorn.hypeschools.com/',
  VIAFRONTIERS: 'https://viafrontiers.hypeschools.com/',
  DIGITALWORKFORCE: 'https://digitalworkforce.hypeschools.com/',
  FIRSTMEDIA: 'https://firstmedia.hypeschools.com/',
  COURSES_CLASS_COMPARE: 'https://classcompare.sg/',
  ALLQUANT: 'https://allquant.hypeschools.com/',
  IMADVISER: 'https://imadviser.hypeschools.com/',
  AGB_EDUCATION: 'https://bells.hypeschools.com/',
  CYBERQUOTE: 'https://cyberquote.hypeschools.com/',
  COURSES: 'https://courses.hypeschools.com/',
  SINGAPORESKILLS: 'https://singaporeskillsacademies.com/',
  FLC: 'https://flc.hypeschools.com/',
  LMA: 'https://lma.hypeschools.com/',
  WFA: 'https://wfa.hypeschools.com/',
  THERAPADA: 'https://therapada.hypeschools.com/'
}

export const paypalKey = {
  viaFrontiers:
    'AV-unzNG5NkGMF6N9BxjxIN4yST0TsMuiwIyHH_XhJdYOSxGcx_YFIqzO2CgmXjKq04AYMgzp4KH1ulN',
  classCompare:
    'AV-unzNG5NkGMF6N9BxjxIN4yST0TsMuiwIyHH_XhJdYOSxGcx_YFIqzO2CgmXjKq04AYMgzp4KH1ulN',
  allQuant:
    'AVaGPN8J-nifbFkIPcZlclDloj0va4BbuxlhjZV6kuzgmfJAOx1AM72jGur0eEWsC08P23PHqnW6t933',
  default: config.clientIdPaypal
}

export const DOMAIN_ID = {
  SALES_AGENT: 9,
  ARTOFUNUMBERS_DOMAIN: 12,
  UNICORN_DOMAIN: 4,
  VIAFRONTIERS: 8,
  FIRSTMEDIA: 13,
  COURSES_CLASS_COMPARE: 14,
  ALLQUANT: 15,
  IMADVISER: 16,
  AGB_EDUCATION: 6,
  COURSES: 11,
  CYBERQUOTE: 17,
  FLC: 35,
  ASCENDO: 19,
  THERAPADA: 72
}

const ga4react = new GA4React()
ga4react.initialize()

function getFaviconEl() {
  return document.getElementById('favicon')
}

const WhatsAppWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
`

const HypeSchoolRoutes = ({ domain, categories }) => {
  const renderRoutes = () => {
    const ReactPixel = require('react-facebook-pixel')
    switch (domain.domain) {
      case DOMAIN.SUPER_ADMIN:
        return <SuperAdminRoutes />

      case DOMAIN.SUPER_ADMIN_STAGING:
        return <SuperAdminRoutes />

      case DOMAIN.VIAFRONTIERS:
        return (
          <DefaultRoutes categories={categories} normalRoute domain={domain} />
        )

      case DOMAIN.ALLQUANT:
        return (
          <DefaultRoutes categories={categories} normalRoute domain={domain} />
        )

      case DOMAIN.IMADVISER:
        return (
          <DefaultRoutes categories={categories} normalRoute domain={domain} />
        )

      case DOMAIN.ARTOFUNUMBERS_DOMAIN:
        ReactPixel.default.init('843454373167718')
        ga4react.gaCode = 'G-1ESBSRL9JV'
        return (
          <DefaultRoutes categories={categories} normalRoute domain={domain} />
        )

      case DOMAIN.UNICORN_DOMAIN:
        ga4react.gaCode = 'G-KKEXQVZTPE'
        return (
          <DefaultRoutes categories={categories} normalRoute domain={domain} />
        )

      case DOMAIN.COURSES_CLASS_COMPARE:
        const favicon = getFaviconEl()
        favicon.href = images.favicon_classcompare
        document.title = 'ClassCompare'
        ga4react.gaCode = 'G-WRKQXRC3HY'
        return (
          <>
            <ClassCompareRoutes categories={categories} />
            <WhatsAppWrapper>
              <WhatsAppWidget
                phoneNumber="+6588236399"
                companyName="Team ClassCompare"
                sendButton="Start Chat"
              />
            </WhatsAppWrapper>
          </>
        )

      default:
        return (
          <DefaultRoutes categories={categories} normalRoute domain={domain} />
        )
    }
  }

  return renderRoutes()
}

export default HypeSchoolRoutes
