// eslint-disable-next-line
import produce from 'immer'
import * as constants from './constants'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'

export const initialState = {
  course: null,
  slides: null,
  domain: null,
  profile: null,
  previews: null,
  userInfo: null,
  lessonLength: 0,
  listCourse: null,
  userAnswer: null,
  listDomain: null,
  answerTime: null,
  selectedQuiz: null,
  slideDetails: null,
  selectedSlide: null,
  slidesOriginal: null,
  listCourseEnroll: null,
  userPaymentMethod: null,
  listCategory: [],
  userLocation: null,
  refId: null,
  userLog: null,
  userLogCurrentState: null,
  preventLoadRelatedApi: false,
}

const persistConfig = {
  key: 'home',
  storage: localStorage,
  whitelist: ['domain', 'selectedSlide', 'userLocation', 'refId'],
  debounce: 1000,
}

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.SET_SELECTED_SLIDE:
        newState.selectedSlide = action.payload
        break
      case constants.SET_SELECTED_QUIZ:
        newState.selectedQuiz = action.payload
        break
      case constants.LOAD_LIST_COURSE:
        break
      case constants.LOAD_LIST_COURSE_SUCCESS:
        newState.listCourse = action.payload
        break
      case constants.LOAD_LIST_COURSE_FAILED:
        break
      case constants.LOAD_COURSE:
        break
      case constants.LOAD_COURSE_SUCCESS:
        newState.course = action.payload
        break
      case constants.LOAD_COURSE_FAILED:
        break

      case constants.LOAD_SLIDES:
        break
      case constants.LOAD_SLIDES_SUCCESS:
        newState.slides = action.payload
        newState.lessonLength = action.length
        break
      case constants.LOAD_ORIGINAL_SLIDES_SUCCESS:
        newState.slidesOriginal = action.payload
        break
      case constants.LOAD_SLIDES_FAILED:
        break

      case constants.LOAD_PREVIEW_BY_CHANNEL_ID:
        break
      case constants.LOAD_PREVIEW_BY_CHANNEL_ID_SUCCESS:
        newState.previews = action.payload
        break
      case constants.LOAD_PREVIEW_BY_CHANNEL_ID_FAILED:
        break

      case constants.LOAD_SLIDE_DETAILS:
        break
      case constants.LOAD_SLIDE_DETAILS_SUCCESS:
        newState.slideDetails = action.payload
        break
      case constants.LOAD_SLIDE_DETAILS_FAILED:
        break

      case constants.POST_USER_ANSWER:
        break
      case constants.POST_USER_ANSWER_SUCCESS:
        // newState.userAnswer = action.payload;
        break
      case constants.POST_USER_ANSWER_FAILED:
        break

      case constants.LOAD_USER_ANSWER:
        break
      case constants.LOAD_USER_ANSWER_SUCCESS:
        newState.userAnswer = action.payload
        break
      case constants.LOAD_USER_ANSWER_FAILED:
        break

      case constants.LOAD_DOMAIN:
        break
      case constants.LOAD_DOMAIN_SUCCESS:
        newState.domain = action.payload
        break
      case constants.LOAD_LIST_DOMAIN_SUCCESS:
        newState.listDomain = action.payload
        break
      case constants.LOAD_DOMAIN_FAILED:
        break

      case constants.LOAD_USER_LOCATION:
        break
      case constants.LOAD_USER_LOCATION_SUCCESS:
        newState.userLocation = action.payload
        break
      case constants.LOAD_USER_LOCATION_FAILED:
        break

      //get client secret
      case constants.GET_ANSWER_TIME:
        break
      case constants.GET_ANSWER_TIME_SUCCESS:
        newState.answerTime = action.payload
        break
      case constants.GET_ANSWER_TIME_FAILED:
        break

      //get client secret
      case constants.GET_USER_PAYMENT_METHOD:
        break
      case constants.GET_USER_PAYMENT_METHOD_SUCCESS:
        newState.userPaymentMethod = action.payload
        break
      case constants.GET_USER_PAYMENT_METHOD_FAILED:
        break

      case constants.GET_USER_INFO:
        break
      case constants.GET_USER_INFO_SUCCESS:
        newState.userInfo = action.payload
        break
      case constants.GET_USER_INFO_FAILED:
        newState.userInfo = action.payload
        break
      case constants.SET_PREVIEW_COMPLETE:
        break
      case constants.GET_CUSTOMER_INFO:
        break
      case constants.GET_CUSTOMER_INFO_SUCCESS:
        newState.profile = action.payload
        break
      case constants.GET_CUSTOMER_INFO_FAILED:
        break

      case constants.SET_REF:
        newState.refId = action.payload
        break

      case constants.LOAD_LIST_CATEGORY:
        break
      case constants.LOAD_LIST_CATEGORY_SUCCESS:
        newState.listCategory = action.payload
        break
      case constants.LOAD_LIST_CATEGORY_FAILED:
        newState.listCategory = null
        break

      case constants.PUT_USER_LOG:
        break
      case constants.PUT_USER_LOG_SUCCESS:
        newState.userLogCurrentState = action.payload
        break
      case constants.PUT_USER_LOG_FAILED:

      case constants.GET_USER_LOG:
        break
      case constants.GET_USER_LOG_SUCCESS:
        newState.userLog = action.payload
        break
      case constants.GET_USER_LOG_FAILED:
        break

      case constants.PREVENT_LOAD_RELATED_API:
        newState.preventLoadRelatedApi = action.payload
        break
    }
  })

export default persistReducer(persistConfig, reducer)
