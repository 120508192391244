import React, { useState } from 'react'
import dayjs from 'dayjs'
import images from 'Themes/Images'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Popover } from 'antd'

import useComponentVisible from 'utils/hooks'
import { showFormUserModal } from 'container/Modal/actions'
import { userType } from 'utils/helper'

const TableUsers = ({ page, listUser, perPage = 15 }) => {
  const [selectedCourses, setSelectedCourses] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()

  const renderClassName = (id) => {
    const isActive = selectedCourses.find((course) => course.id === id)
    if (isActive) {
      return 'hs-table-active'
    }
    return ''
  }
  const handleBack = () => {
    history.push(`/admin/user-management?page=${Number(page) - 1}`)
  }

  const handleNext = () => {
    history.push(`/admin/user-management?page=${Number(page) + 1}`)
  }

  const handleClickTable = (course) => {
    const isActive = selectedCourses.find((item) => item.id === course.id)
    if (isActive) {
      setSelectedCourses(
        selectedCourses.filter((item) => item.id !== isActive.id)
      )
    } else {
      setSelectedCourses([...selectedCourses, course])
    }
  }

  const handleViewDetails = (item) => {
    dispatch(showFormUserModal(item))
  }

  const handleAssetStudent = (course) => {
    history.push(`/admin/course/outcome-views/${course.id}`)
  }

  const handleViewCourse = (course) => {
    history.push(`/admin/course/channel-views/${course.id}`)
  }
  const handleChange = () => {}

  const handleDeleteCourse = (course) => {
    if (course.id) {
    }
  }

  const renderRole = (item) => {
    const groups = get(item, 'user_groups', [])
    const adminGid = userType(3, groups)
    const saleGid = userType(
      Number(process.env.REACT_APP_SALES_GROUP_ID),
      groups
    )
    const superAdmin = adminGid ? 'Super Admin' : ''
    const salesAgent = saleGid ? ', Sales Agent' : ''
    if (superAdmin && salesAgent) {
      return 'Super Admin, Sales Agent'
    }
    if (superAdmin && !salesAgent) {
      return 'Super Admin'
    }
    if (!superAdmin && salesAgent) {
      return 'Sales Agent'
    }
  }

  const handleShowMenu = (course = {}) => {
    // const listCourses = [...courses];
    // for (let i = 0; i < courses.length; i++) {
    //   if (courses[i].id === course.id) {
    //     listCourses[i].show = !listCourses[i].show;
    //   } else {
    //     listCourses[i].show = false;
    //   }
    // }
    // dispatch(loadListCourseSuccess(listCourses));
  }

  const { ref } = useComponentVisible(false, handleShowMenu)
  return (
    <table className="hs-table">
      <thead className="hs-thead">
        <tr className="hs-row">
          <th>Name</th>
          <th>Role(s)</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Added on</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="hs-tbody">
        {listUser &&
          listUser.map((item) => (
            <tr
              key={item.id}
              className={`hs-row ${renderClassName(item)}`}
              onClick={() => {
                handleClickTable(item)
              }}
            >
              <td className="hs-column" onClick={() => handleViewDetails(item)}>
                {item.partner.name}
              </td>
              <td className="hs-column" onClick={() => handleViewDetails(item)}>
                {renderRole(item)}
              </td>
              <td className="hs-column" onClick={() => handleViewDetails(item)}>
                {get(item, 'mobile', '')}
              </td>
              <td className="hs-column" onClick={() => handleViewDetails(item)}>
                {item.login}
              </td>
              <td className="hs-column" onClick={() => handleViewDetails(item)}>
                {dayjs(item.write_date).format('DD/MM/YYYY')}
              </td>
              <td className="hs-column hs-column-cta">
                <Popover
                  placement="rightTop"
                  overlayClassName="popup_confirm"
                  visible={item.show}
                  content={
                    <div className="popup-cta">
                      <div
                        className="popup-cta__wrap"
                        onClick={() => handleViewCourse(item)}
                      >
                        <div className="popup-cta__text">View course</div>
                        <div className="popup-cta__icon">
                          <img src={images.input.eye_inactive} alt="" />
                        </div>
                      </div>
                      <div
                        className="popup-cta__wrap"
                        onClick={() => {
                          handleShowMenu()
                          handleViewDetails(item)
                        }}
                      >
                        <div className="popup-cta__text">Edit course</div>
                        <div className="popup-cta__icon">
                          <img src={images.admin.pen_alt} alt="" />
                        </div>
                      </div>
                      <div
                        className="popup-cta__wrap"
                        onClick={() => {
                          handleShowMenu()
                          handleAssetStudent(item)
                        }}
                      >
                        <div className="popup-cta__text">Assess Students</div>
                        <div className="popup-cta__icon">
                          <img src={images.admin.pen_alt} alt="" />
                        </div>
                      </div>
                      <div
                        className="popup-cta__wrap"
                        onClick={() => {
                          handleShowMenu()
                          handleDeleteCourse(item)
                        }}
                      >
                        <div className="popup-cta__delete">Delete course</div>
                        <div className="popup-cta__icon">
                          <img src={images.admin.trash_icon} alt="" />
                        </div>
                      </div>
                    </div>
                  }
                  trigger="click"
                >
                  <div
                    ref={ref}
                    style={{ padding: '0 15px' }}
                    onClick={() => handleShowMenu(item)}
                  >
                    <img src={images.admin.ellipsis} alt="" width="5" />
                  </div>
                </Popover>
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td style={{ paddingLeft: '20px' }}>Showing results 1 - 15</td>
          <td colSpan="8">
            <div
              className="pagination"
              style={{
                justifyContent: 'flex-end',
                margin: '14px 0'
              }}
            >
              <button
                className="tertiary-btn"
                onClick={handleBack}
                disabled={Number(page) === 1}
              >
                BACK
              </button>
              <div className="pagination__page">Page</div>
              <input
                type="text"
                className="pagination__input"
                value={page}
                onChange={handleChange}
              />{' '}
              <div className="pagination__total-course"></div>
              <button
                className="tertiary-btn"
                onClick={handleNext}
                disabled={listUser.length < perPage}
              >
                NEXT
              </button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default TableUsers
