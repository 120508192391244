import React, { useState, useEffect, memo } from 'react'
import images from 'Themes/Images'
import Dropdown from 'react-dropdown'
import { get, cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'antd'

import BtnPrimary from 'component/BtnPrimary'
import { makeSelectListOutcome } from '../../selectors'
import { mapListOutcomeToDropdown } from 'utils/helper'
import * as actions from '../../actions'
import { CKEditorWrapper } from 'container/CMS/screens/Article'
import TextEditor from 'src/customs/ClassCompare/src/components/Editor'
import { parseSafe } from 'utils/helper'
import './styles.scss'
import ListChoice from '../../../../component/ListChoice/index'

const ALLOW_ANSWER = [
  {
    name: 'text',
    desc: '<span>Free form</span>',
    icon: images.admin.pen_alt,
    iconActive: images.admin.pen_alt_active
  },
  {
    name: 'attachment',
    desc: '<span>Attachment</span>',
    icon: images.admin.image_black,
    iconActive: images.admin.image_active_black
  },
  // {
  //   name: 'option',
  //   desc: '<span>Choose Answer</span',
  //   icon: images.admin.pdf_black,
  //   iconActive: images.admin.pdf_active_black
  // },
  {
    name: 'url',
    desc: '<span>External links<span>',
    icon: images.admin.link_black,
    iconActive: images.admin.link_active_black
  },
  {
    name: 'learning-outcome',
    desc: '<span>Learning Outcome</span',
    icon: images.admin.pdf_black,
    iconActive: images.admin.pdf_active_black
  }
]

const Quiz = ({ question, questions, idCourse, index, formik }) => {
  const dispatch = useDispatch()
  const [assessmentInput, setAssessmentInput] = useState('')
  const [selectedOutcome, setSelectedOutcome] = useState(null)
  const listOutcome = useSelector(makeSelectListOutcome())
  const initialOutcome = mapListOutcomeToDropdown(listOutcome)
  const types = get(question, 'types', '')
  const arrayType = types.split(',')
  const textQuestion = get(question, 'question', '')
  const assessment_criteria = get(question, 'assessment_criteria', null)
  const multipleChoices = parseSafe(get(question, 'multiple_choices', ''))

  useEffect(() => {
    if (!listOutcome) {
      dispatch(actions.loadListLearningOutcome())
    }
  }, [])

  useEffect(() => {
    if (assessment_criteria && Array.isArray(initialOutcome)) {
      const learning_outcome_id = get(
        assessment_criteria,
        'learning_outcome_id'
      )
      const outcome = get(assessment_criteria, 'criteria', '')
      const learningOutcome = initialOutcome.find(
        (outcome) => outcome.value === learning_outcome_id
      )

      setSelectedOutcome(learningOutcome)
      setAssessmentInput(outcome)
    }
  }, [assessment_criteria, listOutcome])

  const handleSelectType = (format) => {
    const isSelected = arrayType.find((item) => item === format.name)
    const addNew = [...arrayType, format.name].toString()
    const selected = [
      ...arrayType.filter((item) => item !== format.name)
    ].toString()

    const newQuestion = {
      ...question,
      types: isSelected ? selected : addNew
    }

    const indexQuestion = questions.findIndex(
      (item) => item.sequence === question.sequence
    )

    const cloneAllQuestion = [...questions]
    cloneAllQuestion[indexQuestion] = newQuestion

    dispatch(actions.setQuestions(cloneAllQuestion))
  }

  const handleChangeText = (data) => {
    const indexQuestion = questions.findIndex(
      (item) => item.sequence === question.sequence
    )

    const cloneAllQuestion = cloneDeep(questions)

    cloneAllQuestion[indexQuestion].question = data

    dispatch(actions.setQuestions(cloneAllQuestion))
  }

  const handleDelete = () => {
    if (!get(question, 'id') && question.sequence) {
      const cloneAllQuestion = [...questions]
      const filterAllQuestion = cloneAllQuestion.filter(
        (item) => item.sequence !== question.sequence
      )
      return dispatch(actions.setQuestions(filterAllQuestion))
    }

    dispatch(
      actions.createQuiz({
        idCourse,
        old_question_ids: [question.id],
        name: formik.values.name
      })
    )
  }

  const pickedLearningOutcome = arrayType.find(
    (type) => type === 'learning-outcome'
  )

  const handleAddAssessment = () => {
    if (selectedOutcome && selectedOutcome.value && !assessment_criteria) {
      dispatch(
        actions.createAssessment(
          {
            outComeId: selectedOutcome.value,
            criteria: assessmentInput
          },
          question
        )
      )
    } else {
      dispatch(
        actions.createAssessment(
          {
            outComeId: selectedOutcome.value,
            criteria: assessmentInput,
            id: get(assessment_criteria, 'id')
          },

          question
        )
      )
    }
  }

  const handleDeleteAssessment = () => {
    if (get(assessment_criteria, 'id', null)) {
      dispatch(actions.deleteAssessment(assessment_criteria.id))
      setAssessmentInput('')
      setSelectedOutcome(null)
    }
  }
  return (
    <div className="quiz-lesson">
      <div
        className="inner_container_2"
        style={{ padding: '26px 24px 0 24px' }}
      >
        <div className="add-course__description quiz-lesson__description">
          <div className="quiz-lesson__head">
            <span>Question {index + 1} </span>
            <img src={images.admin.trash_icon} alt="" onClick={handleDelete} />
          </div>
          <div style={{ position: 'relative', width: '100%' }}>
            <CKEditorWrapper minHeight={200}>
              {textQuestion || textQuestion === '' ? (
                <TextEditor
                  value={textQuestion}
                  onChange={(data) => {
                    handleChangeText(data)
                  }}
                />
              ) : null}
            </CKEditorWrapper>
          </div>
        </div>
        {multipleChoices ? (
          <div className="multiple_choice">
            <Row style={{ marginBottom: 20 }}>
              <Col sm={20}>
                <div className="multiple_choice__label">Choices</div>
                <div className="multiple_choice__text">
                  You can select more than one correct answer.
                </div>
              </Col>
              <Col sm={4}>
                <div className="multiple_choice__head">CORRECT ANSWER(s)</div>
              </Col>
            </Row>
            <ListChoice
              listChoice={multipleChoices}
              dispatch={dispatch}
              questions={questions}
              questionIndex={index}
            />
          </div>
        ) : (
          <div className="quiz-lesson__allow-answer">
            <div className="add-course__head" style={{ marginBottom: 5 }}>
              ALLOWED ANSWER FORMAT(S)
            </div>
            <div className="quiz-lesson__allow-answer__text">
              You may select more than one.
            </div>

            <div className="quiz-lesson__allow-answer__format-list">
              {ALLOW_ANSWER.map((item) => {
                const isPickType = arrayType.find((type) => type === item.name)
                return (
                  <div
                    className="quiz-lesson__allow-answer__format-item"
                    onClick={() => handleSelectType(item)}
                    key={item.name}
                  >
                    {isPickType && (
                      <div
                        className="quiz-lesson__allow-answer-checked"
                        style={{
                          background: `url(${images.admin.check_with_bg})`,
                          backgroundRepeat: 'no-repeat'
                        }}
                      />
                    )}

                    <div className="quiz-lesson__allow-answer__format-type">
                      <img
                        src={isPickType ? item.iconActive : item.icon}
                        alt=""
                      />
                    </div>
                    <div
                      className="quiz-lesson__allow-answer__format-text"
                      dangerouslySetInnerHTML={{
                        __html: item.desc
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
            {question.error && (
              <div
                className="form__error"
                style={{ paddingLeft: 0, paddingTop: 8 }}
              >
                {question.error}
              </div>
            )}

            {pickedLearningOutcome && (
              <div className="quiz-lesson__learning-outcome">
                <div style={{ width: '80%' }}>
                  <Dropdown
                    options={initialOutcome}
                    onChange={(value) => setSelectedOutcome(value)}
                    placeholder="Select Learning Outcome"
                    value={selectedOutcome}
                    className="dropdownScheduleModal"
                  />
                </div>
                <div style={{ width: '80%' }}>
                  {selectedOutcome && (
                    <>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <div style={{ flex: 1 }}>
                          <div className="field__label">
                            Assessment Criteria
                          </div>
                          <textarea
                            className="add-course__textarea mgbt-0"
                            name="assessment"
                            cols="30"
                            rows="6"
                            placeholder="Enter Assessment Criteria"
                            value={assessmentInput}
                            onChange={(e) =>
                              setAssessmentInput(e.currentTarget.value)
                            }
                          ></textarea>
                        </div>
                        {assessment_criteria && (
                          <span
                            style={{
                              marginLeft: 15,
                              marginBottom: 5,
                              fontSize: 12,
                              fontWeight: 'bold',
                              cursor: 'pointer'
                            }}
                            onClick={handleDeleteAssessment}
                          >
                            <img
                              src={images.admin.x}
                              alt=""
                              style={{ marginBottom: 2 }}
                            />{' '}
                            delete
                          </span>
                        )}
                      </div>
                      <BtnPrimary
                        style={{ marginTop: 15 }}
                        name={`${assessment_criteria ? 'Save' : 'Add'}`}
                        type="button"
                        handleClick={handleAddAssessment}
                        iconLeft={images.admin.plus}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          background: '#e7ebf4',
          height: 2,
          width: '100%',
          marginTop: 20
        }}
      ></div>
    </div>
  )
}

export default Quiz
