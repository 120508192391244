import { createSelector } from 'reselect'

const selectCheckout = (state) => state.checkout

export const makeSelectListSetOutcomeCustom = () =>
  createSelector(selectCheckout, (subState) => subState.listSetOutcomeCustom)

export const makeSelectSelectedSchedule = () =>
  createSelector(selectCheckout, (subState) => subState.selectedSchedule)

export const makeSelectSelectedPriceSFC = () =>
  createSelector(selectCheckout, (subState) => subState.selectedPriceSFC)

export const makeSelectSelectedModuleSFC = () =>
  createSelector(selectCheckout, (subState) => subState.selectedModuleSFC)

export const makeSelectInvoiceNumber = () =>
  createSelector(selectCheckout, (subState) => subState.invoiceNumber)

export const makeSelectClient = () =>
  createSelector(selectCheckout, (subState) => subState.client)
