import React, { useState } from 'react'
import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import { useDispatch } from 'react-redux'
import { showFormUserModal } from 'container/Modal/actions'
import { loadListUser } from 'container/Admin/actions'

const HeaderLeadsPage = ({ title }) => {
  const [searchValue, setSearchValue] = useState()
  const dispatch = useDispatch()

  const renderHeaderFunction = () => {
    if (title === 'User management') {
      return (
        <>
          <div className="mgL-20">
            <button
              className="primary-btn custom-btn"
              onClick={handleAddNewUser}
            >
              <img src={images.admin.plus_green} alt="" />
              <span>Add new user</span>
            </button>
          </div>
          <div className="head-filter__right">
            <div className="head-filter__search">
              <img
                src={images.header.search}
                alt="search"
                onClick={handleSearch}
                style={{ top: 11 }}
              />
              <input
                type="text"
                placeholder="Search User By Email"
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                value={searchValue}
                onKeyPress={handleKeyPress}
                style={{ height: 37, cursor: 'auto' }}
              />
            </div>
          </div>
        </>
      )
    }
  }
  const handleSearch = () => {
    dispatch(loadListUser({ search: searchValue, current: 1 }))
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter')
      dispatch(loadListUser({ search: searchValue, current: 1 }))
  }
  const handleAddNewUser = () => {
    dispatch(showFormUserModal())
  }

  return (
    <div className="header__courses">
      <div className="header__intro" style={{ paddingLeft: 48 }}>
        <div className="header__course">
          <div className="header__course__level">SUPERADMIN</div>
          <div className="header__course__title__box">
            <div className="header__course__title admin-courses">
              <div className="admin-courses__title">{title}</div>
            </div>
            {renderHeaderFunction()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderLeadsPage
