import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Modal } from 'antd';
import { useFormik } from 'formik';
import Dropdown from 'react-dropdown';

import images from 'Themes/Images';
import Input from 'component/Input';
import BtnPrimary from 'component/BtnPrimary';
import { ERROR_MESSAGE } from 'utils/constants';
import { hideFormOutcomeModal } from '../../actions';
import {
  createLearningOutcome,
  loadAssessment,
  deleteLearningOutcome
} from '../../../Admin/actions';
import './styles.scss';
import { INITIAL_TYPE_OUTCOME } from '../../../Admin/components/OutComeReviews/components/EditType';

const OutcomeSchema = Yup.object().shape({
  outcome: Yup.string().required(ERROR_MESSAGE),
  domain: Yup.string().required(ERROR_MESSAGE),
  domain_id: Yup.string().required(ERROR_MESSAGE)
});

const FormOutcomeModal = ({ showFormOutcomeModal, dispatch, outCome }) => {
  const formik = useFormik({
    initialValues: {
      outcome: '',
      domain: '',
      domain_id: '',
      type: ''
    },

    validationSchema: OutcomeSchema,
    onSubmit: values => {
      if (!outCome) {
        dispatch(createLearningOutcome(values));
      } else {
        dispatch(createLearningOutcome({ ...values, id: outCome.id }));
      }
      handleResetField();
    }
  });

  const handleResetField = () => {
    formik.resetForm({});
  };

  useEffect(() => {
    if (outCome) {
      formik.setFieldValue('outcome', outCome.outcome);
      formik.setFieldValue('domain', outCome.domain);
      formik.setFieldValue('domain_id', outCome.domain_id);
      formik.setFieldValue('type', outCome.type);
      dispatch(loadAssessment(outCome.id));
    }
  }, [outCome]);

  const handleCancel = () => {
    if (outCome) {
      handleResetField();
    }
    dispatch(hideFormOutcomeModal());
  };

  const renderIcon = () => {
    if (outCome) {
      return images.header.check;
    }
    return images.admin.plus;
  };

  const handleDeleteOutcome = () => {
    if (outCome) {
      dispatch(deleteLearningOutcome(outCome.id));
      handleCancel();
    }
  };

  const onSelect = data => {
    formik.setFieldValue('type', data.value);
  };

  return (
    <Modal
      visible={showFormOutcomeModal}
      footer={null}
      onCancel={handleCancel}
      className="sc-modal"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="sc-modal__header">
          <div className="sc-modal__heading">
            {outCome ? 'Edit Outcome' : 'Add A Outcome'}
          </div>
        </div>

        <div className="sc-modal__main">
          <div className="sc-modal__outcome-block">
            <Input
              label="Outcome*"
              value={formik.values.outcome}
              onChange={formik.handleChange}
              name="outcome"
              placeHolder="Enter outcome"
              formik={formik}
            />
          </div>
          <div className="sc-modal__outcome-block">
            <div className="field__label">Type</div>
            <Dropdown
              options={INITIAL_TYPE_OUTCOME}
              onChange={onSelect}
              value={formik.values.type}
              placeholder="All Types"
              className="dropdownScheduleModal"
            />
          </div>
          <div className="sc-modal__outcome-block">
            <Input
              label="Domain*"
              value={formik.values.domain}
              onChange={formik.handleChange}
              name="domain"
              placeHolder="Enter domain"
              formik={formik}
            />
          </div>
          <div className="sc-modal__outcome-block">
            <Input
              label="Domain ID*"
              value={formik.values.domain_id}
              onChange={formik.handleChange}
              name="domain_id"
              placeHolder="Enter domain id"
              formik={formik}
            />
          </div>
        </div>

        <div className="sc-modal__footer">
          <div>
            {outCome && (
              <BtnPrimary
                name="Delete"
                style={{
                  backgroundColor: 'unset',
                  color: '#D85025',
                  fontWeight: 'bold',
                  transition: 'all 0.2s'
                }}
                iconLeft={images.admin.trash_icon}
                type="button"
                handleClick={handleDeleteOutcome}
              />
            )}
          </div>
          <BtnPrimary
            name={`${outCome ? 'Save Outcome' : 'Create Outcome'}`}
            style={{
              backgroundColor: '#00122A',
              padding: 12,
              fontWeight: 'bold',
              transition: 'all 0.2s'
            }}
            type="submit"
            iconLeft={renderIcon()}
          />
        </div>
      </form>
    </Modal>
  );
};

export default FormOutcomeModal;
