import styled from 'styled-components'

export const CourseCurriculumItemWrap = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #77838f;
  padding: 20px 30px;
  border-top: 1px solid #e7e7ec;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
  }
  .curriculum__item-left {
    display: flex;
    & img {
      padding-right: 12px;
    }
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  .curriculum__item-right {
    display: flex;
  }
  .curriculum__item-question {
    background: rgba(255, 199, 139, 0.4);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #2f2d51;
    opacity: 0.7;
    margin-right: 20px;
  }
  .curriculum__item-total-hours {
    background: rgba(25, 110, 205, 0.1);
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #196ecd;
    opacity: 0.7;
    padding: 0 10px;
    margin-right: 20px;
  }
`
