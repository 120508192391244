import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { DOMAIN } from 'src/routes'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { makeSelectDomain } from 'container/Home/selectors'
import { makeSelectCourse } from 'container/Home/selectors'
import { get } from 'lodash'
import './styles.scss'

const CheckoutHeadClassCompare = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-top: 40px;
  }
  .text {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #999999;
  }
  .course-name {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #666666;
  }
`
const HsSteps = ({ steps, indexActive, failed }) => {
  const domain = useSelector(makeSelectDomain())
  const course = useSelector(makeSelectCourse())
  const courseName = get(course, 'name', '')
  const classCompareDomain = domain.domain === DOMAIN.COURSES_CLASS_COMPARE
  const isSingaporeSkillsAcademies =
    domain && domain.domain === DOMAIN.SINGAPORESKILLS

  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const handleRenderStep = () => {
    if (!Array.isArray(steps)) return null

    const items = []
    steps.forEach((step, i) => {
      const isActive = i <= indexActive
      const isConnectorActive = i < indexActive

      const stepClass = classNames('steps__item', {
        'steps__item--active': isActive,
        'steps__item--active-classcompare': isActive && classCompareDomain,
      })

      const stepTitleClass = classNames('steps__item-title', {
        'steps__item-title--active': i === indexActive,
      })

      let connect = i
      if (i === steps.length - 1) {
        connect = -1
      }

      const connectorClass = classNames(
        'steps__item__container',
        `steps__item__${i}`,
        `steps__connector__item__${connect}`,
        {
          'steps__connector__item-active': isConnectorActive,
          'steps__connector__item-active-classcompare':
            isConnectorActive && classCompareDomain,
          'steps__connector__item-complete': isConnectorActive,
          'steps__connector__item-complete-classcompare':
            isConnectorActive && classCompareDomain,
        }
      )

      let item = (
        <li key={`${i}_item`} className={stepClass} onClick={() => {}}>
          <div className={connectorClass} />
          {!isMobile && <p className={stepTitleClass}>{step.title}</p>}
        </li>
      )

      if (failed && step.title === 'Complete') {
        item = (
          <li key={`${i}_item`} className={stepClass} onClick={() => {}}>
            <div className={`${connectorClass} inactive`} />
            {!isMobile && <p className={stepTitleClass}>{step.title}</p>}
          </li>
        )
      }
      items.push(item)
    })

    return items
  }

  const handleMaxWidthSteps = () => {
    if (isMobile && steps.length === 3) {
      return { maxWidth: '146px' }
    }
    if (isMobile && steps.length === 4) {
      return { maxWidth: '210px' }
    }

    if (isMobile && steps.length === 5) {
      return { maxWidth: '270px' }
    }

    if (steps.length === 3) {
      return { maxWidth: '440px' }
    }
    if (steps.length === 4) {
      return { maxWidth: '610px' }
    }
    if (steps.length === 5) {
      return { maxWidth: '100%' }
    }
  }
  return (
    <div className="steps">
      {classCompareDomain ? (
        <CheckoutHeadClassCompare>
          <div className="text">Enrolment</div>
          <div className="course-name">{courseName}</div>
        </CheckoutHeadClassCompare>
      ) : (
        <h1
          className="hs-checkout__title"
          style={{
            marginTop: isMobile ? 30 : 0,
            marginBottom: isMobile ? 20 : 40,
          }}
        >
          Enrolment
        </h1>
      )}

      <ul
        className={classNames('steps__list', {
          steps__list__singapore: isSingaporeSkillsAcademies || wfaDomain,
        })}
        style={handleMaxWidthSteps()}
      >
        {handleRenderStep()}
      </ul>
    </div>
  )
}

export default HsSteps
