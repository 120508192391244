import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { parse } from 'query-string'
import { get } from 'lodash'
import styled from 'styled-components'

import { getUserInfoByToken, resetPassword } from '../../actions'
import { makeSelectError, makeSelectUserInfo } from '../../selectos'
import { ERROR_MESSAGE } from 'utils/constants'
import { makeSelectDomain } from '../../../Home/selectors'

import ResetPasswordForm from '../ResetPasswordForm'
import { showForgotPasswordModal } from 'container/Modal/actions'
import './styles.scss'

const StudentLoginSchema = Yup.object().shape({
  email: Yup.string().email().required(ERROR_MESSAGE),
  name: Yup.string().required(ERROR_MESSAGE),
  password: Yup.string().required(ERROR_MESSAGE),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required(ERROR_MESSAGE)
})

const AuthMobileWrapper = styled.div`
  padding-bottom: 45px;
`
const LogoMobileWrapper = styled.div`
  width: 100%;
  height: 158px;
  background-color: #e7e9ee;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
`

const ResetPassword = ({ classCompare }) => {
  const dispatch = useDispatch()
  const userInfo = useSelector(makeSelectUserInfo())
  const error = useSelector(makeSelectError())
  const domain = useSelector(makeSelectDomain())
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { search } = useLocation()
  const { signup, student, token } = parse(search)

  useEffect(() => {
    if (token) {
      dispatch(getUserInfoByToken(token))
    } else {
      dispatch(showForgotPasswordModal())
    }
  }, [token])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      confirmPassword: ''
    },
    validationSchema: StudentLoginSchema,
    onSubmit: (values) => {
      dispatch(resetPassword({ ...values, token: token }))
    }
  })

  useEffect(() => {
    if (userInfo) {
      const name = get(userInfo, 'name')
      const email = get(userInfo, 'email')
      formik.setFieldValue('name', name)
      formik.setFieldValue('email', email)
    }
  }, [userInfo])

  const isValid =
    formik.values.password &&
    formik.values.email &&
    formik.values.confirmPassword &&
    formik.values.name

  const renderLogoAuth = () => {
    return (
      <div
        style={
          !isMobile
            ? {
                fontWeight: 'bold',
                fontSize: 24,
                color: '#fff',
                whiteSpace: 'nowrap',
                position: 'absolute',
                top: -50,
                transform: 'translateX(-50%)'
              }
            : {
                fontWeight: 'bold',
                fontSize: 24,
                padding: '0 15px',
                color: !student || classCompare ? '#fff' : '#000'
              }
        }
      >
        {domain && domain.name}
      </div>
    )
  }

  return !isMobile ? (
    <div
      className={`auth__container ${
        !student && !signup && 'auth__school-login'
      }`}
    >
      <div className="auth__wrap">
        <div className={`auth__head ${classCompare ? 'classcompare_bg' : ''}`}>
          <div className="auth--wrap">
            <div className="auth__login">
              <Link to="/auth?login=true&student=true">
                <span>LOG IN</span>
              </Link>
            </div>
            <div className="auth__signup">
              <Link to="/auth?signup=true">
                <button className="quaternary-btn">REGISTER</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="auth__form">
          <Link to="/?page=1&perpage=15">{renderLogoAuth()}</Link>
          <ResetPasswordForm formik={formik} isValid={isValid} error={error} />
        </div>
      </div>
    </div>
  ) : (
    <AuthMobileWrapper>
      <LogoMobileWrapper className={`${classCompare ? 'classcompare_bg' : ''}`}>
        <Link to="/?page=1&perpage=15">{renderLogoAuth(true)}</Link>
      </LogoMobileWrapper>
      <ResetPasswordForm formik={formik} isValid={isValid} error={error} />
    </AuthMobileWrapper>
  )
}

export default ResetPassword
