import { Cascader } from 'antd'
import { uniqBy } from 'lodash'
import { parse } from 'query-string'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const mapGroupsToCascader = (groups, disabledLevel3) => {
  const keys = []

  // eslint-disable-next-line no-unused-expressions
  groups?.forEach((item) => {
    if (item?.level === 1) {
      keys.push({ label: item?.name, value: item?.id, level: 1 })
    }
  })

  // eslint-disable-next-line no-unused-expressions
  keys?.forEach((item, index) => {
    const arrCatLevel1 = uniqBy(
      groups.reduce(
        (acc, curr) =>
          curr.level === 2 && curr.parent_id === item?.value
            ? [
                ...acc,
                {
                  label: curr?.name,
                  value: curr?.id,
                  level: 2,
                },
              ]
            : acc,
        []
      ),
      'label'
    )
    if (arrCatLevel1?.length === 0) {
      keys[index].children = null
    } else {
      keys[index].children = arrCatLevel1
      // eslint-disable-next-line no-unused-expressions
    }
  })

  // eslint-disable-next-line no-unused-expressions
  keys?.forEach((item, index) => {
    if (item?.children) {
      // eslint-disable-next-line no-unused-expressions
      item?.children?.forEach((item, index2) => {
        const arrCatLevel2 = uniqBy(
          groups.reduce(
            (acc, curr) =>
              curr.level === 3 && curr.parent_id === item?.value
                ? [
                    ...acc,
                    {
                      label: curr?.name,
                      value: curr?.id,
                      parentID: curr.parent_id,
                      level: 3,
                      disabled: disabledLevel3,
                    },
                  ]
                : acc,
            []
          ),
          'label'
        )
        if (arrCatLevel2?.length !== 0) {
          keys[index].children[index2]['children'] = arrCatLevel2
        }
      })
    }
  })

  return keys
}

const GroupSelect = ({
  value,
  setValue,
  onChange,
  multiple = true,
  placeholder = 'Select group...',
  data,
  disabledLevel3 = false,
}) => {
  const { search } = useLocation()
  const { level1, level2, level3 } = parse(search)

  useEffect(() => {
    if (level1 && setValue) {
      setValue(
        [
          level1 && parseInt(level1),
          level2 && parseInt(level2),
          level3 && parseInt(level3),
        ].filter((item) => item)
      )
    }
  }, [level1, level2, level3, setValue])

  return (
    <Cascader
      options={mapGroupsToCascader(data, disabledLevel3)}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className="w-full"
      changeOnSelect
      multiple={multiple}
      expandTrigger="hover"
    />
  )
}

export default React.memo(GroupSelect)
