import React, { useEffect, useMemo, useState } from 'react'
import { AboutCourseWrap } from './styles'
import ReactPlayer from 'react-player'
import images from 'Themes/Images'
import { get } from 'lodash'
import { formatMoney } from 'utils/helper'
import { getStudentEnroll } from 'container/Admin/saga'
import { ReactPlayerWrapper } from 'ClassCompare/styles'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

export const PricingTiers = styled.div`
  & > div {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    display: block;
    & > div {
      width: 100%;
    }
  }
`

export const PricingTierItem = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: 'Jost', sans-serif;
  border-bottom: 1px solid #e7e7ec;
  padding-bottom: 10px;
  width: 100%;
  .pricing {
    &__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-transform: capitalize;
      color: #000000;
    }
    &__price {
      font-weight: 500;
      font-size: 22px;
      line-height: 43px;
      text-transform: capitalize;
      color: #2f2d51;
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      color: #4a8f9f;
    }
  }
`

const AboutCourse = ({
  imageUrl,
  course,
  lessonLength,
  handleEnroll,
  renderButtonText,
  fixedAbout,
  schedules,
  isAscendoDomain,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [students, setStudents] = useState(0)
  const previewUrl = get(course, 'preview_url', '')
  const isShowSeatsLeft = get(course, 'is_show_seats_left', false)
  const totalSeatsLeft = useMemo(
    () =>
      schedules?.length > 0
        ? schedules?.reduce((acc, curr) => acc + curr?.seats, 0)
        : false,
    [schedules]
  )
  useEffect(() => {
    const getStudentEnrolled = async () => {
      const enrolledStudents = await getStudentEnroll(0, get(course, 'id'))
      if (enrolledStudents?.data) {
        setStudents(enrolledStudents.data.length)
      }
    }
    if (course) {
      getStudentEnrolled()
    }
  }, [course])

  const renderPrice = () => {
    const optionPrices = get(course, 'prices', [])?.sort((a, b) => {
      return a?.price - b?.price
    })

    const coursePrice = get(course, 'price', 0)
    const specialPrice = get(course, 'special_price', 0)
    const allowSpecialPrice = get(course, 'is_allow_special_price', 0)
    const discountPricePercent = Math.round(
      ((coursePrice - specialPrice) / coursePrice) * 100
    )

    if (Array.isArray(optionPrices) && optionPrices.length > 0) {
      return (
        <div className="pricing_wrap">
          <PricingTiers>
            {optionPrices.map((price, index) => (
              <PricingTierItem key={index}>
                <div className="pricing__label">{price.title}</div>
                <div className="pricing__price">{formatMoney(price.price)}</div>
                <div className="pricing__text">{price.description}</div>
              </PricingTierItem>
            ))}
          </PricingTiers>
        </div>
      )
    }

    if (allowSpecialPrice) {
      return (
        <div className="about-course__prices__wrap">
          <div className="about-course__prices__left">
            <div className="about-course__discount">
              {formatMoney(specialPrice)}
            </div>
            <div className="about-course__original">
              <strike>{coursePrice}</strike>
            </div>
          </div>
          <div className="about-course__prices__left">
            <div className="about-course__percent">
              {discountPricePercent}% off
            </div>{' '}
          </div>
        </div>
      )
    }

    return (
      <div className="about-course__prices__left">
        <div className="about-course__discount">
          {formatMoney(coursePrice, false, isAscendoDomain ? 'SGD' : 'USD', 0)}
        </div>
      </div>
    )
  }

  return (
    <AboutCourseWrap
      style={{
        position: !isMobile && fixedAbout ? 'sticky' : 'unset',
        top: 0,
        animation:
          !isMobile && fixedAbout ? 'fadeIn 250ms linear 250ms forwards' : '',
        opacity: !isMobile && fixedAbout ? 0 : 1,
      }}
    >
      {(!fixedAbout || isMobile) && previewUrl && (
        <div className="about-course__banner">
          <ReactPlayerWrapper marginTop={0}>
            <ReactPlayer
              className="react-player"
              url={previewUrl}
              width="100%"
              height="100%"
              controls={true}
            />
          </ReactPlayerWrapper>
        </div>
      )}
      <div className="about-course__main">
        <div className="about-course__prices">{renderPrice()}</div>
        <div>
          <button className="btn_enroll" onClick={handleEnroll}>
            {renderButtonText()}
          </button>
        </div>
        <div className="about-course__summary">
          <div className="about-course__summary-item">
            <div className="flex">
              <div>
                <img src={images.clock_small} />
              </div>
              <div className="about-course__summary-item-title">Duration</div>
            </div>
            <div className="about-course__summary-item-desc">
              {get(course, 'total_days', '')} Hours
            </div>
          </div>
          <div className="about-course__summary-item">
            <div className="flex">
              <div>
                <img src={images.lectures} />
              </div>
              <div className="about-course__summary-item-title">Lectures</div>
            </div>
            <div className="about-course__summary-item-desc">
              {lessonLength}
            </div>
          </div>
          {/* This part */}
          {!isShowSeatsLeft && totalSeatsLeft ? (
            <div className="about-course__summary-item">
              <div className="flex">
                <div>
                  <img src={images.enrolled} />
                </div>
                <div className="about-course__summary-item-title">
                  Participants
                </div>
              </div>
              <div className="about-course__summary-item-desc">
                {totalSeatsLeft} Seats Left
              </div>
            </div>
          ) : (
            <div className="about-course__summary-item">
              <div className="flex">
                <div>
                  <img src={images.enrolled} />
                </div>
                <div className="about-course__summary-item-title">Enrolled</div>
              </div>
              <div className="about-course__summary-item-desc">
                {students} students
              </div>
            </div>
          )}

          <div className="about-course__summary-item">
            <div className="flex">
              <div>
                <img src={images.certificate} />
              </div>
              <div className="about-course__summary-item-title">
                Certificate
              </div>
            </div>
            <div className="about-course__summary-item-desc">Yes</div>
          </div>
        </div>
      </div>
    </AboutCourseWrap>
  )
}

export default AboutCourse
