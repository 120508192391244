import React, { useRef, useEffect } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'

import images from 'Themes/Images'
import config from 'src/global-config'
import { fileToBase64 } from 'utils/helper'

const FILE_UPLOAD_WRAPPER = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
`

const FILE_UPLOAD = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #e7ebf4;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 16px;
`

const FileUploadComponent = ({
  item,
  file,
  admin,
  field,
  handleGetFile,
  handleRemoveFile,
  handleRemoveFileServer,
}) => {
  const refFile = useRef(null)
  const name = get(item, 'name', '')
  const attachmentId = get(item, 'id', '')
  const url = `${config.baseUrl}/web/content/${attachmentId}?download=true`
  let hasFile = false

  const uploadNewFile = file && file.img
  useEffect(() => {
    if (item && !admin) {
      handleGetFile('', item.name, field)
    }
  }, [item, admin])

  if (attachmentId && file && file.name && !file.img) {
    hasFile = true
  }

  if (admin && item) {
    hasFile = true
  }
  const handleClickFile = () => {
    if (refFile && refFile.current) {
      refFile.current.click()
    }
  }

  const handleChangeFileDoc = (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      const name = get(fileUploaded, 'name', '')

      // if (!SUPPORTED_ALL_FORMATS.includes(type)) {
      //   return showError('Only JPG, PNG PDF, DOCX and XLS accepted.')
      // }

      fileToBase64(fileUploaded).then((result) => {
        if (result) {
          const img = result ? result.split('base64,')[1] : ''
          handleGetFile(img, name, field)
        }
      })
    }
  }

  const handleRemoveAttachment = () => {
    handleRemoveFile(field)
  }
  return admin ? (
    <FILE_UPLOAD_WRAPPER>
      <FILE_UPLOAD onClick={handleClickFile}>
        <img
          src={
            hasFile
              ? images.admin.document_upload_active
              : images.admin.document_upload
          }
          alt=""
        />

        {hasFile ? (
          <div className="add-lesson__text" style={{ color: '#27284d' }}>
            {name}
          </div>
        ) : (
          <div className="add-lesson__text">No file uploaded</div>
        )}
      </FILE_UPLOAD>

      {hasFile && (
        <div
          className="add-lesson__button-2"
          style={{ right: 50, position: 'absolute', marginTop: 0 }}
        >
          <a
            href={url}
            className="btn__download"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            onClick={() => {}}
          >
            <img src={images.admin.download} alt="" />
            <span>Download</span>
          </a>
          <span
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => handleRemoveFileServer(field)}
          >
            <img src={images.admin.trash_icon} alt="" />
          </span>
        </div>
      )}
    </FILE_UPLOAD_WRAPPER>
  ) : (
    <FILE_UPLOAD_WRAPPER>
      <FILE_UPLOAD onClick={handleClickFile}>
        <img
          src={
            hasFile
              ? images.admin.document_upload_active
              : images.admin.document_upload
          }
          alt=""
        />
        {file && file.img && (
          <div className="add-lesson__text" style={{ color: '#27284d' }}>
            {file.name}
          </div>
        )}
        {hasFile ? (
          <div className="add-lesson__text" style={{ color: '#27284d' }}>
            {name}
          </div>
        ) : (
          <>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={refFile}
              onChange={handleChangeFileDoc}
            />
            <div
              className="add-lesson__text"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: 220,
              }}
            >
              {uploadNewFile ? '' : 'No file uploaded'}
            </div>
          </>
        )}
      </FILE_UPLOAD>

      {hasFile && (
        <div
          className="add-lesson__button-2"
          style={{ right: 50, position: 'absolute', marginTop: 0 }}
        >
          <a
            href={url}
            className="btn__download"
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            onClick={() => {}}
          >
            <img src={images.admin.download} alt="" />
            <span>Download</span>
          </a>
        </div>
      )}
      <div
        className="add-lesson__button"
        style={{
          position: 'absolute',
          right: 38,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!hasFile && (
          <button
            className="tertiary-btn"
            style={{
              width: 90,
              padding: '8px 20px',
              marginRight: 10,
            }}
            type="button"
            onClick={() => refFile.current.click()}
          >
            Browse
          </button>
        )}
        <div style={{ cursor: 'pointer' }} onClick={handleRemoveAttachment}>
          <img src={images.admin.trash_icon} alt="" />
        </div>
      </div>
    </FILE_UPLOAD_WRAPPER>
  )
}

export default FileUploadComponent
