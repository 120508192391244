import React from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';

const Pagination = ({ page, perPage, courses, user, listUser, setValue }) => {
  const history = useHistory();

  const handleBack = () => {
    history.push(`/?page=${Number(page) + -1}&perpage=${perPage}`);
  };

  const handleNext = () => {
    history.push(`/?page=${Number(page) + 1}&perpage=${perPage}`);
  };

  const handleBackUser = () => {
    history.push(`/admin/user-management?page=${Number(page) + -1}`);
    setValue('');
  };

  const handleNextUser = () => {
    history.push(`/admin/user-management?page=${Number(page) + 1}`);
    setValue('');
  };

  return (
    <div className="pagination__block">
      <div className="pagination__text">
        {/* Showing results 1 - 15 of 1,403 courses */}
        {!user && 'Showing results 1 - 15'}
      </div>
      <div className="pagination">
        <button
          className="tertiary-btn"
          onClick={user ? handleBackUser : handleBack}
          disabled={Number(page) === 1}
        >
          BACK
        </button>
        <div className="pagination__page">Page</div>
        <input
          type="text"
          className="pagination__input"
          value={page}
          onChange={() => {}}
        />
        <div className="pagination__total-course">{/* of 155 */}</div>
        <button
          className="tertiary-btn"
          disabled={
            user
              ? listUser && listUser.length < perPage
              : courses && courses.length < perPage
          }
          onClick={user ? handleNextUser : handleNext}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};

export default Pagination;
