export function setAdminFirstLogin(token) {
  localStorage.setItem('admin_first_login', token)
}

export function getAdminFirstLogin() {
  return localStorage.getItem('admin_first_login')
}

export function clearAdminFirstLogin() {
  localStorage.removeItem('admin_first_login')
}
