import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BtnPrimary from 'component/BtnPrimary'
import { get } from 'lodash'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { makeSelectListCourse } from 'container/Home/selectors'
import { getUserById } from 'container/Home/saga'
import CourseEnrolled from 'component/CourseEnrolled'
import CourseAffiliateSources from 'component/CourseAffiliateSources'
import config from 'src/global-config'
import { collectCustomerInterest, deleteLead } from '../../actions'
import { loadListCourse } from 'container/Home/actions'

const LeadDetailWrapper = styled.div`
  .lead__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #27284d;
    padding-top: 2px;
    margin-right: 8px;
  }
  .lead__value {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #5a6686;
  }
`

const UserEnrolled = styled.div`
  margin-left: 28px;
  padding: 28px 0;
  border-top: 1px solid #e7ebf4;
  border-bottom: 1px solid #e7ebf4;
  font-family: 'Montserrat', sans-serif;
  color: #5a6686;

  .lead-enroll__label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 4px;
  }

  .lead-enroll__value {
    font-weight: normal;
    font-size: 12px;
    & img {
      margin-right: 6px;
    }
  }
  .block {
    margin-bottom: 12px;
  }
  & > div {
    margin-bottom: 20px;
  }
`

const LeadInfoWrapper = styled.div`
  padding: 28px;
  .block {
    margin-bottom: 30px;
  }
  .lead-enroll__sources {
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #5a6686;
  }

  .lead-enroll__label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #5a6686;
  }

  .lead-enroll__link {
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #a0abc3;
  }
`
const LeadDetailHeadWrapper = styled.div`
  padding: 18px 0 13px 25px;
  background-color: rgba(231, 234, 241, 0.2);
`

const LeadDetailPage = ({
  selectedCustomer,
  onClose,
  page,
  perPage,
  filter
}) => {
  const dispatch = useDispatch()
  const [userInfo, setUserInfo] = useState(null)
  const userId = get(selectedCustomer, 'user.id')
  const listCourse = useSelector(makeSelectListCourse())
  const coursesEnrolled =
    listCourse && listCourse.filter((course) => course.partners.length > 0)

  useEffect(() => {
    let isCancelled = false
    const getUserInfo = async (userId) => {
      try {
        const request = `${config.baseUrl}/api/users/${userId}/profiles`
        const res = await fetch(request, { method: 'GET' })
        const data = await res.json()
        setUserInfo(get(data, 'data'))
        isCancelled = true
      } catch (error) {
        console.log('error', error)
      }
    }

    if (userId && !isCancelled) {
      getUserInfo(userId)
    }

    return () => {
      isCancelled = true
    }
  }, [userId])

  useEffect(() => {
    const loadListCourseByUserPartner = async () => {
      const res = await getUserById(selectedCustomer.user_id)
      const user = get(res, 'data[0]', null)

      if (user && user.partner_id) {
        dispatch(loadListCourse({ partner: user.partner_id, superAdmin: true }))
      }
    }
    if (selectedCustomer) {
      loadListCourseByUserPartner()
    }
  }, [selectedCustomer])

  const handleClickArchive = () => {
    dispatch(
      collectCustomerInterest({
        ...selectedCustomer,
        archive: !selectedCustomer.archive
      })
    )
    onClose()
  }

  const handleDelete = () => {
    dispatch(deleteLead({ id: selectedCustomer.id, page, perPage, filter }))
    onClose()
  }

  return (
    selectedCustomer && (
      <LeadDetailWrapper>
        <LeadDetailHeadWrapper>
          <BtnPrimary
            name="Edit details"
            style={{
              backgroundColor: 'unset',
              border: '2px solid #E7EBF4',
              padding: '10px 20px',
              fontSize: 12,
              color: '#6F84A0',
              marginRight: 20
            }}
          />
          <BtnPrimary
            name={`${selectedCustomer.archive ? 'UnArchive' : 'Archive'}`}
            style={{
              backgroundColor: 'unset',
              border: '2px solid #E7EBF4',
              padding: '10px 12px',
              fontSize: 12,
              color: '#6F84A0',
              marginRight: 20
            }}
            handleClick={handleClickArchive}
          />
          <BtnPrimary
            name="Delete"
            style={{
              backgroundColor: 'unset',
              border: '2px solid #E7EBF4',
              padding: '10px 12px',
              fontSize: 12,
              color: '#6F84A0'
            }}
            handleClick={handleDelete}
          />
        </LeadDetailHeadWrapper>
        <LeadInfoWrapper>
          <div className="block">
            <div className="lead__label">Full Name</div>
            <div className="lead__value">{get(userInfo, '[0].name', '')}</div>
          </div>
          <div className="block">
            <div className="lead__label">Mobile</div>
            <div className="lead__value">{get(userInfo, '[0].phone', '')}</div>
          </div>
          <div className="block">
            <div className="lead__label">Email Address</div>
            <div className="lead__value">{get(userInfo, '[0].email', '')}</div>
          </div>
          <div className="block">
            <div className="lead__label">Added on</div>
            <div className="lead__value">
              {dayjs(get(selectedCustomer, 'write_date')).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className="block">
            <div className="lead__label">Topics</div>
            <div className="lead__value">
              {get(selectedCustomer, 'categories') &&
                get(selectedCustomer, 'categories', []).join(', ')}
            </div>
          </div>
        </LeadInfoWrapper>

        <UserEnrolled>
          <div className="lead__label">
            Enrolled ({coursesEnrolled && coursesEnrolled.length})
          </div>
          <CourseEnrolled coursesEnrolled={coursesEnrolled} />
        </UserEnrolled>

        <LeadInfoWrapper>
          <div className="block">
            <div className="lead__label" style={{ marginBottom: 8 }}>
              Affiliate Sources
            </div>
            <CourseAffiliateSources coursesEnrolled={coursesEnrolled} />
          </div>
        </LeadInfoWrapper>
      </LeadDetailWrapper>
    )
  )
}

export default LeadDetailPage
