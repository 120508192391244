import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ProtectedRoute from 'component/ProtectedRoute'
import HomePage from '../../customs/ClassCompare/src/screens/HomePage'
import CourseListing from '../../customs/ClassCompare/src/screens/CoursesListing/index'
import Checkout from 'container/Checkout'
import Admin from 'container/Admin/index'
import ResetPassword from 'container/Auth/screens/ResetPassword'
import AuthPage from 'container/Auth/index'
import PrivacyPolicy from 'component/PrivacyPolicy/index'
import PageNotFound from 'component/404PageNotFound/index'
import Article from 'container/CMS/screens/Article'
import ListArticle from 'container/CMS/screens/ListArticle'
import ArticleDetail from '../../customs/ClassCompare/src/screens/Article/index'
import CoursePreview from 'container/Home/screens/CoursePreview'

const ClassCompareRoutes = ({ categories }) => {
  return (
    <Switch>
      <Route path="/checkout/:id">
        <Checkout classCompare />
      </Route>
      <ProtectedRoute path="/admin">
        <Admin />
      </ProtectedRoute>
      <Route exact path="/course/channel/:id">
        <CoursePreview isClassCompare />
      </Route>
      <Route exact path="/cms">
        <ListArticle />
      </Route>
      <Route exact path="/cms/:id">
        <Article />
      </Route>
      <Route path="/course/preview/:id">
        <CoursePreview isClassCompare />
      </Route>
      <Route exact path="/web/reset_password">
        <ResetPassword classCompare />
      </Route>
      <Route exact path="/auth">
        <AuthPage classCompare />
      </Route>
      <Route exact path="/terms">
        <PrivacyPolicy />
      </Route>
      <Route path="/category/:id">
        <CourseListing categories={categories} />
      </Route>
      <Route path="/article/:slug">
        <ArticleDetail />
      </Route>
      <Route exact path="/pagenotfound">
        <PageNotFound />
      </Route>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Redirect to="/pagenotfound" />
    </Switch>
  )
}

export default ClassCompareRoutes
