import { cloneDeep } from 'lodash'

export const mapSlidesToSideBar = (slides) => {
  return slides
    .filter((slide) => slide.is_category)
    .map((sidebar) => ({
      ...sidebar,
      is_toggle: false,
      items: slides
        .filter(
          (item) => !item.is_category && item.sequence === sidebar.sequence
        )
        .sort((a, b) => a.sub_sequence - b.sub_sequence)
    }))
}

export const mapTagsToFilterOptions = (tags) => {
  return tags.map((tag) => ({
    value: tag.id,
    label: tag.name
  }))
}

export const mapScheduleToFilterOptions = (tags) => {
  return tags.map((tag) => ({
    value: tag.id,
    label: tag.name
  }))
}

export const mapSlidesToSideBarBlock = (slides, isBlockedLessons, previews) => {
  if (!slides) {
    return []
  }

  // basically reorder the section & lesson -> check preview & locked lesson | open lesson

  if (!isBlockedLessons) {
    return mapSlidesToSideBar(slides)
  }
  let cloneSlides = mapSlidesToSideBar(cloneDeep(slides))

  let isOpen = false
  if (Array.isArray(previews) && previews.length === 0) {
    for (let i = 0; i < cloneSlides.length; i++) {
      if (
        Array.isArray(cloneSlides[i].items) &&
        cloneSlides[i].items.length > 0
      ) {
        for (let j = 0; j < cloneSlides[i].items.length; j++) {
          if (!cloneSlides[i].items[j].is_category && !isOpen) {
            isOpen = true
            cloneSlides[i].items[j] = {
              ...cloneSlides[i].items[j],
              blocked_lesson: false
            }
          } else {
            cloneSlides[i].items[j].is_category
              ? (cloneSlides[i].items[j] = cloneSlides[i].items[j])
              : (cloneSlides[i].items[j] = {
                  ...cloneSlides[i].items[j],
                  blocked_lesson: true
                })
          }
        }
      }
    }

    return cloneSlides
  } else {
    for (let i = 0; i < cloneSlides.length; i++) {
      if (
        Array.isArray(cloneSlides[i].items) &&
        cloneSlides[i].items.length > 0
      ) {
        for (let j = 0; j < cloneSlides[i].items.length; j++) {
          const openSlide =
            Array.isArray(previews) &&
            previews.find(
              (preview) =>
                preview.slide_id === cloneSlides[i].items[j].id &&
                preview.completed
            )

          if (openSlide) {
            cloneSlides[i].items[j] = {
              ...cloneSlides[i].items[j],
              blocked_lesson: false
            }
          } else {
            if (!cloneSlides[i].items[j].is_category && !isOpen) {
              cloneSlides[i].items[j] = {
                ...cloneSlides[i].items[j],
                blocked_lesson: false
              }
              isOpen = true
            } else {
              cloneSlides[i].items[j].is_category
                ? (cloneSlides[i].items[j] = cloneSlides[i].items[j])
                : (cloneSlides[i].items[j] = {
                    ...cloneSlides[i].items[j],
                    blocked_lesson: true
                  })
            }
          }
        }
      }
    }
    return cloneSlides
  }
}
