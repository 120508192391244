import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { useSelector } from 'react-redux'
import { isAdminRole } from 'utils/userUtils'

const useGetClientAdmin = () => {
  const currentUser = useSelector(makeSelectCurrentUser())
  const userClients = currentUser?.user_clients || []
  const userGroups = currentUser?.user_groups || []

  return {
    isClientAdmin: isAdminRole(userGroups) && userClients?.length > 0,
    userClients,
  }
}

export default useGetClientAdmin
