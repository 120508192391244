import React from 'react'
import styles from './Header.module.css'
import Images from 'Themes/Images'

export default function Header({ companyName, textReplyTime, handleOpen }) {
  return (
    <div className={styles.root}>
      <span className={styles.close_btn} onClick={handleOpen}>
        <img src={Images.outline} alt="" />
      </span>
      <div className={styles.logo}>
        <img
          src={Images.favicon_classcompare_apple}
          width={35}
          height={35}
          alt="classcompare icon"
        />
      </div>
      <div className={styles.texts}>
        <span className={styles.texts_h1}>{companyName}</span>
        <span className={styles.texts_span}>{textReplyTime}</span>
      </div>
    </div>
  )
}
