import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import images from '../../../assets/images'
import NavbarClassCompare from '../../components/NavbarClassCompare'
import ListCourse from '../../components/ListCourse'
import ListCat from '../../components/ListCat'
import { Banner, Body, Layout } from '../../../styles'
import { loadListCourse } from 'container/Home/actions'
import { loadListCourseOfSeller } from 'src/customs/SuperAdmin/container/AdminActor/actions'
import { makeSelectListCourse } from 'container/Home/selectors'
import { makeSelectListCourseOfSeller } from 'src/customs/SuperAdmin/container/AdminActor/selectors'
import { makeSelectListCategory } from 'container/Home/selectors'
import { getListArticle } from 'container/CMS/hooks'
import { loadCourseSuccess } from 'container/Home/actions'
import { useMediaQuery } from 'react-responsive'

export const MainDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  @media (max-width: 767px) {
    display: block;
    img {
      width: 100%;
    }
  }
  section {
    flex: 1;
  }
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 100%;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 27px;
    }
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 145.6%;
    font-size: 18px;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .school__logo {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: #5a6686;
    border: 3px solid #fff;
    overflow: hidden;
    margin-right: 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      width: 120px;
      height: 120px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }
  }
`

const HomePage = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const dispatch = useDispatch()
  const listCourses = useSelector(makeSelectListCourse())
  const listCourseOfSeller = useSelector(makeSelectListCourseOfSeller())
  const categories = useSelector(makeSelectListCategory())

  const featuredCourse = useMemo(() => {
    return listCourses && listCourses.filter((course) => course.is_priority)
  }, [listCourses])

  const [listArticle, setListArticle] = useState(null)

  useEffect(() => {
    dispatch(loadListCourseOfSeller({ userId: '738' }))
  }, [])

  useEffect(() => {
    if (Array.isArray(listCourseOfSeller) && listCourseOfSeller.length !== 0) {
      let listIds = ''
      listCourseOfSeller.forEach(
        (item) => (listIds += `&ids=${item.channel_id}`)
      )

      if (listIds) {
        dispatch(
          loadListCourse({
            superAdmin: true,
            listIds
          })
        )
      }
    }
  }, [listCourseOfSeller])

  useEffect(() => {
    let isCancelled = false

    const fetchData = async () => {
      const res = await getListArticle()
      if (!isCancelled && Array.isArray(res)) {
        const data = res.filter((article) => article.status)
        setListArticle(data)
      }
    }

    fetchData()
    return () => {
      isCancelled = true
    }
  }, [])

  useEffect(() => {
    dispatch(loadCourseSuccess(null))
  }, [])

  return (
    <>
      <Layout>
        <Banner
          img={
            isMobile
              ? 'https://lh3.googleusercontent.com/4NwYgtJl5Mj8uvk69BgPFjl-y7wg0CEAZ2AYvikDh9mzbrACqnIfq2s4YWsC5_eaeAPURMKkDxeILa3vdrzT3rseshJhb7p8nxJwLPC2SuTUumNw=s640'
              : 'https://lh3.googleusercontent.com/4NwYgtJl5Mj8uvk69BgPFjl-y7wg0CEAZ2AYvikDh9mzbrACqnIfq2s4YWsC5_eaeAPURMKkDxeILa3vdrzT3rseshJhb7p8nxJwLPC2SuTUumNw=s0'
          }
          height={614}
          heightMobile={714}
        />
        <Body>
          <NavbarClassCompare />
          <MainDescription>
            <div>
              <h1>It’s never too late to learn something new.</h1>
              <h2>
                ClassCompare is the place for you to discover and find the best
                SkillsFuture eligible courses based on your interests.
              </h2>
            </div>
            <div>
              <img
                src="https://lh3.googleusercontent.com/KRBjQgfDIyESmzoBjb84csMjQvTcDhWBD2cNCp8_Zapf9WbmqH8NqQW4jyyH0n5GDvYjEOiytYl2fCJS7HYajq0I7itdls-Bnijp51QmKAoc9pms=s0"
                width={isMobile ? '300' : '455'}
                height={isMobile ? '300' : '325'}
                alt="classcompare logo"
              ></img>
            </div>
          </MainDescription>
          {listArticle && listArticle.length > 0 && (
            <ListCourse
              title="Trending Articles"
              courses={listArticle}
              mgBt={60}
              cmsArticle
            />
          )}
          {featuredCourse && featuredCourse.length > 0 && (
            <ListCourse
              title="Featured Classes"
              courses={featuredCourse}
              mgBt={60}
              activeTitle={listArticle && listArticle.length > 0}
            />
          )}
          <ListCat title="Browse" categories={categories} mgBt={60} />
          <ListCourse title="Latest" activeTitle courses={listCourses} />
        </Body>
      </Layout>
    </>
  )
}

export default HomePage
