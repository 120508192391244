import React from 'react'
import { Popover } from 'antd'
import { get } from 'lodash'
import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import useComponentVisible from 'utils/hooks'
import ReportBooks from './ReportBooks'

const Reports = ({
  listUserAnswer,
  questions,
  course,
  selectedSchedule,
  slideDetails,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)
  const proficieny_name = get(course, 'proficieny_name', '')
  const proficieny_id = get(course, 'proficieny_id', '')
  return (
    <Popover
      placement="bottomLeft"
      overlayClassName="popup_confirm"
      content={
        <div className="popup-cta" style={{ width: 180 }}>
          <ReportBooks
            slideDetails={slideDetails}
            course={course}
            listUserAnswer={listUserAnswer}
            questions={questions}
            proficieny_name={proficieny_name}
            proficieny_id={proficieny_id}
            selectedSchedule={selectedSchedule}
          />
        </div>
      }
      trigger="click"
      visible={isComponentVisible}
    >
      <BtnPrimary
        btnRef={ref}
        name="Export Report"
        icon={images.admin.download}
        style={{ color: '#042580', backgroundColor: '#f6f6f9' }}
        handleClick={() => setIsComponentVisible(!isComponentVisible)}
        type="button"
      />
    </Popover>
  )
}

export default Reports
