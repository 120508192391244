import styled from 'styled-components'

export const AdminLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 210px repeat(auto-fit, minmax(250px, 1fr)); // the width of each column
  grid-template-rows: auto; // row height will auto-adjust to contents
  @media (max-width: 767px) {
    display: block;
  }
  .container_left {
    max-width: 210px;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    background: #e7ebf4;
    @media (max-width: 767px) {
      max-width: 100%;
      min-height: unset;
      height: 100%;
      margin-bottom: 10px;
    }
  }
  .container_right {
    min-height: 90vh;
    width: calc(100% - 2px);
    height: 100%;
    @media (max-width: 767px) {
      margin-top: 20px;
    }
    .inner_container {
      width: 100%;
      max-width: 1230px;
      @media (max-width: 1430px) {
        max-width: 100%;
      }
    }
    .inner_container_2 {
      width: 950px;
      @media (max-width: 1430px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        max-width: 100%;
        min-height: unset;
        height: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .admin_channel {
    width: 100%;
  }
`

export const StudentLayout = styled.div`
  width: 100%;
  background-color: '#E5E5E5';
  display: grid;
  grid-template-columns: 280px repeat(auto-fit, minmax(250px, 1fr)); // the width of each column
  grid-template-rows: auto; // row height will auto-adjust to contents
  @media (max-width: 767px) {
    display: block;
  }
  .container_left {
    min-height: 100vh;
    width: 100%;
    height: 100%;
    @media (max-width: 767px) {
      display: block;
      min-height: unset;
    }
  }
  .container_right {
    min-height: 100vh;
    width: 100%;
    height: 100%;
    @media (max-width: 767px) {
      min-height: unset;
    }
    .inner_container {
      width: 100%;
      max-width: 1230px;
      @media (max-width: 1430px) {
        max-width: 100%;
      }
    }
  }
`
