import React from 'react'
import images from 'Themes/Images'
import { get } from 'lodash'
import dayjs from 'dayjs'

const CourseEnrolled = ({ coursesEnrolled }) => {
  return (
    <div>
      {coursesEnrolled &&
        coursesEnrolled.map((item) => {
          const partners = get(item, 'partners', [])
          const date = get(partners, '[0].create_date')
          return (
            <div className="block" key={item.id}>
              <div className="lead-enroll__label">{item.name}</div>
              <div className="lead-enroll__value">
                <img src={images.admin.green_circle} alt="" /> Enrolled on{' '}
                {dayjs(date).format('DD/MM/YYYY')} • On-going
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default CourseEnrolled
