import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { Row, Col } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import images from 'Themes/Images'
import TableSchedules from '../../../TableSchedules'
import BtnPrimary from 'component/BtnPrimary'
import { getSchedules } from 'container/Admin/actions'
import { showFormSchedule } from 'container/Modal/actions'
import { makeSelectCourse } from 'container/Home/selectors'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import './styles.scss'
import useGetTrainer from '../../../../../../hooks/useGetTrainer'

const Schedules = ({ filter }) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const dispatch = useDispatch()
  const course = useSelector(makeSelectCourse())
  const schedules = useSelector(makeSelectSchedules())
  const currentUser = useSelector(makeSelectCurrentUser())

  const isEmpty =
    !schedules || (Array.isArray(schedules) && schedules.length === 0)
  const { id } = useParams()

  useEffect(() => {
    if (id && page && filter === 'schedules') {
      dispatch(getSchedules(id, true, page))
    }
  }, [id, page, filter])

  const handleAddSchedule = () => {
    dispatch(showFormSchedule())
  }

  const { isTrainer } = useGetTrainer()
  return (
    <Row>
      <Col xs={24} md={24}>
        <div className="list-schedule">
          <div className="list-schedule__top">
            <div className="list-schedule__top__left">
              <div className="list-schedule__course-name">
                {get(course, 'name', '')}
              </div>
              <div className="list-schedule__heading">
                Scheduling {Array.isArray(schedules) && `(${schedules.length})`}
              </div>
            </div>
            <div className="list-schedule__top__right">
              {!isEmpty && !isTrainer && (
                <BtnPrimary
                  name="Add a schedule"
                  style={{
                    backgroundColor: '#F6F6F9',
                    fontWeight: 'bold',
                    color: '#27284D',
                    padding: '8px 10px',
                  }}
                  handleClick={handleAddSchedule}
                  iconLeft={images.admin.plus}
                />
              )}
            </div>
          </div>
          <div>
            <TableSchedules
              schedules={schedules}
              isEmpty={isEmpty}
              dispatch={dispatch}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
              currentUser={currentUser}
              course={course}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Schedules
