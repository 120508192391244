import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet'

import images from 'Themes/Images'
import Input from 'component/Input'
import InputTypePassword from 'component/InputTypePassword'
import { getDomain } from 'utils/validate'
import { makeSelectError } from '../../selectos'
import { ERROR_MESSAGE } from 'utils/constants'
import { Signup, SignupFailed } from '../../actions'
import './styles.scss'

const SignUpSchema = Yup.object().shape({
  password: Yup.string()
    .required(ERROR_MESSAGE)
    .matches(
      /^(?=.*\d).{8,}$/,
      'Your password must contain 8 characters, including at least 1 number.'
    ),
  email: Yup.string().email().required(ERROR_MESSAGE),
  name: Yup.string().required(ERROR_MESSAGE),
})

const SignUpForm = ({ isSingaporeSkillsAcademies }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const error = useSelector(makeSelectError())
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(SignupFailed(''))
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      phone: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      dispatch(Signup(values))
    },
  })
  const isValid = formik.values.password && formik.values.email

  const handleNumberInput = (currentTarget, field) => {
    let regExp = /^[0-9]{1,15}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue(field, currentTarget.value)
    }
  }

  return (
    <>
      <Helmet>
        <title>Signup</title>
      </Helmet>
      <div className="login-form">
        <div className="login-form__head">
          <div className="login-form__text">
            Register with {get(getDomain(), 'name', '')}.
          </div>
          <div className="login-form__label">
            Create an account today and start learning with{' '}
            {get(getDomain(), 'name', '')}.
          </div>
        </div>

        <div className="login-form__wrap">
          <form
            onSubmit={formik.handleSubmit}
            style={{ marginBottom: isMobile ? 0 : 30 }}
          >
            {error && <div className="login-form__error">{error}</div>}
            <div className="form__field">
              <Input
                label="Full Name"
                name="name"
                style={{ padding: 12 }}
                placeHolder="Enter your full name"
                value={formik.values.name}
                formik={formik}
                onChange={formik.handleChange}
              />
            </div>
            {isSingaporeSkillsAcademies && (
              <div className="form__field">
                <Input
                  label="Phone number"
                  name="phone"
                  className="mg-small"
                  placeHolder="Enter your phone number"
                  formik={formik}
                  onChange={({ currentTarget }) => {
                    handleNumberInput(currentTarget, 'phone')
                  }}
                  value={formik.values.phone}
                />
              </div>
            )}

            <div className="form__field">
              <Input
                label="EMAIL ADDRESS"
                name="email"
                style={{ padding: 12 }}
                placeHolder="Enter your email address"
                value={formik.values.email}
                error={error}
                formik={formik}
                onChange={(e) =>
                  formik.setFieldValue(
                    'email',
                    e.currentTarget.value.trim().toLowerCase()
                  )
                }
              />
            </div>

            <div className="form__field">
              <label htmlFor="email" className="form__label">
                CREATE YOUR PASSWORD
              </label>

              <InputTypePassword
                placeholder="Enter your password"
                onChange={(e) =>
                  formik.setFieldValue('password', e.currentTarget.value.trim())
                }
                value={formik.values.password}
                name="password"
                className="form__input"
              />
              {formik.touched.password && formik.errors.password && (
                <div className="form__error">{formik.errors.password}</div>
              )}
            </div>

            <button
              type="submit"
              className={`primary-btn ${!isValid && 'primary-btn-disable'}`}
              style={{ width: '100%' }}
            >
              <span>Register</span>
              {isValid ? (
                <img src={images.course.arrow_right_active} alt="" />
              ) : (
                <img src={images.course.arrow_right} alt="" />
              )}
            </button>
            <div className="auth__policy">
              By signing up, you agree to Hypeschools'{' '}
              <Link to="/terms" className="link-policy">
                Privacy Policy
              </Link>
            </div>
          </form>
        </div>
        <div className="login-form__register">
          <div className="login-form__register__wrap">
            <div className="login-form__register__question">
              Already have an account?
            </div>
            <div className="login-form__register__action">
              Login below to start learning.
            </div>
            <Link to="/auth?login=true&student=true">
              <button className="quaternary-btn">LOGIN</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpForm
