import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { get } from 'lodash'
import { hideDetailsRegistration } from '../../actions'
import { useSelector } from 'react-redux'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes'
import config from 'src/global-config'
import DetailPayment from '../DetailPayment/index'
import './styles.scss'

const ViewDetailsRegistration = ({
  dispatch,
  showDetailsRegistration,
  partner,
}) => {
  const [detailsRegis, setDetailsRegis] = useState(null)

  const domain = useSelector(makeSelectDomain())

  const isFirstMediaDomain = domain && domain.domain === DOMAIN.FIRSTMEDIA

  const handleCancel = () => {
    dispatch(hideDetailsRegistration())
  }
  const course = get(partner, 'course')

  useEffect(() => {
    const fetchPaymentMethod = async (userId) => {
      try {
        const res = await fetch(
          `${config.baseUrl}/api/users/${userId}/payment-method`,
          { method: 'GET' }
        )

        const data = await res.json()

        if (Array.isArray(data.data) && data.data.length !== 0) {
          const result = data.data.filter(
            (item) => item.channel_id === get(partner, 'channel_id')
          )

          setDetailsRegis(result)
        }
      } catch (error) {
        console.log('err', error)
      }
    }

    const userId = get(partner, 'id', '')
    fetchPaymentMethod(userId)
  }, [partner])

  return (
    <Modal
      visible={showDetailsRegistration}
      footer={null}
      onCancel={handleCancel}
      width={920}
    >
      <div className="ld-modal-2">
        <h1 className="ld-modal__heading" style={{ fontWeight: 'bold' }}>
          Registration Details
        </h1>
        {detailsRegis &&
          detailsRegis.map((data, i) => (
            <DetailPayment
              key={i}
              data={data}
              course={course}
              isFirstMediaDomain={isFirstMediaDomain}
              partner={partner}
            />
          ))}

        {!detailsRegis && (
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '100px 0',
            }}
          >
            Empty Data
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ViewDetailsRegistration
