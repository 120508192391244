import React from 'react'
import styled from 'styled-components'
import images from '../../../assets/images'

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 40px 0;
  @media (max-width: 767px) {
    align-items: flex-end;
  }
  .shares {
    width: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    color: #666666;
    @media (max-width: 767px) {
      font-size: 14px;
      width: 150px;
    }
  }
`

const ReviewerWrapper = styled.div`
  display: flex;
  align-items: center;
  .introduce {
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #666666;
      margin-bottom: 5px;
      text-transform: none;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    .text {
      font-weight: normal;
      font-size: 14px;
      color: #999999;
      @media (max-width: 767px) {
        font-size: 11px;
      }
    }
  }
`

const FooterClassCompare = () => {
  return (
    <FooterWrapper>
      <ReviewerWrapper>
        <div style={{ marginRight: 12 }}>
          <img src={images.logo_reviewer_2}></img>
        </div>
        <div className="introduce">
          <div className="title">Mr. CompareHare</div>
          <div className="text">Resident Reviewer</div>
        </div>
      </ReviewerWrapper>
      <div className="shares">
        <div>Share</div>
        <div>
          <img src={images.linkedin} alt="linkedin"></img>
        </div>
        <div>
          <img src={images.facebook} alt="facebook"></img>
        </div>
        <div>
          <img src={images.twitter} alt="twitter"></img>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default FooterClassCompare
