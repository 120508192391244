import React, { useEffect } from 'react'
import SentenceItem from '../SentenceItem'
import { SentenceListWrap } from './styles'
import images from 'Themes/Images'
import { isEmpty } from 'lodash-es'

const SentenceList = ({ listArr, setListArr }) => {
  const handleAddMore = () => {
    const index = listArr[listArr.length - 1].id + 1
    const newArr = [...listArr, { id: index, text: '' }]
    setListArr(newArr)
  }

  const handleRemove = (id) => {
    const newArr = listArr.filter((item) => item.id !== id)
    if (!isEmpty(newArr)) {
      setListArr(newArr)
    }
  }

  const handleChangeText = (target, id) => {
    const value = target.value

    if (value.length > 50) {
      return
    }
    const newArr = listArr.map((item) => {
      if (item.id === id) {
        return { ...item, text: value }
      }
      return item
    })

    setListArr(newArr)
  }

  return (
    <SentenceListWrap>
      <div className="sentence__wrap">
        {listArr &&
          listArr.map(({ text, id }) => (
            <SentenceItem
              text={text}
              id={id}
              key={id}
              handleChangeText={handleChangeText}
              handleRemove={handleRemove}
              handleAddMore={handleAddMore}
            />
          ))}
      </div>
      <img
        className="sentence__add"
        src={images.admin.plus}
        onClick={handleAddMore}
      />
    </SentenceListWrap>
  )
}

export default SentenceList
