import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { makeSelectCourse } from 'container/Home/selectors'
import { get } from 'lodash'
import './styles.scss'
import { hideVideoPlayer } from '../../actions'

const VideoPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  margin-top: ${(props) => `${props.marginTop}px`};
  border-radius: 10px;
  overflow: hidden;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 767px) {
    padding-top: 85.25%;
  }
`

const VideoPlayerModal = ({ dispatch, showVideoPlayer }) => {
  const course = useSelector(makeSelectCourse())

  const videoUrl = get(course, 'preview_url', '')
  const handleCancel = () => {
    dispatch(hideVideoPlayer())
  }

  return (
    <Modal
      visible={showVideoPlayer}
      footer={null}
      onCancel={handleCancel}
      className="video-player__wrap"
    >
      <VideoPlayerWrapper>
        <ReactPlayer
          className="react-player"
          url={videoUrl}
          width="100%"
          height="100%"
          controls={true}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload'
              }
            }
          }}
        />
      </VideoPlayerWrapper>
    </Modal>
  )
}

export default VideoPlayerModal
