import React from 'react'
import { get } from 'lodash'
import ScheduleItem from '../ScheduleItem'
import classNames from 'classnames'

const Schedules = ({
  scheduleRef,
  schedules,
  dispatch,
  history,
  course,
  currentUser,
  classCompare,
  previewPage
}) => {
  return (
    <div className="course-preview__syllabus">
      {!classCompare ||
        (!previewPage && (
          <>
            <div
              className="course-preview__heading"
              style={{ paddingLeft: 20 }}
            >
              Schedule
            </div>
            <div className="course-preview__text" style={{ paddingLeft: 20 }}>
              View and select a schedule suitable for you.
            </div>
          </>
        ))}

      <div
        className={classNames('course-preview__card', {
          'class-compare_schedules': classCompare
        })}
      >
        {schedules &&
          schedules.map((item, i) => {
            if (!get(item, 'active')) {
              return null
            }

            return (
              <ScheduleItem
                dispatch={dispatch}
                key={item.id}
                item={item}
                course={course}
                currentUser={currentUser}
                history={history}
                classCompare={classCompare}
              />
            )
          })}

        {Array.isArray(schedules) && schedules.length === 0 && (
          <h2 style={{ padding: '25px 0' }}>
            There are no classes available currently, please check back later.
          </h2>
        )}
      </div>
      <div ref={scheduleRef} />
    </div>
  )
}

export default Schedules
