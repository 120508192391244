import { takeLatest, call, put } from 'redux-saga/effects'
import config from 'src/global-config'
import request from 'utils/request'

import * as constants from './constants'
import * as actions from './actions'
import { get } from 'lodash'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { showError, showSuccess } from 'utils/notification'

import { loadListUser, getUserData } from 'container/Admin/actions'
import { hideFormUserModal } from 'container/Modal/actions'

function* onLoadListCourseOfSales(action) {
  const userId = action.params.userId

  const requestURL = `${config.baseUrl}/api/sales/channels?user_ids=${userId}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET'
    })

    yield put(actions.loadListCourseOfSellerSuccess(res.data))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onAddCourseToSeller(action) {
  const userId = action.params.userId
  const createUid = action.params.create_uid
  const channelId = action.params.channelId
  const body = {
    channel_id: channelId,
    user_id: userId,
    create_uid: createUid
  }

  const requestURL = `${config.baseUrl}/api/sales/channels`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(body)
    })

    yield put(actions.loadListCourseOfSeller({ userId }))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteCourseOfSeller(action) {
  const id = action.params.id
  const userId = action.params.userId

  const requestURL = `${config.baseUrl}/api/sales/${id}/channels`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE'
    })

    yield put(actions.loadListCourseOfSeller({ userId }))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onCreateOrUpdateUser(action) {
  const needToLoadUsers = action.needToLoadUsers
  const id = action.params.id || ''
  let body = {
    name: action.params.name,
    login: action.params.login,
    password: action.params.password || '',
    mobile: action.params.mobile,
    group_id: action.params.role,
    additional_info: action.params.additional_info || '',
    id: id
  }

  if (!id) {
    delete body.id
  }

  if (!body.password) {
    delete body.password
  }

  if (!body.group_id) {
    delete body.group_id
  }

  if (!body.group_id) {
    delete body.group_id
  }

  const requestURL = `${config.baseUrl}/api/users/signup`

  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(body)
    })

    const error = get(res, 'errors', null)
    const result = get(res, 'data.id', null)

    if (error) {
      const errorEmail = get(error, 'errors.login[0]', '')
      const errorPassword = get(error, 'errors.password[0]', '')
      console.log('error', error)
      yield put(hideGlobalLoading())
      if (errorEmail) {
        return showError(errorEmail)
      }
      if (errorPassword) {
        return showError(errorPassword)
      }
    }
    if (needToLoadUsers) {
      yield put(loadListUser({ current: 1, superAdmin: true }))
    }
    yield put(getUserData(id))
    yield put(hideFormUserModal())
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadListCollection(action) {
  const page = action.params.page
  const keyword = action.params.keyword
  const filter = action.params.filter
  let requestURL = `${config.baseUrl}/api/sales/interests?order_by=id%20desc&page=${page}&per_page=15`

  if (keyword) {
    requestURL += `&name=${keyword}`
  }

  if (filter === 'archive') {
    requestURL += '&archive=true'
  }

  if (filter === 'leads') {
    requestURL += '&archive=false'
  }

  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET'
    })
    const data = res.data
    yield put(actions.loadListCollectionSuccess(data))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteLead(action) {
  const id = action.params.id
  const page = action.params.page
  const perPage = action.params.perPage
  const filter = action.params.filter
  const requestURL = `${config.baseUrl}/api/sales/interests/${id}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE'
    })

    yield put(actions.loadListCollection({ page, perPage, filter }))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onCollectCustomerInterest(action) {
  const name = action.params.name || ''
  const mobile = action.params.mobile || ''
  const email = action.params.email || ''
  const days = action.params.days || []
  const categories = action.params.categories || []
  const create_uid = action.params.create_uid || null
  const id = action.params.id
  const user_id = action.params.user_id
  const archive = action.params.archive || false
  const channel_id = action.params.channel_id
  const body = {
    name,
    mobile,
    email,
    days,
    categories,
    create_uid,
    id,
    archive,
    user_id,
    channel_id: parseInt(channel_id)
  }

  if (!id) {
    delete body.id
  }

  const requestURL = `${config.baseUrl}/api/sales/interests`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(body)
    })

    if (id) {
      if (archive) {
        showSuccess('Archive Successful!')
      } else {
        showSuccess('UnArchive Successful!')
      }
      yield put(
        actions.loadListCollection({
          page: 1,
          filter: archive ? 'leads' : 'archive'
        })
      )
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

export default function* superAdminSaga() {
  yield takeLatest(constants.DELETE_LEAD, onDeleteLead)
  yield takeLatest(constants.ADD_COURSE_TO_SELLER, onAddCourseToSeller)
  yield takeLatest(constants.DELETE_COURSE_OF_SELLER, onDeleteCourseOfSeller)
  yield takeLatest(constants.CREATE_OR_UPDATE_USER, onCreateOrUpdateUser)
  yield takeLatest(constants.LOAD_LIST_COLLECTION, onLoadListCollection)
  yield takeLatest(
    constants.COLLECT_CUSTOMER_INTEREST,
    onCollectCustomerInterest
  )
  yield takeLatest(
    constants.LOAD_LIST_COURSE_OF_SELLER,
    onLoadListCourseOfSales
  )
}
