import React, { useState } from 'react'
import { Table, Popover } from 'antd'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { get } from 'lodash'
import images from 'Themes/Images'
import DeleteUserModal from './DeleteUserModal'
import { isTrainerRole, isAdminRole } from 'utils/userUtils'
import './styles.scss'

const TableUser = ({
  setPagination,
  users,
  setUsers,
  reloadUsers,
  handleResendEmail,
  clients,
}) => {
  const [visible, setVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const history = useHistory()

  const columns = [
    {
      title: 'NAME',
      onCell: (record) => {
        return {
          onClick: (ev) => {
            handleViewUser(record)
          },
        }
      },
      render: (record) => (
        <div className="table_link">{record.partner.name}</div>
      ),
    },
    {
      title: 'LOGIN',
      onCell: (record) => {
        return {
          onClick: (ev) => {
            handleViewUser(record)
          },
        }
      },
      render: (record) => <div className="table_link">{record.login}</div>,
    },
    {
      title: 'Clients',
      width: 180,
      onCell: (record) => {
        return {
          onClick: (ev) => {
            handleViewUser(record)
          },
        }
      },
      render: (record) => {
        const client = clients?.reduce((acc, curr) => {
          const existUser = curr?.user_clients?.find(
            (x) => x.user_id === record?.id
          )
          return existUser?.id ? [...acc, curr] : acc
        }, [])

        return (
          <div className="table_link">
            {client?.map((item) => item?.name)?.join(', ')}
          </div>
        )
      },
    },
    {
      title: 'Role',
      render: (record) => {
        const userGroups = get(record, 'user_groups', [])
        const isAdmin = isAdminRole(userGroups)
        const isTrainer = isTrainerRole(userGroups)
        const isAdminAndTrainer = isAdmin && isTrainer
        let label = 'Learner'

        if (isAdminAndTrainer) {
          label = 'Admin, Trainer'
        } else if (isAdmin) {
          label = 'Admin'
        } else if (isTrainer) {
          label = 'Trainer'
        }

        return (
          <div className="table_link" onClick={() => handleViewUser(record)}>
            {label}
          </div>
        )
      },
    },
    {
      title: 'CREATE DATE',
      dataIndex: 'create_date',
      onCell: (record) => {
        return {
          onClick: (ev) => {
            handleViewUser(record)
          },
        }
      },
      render: (date, record) => (
        <div className="table_link">{dayjs(date).format('LLL')}</div>
      ),
    },
    {
      title: 'LAST LOGIN',
      onCell: (record) => {
        return {
          onClick: (ev) => {
            handleViewUser(record)
          },
        }
      },
      render: (record) => (
        <div className="table_link">
          {get(record, 'user_logs.create_date')
            ? dayjs(record.user_logs.create_date).format('LLL')
            : ''}
        </div>
      ),
    },
    {
      title: '',
      render: (record) => (
        <div
          className="hs-column hs-column-cta"
          onClick={() => handleShowMenu(record)}
        >
          <Popover
            placement="leftTop"
            visible={record.show}
            overlayClassName="popup_confirm"
            content={
              <div className="popup-cta" style={{ width: 200 }}>
                <div
                  className="popup-cta__wrap"
                  onClick={() => handleViewUser(record)}
                >
                  <div className="popup-cta__text">View User</div>
                  <div className="popup-cta__icon">
                    <img src={images.input.eye_inactive} alt="" />
                  </div>
                </div>
                <div
                  className="popup-cta__wrap"
                  onClick={() => handleResendEmail(record)}
                >
                  <div className="popup-cta__text">Reset Password Email</div>
                  <div className="popup-cta__icon">
                    <img src={images.email} width={13} alt="" />
                  </div>
                </div>
                <div
                  className="popup-cta__wrap"
                  onClick={() => {
                    setVisible(true)
                    setSelectedUser(record)
                  }}
                >
                  <div className="popup-cta__delete">Delete User</div>
                  <div className="popup-cta__icon">
                    <img src={images.admin.trash_icon} alt="" />
                  </div>
                </div>
              </div>
            }
            trigger="click"
          >
            <div style={{ padding: '0 15px' }}>
              Actions
              <img src={images.share.arrow_down} style={{ paddingLeft: 8 }} />
            </div>
          </Popover>
        </div>
      ),
    },
  ]

  const handleShowMenu = (user) => {
    const listUser = [...users]
    for (let i = 0; i < users.length; i++) {
      if (listUser[i].id === user?.id) {
        listUser[i].show = !listUser[i].show
      } else {
        listUser[i].show = false
      }
    }

    setUsers(listUser)
  }

  const handleViewUser = (record) =>
    history.push(`/admin/user-management/create?user=${record?.id}`)

  return (
    <>
      <Table
        columns={columns}
        dataSource={users}
        onChange={(value) => setPagination(value.current)}
        rowKey={(record) => record.id}
        pagination={false}
        rowClassName="table-user-row"
        scroll={{ x: 'calc(700px + 50%)', y: 600 }}
      />
      <DeleteUserModal
        selectedUser={selectedUser}
        visible={visible}
        onClose={() => setVisible(false)}
        reloadUsers={reloadUsers}
      />
    </>
  )
}

export default TableUser
