import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { get, groupBy, isEmpty, truncate } from 'lodash'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { showError } from 'utils/notification'
import { loadListLearningOutcome } from '../../../actions'
import { makeSelectDomain } from '../../../../Home/selectors'
import { makeSelectListOutcome } from '../../../selectors'
import config from 'src/global-config'
import IndividualsWorkbook from '../IndividualsWorkbook'
import { encodedSlideByQuiz } from 'utils/helper'
import {
  assessmentItem,
  assessmentItemBell,
} from 'src/template/AssessmentRecord'
import { parseSafe } from 'utils/helper'
import { DOMAIN } from 'src/routes'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { assessmentItemUnicorn } from '../../../../../template/AssessmentRecord'

const AssessmentRecord = ({
  listUserByCourse,
  listSetOutcomeStudents,
  listLearningOutCome = [],
  proficieny_name,
  proficieny_id,
  listUserStatus,
  listCustomOutcomeUser = [],
  listSetOutcomeCustom,
  slidesOriginal,
  selectedSchedule,
  courseName,
  course,
}) => {
  const [listUserProfile, setListUserProfile] = useState(null)
  const [listUserAnswer, setListUserAnswer] = useState(null)
  const currentUser = useSelector(makeSelectCurrentUser())

  useEffect(() => {
    const fetchProfiles = async (userId, listProfile) => {
      if (listProfile[userId]) {
        return null
      }
      try {
        const res = await fetch(
          `${config.baseUrl}/api/users/${userId}/profiles`,
          {
            method: 'GET',
          }
        )

        const data = await res.json()
        if (Array.isArray(data.data) && data.data.length !== 0) {
          listProfile[get(data, 'data[0].user_id')] = get(data, 'data[0]', {})
        }
      } catch (error) {
        console.log('err', error)
      }
    }

    const userIDs = new Set()

    for (let i = 0; i < listUserByCourse.length; i++) {
      const userId = get(listUserByCourse, `[${i}].user.id`, '')
      userIDs.add(userId)
    }

    for (let i = 0; i < listSetOutcomeCustom.length; i++) {
      const trainerId = get(listSetOutcomeCustom, `[${i}].create_uid`, '')
      userIDs.add(trainerId)
    }

    const listProfile = {}
    userIDs.forEach((userID) => {
      fetchProfiles(userID, listProfile)
    })

    setListUserProfile(listProfile)
  }, [listUserByCourse, listSetOutcomeCustom])

  useEffect(() => {
    const fetchAllAnswerByUser = async (userId) => {
      const encodedSlideId = encodedSlideByQuiz(slidesOriginal)
      try {
        const res = await fetch(
          `${config.baseUrl}/api/users/${userId}/slides/answers?schedule_id=${
            selectedSchedule?.value || 0
          }${encodedSlideId}&order_by=id%20asc&per_page=1000&ignore_per_page=true`,
          {
            method: 'GET',
          }
        )

        const data = await res.json()
        return data.data
      } catch (error) {
        console.log('err', error)
      }
    }

    const fetchAllUserAnswerByCourse = async () => {
      const requests = []

      for (let i = 0; i < listUserByCourse.length; i++) {
        const userId = get(listUserByCourse, `[${i}].user.id`)
        requests.push(fetchAllAnswerByUser(userId))
      }
      const listUserAnswer = await Promise.all(requests)
      setListUserAnswer(listUserAnswer)
    }

    fetchAllUserAnswerByCourse()
  }, [listUserByCourse])

  const zip = new JSZip()
  const folderZip = zip.folder('learning_outcome')
  const mergeLearningOutcome = [
    ...listLearningOutCome,
    ...listCustomOutcomeUser,
  ]

  const allTypes = groupBy(
    mergeLearningOutcome,
    !isEmpty(listLearningOutCome)
      ? 'assessment_criteria.learning_outcome.type'
      : 'type'
  )

  const dispatch = useDispatch()
  const domain = useSelector(makeSelectDomain())
  const isBellDomain = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION
  const isUnicorn = get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN
  const listOutcome = useSelector(makeSelectListOutcome())

  useEffect(() => {
    dispatch(loadListLearningOutcome())
  }, [])

  const generateIndividualsPDF = () => {
    if (isEmpty(listUserByCourse)) {
      showError('There is no user')
      return
    }

    for (let i = 0; i < listUserByCourse.length; i++) {
      const doc = new jsPDF('p', 'pt')
      const payload = {
        doc,
        data: listUserByCourse[i],
        listUserProfile,
        listUserStatus,
        domain,
        allTypes,
        proficieny_name,
        proficieny_id,
        listOutcome,
        listSetOutcomeCustom,
        mergeLearningOutcome,
        listSetOutcomeStudents,
      }

      const userStatus = listUserStatus.find(
        (item) => item.user_id === listUserByCourse[i]?.user?.id
      )

      if (isBellDomain) {
        assessmentItemBell({
          ...payload,
          courseName,
          selectedSchedule,
          course,
          currentUser,
          userStatus,
        })
      } else if (isUnicorn) {
        assessmentItemUnicorn({
          ...payload,
          courseName,
          selectedSchedule,
          course,
          currentUser,
          userStatus,
        })
      } else {
        assessmentItem(payload)
      }

      const user = get(listUserByCourse[i], 'user.partner', '')

      if (typeof doc !== 'undefined') {
        try {
          const fileName = `${user.name
            .trim()
            .replace(/\//, '')
            .replace(/ +/g, '_')}_${get(
            selectedSchedule,
            'class_code',
            ''
          )}_Assessment Record_${course?.name}`

          const newFileName = truncate(fileName, {
            length: 83,
            separator: /,? +/,
          })

          folderZip.file(`${newFileName}.pdf`, doc.output('blob'))
        } catch {
          console.error('Something went wrong!')
        }
      }
    }

    folderZip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(
        content,
        `${truncate(
          `${get(selectedSchedule, 'class_code', '')}_Individual Records_${
            course?.name
          }`,
          {
            length: 83,
            separator: /,? +/,
          }
        )}.zip`
      )
    })
  }

  const generatePDF = () => {
    if (isEmpty(listUserByCourse)) {
      showError('There is no user')
      return
    }
    const doc = new jsPDF('p', 'pt')

    for (let i = 0; i < listUserByCourse.length; i++) {
      const payload = {
        doc,
        data: listUserByCourse[i],
        listUserProfile,
        listUserStatus,
        domain,
        allTypes,
        proficieny_name,
        proficieny_id,
        listOutcome,
        listSetOutcomeCustom,
        mergeLearningOutcome,
        listSetOutcomeStudents,
      }

      const userStatus = listUserStatus.find(
        (item) => item.user_id === listUserByCourse[i]?.user?.id
      )

      console.log('payload', payload)

      if (isBellDomain) {
        const additionalData = parseSafe(course?.course_instructor)
        const durations = additionalData?.duration
        assessmentItemBell({
          ...payload,
          courseName,
          selectedSchedule,
          course,
          currentUser,
          userStatus,
        })
      } else if (isUnicorn) {
        assessmentItemUnicorn({
          ...payload,
          courseName,
          selectedSchedule,
          course,
          currentUser,
        })
      } else {
        assessmentItem(payload)
      }

      if (i < listUserByCourse.length - 1) {
        doc.addPage()
      }
    }
    doc.save(
      `${get(selectedSchedule, 'class_code', '')}_Assessment Records.pdf`
    )
  }

  return (
    <>
      <div className="popup-cta__wrap" onClick={generatePDF}>
        <div className="popup-cta__text">Assessment Records</div>
      </div>
      <div className="popup-cta__wrap" onClick={generateIndividualsPDF}>
        <div className="popup-cta__text">Individuals Records</div>
      </div>
      <IndividualsWorkbook
        domain={domain}
        dispatch={dispatch}
        listUserProfile={listUserProfile}
        listUserByCourse={listUserByCourse}
        listUserAnswer={listUserAnswer}
        proficieny_name={proficieny_name}
        proficieny_id={proficieny_id}
        slidesOriginal={slidesOriginal}
        listUserStatus={listUserStatus}
        selectedSchedule={selectedSchedule}
        courseName={courseName}
      />
    </>
  )
}
export default AssessmentRecord
