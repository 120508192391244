import React, { useRef, useState } from 'react'
import images from 'Themes/Images'
import { SUPPORTED_FORMATS } from 'utils/constants'
import { showError } from 'utils/notification'
import { fileToBase64 } from 'utils/helper'
import { get } from 'lodash'
import { Form } from 'antd'

const UploadFile = ({ form, fieldName, fieldNameSecond }) => {
  const [currentFile, setCurrentFile] = useState(null)
  const file = useRef(null)

  const handleClickFile = () => {
    if (file && file.current) {
      file.current.click()
    }
  }

  const handleChangeFile = (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      const name = get(fileUploaded, 'name', '')
      const type = get(fileUploaded, 'type', '')

      if (!SUPPORTED_FORMATS.includes(type)) {
        return showError('Only JPG and PNG accepted.')
      }

      setCurrentFile(name)

      fileToBase64(fileUploaded).then((result) => {
        if (result) {
          console.log('name', name)
          form.setFieldsValue({ [fieldName]: result, [fieldNameSecond]: name })
        }
      })
    }
  }
  return (
    <div
      className="add-lesson__upload"
      onClick={handleClickFile}
      style={{ backgroundColor: '#fff', margin: '15px 0' }}
    >
      <img
        src={
          currentFile
            ? images.admin.document_upload_active
            : images.admin.document_upload
        }
        alt=""
      />
      {currentFile ? (
        <div
          className="add-lesson__text"
          style={{ color: '#00122a', maxWidth: 200 }}
        >
          {currentFile}
        </div>
      ) : (
        <div className="add-lesson__text">No File Uploaded</div>
      )}

      <div
        className="add-lesson__button"
        style={{
          position: 'absolute',
          right: 20,
        }}
      >
        <button
          className="tertiary-btn"
          type="button"
          style={{ padding: '7px 20px', width: 90 }}
        >
          Browse
        </button>
      </div>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={file}
        onChange={handleChangeFile}
      />
      <Form.Item name="attachment_name" style={{ display: 'hidden' }} />
    </div>
  )
}

export default UploadFile
