import React from 'react'
import dayjs from 'dayjs'
import images from 'Themes/Images'
import { get } from 'lodash'
import { Popconfirm } from 'antd'
import axiosInstance from 'utils/axiosInstance'
import { showSuccess } from 'utils/notification'

const TableClients = ({ listUser, refetch }) => {
  const removeRecord = async (userClientID) => {
    try {
      await axiosInstance.delete(`/api/clients/users/${userClientID}`)
      showSuccess(`Delete client succeed!`)
    } catch (error) {
      console.log('~ error', error)
      showSuccess(`Delete client failed!`)
    }
  }

  return (
    <>
      <table className="hs-table">
        <thead className="hs-thead">
          <tr className="hs-row">
            <th>Name</th>
            <th>Logo</th>
            <th>Added on</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="hs-tbody">
          {listUser &&
            listUser?.map((item) => {
              const logo = get(item, 'client.logo', '')
              return (
                <tr key={item.id} className={`hs-row`}>
                  <td className="hs-column">{item?.client?.name}</td>
                  <td className="hs-column">
                    <img src={logo} width={80} alt="logo" />
                  </td>
                  <td className="hs-column">
                    {dayjs(item.write_date).format('DD/MM/YYYY')}
                  </td>
                  <td className="hs-column hs-column-cta">
                    <Popconfirm
                      placement="leftTop"
                      title="Do you want to remove this record?"
                      onConfirm={async () => {
                        await removeRecord(item.id)
                        await refetch()
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div style={{ padding: '0 15px' }}>
                        <img src={images.admin.trash_icon} alt="" width="10" />
                      </div>
                    </Popconfirm>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )
}

export default TableClients
