import React from 'react'
import { Row, Col, Radio, DatePicker, Checkbox } from 'antd'
import Dropdown from 'react-dropdown'
import { Link } from 'react-router-dom'

import Input from 'component/Input'
import FileUploadComponent from 'component/FileUploadComponent'
import { initialNational, initialLevel } from 'utils/constants'
import { getAttachmentByField } from 'utils/helper'
import { get } from 'lodash'
import { CUSTOM_COURSE, CUSTOM_COURSE_2 } from 'utils/constants'

const ProfileFlc = ({
  formik,
  handleNumberInput,
  attachments,
  handleGetFile,
  handleRemoveFile,
  setTermCondition,
  termCondition,
  checkRule,
  setCheckRule,
  isClassCompareDomain,
  course
}) => {
  const linkTerm = isClassCompareDomain ? '/terms?school_id=35' : '/terms'

  const isCustomCourse = get(course, 'id') === CUSTOM_COURSE
  const isCustomCourse2 = get(course, 'id') === CUSTOM_COURSE_2

  if (isCustomCourse2) {
    return (
      <>
        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Full Name</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Input
                name="name"
                placeHolder="Enter your full name"
                formik={formik}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Date of Birth:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__date-picker">
              <DatePicker
                onChange={(date, dateString) => {
                  formik.setFieldValue('date_of_birth', date)
                }}
                allowClear
                value={formik.values.date_of_birth || ''}
                placeholder="Date of Birth"
                className={`sc-modal__datepicker ${
                  formik.touched.date_of_birth &&
                  formik.errors.date_of_birth &&
                  'datepicker__error'
                }`}
              />
              {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                <div className="form__error">{formik.errors.date_of_birth}</div>
              )}
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Gender:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Radio.Group
                onChange={(e) => formik.setFieldValue('gender', e.target.value)}
                value={formik.values.gender}
                name="add-course__radio-type"
              >
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Company Email:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Input
                name="company_email"
                placeHolder="Enter your company email"
                formik={formik}
                value={formik.values.company_email}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Personal Email:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Input
                name="personal_email"
                placeHolder="Enter your personal email"
                formik={formik}
                value={formik.values.personal_email}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Mobile No.:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="phone"
                placeHolder="Enter Mobile No."
                formik={formik}
                value={formik.values.phone}
                onChange={({ currentTarget }) =>
                  handleNumberInput(currentTarget, 'phone')
                }
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Company:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="company"
                placeHolder="Enter Your Company"
                formik={formik}
                value={formik.values.company}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      </>
    )
  }

  if (isCustomCourse) {
    return (
      <>
        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              Full Name (Ensure name is exactly according to NRIC to be eligible
              for grant funding):
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Input
                name="name"
                placeHolder="Enter your full name"
                formik={formik}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">NRIC:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="passport"
                placeHolder="Enter NRIC"
                formik={formik}
                value={formik.values.passport}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Nationality:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Dropdown
                options={initialNational}
                onChange={(value) =>
                  formik.setFieldValue('nationality', value.label)
                }
                value={formik.values.nationality}
                placeholder="Select Nationality"
                className="dropdownScheduleModal dropdown"
              />

              {formik.values.nationality === 'Other' && (
                <div style={{ marginTop: 20 }}>
                  <Input
                    name="other_nationality"
                    placeHolder="Type another nationality here"
                    formik={formik}
                    value={formik.values.other_nationality}
                    onChange={formik.handleChange}
                  />
                </div>
              )}

              {formik.touched.nationality && formik.errors.nationality && (
                <div className="form__error">{formik.errors.nationality}</div>
              )}
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              Are you a Singapore Citizen & age 40 years old and above?
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_above_40', e.target.value)
                }
                value={formik.values.question_above_40}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
              {formik &&
                formik.touched.question_above_40 &&
                formik.errors.question_above_40 && (
                  <div className="form__error">
                    {formik.errors.question_above_40}
                  </div>
                )}
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Company Email:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Input
                name="company_email"
                placeHolder="Enter your company email"
                formik={formik}
                value={formik.values.company_email}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Personal Email:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Input
                name="personal_email"
                placeHolder="Enter your personal email"
                formik={formik}
                value={formik.values.personal_email}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Billing Address:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="user_billing_address"
                placeHolder="Enter Billing Address"
                formik={formik}
                value={formik.values.user_billing_address}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Mobile No.:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="phone"
                placeHolder="Enter Mobile No."
                formik={formik}
                value={formik.values.phone}
                onChange={({ currentTarget }) =>
                  handleNumberInput(currentTarget, 'phone')
                }
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Gender:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Radio.Group
                onChange={(e) => formik.setFieldValue('gender', e.target.value)}
                value={formik.values.gender}
                name="add-course__radio-type"
              >
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Date of Birth:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__date-picker">
              <DatePicker
                onChange={(date, dateString) => {
                  formik.setFieldValue('date_of_birth', date)
                }}
                allowClear
                value={formik.values.date_of_birth || ''}
                placeholder="Date of Birth"
                className={`sc-modal__datepicker ${
                  formik.touched.date_of_birth &&
                  formik.errors.date_of_birth &&
                  'datepicker__error'
                }`}
              />
              {formik.touched.date_of_birth && formik.errors.date_of_birth && (
                <div className="form__error">{formik.errors.date_of_birth}</div>
              )}
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Marital Status:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="marital_status"
                placeHolder="Enter Your Marital Status"
                formik={formik}
                value={formik.values.marital_status}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              Highest Academic Qualifications:
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="highest_academic_qualifications"
                placeHolder="Enter Your Highest Academic Qualifications"
                formik={formik}
                value={formik.values.highest_academic_qualifications}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Professional Designation:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="professional_designation"
                placeHolder="Enter Your Professional Designation"
                formik={formik}
                value={formik.values.professional_designation}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Company:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="company"
                placeHolder="Enter Your Company"
                formik={formik}
                value={formik.values.company}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">Title:</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="title"
                placeHolder="Enter Your Title"
                formik={formik}
                value={formik.values.title}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={20}>
            <div className="profile__name">Pre-Workshop Questionaire</div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              1. What were you doing prior to joining the financial advisory
              industry?
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="question_1"
                placeHolder="Enter Your Answer"
                formik={formik}
                value={formik.values.question_1}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              2. How long have you been a Financial Adviser?
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="question_2"
                placeHolder="Enter Your Answer"
                formik={formik}
                value={formik.values.question_2}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">3. Have you written your Will?</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_3', e.target.value)
                }
                value={formik.values.question_3}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
              {formik &&
                formik.touched.question_3 &&
                formik.errors.question_3 && (
                  <div className="form__error">{formik.errors.question_3}</div>
                )}
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              4. Have you ever helped your client to draw up a will?
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_4', e.target.value)
                }
                value={formik.values.question_4}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
              {formik &&
                formik.touched.question_4 &&
                formik.errors.question_4 && (
                  <div className="form__error">{formik.errors.question_4}</div>
                )}
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              5. Have you done any key-man insurance or buy-sell agreement for
              businessmen?
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_5', e.target.value)
                }
                value={formik.values.question_5}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
              {formik &&
                formik.touched.question_5 &&
                formik.errors.question_5 && (
                  <div className="form__error">{formik.errors.question_5}</div>
                )}
            </div>
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              6. Do you charge a fee for your financial planning services? If
              yes, how much do you typically charge for each case?
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__name">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_6', e.target.value)
                }
                value={formik.values.question_6}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
            {formik.values.question_6 === 'yes' && (
              <div className="profile__input">
                <Input
                  name="question_6_2"
                  placeHolder="Enter Your Answer"
                  formik={formik}
                  value={formik.values.question_6_2}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            {formik &&
              formik.touched.question_6 &&
              formik.errors.question_6 && (
                <div className="form__error">{formik.errors.question_6}</div>
              )}
          </Col>
        </Row>

        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">
              7. What do you expect to learn from this course?
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="question_7"
                placeHolder="Enter Your Answer"
                formik={formik}
                value={formik.values.question_7}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className="" justify="center" style={{ marginBottom: 15 }}>
          <Col xs={24} md={18}>
            <Checkbox onChange={() => setCheckRule(!checkRule)}>
              I have read, understood and agreed to the{' '}
              <Link
                to={`/terms${isCustomCourse ? '?custom=true' : ''}`}
                target="_blank"
                style={{
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  color: '#042580'
                }}
              >
                terms and conditions
              </Link>{' '}
              outlined in this form and would like to register for this 3-Day
              Blended Learning training workshop.
            </Checkbox>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">
            Full Name (Ensure name is exactly according to NRIC to be eligible
            for grant funding):
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="name"
              placeHolder="Enter your full name"
              formik={formik}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">NRIC:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="passport"
              placeHolder="Enter NRIC"
              formik={formik}
              value={formik.values.passport}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Nationality:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Dropdown
              options={initialNational}
              onChange={(value) =>
                formik.setFieldValue('nationality', value.label)
              }
              value={formik.values.nationality}
              placeholder="Select Nationality"
              className="dropdownScheduleModal dropdown"
            />

            {formik.values.nationality === 'Other' && (
              <div style={{ marginTop: 20 }}>
                <Input
                  name="other_nationality"
                  placeHolder="Type another nationality here"
                  formik={formik}
                  value={formik.values.other_nationality}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            {formik.touched.nationality && formik.errors.nationality && (
              <div className="form__error">{formik.errors.nationality}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">
            Are you a Singapore Citizen & age 40 years old and above?
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Radio.Group
              onChange={(e) =>
                formik.setFieldValue('question_above_40', e.target.value)
              }
              value={formik.values.question_above_40}
              name="add-course__radio-type"
            >
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
            {formik &&
              formik.touched.question_above_40 &&
              formik.errors.question_above_40 && (
                <div className="form__error">
                  {formik.errors.question_above_40}
                </div>
              )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Billing Address:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="user_billing_address"
              placeHolder="Enter Billing Address"
              formik={formik}
              value={formik.values.user_billing_address}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Mobile No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="phone"
              placeHolder="Enter Mobile No."
              formik={formik}
              value={formik.values.phone}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'phone')
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Date of Birth:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__date-picker">
            <DatePicker
              onChange={(date, dateString) => {
                formik.setFieldValue('date_of_birth', date)
              }}
              allowClear
              value={formik.values.date_of_birth || ''}
              placeholder="Date of Birth"
              className={`sc-modal__datepicker ${
                formik.touched.date_of_birth &&
                formik.errors.date_of_birth &&
                'datepicker__error'
              }`}
            />
            {formik.touched.date_of_birth && formik.errors.date_of_birth && (
              <div className="form__error">{formik.errors.date_of_birth}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Gender:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Radio.Group
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
              value={formik.values.gender}
              name="add-course__radio-type"
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Occupation:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="occupation"
              placeHolder="Enter Occupation"
              formik={formik}
              value={formik.values.occupation}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Highest Education Level:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Dropdown
              options={initialLevel}
              onChange={(value) =>
                formik.setFieldValue('education_level', value.label)
              }
              value={formik.values.education_level}
              placeholder="Select Education Level"
              className="dropdownScheduleModal dropdown"
            />

            {formik.values.education_level === 'Other' && (
              <div style={{ marginTop: 20 }}>
                <Input
                  name="other_level"
                  placeHolder="Type another education level here"
                  formik={formik}
                  value={formik.values.other_level}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            {formik.touched.education_level &&
              formik.errors.education_level && (
                <div className="form__error">
                  {formik.errors.education_level}
                </div>
              )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">NRIC front:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <FileUploadComponent
              item={getAttachmentByField(attachments, 'nric_front')}
              handleGetFile={handleGetFile}
              handleRemoveFile={handleRemoveFile}
              file={formik.values.nric_front}
              field="nric_front"
            />
            {formik.touched.nric_front && formik.errors.nric_front && (
              <div className="form__error">{formik.errors.nric_front}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">NRIC back:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <FileUploadComponent
              item={getAttachmentByField(attachments, 'nric_back')}
              handleGetFile={handleGetFile}
              handleRemoveFile={handleRemoveFile}
              file={formik.values.nric_back}
              field="nric_back"
            />
            {formik.touched.nric_back && formik.errors.nric_back && (
              <div className="form__error">{formik.errors.nric_back}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Company (Optional):</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="company"
              placeHolder="Enter Company"
              formik={formik}
              value={formik.values.company}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={20}>
          <div className="profile__name">
            I have been referred by ( Please give details if any):
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Full Name:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="referred_name"
              placeHolder="Enter Full Name"
              formik={formik}
              value={formik.values.referred_name}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Mobile No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="referred_mobile"
              placeHolder="Enter Mobile No"
              formik={formik}
              value={formik.values.referred_mobile}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'referred_mobile')
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Co(if any):</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="referred_co"
              placeHolder="Enter Co(if any)"
              formik={formik}
              value={formik.values.referred_co}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="" justify="center" style={{ marginBottom: 15 }}>
        <Col xs={24} md={18}>
          <Checkbox onChange={() => setCheckRule(!checkRule)}>
            I declare that I have access to a desktop/laptop with Windows or Mac
            OS installed with MS Excel 2016 or later, a webcam and basic Excel
            and Internet Browser skills.
          </Checkbox>
        </Col>
      </Row>
      <Row className="" justify="center">
        <Col xs={24} md={18}>
          <Checkbox onChange={() => setTermCondition(!termCondition)}>
            I agree to the{' '}
            <Link to={linkTerm}>
              <span className="profile__highlight">Terms & Conditions</span>
            </Link>{' '}
            and{' '}
            <Link to={linkTerm}>
              <span className="profile__highlight">
                Personal Data Protection Act – Consent Form
              </span>
            </Link>
          </Checkbox>
        </Col>
      </Row>
    </>
  )
}

export default ProfileFlc
