import React from 'react'
import './styles.scss'
import images from '../../../../Themes/Images'

const Alert = ({ icon, message, type, style }) => {
  return (
    <div
      className="alert"
      style={{
        backgroundColor:
          type === 'success'
            ? 'rgba(224, 249, 231, 0.9)'
            : 'rgba(249, 229, 222, 0.9)',
        color: type === 'success' ? '#06B031' : '#D85025',
        ...style
      }}
    >
      <img
        src={
          type === 'success'
            ? images.admin.success_icon
            : images.admin.failed_icon
        }
        alt=""
      />
      <div>{message}</div>
    </div>
  )
}

export default Alert
