import React from 'react'
import { Row, Col } from 'antd'
import BtnPrimary from 'component/BtnPrimary'
import images from 'Themes/Images'
import HeaderAdminCRM from './HeaderAdminCRM'
import { Progress } from 'antd'
import './styles.scss'

const CRM = () => {
  return (
    <>
      <HeaderAdminCRM />
      <div className="crm">
        <div className="crm__header__wrap">
          <div className="crm__header">
            <div className="crm__header__left">
              <BtnPrimary
                name="Group Search"
                iconLeft={images.admin.group_search}
                style={{
                  backgroundColor: '#DEE2ED',
                  fontSize: 10,
                  color: '#6D788F',
                  marginRight: 8
                }}
              />
              <BtnPrimary
                name="Filter(3)"
                iconLeft={images.admin.filter}
                style={{
                  backgroundColor: '#DEE2ED',
                  fontSize: 10,
                  color: '#6D788F'
                }}
              />
            </div>
            <div className="crm__header__right"></div>
          </div>
        </div>
        <div className="crm__body__wrap">
          <div className="crm__body">
            <Row className="crm__row">
              {[1, 2, 3, 4, 5, 6].map(() => (
                <Col md={6}>
                  <div className="crm__block">
                    <div className="crm__heading">Leads (6)</div>
                    <div className="crm__progressbar">
                      <Progress
                        percent={25}
                        showInfo={false}
                        size="small"
                        strokeColor="#FF0D6B"
                        trailColor="rgba(193,199,216,0.5)"
                        style={{ height: '4px' }}
                      />
                      <div className="crm__progressbar__value">$9,999.99</div>
                    </div>
                    <div className="crm__list__wrap">
                      <div className="crm__list">
                        {[1, 2, 3, 4, 5, 9, 10, 2, 2, 2, 2, 2].map((item) => (
                          <div className="crm__item">
                            <div className="crm__item__head">
                              <div className="crm__item__title">
                                Jamie Oliver{' '}
                              </div>
                              <div className="crm__item__price">$999.99</div>
                            </div>
                            <div className="crm__item__desc">
                              Sed ut perspiciatis unde omnis iste natus error
                              sit voluptatem
                            </div>
                            <div className="crm__item__list-type">
                              <div className="crm__item__type">
                                <div className="crm__item__type-status"></div>
                                <div>JOBSEEKER</div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="crm__addnew">
                          <img src={images.admin.add_crm} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              <Col md={5}>
                <div className="crm__block">
                  <div className="crm__addcol">
                    <div className="crm__addcol__heading">
                      <div className="crm__addcol__text">Add Column</div>
                      <div>
                        <img src={images.admin.pen_alt} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="crm__addnew"
                    style={{ height: 150, borderRadius: 4 }}
                  >
                    <img src={images.admin.add_crm} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default CRM
