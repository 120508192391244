import React from 'react'
import './styles.scss'

const HeaderCourseDetails = ({ course }) => {
  return (
    <div className="header__courses-details">
      <div className="header__intro" style={{ height: 40 }}>
        <div className="header__course">
          <div className="header__course__level">{course && course.name}</div>
        </div>
      </div>
    </div>
  )
}

export default HeaderCourseDetails
