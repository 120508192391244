import React, { useState, useEffect, useRef } from 'react'
import { uploadImage } from 'src/container/CMS/hooks.js'
import { get } from 'lodash'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { makeSelectDomain } from 'container/Home/selectors'
import { showSuccess } from 'utils/notification'

import images from 'Themes/Images'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { loadDomain } from 'container/Home/actions'
import './styles.scss'
import { patchSchoolSettings } from '../Branding/handler'

const Report = ({ setSubmit, formikRef }) => {
  const school = useSelector(makeSelectDomain())
  const dispatch = useDispatch()

  const [pickingsField, setPickingsField] = useState([])

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())
      // const isSuccess = await patchSchoolSettings(
      //   {
      //     name: values.name,
      //     logo: get(logo, 'image_url', ''),
      //     banner: get(photo, 'image_url', ''),
      //     page_title: pageTitle,
      //     page_description: pageDesc,
      //     allow_stripe_payment: get(school, 'allow_stripe_payment', false)
      //   },
      //   schoolId
      // )

      // if (isSuccess) {
      //   dispatch(loadDomain())
      //   showSuccess('Update School Report Success!')
      // }

      dispatch(hideGlobalLoading())
    }
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="admin__channel branding__wrapper"
    >
      <div
        className="channel__head"
        style={{ borderBottom: '1px solid #d0cad8' }}
      >
        <div className="channel__head__left">
          <div className="channel__chapter">School Report</div>
        </div>
      </div>
      <div
        className="channel__body admin__channel__body"
        style={{
          borderTop: 'unset',
          display: 'block'
        }}
      >
        <div
          className="channel__left admin__channel__left"
          style={{ padding: 0 }}
        >
          <div
            className="add-course__heading"
            style={{ borderTop: 'unset', fontSize: '12px' }}
          >
            User Report
          </div>

          <div className="add-course__head" style={{ padding: '0 24px' }}>
            Picking fields for report
          </div>
          <div className="inner_container">
            <div className="add-course__upload-wrap-first"></div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Report
