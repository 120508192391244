import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { formatMoney } from 'utils/helper'

const CourseInfoWrap = styled.div`
  .pricing_wrap {
    padding-top: 36px;
    padding-bottom: 20px;
  }
`

export const ListPriceWrap = styled.div`
  border-bottom: 1px solid #dddddd;
  border-top: ${(props) => (props.normalPage ? 'unset' : '1px solid #dddddd;')};
  display: flex;
  padding: ${(props) => (props.normalPage ? '0 0 40px 0' : '60px 0')};
  @media (max-width: 767px) {
    padding: 25px 0;
    justify-content: space-between;
  }
`

export const CourseInfoItem = styled.div`
  &:not(:last-child) {
    padding-right: 160px;
    @media (max-width: 767px) {
      padding-right: 0;
    }
  }
  .info__label {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #666666;
  }
  .info__text {
    font-weight: normal;
    font-size: 14px;
    padding-top: 6px;
    color: #999999;
  }
  @media (max-width: 767px) {
    .info__label {
      font-size: 22px;
    }
  }
`

export const PricingTiers = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 30px;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    display: block;
    & > div {
      width: 100%;
    }
  }
`

export const PricingTierItem = styled.div`
  border: 1px solid #cccccc;
  background-color: #fff;
  width: 300px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;

  .pricing {
    &__label {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #999999;
      padding-bottom: 10px;
    }
    &__price {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #666666;
      padding-bottom: 20px;
    }
    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #999999;
    }
  }
`

const CourseInfo = ({ course, optionPrices, courseType }) => {
  return (
    <CourseInfoWrap>
      <ListPriceWrap>
        <CourseInfoItem>
          <div className="info__label">
            {get(course, 'total_days', '')} hours
          </div>
          <div className="info__text">Of lesson time</div>
        </CourseInfoItem>
        <CourseInfoItem>
          <div className="info__label">{get(courseType, 'name', '')}</div>
          <div className="info__text">Course Type</div>
        </CourseInfoItem>
      </ListPriceWrap>
      {Array.isArray(optionPrices) && optionPrices.length > 0 && (
        <div className="pricing_wrap">
          <h2 style={{ paddingBottom: 40 }}>Pricing Tiers</h2>
          <PricingTiers>
            {optionPrices
              ?.sort((a, b) => a?.price - b?.price)
              ?.map((price, index) => (
                <PricingTierItem key={index}>
                  <div className="pricing__label">{price.title}</div>
                  <div className="pricing__price">
                    {formatMoney(price.price, null)}
                  </div>
                  <div className="pricing__text">{price.description}</div>
                </PricingTierItem>
              ))}
          </PricingTiers>
        </div>
      )}
    </CourseInfoWrap>
  )
}

export default CourseInfo
