import React from 'react'
import { Row, Col, DatePicker } from 'antd'
import Dropdown from 'react-dropdown'
import { initialNational } from 'utils/constants'
import { get } from 'lodash'
import { renderColValue } from 'utils/helper'

import Input from 'component/Input'

const CyberQuote = ({ formik, editable, userProfile, userData }) => {
  const name = get(userProfile, 'name', '') || get(userData, 'partner.name', '')
  const birthDay = get(userProfile, 'birthday', '')
  const nationality = get(userProfile, 'nationality', '')
  const occupation = get(userProfile, 'occupation', '')
  const passport = get(userProfile, 'passport', '')
  const phone = get(userProfile, 'phone', '')
  const postalAddress = get(userProfile, 'postal_address', '')
  const postalCode = get(userProfile, 'postal_code', '')
  const unitNumber = get(userProfile, 'unit_number', '')

  const handleChangePhoneNum = ({ currentTarget }) => {
    var regExp = /^[0-9]{1,13}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue('phone', currentTarget.value)
    }
  }

  return (
    <div>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          NAME (ACCORDING TO ID/PASSPORT):
        </Col>
        <Col xs={renderColValue(24, 10)} md={6} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="name"
                placeHolder="Enter your full name"
                formik={formik}
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            name
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          NRIC No.:
        </Col>
        <Col md={6} xs={10} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="passport"
                placeHolder="Enter ID/Passport"
                formik={formik}
                value={formik.values.passport}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            passport
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Contact no.:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="phone"
                placeHolder="Enter Phone No."
                formik={formik}
                value={formik.values.phone}
                onChange={handleChangePhoneNum}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            phone
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Nationality:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Dropdown
                options={initialNational}
                onChange={(value) =>
                  formik.setFieldValue('nationality', value.label)
                }
                value={formik.values.nationality}
                placeholder="Select Nationality"
                className="dropdownScheduleModal"
              />

              {formik.values.nationality === 'Other' && (
                <div style={{ marginTop: 20 }}>
                  <Input
                    name="other_nationality"
                    placeHolder="Type another nationality here"
                    formik={formik}
                    value={formik.values.other_nationality}
                    onChange={formik.handleChange}
                    style={{ padding: 8 }}
                  />
                </div>
              )}
            </div>
          ) : (
            nationality
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Date of Birth:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <DatePicker
                onChange={(date, dateString) => {
                  formik.setFieldValue('date_of_birth', date)
                }}
                allowClear
                value={formik.values.date_of_birth}
                placeholder="Date of Birth"
                className="sc-modal__datepicker"
                style={{ width: '100%' }}
              />
            </div>
          ) : (
            birthDay
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Street:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="address"
                placeHolder="Enter Postal Address"
                formik={formik}
                value={formik.values.address}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            postalAddress
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Unit No.:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="unit_number"
                placeHolder="Enter Unit Number"
                formik={formik}
                value={formik.values.unit_number}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            unitNumber
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Postal Code:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="postTalCode"
                placeHolder="Enter Postal Code"
                formik={formik}
                value={formik.values.postTalCode}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            postalCode
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Occupation:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="occupation"
                placeHolder="Enter Occupation"
                formik={formik}
                value={formik.values.occupation}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            occupation
          )}
        </Col>
      </Row>
    </div>
  )
}

export default CyberQuote
