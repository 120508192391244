import React, { useEffect } from 'react'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import TableUsers from '../../../../components/TableUsers'
import HeaderLeadsPage from '../../../../components/HeaderLeadsPage'
import { loadListUser } from 'container/Admin/actions'
import { makeSelectListUser } from 'container/Admin/selectors'
import { loadListCourse } from 'container/Home/actions'
import { AdminLayout } from 'src/styles'

const UserManagement = () => {
  const dispatch = useDispatch()
  const listUser = useSelector(makeSelectListUser())
  const { search } = useLocation()
  const { page = 1 } = parse(search)

  useEffect(() => {
    if (page) {
      dispatch(loadListUser({ current: page, superAdmin: true }))
    }
  }, [page])

  useEffect(() => {
    dispatch(loadListCourse({ superAdmin: true }))
  }, [])

  return (
    <div>
      <HeaderLeadsPage title="User management" />
      <AdminLayout>
        <div className="container_left" />
        <div className="container_right">
          {listUser && <TableUsers page={page} listUser={listUser} />}
        </div>
      </AdminLayout>
    </div>
  )
}

export default UserManagement
