import React from 'react'

const HeaderPage = ({ title }) => {
  return (
    <div className="header__courses">
      <div className="header__intro" style={{ paddingLeft: 48 }}>
        <div className="header__course">
          <div className="header__course__title__box">
            <div className="header__course__title admin-courses">
              <div className="admin-courses__title">{title}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderPage
