import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'antd'
import { parse } from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, useParams } from 'react-router-dom'
// import AdminComments from '../../components/AdminComments';

import FilterCourses from 'component/FilterCourses'
import ChannelDetails from '../../components/ChannelDetails'
import HeaderAdminCourseDetails from './HeaderAdminCourseDetails'
import { loadSlides } from 'container/Home/actions'

import { loadCourse } from '../../../Home/actions'
import { makeSelectCourse } from '../../../Home/selectors'
import './styles.scss'
import { AdminLayout } from 'src/styles'
import { getSchedules } from 'container/Admin/actions'

const initialOptions = [
  { text: 'General', value: 'general' },
  { text: 'Lessons', value: 'lessons' },
  { text: 'Schedules', value: 'schedules' },
]

const CourseDetails = () => {
  const [filter, setFilter] = useState('general')
  const [saveGo, setSaveGo] = useState(false)
  const saveRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const { route } = parse(search)
  const { id } = useParams()
  const [filterOptions, setFilterOptions] = useState(initialOptions)
  const course = useSelector(makeSelectCourse())

  useEffect(() => {
    if (id === 'new') {
      setFilterOptions([{ text: 'General', value: 'general' }])
    } else {
      setFilterOptions(initialOptions)
    }
  }, [id])

  useEffect(() => {
    if (id) {
      dispatch(loadCourse(id))
      dispatch(loadSlides(id, true))
    }
  }, [id])

  useEffect(() => {
    if (id && filter !== 'schedules') {
      dispatch(getSchedules(id, true, null, false, true))
    }
  }, [id, filter])

  useEffect(() => {
    if (route) {
      setFilter(route)
    }
  }, [route])

  return (
    <>
      <HeaderAdminCourseDetails
        idCourse={id}
        route={route}
        saveRef={saveRef}
        setSaveGo={setSaveGo}
      />
      <AdminLayout>
        <div className="container_left">
          {(course || id === 'new') && (
            <FilterCourses
              setFilter={setFilter}
              filter={filter}
              filterOptions={filterOptions}
              idCourse={id}
              history={history}
              pathname={pathname}
              admin
            />
          )}
        </div>

        <div className="container_right">
          <ChannelDetails
            filter={filter}
            setFilter={setFilter}
            course={course}
            saveRef={saveRef}
            saveGo={saveGo}
          />
        </div>
      </AdminLayout>
    </>
  )
}

export default CourseDetails
