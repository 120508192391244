import React, { useEffect, useState } from 'react'
import { parse, stringify } from 'query-string'
import { useLocation } from 'react-router-dom'
import TableClients from './components/TableClients'
import HeaderClientPage from './components/HeaderClientPage'
import { AdminLayout } from 'src/styles'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/helper'
import useGetClientAdmin from '../../../../hooks/useGetClientAdmin'
import { isEmpty } from 'lodash'

const defaultFilter = {
  page: 1,
  per_page: 15,
  order_by: 'id desc',
}
const ClientManagement = () => {
  const { search } = useLocation()
  const { page = 1 } = parse(search)
  const [searchValue, setSearchValue] = useState(null)
  const [data, setData] = useState([])

  const { userClients } = useGetClientAdmin()
  const getClients = async () => {
    try {
      const params = stringify({
        ...defaultFilter,
        page,
        ...(searchValue && { name: searchValue }),
      })
      const res = await axiosInstance.get(`/api/clients?${params}`)
      const data = getResponseData(res)
      if (!isEmpty(userClients)) {
        setData(
          data?.reduce(
            (acc, curr) =>
              userClients?.find((item2) => item2?.client_id === curr?.id)
                ? [...acc, curr]
                : acc,
            []
          )
        )
      } else setData(data)
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {
    if (searchValue || searchValue === '') {
      // enhance sau
      getClients()
    }
  }, [searchValue])

  return (
    <div>
      <HeaderClientPage
        title="Client management"
        refetch={getClients}
        setSearchValue={setSearchValue}
      />
      <AdminLayout>
        <div className="container_left" />
        <div className="container_right">
          {data && (
            <TableClients page={page} listUser={data} refetch={getClients} />
          )}
        </div>
      </AdminLayout>
    </div>
  )
}

export default ClientManagement
