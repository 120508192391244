import React, { useState, useEffect, useRef } from 'react'
import FilterCourses from 'component/FilterCourses'
import HeaderAdminSettings from './HeaderAdminSettings'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { get } from 'lodash'
import Report from './components/Report'
import Branding from './components/Branding'
import Payments from './components/Payments'
import { AdminLayout } from 'src/styles'
import { useHistory } from 'react-router-dom'
import Categories from './components/Categories'

const filterOptions = [
  {
    text: 'Branding',
    value: 'branding'
  },
  {
    text: 'Payments',
    value: 'payments'
  },
  {
    text: 'Categories',
    value: 'categories'
  }
  // {
  //   text: 'Report',
  //   value: 'report'
  // }
]

const AdminSettings = () => {
  const [option, setOption] = useState()
  const history = useHistory()
  const formikRef = useRef()

  const { search } = useLocation()
  const parsedSearch = parse(search)

  useEffect(() => {
    if (parsedSearch && parsedSearch.route) {
      setOption(
        get(
          filterOptions.find((item) => item.value === parsedSearch.route),
          'value',
          ''
        )
      )
    }
  }, [parsedSearch.option, option])

  const renderMainContent = () => {
    switch (option) {
      case 'categories':
        return <Categories />
      case 'branding':
        return <Branding formikRef={formikRef} />
      case 'payments':
        return <Payments formikRef={formikRef} />
      case 'report':
        return <Report />
    }
  }

  return (
    <>
      <HeaderAdminSettings option={option} formikRef={formikRef} />
      <AdminLayout>
        <div className="container_left">
          <FilterCourses
            setFilter={setOption}
            filter={option}
            filterOptions={filterOptions}
            label="NAVIGATE"
            admin
            pathname="/admin/settings"
            history={history}
          />
        </div>

        <div className="container_right">{renderMainContent()}</div>
      </AdminLayout>
    </>
  )
}

export default AdminSettings
