import React, { useState, useEffect } from 'react'
import { Switch } from 'antd'
import classNames from 'classnames'

import { InputQuizSettings } from './styles'
import { checkInput } from 'utils/helper'
import { get } from 'lodash'
import { DOMAIN } from '../../../../../../routes'
import { makeSelectDomain } from '../../../../../Home/selectors'
import { useSelector } from 'react-redux'

const QuizSettings = ({ formik }) => {
  const [allowPassingGrade, setAllowPassGrade] = useState(false)
  const domain = useSelector(makeSelectDomain())
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  useEffect(() => {
    if (formik.values.quiz_pass_rate) {
      setAllowPassGrade(true)
    } else {
      setAllowPassGrade(false)
    }
  }, [formik.values.quiz_pass_rate])

  const handleChangeInputNumber = (e) => {
    const value = e.currentTarget.value

    if (checkInput(e) && checkPercent(value)) {
      formik.setFieldValue('quiz_pass_rate', value)
    }
  }
  const checkPercent = (percent) => {
    return Number(percent) <= 100
  }
  const handleAllowPassingGrade = (checked) => {
    setAllowPassGrade(checked)
    if (!checked) {
      formik.setFieldValue('quiz_pass_rate', 0)
    }
  }
  return (
    <div className="lessons__tags mgB-45">
      <div
        className="add-course__switch switch_wrap"
        style={{ marginBottom: 2 }}
      >
        <Switch
          size={25}
          checked={allowPassingGrade}
          onChange={(checked) => handleAllowPassingGrade(checked)}
        />
        <div
          className={classNames({
            'quiz-addnew__text': allowPassingGrade,
            'add-course__switch__label': !allowPassingGrade,
          })}
          style={{ marginLeft: 6 }}
        >
          This quiz requires a passing grade
        </div>
      </div>
      {allowPassingGrade && (
        <>
          <div style={{ paddingLeft: 45 }}>Passing Grade</div>
          <InputQuizSettings>
            <input
              value={allowPassingGrade ? formik.values.quiz_pass_rate : ''}
              onChange={
                allowPassingGrade ? (e) => handleChangeInputNumber(e) : () => {}
              }
              type="text"
            />
            <div>%</div>
          </InputQuizSettings>
        </>
      )}
      <div className="add-course__switch switch_wrap" style={{ marginTop: 20 }}>
        <Switch
          size={25}
          checked={formik.values.show_quiz_only}
          onChange={(checked) =>
            formik.setFieldValue('show_quiz_only', checked)
          }
        />
        <div
          className={classNames({
            'quiz-addnew__text': formik.values.show_quiz_only,
            'add-course__switch__label': !formik.values.show_quiz_only,
          })}
          style={{ marginLeft: 6 }}
        >
          Hide lesson and show quiz only
        </div>
      </div>
      <div className="add-course__switch switch_wrap" style={{ marginTop: 20 }}>
        <Switch
          size={25}
          checked={!formik.values.is_show_answer}
          onChange={(checked) => {
            formik.setFieldValue('is_show_answer', !checked)
          }}
        />
        <div
          className={classNames({
            'quiz-addnew__text': !formik.values.is_show_answer,
            'add-course__switch__label': formik.values.is_show_answer,
          })}
          style={{ marginLeft: 6 }}
        >
          Automark and display the MCQ answers to students
        </div>
      </div>
      {wfaDomain && (
        <div
          className="add-course__switch switch_wrap"
          style={{ marginTop: 20 }}
        >
          <Switch
            size={25}
            checked={formik.values.is_correct_answer}
            onChange={(checked) =>
              formik.setFieldValue('is_correct_answer', checked)
            }
          />
          <div
            className={classNames({
              'quiz-addnew__text': formik.values.is_correct_answer,
              'add-course__switch__label': !formik.values.is_correct_answer,
            })}
            style={{ marginLeft: 6 }}
          >
            Answer must be correct to proceed to the next question
          </div>
        </div>
      )}
    </div>
  )
}

export default QuizSettings
