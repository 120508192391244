import React from 'react'
import { Modal } from 'antd'
import { get } from 'lodash'
import images from 'Themes/Images'
import { hideDeleteLearningOutcome } from '../../actions'
import { deleteAssessment, deleteCustomOutcome } from 'container/Admin/actions'
import { getListOutcomeStudent } from '../../../Admin/actions'
import './styles.scss'
import { loadSlides } from '../../../Home/actions'

const DeleteLearningOutCome = ({
  dispatch,
  showDeleteLearningOutcome,
  outCome,
  selectedSchedule,
  courseId
}) => {
  const handleOk = () => {
    const is_custom = get(outCome, 'is_custom', false)
    if (!is_custom) {
      const assessmentCriteriaID = get(outCome, 'assessment_criteria_id')
      dispatch(deleteAssessment(assessmentCriteriaID))
    } else {
      const id = get(outCome, 'id')
      dispatch(deleteCustomOutcome({ id }))
    }
    setTimeout(() => {
      dispatch(
        getListOutcomeStudent({
          channel_id: courseId
        })
      )
    }, 500)

    dispatch(
      loadSlides(courseId, false, null, get(selectedSchedule, 'value', ''))
    )
    dispatch(hideDeleteLearningOutcome())
  }
  const handleCancel = () => {
    dispatch(hideDeleteLearningOutcome())
  }

  return (
    <Modal
      visible={showDeleteLearningOutcome}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="ld-modal">
        <div className="ld-modal__icon">
          <img src={images.admin.trash_icon} alt="" width="25" height="29" />
        </div>
        <div className="ld-modal__warning">
          Are you sure you want to delete this assessment criteria ?
        </div>
        <div className="ld-modal__label">
          You will not be able to recover this later.
        </div>
        <div className="ld-modal__btn__ok">
          <button className="delete-btn" onClick={handleOk}>
            <img
              src={images.admin.trash_white_icon}
              alt=""
              style={{ fill: '#fff' }}
              className="trash-icon"
            />
            <span>Delete forever</span>
          </button>
        </div>
        <div className="ld-modal__btn__cancel">
          <button className="tertiary-btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteLearningOutCome
