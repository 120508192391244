import React from 'react'
import classNames from 'classnames'
import './styles.scss'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { makeSelectCourse } from '../../container/Home/selectors'
import { getSchedulesSuccess } from '../../container/Admin/actions'

const FilterCourses = ({
  setFilter,
  filter,
  filterOptions,
  idCourse,
  previewPage,
  style,
  isSuperAdmin,
  admin,
  adminCourses,
  history,
  pathname,
  label
}) => {
  const dispatch = useDispatch()
  const course = useSelector(makeSelectCourse())
  const requiredSchedule = get(course, 'is_schedule', false)
  const renderHead = () => {
    if (label) {
      return label
    }
    if (previewPage) {
      return
    }
    if (!filterOptions) {
      return ''
    }
    return filterOptions.find((option) => option.value === filter)
      ? 'NAVIGATE'
      : 'BROWSE COURSES'
  }
  return (
    <div className="courses-filter" style={style}>
      <div className="courses-filter__wrapper">
        {!isSuperAdmin && (
          <div className="courses-filter__head">{renderHead()}</div>
        )}
        {filterOptions.map((option) => {
          if (!requiredSchedule && option.value === 'schedules') {
            return null
          }
          return (
            <div key={option.value}>
              <div
                className={classNames('courses-filter__title', {
                  'courses-filter-active': filter === option.value
                })}
                onClick={() => {
                  if (admin) {
                    setFilter(option.value)
                    if (option.value === 'schedules') {
                      dispatch(getSchedulesSuccess(null))
                    }
                    return history.push(`${pathname}?route=${option.value}`)
                  }
                  if (idCourse && idCourse !== 'new') {
                    return setFilter(option.value)
                  }

                  if (adminCourses) {
                    setFilter(option.value)
                    return history.push(pathname)
                  }

                  setFilter(option.value)
                }}
              >
                {option.text}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default FilterCourses
