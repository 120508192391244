import React, { useState } from 'react'
import { Input } from 'antd'
import { useDispatch } from 'react-redux'
import {
  setCompetentLearningOutcome,
  setListOutcomeCustom,
} from '../../../../actions'
import { showError } from 'utils/notification'
import { get } from 'lodash'

const { TextArea } = Input

const Remark = ({
  setRemarks,
  remarks,
  remark,
  placeholder = 'Add Remark',
}) => {
  const [active, setActive] = useState(false)

  const dispatch = useDispatch()

  const handleChange = ({ currentTarget }) => {
    setActive(true)
    let newValues = [...remarks]
    const value = currentTarget.value
    const itemIndex =
      Array.isArray(newValues) &&
      newValues?.findIndex((item) => item?.id === remark?.id)
    if (itemIndex > -1) {
      newValues[itemIndex]['remark'] = value
    }

    setRemarks(newValues)
  }

  const handleSaveRemark = (e) => {
    if (active) {
      if (remark.remark && remark.remark?.length >= 3) {
        const isCustom = get(remark, 'is_custom', false)
        if (isCustom) {
          dispatch(setListOutcomeCustom({ ...remark }))
        } else {
          dispatch(
            setCompetentLearningOutcome({
              ...remark,
              outcomeId: remark?.learning_outcome_id,
              userId: remark?.user_id,
            })
          )
        }
        setActive(false)
      } else {
        showError('Remark must be minimum 3 characters.', 2000)
      }
    }
  }

  return (
    <div>
      <TextArea
        rows={4}
        className="remarks__input"
        placeholder={placeholder}
        disabled={!remark}
        value={remark?.remark || ''}
        onChange={handleChange}
        onBlur={handleSaveRemark}
      />
    </div>
  )
}

export default Remark
