import React from 'react'
import { get } from 'lodash'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import images from 'Themes/Images'
import { createZoomLink } from '../../../../actions'
import { makeSelectCourse } from 'container/Home/selectors'

const GenerateZoomLink = ({
  meeting,
  zoom,
  schedule,
  password,
  meeting_option,
  meeting_option_2,
  formik
}) => {
  const dispatch = useDispatch()
  const course = useSelector(makeSelectCourse())

  const handleGenerateZoomLink = () => {
    dispatch(
      createZoomLink({
        zoom,
        schedule,
        password,
        meeting_option,
        meeting_option_2
      })
    )
  }

  return (
    <>
      {course && (
        <div className="add-course__right-bottom" style={{ width: 250 }}>
          <div
            className="add-course__generate-link"
            onClick={handleGenerateZoomLink}
          >
            <button
              className="tertiary-btn"
              style={{ width: '100%' }}
              type="button"
            >
              Generate link
            </button>
          </div>

          {meeting && (
            <>
              <div className="add-course__head">Participant URL</div>
              <div className="add-course__zoom-link">
                <input
                  type="text"
                  className="add-course__zoom-link-text"
                  value={formik.values.url}
                  style={{ width: 210, border: 'none', outline: 'none' }}
                  placeholder="Enter Participant Url"
                  onChange={(e) => formik.setFieldValue('url', e.target.value)}
                />

                <CopyToClipboard
                  text={formik.values.url}
                  onCopy={() => {
                    message.success('Copied Zoom Link')
                  }}
                >
                  <div className="add-course__zoom-link-wrap-icon">
                    <img
                      src={images.share.copy}
                      alt=""
                      className="add-course__zoom-link-icon"
                    />
                  </div>
                </CopyToClipboard>
              </div>
            </>
          )}
          {meeting && (
            <>
              <div className="add-course__head">Host URL</div>
              <div className="add-course__zoom-link">
                <input
                  type="text"
                  className="add-course__zoom-link-text"
                  style={{ width: 210, border: 'none', outline: 'none' }}
                  onChange={(e) =>
                    formik.setFieldValue('start_url', e.target.value)
                  }
                  value={formik.values.start_url}
                  placeholder="Enter Host Url"
                />

                <CopyToClipboard
                  text={formik.values.start_url}
                  onCopy={() => {
                    message.success('Copied Zoom Link')
                  }}
                >
                  <div className="add-course__zoom-link-wrap-icon">
                    <img
                      src={images.share.copy}
                      alt=""
                      className="add-course__zoom-link-icon"
                    />
                  </div>
                </CopyToClipboard>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default GenerateZoomLink
