import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import OptionsPricingItem from '../OptionsPricingItem'
import images from 'Themes/Images'

const OptionsPricingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  .plus-icon {
    cursor: pointer;
    align-self: center;
  }
`

const OptionsPricing = ({ prices, formik, course, isSFC }) => {
  const handleAddMorePrice = () => {
    let newList = []
    if (isSFC) {
      newList = [
        ...prices,
        { title: '', price: 0, course_code: '', is_edit: true }
      ]
      formik.setFieldValue('sfc_prices', newList)
    } else {
      newList = [
        ...prices,
        { title: '', price: 0, description: '', is_edit: true }
      ]
      formik.setFieldValue('prices', newList)
    }
  }
  const dispatch = useDispatch()

  return (
    <OptionsPricingWrapper>
      {prices &&
        prices.map((item, index) => (
          <OptionsPricingItem
            listOption={prices}
            key={index}
            option={item}
            index={index}
            formik={formik}
            course={course}
            dispatch={dispatch}
            isSFC={isSFC}
          />
        ))}
      <div onClick={handleAddMorePrice} className="plus-icon">
        <img
          src={images.admin.plus_normal}
          alt=""
          style={{ paddingBottom: 3, paddingRight: 3 }}
        />{' '}
        <span className="quiz-addnew__text">
          {isSFC ? 'Add Course Code' : 'Add display price'}
        </span>
      </div>
    </OptionsPricingWrapper>
  )
}

export default OptionsPricing
