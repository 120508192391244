import styled from 'styled-components'

export const SentenceItemWrap = styled.div`
  width: 600px;
  padding: 10px 50px 10px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #e7ebf4;
  border-radius: 4px;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
  }
  .sentence__input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    color: #00122a;
    letter-spacing: 0.02em;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
  }
  .sentence__word {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 1.2rem;
  }
`
