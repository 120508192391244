import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Table } from 'antd'
import { get } from 'lodash'
import config from 'src/global-config'
import { getUserInfo } from 'utils/request'
import { showSuccess } from 'utils/notification'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import BadgeContainer from '../Reports/Badge'
import './styles.scss'

const TableBages = ({
  course,
  listUserByCourse,
  listUserStatus,
  profiles,
  setProfiles,
}) => {
  const dispatch = useDispatch()

  const columnsTable = [
    {
      title: () => {
        return (
          <div>
            <div
              style={{ color: '#d85025', fontSize: '2rem', paddingBottom: 5 }}
            >
              {get(listUserByCourse, 'length', '')}
            </div>
            <div>All Students</div>
          </div>
        )
      },
      width: '25%',
      render: (record) => {
        return <span>{get(record, 'user.partner.name', '')}</span>
      },
    },
    {
      title: 'Assessment Grade',
      width: '20%',
      render: (record, i, index) => {
        return <span></span>
      },
    },
    {
      title: 'Competency',
      width: '20%',
      render: (record, i, index) => {
        const competent = get(listUserStatus, `[${index}].competent`, null)
        if (competent === null) {
          return ''
        }

        return <span>{competent ? 'C' : 'NC'}</span>
      },
    },
    {
      title: 'Badge No.',
      width: '20%',
      render: (record, i, index) => {
        return <span></span>
      },
    },

    {
      title: 'Action',
      width: '15%',
      render: (record, i, index) => {
        return (
          <div className="action__wrap">
            <BadgeContainer
              record={record}
              index={index}
              profiles={profiles}
              course={course}
            />
            <div
              className="action"
              onClick={() => handleSendEmail(record, index)}
            >
              Email
            </div>
          </div>
        )
      },
    },
  ]

  const handleSendEmail = async (record, index) => {
    dispatch(showGlobalLoading())

    const courseName = get(course, 'name', '')

    const profileName =
      get(profiles, `[${index}].name`, '') ||
      get(record, 'user.partner.name', '')

    const user = get(record, 'user.login', '')

    try {
      const requestUrl = `${config.api}/send-mail/badge-mail`
      const res = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mail: user,
          courseName,
          profileName,
        }),
      })

      const data = await res.json()
      if (data.message === 'success') {
        showSuccess('The email was sent to the user.')
      }

      dispatch(hideGlobalLoading())
    } catch (error) {
      dispatch(hideGlobalLoading())
      console.log('error', error)
    }
  }

  useEffect(() => {
    const getAllProfileUser = async () => {
      let request = []
      for (let i = 0; i < listUserByCourse.length; i++) {
        request.push(
          getUserInfo(
            get(listUserByCourse[i], 'user.id', ''),
            course?.website_id
          )
        )
      }
      const data = await Promise.all(request)
      setProfiles(data)
      dispatch(hideGlobalLoading())
    }
    if (listUserByCourse && listUserByCourse.length > 0) {
      dispatch(showGlobalLoading())
      getAllProfileUser()
    }
  }, [listUserByCourse])

  return (
    Array.isArray(listUserByCourse) &&
    listUserByCourse.length > 0 && (
      <>
        <div className="table_certificate">
          <Table
            columns={columnsTable}
            dataSource={listUserByCourse}
            rowKey={(record) => record.id}
            pagination={false}
            className="certificate-table"
          />
        </div>
      </>
    )
  )
}

export default TableBages
