import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { get } from 'lodash'

import { ERROR_MESSAGE } from 'utils/constants'
import BtnPrimary from 'component/BtnPrimary'
import Input from 'component/Input'
import images from 'Themes/Images'
import config from 'src/global-config'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { LoginSuccess } from 'container/Auth/actions'
import { getUserByEmail } from 'container/Home/saga'
import { postEnroll } from '../../../../../Home/actions'
import * as actions from 'container/Auth/actions'
import { setAccessToken, createUserInfo } from 'utils/request'
import { makeSelectError } from '../../../../../Auth/selectos'
import { useSelector } from 'react-redux'
import { makeSelectDomain } from '../../../../../Home/selectors'
import { DOMAIN } from '../../../../../../routes'

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  email: Yup.string().email().required(ERROR_MESSAGE),
})

const SignUp = ({
  refId,
  dispatch,
  history,
  course,
  schedule,
  handleSwitchMode,
  requiredSchedule,
  isSingaporeSkillsAcademies,
  handlePaymentMethodFreeCourse,
}) => {
  const domain = useSelector(makeSelectDomain())
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  const error = useSelector(makeSelectError())
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())
      const requestURL = `${config.baseUrl}/api/users/signup`
      const body = {
        name: values.name,
        login: values.email,
        mobile: values.phone,
      }

      if (refId) {
        body.ref_id = refId
      }

      try {
        const res = await fetch(requestURL, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        const data = await res.json()
        const error = get(data, 'errors', null)
        const result = get(data, 'data.id', null)
        const accessToken = get(data, 'data.access_token', '')

        if (error) {
          const errorText =
            'Oops! You can not have two users with the same email!'
          dispatch(actions.LoginFailed(errorText))
          dispatch(hideGlobalLoading())
          return
        }
        dispatch(actions.setAccessToken(accessToken))
        setAccessToken(accessToken)
        if (result) {
          const userAccount = await getUserByEmail(values.email)

          if (userAccount) {
            dispatch(LoginSuccess(userAccount))
            const userId = get(userAccount, 'id')
            await createUserInfo(userId, {
              name: values.name,
              email: values.email,
              website_id: course?.website_id,
            })

            if (isSingaporeSkillsAcademies || wfaDomain) {
              dispatch(showGlobalLoading())
              const setPayment = await handlePaymentMethodFreeCourse(userId)
              if (setPayment) {
                setTimeout(() => {
                  dispatch(postEnroll({ is_send: true }))
                  dispatch(hideGlobalLoading())
                }, 1500)
              }
              return
            }

            if (!requiredSchedule) {
              return history.push(
                `/checkout/${get(course, 'id', '')}/complete-profile`
              )
            } else {
              if (schedule) {
                history.push(
                  `/checkout/${get(course, 'id', '')}/confirm?ourse=${schedule}`
                )
              } else {
                history.push(`/checkout/${get(course, 'id', '')}/confirm`)
              }
            }
          } else {
            const errorText =
              'Oops! It seems like there is a problem with your account. Please check your email and try to login again!'
            dispatch(actions.LoginFailed(errorText))
          }
        }
        dispatch(hideGlobalLoading())
      } catch (err) {
        dispatch(hideGlobalLoading())
        console.log('err', err)
      }
    },
  })

  const handleNumberInput = (currentTarget, field) => {
    let regExp = /^[0-9]{1,15}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue(field, currentTarget.value)
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <h3 className="hs-confirm__heading">Register</h3>
      {error && <div className="login-form__error">{error}</div>}
      <Input
        label="Full Name"
        name="name"
        className="mg-small"
        placeHolder="Enter your name"
        formik={formik}
        onChange={formik.handleChange}
        value={formik.values.name}
      />
      {isSingaporeSkillsAcademies && (
        <Input
          label="Phone number"
          name="phone"
          className="mg-small"
          placeHolder="Enter your phone number"
          formik={formik}
          onChange={({ currentTarget }) => {
            handleNumberInput(currentTarget, 'phone')
          }}
          value={formik.values.phone}
        />
      )}
      <Input
        label="EMAIL ADDRESS"
        name="email"
        className="mg-small"
        placeHolder="Enter your email address"
        formik={formik}
        onChange={(e) => {
          formik.setFieldValue(
            'email',
            e.currentTarget.value.trim().toLowerCase()
          )
        }}
        value={formik.values.email}
      />
      {!wfaDomain && (
        <div className="password-require">
          You will receive an email to set your password after creating your
          account
        </div>
      )}

      <BtnPrimary
        name={wfaDomain ? 'Get Started' : 'Create account'}
        style={{
          fontWeight: '800',
          padding: '12px 12px 12px 24px',
          fontSize: 12,
          width: '100%',
        }}
        styleIcon={{ marginBottom: 0 }}
        type="submit"
        icon={images.course.arrow_right_active}
      />
      <div className="auth__policy" style={{ marginBottom: 20 }}>
        By signing up, you agree to Hypeschools'{' '}
        <Link to="/terms" className="link-policy">
          Privacy Policy
        </Link>
      </div>
      <div className="auth__switch">
        Registered before? <span onClick={handleSwitchMode}>Log in here</span>
      </div>
    </form>
  )
}

export default SignUp
