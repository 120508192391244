import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import ChoiceItem from '../ChoiceItem/index'
import { setQuestions } from '../../container/Admin/actions'
import { cloneDeep } from 'lodash'

const ListChoiceWrapper = styled.div``

const ListChoice = ({ listChoice, dispatch, questions, questionIndex }) => {
  const isAddMoreOption = useMemo(() => {
    return listChoice && listChoice.find((item) => !item.text)
  }, [listChoice])

  const hasTwoEmptyOption = useMemo(() => {
    const array = listChoice && listChoice.filter((item) => !item.text)
    return array.length >= 2
  }, [listChoice])

  useEffect(() => {
    if (!isAddMoreOption) {
      const newQuestions = cloneDeep(questions)
      const newListChoice = cloneDeep(listChoice)
      if (Array.isArray(newListChoice)) {
        newListChoice.push({
          order: newListChoice.length,
          text: '',
          is_correct: false
        })
      }
      newQuestions[questionIndex].multiple_choices =
        JSON.stringify(newListChoice)

      dispatch(setQuestions(newQuestions))
    }
  }, [isAddMoreOption])

  useEffect(() => {
    if (hasTwoEmptyOption) {
      const newQuestions = cloneDeep(questions)
      let newListChoice = cloneDeep(listChoice)
      if (Array.isArray(newListChoice)) {
        let index = 0
        newListChoice.forEach((item, i) => {
          if (!item.text && !item.is_correct) {
            index = i
          }
        })
        newListChoice.splice(index)

        newQuestions[questionIndex].multiple_choices =
          JSON.stringify(newListChoice)

        dispatch(setQuestions(newQuestions))
      }
    }
  }, [hasTwoEmptyOption])

  return (
    <ListChoiceWrapper>
      {listChoice &&
        listChoice.map((choice, i) => (
          <ChoiceItem
            key={i}
            index={i}
            choice={choice}
            dispatch={dispatch}
            questions={questions}
            listChoice={listChoice}
            questionIndex={questionIndex}
          />
        ))}
    </ListChoiceWrapper>
  )
}

export default ListChoice
