import React, { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextEditor from 'src/customs/ClassCompare/src/components/Editor'

import styled from 'styled-components'
import NavBar from 'src/customs/ClassCompare/src/components/NavbarClassCompare'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { Body, Layout } from 'src/customs/ClassCompare/styles'
import Button from 'src/customs/ClassCompare/src/components/Button'
import InputCMS from 'src/customs/ClassCompare/src/components/InputCMS'
import images from 'Themes/Images'
import { get } from 'lodash'
import {
  getArticle,
  getListArticle,
  postArticle,
  uploadImage,
} from '../../hooks'
import { showSuccess } from 'utils/notification'

const CMSWrapper = styled.div``

export const CKEditorWrapper = styled.div`
  .ck.ck-editor__top.ck-reset_all {
    position: ${(props) => (props.sticky ? 'sticky' : 'unset')};
    top: ${(props) => (props.top ? `${props.top}px` : 0)};
    z-index: ${(props) => (props.zIndex ? props.zIndex : 1000)};
  }
  figure {
    float: none !important;
  }
  .active_image {
    & .backdrop {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 99;
    }

    & img {
      @media (min-width: 780px) {
        position: fixed;
        z-index: 100;
        width: 60%;
        height: 60%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.2s all ease-in-out;
      }
    }
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
  .ck.ck-sticky-panel__placeholder {
    display: none !important;
  }

  .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    position: unset;
    width: 100% !important;
  }
  .ck-content {
    min-height: ${(props) =>
      props.minHeight ? `${props.minHeight}px` : '500px'};
  }
  .text-big {
    font-size: 1.4em;
  }
  .text-tiny {
    font-size: 0.7em;
  }
  .text-small {
    font-size: 0.85em;
  }
  .text-tiny {
    font-size: 0.7em;
  }
  .text-huge {
    font-size: 1.8em;
  }
  img {
    max-width: 100%;
    height: auto !important;
  }
  ul,
  ol {
    list-style: inherit;
    margin: initial;
    padding: 0 0 0 40px;
  }
  li {
    list-style: inherit;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #77838f;
  }
  .table {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      height: 100%;
      border: 1px double #b3b3b3;
    }
    thead {
      font-style: normal;
      font-size: 1.2rem;
      line-height: 16px;
    }
    thead {
      font-weight: 600;
      color: #27284d;
    }
    table th {
      font-weight: 700;
      background: hsla(0, 0%, 0%, 5%);
    }
    table td,
    table th {
      min-width: 2em;
      padding: 0.4em;
      border: 1px solid #bfbfbf;
    }
  }
  .table-responsive {
    & table tr {
      @media (max-width: 767px) {
        border: none;
        display: flex;
        flex-direction: column;
      }
    }
    & table td {
      @media (max-width: 767px) {
        border: none;
        display: flex;
        flex-direction: column;
        width: 100% !important;
      }
    }
  }
  .table-none-border table,
  .table-none-border td,
  .table-none-border tr {
    border: none !important;
  }
  p {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: ${(props) => props.pColor || '#666666'};
  }
  strong {
    color: #2f2d51;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
  }
  .input-label {
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #042580;
    width: 80px;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`
const CMS = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const hiddenFileInput = useRef(null)
  const [article, setArticle] = useState(null)
  const [file, setFile] = useState(null)
  const [dataDescription, setDataDescription] = useState('')
  const [state, setState] = useState({
    title: '',
    short_description: '',
    description: '',
    slug: '',
  })

  useEffect(() => {
    let unmount = false
    const loadArticle = async (id) => {
      const article = await getArticle(id)
      return article
    }
    if (id && !unmount) {
      loadArticle(id).then((article) => {
        if (article) {
          setArticle(article)
        }
      })
    }
    return () => {
      unmount = true
    }
  }, [id])

  useEffect(() => {
    if (article) {
      const newState = { ...state }
      newState['short_description'] = get(article, 'short_description', '')
      newState['title'] = get(article, 'title', '')
      newState['description'] = get(article, 'description', '')
      newState['slug'] = get(article, 'slug', '')
      setState(newState)
      const image = get(article, 'image')

      setFile(image)
    }
  }, [article])

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      dispatch(showGlobalLoading())
      const image = await uploadImage(fileUploaded)
      setFile(image)
      dispatch(hideGlobalLoading())
    }
  }

  const handleRemove = () => {
    setFile(null)
  }

  const handleChangeInput = ({ currentTarget }) => {
    const newState = { ...state }
    const value = currentTarget.value
    const name = currentTarget.name
    newState[name] = value
    setState(newState)
  }

  const handleSaveArticle = async (publish = false) => {
    dispatch(showGlobalLoading())
    const body =
      id && id !== 'article'
        ? {
            ...state,
            description: dataDescription,
            image: get(file, 'url') ? get(file, 'url', '') : file,
            id: parseInt(id),
          }
        : {
            ...state,
            description: dataDescription,
            image: get(file, 'url') ? get(file, 'url', '') : file,
          }

    body.status = publish

    await postArticle(body)

    if (id && id !== 'article') {
      showSuccess('Update Article Successful')
    } else {
      showSuccess('Create Article Successful')
    }

    await getListArticle()
    dispatch(hideGlobalLoading())
  }

  return (
    <Layout>
      <Body>
        <CMSWrapper>
          <NavBar cmsMode />
          <ButtonWrapper className="mgB-45">
            <Button
              title="Save as draft"
              color="#fff"
              bgColor="#FF0D6B"
              minWidth="163"
              height="39"
              fontSize="12"
              handleClick={() => handleSaveArticle(false)}
              className="mgR-15"
            />
            <Button
              title="Publish"
              color="#fff"
              bgColor="#FF0D6B"
              minWidth="128"
              height="39"
              fontSize="12"
              handleClick={() => handleSaveArticle(true)}
            />
          </ButtonWrapper>

          <FieldWrapper className="mgB-30">
            <div className="input-label">Title</div>
            <InputCMS
              height={42}
              value={state.title}
              handleChange={handleChangeInput}
              name="title"
            />
          </FieldWrapper>
          <FieldWrapper className="mgB-30">
            <div className="input-label">Slug</div>
            <InputCMS
              height={42}
              value={state.slug}
              handleChange={handleChangeInput}
              name="slug"
            />
          </FieldWrapper>
          <FieldWrapper className="mgB-30">
            <div className="input-label">Short description</div>
            <InputCMS
              height={42}
              value={state.short_description}
              name="short_description"
              handleChange={handleChangeInput}
            />
          </FieldWrapper>
          <FieldWrapper className="mgB-30">
            <div className="input-label">Image</div>
            <div
              className="add-course__upload-wrap-first"
              style={{ padding: 0 }}
            >
              <div className="add-course__img">
                <img
                  src={
                    get(file, 'url', '')
                      ? get(file, 'url', '')
                      : file
                      ? file
                      : images.admin.course_img_xample
                  }
                  alt={get(file, 'name', '')}
                />
              </div>
              <div className="add-course__file-type">
                {get(file, 'name') && (
                  <div className="add-course__file-name">
                    {' '}
                    {get(file, 'name')}
                  </div>
                )}
                {get(file, 'size') && (
                  <div className="add-course__file-size">
                    {file.size + 'kb'}
                  </div>
                )}
                <div className="add-course__file-upload">
                  <button
                    className="tertiary-btn"
                    type="button"
                    onClick={handleClick}
                    style={{ width: '111px!important' }}
                  >
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={hiddenFileInput}
                      onChange={handleChange}
                    />
                    <img src={images.header.image_none_bg} alt="" />
                    <span>{!file ? 'Upload' : 'Reupload'}</span>
                  </button>
                </div>
                <div className="add-course__file-remove" onClick={handleRemove}>
                  <button className="tertiary-btn" type="button">
                    <img src={images.admin.trash_icon} alt="" />{' '}
                    <span>Discard</span>
                  </button>
                </div>
              </div>
            </div>
          </FieldWrapper>
          <FieldWrapper className="mgB-20">
            <div className="input-label">Description</div>
          </FieldWrapper>
          <CKEditorWrapper style={{ minHeight: 500 }}>
            <TextEditor
              value={state.description}
              onChange={(data) => {
                setDataDescription(data)
              }}
              sticky
            ></TextEditor>
          </CKEditorWrapper>
        </CMSWrapper>
      </Body>
    </Layout>
  )
}

export default CMS
