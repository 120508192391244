import React from 'react'
import { Row, Col } from 'antd'

import Input from 'component/Input'

const ProfileDefault = ({ formik, ascendoDomain }) => {
  return (
    <>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Email:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="email"
              placeHolder="Enter your email"
              formik={formik}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">
            Full Name (Ensure name is exactly according to NRIC to be eligible
            for grant funding):
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="name"
              placeHolder="Enter your full name"
              formik={formik}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>
      {ascendoDomain && (
        <Row className="profile__row" gutter={30} justify="center">
          <Col xs={24} md={8}>
            <div className="profile__name">ID (eg. NRIC/FIN) / Passport No. :</div>
          </Col>
          <Col xs={24} md={12}>
            <div className="profile__input">
              <Input
                name="passport"
                placeHolder="Enter ID/Passport"
                formik={formik}
                value={formik.values.passport}
                onChange={formik.handleChange}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ProfileDefault
