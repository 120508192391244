import React from 'react'
import { useSelector } from 'react-redux'

import CourseItem from '../CourseItem'
import { makeSelectTags } from 'container/Admin/selectors'
import { ListCourseWrap } from '../../customs/ClassCompare/src/components/ListCourse/index'
import './styles.scss'

const ListCourses = ({ courses, admin, title, mgBt, activeTitle, mgT }) => {
  const courseTags = useSelector(makeSelectTags())
  return (
    <ListCourseWrap mgBt={mgBt} activeTitle={activeTitle} mgT={mgT}>
      <div className="list_title">{title}</div>
      <div className="list-courses">
        {courseTags &&
          courses &&
          courses.map((course, index) => (
            <CourseItem
              key={course.id}
              admin={admin}
              course={course}
              key={course.id}
              courseTags={courseTags}
            />
          ))}
      </div>
    </ListCourseWrap>
  )
}

export default ListCourses
