import React from 'react'
import { Row, Col, Radio, DatePicker } from 'antd'

import { get } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import Input from 'component/Input'
import { renderColValue } from 'utils/helper'

const ArtOfNumbers = ({ formik, editable, userProfile }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isEditOnMobile = editable && isMobile
  const birthDay = get(userProfile, 'birthday', '')
  const gender = get(userProfile, 'gender', '')

  const phone = get(userProfile, 'phone', '')

  const handleChangePhoneNum = ({ currentTarget }) => {
    var regExp = /^[0-9]{1,13}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue('phone', currentTarget.value)
    }
  }
  return (
    <div>
      {' '}
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12, isEditOnMobile)}
        >
          Gender:
        </Col>
        <Col
          md={6}
          xs={renderColValue(24, 10, isEditOnMobile)}
          className="profile__text"
        >
          {editable ? (
            <Radio.Group
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
              value={formik.values.gender}
              name="add-course__radio-type"
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          ) : (
            gender
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12, isEditOnMobile)}
        >
          Date of Birth:
        </Col>
        <Col
          md={6}
          xs={renderColValue(24, 10, isEditOnMobile)}
          className="profile__text"
        >
          {editable ? (
            <div className="profile__field__wrapper">
              <DatePicker
                onChange={(date, dateString) => {
                  formik.setFieldValue('date_of_birth', date)
                }}
                allowClear
                value={formik.values.date_of_birth}
                placeholder="Date of Birth"
                className="sc-modal__datepicker"
                style={{ width: '100%' }}
              />
            </div>
          ) : (
            birthDay
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12, isEditOnMobile)}
        >
          Phone No.:
        </Col>
        <Col
          md={6}
          xs={renderColValue(24, 10, isEditOnMobile)}
          className="profile__text"
        >
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="phone"
                placeHolder="Enter Phone No."
                formik={formik}
                value={formik.values.phone}
                onChange={handleChangePhoneNum}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            phone
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ArtOfNumbers
