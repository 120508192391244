import React, { useState, useEffect, useRef } from 'react'
import { uploadImage } from 'src/container/CMS/hooks.js'
import { get } from 'lodash'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import BtnPrimary from 'component/BtnPrimary'

import TableCategories from 'container/Admin/components/TableCategories'
import images from 'Themes/Images'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { loadListCategory } from 'container/Home/actions'

import { makeSelectListCategory } from 'container/Home/selectors'
import './styles.scss'
import { putCategorySettings, deleteCategorySettings } from './handler'
import { useMediaQuery } from 'react-responsive'

const Categories = () => {
  const dispatch = useDispatch()
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [setupCat, setSetupCat] = useState(false)
  const photoFileRef = useRef(null)
  const categories = useSelector(makeSelectListCategory())
  const isMediumDesktop = useMediaQuery({ maxWidth: 1280 })

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      active: false
    },
    onSubmit: async (values) => {
      dispatch(showGlobalLoading())
      const body = {
        ...values,
        image: get(photo, 'image_url', '')
      }
      const id = await putCategorySettings(
        selectedCategory ? { ...body, id: selectedCategory.id } : body
      )
      if (id) {
        dispatch(loadListCategory())
      }
      dispatch(hideGlobalLoading())
      formik.resetForm()
      setPhoto(null)
      setSetupCat(false)
      setSelectedCategory(null)
    }
  })

  useEffect(() => {
    if (selectedCategory) {
      setPhoto({ image_url: selectedCategory.image })
      formik.setFieldValue('name', selectedCategory.name)
      formik.setFieldValue('description', selectedCategory.description)
      formik.setFieldValue('active', selectedCategory.active)
    }
  }, [selectedCategory])

  const renderPhoto = () => {
    if (photo && photo.image_url) {
      return photo.image_url + '=s0'
    }

    return images.admin.course_img_xample
  }

  const handleClickPhoto = () => {
    photoFileRef.current.click()
  }

  const handleChangePhoto = async (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      dispatch(showGlobalLoading())
      const imageUrl = await uploadImage(fileUploaded)
      if (imageUrl) {
        setPhoto({
          name: imageUrl.name,
          image_url: imageUrl.url,
          size: fileUploaded.size
        })
      }
      dispatch(hideGlobalLoading())
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    formik.submitForm()
  }

  const handleSwitch = async (checked, category) => {
    dispatch(showGlobalLoading())
    const id = await putCategorySettings({ ...category, active: checked })
    if (id) {
      dispatch(loadListCategory())
    }
    dispatch(hideGlobalLoading())
  }

  const handleDeleteCategory = async (category) => {
    dispatch(showGlobalLoading())
    await deleteCategorySettings(category)
    dispatch(loadListCategory())
    dispatch(hideGlobalLoading())
  }

  const handleEditCategory = async (category) => {
    setSelectedCategory(category)
    setSetupCat(true)
  }
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="admin__channel branding__wrapper"
    >
      <div className="channel__head">
        <div className="channel__head__left inner_container">
          <div className="channel__chapter">Settings</div>
          <div className="category__setup__head">
            <div className="channel__title" style={{ width: 400 }}>
              Categories
            </div>
            <div>
              <BtnPrimary
                name="Add a category"
                style={{
                  backgroundColor: '#F6F6F9',
                  fontWeight: 'bold',
                  color: '#27284D',
                  padding: '8px 10px'
                }}
                type="button"
                iconLeft={images.admin.plus}
                handleClick={() => setSetupCat(!setupCat)}
              />
            </div>
          </div>
        </div>
      </div>
      <TableCategories
        categories={categories}
        handleSwitch={handleSwitch}
        handleDeleteCategory={handleDeleteCategory}
        handleEditCategory={handleEditCategory}
      />
      {setupCat && (
        <div
          className="channel__body admin__channel__body"
          style={{
            borderTop: 'unset',
            display: 'block',
            paddingLeft: 24,
            paddingRight: 20,
            marginTop: 50
          }}
        >
          <div className="inner_container">
            <div className="category__setup">
              <div className="category__setup__left">
                <div style={{ marginBottom: 15 }}>
                  <div className="category__head">Category name*</div>
                  <div className="inner_container">
                    <div
                      className="add-course__upload-wrap-first"
                      style={{ padding: 0 }}
                    >
                      <input
                        type="text"
                        name="name"
                        className="add-course__input"
                        placeholder="eg. Finance"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="category__head">Category Description</div>
                  <div className="inner_container">
                    <div
                      className="add-course__upload-wrap-first"
                      style={{ padding: 0 }}
                    >
                      <textarea
                        className="add-course__textarea mgbt-0"
                        placeholder="eg. Financial Literacy"
                        rows="2"
                        value={formik.values.description}
                        name="description"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="category__setup__center">
                <div>
                  <div className="category__head">Category Image*</div>
                  <div className="inner_container">
                    <div
                      className="category__setup__upload-wrap"
                      style={{ padding: 0, alignItems: 'flex-end' }}
                    >
                      <div
                        className="add-course__img"
                        style={{
                          marginRight: 30,
                          width: isMediumDesktop ? 240 : 300,
                          height: 140,
                          borderRadius: 20
                        }}
                      >
                        <img src={renderPhoto()} alt="" />
                      </div>
                      <div className="category__setup__cta">
                        {photo && photo.name && (
                          <div className="add-course__file-name">
                            {photo.name}
                          </div>
                        )}
                        {photo && photo.size && (
                          <div className="add-course__file-size">
                            {photo.size + 'kb'}
                          </div>
                        )}
                        <div className="add-course__file-upload">
                          <button
                            className="tertiary-btn"
                            type="button"
                            onClick={handleClickPhoto}
                            style={{ width: '111px!important' }}
                          >
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              ref={photoFileRef}
                              onChange={handleChangePhoto}
                            />
                            <img src={images.header.image_none_bg} alt="" />
                            <span>Browse</span>
                          </button>
                        </div>
                        <div
                          className="add-course__file-remove"
                          onClick={handleRemovePhoto}
                        >
                          <button className="tertiary-btn" type="button">
                            <img src={images.admin.trash_icon} alt="" />{' '}
                            <span>Discard</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="category__setup__right">
                <button className="btn btn--primary" type="submit">
                  <span className="btn__text">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  )
}

export default Categories
