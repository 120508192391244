import React, { useMemo, useState, useRef, useEffect } from 'react'
import images from 'Themes/Images'
import Quiz from '../../../../Quiz'
import { initialQuestion, initialChoicesQuestion } from 'utils/constants'
import { setQuestions } from '../../../../../actions'
import './styles.scss'
import QuizSettings from '../../QuizSettings'
import SimpleLoading from 'component/SimpleLoading'
import useGetTrainer from '../../../../../../../hooks/useGetTrainer'

const QuizLesson = ({
  questions,
  idCourse,
  formik,
  handleSubmit,
  dispatch,
  slideDetails,
  currentPage,
  setCurrentPage,
  bottomRef,
}) => {
  const { isTrainer } = useGetTrainer()
  const listQuizRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const newQuestions = useMemo(
    () => Array.isArray(questions) && questions.slice(0, currentPage),
    [questions, currentPage]
  )

  useEffect(() => {
    if (slideDetails) {
      setCurrentPage(2)
    }
  }, [slideDetails])

  useEffect(() => {
    if (loading) {
      handleMoreData()
    }
  }, [loading])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [currentPage, questions])

  const handleScroll = () => {
    if (
      window.innerHeight / 2 + document.documentElement.scrollTop >=
        listQuizRef?.current?.clientHeight &&
      currentPage < questions?.length
    ) {
      setLoading(true)
    }
  }

  const handleAddMoreQuiz = (type) => {
    const sequence =
      questions.length !== 0 ? questions[questions.length - 1].sequence + 1 : 1
    setCurrentPage(questions.length + 1)
    if (type === 'choices') {
      dispatch(
        setQuestions([
          ...questions,
          {
            ...initialChoicesQuestion,
            sequence,
          },
        ])
      )
    } else {
      dispatch(
        setQuestions([
          ...questions,
          {
            ...initialQuestion,
            sequence,
          },
        ])
      )
    }
  }

  const handleMoreData = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 2)
      setLoading(false)
    }, 200)
  }

  return (
    <div className="quiz-lesson__wrap">
      <div
        className={`quiz-lesson__all-questions ${
          isTrainer ? 'disabledbutton' : ''
        }`}
      >
        <div ref={listQuizRef}>
          {newQuestions &&
            newQuestions.map((question, index) => (
              <Quiz
                key={index}
                question={question}
                index={index}
                questions={questions}
                idCourse={idCourse}
                formik={formik}
                handleSubmit={handleSubmit}
              />
            ))}
          {loading && <SimpleLoading />}
        </div>

        <div style={{ margin: '20px 0 40px 20px' }} ref={bottomRef}>
          <div
            className={`quiz-addnew ${isTrainer ? 'disabledbutton' : ''}`}
            onClick={() => handleAddMoreQuiz()}
          >
            <div className="quiz-addnew__icon">
              <img src={images.admin.plus_normal} alt="" />
            </div>
            <div className="">
              <div className="quiz-addnew__text">
                Add an open-ended question
              </div>
              <div className="quiz-addnew__desc">
                Student upload their answers via typing, image or file upload,
                or via links.
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ margin: '0px 0 40px 20px' }}
          className={`${isTrainer ? 'disabledbutton' : ''}`}
        >
          <div
            className="quiz-addnew"
            onClick={() => handleAddMoreQuiz('choices')}
          >
            <div className="quiz-addnew__icon">
              <img src={images.admin.plus_normal} alt="" />
            </div>
            <div className="">
              <div className="quiz-addnew__text">
                Add a multiple choice question (MCQ)
              </div>
              <div className="quiz-addnew__desc">
                Students pick an answer from a few options prepared by you.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="add-course__heading" style={{ borderTop: 'unset' }}>
        Quiz Settings
      </div>
      <div
        style={{ margin: '20px 0 80px 20px' }}
        className={`${isTrainer ? 'disabledbutton' : ''}`}
      >
        <QuizSettings formik={formik} />
      </div>
    </div>
  )
}

export default QuizLesson
