import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { parse } from 'query-string'
import dayjs from 'dayjs'
import Dropdown from 'react-dropdown'
import download from 'downloadjs'
import axios from 'axios'

import images from 'Themes/Images'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'

import HeaderCourseView from '../../screens/CourseView/HeaderCourseView'
import { makeSelectCourse } from 'container/Home/selectors'
import { loadCourse } from 'container/Home/actions'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import { mapScheduleToDropDown } from 'utils/helper'
import './styles.scss'
import { showGlobalLoading } from '../../../Modal/actions'
import { hideGlobalLoading } from 'container/Modal/actions'
import TableBages from './TableBages'

const BadgesReview = () => {
  const dispatch = useDispatch()
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [profiles, setProfiles] = useState(null)
  const course = useSelector(makeSelectCourse())

  const history = useHistory()
  const { pathname, search } = useLocation()
  const parsedSearch = parse(search)
  const requiredSchedule = get(course, 'is_schedule', false)

  const schedules = useSelector(selectors.makeSelectSchedules())
  const listUserByCourse = useSelector(selectors.makeSelectListUserByCourse())

  const initialSelectSchedule = mapScheduleToDropDown(
    schedules,
    false,
    false,
    true
  )
  const { id } = useParams()

  useEffect(() => {
    if (Array.isArray(schedules) && parsedSearch && parsedSearch.schedule) {
      const schedule = schedules.find(
        (item) => item.id === parseInt(parsedSearch.schedule)
      )
      const id = get(schedule, 'id')
      const startDate = get(schedule, 'start_date', '')
      const endDate = get(schedule, 'end_date', '')
      const label = `${dayjs(startDate).format('DD MMM')} - ${dayjs(
        endDate
      ).format('DD MMM YYYY')}`
      setSelectedSchedule({ value: id, label })
    }
  }, [parsedSearch.schedule, schedules])

  useEffect(() => {
    if (id) {
      const isAdmin = true
      dispatch(loadCourse(id))
      dispatch(actions.getSchedules(id, isAdmin))
    }
  }, [id])

  useEffect(() => {
    if (parsedSearch && parsedSearch.user && Array.isArray(listUserByCourse)) {
      const user = listUserByCourse.find(
        (item) => item.id === parseInt(parsedSearch.user)
      )

      setCurrentUser(user)
    }
  }, [parsedSearch.user, listUserByCourse])

  useEffect(() => {
    if (id) {
      dispatch(
        actions.getUserByCourseAndSchedule({ id, schedule: selectedSchedule })
      )
    }
  }, [id, selectedSchedule])

  const handleBathGenerate = async () => {
    const data = []
    if (listUserByCourse && listUserByCourse.length > 0) {
      dispatch(showGlobalLoading())
      for (let i = 0; i < listUserByCourse.length; i++) {
        const record = listUserByCourse[i]
        const courseName = get(course, 'name', '')
        const profileName =
          get(profiles, `[${i}][0].name`, '') ||
          get(record, 'user.partner.name', '')

        data.push({ courseName, profileName })
      }
    }

    const requestUrl = `${config.api}/send-mail/badges-pdf`

    await axios
      .post(
        requestUrl,
        { data },
        {
          responseType: 'arraybuffer',
        }
      )
      .then(async (response) => {
        download(response.data, 'badges.zip', 'application/zip')
        return response.data
      })
    dispatch(hideGlobalLoading())
  }

  return (
    <div>
      <HeaderCourseView course={course} />
      <div className="oc-views">
        <div className="channel" style={{ margin: 0, height: 720 }}>
          <div className="channel__head">
            <div className="channel__head__left">
              <div className="channel__chapter">
                COURSE: {get(course, 'name', '')}
              </div>
              <div className="channel__title">Badges Management</div>
            </div>
            <div
              className="channel__head__right"
              style={{ alignItems: 'center' }}
            >
              <div
                className="channel__head__right__button"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {listUserByCourse && (
                  <div style={{ marginRight: 20 }}>
                    <BtnPrimary
                      name="Batch Generate"
                      icon={images.admin.download}
                      style={{
                        color: '#042580',
                        backgroundColor: '#f6f6f9',
                      }}
                      handleClick={handleBathGenerate}
                      type="button"
                    />
                  </div>
                )}
                {requiredSchedule && (
                  <>
                    <div
                      style={{
                        marginRight: 15,
                        whiteSpace: 'nowrap',
                        fontWeight: '600',
                        fontSize: 12,
                        color: '#27284D',
                      }}
                    >
                      Select batch
                    </div>
                    <div style={{ width: 230 }}>
                      {initialSelectSchedule && (
                        <Dropdown
                          options={initialSelectSchedule}
                          onChange={(value) => {
                            setCurrentUser(null)
                            history.push(pathname + `?schedule=${value.value}`)
                          }}
                          value={selectedSchedule}
                          placeholder="All Schedules"
                          className="dropdownScheduleModal"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <Row>
            <Col xs={24} md={24}>
              <div className="quiz__right-answer-2" style={{ height: 685 }}>
                <TableBages
                  course={course}
                  listUserByCourse={listUserByCourse}
                  profiles={profiles}
                  setProfiles={setProfiles}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default BadgesReview
