import React, { useEffect, useRef } from 'react'
import './styles.scss'

const Input = ({
  label,
  name,
  value,
  onChange,
  formik,
  placeHolder,
  className,
  onBlur,
  autoComplete = '',
  noScroll,
  style,
  error
}) => {
  const fieldRef = React.useRef(null)

  useEffect(() => {
    if (
      !noScroll &&
      formik &&
      formik.touched[name] &&
      formik.errors[name] &&
      formik.isSubmitting &&
      fieldRef
    ) {
      return window.scrollTo({
        behavior: 'smooth',
        top: fieldRef.current.offsetTop + 100
      })
    }
  }, [formik])
  return (
    <div className={className}>
      {label && <div className="field__label">{label}</div>}
      <input
        style={style}
        ref={fieldRef}
        type="text"
        name={name}
        className={`field__input ${
          formik &&
          formik.touched[name] &&
          formik.errors[name] &&
          'form__input__error'
        } ${error ? 'form__input__error' : ''}`}
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        onBlur={onBlur}
        autoComplete={autoComplete}
      />
      {formik && formik.touched[name] && formik.errors[name] && (
        <div className="form__error">{formik.errors[name]}</div>
      )}
    </div>
  )
}

export default Input
