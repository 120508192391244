import React from 'react'
import { get, sortBy } from 'lodash'
import { Modal } from 'antd'
import styled from 'styled-components'
import { hideReorderQuizModal } from '../../actions'
import { makeSelectSelectedSlide } from '../../../Home/selectors'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { createQuiz } from '../../../Admin/actions'
import parseHtml from 'html-react-parser'

const LIST_QUIZ_WRAPPER = styled.div`
  display: flex;
  flex-direction: column;
`

const QUIZ_ITEM = styled.div`
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  &:not(:last-child) {
    margin-bottom: 18px;
  }
  span {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ReorderQuizModal = ({ dispatch, showReorderQuizModal, useSelector }) => {
  const selectedSlide = useSelector(makeSelectSelectedSlide())
  const questions = get(selectedSlide, 'questions', [])
  const allQuestions =
    Array.isArray(questions) && sortBy(questions, ['sequence'])

  const handleCancel = () => {
    dispatch(hideReorderQuizModal())
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle
  })

  const QuizList = () => {
    return (
      <LIST_QUIZ_WRAPPER>
        {allQuestions &&
          allQuestions.map((item, index) => (
            <QuizItem item={item} index={index} key={item.id} />
          ))}
      </LIST_QUIZ_WRAPPER>
    )
  }

  const QuizItem = ({ item, index }) => {
    return (
      <Draggable draggableId={item.id + ''} index={index}>
        {(provided, snapshot) => (
          <QUIZ_ITEM
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            Question {item.sequence}: <span>{parseHtml(item.question)}</span>
          </QUIZ_ITEM>
        )}
      </Draggable>
    )
  }

  const handleDragEnd = (result) => {
    if (
      !result.destination ||
      !result.source ||
      result.destination.index === result.source.index
    ) {
      return
    }

    const quizSource = allQuestions[result.source.index]
    const quizDes = allQuestions[result.destination.index]

    reorderQuiz(quizSource, quizDes, result)
  }

  const reorderQuiz = async (source, des, result) => {
    const sequenceSource = get(source, 'sequence')
    const sequenceDes = get(des, 'sequence')
    const newQuestions = [...allQuestions]
    const sourceIndex = result.source.index
    const desIndex = result.destination.index

    const subtractionIndex = sourceIndex - desIndex

    if (subtractionIndex === -1 || subtractionIndex === 1) {
      newQuestions[sourceIndex].sequence = sequenceDes
      newQuestions[desIndex].sequence = sequenceSource
    } else if (subtractionIndex < -1) {
      newQuestions[sourceIndex].sequence = sequenceDes
      for (let i = desIndex; i > sourceIndex; i--) {
        newQuestions[i].sequence = i
      }
    } else if (subtractionIndex > 1) {
      newQuestions[sourceIndex].sequence = sequenceDes
      let index = sequenceDes
      for (let i = desIndex; i < sourceIndex; i++) {
        newQuestions[i].sequence = index + 1
        index++
      }
    }
    const id = get(selectedSlide, 'channel_id')
    const name = get(selectedSlide, 'name')
    dispatch(
      createQuiz({
        questions: newQuestions,
        idCourse: id,
        name: name
      })
    )
  }

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 8
  })

  return (
    <Modal visible={showReorderQuizModal} footer={null} onCancel={handleCancel}>
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Reorder Quiz
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <QuizList />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="ld-modal__btn__cancel" style={{ marginTop: 25 }}>
          <button className="tertiary-btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ReorderQuizModal
