import { createSelector } from 'reselect'

const selectHomeDomain = (state) => state.admin

export const makeSelectTags = () =>
  createSelector(selectHomeDomain, (subState) => subState.tags)

export const makeSelectSlideTags = () =>
  createSelector(selectHomeDomain, (subState) => subState.slideTags)

export const makeSelectCourseType = () =>
  createSelector(selectHomeDomain, (subState) => subState.courseType)

export const makeSelectSelectedSection = () =>
  createSelector(selectHomeDomain, (subState) => subState.selectedSection)

export const makeSelectZoomLink = () =>
  createSelector(selectHomeDomain, (subState) => subState.zoomLink)

export const makeSelectListUserAnswer = () =>
  createSelector(selectHomeDomain, (subState) => subState.listUserAnswer)

export const makeSelectAccountStripe = () =>
  createSelector(selectHomeDomain, (subState) => subState.accountStripe)

export const makeSelectSchedules = () =>
  createSelector(selectHomeDomain, (subState) => subState.schedules)

export const makeSelectListOutcome = () =>
  createSelector(selectHomeDomain, (subState) => subState.listOutcome)

export const makeSelectAssessment = () =>
  createSelector(selectHomeDomain, (subState) => subState.assessment)

export const makeSelectAttachments = () =>
  createSelector(selectHomeDomain, (subState) => subState.attachments)

export const makeSelectZooms = () =>
  createSelector(selectHomeDomain, (subState) => subState.zooms)

export const makeSelectListUser = () =>
  createSelector(selectHomeDomain, (subState) => subState.listUser)

export const makeSelectUserData = () =>
  createSelector(selectHomeDomain, (subState) => subState.userData)

export const makeSelectUserProfile = () =>
  createSelector(selectHomeDomain, (subState) => subState.userProfile)

export const makeSelectListSchedule = () =>
  createSelector(selectHomeDomain, (subState) => subState.listSchedule)

export const makeSelectListUserByCourse = () =>
  createSelector(selectHomeDomain, (subState) => subState.listUserByCourse)

export const makeSelectLearningOutComeByUser = () =>
  createSelector(selectHomeDomain, (subState) => subState.learningOutComeByUser)

export const makeSelectListSetOutcomeStudents = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.listSetOutComeStudents
  )

export const makeSelectListCustomOutcomeStudent = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.listCustomOutcomeStudent
  )

export const makeSelectListSetOutcomeCustom = () =>
  createSelector(selectHomeDomain, (subState) => subState.listSetOutcomeCustom)

export const makeSelectPaymentStatus = () =>
  createSelector(selectHomeDomain, (subState) => subState.paymentStatus)

export const makeSelectQuestions = () =>
  createSelector(selectHomeDomain, (subState) => subState.questions)
