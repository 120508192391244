import config from 'src/global-config'
import { getAccessToken } from 'utils/request'

export const deleteCourse = async (id) => {
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${id}`
  try {
    await fetch(requestURL, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    })
  } catch (err) {
    console.log('err', err)
  }
}

export const updateCourse = async (course, id, isPublished) => {
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${id}`
  try {
    await fetch(requestURL, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      },
      body: JSON.stringify({
        ...course,
        is_published: isPublished,
        prices: [],
        sfc_prices: []
      })
    })
  } catch (err) {
    console.log('err', err)
  }
}
