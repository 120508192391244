import React from 'react'
import { Radio } from 'antd'
import classNames from 'classnames'
import BtnPrimary from 'component/BtnPrimary'
const WeChatPay = ({ formik, submitRef }) => {
  return (
    <div className="card__main">
      <Radio value="wechat_pay">
        <span className="hs-checkout__heading-2">Pay with WeChat Pay</span>
      </Radio>
      <div
        className={classNames('payment_method', {
          toggle_payment_method: formik.values.payment_method === 'wechat_pay',
        })}
      >
        <BtnPrimary
          name="Pay with WeChat Pay"
          style={{
            backgroundColor: '#00122A',
            fontWeight: 600,
            padding: '12px 40px',
            width: '100%',
          }}
          handleClick={() => submitRef.current.click()}
          type="button"
        />
      </div>
    </div>
  )
}

export default WeChatPay
