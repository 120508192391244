import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { parse } from 'query-string'
import {
  makeSelectListCourse,
  makeSelectDomain,
} from 'container/Home/selectors'
import TableEnrollment from '../TableEnrollment'
import { getCourseEnrollment } from 'utils/helper'
import { loadListCourse } from 'container/Home/actions'
import { get } from 'lodash'
import { showDetailsRegistration } from 'container/Modal/actions'

const Enrollment = ({ userData, userProfile, dispatch }) => {
  const listCourse = useSelector(makeSelectListCourse())
  const domain = useSelector(makeSelectDomain())
  const listCourseEnrollment = getCourseEnrollment(listCourse)
  const { search } = useLocation()
  const { course_id } = parse(search)

  useEffect(() => {
    if (course_id && listCourse) {
      const course = listCourseEnrollment.find(
        (item) => item.id === parseInt(course_id)
      )
      const partner = get(course, 'partners[0]', null)
      if (course) {
        dispatch(
          showDetailsRegistration({ ...partner, ...userData, course: course })
        )
      }
    }
  }, [course_id, listCourseEnrollment])

  useEffect(() => {
    if (userData) {
      dispatch(
        loadListCourse({
          partner: get(userData, 'partner_id'),
          admin: true,
          filter: 'sequence',
          newFilter: 'per_page=1000&ignore_per_page=true&include_delete=true',
        })
      )
    }
  }, [userData])

  return (
    <div>
      <h1 className="u-manage__heading">Enrolment</h1>
      {listCourse && (
        <TableEnrollment
          listCourseEnrollment={listCourseEnrollment}
          userData={userData}
          userProfile={userProfile}
          domain={domain}
        />
      )}
    </div>
  )
}

export default Enrollment
