// eslint-disable-next-line
import produce from 'immer'
import * as constants from './constants'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'

export const initialState = {
  currentUser: null,
  userInfo: null,
  error: '',
  accessToken: '',
}

const persistConfig = {
  key: 'auth',
  storage: localStorage,
  whitelist: ['currentUser', 'accessToken'],
}

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      //LOGIN
      case constants.LOGIN:
        break
      case constants.LOGIN_SUCCESS:
        newState.currentUser = action.payload
        newState.error = ''
        break
      case constants.LOGIN_FAILED:
        newState.error = action.error
        break
      //GET_USER_INFO_BY_TOKEN
      case constants.GET_USER_INFO_BY_TOKEN:
        break
      case constants.GET_USER_INFO_BY_TOKEN_SUCCESS:
        newState.userInfo = action.payload
        break
      case constants.GET_USER_INFO_BY_TOKEN_FAILED:
        break
      //SIGNUP
      case constants.SIGNUP:
        break
      case constants.SIGNUP_SUCCESS:
        newState.currentUser = action.payload
        newState.error = ''
        break
      case constants.SIGNUP_FAILED:
        newState.error = action.error
        break
      //GET_USER_INFO_BY_TOKEN
      case constants.GET_USER_INFO_BY_TOKEN:
        break
      case constants.GET_USER_INFO_BY_TOKEN_SUCCESS:
        newState.userInfo = action.payload
        break
      case constants.GET_USER_INFO_BY_TOKEN_FAILED:
        break
      //LOGOUT
      case constants.LOGOUT:
        newState.currentUser = null
        break

      case constants.SET_ACCESS_TOKEN:
        newState.accessToken = action.payload
        break
    }
  })

export default persistReducer(persistConfig, reducer)
