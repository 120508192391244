import React from 'react'

import Dropdown from 'react-dropdown'
import { mapListClientToDropDown } from 'utils/helper'

const ClientSelect = ({ formik, clients, currentUserClients }) => {
  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Dropdown
        options={mapListClientToDropDown(clients, currentUserClients)}
        onChange={(data) => formik.setFieldValue('client', data)}
        value={formik.values?.client}
        className="dropdownScheduleModal"
        placeholder="Select client"
      />
    </div>
  )
}

export default ClientSelect
