import styled from 'styled-components'

export const PreviewPageLayout = styled.div`
  width: 100%;
  background: #fff;
  min-height: calc(100vh - 110px);
  position: relative;
  font-family: 'Jost', sans-serif;
  color: #ffffff;
  .wrapper {
    position: relative;
    z-index: 1000;
  }
  .preview__container {
    width: 1170px;
    margin: 0 auto;
    display: flex;
    position: relative;
    padding-top: 100px;
    z-index: 100;

    @media (max-width: 1170px) {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 80px;
    }

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }
  .preview__container__left {
    flex: 1;
    margin-right: 30px;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-right: 0;
      padding: 0 20px;
    }
  }
  .preview__container__right {
    width: 370px;

    @media (max-width: 1170px) {
      width: 320px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 20px;
      margin-bottom: 40px;
    }
  }
  .preview__course-title {
    font-weight: 500;
    font-size: 35px;
    line-height: 51px;
    text-transform: capitalize;
    color: #ffffff;
    @media (max-width: 767px) {
      color: #2f2d51;
    }
  }

  .preview__flex {
    display: flex;
    div:first-child {
      margin-right: 20px;
    }
  }
  .preview__course-cat,
  .preview__course-review {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
  }
  .preview__course-cat-title,
  .preview__course-rating {
    font-weight: 400;
    font-size: 14px;
  }
  .preview__course-star {
    padding-right: 5px;
  }
  .preview__course-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .preview__course-desc-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #77838f;
  }
  .preview__course-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #2f2d51;
  }
  .preview__course-description {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  .preview__read-more {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: #4a8f9f;
  }
  .preview__banner {
    margin-top: 50px;
    width: 770px;
    height: 500px;
    border-radius: 5px;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .preview__course-benefit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }
  .preview__course-benefit-item {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-column-gap: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #77838f;
  }
  .preview__course-requirements,
  .preview__course-curriculum,
  .preview__course-benefits,
  .preview__course-instructor {
    margin-top: 50px;
  }

  .preview__course-require-item {
    font-weight: 400;
    font-size: 16px;
    color: #77838f;
    display: flex;
    align-items: flex-start;
    line-height: 45px;
    & img {
      margin-right: 15px;
      padding-bottom: 5px;
    }
  }
  .preview__header__wrapper {
    min-height: 300px;
    @media (max-width: 767px) {
      color: #2f2d51;
    }
  }
`

export const BannerImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${(props) => props.bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: ${(props) => `${props.height}px`};

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .blur {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: ${(props) => `${props.opacity ? props.opacity : '0.4'}`};
  }
`
