import React from 'react'
import { Row, Col } from 'antd'
import { get } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import Input from 'component/Input'
import { renderColValue } from 'utils/helper'

const Default = ({
  formik,
  editable,
  userProfile,
  userData,
  isAscendoDomain = false,
}) => {
  const name = get(userProfile, 'name', '') || get(userData, 'partner.name', '')
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isEditOnMobile = editable && isMobile
  const passport = get(userProfile, 'passport', '')
  const phone =
    get(userProfile, 'phone', '') || get(userData, 'partner.mobile', '')

  const handleChangePhoneNum = ({ currentTarget }) => {
    var regExp = /^[0-9]{1,13}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue('phone', currentTarget.value)
    }
  }

  return (
    <>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12, isEditOnMobile)}
        >
          NAME (ACCORDING TO ID/PASSPORT):
        </Col>
        <Col xs={renderColValue(24, 10)} md={6} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="name"
                placeHolder="Enter your full name"
                formik={formik}
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            name
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12, isEditOnMobile)}
        >
          Phone No.:
        </Col>
        <Col
          md={6}
          xs={renderColValue(24, 10, isEditOnMobile)}
          className="profile__text"
        >
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="phone"
                placeHolder="Enter Phone No."
                formik={formik}
                value={formik.values.phone}
                onChange={handleChangePhoneNum}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            phone
          )}
        </Col>
      </Row>

      {isAscendoDomain && (
        <Row>
          <Col
            className="profile__name-2 profile__col"
            md={6}
            xs={renderColValue(24, 12)}
          >
            ID (eg. NRIC/FIN) / Passport No. :
          </Col>
          <Col md={6} xs={10} className="profile__text">
            {editable ? (
              <div className="profile__field__wrapper">
                <Input
                  name="passport"
                  placeHolder="Enter ID/Passport"
                  formik={formik}
                  value={formik.values.passport}
                  onChange={formik.handleChange}
                  style={{ padding: 8 }}
                />
              </div>
            ) : (
              passport
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export default Default
