import React, { useMemo, useEffect } from 'react'
import { Banner, Body, Layout } from '../../../styles'
import styled from 'styled-components'
import ListCourse from '../../components/ListCourse'
import { useParams } from 'react-router-dom'

import { get } from 'lodash'
import { LIST_CAT } from '../../constants'
import { useSelector, useDispatch } from 'react-redux'
import { makeSelectListCourse } from '../../../../../container/Home/selectors'
import { makeSelectListCourseOfSeller } from '../../../../SuperAdmin/container/AdminActor/selectors'
import { mapListCourseOfSeller } from 'utils/helper'
import {
  loadListCategory,
  loadListCourse
} from '../../../../../container/Home/actions'
import { loadListCourseOfSeller } from '../../../../SuperAdmin/container/AdminActor/actions'
import NavbarClassCompare from '../../components/NavbarClassCompare'
import ListCourses from 'component/ListCourses'

const MainDescription = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 85px;
  margin-bottom: 100px;

  @media (max-width: 767px) {
    display: block;
    margin-top: 85px;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
  }
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 100%;
    color: #ffffff;
    text-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
    @media (max-width: 767px) {
      font-size: 27px;
    }
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 145.6%;
    font-size: 18px;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
  }
`

const CourseListing = ({ categories, normalRoute }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const category = useMemo(() => {
    return (
      categories &&
      categories.find((cat) => get(cat, 'name', '').toLowerCase() === id)
    )
  }, [categories, id])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  const categoryName = get(category, 'name', '')
  const categoryId = get(category, 'id', '')

  const listCourses = useSelector(makeSelectListCourse())

  const listCourseOfSeller = useSelector(makeSelectListCourseOfSeller())
  const courses = useMemo(() => {
    return normalRoute
      ? listCourses
      : mapListCourseOfSeller(listCourseOfSeller, listCourses)
  }, [listCourses, listCourseOfSeller])

  useEffect(() => {
    if (id && categoryId) {
      dispatch(
        loadListCourse({
          superAdmin: normalRoute ? false : true,
          catId: categoryId
          // partner: get(currentUser, 'partner_id', '')
        })
      )
    }
  }, [id, categoryId])

  useEffect(() => {
    dispatch(loadListCourseOfSeller({ userId: '738' }))
  }, [])

  return (
    <Layout>
      <Banner
        img={
          normalRoute
            ? get(category, 'image', '') + '=s0'
            : get(LIST_CAT, `[${categoryName}].banner`, '')
        }
        height={280}
        heightMobile={215}
        top={normalRoute ? 50 : 0}
      />
      <Body>
        {!normalRoute && <NavbarClassCompare />}
        <MainDescription>
          <section>
            <h1>{categoryName}</h1>
            <h3>
              {normalRoute
                ? get(category, 'description', '')
                : get(LIST_CAT, `[${categoryName}].desc`, '')}
            </h3>
          </section>
        </MainDescription>
        {normalRoute ? (
          <ListCourses courses={courses} activeTitle />
        ) : (
          <ListCourse activeTitle courses={courses} mgBt={60} />
        )}
      </Body>
    </Layout>
  )
}

export default CourseListing
