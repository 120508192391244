import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  formatMoney,
  getRawDescription,
  getDescriptionData
} from 'utils/helper'
import { getOptionPrice } from 'utils/helper'
import { makeSelectListCategory } from 'container/Home/selectors'
import images from '../../../assets/images'
import { showVideoPlayer } from 'container/Modal/actions'
import { loadCourseSuccess } from 'container/Home/actions'

export const CourseItemWrap = styled.div`
  width: 300px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  cursor: pointer;
  position: relative;
  max-height: 425px;
  margin-right: 30px;

  @media (max-width: 767px) {
    margin-right: 15px !important;
    width: 280px;
    flex: 0 0 auto;
  }

  margin-bottom: 30px;
  .course_img {
    position: relative;
    width: 100%;
    height: 180px;
    z-index: 10;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
  .course_cat {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    padding-bottom: 10px;
  }
  .course {
    height: 100%;
  }
  .course_info {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    padding: 20px 20px 20px 28px;
    background-color: #fff;
    height: 100%;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
  }
  .course_title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #666666;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .course_desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #999999;
    margin-bottom: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .course_fee {
    position: absolute;
    bottom: 25px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #999999;
    display: flex;
    & div {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
    strong {
      font-size: 16px;
      color: #666666;
    }
    &__name {
      display: block;
      padding-bottom: 5px;
    }
  }
`

const ReviewerWrapper = styled.div`
  display: flex;
  align-items: center;
  .introduce {
    .title {
      font-weight: 600;
      font-size: 12px;
      color: #999999;
      margin-bottom: 5px;
      text-transform: none;
    }
    .text {
      font-size: 11px;
      color: #999999;
      text-transform: none;
    }
  }
`

const CourseItem = ({ course, cmsArticle }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const categories = useSelector(makeSelectListCategory())

  const categoryId = get(course, 'category_id')
  const category = categories && categories.find((cat) => cat.id === categoryId)
  const imageUrl = get(course, 'image_url', '')

  const name = get(course, 'name', '')
  const shortDescription = get(course, 'short_description', '')
  const description = getDescriptionData(get(course, 'description', ''))
  const rawDescription = getRawDescription(description)

  const prices = get(course, 'prices', [])
  const lowestPrice = getOptionPrice(prices)
  const highestPrice = getOptionPrice(prices, 'highest')

  const handleClickCourse = () => {
    if (cmsArticle) {
      history.push(`/article/${course.slug}`)
    } else {
      history.push(`/course/preview/${course.id}`)
    }
  }

  //Article Data
  const imageArticle = get(course, 'image', '')
  const titleArticle = get(course, 'title', '')
  const descriptionArticle = get(course, 'short_description', '')
  const previewUrl = get(course, 'preview_url', '')
  const handlePlayVideo = () => {
    dispatch(loadCourseSuccess(course))
    dispatch(showVideoPlayer())
  }

  return (
    <CourseItemWrap onClick={previewUrl ? () => {} : handleClickCourse}>
      <div className="course_img">
        <img
          src={cmsArticle ? imageArticle : imageUrl}
          alt={cmsArticle ? titleArticle : name}
        ></img>
        {previewUrl && (
          <div
            className="play_btn"
            onClick={handlePlayVideo}
            style={{ width: 50, height: 50 }}
          >
            <img src={images.playBtnSmall} alt="play button" />
          </div>
        )}
      </div>
      <div className="course_info" onClick={handleClickCourse}>
        <div className="course_cat">
          {!cmsArticle ? get(category, 'name', '') : ''}
        </div>
        <div className="course_title">{cmsArticle ? titleArticle : name}</div>
        <div className="course_desc">
          {cmsArticle
            ? descriptionArticle
            : shortDescription
            ? shortDescription
            : rawDescription}
        </div>
        <div className="course_fee">
          {highestPrice ? (
            <div className="course_full-fee">
              <span className="course_fee__name">FULL FEE</span>
              <strong>{formatMoney(highestPrice, null)}</strong>
            </div>
          ) : (
            ''
          )}
          {lowestPrice ? (
            <div className="course_subside-fee">
              <span className="course_fee__name">SUBSIDISED FEE</span>
              <strong>{formatMoney(lowestPrice, null)}</strong>
            </div>
          ) : (
            ''
          )}
          {cmsArticle && (
            <ReviewerWrapper>
              <div style={{ marginRight: 12 }}>
                <img
                  src={images.logo_reviewer}
                  width="40"
                  height="40"
                  alt="Mr. CompareHare"
                ></img>
              </div>
              <div className="introduce">
                <div className="title">Mr. CompareHare</div>
                <div className="text">Resident Reviewer</div>
              </div>
            </ReviewerWrapper>
          )}
        </div>
      </div>
    </CourseItemWrap>
  )
}

export default CourseItem
