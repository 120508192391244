import { useEffect, useState } from 'react'
import config from 'src/global-config'

export default function useSignatureHook(meetingId, zoomId) {
  const [signature, setSignature] = useState(null)
  useEffect(() => {
    const url = `${config.baseUrl}/api/notifications/zooms/signature?meeting_id=${meetingId}&zoom_id=${zoomId}`

    const fetchData = async () => {
      try {
        const response = await fetch(url, { method: 'GET' })
        const json = await response.json()
        if (json && json.data) {
          setSignature(json.data)
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    if (meetingId && zoomId) {
      fetchData()
    }
  }, [meetingId, zoomId])

  return { signature }
}
