import React from 'react'
import { Row, Col } from 'antd'
import CheckoutProgressBar from '../../components/CheckoutProgressBar'
import BtnPrimary from 'component/BtnPrimary'
import images from 'Themes/Images'

const Failed = ({
  history,
  isUnicornDomain,
  isSingaporeSkillsAcademies,
  requiredSchedule
}) => {
  return (
    <div>
      <CheckoutProgressBar
        indexActive={3}
        failed={true}
        isUnicornDomain={isUnicornDomain}
        requiredSchedule={requiredSchedule}
        isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
      />
      <Row className="hs-billing" gutter={70}>
        <Col
          xs={24}
          md={24}
          style={{
            paddingBottom: 80,
            marginBottom: 50,
            borderBottom: '1px solid #d0cad8'
          }}
        >
          <div className="hs-confirm__heading" style={{ marginBottom: 8 }}>
            Payment
          </div>
          <div className="hs-confirm__text">
            We could not process your payment. <br />
            Please try again or contact your institution’s admin for assistance.
          </div>
        </Col>
        <Col xs={24} md={24}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <BtnPrimary
              name="Back to courses page"
              icon={images.course.arrow_right_active}
              style={{
                backgroundColor: '#00122A',
                fontWeight: 600,
                padding: '12px 40px'
              }}
              styleIcon={{ marginBottom: 2 }}
              handleClick={() => {
                history.push('/')
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Failed
