import React from 'react'
import { Row, Col } from 'antd'
import Input from 'component/Input'
import images from 'Themes/Images'
import { Radio } from 'antd'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { get } from 'lodash'

import classNames from 'classnames'
import InputFile from '../InputFile'
import PaypalButton from '../PaypalButton'
import { formatMoney } from 'utils/helper'
import MultipleSFCPrice from '../MutilpleSFCPrice/index'
import { getOptionPrice } from 'utils/helper'
import MediaStripePay from '../MediaStripePay'
import WeChatPay from '../WechatPay'
import './styles.scss'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '12px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#a0abc3',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

const CardInformation = ({
  error,
  formik,
  course,
  history,
  dispatch,
  submitRef,
  currentUser,
  applicationFee,
  isAllowApplicationFee,
  isViaFrontiers,
  isUnicornDomain,
  firstMediaDomain,
  selectedSchedule,
  userPaymentByCourse,
  isArtofnumbersDomain,
  allQuantDomain,
  cyberQuoteDomain,
  imadviserDomain,
  isFlcDomain,
  isPortalDomain,
  specialPrice,
  allowSpecialPrice,
  handleNumberInput,
  complete,
  setComplete,
  paymentRequest,
  allowStripe,
  selectedPriceSFC,
  remainingAmount,
  isCustomCourse,
  isBellDomain,
  ascendoDomain,
}) => {
  const sfcPrices = get(course, 'sfc_prices', [])
  const lowestPrice = getOptionPrice(course.prices)

  const renderCourseFee = () => {
    if (!course || (course && course.prices.length === 0)) {
      return null
    }
    return (
      <div className="hs-checkout__text">
        Course Fee Payable -
        {course &&
          course.prices.map((item) => {
            return (
              <div key={item.title}>
                {item.title} - {formatMoney(item.price)}
              </div>
            )
          })}
      </div>
    )
  }
  const renderPaymentStripe = () => {
    return (
      <>
        {' '}
        <div className="card__main">
          <Radio value="stripe">
            <span className="hs-checkout__heading-2">
              Pay with your credit or debit card
            </span>
          </Radio>
          <div
            className={classNames('payment_method', {
              toggle_payment_method: formik.values.payment_method === 'stripe',
            })}
          >
            <Input
              name="fullName"
              label="Full name*"
              placeHolder="Enter name on card"
              formik={formik}
              value={formik.values.fullName}
              onChange={formik.handleChange('fullName')}
            />
            <div>
              <div className="field__label">CARD NUMBER*</div>
              <CardNumberElement
                id="cardNumber"
                className="card-number"
                placeholder="Enter 16 digit card number"
                options={ELEMENT_OPTIONS}
              />
              {error && <span className="form__error">{error}</span>}
            </div>
            <Row gutter={15}>
              <Col xs={24} md={10}>
                <div className="field__label">EXPIRY DATE*</div>
                <CardExpiryElement
                  id="expiry"
                  className="card-expiry"
                  options={ELEMENT_OPTIONS}
                />
              </Col>
              <Col xs={24} md={10}>
                <div className="field__label">CVV / CVC*</div>
                <CardCvcElement
                  id="cvc"
                  className="card-cvc"
                  placeholder="3-digit"
                  options={ELEMENT_OPTIONS}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={
            ascendoDomain ? 'media_stripe_pay_asc' : 'media_stripe_pay'
          }
        >
          <MediaStripePay paymentRequest={paymentRequest} formik={formik} />
        </div>
        {(ascendoDomain || isPortalDomain) && (
          <WeChatPay formik={formik} submitRef={submitRef} />
        )}
      </>
    )
  }

  const renderPaymentMethod = () => {
    if (isBellDomain) {
      return null
    }
    if (allQuantDomain || imadviserDomain || cyberQuoteDomain) {
      return (
        <>
          <div className="card__main">
            <PaypalButton
              allowSpecialPrice={allowSpecialPrice}
              specialPrice={specialPrice}
              formik={formik}
              course={course}
              history={history}
              dispatch={dispatch}
              applicationFee={applicationFee}
              isAllowApplicationFee={isAllowApplicationFee}
            />
          </div>
          {renderPaymentStripe()}
        </>
      )
    }

    if (firstMediaDomain) {
      return (
        <>
          {sfcPrices && sfcPrices.length > 0 ? (
            <div className="card__main">
              <div className="payment_method_block">
                <Radio value="credit">
                  <span className="hs-checkout__heading-2">
                    Pay using SkillsFuture Credit
                  </span>
                </Radio>
                <div className="payment_method_toggle">
                  <img
                    src={
                      formik.values.payment_method === 'credit'
                        ? images.admin.angle_up
                        : images.admin.angle_down
                    }
                    alt=""
                  />
                </div>
              </div>
              <div
                className={classNames('payment_method', {
                  toggle_payment_method:
                    formik.values.payment_method === 'credit',
                })}
              >
                {renderCourseFee()}
                <div className="hs-checkout__text">
                  You will be redirected to the Skillsfuture portal to make your
                  claims and brought back to this page to continue enrollment.
                </div>
                <Input
                  name="credit"
                  placeHolder="S1234567C"
                  label="Enter NRIC then click claim SFC below"
                  formik={formik}
                  value={formik.values.credit}
                  onChange={formik.handleChange}
                  className={classNames('payment_method', {
                    toggle_payment_method:
                      formik.values.payment_method === 'credit',
                  })}
                />
                <MultipleSFCPrice
                  allowSelectSFC={formik.values.credit}
                  submitRef={submitRef}
                  sfcPrices={sfcPrices}
                  selectedSchedule={selectedSchedule}
                  userPaymentByCourse={userPaymentByCourse}
                  complete={complete}
                  setComplete={setComplete}
                  selectedPriceSFC={selectedPriceSFC}
                />
              </div>
            </div>
          ) : null}

          <div className="card__main">
            <div className="payment_method_block">
              <Radio value="paynow">
                <span className="hs-checkout__heading-2">
                  Pay using Paynow / Bank Transfer
                </span>
              </Radio>
              <div className="payment_method_toggle">
                <img
                  src={
                    formik.values.payment_method === 'paynow'
                      ? images.admin.angle_up
                      : images.admin.angle_down
                  }
                  alt=""
                />
              </div>
            </div>

            <div
              className={classNames('payment_method', {
                toggle_payment_method:
                  formik.values.payment_method === 'paynow',
              })}
            >
              <div className="hs-checkout__info">
                <div className="hs-checkout__text">
                  If not using SkillsFuture Credit to claim for the course fees,
                  please make payment with the following methods and upload the
                  payment screenshot below then click Pay to proceed.
                </div>
                <div className="hs-checkout__heading-3">Bank Transfer:</div>
                <div className="hs-checkout__text">
                  Please include your full name in comments when making the
                  transfer.
                </div>
                <div className="hs-checkout__text">
                  <span style={{ fontWeight: 'bold' }}>
                    First Media Design School Pte Ltd
                  </span>
                </div>
                <div className="hs-checkout__text">
                  <span style={{ fontWeight: 'bold' }}>
                    DBS BANK Account No: 288-900902-7
                  </span>
                </div>
                <div className="hs-checkout__text" style={{ marginTop: 15 }}>
                  <span style={{ fontWeight: 'bold' }}>
                    PayNow UEN: 200517638C
                  </span>
                </div>
              </div>
              <div className="hs-checkout__info">
                <div className="hs-checkout__qr-code">
                  <img src={images.admin.firstmedia_paynow} alt="" />
                </div>
                <div className="hs-checkout__text" style={{ marginTop: 15 }}>
                  Use your banking app to scan or import PayNow QR. Once payment
                  is made please upload a screenshot of the payment below by
                  clicking on Browse. After selecting the screenshot click Pay
                  to proceed.
                </div>
              </div>
              <div className="hs-checkout__info">{renderCourseFee()}</div>

              <InputFile
                formik={formik}
                fieldName="attachment"
                fieldNameSecond="attachment_name"
              />
            </div>
          </div>
        </>
      )
    }

    if (isFlcDomain || isPortalDomain) {
      return (
        <>
          {!isCustomCourse &&
          !isAllowApplicationFee &&
          remainingAmount === 0 ? (
            <div className="card__main">
              <div className="payment_method_block">
                <Radio value="credit">
                  <span className="hs-checkout__heading-2">
                    Pay using SkillsFuture Credit
                  </span>
                </Radio>
                <div className="payment_method_toggle">
                  <img
                    src={
                      formik.values.payment_method === 'credit'
                        ? images.admin.angle_up
                        : images.admin.angle_down
                    }
                    alt=""
                  />
                </div>
              </div>
              <div
                className={classNames('payment_method', {
                  toggle_payment_method:
                    formik.values.payment_method === 'credit',
                })}
              >
                {renderCourseFee()}
                <div className="hs-checkout__text">
                  You will be redirected to the Skillsfuture portal to make your
                  claims and brought back to this page to continue enrollment.
                </div>
                <Input
                  name="credit"
                  placeHolder="S1234567C"
                  label="Enter NRIC then click claim SFC below"
                  formik={formik}
                  value={formik.values.credit}
                  onChange={formik.handleChange}
                  className={classNames('payment_method', {
                    toggle_payment_method:
                      formik.values.payment_method === 'credit',
                  })}
                />
                <MultipleSFCPrice
                  allowSelectSFC={formik.values.credit}
                  submitRef={submitRef}
                  sfcPrices={sfcPrices}
                  selectedSchedule={selectedSchedule}
                  userPaymentByCourse={userPaymentByCourse}
                  complete={complete}
                  setComplete={setComplete}
                  selectedPriceSFC={selectedPriceSFC}
                />
              </div>
            </div>
          ) : null}

          <div className="card__main">
            <div className="payment_method_block">
              <Radio value="paynow">
                <span className="hs-checkout__heading-2">
                  Pay using Paynow / Bank Transfer
                </span>
              </Radio>
              <div className="payment_method_toggle">
                <img
                  src={
                    formik.values.payment_method === 'paynow'
                      ? images.admin.angle_up
                      : images.admin.angle_down
                  }
                  alt=""
                />
              </div>
            </div>

            <div
              className={classNames('payment_method', {
                toggle_payment_method:
                  formik.values.payment_method === 'paynow',
              })}
            >
              <div className="hs-checkout__info">
                {isAllowApplicationFee && (
                  <div className="hs-checkout__text">
                    Please pay the application fee as shown in the checkout to
                    proceed.
                  </div>
                )}
              </div>
              <div className="hs-checkout__info">
                <div className="hs-checkout__heading-3">Bank Transfer:</div>
                <div className="hs-checkout__text">
                  Please include your full name in comments when making the
                  transfer.
                </div>
                <div className="hs-checkout__text">
                  Financial Life Coaching Pte Ltd
                </div>
                <div className="hs-checkout__text">
                  <span className="hs-checkout__heading-3">Bank Name:</span> DBS
                  Bank Ltd
                </div>
                <div className="hs-checkout__text">
                  <span className="hs-checkout__heading-3">Bank Code:</span>{' '}
                  7171
                </div>
                <div className="hs-checkout__text">
                  <span className="hs-checkout__heading-3">Account No:</span>{' '}
                  027-002238-3
                </div>
                <div className="hs-checkout__text">
                  <span className="hs-checkout__heading-3">
                    Bank Swift Code:
                  </span>{' '}
                  DBSSSGSG
                </div>
              </div>
              <div className="hs-checkout__info">
                <div className="hs-checkout__heading-3">Paynow Corporate:</div>
                <div className="hs-checkout__text">UEN 198101881G</div>
                <div className="hs-checkout__qr-code">
                  <img src={images.admin.flc_paynow_qrcode} alt="" />
                </div>
              </div>

              <InputFile
                formik={formik}
                fieldName="attachment"
                fieldNameSecond="attachment_name"
              />

              <div
                style={{
                  fontSize: 12,
                  width: 300,
                  marginBottom: 20,
                  color: 'red',
                }}
              >
                * Please upload screenshot of your bank transfer and enter your
                payment reference no. below.
              </div>
              <Input
                name="paynow"
                placeHolder="Enter Payment Reference No."
                formik={formik}
                value={formik.values.paynow}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="card__main">
            <div className="payment_method_block">
              <Radio value="company_sponsored">
                <span className="hs-checkout__heading-2">
                  Company Sponsored - Fill in company details here:
                </span>
              </Radio>
              <div className="payment_method_toggle">
                <img
                  src={
                    formik.values.payment_method === 'company_sponsored'
                      ? images.admin.angle_up
                      : images.admin.angle_down
                  }
                  alt=""
                />
              </div>
            </div>

            <div
              className={classNames('payment_method', {
                toggle_payment_method:
                  formik.values.payment_method === 'company_sponsored',
              })}
            >
              <Input
                label="Full name of company"
                name="company_name"
                placeHolder="Enter Full Name Of Company"
                formik={formik}
                value={formik.values.company_name}
                onChange={formik.handleChange}
              />
              <Input
                label="Company Address"
                name="company_billing_address"
                placeHolder="Enter Company Address"
                formik={formik}
                value={formik.values.company_billing_address}
                onChange={formik.handleChange}
              />
              <div
                className="hs-checkout__text"
                style={{ marginTop: 20, marginBottom: -10 }}
              >
                <span
                  className="hs-checkout__heading-3"
                  style={{ fontSize: 16 }}
                >
                  Company contact person:
                </span>{' '}
              </div>

              <Input
                label="Name"
                name="contact_name"
                placeHolder="Enter Name"
                formik={formik}
                value={formik.values.contact_name}
                onChange={formik.handleChange}
              />
              <Input
                label="Tel"
                name="contact_mobile"
                placeHolder="Enter Tel"
                formik={formik}
                value={formik.values.contact_mobile}
                onChange={({ currentTarget }) => {
                  handleNumberInput(currentTarget, 'contact_mobile')
                }}
              />
              <Input
                label="Email"
                name="contact_email"
                placeHolder="Enter Email"
                formik={formik}
                value={formik.values.contact_email}
                onChange={formik.handleChange}
              />
            </div>
            {isPortalDomain && renderPaymentStripe()}
          </div>
        </>
      )
    }

    if (isViaFrontiers) {
      return (
        <>
          <div className="card__main">
            <PaypalButton
              allowSpecialPrice={allowSpecialPrice}
              specialPrice={specialPrice}
              formik={formik}
              course={course}
              history={history}
              dispatch={dispatch}
              applicationFee={applicationFee}
              isAllowApplicationFee={isAllowApplicationFee}
            />
          </div>
          {renderPaymentStripe()}
        </>
      )
    }

    if (isArtofnumbersDomain) {
      return (
        <>
          <div className="card__main">
            <div className="payment_method_block">
              <Radio value="paynow">
                <span className="hs-checkout__heading-2">
                  Pay using Paynow / Bank Transfer
                </span>
              </Radio>
              <div className="payment_method_toggle">
                <img
                  src={
                    formik.values.payment_method === 'paynow'
                      ? images.admin.angle_up
                      : images.admin.angle_down
                  }
                  alt=""
                />
              </div>
            </div>

            <div
              className={classNames('payment_method', {
                toggle_payment_method:
                  formik.values.payment_method === 'paynow',
              })}
            >
              <div className="hs-checkout__info">
                <div className="hs-checkout__heading-3">Bank Transfer:</div>
                <div className="hs-checkout__text">
                  Please include your full name in comments when making the
                  transfer.
                </div>
                <div className="hs-checkout__text">
                  Bank account name:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    Art of Numbers Academy LLP
                  </span>
                </div>
                <div className="hs-checkout__text">
                  Bank:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    Maybank Singapore Limited
                  </span>
                </div>
                <div className="hs-checkout__text">
                  Account no:{' '}
                  <span style={{ fontWeight: 'bold' }}>04191077089</span>
                </div>
              </div>
              <div className="hs-checkout__info">
                <div className="hs-checkout__heading-3">Paynow Corporate:</div>
                <div className="hs-checkout__text">
                  Company UEN:{' '}
                  <span style={{ fontWeight: 'bold' }}>T19LL1091K</span>
                </div>
                <div className="hs-checkout__qr-code">
                  <img src={images.admin.artofnumbers_qr_code} alt="" />
                </div>
              </div>

              <InputFile
                formik={formik}
                fieldName="attachment"
                fieldNameSecond="attachment_name"
              />

              <div
                style={{
                  fontSize: 12,
                  width: 300,
                  marginBottom: 20,
                  color: 'red',
                }}
              >
                * Please upload screenshot of your bank transfer and enter your
                payment reference no. below.
              </div>
              <Input
                name="paynow"
                placeHolder="Enter Payment Reference No."
                formik={formik}
                value={formik.values.paynow}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="card__main">
            <PaypalButton
              allowSpecialPrice={allowSpecialPrice}
              specialPrice={specialPrice}
              formik={formik}
              course={course}
              history={history}
              dispatch={dispatch}
              applicationFee={applicationFee}
              isAllowApplicationFee={isAllowApplicationFee}
            />
          </div>
        </>
      )
    }
    if (isUnicornDomain) {
      return (
        <>
          {!isAllowApplicationFee && remainingAmount === 0 ? (
            <div className="card__main">
              <div className="payment_method_block">
                <Radio value="credit">
                  <span className="hs-checkout__heading-2">
                    Pay using SkillsFuture Credit
                  </span>
                </Radio>
                <div className="payment_method_toggle">
                  <img
                    src={
                      formik.values.payment_method === 'credit'
                        ? images.admin.angle_up
                        : images.admin.angle_down
                    }
                    alt=""
                  />
                </div>
              </div>
              <div
                className={classNames('payment_method', {
                  toggle_payment_method:
                    formik.values.payment_method === 'credit',
                })}
              >
                {renderCourseFee()}
                <div className="hs-checkout__text">
                  You will be redirected to the Skillsfuture portal to make your
                  claims and brought back to this page to continue enrollment.
                </div>
                <Input
                  name="credit"
                  placeHolder="S1234567C"
                  label="Enter NRIC then click claim SFC below"
                  formik={formik}
                  value={formik.values.credit}
                  onChange={formik.handleChange}
                  className={classNames('payment_method', {
                    toggle_payment_method:
                      formik.values.payment_method === 'credit',
                  })}
                />
                <MultipleSFCPrice
                  allowSelectSFC={formik.values.credit}
                  submitRef={submitRef}
                  sfcPrices={sfcPrices}
                  selectedSchedule={selectedSchedule}
                  userPaymentByCourse={userPaymentByCourse}
                  complete={complete}
                  setComplete={setComplete}
                  selectedPriceSFC={selectedPriceSFC}
                />
              </div>
            </div>
          ) : null}

          <div className="card__main">
            <div className="payment_method_block">
              <Radio value="paynow">
                <span className="hs-checkout__heading-2">
                  Pay using Paynow / Bank Transfer
                </span>
              </Radio>
              <div className="payment_method_toggle">
                <img
                  src={
                    formik.values.payment_method === 'paynow'
                      ? images.admin.angle_up
                      : images.admin.angle_down
                  }
                  alt=""
                />
              </div>
            </div>

            <div
              className={classNames('payment_method', {
                toggle_payment_method:
                  formik.values.payment_method === 'paynow',
              })}
            >
              <div className="hs-checkout__info">
                <div className="hs-checkout__text">
                  Course Fee Payable -{' '}
                  <span className="bold">{formatMoney(lowestPrice)}</span> after
                  70%
                </div>
                {isAllowApplicationFee && (
                  <div className="hs-checkout__text">
                    Please pay the application fee as shown in the checkout to
                    proceed.
                  </div>
                )}
              </div>
              <div className="hs-checkout__info">
                <div className="hs-checkout__heading-3">Bank Transfer:</div>
                <div className="hs-checkout__text">
                  Please include your full name in comments when making the
                  transfer.
                </div>
                <div className="hs-checkout__text">
                  Unicorn Financial Solutions Pte Limited
                </div>
                <div className="hs-checkout__text">DBS BANK -072-000-9932 </div>
              </div>
              <div className="hs-checkout__info">
                <div className="hs-checkout__heading-3">Paynow Corporate:</div>
                <div className="hs-checkout__text">UEN: 200501540R</div>
                <div className="hs-checkout__qr-code">
                  <img src={images.admin.qr_code} alt="" />
                </div>
              </div>

              <InputFile
                formik={formik}
                fieldName="attachment"
                fieldNameSecond="attachment_name"
              />

              <div
                style={{
                  fontSize: 12,
                  width: 300,
                  marginBottom: 20,
                  color: 'red',
                }}
              >
                * Please upload screenshot of your bank transfer and enter your
                payment reference no. below.
              </div>
              <Input
                name="paynow"
                placeHolder="Enter Payment Reference No."
                formik={formik}
                value={formik.values.paynow}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </>
      )
    }

    return renderPaymentStripe()
  }

  const handleClickPaymentMethod = (e) => {
    const value = e.target.value
    formik.setFieldValue('payment_method', value)
  }

  return (
    <div>
      <Radio.Group
        onChange={handleClickPaymentMethod}
        value={formik.values.payment_method}
        name="add-course__radio-type"
        style={{ width: '100%' }}
      >
        <div className="card">{renderPaymentMethod()}</div>
      </Radio.Group>
    </div>
  )
}

export default CardInformation
