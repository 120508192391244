import React from 'react'
import { Row, Col, DatePicker } from 'antd'
import { initialNational } from 'utils/constants'
import Dropdown from 'react-dropdown'

import Input from 'component/Input'

const ProfileCyberQuote = ({ formik, handleNumberInput }) => {
  return (
    <>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">
            Full Name (Ensure name is exactly according to NRIC to be eligible
            for grant funding):
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="name"
              placeHolder="Enter your full name"
              formik={formik}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">NRIC No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="passport"
              placeHolder="Enter ID/Passport"
              formik={formik}
              value={formik.values.passport}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Email:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="email"
              placeHolder="Enter your email"
              formik={formik}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Contact no.:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="phone"
              placeHolder="Enter Phone No."
              formik={formik}
              value={formik.values.phone}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'phone')
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Citizenship:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Dropdown
              options={initialNational}
              onChange={(value) =>
                formik.setFieldValue('nationality', value.label)
              }
              value={formik.values.nationality}
              placeholder="Select Nationality"
              className="dropdownScheduleModal dropdown"
            />

            {formik.values.nationality === 'Other' && (
              <div style={{ marginTop: 20 }}>
                <Input
                  name="other_nationality"
                  placeHolder="Type another nationality here"
                  formik={formik}
                  value={formik.values.other_nationality}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            {formik.touched.nationality && formik.errors.nationality && (
              <div className="form__error">{formik.errors.nationality}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Date of Birth:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__date-picker">
            <DatePicker
              onChange={(date, dateString) => {
                formik.setFieldValue('date_of_birth', date)
              }}
              allowClear
              value={formik.values.date_of_birth || ''}
              placeholder="Date of Birth"
              className={`sc-modal__datepicker ${
                formik.touched.date_of_birth &&
                formik.errors.date_of_birth &&
                'datepicker__error'
              }`}
            />
            {formik.touched.date_of_birth && formik.errors.date_of_birth && (
              <div className="form__error">{formik.errors.date_of_birth}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Street:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="address"
              placeHolder="Enter Street"
              formik={formik}
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Unit No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <Input
            name="unit_number"
            placeHolder="01-01"
            formik={formik}
            value={formik.values.unit_number}
            onChange={formik.handleChange}
          />
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Postal Code:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="postTalCode"
              placeHolder="Enter Postal Code"
              formik={formik}
              value={formik.values.postTalCode}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Occupation:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="occupation"
              placeHolder="Enter Occupation"
              formik={formik}
              value={formik.values.occupation}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProfileCyberQuote
