import React from 'react'
import { get } from 'lodash'
import { hideGlobalLoading, showGlobalLoading } from '../../../../Modal/actions'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import config from 'src/global-config'
import { downloadImage } from 'src/utils/helper'
import { showError } from '../../../../../utils/notification'

const Badge = ({ record, course, index, profiles }) => {
  const dispatch = useDispatch()

  const userID = record?.user?.id
  const profileName =
    get(profiles, `[${index}].name`, '') || get(record, 'user.partner.name', '')

  const handleDownloadBadge = async () => {
    dispatch(showGlobalLoading())

    const requestUrl = `${config.api}/send-mail/badge-pdf`

    await axios
      .post(requestUrl, { email_id: userID, course_id: course?.id })
      .then(async (response) => {
        if (!response?.error) {
          downloadImage(response.data.url, `Badge_${profileName}.png`)
          return response.data
        } else {
          showError('Get Badge Failed!')
        }
      })

    dispatch(hideGlobalLoading())
  }

  return (
    <>
      <div className="action vertical-line" onClick={handleDownloadBadge}>
        Download
      </div>
    </>
  )
}
export default Badge
