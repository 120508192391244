import React from 'react'
import { get } from 'lodash'

const NotCompetent = ({ listUserNotComplete, handleSelectUser }) => {
  return (
    <div className="quiz__list-user">
      {listUserNotComplete &&
        Object.keys(listUserNotComplete).map((item) => {
          const competent = get(
            listUserNotComplete[item],
            '[0].user.answer.competent',
            null
          )
          const name = get(
            listUserNotComplete[item],
            `[0].user.partner.name`,
            ''
          )
          return (
            <div
              key={item.id}
              className="quiz__item-user"
              onClick={() => handleSelectUser(item)}
            >
              <div className="quiz__item-name">{name}</div>
              {competent !== null &&
                (competent === false ? (
                  <div className="quiz__item-status quiz__item-status-nc">
                    Fail
                  </div>
                ) : (
                  <div className="quiz__item-status quiz__item-status-c">
                    Pass
                  </div>
                ))}
            </div>
          )
        })}
    </div>
  )
}

export default NotCompetent
