import * as constant from './constants'

// SET SELECTED SLIDE
export function setSelectedSlide(payload) {
  return {
    type: constant.SET_SELECTED_SLIDE,
    payload,
  }
}
// SET SELECTED QUIZ
export function setSelectedQuiz(payload) {
  return {
    type: constant.SET_SELECTED_QUIZ,
    payload,
  }
}

// LOAD LIST COURSE
export function loadListCourse(params) {
  return {
    type: constant.LOAD_LIST_COURSE,
    params,
  }
}

export function loadListCourseSuccess(payload) {
  return {
    type: constant.LOAD_LIST_COURSE_SUCCESS,
    payload,
  }
}

export function loadListCourseFailed(error) {
  return {
    type: constant.LOAD_LIST_COURSE_FAILED,
    error,
  }
}

export function loadListCategory(params) {
  return {
    type: constant.LOAD_LIST_CATEGORY,
    params,
  }
}

export function loadListCategorySuccess(payload) {
  return {
    type: constant.LOAD_LIST_CATEGORY_SUCCESS,
    payload,
  }
}

export function loadListCategoryFailed(error) {
  return {
    type: constant.LOAD_LIST_CATEGORY_FAILED,
    error,
  }
}

// LOAD COURSE
export function loadCourse(params, ignoreCompany, needToLoading = true) {
  return {
    type: constant.LOAD_COURSE,
    params,
    ignoreCompany,
    needToLoading,
  }
}

export function loadCourseSuccess(payload) {
  return {
    type: constant.LOAD_COURSE_SUCCESS,
    payload,
  }
}

export function loadCourseFailed(error) {
  return {
    type: constant.LOAD_COURSE_FAILED,
    error,
  }
}

// LOAD SLIDES DETAILS
export function loadSlideDetails(params) {
  return {
    type: constant.LOAD_SLIDE_DETAILS,
    params,
  }
}

export function loadSlideDetailsSuccess(payload) {
  return {
    type: constant.LOAD_SLIDE_DETAILS_SUCCESS,
    payload,
  }
}

export function loadSlideDetailsFailed(error) {
  return {
    type: constant.LOAD_SLIDE_DETAILS_FAILED,
    error,
  }
}

// LOAD COURSE
export function loadSlides(params, isAdmin, idSlide, scheduleId, preview) {
  return {
    type: constant.LOAD_SLIDES,
    params,
    isAdmin,
    idSlide,
    scheduleId,
    preview,
  }
}

export function loadSlidesSuccess(payload, length) {
  return {
    type: constant.LOAD_SLIDES_SUCCESS,
    payload,
    length,
  }
}

export function loadOriginalSlidesSuccess(payload) {
  return {
    type: constant.LOAD_ORIGINAL_SLIDES_SUCCESS,
    payload,
  }
}

export function loadSlidesFailed(error) {
  return {
    type: constant.LOAD_SLIDES_FAILED,
    error,
  }
}

// LOAD PREVIEW TICK
export function loadPreviewByChannelId(params) {
  return {
    type: constant.LOAD_PREVIEW_BY_CHANNEL_ID,
    params,
  }
}

export function loadPreviewByChannelIdSuccess(payload) {
  return {
    type: constant.LOAD_PREVIEW_BY_CHANNEL_ID_SUCCESS,
    payload,
  }
}

export function loadPreviewByChannelIdFailed(error) {
  return {
    type: constant.LOAD_PREVIEW_BY_CHANNEL_ID_FAILED,
    error,
  }
}

// LOAD PREVIEW TICK
export function setPreviewComplete(params) {
  return {
    type: constant.SET_PREVIEW_COMPLETE,
    params,
  }
}

// POST USER ANSWER
export function postUserAnswer(
  params,
  needToNext = true,
  preventLoading = false
) {
  return {
    type: constant.POST_USER_ANSWER,
    params,
    needToNext,
    preventLoading,
  }
}

export function postUserAnswerSuccess(payload) {
  return {
    type: constant.POST_USER_ANSWER_SUCCESS,
    payload,
  }
}

export function postUserAnswerFailed(error) {
  return {
    type: constant.POST_USER_ANSWER_FAILED,
    error,
  }
}

// LOAD USER ANSWER
export function loadUserAnswer(params) {
  return {
    type: constant.LOAD_USER_ANSWER,
    params,
  }
}

export function loadUserAnswerSuccess(payload) {
  return {
    type: constant.LOAD_USER_ANSWER_SUCCESS,
    payload,
  }
}

export function loadUserAnswerFailed(error) {
  return {
    type: constant.LOAD_USER_ANSWER_FAILED,
    error,
  }
}

// LOAD USER ANSWER
export function loadDomain(params) {
  return {
    type: constant.LOAD_DOMAIN,
    params,
  }
}

export function loadDomainSuccess(payload) {
  return {
    type: constant.LOAD_DOMAIN_SUCCESS,
    payload,
  }
}

export function loadListDomainSuccess(payload) {
  return {
    type: constant.LOAD_LIST_DOMAIN_SUCCESS,
    payload,
  }
}

export function loadDomainFailed(error) {
  return {
    type: constant.LOAD_DOMAIN_FAILED,
    error,
  }
}

export function loadUserLocation(params) {
  return {
    type: constant.LOAD_USER_LOCATION,
    params,
  }
}

export function loadUserLocationSuccess(payload) {
  return {
    type: constant.LOAD_USER_LOCATION_SUCCESS,
    payload,
  }
}

export function loadUserLocationFailed(error) {
  return {
    type: constant.LOAD_USER_LOCATION_FAILED,
    error,
  }
}

// ENROLL USER
export function postEnroll(params) {
  return {
    type: constant.POST_ENROLL,
    params,
  }
}

export function postEnrollSuccess(payload) {
  return {
    type: constant.POST_ENROLL_SUCCESS,
    payload,
  }
}

export function postEnrollFailed(error) {
  return {
    type: constant.POST_ENROLL_FAILED,
    error,
  }
}
// UPDATE ENROLL USER
export function updateEnroll(params) {
  return {
    type: constant.UPDATE_ENROLL,
    params,
  }
}

export function updateEnrollSuccess(payload) {
  return {
    type: constant.UPDATE_ENROLL_SUCCESS,
    payload,
  }
}

export function updateEnrollFailed(error) {
  return {
    type: constant.UPDATE_ENROLL_FAILED,
    error,
  }
}

// DELETE ENROLL USER
export function deleteEnroll(params) {
  return {
    type: constant.DELETE_ENROLL,
    params,
  }
}

export function deleteEnrollSuccess(payload) {
  return {
    type: constant.DELETE_ENROLL_SUCCESS,
    payload,
  }
}

export function deleteEnrollFailed(error) {
  return {
    type: constant.DELETE_ENROLL_FAILED,
    error,
  }
}

// LOAD USER ANSWER
export function getAnswerTime(params) {
  return {
    type: constant.GET_ANSWER_TIME,
    params,
  }
}
export function getAnswerTimeSuccess(payload) {
  return {
    type: constant.GET_ANSWER_TIME_SUCCESS,
    payload,
  }
}
export function getAnswerTimeFailed(payload) {
  return {
    type: constant.GET_ANSWER_TIME_FAILED,
    payload,
  }
}

export function putAnswerTime(params) {
  return {
    type: constant.PUT_ANSWER_TIME,
    params,
  }
}

// LOAD USER PAYMENT METHOD
export function getUserPaymentMethod(params) {
  return {
    type: constant.GET_USER_PAYMENT_METHOD,
    params,
  }
}
export function getUserPaymentMethodSuccess(payload) {
  return {
    type: constant.GET_USER_PAYMENT_METHOD_SUCCESS,
    payload,
  }
}
export function getUserPaymentMethodFailed(payload) {
  return {
    type: constant.GET_USER_PAYMENT_METHOD_FAILED,
    payload,
  }
}

// LOAD USER INFO
export function getUserInfo(params) {
  return {
    type: constant.GET_USER_INFO,
    params,
  }
}
export function getUserInfoSuccess(payload) {
  return {
    type: constant.GET_USER_INFO_SUCCESS,
    payload,
  }
}
export function getUserInfoFailed(payload) {
  return {
    type: constant.GET_USER_INFO_FAILED,
    payload,
  }
}

export function setRef(payload) {
  return {
    type: constant.SET_REF,
    payload,
  }
}

export function putUserLog(params, needToLoadLog = false) {
  return {
    type: constant.PUT_USER_LOG,
    params,
    needToLoadLog,
  }
}
export function putUserLogSuccess(payload) {
  return {
    type: constant.PUT_USER_LOG_SUCCESS,
    payload,
  }
}
export function putUserLogFailed(payload) {
  return {
    type: constant.PUT_USER_LOG_FAILED,
    payload,
  }
}

export function getUserLog(params) {
  return {
    type: constant.GET_USER_LOG,
    params,
  }
}
export function getUserLogSuccess(payload) {
  return {
    type: constant.GET_USER_LOG_SUCCESS,
    payload,
  }
}
export function getUserLogFailed(payload) {
  return {
    type: constant.GET_USER_LOG_FAILED,
    payload,
  }
}

export function preventLoadRelatedApi(payload) {
  return {
    type: constant.PREVENT_LOAD_RELATED_API,
    payload,
  }
}
