import { get } from 'lodash'
import { getDomain } from 'utils/validate'

const env = process.env.REACT_APP_ENV
let config = {}

if (env === 'develop') {
  config = {
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID || 'UA-128643591-1',
    imageURL: 'https://lms.ascendo.edu.sg/web/image',
    baseUrl: 'https://lms.ascendo.edu.sg',
    conferenceUrl: 'https://conference.hypeschools.com',
    uploadApi:
      process.env.REACT_APP_UPLOAD_API || 'https://api-staging.hypeschools.com',
    api: process.env.REACT_APP_API || 'https://api-staging.hypeschools.com',
    clientIdPaypal:
      process.env.REACT_APP_CLIENT_ID_PAYPAL ||
      'AfWDDSBoBJ1GsrE0NaX7-2yMFri4GsgwHwUxwlgIH4GkcYoDes87N3jgEWnjia3ygLxT4aEv2NRR_LJy',
    secret:
      process.env.REACT_APP_SECRET ||
      'pk_test_51HLASYJ14y87Gageg8tnHgwT9xbB8VtnwzBD0e3dVmvzmOH7hcTa9HRogGJazhREFxXyAJLU4QR1IIpcWOcbw2NR00nHeoQacb',
    secretAllQuant: process.env.REACT_APP_SECRET_ALLQUANT,
    therapadaKey: process.env.REACT_APP_THERAPADA_KEY,
    ascendoKey: process.env.REACT_APP_ASCENDO_KEY,
    gatewaySFC: process.env.REACT_APP_GATEWAY_SFC,
  }
} else {
  config = {
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID || 'UA-128643591-1',
    imageURL: `${get(getDomain(), 'domain', '')}/web/image`,
    baseUrl: `${get(getDomain(), 'domain', '')}`,
    conferenceUrl: 'https://conference.hypeschools.com',
    uploadApi:
      process.env.REACT_APP_UPLOAD_API || 'https://api-staging.hypeschools.com',
    api: process.env.REACT_APP_API || 'https://api-staging.hypeschools.com',
    clientIdPaypal:
      process.env.REACT_APP_CLIENT_ID_PAYPAL ||
      'AfWDDSBoBJ1GsrE0NaX7-2yMFri4GsgwHwUxwlgIH4GkcYoDes87N3jgEWnjia3ygLxT4aEv2NRR_LJy',
    secret:
      process.env.REACT_APP_SECRET ||
      'pk_test_51HLASYJ14y87Gageg8tnHgwT9xbB8VtnwzBD0e3dVmvzmOH7hcTa9HRogGJazhREFxXyAJLU4QR1IIpcWOcbw2NR00nHeoQacb',
    secretAllQuant: process.env.REACT_APP_SECRET_ALLQUANT,
    therapadaKey: process.env.REACT_APP_THERAPADA_KEY,
    ascendoKey: process.env.REACT_APP_ASCENDO_KEY,
    gatewaySFC: process.env.REACT_APP_GATEWAY_SFC,
  }
}

export default config
