import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { parse } from 'query-string'
import dayjs from 'dayjs'
import * as selectors from './container/Admin/actions'
import Modal from './container/Modal'

import Footer from './components/Footer'
import Navbar from './components/Navbar'

import {
  loadDomain,
  loadListCategory,
  loadUserLocation,
  setRef,
} from './container/Home/actions'
import { setNavigator } from 'utils/navigation'

import './App.css'
import 'react-dropdown/style.css'
import 'react-toastify/dist/ReactToastify.css'
import HypeSchoolRoutes, { DOMAIN } from './routes/'
import {
  makeSelectDomain,
  makeSelectUserLocation,
} from './container/Home/selectors'
import config from './global-config'
import { makeSelectListCategory } from 'container/Home/selectors'
import './styles.scss'
import NewNavBar from './customs/ClassCompare/src/components/NewNavbar/index'

const utc = require('dayjs/plugin/utc')
const weekday = require('dayjs/plugin/weekday')
const duration = require('dayjs/plugin/duration')
const localeData = require('dayjs/plugin/localeData')
const advancedFormat = require('dayjs/plugin/advancedFormat')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(duration)
dayjs.extend(localeData)
dayjs.extend(isSameOrAfter)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

const App = () => {
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const domain = useSelector(makeSelectDomain())
  const categories = useSelector(makeSelectListCategory())
  const userLocation = useSelector(makeSelectUserLocation())
  const { ref } = parse(search)

  useEffect(() => {
    const isLoginAsUser = localStorage.getItem('loginAsUser')
    const tabsOpen = localStorage.getItem('tabsOpen')

    if (tabsOpen === null) {
      localStorage.setItem('tabsOpen', 1)
    } else {
      localStorage.setItem('tabsOpen', parseInt(tabsOpen) + parseInt(1))
    }

    window.onunload = () => {
      const newTabCount = localStorage.getItem('tabsOpen')

      if (newTabCount > 0) {
        localStorage.setItem('tabsOpen', newTabCount - 1)

        if (newTabCount - 1 === 0) {
          if (isLoginAsUser) {
            setTimeout(() => {
              sessionStorage.clear()
              localStorage.clear()
            }, 0)
          }
        }
      }
    }
  }, [])

  setNavigator(dispatch)

  useLayoutEffect(() => {
    if (!userLocation) {
      dispatch(loadUserLocation())
    }
  }, [userLocation])

  useEffect(() => {
    if (window.location.href === 'https://ibf.hypeschools.com/') {
      window.location.replace('https://www.allquant.co/ibf-accredited-course')
    }
  }, [])

  useLayoutEffect(() => {
    dispatch(loadDomain())
  }, [])

  useEffect(() => {
    dispatch(selectors.LoadCourseType())
    dispatch(selectors.loadSlideTags())
    dispatch(selectors.loadTags())
    dispatch(loadListCategory())
    ReactGA.initialize(config.gaTrackingId)
  }, [])

  useEffect(() => {
    if (ref) {
      dispatch(setRef(ref))
    }
  }, [ref])

  useEffect(() => {
    ReactGA.pageview(`${pathname}${search}`)
  }, [pathname, search])

  const renderNavBar = () => {
    if (!domain) {
      return null
    }
    const urls = ['/course/preview', '/category', '/about', '/pagenotfound']

    if (domain?.domain === DOMAIN.COURSES_CLASS_COMPARE) {
      if (pathname.match('/admin')) {
        return <Navbar domain={domain} />
      }
      return null
    }

    if (urls.some((url) => pathname.match(url)) || pathname === '/') {
      return <NewNavBar domain={domain} />
    }

    return <Navbar domain={domain} />
  }

  return (
    <div className="App">
      <Modal />
      <ToastContainer hideProgressBar={true} />
      {renderNavBar()}
      {domain && domain.domain && (
        <HypeSchoolRoutes
          domain={domain}
          userLocation={userLocation}
          categories={categories}
        />
      )}
      {domain && domain.domain && <Footer domain={domain} />}
    </div>
  )
}

export default App
