import { Button, Form, Input, message, Select, Space } from 'antd'
import { get } from 'lodash'
import React, { useMemo, useState } from 'react'
import CoursePrices from '../CoursePrices'
import { parseSafe } from 'utils/helper'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes'
import {
  getInvoiceFLCDomain,
  getInvoiceUnicornDomain,
  updatePaymentMethod,
} from 'utils/request'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import config from 'src/global-config'
import { minTwoDigits } from 'container/Checkout/screens/Billing'
import UploadFile from '../UploadFile'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { getUserPaymentMethod } from 'container/Home/actions'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
}

const PAYNOW = 'paynow'
const SFC_METHOD = 'credit'

export const PAYMENT_METHOD_STATUS_SELECT = {
  [SFC_METHOD]: 'SFC method',
  [PAYNOW]: 'Paynow method',
}

const PaymentMethodForm = ({
  isEdit,
  onCancel,
  course,
  userProfile,
  paymentMethod,
}) => {
  const [form] = Form.useForm()
  const [selectedMethod, setSelectedMethod] = useState('')
  const { search } = useLocation()
  const { user: userID } = parse(search)
  const domain = useSelector(makeSelectDomain())
  const isUnicornDomain = get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN
  const isFlcDomain = get(domain, 'domain', '') === DOMAIN.FLC
  const isInputPrice = course?.sfc_prices?.length === 0
  const price = course?.special_price ? course?.special_price : course?.price
  const dispatch = useDispatch()
  const [paymentMethodEdit, setPaymentMethodEdit] = useState('')

  const dataPaymentSelectedEdit = useMemo(
    () =>
      paymentMethodEdit &&
      paymentMethod?.length &&
      paymentMethod?.find((item) => item?.id === paymentMethodEdit),
    [paymentMethodEdit, paymentMethod]
  )

  const onFinish = async (values) => {
    dispatch(showGlobalLoading())
    try {
      await handleUpdateInvoice(values)
    } catch (err) {
      dispatch(hideGlobalLoading())
      message.error('Updated Payment Method failed!')
    }
  }

  const getInvoiceNumber = async (values) => {
    const requestURL = `${config.baseUrl}/api/invoices/numbers`
    try {
      const res = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          domain: values.domain,
          channel_id: values.courseID,
        }),
      })
      const data = await res.json()
      if (data?.data?.value) {
        return data?.data?.value
      }
    } catch (err) {
      return null
    }
  }

  const handleUpdateInvoice = async (values) => {
    const payment_method = values?.method

    const additionalData = isEdit
      ? parseSafe(paymentMethodEdit?.additional_info)
      : parseSafe(paymentMethod?.additional_info)
    const invoiceSequence = get(additionalData, 'sequence_num', '')
      ? get(additionalData, 'sequence_num', '')
      : await getInvoiceNumber({
          domain: 'flc',
          courseID: course?.id,
        })

    const schedule = get(course, 'partners[0].schedule', null)
    const additionalDataInfo = parseSafe(
      get(userProfile, 'additional_info', '')
    )
    const tgsCode = get(course, 'sfc_prices[0].course_code')
    try {
      const invoiceNo = values?.invoiceNo
        ? values.invoiceNo
        : isFlcDomain
        ? `${minTwoDigits(invoiceSequence || '')}-${dayjs().format(
            'MMDD'
          )}-${get(schedule, 'class_code', '')}`
        : get(additionalData, 'invoice_no', '')

      const studentName = get(userProfile, 'name', '')
      const companyName =
        get(additionalDataInfo, 'company', '') ||
        get(additionalData, 'company_name', '')
      const companyBilling = get(additionalData, 'company_billing_address')
      const billingAddress = get(additionalDataInfo, 'user_billing_address')
      const dateEnrolled = dayjs(
        get(course, 'partners[0].create_date', '')
      ).format('DD/MM/YYYY')

      const courseFee = isInputPrice
        ? get(values, 'price', '')
        : +get(values, 'price.price', 0)

      let invoice = null
      if (isFlcDomain) {
        const invoiceNo = `${minTwoDigits(
          invoiceSequence || ''
        )}-${dayjs().format('MMDD')}-${get(schedule, 'class_code', '')}`

        invoice = await getInvoiceFLCDomain(
          invoiceNo,
          studentName,
          schedule,
          courseFee,
          companyName,
          course,
          dateEnrolled,
          billingAddress,
          companyBilling
        )
      } else if (isUnicornDomain) {
        const date = dayjs(get(course, 'partners[0].create_date', '')).format(
          'YYYYMMDD'
        )
        const invoiceNo = `${date}-${tgsCode}-${userID}`

        invoice = await getInvoiceUnicornDomain(
          invoiceNo,
          studentName,
          schedule,
          course,
          courseFee
        )
      }

      if (invoice) {
        const body = {
          ...(isEdit ? dataPaymentSelectedEdit : paymentMethod),
          channel_id: course?.id,
          payment_method,
          schedule_id: get(course, 'partners[0].schedule.id', ''),
          user_id: +userID,
          value: '',
          domain: domain.domain,
          course_code: tgsCode,
          additional_info: JSON.stringify({
            invoice_no: invoiceNo,
            sequence_num: invoiceSequence,
            class_code: get(schedule, 'class_code', ''),
            course_fee: courseFee,
          }),
        }
        if (payment_method === 'credit') {
          body.attachment = invoice.pdf
        } else if (payment_method === 'paynow') {
          body.invoice_url = invoice.pdf
          body.attachment =
            typeof values.attachment === 'string'
              ? values.attachment.split('base64,')[1]
              : ''
          body.attachment_name = values.attachment_name
          body.value = values.paymentReference
        } else {
          body.invoice_url = invoice.pdf
        }
        const updatedPayment = await updatePaymentMethod(body)
        if (!!updatedPayment) {
          message.success('Updated invoice!')
        }
        dispatch(getUserPaymentMethod({ userId: userID }))
        dispatch(hideGlobalLoading())
        onCancel()
      }
    } catch (error) {
      dispatch(hideGlobalLoading())
    }
  }

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="PaymentMethodForm"
        layout="horizontal"
        onFinish={onFinish}
        autoComplete="off"
      >
        {isEdit && paymentMethod?.length > 0 && (
          <Form.Item wrapperCol={{ span: 24 }}>
            <Select
              placeholder="Select payment method to edit"
              onChange={(e) => setPaymentMethodEdit(e)}
            >
              {paymentMethod?.map((item) => (
                <Select.Option key={item?.id} value={item?.id}>
                  {PAYMENT_METHOD_STATUS_SELECT[item?.payment_method]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {((isEdit && paymentMethodEdit) || !isEdit) && (
          <>
            <Form.Item
              name="method"
              label="Payment Method"
              rules={[
                { required: true, message: 'Please select Payment method!' },
              ]}
              getValueFromEvent={(value) => {
                setSelectedMethod(value)
                return value
              }}
            >
              <Select placeholder="Select payment method">
                {Object.entries(PAYMENT_METHOD_STATUS_SELECT).map(
                  ([value, text]) => (
                    <Select.Option key={value} value={value}>
                      {text}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Prices"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Please select price!',
                },
              ]}
              initialValue={isInputPrice ? price : null}
            >
              {isInputPrice ? (
                <Input placeholder="Enter price" />
              ) : (
                <CoursePrices coursePrices={course?.sfc_prices} form={form} />
              )}
            </Form.Item>
            <Form.Item label="Invoice No (Optional)" name="invoiceNo">
              <Input placeholder="Enter Invoice No (Optional)" />
            </Form.Item>
            {selectedMethod === PAYNOW ? (
              <>
                <Form.Item
                  label="Upload File"
                  name="attachment"
                  rules={[
                    {
                      required: true,
                      message: 'Please upload screenshot!',
                    },
                  ]}
                >
                  <UploadFile
                    form={form}
                    fieldName="attachment"
                    fieldNameSecond="attachment_name"
                  />
                </Form.Item>
                <Form.Item
                  label="Payment Reference"
                  name="paymentReference"
                  rules={[
                    {
                      required: true,
                      message: 'Please input payment reference!',
                    },
                  ]}
                >
                  <Input placeholder="Enter Payment Reference No." />
                </Form.Item>
              </>
            ) : null}
          </>
        )}

        <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isEdit && !dataPaymentSelectedEdit}
            >
              {isEdit ? 'Update' : 'Create'}
            </Button>

            <Button onClick={onCancel}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default React.memo(PaymentMethodForm)
