import axios from 'axios'
import { getAccessToken } from 'utils/request'
import config from 'src/global-config'
import { get } from 'lodash'

export const putCategorySettings = async (payload, id) => {
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/categories`

  try {
    const res = await axios.put(requestURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    })

    const id = get(res, 'data.data.id')
    if (id) {
      return id
    }
    return false
  } catch (error) {
    console.log('error', error)
    return false
  }
}

export const deleteCategorySettings = async (payload) => {
  const id = get(payload, 'id')
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/categories/${id}`
  try {
    await axios.delete(requestURL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    })

    return true
  } catch (error) {
    console.log('error', error)
    return false
  }
}
