import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { get, isEmpty } from 'lodash'
import RouteClients from '../RouteClients'

const ProtectedRouteClient = ({ children, ...rest }) => {
  const currentUser = useSelector(makeSelectCurrentUser())
  const userClients = get(currentUser, 'user_clients', null)

  let valid = true
  let redirect = <Redirect to="/" />
  if (!isEmpty(userClients)) {
    if (userClients?.length > 1) {
      redirect = <RouteClients userClients={userClients} />
    } else {
      const link = userClients?.[0].client?.slug
      redirect = <Redirect to={link} />
    }

    valid = false
  }

  return <Route {...rest} render={() => (valid ? children : redirect)} />
}

export default ProtectedRouteClient
