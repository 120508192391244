import React, { useEffect, useState } from 'react'
import images from 'Themes/Images'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'antd'
import './styles.scss'
import { setGlobalCourse } from '../../../actions'
import { makeSelectCourse } from 'container/Home/selectors'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import useGetTrainer from '../../../../../hooks/useGetTrainer'

const HeaderAdminCourseDetails = ({ idCourse, route, saveRef, setSaveGo }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const history = useHistory()
  const dispatch = useDispatch()
  const [publish, setPublish] = useState(false)
  const course = useSelector(makeSelectCourse())
  const { isTrainer } = useGetTrainer()
  const isGeneralRoute = !route || route === 'general'
  const isLessonRoute = route === 'lessons'

  useEffect(() => {
    if (course) {
      const isPublished = get(course, 'is_published', false)
      setPublish(isPublished)
    }
  }, [course])

  const handlePublishCourse = () => {
    setPublish(!publish)
    dispatch(setGlobalCourse({ is_published: !publish }))
  }

  const handlePreview = () => {
    if (isGeneralRoute) {
      return history.push(`/course/preview/${idCourse}`)
    }
    if (isLessonRoute) {
      return history.push(`/course/channel/${idCourse}?preview=true`)
    }
  }

  const handleSubmit = (value) => {
    if (saveRef && saveRef.current) {
      setSaveGo(value)
      saveRef.current.submitForm()
    }
  }

  const renderTextPreview = () => {
    if (isLessonRoute) {
      return 'Preview Lesson'
    }
    return 'Preview Course'
  }
  return (
    <div
      className={classNames('header__courses', {
        sticky: isGeneralRoute,
      })}
    >
      <div className="header__intro_2">
        <div className="inner_header_container">
          <div className="header__course">
            <div className="header__course__level">
              {get(course, 'name', 'Course Title')}
            </div>
            <div className="header__course__title__box">
              <div
                className="header__course__title mg_right-32"
                style={{ lineHeight: 1 }}
              >
                {idCourse !== 'new' ? 'Edit Course' : 'Add New Course'}{' '}
              </div>
              <div className="global-publish">
                <Switch
                  checkedChildren="Published"
                  unCheckedChildren="Unpublished"
                  onChange={handlePublishCourse}
                  checked={publish}
                  disabled={isTrainer}
                />
              </div>
              <div>
                <a
                  className="header__course__save"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handlePreview}
                >
                  <img src={images.input.eye_inactive} alt="" />
                  {renderTextPreview()}
                </a>
              </div>

              {/* <div
              className="header__course__save"
              onClick={handeSubmitUnPublish}
            >
              <img src={images.admin.save_icon} alt="" />
              Unpublish
            </div> */}
              {/* <div className="header__course__delete">
              <img src={images.admin.trash_icon} alt="" />
              Discard
            </div> */}
            </div>
          </div>
          {isGeneralRoute && (
            <div
              className="channel__head__right"
              style={{ paddingRight: isMobile ? 0 : 64 }}
            >
              <div className="channel__head__right__button">
                {!isTrainer && (
                  <>
                    <button
                      className="btn btn-primary admin-btn-primary"
                      onClick={() => handleSubmit(false)}
                    >
                      <span className="btn__text">
                        <img src={images.admin.save_icon} alt="" />
                        Save
                      </span>
                    </button>
                    <button
                      className="btn btn-primary admin-btn-primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => handleSubmit(true)}
                    >
                      <span className="btn__text">
                        <img src={images.admin.save_icon} alt="" />
                        Save & go to lesson
                      </span>
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {/* <div className="header__action">
           <div className="header__summary__wrap">
            <div className="header__summary">
              <div className="header__summary__number">12</div>
              <div className="header__summary__title">Lessons</div>
            </div>
            <div className="header__summary">
              <div className="header__summary__number">3</div>
              <div className="header__summary__title">Reviews</div>
            </div>
            <div className="header__summary">
              <div className="header__summary__number">1.2K</div>
              <div className="header__summary__title">Attendees</div>
            </div>
            <div className="header__summary">
              <div className="header__summary__number">993</div>
              <div className="header__summary__title">Completed</div>
            </div>
          </div> 
        </div> */}
        </div>
      </div>
    </div>
  )
}

export default HeaderAdminCourseDetails
