import React from 'react'
import Badge from './Badge'

const BadgeContainer = ({ record, index, profiles, course }) => {
  return (
    <Badge record={record} index={index} profiles={profiles} course={course} />
  )
}

export default BadgeContainer
