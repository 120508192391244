import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { get } from 'lodash'
import parse from 'html-react-parser'

import NavbarClassCompare from '../../components/NavbarClassCompare'
import { BannerDetail, Body, Layout } from '../../../styles'
import { getArticleBySlug } from 'container/CMS/hooks'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import FooterClassCompare from '../../components/FooterClassCompare/index'
import { CKEditorWrapper } from 'container/CMS/screens/Article/index'

const ArticleWrapper = styled.div`
  .title {
    font-weight: 600;
    font-size: 36px;
    color: #666666;
    margin-bottom: 5px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  .short_description {
    font-weight: normal;
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 28px;
    }
    color: #999999;
  }
`

const Article = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [article, setArticle] = useState(null)
  const title = get(article, 'title', '')
  const description = get(article, 'description', '')
  const shortDescription = get(article, 'short_description', '')
  const image = get(article, 'image', '')
  const { slug } = useParams()

  useEffect(() => {
    let isCancelled = false

    const fetchData = async () => {
      const res = await getArticleBySlug(slug)
      if (!isCancelled) {
        setArticle(res)
      }
    }

    fetchData()
    return () => {
      isCancelled = true
    }
  }, [slug])

  return (
    <Layout>
      <Body>
        <ArticleWrapper>
          <NavbarClassCompare cmsMode isFe articlePage />
          <BannerDetail
            height={isMobile ? 326 : 576}
            mgT={60}
            className="mgB-20"
          >
            <img src={image + '=s0'} />
          </BannerDetail>
          <div className="title">{title}</div>
          <div className="short_description">{shortDescription}</div>
          <FooterClassCompare />
          <CKEditorWrapper className="mgT-40">
            {description ? parse(description) : ''}
          </CKEditorWrapper>
        </ArticleWrapper>
      </Body>
    </Layout>
  )
}

export default Article
