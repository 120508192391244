import { get } from 'lodash'
import unicorn from './unicorn_logo.png'
import { replaceTextForExporting } from 'utils/helper'
import { parseSafe } from 'utils/helper'

export const assessmentItemTemplateUnicorn = ({
  doc,
  data,
  listUserProfile,
  listUserStatus,
  allTypes,
  proficieny_name,
  proficieny_id,
  listOutcome,
  listSetOutcomeCustom,
  mergeLearningOutcome,
  listSetOutcomeStudents,
  courseName,
  selectedSchedule,
  course,
}) => {
  let countC = 0
  const user = get(data, 'user', '')
  const scheduleID = get(data, 'schedule_id', '')
  const userId = get(user, 'id', '')
  const passport = get(listUserProfile, `[${userId}].passport`, '').slice(-4)
  const name =
    get(listUserProfile, `[${userId}].name`, '') || user?.partner?.name || ''

  const userStatus = listUserStatus.find((item) => item.user_id === userId)
  const submitMarkDate = get(userStatus, 'write_date', '')

  const additionalData = parseSafe(course?.course_instructor)
  const durations = additionalData?.durations
  // const durationBySchedule =
  //   (selectedSchedule?.value || scheduleID) &&
  //   durations?.filter(
  //     (item) =>
  //       item?.schedule_id &&
  //       item?.schedule_id === (selectedSchedule?.value || scheduleID) &&
  //       item?.user_id === userId
  //   )
  const durationBySchedule =
    (+selectedSchedule?.value >= 0 || +scheduleID >= 0) &&
    durations?.reduce((prev, curr) => {
      if (
        +curr?.schedule_id >= 0 &&
        curr?.schedule_id === (selectedSchedule?.value || scheduleID)
      ) {
        const currentData = curr?.list_users?.find((i) => i?.user_id === userId)
        return [
          ...prev,
          {
            allocated_duration: curr?.allocated_duration,
            assessment_type: curr?.assessment_type,
            schedule_id: curr?.schedule_id,
            ...currentData,
          },
        ]
      }
      return prev
    }, [])
  doc.setFontSize(9)
  doc.text(`${courseName}`, 260, 55)
  doc.addImage(unicorn, 'PNG', 60, 30, 180, 60)

  doc.setFontSize(42)
  doc.text('Assessment Record', 115, 180)
  doc.setFontSize(28)
  doc.text('For:', 270, 240)
  doc.text(`${courseName}`, 300, 270, { maxWidth: 380, align: 'center' })

  doc.setFontSize(22)
  doc.text(`${proficieny_name}`, 300, 350, { maxWidth: 380, align: 'center' })
  doc.text(`${proficieny_id}`, 300, 380, { maxWidth: 380, align: 'center' })

  doc.autoTable({
    margin: { left: 60, right: 60 },
    startY: 450,
    theme: 'grid',
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
      fontSize: 18,
      textColor: [32, 32, 32],
    },
    body: [
      [
        {
          content: `Approved Assessment Centre: 
Unicorn Financial Solutions`,
        },
      ],
      [
        `Candidate Name: 
${name}`,
      ],
    ],
  })
  doc.addPage()

  doc.setFontSize(24)
  doc.text('Version Control Record', 60, 80)
  doc.autoTable({
    margin: { left: 60 },
    startY: 100,
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 110 },
      1: { cellWidth: 110 },
      2: { cellWidth: 110 },
      3: { cellWidth: 110 },
    },
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
    },
    headStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      fillColor: [238, 236, 225],
      textColor: [32, 32, 32],
    },
    head: [
      [
        {
          content: 'Version',
        },
        { content: 'Date' },
        { content: 'Change/History' },
        { content: 'Author' },
      ],
    ],
    body: [['1.00', '20 June 2021', 'First Edition', 'Rudolf Liu']],
  })

  doc.addPage()

  const dataOutcome =
    listSetOutcomeCustom?.find(
      (item) =>
        item.user_id === userId && item?.learning_outcome_channel_custom_id
    ) ||
    listSetOutcomeStudents?.find(
      (item) => item.user_id === userId && item?.learning_outcome_id
    )
  const trainerId = dataOutcome?.create_uid

  let i = 0
  for (let key in allTypes) {
    if (Array.isArray(allTypes[key]) && allTypes[key].length > 0) {
      if (i !== 0) doc.addPage()
      const firstRender = i === 0
      const oldData =
        durationBySchedule?.length &&
        durationBySchedule?.find((item) => item?.assessment_type === key)

      const nextDuration =
        durationBySchedule?.length > 0
          ? oldData
            ? oldData
            : durationBySchedule?.find((item) => !item?.assessment_type)
          : durations?.find(
              (item) => item?.assessment_type === key && !item?.schedule_id
            )
      doc.setFontSize(14)
      doc.text(key === 'null' ? '' : key, 20, firstRender ? 180 : 80)
      doc.setFontSize(11)
      doc.text(
        'This document is to record the evidence gathered for the competency assessment of the following',
        20,
        firstRender ? 220 : 140
      )

      doc.setFontSize(11)
      doc.text(
        `candidate for the skill standard, ${proficieny_name} (${proficieny_id}).`,
        20,
        firstRender ? 240 : 160
      )
      // table

      doc.autoTable({
        margin: { left: 20 },
        startY: firstRender ? 280 : 180,
        startX: 20,
        theme: 'plain',
        styles: {},
        columnStyles: {
          0: { cellWidth: 220 },
          1: { cellWidth: 220 },
        },
        bodyStyles: {
          lineWidth: 0.6,
          lineColor: 12,
          minCellHeight: 20,
        },
        body: [
          [
            `Candidate's Name (as in NRIC):`,
            `${get(user, 'partner.name', '')}`,
          ],
          [
            `Candidate's NRIC
(Please use last four figures of NRIC, e.g "SXXXX123A")`,
            `${
              passport
                ? `S/T XXXX${passport.slice(0, 3)}/${passport.slice(-1)}`
                : ''
            }`,
          ],
          [
            `Assessor's Name:`,
            `${get(listUserProfile, `[${trainerId}].name`, '')}`,
          ],

          [
            'Allocated Duration',
            nextDuration?.allocated_duration
              ? `${nextDuration?.allocated_duration} mins`
              : '',
          ],
        ],
      })

      // table 1

      doc.autoTable({
        margin: { left: 20 },
        startY: 30 + doc.autoTable.previous.finalY || 0,
        showHead: 'firstPage',
        theme: 'grid',
        rowPageBreak: 'avoid',
        columnStyles: {
          0: { cellWidth: 170 },
          1: { cellWidth: 190 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 95 },
        },
        bodyStyles: {
          lineWidth: 0.6,
          lineColor: 12,
        },
        headStyles: {
          lineWidth: 0.6,
          lineColor: 12,
          valign: 'middle',
          halign: 'center',
          fillColor: [217, 217, 217],
          textColor: [32, 32, 32],
        },
        head: [
          [
            {
              content: 'Learning Outcome',
              rowSpan: 2,
            },
            { content: 'Assessment Criteria', rowSpan: 2 },
            { content: 'Please tick', colSpan: 2 },
            { content: 'Remarks', rowSpan: 2 },
          ],
        ],
        didDrawCell: function (data) {
          if (data.cell.raw.key === 'mark' && data.cell.raw.content === '  ') {
            var cell = data.cell
            doc.addImage(
              'https://lh3.googleusercontent.com/QfgjAd7jfGbt5KwEsZ8WLmzVEq0Npb3--LgMMPLz7PZuSptDgcNYZxTSk2CN3_Fss5yZdGcfkbAJReT_ek8k8aB5V3M4kOwqFBhNlplClR34PFjt=s0',
              cell.x + cell.width / 2 - 5,
              cell.y + cell.height / 2 - 5,
              12,
              12
            )
          }

          if (data.cell.raw.key === 'mark' && data.cell.raw.content === ' ') {
            var cell = data.cell
            doc.addImage(
              'https://lh3.googleusercontent.com/_kwqqknsSWAavjQ606XM5csbbP00AAB-NPoPgEqpKSxlWFjMCSNB20V0Y25zw0-ZLUP6ATyFPkjf0GDVTogWpg865seMWbAN2Y_yYnyNtkOC1jqt=s0',
              cell.x + cell.width / 2 - 5,
              cell.y + cell.height / 2 - 5,
              12,
              12
            )
          }
        },
        body: [
          [
            '',
            '',
            {
              content: 'C',
              styles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [217, 217, 217],
                textColor: [32, 32, 32],
                fontStyle: 'bold',
              },
            },
            {
              content: 'NYC',
              styles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [217, 217, 217],
                textColor: [32, 32, 32],
                fontStyle: 'bold',
              },
            },
            '',
          ],
          // eslint-disable-next-line no-loop-func
          ...allTypes[key].map((outcome) => {
            const isCustom = outcome.is_custom
            const assessment_criteria = get(outcome, 'assessment_criteria', {})
            const learningOutcome = listOutcome.find(
              (item) => item.id === assessment_criteria.learning_outcome_id
            )
            const customAnswer = listSetOutcomeCustom.find(
              (item) =>
                item.user_id === userId &&
                item.learning_outcome_channel_custom_id === outcome.id
            )

            const answer = listSetOutcomeStudents.find(
              (item) =>
                item.learning_outcome_id === outcome.id &&
                item.user_id === userId
            )

            countC += isCustom
              ? get(customAnswer, 'competent')
                ? 1
                : 0
              : get(answer, 'competent')
              ? 1
              : 0

            let nullAnswer = isCustom
              ? get(customAnswer, 'competent') === null
              : get(answer, 'competent') === null

            if (isCustom && !customAnswer) {
              nullAnswer = true
            }

            if (!isCustom && !answer) {
              nullAnswer = true
            }
            return [
              {
                content: `${
                  isCustom
                    ? get(outcome, 'learning_outcome')
                    : get(learningOutcome, 'outcome', '')
                } `,
              },
              {
                content: `${
                  isCustom
                    ? get(outcome, 'assessment_criteria')
                    : get(assessment_criteria, 'criteria', '')
                } `,
              },
              {
                content: nullAnswer
                  ? ''
                  : isCustom
                  ? get(customAnswer, 'competent')
                    ? ' '
                    : ''
                  : get(answer, 'competent')
                  ? ' '
                  : '',
                key: 'mark',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                },
              },
              {
                content: nullAnswer
                  ? ''
                  : isCustom
                  ? !get(customAnswer, 'competent')
                    ? '  '
                    : ''
                  : !get(answer, 'competent')
                  ? '  '
                  : '',
                key: 'mark',
                styles: {
                  valign: 'middle',
                  halign: 'center',
                },
              },
              {
                content: `${
                  isCustom
                    ? get(customAnswer, 'remark', '') || 'N/A'
                    : get(answer, 'remark', '') || 'N/A'
                } `,
              },
            ]
          }),
        ],
      })
      i++
    }
  }

  // table 2

  addSummaryPage({
    doc,
    courseName,
    passport,
    user,
    mergeLearningOutcome,
    allTypes,
    listSetOutcomeCustom,
    userId,
    course,
    proficieny_id,
    proficieny_name,
    listSetOutcomeStudents,
  })
}

export const addSummaryPage = ({
  doc,
  courseName,
  passport,
  user,
  allTypes,
  listSetOutcomeCustom,
  userId,
  course,
  proficieny_id,
  proficieny_name,
  listSetOutcomeStudents,
}) => {
  doc.addPage()
  doc.setFontSize(9)
  doc.text(`${courseName}`, 260, 55)
  doc.addImage(unicorn, 'PNG', 60, 30, 180, 60)

  doc.setFontSize(18)
  doc.text(`Assessment Summary Record`, 20, 100)
  doc.setFontSize(12)
  doc.text(`Framework:`, 20, 130)
  doc.text(`Information Technology`, 90, 130)
  doc.setFontSize(12)
  doc.text(`TSC:`, 20, 150)
  doc.text(proficieny_name, 90, 150)
  doc.setFontSize(12)
  doc.text(`TSC Code:`, 20, 170)
  doc.text(proficieny_id, 90, 170)

  doc.autoTable({
    margin: { left: 20 },
    startY: 200,
    startX: 20,
    theme: 'plain',
    styles: {},
    columnStyles: {
      0: { cellWidth: 220 },
      1: { cellWidth: 220 },
    },
    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 20,
    },
    body: [
      [`Candidate's Name (as in NRIC):`, `${get(user, 'partner.name', '')}`],
      [
        `Candidate's NRIC
(Please use last four figures of NRIC, e.g "SXXXX123A")`,
        `${
          passport
            ? `S/T XXXX${passport.slice(0, 3)}/${passport.slice(-1)}`
            : ''
        }`,
      ],
    ],
  })

  doc.autoTable({
    margin: { left: 20 },
    startY: doc.autoTable.previous.finalY + 30 || 0,
    theme: 'grid',
    columnStyles: {
      0: { cellWidth: 80 },
      1: { cellWidth: 250 },
      2: { cellWidth: 80 },
      3: { cellWidth: 80 },
    },

    bodyStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      minCellHeight: 30,
    },
    headStyles: {
      lineWidth: 0.6,
      lineColor: 12,
      valign: 'middle',
      halign: 'center',
      fillColor: [217, 217, 217],
      textColor: [32, 32, 32],
    },
    head: [
      [
        {
          content: 'Domain',
        },
        {
          content: 'Domain Outcomes',
        },
        {
          content: `Overall Result
C/NYC`,
        },
        { content: 'Remarks' },
      ],
    ],
    body: summaryBody({
      allTypes,
      listSetOutcomeCustom,
      userId,
      course,
      listSetOutcomeStudents,
    }),
    createdCell: function (cell) {
      if (cell?.cell?.rowSpan > 1) {
        cell.cell.styles.fillColor = '#D9D9D9'
      }
    },
  })
}

const summaryBody = ({
  allTypes,
  listSetOutcomeCustom,
  listSetOutcomeStudents,
  userId,
}) => {
  const arr = []
  let countC = 0
  for (let key in allTypes) {
    for (let i = 0; i < allTypes[key]?.length; i++) {
      const customAnswer = listSetOutcomeCustom.find(
        (item) =>
          item.user_id === userId &&
          item.learning_outcome_channel_custom_id === allTypes[key][i]?.id
      )

      const answer = listSetOutcomeStudents.find(
        (item) =>
          item.learning_outcome_id === allTypes[key]?.[i]?.id &&
          item.user_id === userId
      )

      const isCustom = allTypes[key]?.[i]?.is_custom

      countC += get(customAnswer, 'competent') ? 1 : 0

      let nullAnswer = isCustom
        ? get(customAnswer, 'competent') === null
        : get(answer, 'competent') === null

      if (isCustom && !customAnswer) {
        nullAnswer = true
      }

      if (!isCustom && !answer) {
        nullAnswer = true
      }

      if (i === 0) {
        arr.push([
          {
            content: key,
            rowSpan: allTypes[key]?.length,
          },
          allTypes[key][i]?.learning_outcome ||
            allTypes[key][i]?.assessment_criteria?.learning_outcome?.outcome,

          nullAnswer
            ? ''
            : isCustom
            ? get(customAnswer, 'competent')
              ? 'C'
              : 'NYC'
            : get(answer, 'competent')
            ? 'C'
            : 'NYC',
          customAnswer?.remark,
        ])
      } else {
        // eslint-disable-next-line no-sparse-arrays
        arr.push([
          allTypes[key][i]?.learning_outcome ||
            allTypes[key][i]?.assessment_criteria?.learning_outcome?.outcome,
          nullAnswer
            ? ''
            : isCustom
            ? get(customAnswer, 'competent')
              ? 'C'
              : 'NYC'
            : get(answer, 'competent')
            ? 'C'
            : 'NYC',
          customAnswer?.remark,
        ])
      }
    }
  }
  return arr
}
