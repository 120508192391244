import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from 'utils/axiosInstance'

import TableUserClients from '../../../components/TableUserClients'

import useGetClientAdmin from 'src/hooks/useGetClientAdmin'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { isEmpty, uniqBy } from 'lodash'
import { getResponseData } from 'utils/helper'

const UserManagementClients = ({
  handleResendEmail,
  value,
  isSearch,
  setIsSearch,
  clients,
}) => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const { isClientAdmin } = useGetClientAdmin()
  const currentUser = useSelector(makeSelectCurrentUser())

  const userID = currentUser?.uid

  const userHaveClient = useMemo(() => {
    const client = clients?.reduce((acc, curr) => {
      const existUser = curr?.user_clients?.find(
        (x) => x.user_id === parseInt(userID)
      )
      return existUser?.id ? [...acc, curr] : acc
    }, [])
    return client
  }, [clients, userID])

  const listUserByClients = useMemo(() => {
    if (userHaveClient?.length > 0) {
      return uniqBy(
        userHaveClient?.reduce(
          (acc, curr) =>
            curr?.user_clients
              ? [
                  ...acc,
                  ...curr?.user_clients?.filter(
                    (item) =>
                      item?.user_id && item?.user_id !== currentUser?.uid
                  ),
                ]
              : acc,
          []
        ),
        'user_id'
      )
    }
  }, [userHaveClient, currentUser])

  useEffect(() => {
    if (!isEmpty(users)) {
      if (isSearch && value) {
        const data = users.filter((item) => item?.login === value.trim())
        setFilteredUsers(data)
      }

      if (isSearch && !value) {
        setFilteredUsers(users)
      }
      setIsSearch(false)
    }
  }, [users, isSearch, value])

  useEffect(() => {
    if (listUserByClients) {
      const getListUserData = async () => {
        const results = await Promise.all(
          listUserByClients?.map((item) => getUserById(item?.user_id))
        )
        const data = results?.filter((item) => item)
        setUsers(data)
        setFilteredUsers(data)
      }

      getListUserData()
    }
  }, [listUserByClients])

  const getUserById = async (userID) => {
    try {
      const res = await axiosInstance.get(`/api/users?user_id=${userID}`)
      const data = getResponseData(res)

      return data?.[0] || null
    } catch (error) {
      return null
    }
  }

  return (
    <>
      <div className="u-manage__table-wrap" style={{ marginBottom: 40 }}>
        {isClientAdmin && userHaveClient?.length > 0 ? (
          <TableUserClients
            users={filteredUsers}
            dispatch={dispatch}
            setUsers={setUsers}
            handleResendEmail={handleResendEmail}
            clients={clients}
          />
        ) : null}
      </div>
    </>
  )
}

export default UserManagementClients
