import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { get, isEmpty } from 'lodash'
import dayjs from 'dayjs'

import images from 'Themes/Images'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'
import { showSuccess } from 'utils/notification'
import { initialNational, initialLevel } from 'utils/constants'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { createOrUpdateUser } from 'src/customs/SuperAdmin/container/AdminActor/actions'

import './styles.scss'

import { validateNric } from 'utils/helper'
import { DOMAIN } from 'src/routes'
import Default from './Profiles/Default'
import FirstMedia from './Profiles/FirstMedia'
import ArtOfNumbers from './Profiles/ArtOfNumbers'
import Unicorn from './Profiles/Unicorn'
import { getAccessToken } from 'utils/request'
import AgbEducation from './Profiles/AgbEducation'
import Courses from './Profiles/Courses'
import CyberQuote from './Profiles/CyberQuote'
import { getUserProfile } from '../../actions'
import Flc from './Profiles/Flc'
import { parseSafe } from 'utils/helper'

const UserProfile = ({
  userId,
  userData,
  dispatch,
  domain,
  userProfile,
  isAdminUserData,
}) => {
  const [editable, setEditable] = useState(false)

  const isUnicornDomain = domain && domain.domain === DOMAIN.UNICORN_DOMAIN

  const isFirstMediaDomain = domain && domain.domain === DOMAIN.FIRSTMEDIA

  const isAgbEducation = domain && domain.domain === DOMAIN.AGB_EDUCATION

  const isCoursesDomain = domain && domain.domain === DOMAIN.COURSES

  const isCyberQuoteDomain = domain && domain.domain === DOMAIN.CYBERQUOTE

  const isArtOfNumbersDomain =
    domain && domain.domain === DOMAIN.ARTOFUNUMBERS_DOMAIN

  const isFlcDomain = domain && domain.domain === DOMAIN.FLC

  const isPortalDomain = domain && domain.domain === DOMAIN.PORTAL
  const isAscendoDomain =
    domain &&
    (domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO)

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      postTalCode: '',
      gender: 'male',
      date_of_birth: '',
      nationality: '',
      race: '',
      other_nationality: '',
      passport: '',
      phone: '',
      emergency_contact: '',
      occupation: '',
      education_level: '',
      other_level: '',
      year_exp: '',
      is_experience: false,
      salutation: '',
      unit_number: '',
      additional_info: '',
    },
    onSubmit: async (values) => {
      if (
        values.passport &&
        values.passport.length !== 9 &&
        !validateNric(values.passport)
      ) {
        return formik.setFieldError(
          'passport',
          'Please input correct nric format (eg. S9576291C)'
        )
      }
      const body = {
        name: values.name,
        gender: values.gender,
        birthday:
          (dayjs(values.date_of_birth).isValid() &&
            dayjs(values.date_of_birth).format('DD-MM-YYYY')) ||
          '',
        nationality:
          values.nationality === 'Other'
            ? values.other_nationality
            : values.nationality,
        race: values.race,
        passport: values.passport,
        phone: values.phone,
        contact: values.emergency_contact,
        postal_address: values.address,
        postal_code: values.postTalCode,
        occupation: values.occupation,
        education_level:
          values.education_level === 'Other'
            ? values.other_level
            : values.education_level,
        experience: parseInt(values.year_exp),
        user_id: parseInt(userId),
        nric_front: get(values, 'nric_front.img', ''),
        nric_back: get(values, 'nric_back.img', ''),
        salutation: values.salutation,
        is_experience: values.is_experience,
        unit_number: values.unit_number,
        website_id: userProfile?.website_id || domain?.id,
        additional_info: JSON.stringify({
          company: values.company,
          referred_name: values.referred_name,
          referred_mobile: values.referred_mobile,
          referred_co: values.referred_co,
          title: values.title,
          professional_designation: values.professional_designation,
          highest_academic_qualifications:
            values.highest_academic_qualifications,
          marital_status: values.marital_status,
          personal_email: values.personal_email,
          company_email: values.company_email,
          question_1: values.question_1,
          question_2: values.question_2,
          question_3: values.question_3,
          question_4: values.question_4,
          question_5: values.question_5,
          question_6: values.question_6,
          question_6_2: values.question_6_2,
          question_7: values.question_7,
          question_above_40: values.question_above_40,
          user_billing_address: values.user_billing_address,
          other_level: values.other_level,
        }),
      }

      if (!body.nric_front) {
        delete body.nric_front
      }

      if (!body.nric_front) {
        delete body.nric_back
      }
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(
          `${config.baseUrl}/api/users/${userId}/profiles`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/pdf',
              Authorization: `Bearer ${getAccessToken()}`,
            },
            body: JSON.stringify(
              isEmpty(userProfile)
                ? body
                : { ...body, id: get(userProfile, 'id') }
            ),
          }
        )

        const data = await res.json()

        if (data.data.id) {
          showSuccess('Update Profile Success!')
          dispatch(
            createOrUpdateUser({
              name: values.name,
              login: get(userData, 'login', ''),
              id: parseInt(userId),
            })
          )
          setEditable(false)
        }

        dispatch(getUserProfile(userId))

        dispatch(hideGlobalLoading())
      } catch (err) {
        dispatch(hideGlobalLoading())
        console.log('err', err)
      }
    },
  })

  useEffect(() => {
    const name =
      get(userProfile, 'name', '') || get(userData, 'partner.name', '')
    const birthDay = get(userProfile, 'birthday', '')
    const contact = get(userProfile, 'contact', '')
    const educationLevel = get(userProfile, 'education_level', '')
    const race = get(userProfile, 'race', '')
    const experience = get(userProfile, 'experience', '')
    const gender = get(userProfile, 'gender', '')
    const nationality = get(userProfile, 'nationality', '')
    const occupation = get(userProfile, 'occupation', '')
    const passport = get(userProfile, 'passport', '')
    const phone =
      get(userProfile, 'phone', '') || get(userData, 'partner.mobile', '')
    const postalAddress = get(userProfile, 'postal_address', '')
    const postalCode = get(userProfile, 'postal_code', '')
    const salutation = get(userProfile, 'salutation', '')
    const isExperience = get(userProfile, 'is_experience', false)
    const unitNumber = get(userProfile, 'unit_number', '')

    formik.setFieldValue('gender', gender)
    formik.setFieldValue('race', race)
    formik.setFieldValue(
      'date_of_birth',
      birthDay ? dayjs(birthDay, 'DD-MM-YYYY') : ''
    )
    formik.setFieldValue('year_exp', experience)
    formik.setFieldValue('passport', passport)
    formik.setFieldValue('phone', phone)
    formik.setFieldValue('emergency_contact', contact)
    formik.setFieldValue('occupation', occupation)
    formik.setFieldValue('name', name)
    formik.setFieldValue('address', postalAddress)
    formik.setFieldValue('postTalCode', postalCode)
    formik.setFieldValue('salutation', salutation)
    formik.setFieldValue('is_experience', isExperience)
    formik.setFieldValue('unit_number', unitNumber)

    const otherNational = initialNational.find(
      (item) => item.label === nationality
    )

    if (!otherNational) {
      formik.setFieldValue('nationality', 'Other')
      formik.setFieldValue('other_nationality', nationality)
    } else {
      formik.setFieldValue('nationality', nationality)
    }

    const otherLevel = initialLevel.find(
      (item) => item.label === educationLevel
    )

    if (!otherLevel) {
      formik.setFieldValue('education_level', 'Other')
      formik.setFieldValue('other_level', educationLevel)
    } else {
      formik.setFieldValue('education_level', educationLevel)
    }

    const additionalData = parseSafe(get(userProfile, 'additional_info', ''))

    if (additionalData) {
      formik.setFieldValue('company', get(additionalData, 'company', ''))
      formik.setFieldValue(
        'referred_name',
        get(additionalData, 'referred_name', '')
      )
      formik.setFieldValue(
        'referred_mobile',
        get(additionalData, 'referred_mobile', '')
      )
      formik.setFieldValue(
        'referred_co',
        get(additionalData, 'referred_co', '')
      )

      formik.setFieldValue('title', get(additionalData, 'title', ''))

      formik.setFieldValue(
        'professional_designation',
        get(additionalData, 'professional_designation', '')
      )

      formik.setFieldValue(
        'highest_academic_qualifications',
        get(additionalData, 'highest_academic_qualifications', '')
      )

      formik.setFieldValue(
        'marital_status',
        get(additionalData, 'marital_status', '')
      )

      formik.setFieldValue(
        'personal_email',
        get(additionalData, 'personal_email', '')
      )

      formik.setFieldValue(
        'company_email',
        get(additionalData, 'company_email', '')
      )

      formik.setFieldValue('question_1', get(additionalData, 'question_1', ''))

      formik.setFieldValue('question_2', get(additionalData, 'question_2', ''))

      formik.setFieldValue('question_3', get(additionalData, 'question_3', ''))

      formik.setFieldValue(
        'question_above_40',
        get(additionalData, 'question_above_40', '')
      )

      formik.setFieldValue('question_4', get(additionalData, 'question_4', ''))
      formik.setFieldValue('question_5', get(additionalData, 'question_5', ''))

      formik.setFieldValue('question_6', get(additionalData, 'question_6', ''))

      formik.setFieldValue(
        'question_6_2',
        get(additionalData, 'question_6_2', '')
      )

      formik.setFieldValue(
        'user_billing_address',
        get(additionalData, 'user_billing_address', '')
      )

      formik.setFieldValue('question_7', get(additionalData, 'question_7', ''))
    }
  }, [userProfile, userData])

  const handleEditProfile = () => {
    if (editable) {
      formik.submitForm()
    } else {
      setEditable(true)
    }
  }

  const renderProfileBySchool = () => {
    if (isCoursesDomain) {
      return (
        <Courses
          formik={formik}
          userData={userData}
          editable={editable}
          userProfile={userProfile}
          dispatch={dispatch}
          userId={userId}
          setEditable={setEditable}
        />
      )
    }

    if (isAgbEducation) {
      return (
        <AgbEducation
          formik={formik}
          userData={userData}
          editable={editable}
          userProfile={userProfile}
          dispatch={dispatch}
          userId={userId}
          setEditable={setEditable}
        />
      )
    }
    if (isUnicornDomain) {
      return (
        <Unicorn
          formik={formik}
          userData={userData}
          editable={editable}
          userProfile={userProfile}
          dispatch={dispatch}
          userId={userId}
          setEditable={setEditable}
        />
      )
    }

    if (isFlcDomain || isPortalDomain) {
      return (
        <Flc
          formik={formik}
          userData={userData}
          editable={editable}
          userProfile={userProfile}
          dispatch={dispatch}
          userId={userId}
          setEditable={setEditable}
        />
      )
    }

    if (isFirstMediaDomain) {
      return (
        <FirstMedia
          formik={formik}
          userData={userData}
          editable={editable}
          userProfile={userProfile}
        />
      )
    }

    if (isArtOfNumbersDomain) {
      return (
        <ArtOfNumbers
          userProfile={userProfile}
          editable={editable}
          formik={formik}
          userData={userData}
        />
      )
    }
    if (isCyberQuoteDomain) {
      return (
        <CyberQuote
          userProfile={userProfile}
          editable={editable}
          formik={formik}
          userData={userData}
        />
      )
    }

    return (
      <Default
        userProfile={userProfile}
        editable={editable}
        formik={formik}
        userData={userData}
        isAscendoDomain={isAscendoDomain}
      />
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: 'flex' }}>
        <h1 className="u-manage__heading">User Profile</h1>
        <div>
          <div>
            <BtnPrimary
              name={`${
                editable
                  ? 'Save'
                  : userProfile
                  ? 'Edit Profile'
                  : 'Create Profile'
              }`}
              style={{
                fontWeight: 'bold',
                color: '#fff',
                marginBottom: 15,
                marginLeft: 30,
              }}
              type="button"
              handleClick={handleEditProfile}
              iconLeft={images.admin.save_icon}
            />
          </div>
          {editable && (
            <BtnPrimary
              name="Cancel"
              style={{
                marginLeft: 20,
                backgroundColor: '#E7EBF4',
                fontWeight: 'bold',
                color: '#6F64A0',
              }}
              iconLeft={images.admin.x_inactive}
              type="button"
              handleClick={() => setEditable(false)}
            />
          )}
        </div>
      </div>

      {renderProfileBySchool()}
    </form>
  )
}

export default UserProfile
