import React from 'react'
import styled from 'styled-components'

const ButtonWrap = styled.button`
  outline: none;
  border: none;
  min-width: ${(props) => (props.fullWidth ? '100%' : `${props.minWidth}px`)};
  height: ${(props) => `${props.height}px`};
  font-weight: 600;
  line-height: 18px;
  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};
  border-radius: 10px;
  cursor: pointer;
  padding: 0 15px;
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize}px` : props.fullWidth ? '14px' : '18px'};
`

const Button = ({
  title,
  handleClick,
  color,
  bgColor,
  minWidth,
  height,
  fullWidth,
  fontSize,
  className
}) => {
  return (
    <ButtonWrap
      onClick={handleClick}
      color={color}
      bgColor={bgColor}
      minWidth={minWidth}
      height={height}
      fullWidth={fullWidth}
      fontSize={fontSize}
      className={className}
    >
      {title}
    </ButtonWrap>
  )
}

export default Button
