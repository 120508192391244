import React from 'react'
import { Row, Col } from 'antd'
import Input from 'component/Input'

const ProfileAgbEducation = ({ formik, handleNumberInput }) => {
  return (
    <>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Full Name (ACCORDING TO NRIC):</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="name"
              placeHolder="Enter your full name"
              formik={formik}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">ID/Passport No. (eg. NRIC):</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="passport"
              placeHolder="Enter ID/Passport"
              formik={formik}
              value={formik.values.passport}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Phone No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="phone"
              placeHolder="Enter Phone No."
              formik={formik}
              value={formik.values.phone}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'phone')
              }
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProfileAgbEducation
