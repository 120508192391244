import axios from 'axios'
import { getAccessToken } from 'utils/request'
import config from 'src/global-config'

const defaultOptions = {
  baseURL: config.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
}

const axiosInstance = axios.create(defaultOptions)

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(async (config) => {
  const token = getAccessToken()
  config.headers.Authorization = token ? `Bearer ${token}` : ''

  return config
})

export default axiosInstance
