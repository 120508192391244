import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import images from 'Themes/Images'
import { renderAnswer } from '../../helper'
import { setAnswerCompetent } from '../../../../actions'
import { showError } from 'utils/notification'
import Remarks from '../Remarks'
import parseHtml, { domToReact } from 'html-react-parser'
import { parseSafe } from 'utils/helper'
import { CKEditorWrapper } from '../../../../../CMS/screens/Article'
import classNames from 'classnames'

const UserAnswer = ({
  item,
  i,
  userAnswer,
  selectedSchedule,
  remarks,
  setRemarks,
  isUnicornOrBell = false,
}) => {
  const dispatch = useDispatch()
  const answer = userAnswer.find((i) => i.question_id === item.id)
  const remark = remarks?.find((i) => i.question_id === item.id)
  const answerCompetent = get(answer, 'competent')
  const isEmptyAnswer =
    get(answer, 'value', '') === '' &&
    get(answer, 'url', '') === '' &&
    !get(answer, 'attachments') &&
    !parseSafe(get(answer, 'file_attachments'))
  const [activeImage, setActiveImage] = useState({})

  const multipleChoices = parseSafe(get(item, 'multiple_choices'))
  const listChoices = parseSafe(get(answer, 'value', ''))
  const correctChoices =
    multipleChoices && multipleChoices.filter((item) => item.is_correct)
  const correctChoicesLength = correctChoices && correctChoices.length

  const isCorrectAnswer = useMemo(() => {
    let lengthCorrectAnswer = 0
    if (listChoices && correctChoices) {
      for (let i = 0; i < listChoices.length; i++) {
        for (let j = 0; j < correctChoices.length; j++) {
          if (listChoices[i].order === correctChoices[j].order) {
            lengthCorrectAnswer++
          }
        }
      }
      if (lengthCorrectAnswer && lengthCorrectAnswer === correctChoicesLength) {
        return true
      }
      return false
    }
  }, [listChoices, correctChoices, correctChoicesLength])

  const isFeedBack = useMemo(
    () =>
      Array.isArray(multipleChoices) &&
      multipleChoices?.reduce(
        (acc, curr) => (acc + curr?.is_correct ? 1 : 0),
        0
      ) === 0,
    [multipleChoices]
  )

  const isSameCompetent = isCorrectAnswer === answerCompetent

  useEffect(() => {
    if (
      !isFeedBack &&
      multipleChoices &&
      Array.isArray(listChoices) &&
      !isSameCompetent
    ) {
      if (isCorrectAnswer) {
        handleCheckAnswer()
      } else {
        handleUnCheckAnswer()
      }
    }
  }, [
    multipleChoices,
    listChoices,
    isCorrectAnswer,
    isSameCompetent,
    isFeedBack,
  ])

  const handleCheckAnswer = () => {
    if (get(answer, 'id', null) && !isEmptyAnswer) {
      const competent = true
      dispatch(
        setAnswerCompetent({
          id: answer.id,
          competent,
          schedule: selectedSchedule,
          remark: remark?.remark || '',
        })
      )
    } else {
      showError(`The student doesn't answer this question yet!`)
    }
  }

  const handleUnCheckAnswer = () => {
    if (get(answer, 'id', null) && !isEmptyAnswer) {
      const competent = false
      dispatch(
        setAnswerCompetent({
          id: answer.id,
          competent,
          schedule: selectedSchedule,
          remark: remark?.remark || '',
        })
      )
    } else {
      showError(`The student doesn't answer this question yet!`)
    }
  }
  const renderCompetent = () => {
    let competent = get(answer, 'competent', null)
    let value = get(answer, 'value', null)

    return (
      <div className={`quiz__head__competent`}>
        <div
          className={`quiz__head__check ${
            !isEmptyAnswer && competent === true
              ? 'quiz__head__check-active'
              : ''
          }`}
          onClick={handleCheckAnswer}
        >
          <img
            src={
              !isEmptyAnswer && (competent == true || competent === null)
                ? images.header.check
                : images.header.check_inactive
            }
            alt=""
          />
        </div>
        <div
          className={`quiz__head__check ${
            value && competent === false ? 'quiz__head__check__x-active' : ''
          }`}
          onClick={handleUnCheckAnswer}
        >
          <img
            src={
              !isEmptyAnswer && (competent == false || competent === null)
                ? images.admin.x
                : images.admin.x_inactive
            }
            alt=""
          />
        </div>
      </div>
    )
  }

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return
      }

      const id = get(children, '[0].attribs.src', '')
      const subId = id.substr(id.length - 8)

      if (attribs.class === 'image') {
        const active = get(activeImage, `[${subId}]`, false)
        return (
          <div
            className={classNames({
              active_image: active,
            })}
            onClick={() => handleClickImage(subId)}
          >
            {active && (
              <div
                className="backdrop"
                onClick={() => handleClickImage(subId)}
              />
            )}
            {domToReact(children, options)}
          </div>
        )
      }
    },
  }

  const handleClickImage = (id) => {
    let activeImageClone = {}
    activeImageClone[id] = !activeImage[id]
    setActiveImage(activeImageClone)
  }

  return (
    <>
      <div className="quiz__head-wrap">
        <div className="quiz__head__question">
          <div className="quiz__head__text">Question {i + 1}</div>
          <div className="quiz__question-des">
            <CKEditorWrapper>
              {parseHtml(item.question, options)}
            </CKEditorWrapper>
          </div>
        </div>
        {renderCompetent()}
      </div>
      <div className="quiz__description-wrap">
        {renderAnswer(
          answer,
          item,
          i,
          isCorrectAnswer,
          correctChoices,
          isFeedBack
        )}
      </div>
      {!isEmptyAnswer && (
        <Remarks
          remark={remark}
          remarks={remarks}
          setRemarks={setRemarks}
          answer={answer}
          selectedSchedule={selectedSchedule}
          dispatch={dispatch}
          title={
            isUnicornOrBell
              ? 'Add Remarks (Type at least 3 characters)'
              : 'Add Remarks'
          }
        />
      )}
    </>
  )
}

export default UserAnswer
