import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Space } from 'antd'
import { deleteArticle, getListArticle } from '../../hooks'
import dayjs from 'dayjs'

import styled from 'styled-components'
import NavBar from 'src/customs/ClassCompare/src/components/NavbarClassCompare'
import { Body, Layout } from 'src/customs/ClassCompare/styles'
import Button from 'src/customs/ClassCompare/src/components/Button'
import { getImageAttachment } from 'utils/helper'
import { get } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { showSuccess } from 'utils/notification'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { useDispatch } from 'react-redux'

const CMSWrapper = styled.div`
  .ck-editor__editable_inline {
    min-height: 500px;
  }
`

const TableWrapper = styled.div`
  .ant-table-content::-webkit-scrollbar {
    display: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background: #f5f5f5;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f5f5f5;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 9px;
    text-transform: uppercase;
    color: #042580;
  }

  .ant-table-tbody .ant-table-cell {
    font-weight: normal;
    font-size: 11px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dee2ed;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ImgWrapper = styled.div`
  width: 150px;
  height: 68px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
`

const ListArticle = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const history = useHistory()
  const [listArticle, setListArticle] = useState(null)

  React.useEffect(() => {
    let isCancelled = false

    const loadListArticle = async () => {
      const res = await getListArticle()
      return res
    }

    loadListArticle().then((articles) => {
      if (articles && !listArticle) {
        setListArticle(articles)
      }
    })

    return () => {
      isCancelled = true
    }
  }, [])

  const columns = [
    {
      title: 'Featured Image',
      dataIndex: 'image',
      key: 'image',
      render: (image, record) => {
        return (
          <ImgWrapper onClick={() => handleEdit(record)}>
            <img src={image} width="113" height="68" />
          </ImgWrapper>
        )
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => {
        return <span onClick={() => handleEdit(record)}>{title}</span>
      }
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      render: (slug, record) => {
        return <span onClick={() => handleEdit(record)}>{slug}</span>
      }
    },
    {
      title: 'Created At',
      dataIndex: 'create_date',
      key: 'create_date',
      render: (date) => {
        const createdAt = dayjs(date).format('LLL')
        return <span>{createdAt}</span>
      }
    },
    {
      title: 'Updated At',
      dataIndex: 'write_date',
      key: 'write_date',
      render: (date) => {
        const updatedAt = dayjs(date).format('LLL')
        return <span>{updatedAt}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <span>{status ? 'Published' : 'Draft'}</span>
      }
    },

    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id, record) => (
        <Space size="middle">
          <a onClick={() => handleEdit(record)}>Edit</a>
          <a onClick={() => handleDelete(record)}>Delete</a>
        </Space>
      )
    }
  ]

  const handleDelete = async (record) => {
    const id = get(record, 'id')
    dispatch(showGlobalLoading())
    await deleteArticle(id)
    const newList = await getListArticle()
    setListArticle(newList)
    dispatch(hideGlobalLoading())
    showSuccess('Delete Article Successful!')
  }

  const handleCreate = () => {
    history.push('/cms/article')
  }
  const handleEdit = (record) => {
    const id = get(record, 'id')
    history.push(`/cms/${id}`)
  }
  return (
    <Layout>
      <Body>
        <CMSWrapper>
          <NavBar cmsMode />
          <ButtonWrapper className="mgB-45">
            <Button
              title="Create"
              color="#fff"
              bgColor="#FF0D6B"
              minWidth="123"
              height="39"
              fontSize="12"
              handleClick={handleCreate}
            />
          </ButtonWrapper>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={listArticle}
              pagination={false}
              scroll={isMobile ? { x: 1200 } : null}
            />
          </TableWrapper>
        </CMSWrapper>
      </Body>
    </Layout>
  )
}

export default ListArticle
