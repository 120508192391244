import React, { useRef, useState } from 'react'
import { Modal } from 'antd'
import { get } from 'lodash'
import images from 'Themes/Images'
import { hidePreviewModal } from '../../actions'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import SwitchPagePdf from 'component/SwitchPagePdf'
import { useMediaQuery } from 'react-responsive'
import FileViewer from 'react-file-viewer'
import { saveAs } from 'file-saver'

const PreviewModal = ({ showPreviewModal, dispatch }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [activePdf, setActivePdf] = useState(false)
  const ref = useRef()

  const allPages = get(ref, 'current.pages.length', 0)

  const url = get(showPreviewModal, 'url', '')
  const name = get(showPreviewModal, 'name', '')
  const type = get(showPreviewModal, 'type', '')
  const fileType = name.split('.').pop()
  const isAdmin = get(showPreviewModal, 'isAdmin')

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const handleCancel = () => {
    dispatch(hidePreviewModal())
  }

  const handleNextPage = () => {
    if (pageNumber !== allPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const handlePreviousPage = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const handleDownload = () => {
    saveAs(url, name)
  }

  const renderByType = () => {
    if (type === 'image') {
      return (
        <div className="ld-modal" style={{ minHeight: 400 }}>
          <div className="ld-modal__label" style={{ fontWeight: 'bold' }}>
            {name}{' '}
            {isAdmin ? (
              <span className="download__file" onClick={() => handleDownload()}>
                <img src={images.admin.download} alt="" />
              </span>
            ) : null}
          </div>
          <div
            className="ld-modal__icon"
            style={{ textAlign: 'center', marginBottom: 20 }}
          >
            <img
              src={url + '=s0'}
              alt={name}
              width="100%"
              height="100%"
              style={{ objectFit: 'cover' }}
            />
          </div>
        </div>
      )
    }

    if (type === 'attachment' && fileType === 'pdf') {
      return (
        <div className="ld-modal">
          <div className="ld-modal__label" style={{ fontWeight: 'bold' }}>
            {name}
            {isAdmin ? (
              <span className="download__file" onClick={() => handleDownload()}>
                <img src={images.admin.download} alt="" />
              </span>
            ) : null}
          </div>
          <div
            className="ld-modal__icon"
            style={{ textAlign: 'center', marginBottom: 20 }}
          >
            {activePdf && (
              <SwitchPagePdf
                pageNumber={pageNumber}
                numPages={allPages}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
              />
            )}

            <Document file={url} className="pdf-custom" ref={ref}>
              <Page
                key={name}
                pageNumber={pageNumber}
                onLoadError={() => setActivePdf(false)}
                onLoadSuccess={() => setActivePdf(true)}
                width={isMobile ? 300 : 600}
                className="page-custom"
              />
            </Document>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="ld-modal__label" style={{ fontWeight: 'bold' }}>
          {name}
          {isAdmin ? (
            <span className="download__file" onClick={() => handleDownload()}>
              <img src={images.admin.download} alt="" />
            </span>
          ) : null}
        </div>
        <FileViewer fileType={fileType} filePath={url} />
      </>
    )
  }
  return (
    <Modal
      visible={!!showPreviewModal}
      footer={null}
      onCancel={handleCancel}
      width={800}
    >
      {renderByType()}
    </Modal>
  )
}

export default PreviewModal
