import React from 'react'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import PaymentMethod from 'container/Home/screens/CoursePreview/components/PaymentMethod'
import { formatMoney } from 'utils/helper'
import './styles.scss'
import { useSelector } from 'react-redux'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes'

const ProgressBtn = ({
  billing,
  course,
  discount,
  submit,
  submitRef,
  applicationFee,
  isAllowApplicationFee,
  isFlcDomain,
  isUnicornDomain,
  selectedSchedule,
  firstMediaDomain,
  specialPrice,
  allowSpecialPrice,
  cyberQuoteDomain,
  handleNextStep = () => {},
  selectedPriceSFC,
  therapadaDomain
}) => {
  const domain = useSelector(makeSelectDomain())
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const history = useHistory()
  const price = get(course, 'price', 0)
  const currency = therapadaDomain ? 'USD' : 'SGD'

  const renderName = () => {
    if (selectedPriceSFC) {
      return `Pay`
    }
    if (allowSpecialPrice) {
      return `Pay ${formatMoney(
        specialPrice - (specialPrice * discount) / 100,
        false,
        currency
      )}`
    }
    if (isAllowApplicationFee) {
      return `Pay ${formatMoney(
        applicationFee - (applicationFee * discount) / 100,
        false,
        currency
      )}`
    }
    return isUnicornDomain || firstMediaDomain || isFlcDomain
      ? billing
        ? 'Pay'
        : 'Next'
      : billing
      ? `Pay ${
          (formatMoney(price - (price * discount) / 100), false, currency)
        }`
      : 'Next'
  }
  return (
    <div className="hs-progress-btn">
      <div />
      <div>
        <BtnPrimary
          name={billing ? 'Back' : 'Back to course page'}
          iconLeft={images.course.arrow_left}
          style={{
            marginRight: 16,
            padding: '12px 20px',
            backgroundColor: '#E7EBF4',
            color: '#6F84A0',
            fontWeight: 600
          }}
          handleClick={() => history.goBack()}
          type="button"
          styleIconLeft={{ marginBottom: 2 }}
        />
        <BtnPrimary
          btnRef={submitRef}
          name={renderName()}
          icon={images.course.arrow_right_active}
          style={{
            backgroundColor: '#00122A',
            fontWeight: 600,
            padding: '12px 40px'
          }}
          styleIcon={{ marginBottom: 2 }}
          handleClick={handleNextStep}
          type={submit ? 'submit' : 'button'}
        />
      </div>
    </div>
  )
}

export default ProgressBtn
