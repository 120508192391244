// eslint-disable-next-line
import produce from 'immer'
import * as constants from './constants'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'

export const initialState = {
  listCourseOfSeller: null,
  listCollection: null,
  deleteLead: false
}

const persistConfig = {
  key: 'SuperAdmin',
  storage: localStorage,
  whitelist: []
}

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.LOAD_LIST_COURSE_OF_SELLER:
        break
      case constants.LOAD_LIST_COURSE_OF_SELLER_SUCCESS:
        newState.listCourseOfSeller = action.payload
        break
      case constants.LOAD_LIST_COURSE_OF_SELLER_FAILED:
        newState.listCourseOfSeller = null
        break

      case constants.LOAD_LIST_COLLECTION:
        break
      case constants.LOAD_LIST_COLLECTION_SUCCESS:
        newState.listCollection = action.payload
        break
      case constants.LOAD_LIST_COLLECTION_FAILED:
        newState.listCollection = null
        break

      case constants.DELETE_LEAD:
        break
      case constants.DELETE_LEAD_SUCCESS:
        newState.deleteLead = action.payload
        break
      case constants.DELETE_LEAD_FAILED:
        newState.deleteLead = false
        break
    }
  })

export default persistReducer(persistConfig, reducer)
