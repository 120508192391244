import { useEffect, useState } from 'react'
import axiosInstance from '../utils/axiosInstance'

const useLoadCourses = () => {
  const [courses, setCourses] = useState([])

  useEffect(() => {
    const getCourses = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/lms/courses/channels?order_by=id%20desc&published=all&per_page=1000&ignore_per_page=true`
        )
        if (response?.data?.data) setCourses(response?.data?.data)
      } catch (error) {
        setCourses([])
      }
    }

    getCourses()
  }, [])

  return courses
}

export default useLoadCourses
