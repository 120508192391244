import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { get, isEmpty, truncate } from 'lodash'
import 'jspdf-autotable'
import config from 'src/global-config'
import bells from './bells_2.jpg'

import { showError } from 'utils/notification'
import {
  replaceTextForExporting,
  replaceTextForMQC,
  parseSafe,
} from 'utils/helper'
import { makeSelectDomain } from 'container/Home/selectors'
import { getProfile } from 'utils/profileUtils'
import { MAPPING_NUMBER, isValidUrl } from 'src/utils/helper'
import { DOMAIN } from 'src/routes'

const ReportBooks = ({
  course,
  questions,
  slideDetails,
  listUserAnswer,
  selectedSchedule,
}) => {
  const [listUserProfile, setListUserProfile] = useState(null)
  const questionsLength = Array.isArray(questions) && questions.length
  const domain = useSelector(makeSelectDomain())
  const isBellDomain = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION
  const isAscendoDomain =
    get(domain, 'domain', '') === DOMAIN.ASCENDO ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO_2

  useEffect(() => {
    const fetchProfiles = async (userId, listProfile) => {
      try {
        const res = await fetch(
          `${config.baseUrl}/api/users/${userId}/profiles`,
          {
            method: 'GET',
          }
        )

        const data = await res.json()
        if (Array.isArray(data.data) && data.data.length !== 0) {
          const profileDomainID = course?.website_id
            ? course?.website_id
            : domain?.id
          listProfile[get(data, 'data[0].user_id')] = getProfile(
            profileDomainID,
            data?.data
          )
        }
      } catch (error) {
        console.log('err', error)
      }
    }
    const listProfile = {}
    for (const key of Object.keys(listUserAnswer)) {
      const userId = listUserAnswer[key][0].user.id
      fetchProfiles(userId, listProfile)
    }

    setListUserProfile(listProfile)
  }, [listUserAnswer])

  const zip = new JSZip()
  const folderZip = zip.folder('students_workbook')

  const getXOffset = (doc, text) =>
    (doc.internal.pageSize.getWidth() - doc.getTextWidth(text)) / 2

  const coverPage = ({ doc, courseName, name }) => {
    doc.setFontSize(9)
    doc.text(`<${courseName}>`, 60, 32)
    doc.text(slideDetails?.name, 60, 45)
    doc.addImage(bells, 'JPG', 490, 10, 40, 40)
    doc.setFontSize(12)
    doc.text(
      '_______________________________________________________________________',
      60,
      50
    )
    doc.addImage(bells, 'JPG', 180, 80, 220, 220)

    const lessonName = slideDetails?.name
    var splitLesson = doc.splitTextToSize(lessonName, 200)
    doc.setFontSize(26)

    for (let i = 0; i < splitLesson.length; i++) {
      doc.text(splitLesson[i], getXOffset(doc, splitLesson[i]), 350 + i * 30)
    }
    const spaceYLesson = 30 * splitLesson.length - 1
    doc.setFontSize(18)
    doc.text('For:', getXOffset(doc, 'For:'), 370 + spaceYLesson)

    var splitTitle = doc.splitTextToSize(courseName, 350)

    doc.setFontSize(22)
    for (let i = 0; i < splitTitle.length; i++) {
      doc.text(
        splitTitle[i],
        getXOffset(doc, splitTitle[i]),
        410 + i * 30 + spaceYLesson
      )
    }
    // SFw User Interface Design ICT-DES-4008-1.1
    doc.text(
      course?.proficieny_name,
      getXOffset(doc, course?.proficieny_name),
      splitTitle?.length * 30 + 420 + spaceYLesson
    )
    doc.text(
      course?.proficieny_id,
      getXOffset(doc, course?.proficieny_id),
      splitTitle?.length * 30 + 450 + spaceYLesson
    )

    doc.autoTable({
      margin: { left: 60, right: 60 },
      startY: 580 + spaceYLesson,
      theme: 'grid',
      bodyStyles: {
        lineWidth: 0.6,
        lineColor: 12,
        minCellHeight: 30,
        fontSize: 18,
        textColor: [32, 32, 32],
      },
      body: [
        [
          {
            content: `Approved Assessment Centre: 
BELLS Institute of Higher Learning Pte Ltd`,
          },
        ],
        [
          `Candidate Name: 
${name}`,
        ],
      ],
    })

    copyRightPageBells({ doc })

    doc.addPage()

    doc.setFontSize(24)
    doc.text('Version Control Record', 60, 80)
    doc.autoTable({
      margin: { left: 60 },
      startY: 100,
      theme: 'grid',
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 100 },
        2: { cellWidth: 205 },
        3: { cellWidth: 110 },
      },
      bodyStyles: {
        lineWidth: 0.6,
        lineColor: 12,
        minCellHeight: 30,
      },
      headStyles: {
        lineWidth: 0.6,
        lineColor: 12,
        fillColor: [217, 217, 217],
        textColor: [32, 32, 32],
      },
      head: [
        [
          {
            content: 'Version',
          },
          { content: 'Effective Date' },
          { content: 'Changes' },
          { content: 'Author' },
        ],
      ],
      body: [
        [
          '1.0',
          '28 Oct 2020',
          'First Edition',
          `Esmond Wong
Kwok Yi Jun`,
        ],
        [
          '1.1',
          '3 Feb 2021',
          `Assessment Record changed to reflect advice to learners to use masked NRIC number, indicates Start and End time for each assessment method.`,
          'Rudolf Liu',
        ],
        ['1.2', '19 May 2022', 'Changes to RTP name and logo', 'Naidu Gautama'],
        ['1.3', '01 Jul 2023', 'Changes to RTP name and logo', 'Naidu Gautama'],
      ],
    })

    copyRightPageBells({ doc })

    doc.addPage()
  }

  const copyRightPageBells = ({ doc }) => {
    const bottomPos = doc.internal.pageSize.getHeight() - 60
    const pageCount = doc.internal.getNumberOfPages()
    for (var i = 1; i <= pageCount; i++) {
      doc.setFontSize(12)
      doc.text(
        '_______________________________________________________________________',
        60,
        bottomPos - 20
      )
      doc.setFontSize(9)
      doc.setPage(i)
      doc.text(String(i), doc.internal.pageSize.width / 2 + 220, bottomPos)
      doc.setFontSize(10)
      doc.text(
        'Copyright 2023 © BELLS Institute of Higher Learning Pte Ltd',
        60,
        bottomPos
      )
      doc.text('All Right Reserved', 60, bottomPos + 15)
    }
  }

  const assessmentItem = (doc, data) => {
    const user_id = get(data, '[0].user_id')
    const total_passed = get(data, '[0].user.answer.total_passed')
    const percent_quiz_pass = get(data, '[0].user.answer.percent_quiz_pass')
    const createdUser = data.find((item) => item.user.answer.create_user)
    const questionTypeText = questions.filter(
      (item) => item.types.match('text') || item.types === ''
    )
    const passport = get(listUserProfile, `[${user_id}].passport`, '')
    const createDate = get(data, '[0].create_date', '')
    const partner = get(data, '[0].user.partner', '')
    const courseName = course?.name || ''
    if (isBellDomain) {
      coverPage({ doc, courseName, name: get(partner, 'name', '') })
    }
    doc.setFontSize(24)

    if (domain?.logo) {
      const imgProps = doc.getImageProperties(domain?.logo)

      doc.addImage(
        domain?.logo,
        'PNG',
        20,
        10,
        imgProps.width / 4,
        imgProps.height / 4
      )
    } else {
      doc.text(get(domain, 'name', ''), 20, 60)
    }

    doc.setFontSize(10)

    doc.setFontSize(16)

    const title = isAscendoDomain
      ? doc.splitTextToSize(
          courseName + ' - ' + slideDetails?.name || '',
          550,
          20
        )
      : slideDetails?.name

    doc.text(title, 20, domain?.logo ? 160 : 110)

    doc.setFontSize(11)
    doc.text(
      'This document is to record the evidence gathered for the competency assessment of the following',
      20,
      190
    )

    doc.setFontSize(11)
    doc.text(`candidate.`, 20, 210)

    doc.autoTable({
      margin: { left: 20 },
      startY: 250,
      startX: 20,
      theme: 'plain',
      columnStyles: {
        0: { cellWidth: 267.64 },
        1: { cellWidth: 267.64 },
      },
      styles: {},
      bodyStyles: {
        lineWidth: 0.6,
        lineColor: 12,
        minCellHeight: 40,
      },
      body: [
        [
          `Candidate's Name (as in NRIC): ${get(partner, 'name', '')}`,
          `NRIC No: ${
            isBellDomain
              ? passport
                ? `S/T XXXX${passport.slice(-4).slice(0, 3)}/${passport
                    .slice(-4)
                    .slice(-1)}`
                : ''
              : passport
          }`,
        ],
        !isBellDomain
          ? [
              `Assessor's Name: ${get(
                createdUser,
                'user.answer.create_user.partner.name',
                ''
              )}`,
              'Assessment Venue: Online Class',
            ]
          : null,
        [
          `Date of Assessment: ${
            createDate ? dayjs(createDate).format('DD/MM/YYYY') : ''
          }`,
          'Duration of Assessment: 60min',
        ],
        [
          `Assessment Marks: ${total_passed}/${
            questionsLength ? questionsLength : ''
          }`,
          `Grade: ${percent_quiz_pass ? percent_quiz_pass + '%' : ''}`,
        ],
      ].filter((x) => x),
    })

    // table 1

    const body = questionTypeText.map((question) => {
      const answer = data.find((item) => item.question_id === question.id)
      const marked = get(answer, 'user.answer.competent', null)
      const quizQuestion = get(question, 'question', '')
      const value = get(answer, 'value', '')
      const url = get(answer, 'url', '')
      const remark = get(answer, 'remark', '')
      const attachment = parseSafe(get(answer, 'file_attachments', ''))
      const isExistAttachment =
        Array.isArray(attachment) && attachment?.length > 0
      if (question.types === '') {
        const multipleChoices = parseSafe(get(question, 'multiple_choices', ''))
        const isFeedBack =
          multipleChoices?.reduce(
            (acc, curr) => (acc + curr?.is_correct ? 1 : 0),
            0
          ) === 0

        return [
          replaceTextForExporting(quizQuestion),
          replaceTextForMQC(answer, multipleChoices, isFeedBack),
          {
            content:
              marked === null || answer?.competent === null
                ? ''
                : answer?.competent
                ? ' '
                : '  ',
            key: 'mark',
            styles: { halign: 'center', valign: 'middle' },
          },
          replaceTextForExporting(remark),
        ]
      }

      return [
        replaceTextForExporting(quizQuestion),
        {
          content: !isExistAttachment
            ? replaceTextForExporting(value)
            : 'As per attached:',
          key: isExistAttachment ? answer?.id : 0,
          url: isValidUrl(url) ? answer?.id : 0,
        },
        {
          content:
            marked === null || answer?.competent === null
              ? ''
              : answer?.competent
              ? ' '
              : '  ',
          key: 'mark',
          styles: { halign: 'center', valign: 'middle' },
        },
        replaceTextForExporting(remark),
      ]
    })

    doc.autoTable({
      margin: { left: 20, bottom: 100 },
      startY: 450,
      showHead: 'firstPage',
      theme: 'grid',
      rowPageBreak: 'avoid',
      columnStyles: {
        0: { cellWidth: 220 },
        1: { cellWidth: 180 },
        2: { cellWidth: 50 },
        3: { cellWidth: 85 },
      },
      bodyStyles: {
        lineWidth: 0.6,
        lineColor: 12,
      },
      headStyles: {
        lineWidth: 0.6,
        lineColor: 12,
        valign: 'middle',
        halign: 'center',
        fillColor: [217, 217, 217],
        textColor: [32, 32, 32],
      },
      head: [
        [
          {
            content: 'Question',
          },
          { content: 'Answer' },
          { content: 'Mark' },
          { content: 'Remarks' },
        ],
      ],
      body: body,
      didDrawPage: function (data) {
        data.settings.margin.top = 20
      },
      didDrawCell: function (draw) {
        if (draw.cell.raw.url && draw.pageNumber === 1) {
          var cell = draw.cell
          const answer = data?.find((item) => item.id === draw.cell.raw.url)
          const url = get(answer, 'url', '')

          doc.setTextColor(1, 97, 255)
          doc.textWithLink(`Attachment - URL` || '', cell.x + 5, cell.y + 20, {
            url: url,
          })
        }

        if (draw.cell.raw.key && draw.pageNumber === 1) {
          var cell = draw.cell
          const answer = data?.find((item) => item.id === draw.cell.raw.key)
          const attachments = parseSafe(get(answer, 'file_attachments', ''))

          // eslint-disable-next-line no-unused-expressions
          attachments &&
            attachments?.map((item, index) => {
              doc.setTextColor(1, 97, 255)
              doc.textWithLink(
                `Attachment - ${MAPPING_NUMBER[index]}` || '',
                cell.x + 5,
                cell.y + 30 * (index + 1),
                {
                  url: item.url,
                }
              )
            })
        }
        if (draw.cell.raw.key === 'mark' && draw.cell.raw.content === '  ') {
          var cell = draw.cell
          doc.addImage(
            'https://lh3.googleusercontent.com/QfgjAd7jfGbt5KwEsZ8WLmzVEq0Npb3--LgMMPLz7PZuSptDgcNYZxTSk2CN3_Fss5yZdGcfkbAJReT_ek8k8aB5V3M4kOwqFBhNlplClR34PFjt=s0',
            cell.x + cell.width / 2 - 10,
            cell.y + cell.height / 2 - 10,
            20,
            20
          )
        }

        if (draw.cell.raw.key === 'mark' && draw.cell.raw.content === ' ') {
          var cell = draw.cell
          doc.addImage(
            'https://lh3.googleusercontent.com/_kwqqknsSWAavjQ606XM5csbbP00AAB-NPoPgEqpKSxlWFjMCSNB20V0Y25zw0-ZLUP6ATyFPkjf0GDVTogWpg865seMWbAN2Y_yYnyNtkOC1jqt=s0',
            cell.x + cell.width / 2 - 10,
            cell.y + cell.height / 2 - 10,
            20,
            20
          )
        }
      },
    })
    if (isBellDomain) {
      copyRightPageBells({ doc })
    }
  }

  const generatePDF = () => {
    if (isEmpty(listUserAnswer)) {
      showError('There is no user')
      return
    }
    const doc = new jsPDF('p', 'pt')

    let i = 0
    const length = Object.keys(listUserAnswer).length
    for (const key of Object.keys(listUserAnswer)) {
      assessmentItem(doc, listUserAnswer[key], key)

      if (i < length - 1) {
        doc.addPage()
      }
      i++
    }

    if (isBellDomain) {
      const lessonName = slideDetails?.name
        ? slideDetails?.name.trim().replace(/\s+/g, '_').replace(/\.+/g, '')
        : ''

      const fileName = `${truncate(
        `${get(
          selectedSchedule,
          'class_code',
          ''
        )}_Assessment ${lessonName} (All)}`,
        {
          length: 83,
          separator: /,? +/,
        }
      )}.pdf`

      doc.save(fileName)
    } else {
      doc.save(
        `${get(selectedSchedule, 'class_code', '')}_Student Workbook.pdf`
      )
    }
  }

  const generateIndividualsWorkBook = () => {
    if (isEmpty(listUserAnswer)) {
      showError('There is no user')
      return
    }

    const lessonName = slideDetails?.name
      ? slideDetails?.name.trim().replace(/\s+/g, '_').replace(/\.+/g, '')
      : ''
    let i = 0
    for (const key of Object.keys(listUserAnswer)) {
      const doc = new jsPDF('p', 'pt')
      assessmentItem(doc, listUserAnswer[key], key)

      if (typeof doc !== 'undefined') {
        const name = get(
          listUserAnswer,
          `[${key}][0].user.partner.name`,
          ''
        ).replace(/ +/g, '_')
        try {
          if (isBellDomain) {
            const fileName = `${name}_${get(
              selectedSchedule,
              'class_code',
              ''
            )}_Individual_Assessment ${lessonName}`

            folderZip.file(
              `${truncate(fileName, {
                length: 83,
                separator: /,? +/,
              })}.pdf`,
              doc.output('blob')
            )
          } else {
            folderZip.file(
              `${name}_${get(
                selectedSchedule,
                'class_code',
                ''
              )}_student workbook.pdf`,
              doc.output('blob')
            )
          }
        } catch {
          console.error('Something went wrong!')
        }
      }

      i++
    }
    folderZip.generateAsync({ type: 'blob' }).then(function (content) {
      if (isBellDomain) {
        const fileName = `${get(
          selectedSchedule,
          'class_code',
          ''
        )}_Individual_Assessment ${lessonName}`
        saveAs(content, fileName)
      } else {
        saveAs(
          content,
          `${get(selectedSchedule, 'class_code', '')}_Individual Workbook`
        )
      }
    })
  }

  return (
    <>
      <div className="popup-cta__wrap" onClick={generatePDF}>
        <div className="popup-cta__text">Student Workbook</div>
      </div>
      <div className="popup-cta__wrap" onClick={generateIndividualsWorkBook}>
        <div className="popup-cta__text">Individuals Workbook</div>
      </div>
    </>
  )
}
export default ReportBooks
