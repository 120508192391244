import React from 'react'
import styled from 'styled-components'
import PaymentMethod from '../../../../../container/Home/screens/CoursePreview/components/PaymentMethod'
import Button from '../Button'

const CourseEnrollWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 55px;
  padding-bottom: 55px;
  margin-top: 70px;

  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #666666;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #666666;
    padding-bottom: 20px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
`

const CourseEnroll = ({
  isMobile,
  handleEnroll,
  isEnroll,
  renderButtonText,
  isBellDomain,
}) => {
  return (
    <CourseEnrollWrap>
      <h3>Enroll Today!</h3>
      <h4>
        It’s never too late to learn something new. Enroll for this course
        today.{' '}
      </h4>
      {isBellDomain && isEnroll && (
        <Button
          title={renderButtonText()}
          color="#fff"
          bgColor="#FF0D6B"
          minWidth="352"
          height="50"
          fullWidth={isMobile}
          handleClick={handleEnroll}
        />
      )}
      {!isBellDomain && (
        <Button
          title={renderButtonText()}
          color="#fff"
          bgColor="#FF0D6B"
          minWidth="352"
          height="50"
          fullWidth={isMobile}
          handleClick={handleEnroll}
        />
      )}
    </CourseEnrollWrap>
  )
}

export default CourseEnroll
