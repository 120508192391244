import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableUser from '../../../components/TableUser'
import { makeSelectListUser } from '../../../selectors'
import Pagination from 'component/Pagination'

const UserManagementDefault = ({
  handleResendEmail,
  clients,
  pagination,
  setPagination,
  reloadUsers,
  setValue
}) => {
  const dispatch = useDispatch()
  const listUser = useSelector(makeSelectListUser())
  const [users, setUsers] = useState(listUser)

  useEffect(() => {
    if (listUser) {
      setUsers(listUser)
    }
  }, [listUser])

  return (
    <>
      <div className="u-manage__table-wrap">
        {listUser && (
          <TableUser
            users={users}
            dispatch={dispatch}
            setPagination={setPagination}
            setUsers={setUsers}
            reloadUsers={reloadUsers}
            handleResendEmail={handleResendEmail}
            clients={clients}
          />
        )}
      </div>
      <div style={{ margin: 20 }}>
        <Pagination
          user={true}
          page={pagination}
          perPage={50}
          listUser={listUser}
          setValue={setValue}
        />
      </div>
    </>
  )
}

export default UserManagementDefault
