import React, { useContext } from 'react'
import styles from './WhatsAppButton.module.css'
import WidgetContext from '../context/widgetContext'
import images from 'Themes/Images'

export default function WhatsAppButton() {
  const { handleOpen } = useContext(WidgetContext)

  return (
    <div className={styles.root} onClick={handleOpen}>
      <img src={images.whatapp} alt="" />
    </div>
  )
}
