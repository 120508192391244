import axios from 'axios'
import { get } from 'lodash'
import config from 'src/global-config'
import { showError } from '../../utils/notification'
export const getArticle = async (id) => {
  const requestURl = `${config.baseUrl}/api/cms/articles/${id}`
  try {
    const res = await axios.get(requestURl)
    return get(res, 'data.data')
  } catch (error) {
    console.log('error', error)
  }
}
export const getArticleBySlug = async (slug) => {
  const requestURl = `${config.baseUrl}/api/cms/articles?slug=${slug}`
  try {
    const res = await axios.get(requestURl)
    const article = get(res, 'data.data[0]', null)
    if (article) return article
    return null
  } catch (error) {
    console.log('error', error)
  }
}
export const getListArticle = async () => {
  const requestURl = `${config.baseUrl}/api/cms/articles?order_by=id%20asc&status=true`
  try {
    const res = await axios.get(requestURl)
    const data = get(res, 'data.data', [])
    return data
  } catch (error) {
    console.log('error', error)
  }
}
export const postArticle = async (data) => {
  const requestURl = `${config.baseUrl}/api/cms/articles`
  try {
    const res = await axios.put(requestURl, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const id = get(res, 'data.id')
    if (id) {
      return id
    }
    return false
  } catch (error) {
    return false
    console.log('error', error)
  }
}
export const deleteArticle = async (id) => {
  const requestURl = `${config.baseUrl}/api/cms/articles/${id}`
  try {
    const res = await axios.delete(requestURl)
  } catch (error) {
    console.log('error', error)
  }
}

export const uploadImage = async (file) => {
  const requestURl = `${config.uploadApi}/api/media`
  try {
    const data = new FormData()

    data.append('name', file.name)
    data.append('type', 'image')
    data.append('file', file)
    const image = await axios.post(requestURl, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return get(image, 'data.data')
  } catch (error) {
    showError(`Please double-check your file ${file.name || ''}`)
    return false
  }
}

export const uploadVideo = async (file) => {
  const requestURl = `${config.uploadApi}/api/media`
  const data = new FormData()

  data.append('name', file.name)
  data.append('type', 'video')
  data.append('file', file)
  const video = await axios.post(requestURl, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return get(video, 'data.data')
}

export const uploadPdf = async (file) => {
  const requestURl = `${config.uploadApi}/api/media`
  const data = new FormData()

  data.append('name', file.name)
  data.append('type', 'attachment')
  data.append('file', file)
  const pdf = await axios.post(requestURl, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return get(pdf, 'data.data')
}
