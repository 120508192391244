import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { LIST_CAT } from '../../constants'

const CatItemWrap = styled.div`
  width: 300px;
  height: 140px;
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  z-index: 2;

  margin-right: 30px;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #000000;
    opacity: ${(props) => (props.normalPage ? 0.2 : 0.3)};
  }

  @media (max-width: 767px) {
    margin-right: 15px;
    width: 280px;
    flex: 0 0 auto;
  }
  .cat_img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
    }
  }
  .cat_info {
    position: absolute;
    z-index: 4;
    bottom: 20px;
    left: 20px;
    width: 100%;
  }
  .cat_title {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80%;
    padding: 2px 0;
  }
  .cat_desc {
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
    padding-bottom: 2px;
  }
`

const CatItem = ({ cat, normalPage }) => {
  const history = useHistory()
  const catName = get(cat, 'name', '')
  const catDesc = get(cat, 'description', '')
  const handleClickCat = () => {
    return history.push(`/category/${catName.toLowerCase()}`)
  }

  return (
    <CatItemWrap onClick={handleClickCat} normalPage={normalPage}>
      <div className="cat_img">
        <img
          src={normalPage ? cat.image : get(LIST_CAT, `[${catName}].img`)}
          width="320"
          height="140"
          alt={catName}
        ></img>
      </div>
      <div className="cat_info">
        <div className="cat_title">{catName}</div>
        <div className="cat_desc">
          {normalPage ? catDesc : get(LIST_CAT, `[${catName}].desc`)}
        </div>
      </div>
    </CatItemWrap>
  )
}

export default CatItem
