import { createSelector } from 'reselect'

/**
 * Direct selector to the authorize state domain
 */

const selectSuperAdminDomain = (state) => state.superAdmin

export const makeSelectListCourseOfSeller = () =>
  createSelector(
    selectSuperAdminDomain,
    (subState) => subState.listCourseOfSeller
  )

export const makeSelectListCollection = () =>
  createSelector(selectSuperAdminDomain, (subState) => subState.listCollection)

export const makeSelectDeleteLead = () =>
  createSelector(selectSuperAdminDomain, (subState) => subState.deleteLead)
