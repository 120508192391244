import React from 'react'
import { Row, Col, Radio, DatePicker, Checkbox } from 'antd'
import Dropdown from 'react-dropdown'
import { Link } from 'react-router-dom'

import Input from 'component/Input'
import { initialNational, initialLevel } from 'utils/constants'

const ProfileUnicorn = ({
  formik,
  handleNumberInput,
  setTermCondition,
  termCondition,
  isClassCompareDomain,
}) => {
  const linkTerm = isClassCompareDomain ? '/terms?school_id=4' : '/terms'
  return (
    <>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">
            Full Name (Ensure name is exactly according to NRIC to be eligible
            for grant funding):
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="name"
              placeHolder="Enter your full name"
              formik={formik}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Gender:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Radio.Group
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
              value={formik.values.gender}
              name="add-course__radio-type"
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
            {formik.touched.gender && formik.errors.gender && (
              <div className="form__error">{formik.errors.gender}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Date of Birth:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__date-picker">
            <DatePicker
              onChange={(date, dateString) => {
                formik.setFieldValue('date_of_birth', date)
              }}
              allowClear
              value={formik.values.date_of_birth || ''}
              placeholder="Date of Birth"
              className={`sc-modal__datepicker ${
                formik.touched.date_of_birth &&
                formik.errors.date_of_birth &&
                'datepicker__error'
              }`}
            />
            {formik.touched.date_of_birth && formik.errors.date_of_birth && (
              <div className="form__error">{formik.errors.date_of_birth}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Nationality:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Dropdown
              options={initialNational}
              onChange={(value) =>
                formik.setFieldValue('nationality', value.label)
              }
              value={formik.values.nationality}
              placeholder="Select Nationality"
              className="dropdownScheduleModal dropdown"
            />

            {formik.values.nationality === 'Other' && (
              <div style={{ marginTop: 20 }}>
                <Input
                  name="other_nationality"
                  placeHolder="Type another nationality here"
                  formik={formik}
                  value={formik.values.other_nationality}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            {formik.touched.nationality && formik.errors.nationality && (
              <div className="form__error">{formik.errors.nationality}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Race:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="race"
              placeHolder="Enter Race"
              formik={formik}
              value={formik.values.race}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">ID/Passport No. (eg. NRIC):</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="passport"
              placeHolder="Enter ID/Passport"
              formik={formik}
              value={formik.values.passport}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Phone No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="phone"
              placeHolder="Enter Phone No."
              formik={formik}
              value={formik.values.phone}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'phone')
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Emergency Contact:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="emergency_contact"
              placeHolder="Enter Emergency Phone No."
              formik={formik}
              value={formik.values.emergency_contact}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'emergency_contact')
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Postal Address:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="address"
              placeHolder="Enter Postal Address"
              formik={formik}
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Unit No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <Input
            name="unit_number"
            placeHolder="Enter Unit No. (eg. #05-05)"
            formik={formik}
            value={formik.values.unit_number}
            onChange={formik.handleChange}
          />
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Postal Code:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Input
              name="postTalCode"
              placeHolder="Enter Postal Code"
              formik={formik}
              value={formik.values.postTalCode}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Occupation:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="occupation"
              placeHolder="Enter Occupation"
              formik={formik}
              value={formik.values.occupation}
              onChange={formik.handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Highest Education Level:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Dropdown
              options={initialLevel}
              onChange={(value) =>
                formik.setFieldValue('education_level', value.label)
              }
              value={formik.values.education_level}
              placeholder="Select Education Level"
              className="dropdownScheduleModal dropdown"
            />

            {(formik.values.education_level === 'Other' ||
              formik.values.education_level === 'GCE N-Level') && (
              <div style={{ marginTop: 20 }}>
                <Input
                  name="other_level"
                  placeHolder={
                    formik.values.education_level === 'GCE N-Level'
                      ? 'Relevant Work Experience'
                      : 'Type another education level here'
                  }
                  formik={formik}
                  value={formik.values.other_level}
                  onChange={formik.handleChange}
                />
              </div>
            )}
            {formik.touched.education_level &&
              formik.errors.education_level && (
                <div className="form__error">
                  {formik.errors.education_level}
                </div>
              )}
          </div>
        </Col>
      </Row>

      {/* <Row className="profile__row" justify="center">
        <Col xs={24} md={15}>
          <div className="profile__name">
            How many of years of experience do you have as an investor?:
          </div>
        </Col>
        <Col xs={24} md={5}>
          <div className="profile__input">
            <Input
              name="year_exp"
              placeHolder="Years Of Experience"
              formik={formik}
              value={formik.values.year_exp}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'year_exp')
              }
            />
          </div>
        </Col>
      </Row> */}

      <Row className="profile__row" justify="center">
        <Col xs={24} md={20}>
          <Checkbox onChange={() => setTermCondition(!termCondition)}>
            I agree to the{' '}
            <Link to={linkTerm}>
              <span className="profile__highlight">Terms & Conditions</span>
            </Link>{' '}
            and{' '}
            <Link to={linkTerm}>
              <span className="profile__highlight">
                Personal Data Protection Act – Consent Form
              </span>
            </Link>
          </Checkbox>
        </Col>
      </Row>
      <Row className="profile__row" justify="center">
        <Col xs={24} md={20}>
          <Checkbox>
            I hereby declare that I’m physically residing in Singapore
            throughout this period of the course.
          </Checkbox>
        </Col>
      </Row>
    </>
  )
}

export default ProfileUnicorn
