import React, { useEffect } from 'react'
import CheckoutProgressBar from '../../components/CheckoutProgressBar'
import ProgressBtn from '../../components/ProgressBtn'
import SelectSchedule from '../../components/SelectSchedule'
import { showError } from 'utils/notification'
import './styles.scss'
import { setSelectedSchedule } from '../../actions'

const Confirm = ({
  dispatch,
  schedules,
  course,
  handleNextStep,
  isUnicornDomain,
  selectedSchedule,
  firstMediaDomain,
  isArtofnumbersDomain,
  cyberQuoteDomain,
  requiredSchedule
}) => {
  useEffect(() => {
    dispatch(setSelectedSchedule(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <CheckoutProgressBar
        indexActive={1}
        isUnicornDomain={isUnicornDomain}
        cyberQuoteDomain={cyberQuoteDomain}
        firstMediaDomain={firstMediaDomain}
        isArtofnumbersDomain={isArtofnumbersDomain}
        requiredSchedule={requiredSchedule}
      />
      <div className="hs-confirm">
        <div className="hs-confirm__head">
          <h3 className="hs-confirm__heading">Confirm schedule</h3>
          <p className="hs-confirm__text">
            Select a schedule that works for you. This cannot be changed after
            payment.
          </p>
        </div>
        <SelectSchedule
          course={course}
          schedules={schedules}
          selectedSchedule={selectedSchedule}
        />
      </div>
      <ProgressBtn
        selectedSchedule={selectedSchedule}
        handleNextStep={
          selectedSchedule
            ? handleNextStep
            : () => {
                showError('Please select at least one schedule!')
              }
        }
      />
    </>
  )
}

export default Confirm
