import React, { useEffect, useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import { Form, Input, Modal, Radio, Space, message } from 'antd'
import './styles.scss'

import styled from 'styled-components'
import BtnPrimary from '../../../../component/BtnPrimary'
import GroupSelect from './GroupSelect'
import { isEmpty, omit } from 'lodash'
import { useHistory, useLocation } from 'react-router'
import { parse } from 'query-string'

const AddGroupWrapper = styled.div``

const AddGroup = ({
  data,
  reload,
  isEdit,
  setIsEdit,
  selectedGroup,
  setSelectedGroup,
  visible,
  setVisible,
}) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedType, setSelectedType] = useState(null)
  const { search } = useLocation()
  const parsedValue = parse(search)

  const onFinish = async (values) => {
    try {
      let payload = {
        name: values.name,
      }

      if (!selectedGroups) {
        payload = {
          name: values.name,
          level: 1,
        }
      } else {
        payload = {
          name: values.name,
          parent_id:
            selectedGroups?.length === 1
              ? selectedGroups[0]
              : selectedGroups[1],
          level:
            selectedGroups?.length + 1 > 3 ? 3 : selectedGroups?.length + 1,
        }
      }

      if (isEdit && isEmpty(selectedGroups) && selectedGroup) {
        payload = {
          ...payload,
          level: 1,
          type: 'client',
        }
      }

      if (isEdit && selectedGroup) {
        payload = { ...payload, id: selectedGroup.id }
      }
      if (isEdit && payload.id === payload.parent_id) {
        payload = omit(payload, ['parent_id'])
        payload = { ...payload, level: selectedGroup.level }
      }

      if (selectedType) {
        payload = { ...payload, type: selectedType }
      }
      await axiosInstance.put('/api/folders', payload)

      if (isEdit) {
        message.success('Edit group successfully!')
      } else {
        message.success('Create group successfully!')
      }
      reload()
      setVisible(false)
      setIsEdit(false)
      setSelectedGroup(false)
      setSelectedGroups(null)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (isEdit && selectedGroup) {
      if (selectedGroup.level === 3) {
        const groupLevel2 = data?.find(
          (item) => item.id === selectedGroup.parent_id
        )

        form.setFieldsValue({
          name: selectedGroup?.name,
          group: [groupLevel2?.parent_id, groupLevel2?.id, selectedGroup?.id],
        })
      } else {
        form.setFieldsValue({
          name: selectedGroup?.name,
          group:
            selectedGroup?.level === 1
              ? [selectedGroup?.id]
              : [selectedGroup?.parent_id, selectedGroup?.id],
        })
      }
      form.setFieldsValue({
        type: selectedGroup?.type,
      })
    } else if (parsedValue && parsedValue.level1) {
      form.setFieldsValue({
        name: '',
        type: '',
        group: [
          parsedValue?.level1 && parseInt(parsedValue?.level1),
          parsedValue?.level2 && parseInt(parsedValue?.level2),
        ].filter((item) => item),
      })
    }
  }, [isEdit, selectedGroup, data, parsedValue.level1, parsedValue.level2])

  const onChangeRadio = ({ target: { value } }) => {
    setSelectedType(value)
  }

  return (
    <AddGroupWrapper>
      <Space>
        <BtnPrimary
          name="Add Group"
          style={{
            padding: '8px 10px',
          }}
          handleClick={() => setVisible(true)}
        />
        <BtnPrimary
          name="Add User"
          style={{
            padding: '8px 10px',
          }}
          handleClick={() => {
            return history.push({
              pathname: '/admin/user-management/create',
              search,
            })
          }}
        />
      </Space>
      {visible && (
        <Modal
          title={isEdit ? 'Edit Group' : 'Create Group'}
          visible={visible}
          okText="Save"
          onCancel={() => {
            setVisible(false)
            setSelectedGroup(null)
          }}
          onOk={() => form.submit()}
        >
          <Form
            name="basic"
            style={{ maxWidth: 400 }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input placeholder="Group Name" />
            </Form.Item>
            <Form.Item
              label="Parent Group"
              name="group"
              getValueFromEvent={(value) => {
                setSelectedGroups(value)
                return value
              }}
            >
              <GroupSelect
                placeholder="Group Select"
                data={data}
                multiple={false}
                disabledLevel3
              />
            </Form.Item>
            <Form.Item label="Group Type" name="type" buttonStyle="solid">
              <Radio.Group onChange={onChangeRadio} value={selectedType}>
                <Radio value="client">Client</Radio>
                <Radio value="course">Course</Radio>
                <Radio value="class">Class</Radio>
                <Radio value="role">Role</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </AddGroupWrapper>
  )
}

export default AddGroup
