import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, orderBy } from 'lodash'

import images from 'Themes/Images'
import config from 'src/global-config'
import { loadAttachment } from 'container/Admin/actions'
import { makeSelectAttachments } from 'container/Admin/selectors'
import { loadAttachmentSuccess } from 'container/Admin/actions'

import './styles.scss'

const Attachments = ({ selectedSlide }) => {
  const dispatch = useDispatch()
  const attachments = useSelector(makeSelectAttachments())

  useEffect(() => {
    if (selectedSlide) {
      dispatch(loadAttachment())
    }
    return () => {
      dispatch(loadAttachmentSuccess(null))
    }
  }, [selectedSlide])

  const handleDownloadLink = (sUrl, name) => {
    fetch(sUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  }

  return (
    Array.isArray(attachments) &&
    attachments.length > 0 && (
      <div className="additional">
        <div className="additional__heading">Lesson Downloads</div>
        <div className="additional__link">
          {attachments &&
            orderBy(attachments, 'id', 'desc').map((item) => {
              const attachmentId = get(item, 'attachments.id', '')
              const url = `${config.baseUrl}/web/content/${attachmentId}?download=true`
              const name = get(item, 'name', '')
              return (
                <a
                  className="additional__item"
                  onClick={() => handleDownloadLink(url, name)}
                  key={item.id}
                >
                  <div className="additional__item__text">{name}</div>
                  <div className="additional__item__icon">
                    <img src={images.admin.download} alt="" />
                  </div>
                </a>
              )
            })}
        </div>
      </div>
    )
  )
}

export default Attachments
