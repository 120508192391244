import React, { useState, useEffect, useMemo } from 'react'
import { get } from 'lodash'
import { Row, Col, Select } from 'antd'
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive'

import images from 'Themes/Images'
import Competent from './components/Competent'
import UserAnswer from './components/UserAnswer'
import BtnPrimary from 'component/BtnPrimary'
import NotCompetent from './components/NotCompetent'
import { showMarkingModal } from 'container/Modal/actions'
import { makeSelectSelectedQuiz } from 'container/Home/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { mapScheduleToDropDown } from 'utils/helper'
import Reports from '../Reports'
import { makeSelectCourse, makeSelectDomain } from 'container/Home/selectors'
import { getUserAnswerTimes, resetQuiz } from 'utils/hooks'
import './styles.scss'
import config from 'src/global-config'
import { getAccessToken } from 'utils/request'
import { loadListUserAnswer } from '../../actions'
import { markAnswerCompetent } from 'container/Admin/actions'
import { makeSelectCurrentUser } from '../../../Auth/selectos'
import { useHistory, useLocation } from 'react-router'
import { parse } from 'query-string'
import { DOMAIN } from 'src/routes'

const QuizReviews = ({
  slideDetails,
  listUserAnswer,
  schedules,
  selectedSchedule,
  setSelectedSchedule,
}) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const parsedSearch = parse(search)

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const dispatch = useDispatch()
  const [currentUser, setCurrentUser] = useState(null)
  const [userAnswer, setUserAnswer] = useState(null)
  const [remarks, setRemarks] = useState([])
  const competent = get(userAnswer, '[0].user.answer.competent', null)
  const [switchTab, setSwitchTab] = useState(false)
  const selectedQuiz = useSelector(makeSelectSelectedQuiz())
  const course = useSelector(makeSelectCourse())
  const questions = get(slideDetails, 'questions', [])
  const questionsLength = get(questions, 'length')
  const domain = useSelector(makeSelectDomain())

  const isUnicornOrBell =
    get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION ||
    get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN

  const markedUserAnswer = useMemo(() => {
    if (listUserAnswer[currentUser])
      return listUserAnswer[currentUser].reduce(
        (curr, prev) => (curr + prev.competent === null ? 0 : 1),
        0
      )
  }, [currentUser, listUserAnswer])
  const countCharacters = (str) => {
    const cleanedStr = str.replace(/\s/g, '')
    return cleanedStr.length
  }
  const listUserAnswerMapping = useMemo(() => {
    return questions?.reduce((acc, curr) => {
      const existingAnswer = listUserAnswer[currentUser]?.filter(
        (item) => item?.question_id === curr?.id
      )
      const nextAnswer =
        existingAnswer?.length > 1
          ? existingAnswer.sort(
              (a, b) => new Date(b.write_date) - new Date(a.write_date)
            )?.[0]
          : existingAnswer?.[0]
      if (existingAnswer) {
        return [...acc, { ...nextAnswer }]
      }
      return acc
    }, [])
  }, [questions, listUserAnswer, currentUser])
  const isCheckRemark = useMemo(() => {
    // const isSelectedQuestions = remarks?.filter((item) =>
    //   listUserAnswerMapping?.find((i) => i?.question_id === item?.question_id)
    // )
    const isSelectedQuestions = listUserAnswerMapping?.reduce((prev, curr) => {
      const dataMarks = remarks?.filter(
        (i) => i?.question_id === curr?.question_id
      )

      if (dataMarks?.length >= 2) {
        const nextData =
          dataMarks?.find((x) => countCharacters(x?.remark) >= 3) ||
          dataMarks?.find((x) => x?.remark) ||
          dataMarks?.[0]

        // Check if an item with the same question_id already exists in the prev array
        const isDuplicate = prev.find(
          (item) => item.question_id === curr.question_id
        )

        if (nextData && !isDuplicate) {
          return [...prev, nextData]
        } else {
          return prev
        }
      }
      return [...prev, ...dataMarks]
    }, [])

    return isSelectedQuestions?.every(
      (item) => countCharacters(item?.remark) >= 3
    )
  }, [listUserAnswerMapping, remarks])

  const visibleMarkedBtn = questionsLength >= markedUserAnswer

  let listUserNotComplete = {}
  const requiredSchedule = get(course, 'is_schedule', false)
  const adminUser = useSelector(makeSelectCurrentUser())

  const initialSelectSchedule = mapScheduleToDropDown(
    schedules,
    false,
    false,
    true
  )
  if (listUserAnswer && questions) {
    for (let key in listUserAnswer) {
      if (
        Array.isArray(listUserAnswer[key]) &&
        listUserAnswer[key].length > 0 &&
        get(listUserAnswer[key][0], 'user.answer.competent') === false
      ) {
        listUserNotComplete = {
          ...listUserNotComplete,
          [key]: listUserAnswer[key],
        }
      }
    }
  }

  useEffect(() => {
    if (Array.isArray(schedules) && parsedSearch && parsedSearch.schedule) {
      const schedule = schedules.find(
        (item) => item.id === parseInt(parsedSearch.schedule)
      )
      if (schedule) {
        const id = get(schedule, 'id')
        const classCode = get(schedule, 'class_code', '')
        const startDate = get(schedule, 'start_date', '')
        const endDate = get(schedule, 'end_date', '')
        let label = `(${classCode}) ${dayjs(startDate).format(
          'DD MMM'
        )} - ${dayjs(endDate).format('DD MMM YYYY')}`
        const duration = dayjs(endDate).diff(startDate, 'year')
        if (duration === 10) {
          label = `(${classCode}) Always Available`
        }
        const selectedScheduleData = { value: id, label, class_code: classCode }
        setSelectedSchedule(selectedScheduleData)
      }
    }
  }, [parsedSearch.schedule, schedules])

  useEffect(() => {
    if (currentUser && listUserAnswer) {
      const answer = listUserAnswer[currentUser]
      setUserAnswer(answer)
    } else {
      setUserAnswer(null)
    }
  }, [listUserAnswer])

  useEffect(() => {
    if (userAnswer) {
      setRemarks(
        userAnswer?.map((item) => ({
          ...item,
        }))
      )
    }
  }, [userAnswer])

  const handleSelectUser = (item) => {
    setCurrentUser(item)
    setUserAnswer(listUserAnswer[item])
  }

  const handleClickMark = () => {
    if (listUserAnswerMapping) {
      dispatch(
        showMarkingModal(
          listUserAnswerMapping,
          selectedSchedule,
          currentUser,
          remarks
        )
      )
    }
  }

  const updateUserAnswer = async (userId, questionId, body) => {
    try {
      const requestUrl = `${config.baseUrl}/api/users/${userId}/questions/${questionId}/answers`
      const res = await fetch(requestUrl, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      await res.json()
    } catch (error) {}
  }

  const handleResetQuiz = async () => {
    const requests = []
    for (let i = 0; i < userAnswer.length; i++) {
      if (userAnswer[i].type === '') {
        const userId = userAnswer[i].user_id
        const question_id = userAnswer[i].question_id
        const body = { ...userAnswer[i], value: '', competent: null }
        delete body.user
        requests.push(updateUserAnswer(userId, question_id, body))
      }
    }

    await Promise.all(requests)

    const userId = get(listUserAnswer, `[${currentUser}][0].user.id`)
    const userAnswerTimes = await getUserAnswerTimes(userId)
    const selectedAnswerTime =
      Array.isArray(userAnswerTimes) &&
      userAnswerTimes.find((item) => item.slide_id === get(slideDetails, 'id'))

    await resetQuiz(
      userId,
      get(slideDetails, 'id'),
      get(selectedAnswerTime, 'id')
    )
    dispatch(
      markAnswerCompetent({
        user_id: get(userAnswer, '[0].user_id', ''),
        slide_id: get(userAnswer, '[0].slide_id', ''),
        competent: null,
        selectedSchedule,
        currentUser: adminUser,
        percent_quiz_pass: '',
        total_passed: 0,
      })
    )
    dispatch(loadListUserAnswer(get(selectedSchedule, 'value', 0)))
  }

  return (
    <>
      <div
        className="channel"
        style={{ margin: 0, minHeight: 'calc(100vh + 244px)' }}
      >
        <div className="channel__head">
          <div className="channel__head__left">
            <div className="channel__chapter">
              LESSON: {get(slideDetails, 'name', '')}
            </div>
            <div className="channel__title">
              Quiz {`(${questions.length} questions) `}
            </div>
          </div>
          <div
            className="channel__head__right"
            style={{ alignItems: 'center' }}
          >
            {requiredSchedule && (
              <div
                className="channel__head__right__button"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div
                  style={{
                    marginRight: 15,
                    whiteSpace: 'nowrap',
                    fontWeight: '600',
                    fontSize: 12,
                    color: '#27284D',
                  }}
                >
                  Select batch
                </div>
                <div className="dropdown__wrapper">
                  {initialSelectSchedule && (
                    <Select
                      className="selectSchedule"
                      showSearch
                      placeholder="Select a schedule"
                      optionFilterProp="children"
                      onChange={(value) => {
                        history.push(pathname + `?quiz=true&schedule=${value}`)
                      }}
                      value={selectedSchedule ? selectedSchedule : null}
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={initialSelectSchedule}
                    />
                  )}
                </div>
              </div>
            )}

            <div
              style={{
                marginLeft: isMobile ? 0 : 10,
                marginTop: isMobile ? 10 : 0,
              }}
            >
              <Reports
                listUserAnswer={listUserAnswer}
                questions={questions}
                course={course}
                slideDetails={slideDetails}
                selectedSchedule={selectedSchedule}
              />
            </div>
          </div>
        </div>

        <div
          className="quiz__body-2 quiz__border-top"
          style={{ minHeight: 'calc( 100vh + 140px )' }}
        >
          {selectedQuiz ? (
            <Row style={{ width: '100%' }}>
              <Col xs={24} md={8}>
                <div className="quiz__left-2">
                  <div
                    className="quiz__question"
                    style={{ borderBottom: '1px solid #d0cad8' }}
                  >
                    <div className="quiz__summary">
                      <div
                        className={`quiz__summary__section ${
                          switchTab ? 'quiz__summary__section-active' : ''
                        }`}
                        onClick={() => setSwitchTab(false)}
                      >
                        <div className="quiz__summary__number">
                          {(listUserAnswer &&
                            Object.keys(listUserAnswer).length) ||
                            0}
                        </div>
                        <div className="quiz__summary__label">All students</div>
                      </div>
                      <div
                        className={`quiz__summary__section ${
                          !switchTab ? 'quiz__summary__section-active' : ''
                        }`}
                        onClick={() => setSwitchTab(true)}
                      >
                        <div className="quiz__summary__number">
                          {listUserNotComplete &&
                            Object.keys(listUserNotComplete).length}
                        </div>
                        <div className="quiz__summary__label">
                          Not competent
                        </div>
                      </div>
                    </div>
                  </div>
                  {!switchTab ? (
                    <div className="quiz__list-user">
                      {listUserAnswer &&
                        Object.keys(listUserAnswer).map((item, idx) => (
                          <Competent
                            key={idx}
                            handleSelectUser={handleSelectUser}
                            item={item}
                            listUserAnswer={listUserAnswer}
                            questions={questions}
                            currentUser={currentUser}
                          />
                        ))}
                    </div>
                  ) : (
                    <NotCompetent
                      handleSelectUser={handleSelectUser}
                      listUserNotComplete={listUserNotComplete}
                      questions={questions}
                    />
                  )}
                </div>
              </Col>

              {!isMobile && (
                <div className="quiz__vertical__line quiz__vertical__line-2" />
              )}
              <Col xs={24} md={16}>
                <div
                  className="quiz__right-2"
                  style={{ backgroundColor: '#fff' }}
                >
                  {userAnswer && questions && remarks && (
                    <>
                      <div className="quiz__right-answer">
                        {questions.map((item, i) => (
                          <UserAnswer
                            key={i}
                            item={item}
                            i={i}
                            userAnswer={userAnswer}
                            selectedSchedule={selectedSchedule}
                            remarks={remarks}
                            setRemarks={setRemarks}
                            isUnicornOrBell={isUnicornOrBell}
                          />
                        ))}
                      </div>

                      <div className="quiz__right__bottom">
                        <BtnPrimary
                          style={{
                            padding: '12px 20px',
                            marginRight: 10,
                          }}
                          type="button"
                          handleClick={handleResetQuiz}
                          name="Reset Quiz"
                        />
                        <BtnPrimary
                          style={
                            (
                              isUnicornOrBell
                                ? visibleMarkedBtn && isCheckRemark
                                : visibleMarkedBtn
                            )
                              ? {
                                  padding: '12px 20px',
                                }
                              : {
                                  backgroundColor: '#B3B8BF',
                                  padding: '12px 20px',
                                  cursor: 'not-allowed',
                                }
                          }
                          disabled={
                            isUnicornOrBell
                              ? !visibleMarkedBtn || !isCheckRemark
                              : !visibleMarkedBtn
                          }
                          type="button"
                          handleClick={handleClickMark}
                          name={
                            competent !== null ? 'Submitted' : 'Submit marks'
                          }
                          icon={
                            competent !== null
                              ? images.header.check
                              : images.header.check_inactive
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                minHeight: 150,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              No quiz for now, please select question!
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default QuizReviews
