import React, { useEffect, useMemo, useState } from 'react'
import { parse, stringify } from 'query-string'

import { AdminLayout } from 'src/styles'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/helper'
import { get, isEmpty, uniqBy } from 'lodash'
import HeaderPage from './Header'
import { Popconfirm, Space, Table, message } from 'antd'
import { FolderOutlined, UserOutlined } from '@ant-design/icons'
import './styles.scss'
import AddGroup from './AddGroup'
import { useHistory, useLocation } from 'react-router'
import { isAdminRole, isTrainerRole } from '../../../../utils/userUtils'

const defaultFilter = {
  page: 1,
  per_page: 10000,
  ignore_per_page: true,
  order_by: 'id desc',
  includeUser: true,
}

export const getGroupByLevel = (arr, level, parentLevel) => {
  if (!arr) {
    return []
  }
  return arr?.filter((item) => {
    if (parentLevel) {
      return item?.level === level && item?.parent_id === parentLevel
    }
    return item?.level === level
  })
}
export const getAllUserByClient = (arr, parentID) => {
  if (!arr) {
    return []
  }
  let arrayAccount = []
  arr.forEach((item) => {
    if (item?.parent_id === parentID) {
      arrayAccount = [...arrayAccount, ...item.user_folders]
      arr.forEach((item2) => {
        if (item2.parent_id === item.id) {
          arrayAccount = [...arrayAccount, ...item2.user_folders]
        }
      })
    }
  })

  return arrayAccount
}

export const getUserByRole = (arr, role) => {
  if (!arr) {
    return []
  }

  return uniqBy(
    arr?.filter((item) => {
      const userGroups = get(item, 'res_users.user_groups', [])
      const isAdmin = isAdminRole(userGroups)
      const isTrainer = isTrainerRole(userGroups)

      if (role === 'Learners' && !isAdmin && !isTrainer) {
        return item
      }
      if (role === 'Admin' && isAdmin) {
        return item
      }
      if (role === 'Trainers' && isTrainer) {
        return item
      }
    }),
    'res_users.id'
  )
}

const ClassManagement = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const query = parse(search)
  const [data, setData] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [visible, setVisible] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selectedLevel1, setSelectedLevel1] = useState()
  const [selectedLevel2, setSelectedLevel2] = useState()
  const [selectedLevel3, setSelectedLevel3] = useState()

  const usersFolder = useMemo(
    () => get(selectedLevel3, 'user_folders', null),
    [selectedLevel3]
  )
  const userFoldersLevel2 = get(selectedLevel2, 'user_folders', [])
  const groupLevel1 = getGroupByLevel(data, 1)
  const groupLevel2 = getGroupByLevel(data, 2, get(selectedLevel1, 'id'))
  const groupLevel3 = getGroupByLevel(data, 3, get(selectedLevel2, 'id'))

  const groupLevelRole = useMemo(() => {
    return selectedLevel1 && getAllUserByClient(data, get(selectedLevel1, 'id'))
  }, [data, selectedLevel1])

  const learners = useMemo(() => {
    return (
      Array.isArray(groupLevelRole) &&
      !isEmpty(groupLevelRole) &&
      getUserByRole(groupLevelRole, get(selectedLevel2, 'name'))
    )
  }, [groupLevelRole, selectedLevel2])

  const handlePushQuery = (values) => {
    const queryParams = stringify(values)
    history.push({ pathname: pathname, search: queryParams })
  }
  useEffect(() => {
    if (query) {
      if (query?.level1) {
        setSelectedLevel1(
          data?.find((item) => item.id === parseInt(query?.level1))
        )
      } else {
        setSelectedLevel1(null)
      }

      if (query?.level2) {
        setSelectedLevel2(
          data?.find((item) => item.id === parseInt(query?.level2))
        )
      } else {
        setSelectedLevel2(null)
      }
      if (query?.level3) {
        setSelectedLevel3(
          data?.find((item) => item.id === parseInt(query?.level3))
        )
      } else {
        setSelectedLevel3(null)
      }
    }
  }, [data, query])

  const getFolders = async (reloadUsers) => {
    try {
      const params = stringify({ ...defaultFilter })
      const res = await axiosInstance.get(`/api/folders?${params}`)
      const data = getResponseData(res)

      setData(data)
      if (reloadUsers) {
        if (selectedLevel3) {
          setSelectedLevel3(data?.find((item) => item.id === selectedLevel3.id))
        }
      }
    } catch (error) {
      setData([])
      console.log('error', error)
    }
  }

  useEffect(() => {
    getFolders()
  }, [])

  const columnsTableLevel1 = [
    {
      title: 'Name',
      width: '45%',
      render: (item) => {
        return (
          <div
            className="flex-c pointer"
            onClick={() => {
              handlePushQuery({ ...query, level1: item.id })
            }}
          >
            <div>
              <FolderOutlined
                style={{ fontSize: '28px', paddingRight: '12px' }}
              />
            </div>
            <div>
              <div style={{ color: '#6060FF' }}>{item?.name}</div>
              <div style={{ textTransform: 'capitalize' }}>{item?.type}</div>
            </div>
          </div>
        )
      },
    },

    {
      title: 'User Count',
      width: '20%',
      render: (record) => {
        const totalUsers = record && getAllUserByClient(data, get(record, 'id'))
        return <span>{totalUsers.length ? totalUsers?.length : '-'}</span>
      },
    },
    {
      title: 'Action',
      width: '30%',
      render: (record) => {
        return (
          <div className="action__wrap" style={{ cursor: 'pointer' }}>
            <Space>
              <div
                onClick={() => {
                  setIsEdit(true)
                  setVisible(true)
                  setSelectedGroup(record)
                }}
              >
                Edit
              </div>{' '}
              |{' '}
              <div>
                <Popconfirm
                  placement="topLeft"
                  title="Do you want to delete this group?"
                  onConfirm={() => handleDeleteGroup(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="red underline pointer">Delete</div>
                </Popconfirm>
              </div>
            </Space>
          </div>
        )
      },
    },
  ]

  const columnsTableLevel2 = [
    {
      title: 'Name',
      width: '45%',
      render: (item) => {
        return (
          <div
            className="flex-c pointer"
            onClick={() => {
              handlePushQuery({ ...query, level2: item.id })
            }}
          >
            <div>
              <FolderOutlined
                style={{ fontSize: '28px', paddingRight: '12px' }}
              />
            </div>
            <div>
              <div style={{ color: '#6060FF' }}>{item?.name}</div>
              <div style={{ textTransform: 'capitalize' }}>{item?.type}</div>
            </div>
          </div>
        )
      },
    },

    {
      title: 'User Count',
      width: '20%',
      render: (record) => {
        if (Array.isArray(groupLevelRole) && groupLevelRole?.length > 0) {
          if (record.name === 'Learners') {
            const totalUsers = getUserByRole(groupLevelRole, 'Learners')
            return <span>{totalUsers?.length || '-'}</span>
          }
          if (record.name === 'Admin') {
            const totalUsers = getUserByRole(groupLevelRole, 'Admin')
            return <span>{totalUsers?.length || '-'}</span>
          }
          if (record.name === 'Trainers') {
            const totalUsers = getUserByRole(groupLevelRole, 'Trainers')
            return <span>{totalUsers?.length || '-'}</span>
          }
        }
        const groupData =
          Array.isArray(data) &&
          data?.filter(
            (item) => item.level === 3 && item?.parent_id === record?.id
          )
        const usersCount = groupData?.reduce(
          (acc, curr) => acc + curr?.user_folders?.length,
          0
        )
        return <span>{usersCount + record?.user_folders?.length || '-'}</span>
      },
    },
    {
      title: 'Action',
      width: '30%',
      render: (record) => {
        return (
          <div className="action__wrap" style={{ cursor: 'pointer' }}>
            <Space>
              <div
                onClick={() => {
                  setIsEdit(true)
                  setVisible(true)
                  setSelectedGroup(record)
                }}
              >
                Edit
              </div>{' '}
              |{' '}
              <div>
                <Popconfirm
                  placement="topLeft"
                  title="Do you want to delete this group?"
                  onConfirm={() => handleDeleteGroup(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="red underline pointer">Delete</div>
                </Popconfirm>
              </div>
            </Space>
          </div>
        )
      },
    },
  ]

  const columnsTableLevel3 = [
    {
      title: 'Name',
      width: '45%',
      render: (item) => {
        const userGroups = get(item, 'res_users.user_groups', [])
        const isAdmin = isAdminRole(userGroups)
        const isTrainer = isTrainerRole(userGroups)
        const isAdminAndTrainer = isAdmin && isTrainer
        let label = 'Learner'

        if (isAdminAndTrainer) {
          label = 'Admin, Trainer'
        } else if (isAdmin) {
          label = 'Admin'
        } else if (isTrainer) {
          label = 'Trainer'
        }
        const user = get(item, 'res_users')
        if (user) {
          return (
            <div
              className="flex-c pointer"
              onClick={() => {
                setSelectedLevel3(item)
              }}
            >
              <div>
                <UserOutlined
                  style={{ fontSize: '28px', paddingRight: '12px' }}
                />
              </div>
              <div>
                <div style={{ color: '#051B37', fontWeight: 'bold' }}>
                  {item?.res_users?.partner?.name || ''}
                </div>
                <div style={{ color: '#6060FF' }}>{item?.res_users?.login}</div>
                <div>{label}</div>
              </div>
            </div>
          )
        }
        return (
          <div
            className="flex-c pointer"
            onClick={() => {
              handlePushQuery({ ...query, level3: item.id })
            }}
          >
            <div>
              <FolderOutlined
                style={{ fontSize: '28px', paddingRight: '12px' }}
              />
            </div>
            <div>
              <div style={{ color: '#6060FF' }}>{item?.name}</div>
              <div style={{ textTransform: 'capitalize' }}>{item?.type}</div>
            </div>
          </div>
        )
      },
    },

    {
      title: 'User Count',
      width: '20%',
      render: (record) => {
        return (
          <span>
            {record?.user_folders?.length ? record?.user_folders?.length : '-'}
          </span>
        )
      },
    },
    {
      title: 'Action',
      width: '30%',
      render: (record) => {
        return (
          <div className="action__wrap" style={{ cursor: 'pointer' }}>
            <Space>
              <div
                onClick={() => {
                  if (record?.res_users) {
                    window.open(
                      `/admin/user-management/create?user=${record?.res_users.id}`,
                      '_blank'
                    )
                  } else {
                    setIsEdit(true)
                    setVisible(true)
                    setSelectedGroup(record)
                  }
                }}
              >
                Edit
              </div>{' '}
              |{' '}
              <div>
                <Popconfirm
                  placement="topLeft"
                  title="Do you want to delete this record?"
                  onConfirm={() => handleDeleteGroup(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="red underline pointer">Delete</div>
                </Popconfirm>
              </div>
            </Space>
          </div>
        )
      },
    },
  ]

  const columnsTableStudent = [
    {
      title: 'Name',
      width: '45%',
      render: (item) => {
        const userGroups = get(item, 'res_users.user_groups', [])
        const isAdmin = isAdminRole(userGroups)
        const isTrainer = isTrainerRole(userGroups)
        const isAdminAndTrainer = isAdmin && isTrainer
        let label = 'Learner'

        if (isAdminAndTrainer) {
          label = 'Admin, Trainer'
        } else if (isAdmin) {
          label = 'Admin'
        } else if (isTrainer) {
          label = 'Trainer'
        }
        return (
          <div
            className="flex-c pointer"
            onClick={() => {
              handlePushQuery({ ...query, level3: item.id })
            }}
          >
            <div>
              <UserOutlined
                style={{ fontSize: '28px', paddingRight: '12px' }}
              />
            </div>
            <div>
              <div style={{ color: '#051B37', fontWeight: 'bold' }}>
                {item?.res_users?.partner?.name || ''}
              </div>
              <div style={{ color: '#6060FF' }}>
                {item?.res_users?.login || ''}
              </div>
              <div>{label}</div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Action',
      width: '30%',
      render: (record) => {
        return (
          <div className="action__wrap" style={{ cursor: 'pointer' }}>
            <Space>
              <div
                onClick={() => {
                  window.open(
                    `/admin/user-management/create?user=${record?.res_users.id}`,
                    '_blank'
                  )
                }}
              >
                Edit
              </div>{' '}
              |{' '}
              <div>
                <Popconfirm
                  placement="topLeft"
                  title="Do you want to delete this record?"
                  onConfirm={() => handleDeleteRecord(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="red underline pointer">Delete</div>
                </Popconfirm>
              </div>
            </Space>
          </div>
        )
      },
    },
  ]

  const handleDeleteGroup = async (payload) => {
    const id = get(payload, 'id')

    try {
      if (payload.res_users) {
        await axiosInstance.delete(`/api/users/folders/${id}`)
        message.success(`Delete record successfully!`)
        await getFolders(true)
      } else {
        await axiosInstance.delete(`/api/folders/${id}`)
        message.success(`Delete group ${payload?.name} successfully!`)
        await getFolders()
      }
    } catch (error) {
      console.log('error', error)
      return false
    }
  }

  const handleDeleteRecord = async (payload) => {
    const id = get(payload, 'id')
    try {
      await axiosInstance.delete(`/api/users/folders/${id}`)
      message.success(`Delete record successfully!`)
      await getFolders(true)
    } catch (error) {
      console.log('error', error)
      return false
    }
  }

  const handleClickHome = () => {
    handlePushQuery({
      ...query,
      level1: undefined,
      level2: undefined,
      level3: undefined,
    })
  }
  const handleClickLevel1 = () => {
    handlePushQuery({ ...query, level2: undefined, level3: undefined })
  }

  const handleClickLevel2 = () => {
    handlePushQuery({ ...query, level3: undefined })
  }

  const handleClickLevel3 = () => {
    handlePushQuery({ ...query, level3: undefined })
  }

  return (
    <div>
      <HeaderPage title="Class management" />
      <AdminLayout>
        <div className="container_left" />
        <div className="container_right" style={{ padding: '20px' }}>
          <div className="heading">Class Management</div>
          <AddGroup
            visible={visible}
            setVisible={setVisible}
            data={data}
            reload={getFolders}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
          <div className="title-c-breadcrum">
            <Space>
              <div onClick={handleClickHome}>Home</div>
              <div onClick={handleClickLevel1}>
                {selectedLevel1?.name ? ` > ${selectedLevel1?.name}` : ''}
              </div>
              <div onClick={handleClickLevel2}>
                {selectedLevel2?.name ? ` > ${selectedLevel2?.name}` : ''}
              </div>
              <div onClick={handleClickLevel3}>
                {selectedLevel3?.name ? ` > ${selectedLevel3?.name}` : ''}
              </div>
            </Space>
          </div>
          {selectedLevel3 && usersFolder ? (
            <Table
              columns={columnsTableStudent}
              dataSource={usersFolder}
              rowKey={(record) => record.id}
              pagination={false}
              className="certificate-table-c-2"
            />
          ) : selectedLevel2 ? (
            <Table
              columns={columnsTableLevel3}
              dataSource={
                learners && Array.isArray(learners)
                  ? [
                      ...groupLevel3,
                      ...uniqBy(
                        [...learners, ...userFoldersLevel2],
                        'res_users.login'
                      ),
                    ]
                  : [
                      ...groupLevel3,
                      ...uniqBy(userFoldersLevel2, 'res_users.login'),
                    ]
              }
              rowKey={(record) => record.id}
              pagination={false}
              className="certificate-table-c-2"
            />
          ) : selectedLevel1 ? (
            <Table
              columns={columnsTableLevel2}
              dataSource={groupLevel2}
              rowKey={(record) => record.id}
              pagination={false}
              className="certificate-table-c-2"
            />
          ) : (
            <Table
              bordered={false}
              columns={columnsTableLevel1}
              dataSource={groupLevel1}
              rowKey={(record) => record.id}
              pagination={false}
              className="certificate-table-c-2"
            />
          )}
        </div>
      </AdminLayout>
    </div>
  )
}

export default ClassManagement
