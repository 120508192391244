import images from '../assets/images'
export const LIST_CAT = {
  // Lifestyle: {
  //   desc: 'Baking • Art & Craft • Cooking',
  //   img: images.lifestyle
  // },
  // Miscellaneous: {
  //   desc: 'Anything goes',
  //   img: images.miscellaneous
  // },
  // Technology: {
  //   desc: 'E-Commerce • Website • Software',
  //   img: images.technology
  // },
  Finance: {
    desc: 'Investing • Financial Literacy',
    img: 'https://lh3.googleusercontent.com/dwW0-D7Pd9Ux9CZssyKlxD5ayc-_n9KMw8T_Tfj_UkmWCEBqWOLCWj3Erhqdy-U6a5vOJOfkYDEhDSRqIzQCCwZhwo1nsHoiYDdrljumlhpJYA4',
    banner:
      'https://lh3.googleusercontent.com/oFh0LGKwnPDqVPEOPq9-0cnySYn43M04jZSZUFHa0iKE2Zh-eC84eNjWthMp1tDSbap7W08lRt3Hx117U_p0S-Jxw4TsE_juYU0Kq2kpPH-pQZhu=s0'
  },
  Marketing: {
    desc: 'Digital Marketing • Analytics • Social Media',
    img: 'https://lh3.googleusercontent.com/tHYbFoxCkmdPuB_x0pbw4EnFnjiBEck8nr3lBuWSPwpwbHJmSBklIdD5itbtzUJpCQ-GFbVqxDwym4Y2pTKVHfz4U-olm47U-p62G2P8FR20PW9n',
    banner:
      'https://lh3.googleusercontent.com/mBJc52MbWaiTPRDkutnpIRyZxOtGt0gpHh4NPVJCs7iPBDT_xWwcunMG2bA90j8pQ4tlFvP-Fp7vzFNvawH0ZqH7-jWzi9yJnkg7g8-PfO1Ej5Q=s0'
  },
  Creative: {
    desc: 'Photography • Design • Video ',
    img: 'https://lh3.googleusercontent.com/wkzcKIdqA34OWC_1b6nPEZoV9TNUOS3l8xU6iiKjjyn32ttwrssZoZvUY9UxaV6Xbry6X3JABFTTzf1Anxk7Jl9bqh1WOXHkFLwN985ZBk5Lqg',
    banner:
      'https://lh3.googleusercontent.com/a-cZJ2FsLXSRHsM7kjmBXC4SiKdD28saq4Xzd9t047PElEAY-Y0U1uJS3y1Ou618UYHoDpLVw5TUzDe4kxToEbuTeNgrOZn7zE3EbOcoLHYaBmk=s0'
  }
}
