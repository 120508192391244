import React, { useState } from 'react'
import { get, isEmpty } from 'lodash'

import SideBar from 'component/Sidebar'
import ChannelDetails from 'component/ChannelDetails'
import { Helmet } from 'react-helmet'
import { StudentLayout } from '../../styles'
import './styles.scss'
import { putUserLog } from '../../container/Home/actions'
import { useSelector } from 'react-redux'
import { isAdminRole } from 'utils/userUtils'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import dayjs from 'dayjs'

const Lessons = ({
  slides,
  selectedSlide,
  previews,
  course,
  userLogByCourse,
  userLogCurrentState,
  dispatch,
  id,
  schedule_id,
  preview,
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const currentUser = useSelector(makeSelectCurrentUser())
  const userGroups = get(currentUser, 'user_groups', [])
  const partners = get(course, 'partners[0]')
  const partnerId = get(partners, 'partner_id')
  const courseId = get(course, 'id')
  const scheduleId = get(partners, 'schedule.id', 0)
  const isAdmin = isAdminRole(userGroups)
  const listMeeting = get(course, 'meeting', [])
  const meeting =
    listMeeting &&
    listMeeting.find(
      (item) => get(item, 'schedule_id') === scheduleId || isAdmin
    )

  const handleClickContainerRight = () => {
    if (!isEmpty(userLogCurrentState)) {
      dispatch(
        putUserLog({
          ...userLogCurrentState,
          channel_id: id,
          schedule_id,
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>{get(course, 'name', '')}</title>
      </Helmet>
      <StudentLayout>
        <div className="container_left">
          <SideBar
            slides={slides}
            selectedSlide={selectedSlide}
            previews={previews}
            meeting={meeting}
            partnerId={partnerId}
            courseId={courseId}
            scheduleId={scheduleId}
            setPageNumber={setPageNumber}
            userLogByCourse={userLogByCourse}
            preview={preview}
            needToScroll={true}
          />
        </div>
        <div className="container_right" onClick={handleClickContainerRight}>
          <ChannelDetails
            slides={slides}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            preview={preview}
            previews={previews}
            id={id}
            userLogByCourse={userLogByCourse}
          />
        </div>
      </StudentLayout>
    </>
  )
}

export default Lessons
