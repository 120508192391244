export const TRAINER = +process.env.REACT_APP_TRAINER_GROUP_ID || 18

export const isAdminRole = (groups) => {
  if (!Array.isArray(groups)) {
    return null
  }
  return groups.find((item) => item.gid === 3)
}

export const isTrainerRole = (groups) => {
  if (!Array.isArray(groups)) {
    return null
  }
  return groups.find((item) => item.gid === TRAINER)
}

export const getUserRoles = (values) => {
  let roles = []
  const isAdminChecked = values?.admin
  const isTrainerChecked = values?.trainer

  if (!!isAdminChecked) roles.push(3)
  if (!!isTrainerChecked) roles.push(TRAINER)
  if (roles.length === 0) roles.push(8)

  return roles
}
