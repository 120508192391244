export function submitPaymentForm(path, params) {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = path;

  Object.entries(params || {}).forEach(param => {
    const [key, val] = param;
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.id = key;
    hiddenField.value = val;
    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);
  form.submit();
}
