import React from 'react';
import images from 'Themes/Images';
import './styles.scss';

const HeaderCourses = () => {
  return (
    <div className="header__courses">
      <div className="header__intro">
        <div className="header__course">
          <div className="header__course__level">learn</div>
          <div className="header__course__title">Courses available</div>
        </div>
        {/* <div className="header__action">
          <div className="header__action__fullscreen">
            <img src={images.header.tv} alt="TV" className="tv__icon" />
            Go fullscreen
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderCourses;
