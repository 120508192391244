import React from 'react'
import images from 'Themes/Images'
import InputTypePassword from 'component/InputTypePassword'
import { useDispatch } from 'react-redux'
import { showForgotPasswordModal } from 'container/Modal/actions'

const ResetPasswordForm = ({ formik, isValid, error }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(showForgotPasswordModal())
  }
  return (
    <div className="login-form">
      <div className="login-form__wrap">
        <div className="login-form__switch" />
        <div className="login-form__text">
          Set your password for your account login
        </div>
        {error === 'Token does not exist' ? (
          <div className="login-form__error">
            The Set Password link has expired, please click{' '}
            <span
              onClick={handleClick}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              here
            </span>{' '}
            to resend the email.
          </div>
        ) : (
          <div className="login-form__error">{error}</div>
        )}
        <form onSubmit={formik.handleSubmit} style={{ marginBottom: '56px' }}>
          <div className="form__field">
            <label htmlFor="email" className="form__label">
              Email Address
            </label>
            <input
              id="email"
              name="email"
              className="form__input"
              placeholder="Enter your email address"
              autoComplete="none"
              onChange={formik.handleChange}
              value={formik.values.email}
              disabled={true}
              style={{ cursor: 'not-allowed' }}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="form__error">{formik.errors.email}</div>
            )}
          </div>
          <div className="form__field">
            <label htmlFor="name" className="form__label">
              Name
            </label>
            <input
              id="name"
              name="name"
              disabled={true}
              className="form__input"
              placeholder="Enter your name"
              autoComplete="none"
              onChange={formik.handleChange}
              value={formik.values.name}
              style={{ cursor: 'not-allowed' }}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="form__error">{formik.errors.name}</div>
            )}
          </div>
          <div className="form__field">
            <div className="form__box">
              <label htmlFor="password" className="form__label">
                Password
              </label>
            </div>

            <InputTypePassword
              placeholder={'Enter your password'}
              onChange={formik.handleChange}
              name="password"
              className="form__input"
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="form__error">{formik.errors.password}</div>
            )}
          </div>

          <div className="form__field">
            <div className="form__box">
              <label htmlFor="password" className="form__label">
                Confirm Password
              </label>
            </div>

            <InputTypePassword
              placeholder={'Enter your confirm password'}
              onChange={formik.handleChange}
              name="confirmPassword"
              className="form__input"
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="form__error">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>

          <button
            className={`primary-btn ${!isValid && 'primary-btn-disable'}`}
            style={{ width: '100%' }}
            type="submit"
          >
            <span>Confirm</span>
            {isValid ? (
              <img src={images.course.arrow_right_active} alt="" />
            ) : (
              <img src={images.course.arrow_right} alt="" />
            )}
          </button>
        </form>
      </div>
    </div>
  )
}

export default ResetPasswordForm
