import * as Yup from 'yup'
import { ERROR_MESSAGE } from 'utils/constants'
import { validateNric } from 'utils/helper'

Yup.addMethod(Yup.string, 'validateNric', function (errorMessage) {
  return this.test(`test-passport`, errorMessage, function (value) {
    const { path, createError } = this

    return (
      (value && value.length === 9 && validateNric(value)) ||
      createError({ path, message: errorMessage })
    )
  })
})

export const ProfileUnicornSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  address: Yup.string().required(ERROR_MESSAGE),
  postTalCode: Yup.string().required(ERROR_MESSAGE),
  gender: Yup.string().required(ERROR_MESSAGE),
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  nationality: Yup.string().required(ERROR_MESSAGE),
  race: Yup.string().required(ERROR_MESSAGE),
  passport: Yup.string()
    .required(ERROR_MESSAGE)
    .validateNric('Please input correct nric format (eg. S9576291C)'),
  phone: Yup.string().required(ERROR_MESSAGE),
  emergency_contact: Yup.string().required(ERROR_MESSAGE),
  occupation: Yup.string().required(ERROR_MESSAGE),
  education_level: Yup.string().required(ERROR_MESSAGE),
  unit_number: Yup.string().required(ERROR_MESSAGE),
})

export const checkProfileUnicorn = (profile) => {
  return (
    profile &&
    profile.name &&
    profile.postal_address &&
    profile.postal_code &&
    profile.birthday &&
    profile.contact &&
    profile.gender &&
    profile.nationality &&
    profile.race &&
    profile.passport &&
    profile.phone &&
    profile.occupation &&
    profile.education_level &&
    profile.experience >= 0
  )
}

export const ProfileAgbEducationSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  passport: Yup.string()
    .required(ERROR_MESSAGE)
    .validateNric('Please input correct nric format (eg. S9576291C)'),
  phone: Yup.string().required(ERROR_MESSAGE),
})

export const checkProfileAgbEducation = (profile) => {
  return profile && profile.name && profile.passport && profile.phone
}

export const ProfileArtofNumbersSchema = Yup.object().shape({
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  phone: Yup.string().required(ERROR_MESSAGE),
})

export const checkProfileArtOfNumbers = (profile) => {
  return profile && profile.birthday && profile.phone
}

export const ProfileFirstMediaSchema = Yup.object().shape({
  id_type: Yup.string().required(ERROR_MESSAGE),
  name: Yup.string().required(ERROR_MESSAGE),
  address: Yup.string().required(ERROR_MESSAGE),
  postTalCode: Yup.string().required(ERROR_MESSAGE),
  gender: Yup.string().required(ERROR_MESSAGE),
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  nationality: Yup.string().required(ERROR_MESSAGE),
  passport: Yup.string().required(ERROR_MESSAGE),
  phone: Yup.string().required(ERROR_MESSAGE),
})

export const checkProfileFirstMedia = (profile) => {
  return (
    profile &&
    profile.name &&
    profile.postal_address &&
    profile.postal_code &&
    profile.birthday &&
    profile.gender &&
    profile.nationality &&
    profile.passport &&
    profile.phone
  )
}

export const ProfileCoursesSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  gender: Yup.string().required(ERROR_MESSAGE),
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  nationality: Yup.string().required(ERROR_MESSAGE),
  passport: Yup.string().required(ERROR_MESSAGE),
  phone: Yup.string().required(ERROR_MESSAGE),
  salutation: Yup.string().required(ERROR_MESSAGE),
})

export const checkProfileCourses = (profile) => {
  return (
    profile &&
    profile.name &&
    profile.salutation &&
    profile.birthday &&
    profile.gender &&
    profile.nationality &&
    profile.passport &&
    profile.phone
  )
}

export const ProfileCyberQuoteSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  email: Yup.string().email().required(ERROR_MESSAGE),
  address: Yup.string().required(ERROR_MESSAGE),
  postTalCode: Yup.string().required(ERROR_MESSAGE),
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  nationality: Yup.string().required(ERROR_MESSAGE),
  unit_number: Yup.string().required(ERROR_MESSAGE),
  passport: Yup.string()
    .required(ERROR_MESSAGE)
    .validateNric('Please input correct nric format (eg. S9576291C)'),
  phone: Yup.string().required(ERROR_MESSAGE),
  occupation: Yup.string().required(ERROR_MESSAGE),
})

export const checkProfileCyberQuote = (profile) => {
  return (
    profile &&
    profile.name &&
    profile.email &&
    profile.postal_address &&
    profile.postal_code &&
    profile.birthday &&
    profile.nationality &&
    profile.passport &&
    profile.phone &&
    profile.unit_number &&
    profile.occupation
  )
}

export const ProfileDefaultSchema = Yup.object().shape({
  email: Yup.string().email().required(ERROR_MESSAGE),
  name: Yup.string().required(ERROR_MESSAGE),
})

export const ProfileFlcSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  gender: Yup.string().required(ERROR_MESSAGE),
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  nationality: Yup.string().required(ERROR_MESSAGE),
  passport: Yup.string()
    .required(ERROR_MESSAGE)
    .validateNric('Please input correct nric format (eg. S9576291C)'),
  phone: Yup.string().required(ERROR_MESSAGE),
  occupation: Yup.string().required(ERROR_MESSAGE),
  education_level: Yup.string().required(ERROR_MESSAGE),
  nric_front: Yup.object().required(ERROR_MESSAGE).nullable(),
  nric_back: Yup.object().required(ERROR_MESSAGE).nullable(),
  question_above_40: Yup.string().required(ERROR_MESSAGE),
  user_billing_address: Yup.string().required(ERROR_MESSAGE),
})

export const ProfileFlcCustomSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  gender: Yup.string().required(ERROR_MESSAGE),
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  nationality: Yup.string().required(ERROR_MESSAGE),
  passport: Yup.string()
    .required(ERROR_MESSAGE)
    .validateNric('Please input correct nric format (eg. S9576291C)'),
  phone: Yup.string().required(ERROR_MESSAGE),
  title: Yup.string().required(ERROR_MESSAGE),
  company: Yup.string().required(ERROR_MESSAGE),
  professional_designation: Yup.string().required(ERROR_MESSAGE),
  highest_academic_qualifications: Yup.string().required(ERROR_MESSAGE),
  marital_status: Yup.string().required(ERROR_MESSAGE),
  personal_email: Yup.string().email().required(ERROR_MESSAGE),
  company_email: Yup.string().email().required(ERROR_MESSAGE),
  question_1: Yup.string().required(ERROR_MESSAGE),
  question_2: Yup.string().required(ERROR_MESSAGE),
  question_3: Yup.string().required(ERROR_MESSAGE),
  question_4: Yup.string().required(ERROR_MESSAGE),
  question_5: Yup.string().required(ERROR_MESSAGE),
  question_6: Yup.string().required(ERROR_MESSAGE),
  question_7: Yup.string().required(ERROR_MESSAGE),
  question_above_40: Yup.string().required(ERROR_MESSAGE),
  user_billing_address: Yup.string().required(ERROR_MESSAGE),
})

export const ProfileFlcCustom2Schema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  date_of_birth: Yup.string().required(ERROR_MESSAGE),
  gender: Yup.string().required(ERROR_MESSAGE),
  personal_email: Yup.string().email().required(ERROR_MESSAGE),
  company_email: Yup.string().email().required(ERROR_MESSAGE),
  phone: Yup.string().required(ERROR_MESSAGE),
  company: Yup.string().required(ERROR_MESSAGE)
})
