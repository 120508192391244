import React from 'react'
import { get } from 'lodash'
import styled from 'styled-components'

import images from 'Themes/Images'
import InputSmall from '../InputSmall'
import { checkInput } from 'utils/helper'
import { createCourse } from 'container/Admin/actions'

const OptionPricingItemWrapper = styled.div`
  margin-right: 30px;
  margin-bottom: 24px;
`
const OptionsPricingItem = ({
  option,
  index,
  listOption,
  formik,
  course,
  dispatch,
  isSFC
}) => {
  const price = get(option, 'price', 0)
  const title = get(option, 'title', '')
  const description = get(option, 'description', '')
  const courseCode = get(option, 'course_code', '')
  const isEditTitle = get(option, 'is_edit', false)

  const handleEdit = () => {
    const newList = [...listOption]
    newList[index].is_edit = !newList[index].is_edit
    if (isSFC) {
      formik.setFieldValue('sfc_prices', newList)
    } else {
      formik.setFieldValue('prices', newList)
    }
  }

  const handleChangeInput = (e) => {
    if (checkInput(e)) {
      const newList = [...listOption]
      const value = e.currentTarget.value
      newList[index].price = value
      if (isSFC) {
        formik.setFieldValue('sfc_prices', newList)
      } else {
        formik.setFieldValue('prices', newList)
      }
    }
  }

  const handleChangeTitle = (e) => {
    const name = e.currentTarget.name
    const newList = [...listOption]
    newList[index][name] = e.currentTarget.value
    if (isSFC) {
      formik.setFieldValue('sfc_prices', newList)
    } else {
      formik.setFieldValue('prices', newList)
    }
  }

  const handleRemove = () => {
    delete course.prices
    delete course.sfc_prices
    let payload = {}
    if (isSFC) {
      payload = { ...course, old_sfc_price_ids: [option.id] }
    } else {
      payload = { ...course, old_price_ids: [option.id] }
    }

    dispatch(createCourse(payload))
  }

  return (
    <OptionPricingItemWrapper>
      <InputSmall
        isEdit={true}
        editing={isEditTitle}
        text={title}
        iconLeft={images.admin.sgd}
        onChange={handleChangeInput}
        index={index}
        title={title}
        handleEdit={handleEdit}
        courseCode={isSFC ? courseCode : ''}
        value={price}
        isSFC={isSFC}
        description={description}
        type="text"
        handleChangeTitle={handleChangeTitle}
        handleRemove={handleRemove}
      />
    </OptionPricingItemWrapper>
  )
}

export default OptionsPricingItem
