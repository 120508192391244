import * as constants from './constants'

export function checkoutWithSFCPayment(params) {
  return {
    type: constants.CHECKOUT_WITH_SFC_PAYMENT,
    params
  }
}

export function checkoutWithSFCPaymentSuccess(payload) {
  return {
    type: constants.CHECKOUT_WITH_SFC_PAYMENT_SUCCESS,
    payload
  }
}

export function checkoutWithSFCPaymentFailed(error) {
  return {
    type: constants.CHECKOUT_WITH_SFC_PAYMENT_FAILED,
    error
  }
}

export function setSelectedSchedule(payload) {
  return {
    type: constants.SET_SELECTED_SCHEDULE,
    payload
  }
}

export function setSelectedPriceSFC(payload) {
  return {
    type: constants.SET_SELECTED_PRICE_SFC,
    payload
  }
}

export function setSelectedModuleSFC(payload) {
  return {
    type: constants.SET_SELECTED_MODULE_SFC,
    payload
  }
}

export function getInvoiceNumber(params) {
  return {
    type: constants.GET_INVOICE_NUMBER,
    params
  }
}

export function getInvoiceNumberSuccess(payload) {
  return {
    type: constants.GET_INVOICE_NUMBER_SUCCESS,
    payload
  }
}

export function getInvoiceNumberFailed(error) {
  return {
    type: constants.GET_INVOICE_NUMBER_FAILED,
    error
  }
}

export function setClient(payload) {
  return {
    type: constants.SET_CLIENT,
    payload
  }
}
