import React, { useEffect } from 'react'
import { parse } from 'query-string'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'

import LoginForm from './screens/Login'
import SignUpForm from './screens/SignUp'
import RegisterSuccess from './screens/RegisterSuccess'
import { makeSelectDomain } from '../Home/selectors'
import './styles.scss'
import { DOMAIN } from 'src/routes'
import { getUserInfoFailed } from '../Home/actions'
import SignUpAscendoForm from './screens/SignUpAscendo'

const AuthMobileWrapper = styled.div`
  padding-bottom: 45px;
`
const LogoMobileWrapper = styled.div`
  width: 100%;
  height: 158px;
  background: ${(props) =>
    props.superAdmin || props.student ? '#e7e9ee' : '#00122a'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
`

const AuthPage = ({ superAdmin, classCompare }) => {
  const { search } = useLocation()
  const { login, signup, student, register } = parse(search)
  const domain = useSelector(makeSelectDomain())
  const isSingaporeSkillsAcademies =
    domain && domain.domain === DOMAIN.SINGAPORESKILLS
  const isAscendoDomain =
    domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO

  const isBellDomain = domain && domain.domain === DOMAIN.AGB_EDUCATION

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserInfoFailed(null))
  }, [])

  const renderLogoAuth = () => {
    return (
      <div
        style={
          !isMobile
            ? {
                fontWeight: 'bold',
                fontSize: 24,
                color: '#fff',
                whiteSpace: 'nowrap',
                position: 'absolute',
                top: -50,
                transform: 'translateX(-50%)',
              }
            : {
                fontWeight: 'bold',
                padding: '0 15px',
                fontSize: 24,
                color: !student || classCompare ? '#fff' : '#000',
              }
        }
      >
        {domain && domain.name}
      </div>
    )
  }

  return !isMobile ? (
    <div
      className={`auth__container ${
        !student && !signup && !superAdmin ? 'auth__school-login' : ''
      }`}
      style={{ height: superAdmin ? '95vh' : '100%' }}
    >
      <div className="auth__wrap">
        <div
          className={`auth__head ${
            superAdmin ? 'super-admin__auth__head' : ''
          } ${classCompare ? 'classcompare_bg' : ''}`}
        >
          {!superAdmin && (
            <div className="auth--wrap">
              <div className="auth__login">
                <Link to="/auth?login=true&student=true">
                  <span>LOG IN</span>
                </Link>
              </div>
              {!isBellDomain && (
                <div className="auth__signup">
                  <Link to="/auth?signup=true">
                    <button className="quaternary-btn">REGISTER</button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={`auth__form ${superAdmin ? 'sales-auth__form' : ''}`}>
          <Link to="/?page=1&perpage=15">{renderLogoAuth()}</Link>
          {login && <LoginForm superAdmin={superAdmin} />}
          {signup ? (
            isAscendoDomain ? (
              <SignUpAscendoForm />
            ) : (
              <SignUpForm
                isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
              />
            )
          ) : null}
          {register === 'success' && <RegisterSuccess />}
        </div>
      </div>
    </div>
  ) : (
    <AuthMobileWrapper>
      <LogoMobileWrapper
        classCompare
        superAdmin={superAdmin}
        student={student}
        className={`${classCompare ? 'classcompare_bg' : ''}`}
      >
        <Link to="/?page=1&perpage=15">{renderLogoAuth(true)}</Link>
      </LogoMobileWrapper>
      {login && <LoginForm superAdmin={superAdmin} isMobile={isMobile} />}
      {signup ? (
        isAscendoDomain ? (
          <SignUpAscendoForm />
        ) : (
          <SignUpForm isSingaporeSkillsAcademies={isSingaporeSkillsAcademies} />
        )
      ) : null}
    </AuthMobileWrapper>
  )
}

export default AuthPage
