import React from 'react';
import { Modal, Button } from 'antd';
import { get } from 'lodash';
import images from 'Themes/Images';
import { hideDeleteLessonModal } from '../../actions';
import { deleteLesson, setSelectedSection } from '../../../Admin/actions';
import './styles.scss';
import { setSelectedSlide } from '../../../Home/actions';

const DeleteLessonModal = ({
  slide,
  dispatch,
  showDeleteLessonModal,
  resetForm
}) => {
  const handleOk = () => {
    if (get(slide, 'id', null) && !get(slide, 'is_category', false)) {
      dispatch(deleteLesson(slide.id));
      dispatch(setSelectedSection(null));
      dispatch(hideDeleteLessonModal());
      dispatch(setSelectedSlide(null));
      resetForm();
    } else {
      dispatch(deleteLesson(slide.id));
      dispatch(setSelectedSection(null));
      dispatch(hideDeleteLessonModal());
    }
  };
  const handleCancel = () => {
    dispatch(hideDeleteLessonModal());
  };

  const isSection = get(slide, 'is_category', false);

  return (
    <Modal
      visible={showDeleteLessonModal}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="ld-modal">
        <div className="ld-modal__icon">
          <img src={images.admin.trash_icon} alt="" width="25" height="29" />
        </div>
        <div className="ld-modal__warning">
          Are you sure you want to delete {get(slide, 'name', '')}{' '}
          {isSection ? 'section' : 'lesson'}?
        </div>
        <div className="ld-modal__label">
          You will not be able to recover this later.
        </div>
        <div className="ld-modal__btn__ok">
          <button className="delete-btn" onClick={handleOk}>
            <img
              src={images.admin.trash_white_icon}
              alt=""
              style={{ fill: '#fff' }}
              className="trash-icon"
            />
            <span>Delete forever</span>
          </button>
        </div>
        <div className="ld-modal__btn__cancel">
          <button className="tertiary-btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteLessonModal;
