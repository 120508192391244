import React from 'react'
import { Modal } from 'antd'
import images from 'Themes/Images'
import axiosInstance from 'utils/axiosInstance'
import { showSuccess } from 'utils/notification'
import './styles.scss'

const DeleteUserModal = ({ selectedUser, visible, onClose, reloadUsers }) => {
  const handleOk = async () => {
    try {
      await axiosInstance.delete(`/api/users/${selectedUser?.id}`)
      showSuccess(`Deleted user ${selectedUser?.login}!`)
      onClose()
      reloadUsers()
    } catch (error) {
      showSuccess(`Deleted user ${selectedUser?.login} failed!`)
    }
  }

  return (
    <Modal visible={visible} footer={null} onCancel={onClose}>
      <div className="ld-modal">
        <div className="ld-modal__icon">
          <img src={images.admin.trash_icon} alt="" width="25" height="29" />
        </div>
        <div className="ld-modal__warning">
          Are you sure you want to delete user{' '}
          {selectedUser?.partner?.name || ''}?
        </div>
        <div className="ld-modal__label">
          You will not be able to recover this later.
        </div>
        <div className="ld-modal__btn__ok">
          <button className="delete-btn" onClick={handleOk}>
            <img
              src={images.admin.trash_white_icon}
              alt=""
              style={{ fill: '#fff' }}
              className="trash-icon"
            />
            <span>Delete forever</span>
          </button>
        </div>
        <div className="ld-modal__btn__cancel">
          <button className="tertiary-btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteUserModal
