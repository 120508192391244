import styled from 'styled-components'

export const AboutCourseWrap = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e7e7ec;
  box-sizing: border-box;
  box-shadow: 0px 8px 15px rgba(140, 152, 164, 0.1);
  border-radius: 4px;
  margin-bottom: 50px;
  padding-bottom: 40px;

  .about-course__banner {
    width: 100%;
    padding: 10px;
  }
  .about-course__discount {
    font-weight: 500;
    font-size: 30px;
    line-height: 43px;
    text-align: right;
    text-transform: capitalize;
    color: #2f2d51;
  }
  .about-course__original {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-decoration-line: line-through;
    text-transform: capitalize;
    padding-left: 5px;
    padding-bottom: 10px;
    color: #77838f;
  }
  .about-course__main {
    padding: 0 30px;
    margin-top: 30px;
  }
  .about-course__prices {
    width: 100%;
    &__left {
      display: flex;
      align-items: center;
    }
    &__wrap {
      display: flex;
      justify-content: space-between;
    }
  }
  .about-course__percent {
    background: #b8b2fd;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #ffffff;
    padding: 5px 16px;
  }
  .about-course__prices__alert {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: #ec5252;
    margin-top: 8px;
  }

  .about-course__summary {
    margin-top: 25px;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e7e7ec;
      padding: 10px 0;
      &-title {
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
        color: #2f2d51;
        padding-left: 12px;
      }
      &-desc {
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;
        color: #77838f;
      }
    }
  }
  .about-course__share-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    text-transform: capitalize;
    color: #4a8f9f;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 15px;
    cursor: pointer;
    & img {
      padding-right: 14px;
    }
  }
  .btn_buy_now,
  .btn_enroll {
    padding: 0 20px;
    height: 32px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    width: 100%;
    height: 60px;
  }

  .btn_buy_now {
    background: #090761;
    color: #ffffff;
    margin-bottom: 13px;
    margin-top: 30px;
  }

  .btn_enroll {
    background: #ffc78b;
    color: #090761;
    margin-top: 25px;
  }
`
