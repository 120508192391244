import React, { useMemo } from 'react'
import images from 'Themes/Images'

const ChannelHead = ({
  course,
  slideDetails,
  handleSetComplete,
  handlePreviousSlide,
  handleNextSlide,
  setHideDocs,
  hideDocs,
  isMobile,
  previews
}) => {
  const completedLesson = useMemo(
    () =>
      Array.isArray(previews) &&
      previews?.find(
        (item) => item.slide_id === slideDetails?.id && item?.completed
      ),
    [previews, slideDetails]
  )

  return (
    <div className="channel__head" style={{ paddingTop: 11 }}>
      <div className="channel__head__left">
        <div className="channel__title">{slideDetails.name}</div>
      </div>
      <div className="channel__head__right">
        <div className="channel__head__right__button">
          {!completedLesson && (
            <button
              type="primary"
              className="btn btn-primary"
              onClick={() => handleSetComplete(false)}
              style={{ whiteSpace: 'nowrap' }}
            >
              <img src={images.header.check} alt="" />
              <span className="btn__text">Mark as Done</span>
            </button>
          )}
        </div>
        <div className="channel__head__right__cta">
          <div
            className="channel__head__right__cta__back-btn"
            onClick={() => handlePreviousSlide(false)}
          >
            <img src={images.channel.angle_left} alt="" />
          </div>
          <div
            className="channel__head__right__cta__next-btn"
            onClick={() => handleNextSlide(false)}
          >
            <img src={images.channel.angle_right} alt="" />
          </div>
        </div>

        <div
          className="channel__head__right__docs"
          onClick={() => setHideDocs(!hideDocs)}
          style={{ marginLeft: isMobile ? 0 : 10 }}
        >
          {hideDocs ? (
            <img src={images.channel.docs_unactive} alt="" />
          ) : (
            <img src={images.channel.docs} alt="" />
          )}
        </div>
      </div>
    </div>
  )
}

export default ChannelHead
