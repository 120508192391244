import { get } from 'lodash'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as constants from './constants'
import config from 'src/global-config'
import request from 'utils/request'
import { hideGlobalLoading, showGlobalLoading } from '../Modal/actions'
import { submitPaymentForm } from 'utils/forms'
import { getInvoiceNumberSuccess } from './actions'

function* onCheckoutWithSFCPayment(action) {
  const requestURL = `${config.baseUrl}/api/payments/sfc/claims`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(action.params)
    })

    if (res.data.status === 200) {
      const claimRequest = res.data.data.claimRequest
      const params = {
        encryptedPayload: claimRequest
      }
      const requestGateway = config.gatewaySFC
      submitPaymentForm(requestGateway, params)
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(hideGlobalLoading())
  }
}

function* onGetInvoiceNumber(action) {
  const domain = action.params.domain
  const channel_id = action.params.channel_id

  const requestURL = `${config.baseUrl}/api/invoices/numbers`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        domain,
        channel_id
      })
    })

    if (res?.data?.value) {
      yield put(getInvoiceNumberSuccess(res?.data?.value))
    }
    yield put(hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(hideGlobalLoading())
  }
}

export default function* adminSaga() {
  yield takeLatest(
    constants.CHECKOUT_WITH_SFC_PAYMENT,
    onCheckoutWithSFCPayment
  )
  yield takeLatest(constants.GET_INVOICE_NUMBER, onGetInvoiceNumber)
}
