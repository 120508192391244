import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthPage from 'container/Auth'
import ProtectedRoute from 'component/ProtectedRoute'
import AdminActor from '../../customs/SuperAdmin/container/AdminActor'

const SuperAdminRoutes = () => {
  return (
    <Switch>
      <Route exact path="/auth">
        <AuthPage superAdmin />
      </Route>
      <ProtectedRoute path="/admin">
        <AdminActor />
      </ProtectedRoute>
      <Redirect to="/auth?login=true" />
    </Switch>
  )
}

export default SuperAdminRoutes
