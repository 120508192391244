import React, { useState } from 'react'
import './styles.scss'
import images from '../../Themes/Images'

const InputTypePassword = ({
  onChange,
  placeholder,
  name = '',
  className = '',
  value,
  style,
  styleIcon,
  autoComplete,
  superAdminClass,
  error
}) => {
  const [show, setShow] = useState(false)
  const handleOnclick = () => {
    setShow(!show)
  }
  const type = !show ? 'password' : 'text'
  return (
    <div className={`inputTypePasswordComponent ${superAdminClass}`}>
      <input
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        className={`${className} ${error ? 'form__input__error' : ''}`}
        value={value}
        style={style}
        autoComplete={autoComplete}
      />
      <span className="tap" onClick={handleOnclick} style={styleIcon}>
        {show && <img src={images.input.eye_icon} />}
        {!show && <img src={images.input.eye_closed_icon} width="14" />}
      </span>
    </div>
  )
}
export default InputTypePassword
