import React from 'react'
import dayjs from 'dayjs'
import { Modal } from 'antd'
import { useMediaQuery } from 'react-responsive'

import images from 'Themes/Images'
import { hideConfirmQuizModal } from '../../actions'
import { putAnswerTime, putUserLog } from 'container/Home/actions'

const ConfirmQuizModal = ({
  dispatch,
  answerQuizId,
  isLastAnswer,
  showConfirmQuizModal,
  userLogByCourse,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const handleOk = () => {
    if (answerQuizId) {
      dispatch(
        putAnswerTime({ endDate: dayjs(), answerQuizId, needToComplete: true })
      )
      dispatch(hideConfirmQuizModal())

      if (isLastAnswer && userLogByCourse) {
        dispatch(
          putUserLog(
            {
              ...userLogByCourse,
              status: 'Complete',
              course_completion_date: dayjs(),
            },
            true
          )
        )
      }
    }
  }

  const handleCancel = () => {
    dispatch(hideConfirmQuizModal())
  }

  return (
    <Modal visible={showConfirmQuizModal} footer={null} onCancel={handleCancel}>
      <div className="ld-modal">
        <div className="ld-modal__icon">
          <img src={images.admin.check_active} alt="" width="25" height="29" />
        </div>
        <div className="ld-modal__warning">Confirm quiz completion?</div>
        {!isMobile ? (
          <div className="ld-modal__label" style={{ lineHeight: '22px' }}>
            Please make sure you have answered all the questions correctly.
            <br />
            Once submitted, you will not be able to edit or redo this quiz.
          </div>
        ) : (
          <div className="ld-modal__label" style={{ lineHeight: '18px' }}>
            Please make sure you have answered all the questions correctly. One
            submitted, you will not be able to edit or redo this quiz.
          </div>
        )}

        <div className="ld-modal__btn__ok">
          <button
            className="delete-btn"
            style={{ background: '#ff0d6b', fontWeight: 800 }}
            onClick={handleOk}
          >
            <span>Complete Quiz</span>
          </button>
        </div>
        <div className="ld-modal__btn__cancel">
          <button className="tertiary-btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmQuizModal
