import React from 'react'
import styled from 'styled-components'
import ChoiceItemStudent from '../ChoiceItemStudent/index'

const ListChoiceWrapper = styled.div``

const ListChoiceStudent = ({
  listChoice,
  handlePickOption,
  chooseOptions,
  chooseCorrectOptions,
  correctAnswers,
  hideAnswer,
  isCorrectAnswer,
}) => {
  return (
    <ListChoiceWrapper>
      {listChoice &&
        listChoice.map((choice, i) => (
          <ChoiceItemStudent
            index={i}
            key={i}
            choice={choice}
            chooseOptions={chooseOptions}
            handlePickOption={handlePickOption}
            chooseCorrectOptions={chooseCorrectOptions}
            correctAnswers={correctAnswers}
            hideAnswer={hideAnswer}
            isCorrectAnswer={isCorrectAnswer}
          />
        ))}
    </ListChoiceWrapper>
  )
}

export default ListChoiceStudent
