import styled from 'styled-components'
export const InputQuizSettings = styled.div`
  position: relative;
  max-width: 180px;
  margin-top: 6px;
  padding-left: 50px;
  & input {
    width: 180px;
    height: 32px;
    border: 1px solid #e7ebf4;
    padding: 8px 45px 8px 8px;
    border-radius: 4px;
    outline: none;
    text-align: right;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.65);
  }

  & div {
    position: absolute;
    top: 5px;
    right: -40px;
  }
`
