import React from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import config from 'src/global-config'
import axios from 'axios'
import { downloadImage } from 'src/utils/helper'

const CertificateContainer = ({ record, index, profiles, course }) => {
  const dispatch = useDispatch()
  const userID = record?.user?.id
  const profileName =
    get(profiles, `[${index}].name`, '') || get(record, 'user.partner.name', '')

  const handleDownloadCertificate = async () => {
    dispatch(showGlobalLoading())

    const requestUrl = `${config.api}/send-mail/certificate-pdf`

    await axios
      .post(requestUrl, {
        course_id: course?.id,
        email_id: userID,
      })
      .then(async (response) => {
        downloadImage(response.data.url, `Certificate_${profileName}.pdf`)
        return response.data
      })

    dispatch(hideGlobalLoading())
  }

  return (
    <div className="action vertical-line" onClick={handleDownloadCertificate}>
      Download
    </div>
  )
}

export default CertificateContainer
