import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Layout, Body } from 'src/customs/ClassCompare/styles'
import { useHistory } from 'react-router-dom'

const RouteClientsWrapper = styled.div`
  padding: 12px 13px;
  border: 1px solid #e7ebf4;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5a6686;
  margin-bottom: 10px;

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 100%;
    color: #061b37;
    text-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
    @media (max-width: 767px) {
      font-size: 27px;
    }
  }
  .client__item {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    & span {
      padding-right: 20px;
    }
    &:hover {
      color: #2b4a88;
    }
  }
`

const RouteClients = ({ userClients }) => {
  const history = useHistory()
  useEffect(() => {
    if (userClients?.length > 1) {
      localStorage.setItem('clientID', userClients[0]?.id)
      localStorage.setItem('clientName', userClients[0]?.client?.name)
    }
  }, [userClients])
  return (
    <Layout>
      <Body>
        <RouteClientsWrapper>
          <div>
            <h1>Clients Routing</h1>
            {userClients?.map((item) => {
              if (item?.client_id) {
                return (
                  <div
                    className="client__item"
                    onClick={() => history?.push(item?.client?.slug)}
                  >
                    <span>{item?.client?.name}</span>
                    <img src={item?.client?.logo} width={80} alt="logo" />
                  </div>
                )
              }
            })}
          </div>
        </RouteClientsWrapper>
      </Body>
    </Layout>
  )
}

export default RouteClients
