import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import { get } from 'lodash'
import 'jspdf-autotable'
import images from 'Themes/Images'
import { makeSelectDomain } from 'container/Home/selectors'

const Invoice = ({ currentUser, course, isAdmin }) => {
  const domain = useSelector(makeSelectDomain())
  const partners = get(course, 'partners[0]', null)
  const courseName = get(course, 'name')
  const sfcPrices = get(course, 'sfc_prices')
  const courseCode = get(sfcPrices, '[0].course_code')

  const invoice = (doc, invoiceNo) => {
    doc.setFontSize(20)
    doc.text(domain.name, 20, 50)
    doc.setFontSize(12)
    doc.text('538 Geylang Road', 400, 50)
    doc.text('#02-09 The Arizon', 400, 70)
    doc.text('Singapore 389493', 400, 90)
    doc.text('Tel: +(65) 6887 3885', 400, 110)

    doc.setFontSize(16)
    doc.text('TAX INVOICE', 240, 170)

    // Left
    const y_info = 230
    doc.setFontSize(12)
    doc.text('Account :', 20, y_info)
    doc.setFontSize(12)
    doc.text('COURSE FEES', 80, y_info)

    doc.setFontSize(12)
    doc.text('Bill To :', 20, y_info + 40)
    doc.setFontSize(12)
    doc.text(
      `${
        isAdmin
          ? get(currentUser, 'partner.name', '')
          : get(currentUser, 'partner_display_name', '')
      }`,
      80,
      y_info + 40
    )

    const dateEnrollment = get(partners, 'create_date', '')

    const isOldInvoice = dayjs(dateEnrollment).isBefore('2021-07-10T00:00:00Z')

    // Right
    const x_right = 380
    doc.setFontSize(12)
    doc.text('Date :', x_right - 10, y_info)
    doc.setFontSize(12)
    doc.text(dayjs(dateEnrollment).format('DD/MM/YYYY'), x_right + 30, y_info)

    doc.setFontSize(12)
    doc.text('Invoice No :', x_right - 40, y_info + 20)
    doc.setFontSize(12)
    doc.text(invoiceNo, x_right + 30, y_info + 20)

    doc.setFontSize(12)
    doc.text('GST Registration No :', x_right - 94, y_info + 40)
    doc.setFontSize(12)
    doc.text('200501540R', x_right + 30, y_info + 40)

    doc.setFontSize(12)
    doc.text('Trainee :', x_right - 24, y_info + 60)
    doc.setFontSize(12)
    doc.text(
      `${
        isAdmin
          ? get(currentUser, 'partner.name', '')
          : get(currentUser, 'partner_display_name', '')
      }`,
      x_right + 30,
      y_info + 60
    )

    doc.setFontSize(12)
    doc.text('Course Date :', x_right - 51, y_info + 80)

    const startDateCourse = get(partners, 'schedule.start_date', '')
    const endDateCourse = get(partners, 'schedule.end_date', '')
    doc.setFontSize(12)
    doc.text(
      `${dayjs(startDateCourse).format('YYYY-MM-DD')} to ${dayjs(
        endDateCourse
      ).format('YYYY-MM-DD')}`,
      x_right + 30,
      y_info + 80
    )
    const body = [
      [`${courseCode} ${courseName}`, ' $3,000.00'],
      ['Enhanced Course Fee Subsidy (90% of Course fee)', '-$2,889.00'],
    ]

    if (isOldInvoice) {
      body.push(['IBF Credit (5% of Course fee)', '-$160.50'])
    }

    doc.autoTable({
      margin: { left: 20 },
      startY: y_info + 100,
      startX: 20,
      theme: 'plain',

      headStyles: {
        lineWidth: 0.2,
        lineColor: 12,
      },
      head: [['Description', 'Amount']],
      body: body,
    })

    doc.setFontSize(12)
    doc.text('Add : GST at 7%', 330, 600)

    doc.setFontSize(12)
    doc.text('$210.00', 480, 600)
    doc.setFontSize(12)
    doc.text('_____________', 440, 605)

    doc.setFontSize(12)
    doc.text('Total Fees and GST', 310, 630)

    doc.setFontSize(12)
    doc.text(isOldInvoice ? '$160.50' : '$321', 480, 630)
    doc.setFontSize(15.8)
    doc.text('__________', 440, 635)

    doc.addImage(images.admin.watermark, 'PNG', 390, 650, 180, 160)

    doc.setFontSize(8)
    doc.text(
      'This is a computer generated invoice, no signature is required',
      20,
      760
    )

    doc.setFontSize(8)
    doc.text('UNICORN Financial Solutions Pte Limited', 240, 800)

    doc.setFontSize(8)
    doc.text('Business Registration No. 200501540R', 245, 812)

    doc.setFontSize(8)
    doc.text('Licensed in Singapore as a Financial Advisor', 235, 824)
  }

  const generateInvoicePDF = () => {
    const doc = new jsPDF('p', 'pt')

    const dateEnrollment = get(partners, 'create_date', '')
    const invoiceNo = `${dayjs(dateEnrollment).format('YYYYMMDD')}-${
      get(course, 'course_code')
        ? get(course, 'course_code', '')
        : get(course, 'id', '')
    }-${
      isAdmin ? get(currentUser, 'partner.id', '') : get(currentUser, 'uid', '')
    }`
    invoice(doc, invoiceNo)

    doc.save(`invoice_${invoiceNo}`)
  }

  return (
    <a className="credit__highlight" onClick={generateInvoicePDF}>
      {isAdmin ? 'Download Invoice' : 'Invoice'}
    </a>
  )
}
export default Invoice
