import styled from 'styled-components'

export const CourseCurriculumListWrap = styled.div`
  background: #fff;
  border: 1px solid #e7e7ec;
  box-sizing: border-box;
  box-shadow: 0px 8px 15px rgba(140, 152, 164, 0.1);
  border-radius: 4px;
  margin-bottom: 30px;
  cursor: pointer;

  .curriculum__head {
    padding: 27px 30px;
  }
  .curriculum__section {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #2f2d51;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 10px;
  }
`
