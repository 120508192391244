import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { showError } from 'utils/notification'
import { isAdminRole, isTrainerRole } from 'utils/userUtils'
import { useLocation } from 'react-router-dom'

const preventTrainerRoute = ['/admin/client-management', '/admin/settings']

const ProtectedRoute = ({ children, ...rest }) => {
  const { pathname } = useLocation()
  const currentUser = useSelector(makeSelectCurrentUser())
  const userGroups = currentUser?.user_groups || []
  const isAdmin = isAdminRole(userGroups)
  const isTrainer = isTrainerRole(userGroups)
  const isOnlyTrainer = isTrainer && !isAdmin

  let validUser = isAdmin || isTrainer
  let redirect = <Redirect to="/auth?login=true" />

  if (!isTrainer && !isAdmin) {
    validUser = false
    showError('You are not allowed to access this page!')
  }

  if (isOnlyTrainer && preventTrainerRoute.includes(pathname)) {
    validUser = false
    redirect = <Redirect to="/admin" />
    showError('You are not allowed to access this page!')
  }

  return <Route {...rest} render={() => (validUser ? children : redirect)} />
}

export default ProtectedRoute
