import React from 'react'
import BtnPrimary from 'component/BtnPrimary'
import { showExportUsers } from 'container/Modal/actions'

const ExportUsers = ({ dispatch, clients }) => {
  const handleExportUser = async () => {
    dispatch(showExportUsers(clients))
  }

  return (
    <BtnPrimary
      name="Export Users"
      className="button__wrap"
      handleClick={handleExportUser}
    />
  )
}

export default ExportUsers
