import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { Select } from 'antd'

import { getSchedules } from '../../../../actions'
import { mapScheduleToDropDown } from 'utils/helper'
import { makeSelectSchedules } from '../../../../selectors'

const EditSchedule = ({
  course,
  courseSchedule,
  setCourseSchedule,
  schedule,
}) => {
  const dispatch = useDispatch()
  const schedules = useSelector(makeSelectSchedules())

  useEffect(() => {
    if (schedule && !courseSchedule) {
      setCourseSchedule(schedule)
    }
  }, [schedule, courseSchedule])

  useEffect(() => {
    if (course) {
      const isAdmin = true
      const id = get(course, 'id', '')
      dispatch(getSchedules(id, isAdmin, null, false, true))
    }
  }, [course])
  return (
    <div>
      <Select
        className="selectSchedule"
        showSearch
        placeholder="Select a schedule"
        optionFilterProp="children"
        onChange={(_, value) => setCourseSchedule(value)}
        value={courseSchedule ? courseSchedule : null}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={mapScheduleToDropDown(schedules, false, false, true)}
        dropdownMatchSelectWidth={false}
        size="small"
      />
    </div>
  )
}

export default EditSchedule
