import React from 'react'
import images from 'Themes/Images'
import styled from 'styled-components'
import { get } from 'lodash'
import axiosInstance from 'utils/axiosInstance'
import { message } from 'antd'
import { UsergroupAddOutlined } from '@ant-design/icons'

const CourseSellingWrapper = styled.div`
  padding: 12px 13px;
  border: 1px solid #e7ebf4;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5a6686;
  margin-bottom: 10px;
  .course__name {
    display: flex;
    justify-content: space-between;
  }
  span.course_name {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 16px;
    letter-spacing: 0.02em;
    margin-left: 5px;
    color: #a0abc3;
  }
`

const ClientCourse = ({
  listCourseOfClient,
  listCourses,
  listDomain,
  reloadClient,
  refetch,
  setSelectedCourse,
  assigneStudentsCourse,
}) => {
  const handleDeleteCourse = async (course) => {
    if (!course?.id) {
      return message.error('No course to delete!')
    }
    await axiosInstance.delete(`/api/clients/channels/${course?.id}`)
    reloadClient && reloadClient()
    refetch && refetch()
    message.success('Delete course success!')
  }

  const handleOpenAssigneModal = async (course) => {
    const matchCourse =
      listCourses && listCourses.find((item) => item?.id === course?.channel_id)

    if (matchCourse) {
      await setSelectedCourse(matchCourse)
      await assigneStudentsCourse(matchCourse)
    }
  }
  return (
    listCourseOfClient &&
    listCourseOfClient.map((course) => {
      const channelId = get(course, 'channel_id')
      const matchCourse =
        listCourses && listCourses.find((item) => item.id === channelId)

      const matchDomain =
        listDomain &&
        listDomain.find((item) => item.id === get(matchCourse, 'website_id'))

      return (
        <CourseSellingWrapper key={course.id}>
          <div className="course__name">
            <div style={{ width: 500 }}>
              {get(matchCourse, 'name', '')}{' '}
              <span className="course_name">
                by {get(matchDomain, 'name', '')}
              </span>
            </div>
            <div onClick={() => handleOpenAssigneModal(course)}>
              <UsergroupAddOutlined
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  paddingLeft: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleDeleteCourse(course)}
            >
              <img src={images.admin.x_inactive} alt="" />
            </div>
          </div>
        </CourseSellingWrapper>
      )
    })
  )
}

export default ClientCourse
