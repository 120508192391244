import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import SideBar from 'component/Sidebar'
import {
  loadSlides,
  loadCourse,
  loadSlideDetails,
} from 'container/Home/actions'
import {
  makeSelectSlides,
  makeSelectSelectedSlide,
} from 'container/Home/selectors'

import ChannelViews from '../../components/ChannelViews'
import HeaderCourseView from './HeaderCourseView'
import {
  loadListUserAnswer,
  getSchedules,
  loadListUserAnswerSuccess,
} from '../../actions'
import { makeSelectListUserAnswer } from '../../selectors'
import { makeSelectCourse } from 'container/Home/selectors'
import { parse } from 'query-string'
import { setSelectedSlide } from 'container/Home/actions'
import { StudentLayout } from 'src/styles'
import './styles.scss'

const CourseView = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { search } = useLocation()
  const { option } = parse(search)
  const [selectedSchedule, setSelectedSchedule] = useState('')
  const dispatch = useDispatch()
  const course = useSelector(makeSelectCourse())
  const slides = useSelector(makeSelectSlides())
  const selectedSlide = useSelector(makeSelectSelectedSlide())
  const listUserAnswer = useSelector(makeSelectListUserAnswer()) || {}
  const { id } = useParams()

  useEffect(() => {
    const isAdmin = true
    dispatch(loadCourse(id))
    dispatch(loadSlides(id, true))
    dispatch(getSchedules(id, isAdmin, false, false, true))
  }, [id])

  useEffect(() => {
    if (selectedSlide) {
      dispatch(loadSlideDetails({ id: get(selectedSlide, 'id') }))
      dispatch(loadListUserAnswerSuccess(null))
    }
  }, [selectedSlide])

  useEffect(() => {
    if (option && option === 'mark_quiz' && slides) {
      Array.isArray(slides) &&
        slides.find((slide) => {
          const item =
            slide &&
            Array.isArray(slide.items) &&
            slide.items.find((data) => {
              if (data.questions && data.questions.length !== 0) {
                return true
              }
            })
          if (item) {
            return dispatch(setSelectedSlide(item))
          }
        })
    }
  }, [option, slides])

  useEffect(() => {
    if (selectedSlide) {
      dispatch(loadListUserAnswer(get(selectedSchedule, 'value', 0)))
    }
  }, [selectedSlide, selectedSchedule])

  return (
    <>
      <HeaderCourseView course={course} />
      <StudentLayout>
        <div className="container_left">
          <SideBar
            slides={slides}
            selectedSlide={selectedSlide}
            selectedSchedule={selectedSchedule}
          />
        </div>
        <div className="container_right">
          <ChannelViews
            selectedSchedule={selectedSchedule}
            listUserAnswer={listUserAnswer}
            setSelectedSchedule={setSelectedSchedule}
          />
        </div>
      </StudentLayout>
    </>
  )
}

export default CourseView
