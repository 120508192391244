import React, { useCallback } from 'react'
import { debounce } from 'lodash'
import images from 'Themes/Images'
import { useDispatch } from 'react-redux'
import { showFormClientModal } from 'container/Modal/actions'

const HeaderClientPage = ({ title, refetch, setSearchValue }) => {
  const dispatch = useDispatch()

  const someFunction = (value) => setSearchValue(value)

  const handler = useCallback(debounce(someFunction, 500), [])

  const onChange = ({ currentTarget }) => {
    handler(currentTarget.value)
  }

  const renderHeaderFunction = () => {
    return (
      <>
        <div className="mgL-20">
          <button className="primary-btn custom-btn" onClick={handleAddNewUser}>
            <img src={images.admin.plus_green} alt="" />
            <span>Add new client</span>
          </button>
        </div>
        <div className="head-filter__right">
          <div className="head-filter__search">
            <img src={images.header.search} alt="search" style={{ top: 11 }} />
            <input
              type="text"
              placeholder="Search Client By Name"
              onChange={onChange}
              style={{ height: 37, cursor: 'auto' }}
            />
          </div>
        </div>
      </>
    )
  }

  const handleAddNewUser = () => {
    dispatch(showFormClientModal({ refetch: refetch }))
  }

  return (
    <div className="header__courses">
      <div className="header__intro" style={{ paddingLeft: 48 }}>
        <div className="header__course">
          <div className="header__course__title__box">
            <div className="header__course__title admin-courses">
              <div className="admin-courses__title">{title}</div>
            </div>
            {renderHeaderFunction()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderClientPage
