import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { get } from 'lodash'
import { DOMAIN } from 'src/routes'
import Admin from 'container/Admin'
import Courses from 'container/Home/screens/Courses'
import AuthPage from 'container/Auth'
import Checkout from 'container/Checkout'
import SearchResult from 'components/SearchResult'
import PageNotFound from 'component/404PageNotFound'
import ResetPassword from 'container/Auth/screens/ResetPassword'
import PrivacyPolicy from 'component/PrivacyPolicy'
import CourseDetails from 'container/Home/screens/CourseDetails'
import CoursePreview from 'container/Home/screens/CoursePreview'
import ProtectedRoute from 'component/ProtectedRoute'
import ProtectedRouteClient from 'component/ProtectedRouteClient'
import CourseListing from '../../customs/ClassCompare/src/screens/CoursesListing'
import ClientCoursesListing from '../../customs/ClassCompare/src/screens/ClientCoursesListing'

import AboutPage from 'container/Home/screens/AboutPage'
import OldCoursePreview from 'container/Home/screens/OldCoursePreview'

const DefaultRoutes = ({ categories, normalRoute, domain }) => {
  const validDomain =
    get(domain, 'domain', '') === DOMAIN.FLC ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO_2 ||
    get(domain, 'domain', '') === DOMAIN.PORTAL ||
    get(domain, 'domain', '') === DOMAIN.THERAPADA

  return (
    <Switch>
      <Route
        exact
        strict
        sensitive
        path="/:url([a-z/]*[A-Z]+[a-z/]*)/"
        render={(props) => {
          const path = props.location.pathname
          return <Redirect to={`${path.toLowerCase()}`} />
        }}
      />
      <Route path="/checkout/:id">
        <Checkout />
      </Route>
      <ProtectedRoute path="/admin">
        <Admin />
      </ProtectedRoute>
      <Route exact path="/coursesearch/result/">
        <SearchResult />
      </Route>
      <Route exact path="/course/channel/:id">
        <CourseDetails />
      </Route>
      <Route exact path="/course/preview/:id">
        {validDomain ? <CoursePreview /> : <OldCoursePreview />}
      </Route>
      <Route exact path="/web/reset_password">
        <ResetPassword />
      </Route>
      <Route exact path="/auth">
        <AuthPage />
      </Route>
      <Route exact path="/terms">
        <PrivacyPolicy />
      </Route>
      <Route path="/category/:id">
        <CourseListing categories={categories} normalRoute={normalRoute} />
      </Route>
      <Route exact path="/pagenotfound">
        <PageNotFound />
      </Route>
      <Route exact path="/about">
        <AboutPage />
      </Route>
      <ProtectedRouteClient exact path="/">
        <Courses />
      </ProtectedRouteClient>
      <Route path="/:client">
        <ClientCoursesListing normalRoute={normalRoute} />
      </Route>
      <Route exact path="/cms" />
      <Route exact path="/cms/:id" />
      <Route path="/article/:slug" />
      <Redirect to="/pagenotfound" />
    </Switch>
  )
}

export default DefaultRoutes
