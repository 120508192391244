import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import images from 'Themes/Images'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { showFormClientModal } from 'container/Modal/actions'
import { Popconfirm } from 'antd'
import axiosInstance from 'utils/axiosInstance'
import { showSuccess } from 'utils/notification'
import { makeSelectListCourse } from 'container/Home/selectors'
import { loadListCourse } from 'container/Home/actions'
import config from 'src/global-config'
import useFirstRender from 'src/hooks/useFirstRender'

const TableClients = ({ page, listUser, perPage = 15, refetch }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const listCourses = useSelector(makeSelectListCourse()) || []
  const isFirstRender = useFirstRender()

  const handleBack = () => {
    history.push(`/admin/client-management?page=${Number(page) - 1}`)
  }

  const handleNext = () => {
    history.push(`/admin/client-management?page=${Number(page) + 1}`)
  }

  const handleViewDetails = (item) => {
    dispatch(showFormClientModal({ client: item, refetch }))
  }

  const removeClient = async (clientID) => {
    try {
      await axiosInstance.delete(`/api/clients/${clientID}`)
      showSuccess(`Delete client succeed!`)
    } catch (error) {
      console.log('~ error', error)
      showSuccess(`Delete client failed!`)
    }
  }

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(loadListCourse({ superAdmin: true, newFilter: 'published=all' }))
    }
  }, [isFirstRender])

  return (
    <>
      <table className="hs-table">
        <thead className="hs-thead">
          <tr className="hs-row">
            <th>Name</th>
            <th>Logo</th>
            <th>Banner</th>
            <th>Assigned Course</th>
            <th>Client's URL</th>
            <th>Added on</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="hs-tbody">
          {listUser &&
            listUser.map((item) => {
              const logo = get(item, 'logo', '')
              const banner = get(item, 'banner', '')
              const lengthCourse = item?.channel_clients?.length || 0
              return (
                <tr key={item.id} className={`hs-row`}>
                  <td
                    className="hs-column"
                    onClick={() => handleViewDetails(item)}
                  >
                    {item.name}
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleViewDetails(item)}
                  >
                    <img src={logo} width={80} />
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleViewDetails(item)}
                  >
                    <img src={banner} width={120} />
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleViewDetails(item)}
                  >
                    <span>{lengthCourse} courses</span>
                  </td>
                  <td className="hs-column">
                    <span>
                      <a
                        href={`${config.baseUrl}/${item?.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        {item?.slug}
                      </a>
                    </span>
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleViewDetails(item)}
                  >
                    {dayjs(item.write_date).format('DD/MM/YYYY')}
                  </td>
                  <td className="hs-column hs-column-cta">
                    <Popconfirm
                      placement="leftTop"
                      title="Do you want to remove this client?"
                      onConfirm={async () => {
                        await removeClient(item.id)
                        await refetch()
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div style={{ padding: '0 15px' }}>
                        <img src={images.admin.trash_icon} alt="" width="10" />
                      </div>
                    </Popconfirm>
                  </td>
                </tr>
              )
            })}
        </tbody>
        <tfoot>
          <tr>
            <td style={{ paddingLeft: '20px' }}>Showing results 1 - 15</td>
            <td colSpan="8">
              <div
                className="pagination"
                style={{
                  justifyContent: 'flex-end',
                  margin: '14px 0',
                }}
              >
                <button
                  className="tertiary-btn"
                  onClick={handleBack}
                  disabled={Number(page) === 1}
                >
                  BACK
                </button>
                <div className="pagination__page">Page</div>
                <input
                  type="text"
                  className="pagination__input"
                  value={page}
                  onChange={() => {}}
                />{' '}
                <div className="pagination__total-course"></div>
                <button
                  className="tertiary-btn"
                  onClick={handleNext}
                  disabled={listUser.length < perPage}
                >
                  NEXT
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  )
}

export default TableClients
