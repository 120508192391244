import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import axiosInstance from 'utils/axiosInstance'

import images from 'Themes/Images'
import { loadListUser, getUserDataSuccess } from '../../actions'
import BtnPrimary from 'component/BtnPrimary'
import ExportUsers from '../../components/Reports/ReportUsers'
import './styles.scss'
import {
  showExportRegistrations,
  showExportInvoices,
  showImportUsers,
  showGlobalLoading,
  hideGlobalLoading,
} from '../../../Modal/actions'
import { showSuccess } from 'utils/notification'
import { getClients } from 'utils/request'
import useGetClientAdmin from 'src/hooks/useGetClientAdmin'
import UserManagementClients from './UserManagementClients'
import UserManagementDefault from './UserManagementDefault'

const { Search } = Input

const defaultFilter = {
  ignore_per_page: true,
  per_page: 100,
  order_by: 'id desc',
}

const UserManagement = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { page } = parse(search)
  const [value, setValue] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [pagination, setPagination] = useState(1)
  const [clients, setClients] = useState(null)
  const { isClientAdmin } = useGetClientAdmin()

  useEffect(() => {
    const getClientsData = async () => {
      const data = await getClients(defaultFilter)
      setClients(data)
    }
    getClientsData()
  }, [])

  const handleSearch = () => {
    if (!isClientAdmin) {
      dispatch(loadListUser({ search: value.trim(), current: pagination }))
    } else {
      setIsSearch(true)
    }
  }

  const FilterByNameInput = (
    <Search
      placeholder="Filter By Email, Name"
      style={{ width: 280 }}
      value={value}
      onChange={(e) => {
        const currValue = e.target.value
        setValue(currValue)
      }}
      onSearch={handleSearch}
    />
  )

  useEffect(() => {
    if (pagination) {
      reloadUsers()
    }
  }, [pagination])

  useEffect(() => {
    if (page) {
      setPagination(page)
    }
  }, [page])

  useEffect(() => {
    dispatch(getUserDataSuccess(null))
  }, [])

  const handleClickCreateUser = () => {
    history.push('/admin/user-management/create')
  }

  const handleExportRegistrations = () => {
    dispatch(showExportRegistrations())
  }
  const handleExportInvoices = () => {
    dispatch(showExportInvoices())
  }

  const handleImportUsers = () => {
    dispatch(showImportUsers())
  }

  const reloadUsers = () => {
    if (!isClientAdmin) dispatch(loadListUser({ current: pagination }))
  }

  const handleResendEmail = async (user) => {
    dispatch(showGlobalLoading())
    try {
      await axiosInstance.post('/api/users/reset-password', {
        login: user.login,
      })

      showSuccess(`Email sent to ${user.login} successfully!`)
      dispatch(hideGlobalLoading())
    } catch (error) {
      showSuccess(`Email sent to ${user.login} failed!`)
      dispatch(hideGlobalLoading())
    }
  }

  return (
    <Row className="u-manage">
      <Col md={24} xs={24}>
        <div className="u-manage__head" style={{ padding: '15px 20px' }}>
          <h1 className="u-manage__heading">User Management</h1>
          <Row className="u-manage__operation">
            <Col xs={24} md={18} style={{ marginBottom: 20 }}>
              <BtnPrimary
                name="Create"
                className="button__wrap"
                icon={images.admin.plus}
                styleIcon={{
                  marginBottom: 2,
                }}
                handleClick={handleClickCreateUser}
              />
              <ExportUsers dispatch={dispatch} clients={clients} />
              <BtnPrimary
                name="Export Registrations"
                className="button__wrap"
                handleClick={handleExportRegistrations}
              />
              <BtnPrimary
                name="Export Invoices"
                className="button__wrap"
                handleClick={handleExportInvoices}
              />
              <BtnPrimary
                name="Import Users"
                className="button__wrap"
                handleClick={handleImportUsers}
              />
            </Col>
            <Col xs={24} md={6}>
              {FilterByNameInput}
            </Col>
          </Row>
        </div>

        {isClientAdmin ? (
          <UserManagementClients
            clients={clients}
            value={value}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
            handleResendEmail={handleResendEmail}
          />
        ) : (
          <UserManagementDefault
            clients={clients}
            handleResendEmail={handleResendEmail}
            pagination={pagination}
            setPagination={setPagination}
            reloadUsers={reloadUsers}
            setValue={setValue}
          />
        )}
      </Col>
    </Row>
  )
}

export default UserManagement
