import React from 'react'
import { SentenceItemWrap } from './styles'
import images from 'Themes/Images'

const SentenceItem = ({
  text,
  id,
  handleChangeText,
  handleRemove,
  handleAddMore
}) => {
  return (
    <div className="flex">
      <SentenceItemWrap>
        <input
          className="sentence__input"
          value={text}
          onKeyPress={(e) => e.key === 'Enter' && handleAddMore()}
          onChange={({ target }) => handleChangeText(target, id)}
        />
        <span className="sentence__word">{text.length || '0'}/50</span>
      </SentenceItemWrap>
      <img
        className="sentence__delete"
        src={images.admin.trash_icon}
        onClick={() => handleRemove(id)}
      />
    </div>
  )
}

export default SentenceItem
