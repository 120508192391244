import React from 'react'
import images from 'Themes/Images'
import { formatMoney } from 'utils/helper'
const CartSummarySFC = ({
  name,
  selectedPriceSFC,
  renderDateBetween,
  isAlwaysAvailable,
  startTime,
  endTime,
  remainingAmount,
  ascendoDomain,
}) => {
  const renderSFCPrice = () => {
    return (
      <div className="cart-summary__head">
        <div className="cart-summary__text" style={{ width: '70%' }}>
          <span>{name}</span>
        </div>
        <div className="cart-summary__price">
          {formatMoney(selectedPriceSFC)}
        </div>
      </div>
    )
  }

  const renderSFCFee = () => {
    return (
      <div className="cart-summary__num">{formatMoney(selectedPriceSFC)}</div>
    )
  }

  const renderPercent = () => {
    return '$0.00'
  }

  const renderTotalSFC = () => {
    return (
      <div className="cart-summary__num-total">
        {formatMoney(selectedPriceSFC)}
      </div>
    )
  }
  return (
    <div className="cart-summary">
      <div className="cart-summary__top">
        <h3 className="hs-checkout__heading-2">Checkout</h3>

        {renderSFCPrice()}

        <div className="cart-summary__schedule">
          <span>{renderDateBetween()}</span>
          <span>{isAlwaysAvailable ? '' : startTime + ' - ' + endTime}</span>
        </div>
      </div>

      <div className="cart-summary__total_wrap">
        <div className="cart-summary__total">
          <div className="cart-summary__text">
            <span>Sub-total</span>
          </div>
          {renderSFCFee()}
        </div>
        <div className="cart-summary__total" style={{ marginBottom: 18 }}>
          <div className="cart-summary__text">
            <span>Discount applied</span>
          </div>
          <div className="cart-summary__num">{renderPercent()}</div>
        </div>
        <div className="cart-summary__total">
          <div className="cart-summary__text">
            <img
              src={images.admin.shopping_bag}
              alt=""
              style={{ marginRight: 8, marginBottom: 5 }}
            />

            <span>
              {ascendoDomain ? 'Order total (inclusive of GST)' : 'Your total:'}{' '}
            </span>
          </div>
          {renderTotalSFC()}
        </div>
      </div>

      <div className="cart-summary__total_wrap">
        <div className="cart-summary__total">
          <div className="cart-summary__text">
            <span>Paid</span>
          </div>
          <div className="cart-summary__num-total">
            {formatMoney(remainingAmount)}
          </div>
        </div>
        <div className="cart-summary__total" style={{ marginBottom: 18 }}>
          <div className="cart-summary__text">
            <span>To be paid</span>
          </div>
          <div className="cart-summary__num">
            <div className="cart-summary__num-total">
              {formatMoney(+selectedPriceSFC - +remainingAmount)}
            </div>
          </div>
        </div>
        <div className="cart-summary__total" style={{ fontSize: 12 }}>
          Please pay the remaining amount to proceed with enrollment or contact
          the course provider for help
        </div>
      </div>
    </div>
  )
}

export default CartSummarySFC
