import React, { useState } from 'react'
import images from 'Themes/Images'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Drawer } from 'antd'
import { useMediaQuery } from 'react-responsive'
import LeadDetailPage from '../../container/AdminActor/screens/LeadDetailPage'
import TableBody from '../TableBody/index'

const TableLeads = ({ page, listCollection, perPage, listDomain, filter }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [visible, setVisible] = useState(false)

  const history = useHistory()

  const handleBack = () => {
    history.push(`/admin?page=${Number(page) + -1}`)
  }

  const handleNext = () => {
    history.push(`/admin?page=${Number(page) + 1}`)
  }

  const handleViewDetails = (item) => {
    setSelectedCustomer(item)
    showDrawer()
  }

  const handleChange = () => {}

  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setSelectedCustomer(null)
    setVisible(false)
  }

  return (
    <>
      <Drawer
        width={isMobile ? 300 : 520}
        title={get(selectedCustomer, 'name', '')}
        placement="right"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: 0 }}
        closeIcon={
          <div>
            <img src={images.admin.x_active} alt="" />
          </div>
        }
      >
        <LeadDetailPage
          selectedCustomer={selectedCustomer}
          onClose={onClose}
          page={page}
          perPage={perPage}
          filter={filter}
        />
      </Drawer>
      <div style={{ boxShadow: '0 8px 16px rgba(0, 0, 0, 0.08)' }}>
        <table className="hs-table-custom">
          <thead className="hs-thead-custom">
            <tr className="hs-row-custom">
              <th className="name">Name</th>
              <th className="course_name">Course</th>
              <th className="classcode">Class Course</th>
              <th className="schedule">Schedule</th>
              <th className="date_enroll">Date Enrolled</th>
              <th className="email">Email</th>
              <th className="phone">Phone Number</th>
              <th className="sales">Sales agent</th>
              <th className="profiles">View User Profile</th>
              <th className="details">View Registration Details</th>
            </tr>
          </thead>
          <tbody className="hs-tbody-custom">
            {listCollection &&
              listCollection.map((item) => (
                <TableBody
                  key={item.id}
                  item={item}
                  handleViewDetails={handleViewDetails}
                  listDomain={listDomain}
                />
              ))}
          </tbody>
        </table>
        <div className="table-pagination">
          <div style={{ whiteSpace: 'nowrap' }}>Showing results 1 - 15</div>
          <div colSpan="4">
            <div
              className="pagination"
              style={{
                justifyContent: 'flex-end',
                margin: '14px 0'
              }}
            >
              <button
                className="tertiary-btn"
                onClick={handleBack}
                disabled={Number(page) === 1}
              >
                BACK
              </button>
              <div className="pagination__page">Page</div>
              <input
                type="text"
                className="pagination__input"
                value={page}
                onChange={handleChange}
              />
              <div className="pagination__total-course"></div>
              <button
                className="tertiary-btn"
                onClick={handleNext}
                disabled={listCollection && listCollection.length < perPage}
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TableLeads
