import React, { useEffect } from 'react'
import styled from 'styled-components'
import images from 'Themes/Images'

const SFCPriceItemWrapper = styled.div`
  background-color: ${(props) => (props.claim ? '#5A6686' : '#00122a')};
  width: 100%;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  img {
    padding-left: 8px;
  }
`
const SFCPriceItem = ({
  claimed,
  handleClaim,
  price,
  i,
  complete,
  setComplete,
}) => {
  useEffect(() => {
    if (claimed) {
      setComplete(complete + 1)
    }
  }, [claimed])
  return (
    <SFCPriceItemWrapper
      onClick={() => (claimed ? () => {} : handleClaim(price, i))}
      claim={claimed}
      key={i}
    >
      <span>
        {claimed
          ? `SFC claimed for Module ${i + 1}`
          : `Claim SFC for Module ${i + 1}`}
      </span>
      <img
        src={claimed ? images.header.check : images.course.arrow_right_active}
        alt=""
      />
    </SFCPriceItemWrapper>
  )
}

export default SFCPriceItem
