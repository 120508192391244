import React from 'react'
import './styles.scss'

const BtnPrimary = ({
  name,
  handleClick,
  icon,
  style,
  className,
  iconLeft,
  type,
  styleIcon,
  disabled,
  styleIconLeft,
  btnRef,
}) => {
  return (
    <button
      ref={btnRef}
      className={`btn--primary ${className}`}
      onClick={handleClick}
      style={style}
      type={type}
      disabled={disabled}
    >
      {iconLeft && (
        <img
          src={iconLeft}
          alt=""
          className="icon__left"
          style={styleIconLeft}
        />
      )}
      <span>{name}</span>
      {icon && (
        <img src={icon} alt="" className="icon__right" style={styleIcon} />
      )}
    </button>
  )
}

export default BtnPrimary
