import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import CheckoutProgressBar from '../../components/CheckoutProgressBar'
import BtnPrimary from 'component/BtnPrimary'
import images from 'Themes/Images'
import { get } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { setRef } from 'container/Home/actions'
import { CKEditorWrapper } from 'container/CMS/screens/Article'
import EditorParse from 'html-react-parser'
import { useSelector } from 'react-redux'
import { makeSelectInvoiceNumber } from '../../selectors'
import { getInvoiceNumberSuccess } from '../../actions'

const Complete = ({
  course,
  history,
  firstMediaDomain,
  cyberQuoteDomain,
  refId,
  dispatch,
  isUnicornDomain,
  requiredSchedule,
  isSingaporeSkillsAcademies,
  wfaDomain,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const enrollMessage = get(course, 'enroll_msg', '')
  const invoiceNumber = useSelector(makeSelectInvoiceNumber())

  useEffect(() => {}, [])
  const renderIndex = () => {
    if (!requiredSchedule) {
      return 3
    }
    return 4
  }

  useEffect(() => {
    if (refId) {
      dispatch(setRef(null))
    }
  }, [refId])

  useEffect(() => {
    if (invoiceNumber) {
      dispatch(getInvoiceNumberSuccess(''))
    }
  }, [invoiceNumber])

  const handleNext = () => {
    if (wfaDomain) {
      return history.push(`/course/channel/${get(course, 'id', '')}`)
    }
    return history.push(`/`)
  }
  const renderText = () => {
    if (wfaDomain) {
      return 'Go to course now'
    }
    return 'Go back to course list'
  }

  return (
    <div>
      <CheckoutProgressBar
        complete={true}
        indexActive={renderIndex()}
        cyberQuoteDomain={cyberQuoteDomain}
        isUnicornDomain={isUnicornDomain}
        firstMediaDomain={firstMediaDomain}
        requiredSchedule={requiredSchedule}
        isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
      />
      {course && (
        <Row className="hs-billing" gutter={70}>
          <Col
            xs={24}
            md={24}
            style={{
              paddingBottom: 40,
              marginBottom: 50,
              borderBottom: '1px solid #d0cad8',
            }}
          >
            {enrollMessage ? (
              <CKEditorWrapper pColor="black">
                {EditorParse(enrollMessage)}
              </CKEditorWrapper>
            ) : (
              <div
                className="hs-confirm__heading"
                style={{ marginBottom: 8, lineHeight: '32px' }}
              >
                You have successfully enrolled to {get(course, 'name', '')}
              </div>
            )}
          </Col>
          <Col xs={24} md={24}>
            <div
              style={{
                display: !isMobile ? 'flex' : 'block',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <div />
              <BtnPrimary
                name={renderText()}
                icon={images.course.arrow_right_active}
                style={{
                  backgroundColor: '#00122A',
                  fontWeight: 600,
                  padding: '12.5px 40px',
                  width: isMobile ? '100%' : '',
                  marginTop: isMobile ? 20 : 0,
                }}
                styleIcon={{ marginBottom: 0 }}
                handleClick={handleNext}
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default Complete
