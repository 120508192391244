import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { isEmpty, get } from 'lodash'
import { Row, Col } from 'antd'
import BtnPrimary from 'component/BtnPrimary'
import images from 'Themes/Images'

import * as profiles from 'container/Checkout/components/ProfileBySchool/schema'
import config from 'src/global-config'
import CheckoutProgressBar from '../../components/CheckoutProgressBar'
import ProfileArtofNumbers from '../../components/ProfileBySchool/ProfileArtofNumbers'
import ProfileAgbEducation from '../../components/ProfileBySchool/ProfileAgbEducation'
import ProfileFirstMedia from '../../components/ProfileBySchool/ProfileFirstMedia'
import ProfileUnicorn from '../../components/ProfileBySchool/ProfileUnicorn'
import { showError, showSuccess } from 'utils/notification'
import { getUserInfo } from 'container/Home/actions'
import { initialNational, initialLevel } from 'utils/constants'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import ProfileDefault from '../../components/ProfileBySchool/ProfileDefault'
import { getAccessToken } from 'utils/request'
import { validateNric } from 'utils/helper'
import { postEnroll } from 'container/Home/actions'
import ProfileCourses from '../../components/ProfileBySchool/ProfileCourses'
import ProfileCyberQuote from '../../components/ProfileBySchool/CyberQuote'
import ProfileFlc from '../../components/ProfileBySchool/ProfileFlc'
import { parseSafe } from 'utils/helper'
import './styles.scss'
import { DOMAIN } from '../../../../routes/index'
import { CUSTOM_COURSE, CUSTOM_COURSE_2 } from 'utils/constants'

const CompleteProfile = ({
  course,
  history,
  dispatch,
  userInfo,
  currentUser,
  schedule,
  selectedSchedule,
  isUnicornDomain,
  allQuantDomain,
  firstMediaDomain,
  coursesDomain,
  isArtofnumbersDomain,
  isAgbEducation,
  cyberQuoteDomain,
  ref,
  isFlcDomain,
  isPortalDomain,
  requiredSchedule,
  isSingaporeSkillsAcademies,
  handlePaymentMethodFreeCourse,
  isDigitalWorkForceDomain,
  domain,
  isEnrol,
  ascendoDomain,
}) => {
  const channelId = get(course, 'id')
  const price = get(course, 'price')
  const isAllowApplicationFee = get(course, 'is_application_fee', false)
  const applicationFee = get(course, 'application_fee', 0)
  const [termCondition, setTermCondition] = useState(false)
  const [checkRule, setCheckRule] = useState(false)
  const isClassCompareDomain =
    get(domain, 'domain', '') === DOMAIN.COURSES_CLASS_COMPARE
  const customCourseFLC = get(course, 'id') === CUSTOM_COURSE
  const customCourseFLC_2 = get(course, 'id') === CUSTOM_COURSE_2

  const isLMADomain = domain && domain.domain === DOMAIN.LMA

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  const attachments = get(userInfo, 'attachments', [])

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      const birthDay = get(userInfo, 'birthday', '')
        ? dayjs(get(userInfo, 'birthday', ''), 'DD-MM-YYYY')
        : ''

      const additionalData = parseSafe(get(userInfo, 'additional_info', ''))

      if (additionalData) {
        formik.setFieldValue('company', get(additionalData, 'company', ''))
        formik.setFieldValue(
          'referred_name',
          get(additionalData, 'referred_name', '')
        )
        formik.setFieldValue(
          'referred_mobile',
          get(additionalData, 'referred_mobile', '')
        )
        formik.setFieldValue(
          'referred_co',
          get(additionalData, 'referred_co', '')
        )

        formik.setFieldValue('title', get(additionalData, 'title', ''))

        formik.setFieldValue(
          'professional_designation',
          get(additionalData, 'professional_designation', '')
        )

        formik.setFieldValue(
          'highest_academic_qualifications',
          get(additionalData, 'highest_academic_qualifications', '')
        )

        formik.setFieldValue(
          'marital_status',
          get(additionalData, 'marital_status', '')
        )

        formik.setFieldValue(
          'personal_email',
          get(additionalData, 'personal_email', '')
        )

        formik.setFieldValue(
          'company_email',
          get(additionalData, 'company_email', '')
        )

        formik.setFieldValue(
          'question_1',
          get(additionalData, 'question_1', '')
        )

        formik.setFieldValue(
          'question_2',
          get(additionalData, 'question_2', '')
        )

        formik.setFieldValue(
          'question_3',
          get(additionalData, 'question_3', '')
        )

        formik.setFieldValue(
          'question_3',
          get(additionalData, 'question_3', '')
        )

        formik.setFieldValue(
          'question_4',
          get(additionalData, 'question_4', '')
        )
        formik.setFieldValue(
          'question_5',
          get(additionalData, 'question_5', '')
        )

        formik.setFieldValue(
          'question_6',
          get(additionalData, 'question_6', '')
        )

        formik.setFieldValue(
          'question_6_2',
          get(additionalData, 'question_6_2', '')
        )

        formik.setFieldValue(
          'question_7',
          get(additionalData, 'question_7', '')
        )

        formik.setFieldValue(
          'question_above_40',
          get(additionalData, 'question_above_40', '')
        )
        formik.setFieldValue(
          'user_billing_address',
          get(additionalData, 'user_billing_address', '')
        )
      }
      formik.setFieldValue('date_of_birth', birthDay)
      formik.setFieldValue('id_type', get(userInfo, 'designation', ''))
      formik.setFieldValue('email', get(userInfo, 'email', ''))
      formik.setFieldValue('race', get(userInfo, 'race', ''))
      formik.setFieldValue('phone', get(userInfo, 'phone', ''))
      formik.setFieldValue('gender', get(userInfo, 'gender', ''))
      formik.setFieldValue('passport', get(userInfo, 'passport', ''))
      formik.setFieldValue('year_exp', get(userInfo, 'experience', ''))
      formik.setFieldValue('occupation', get(userInfo, 'occupation', ''))
      formik.setFieldValue('address', get(userInfo, 'postal_address', ''))
      formik.setFieldValue('postTalCode', get(userInfo, 'postal_code', ''))
      formik.setFieldValue('emergency_contact', get(userInfo, 'contact', ''))
      formik.setFieldValue('salutation', get(userInfo, 'salutation', ''))
      formik.setFieldValue('unit_number', get(userInfo, 'unit_number', ''))
      formik.setFieldValue('is_experience', get(userInfo, 'is_experience', ''))

      const nationality = get(userInfo, 'nationality', '')
      const otherNational = initialNational.find(
        (item) => item.label === nationality
      )

      if (!otherNational && nationality) {
        formik.setFieldValue('nationality', 'Other')
        formik.setFieldValue(
          'other_nationality',
          get(userInfo, 'nationality', '')
        )
      } else {
        formik.setFieldValue('nationality', nationality)
      }

      const education_level = get(userInfo, 'education_level', '')
      const otherLevel = initialLevel.find(
        (item) => item.label === education_level
      )

      if (!otherLevel && education_level) {
        formik.setFieldValue('education_level', 'Other')
        formik.setFieldValue('other_level', education_level)
      } else {
        formik.setFieldValue('education_level', education_level)
      }
    } else if (currentUser) {
      const mail = get(currentUser, 'username') || get(currentUser, 'login')
      formik.setFieldValue('email', mail)
    }
  }, [userInfo])

  useEffect(() => {
    if (isLMADomain) {
      formik.setFieldValue('name', currentUser?.name)
      setTimeout(() => {
        formik.submitForm()
      }, 1000)
    }
  }, [isLMADomain, currentUser])

  const getSchemaValidate = () => {
    if (isArtofnumbersDomain) {
      return profiles.ProfileArtofNumbersSchema
    }

    if (isAgbEducation) {
      return profiles.ProfileAgbEducationSchema
    }

    if (isUnicornDomain) {
      return profiles.ProfileUnicornSchema
    }

    if (cyberQuoteDomain) {
      return profiles.ProfileCyberQuoteSchema
    }

    if (firstMediaDomain) {
      return profiles.ProfileFirstMediaSchema
    }
    if (coursesDomain) {
      return profiles.ProfileCoursesSchema
    }

    if (isFlcDomain || isPortalDomain) {
      if (customCourseFLC) {
        return profiles.ProfileFlcCustomSchema
      }

      if (customCourseFLC_2) {
        return profiles.ProfileFlcCustom2Schema
      }

      return profiles.ProfileFlcSchema
    }
    return profiles.ProfileDefaultSchema
  }

  const formik = useFormik({
    validationSchema: getSchemaValidate(),
    initialValues: {
      email: '',
      name: '',
      race: '',
      phone: '',
      address: '',
      passport: '',
      year_exp: '',
      occupation: '',
      gender: 'male',
      postTalCode: '',
      nationality: '',
      other_level: '',
      date_of_birth: '',
      education_level: '',
      other_nationality: '',
      emergency_contact: '',
      id_type: '',
      is_experience: false,
      salutation: '',
      company: '',
      referred_name: '',
      referred_mobile: '',
      referred_co: '',
      title: '',
      professional_designation: '',
      highest_academic_qualifications: '',
      marital_status: '',
      personal_email: '',
      company_email: '',
      question_1: '',
      question_2: '',
      question_3: '',
      question_4: '',
      question_5: '',
      question_6: '',
      question_6_2: '',
      question_7: '',
      question_above_40: '',
      user_billing_address: '',
    },
    onSubmit: async (values) => {
      if (
        (coursesDomain || firstMediaDomain) &&
        values.id_type === 'NRIC' &&
        !validateNric(values.passport)
      ) {
        return formik.setFieldError(
          'passport',
          'Please input correct nric format (eg. S9576291C)'
        )
      }

      if (isFlcDomain && !checkRule && !customCourseFLC_2) {
        return showError(
          'You must check the declaration by clicking the checkbox.'
        )
      }
      if (
        !termCondition &&
        (isUnicornDomain || firstMediaDomain || isFlcDomain) &&
        !customCourseFLC &&
        !customCourseFLC_2
      ) {
        return showError('You need to click check term & condition')
      }
      const userId = get(currentUser, 'uid', '') || get(currentUser, 'id')
      let body = {
        name: values.name,
        email: currentUser?.username,
        ...(ascendoDomain && { passport: values.passport }),
      }

      if (cyberQuoteDomain) {
        body = {
          name: values.name,
          birthday:
            dayjs(values.date_of_birth).isValid() &&
            dayjs(values.date_of_birth).format('DD-MM-YYYY'),
          nationality:
            values.nationality === 'Other'
              ? values.other_nationality
              : values.nationality,
          passport: values.passport,
          phone: values.phone,
          postal_address: values.address,
          postal_code: values.postTalCode,
          occupation: values.occupation,
          user_id: userId,
          unit_number: values.unit_number,
        }
      }

      if (isArtofnumbersDomain) {
        body = {
          gender: values.gender,
          birthday:
            dayjs(values.date_of_birth).isValid() &&
            dayjs(values.date_of_birth).format('DD-MM-YYYY'),
          phone: values.phone,
          user_id: userId,
        }
      }

      if (coursesDomain) {
        body = {
          name: values.name,
          gender: values.gender,
          birthday:
            dayjs(values.date_of_birth).isValid() &&
            dayjs(values.date_of_birth).format('DD-MM-YYYY'),
          nationality:
            values.nationality === 'Other'
              ? values.other_nationality
              : values.nationality,
          passport: values.passport,
          phone: values.phone,
          designation: values.id_type,
          salutation: values.salutation,
          is_experience: values.is_experience,
          user_id: userId,
        }
      }

      if (isUnicornDomain) {
        let additional_info = JSON.stringify({
          other_level: values.other_level,
        })
        body = {
          name: values.name,
          gender: values.gender,
          birthday:
            dayjs(values.date_of_birth).isValid() &&
            dayjs(values.date_of_birth).format('DD-MM-YYYY'),
          nationality:
            values.nationality === 'Other'
              ? values.other_nationality
              : values.nationality,
          race: values.race,
          passport: values.passport,
          phone: values.phone,
          contact: values.emergency_contact,
          postal_address: values.address,
          postal_code: values.postTalCode,
          occupation: values.occupation,
          education_level:
            values.education_level === 'Other'
              ? values.other_level
              : values.education_level,
          experience: parseInt(values.year_exp),
          user_id: userId,
          unit_number: values.unit_number,
          additional_info,
        }
      }

      if (isFlcDomain || isPortalDomain) {
        let additional_info = JSON.stringify({
          company: values.company,
          referred_name: values.referred_name,
          referred_mobile: values.referred_mobile,
          referred_co: values.referred_co,
          title: values.title,
          professional_designation: values.professional_designation,
          highest_academic_qualifications:
            values.highest_academic_qualifications,
          marital_status: values.marital_status,
          personal_email: values.personal_email,
          company_email: values.company_email,
          question_1: values.question_1,
          question_2: values.question_2,
          question_3: values.question_3,
          question_4: values.question_4,
          question_5: values.question_5,
          question_6: values.question_6,
          question_6_2: values.question_6_2,
          question_7: values.question_7,
          question_above_40: values.question_above_40,
          user_billing_address: values.user_billing_address,
        })

        body = {
          name: values.name,
          gender: values.gender,
          birthday:
            dayjs(values.date_of_birth).isValid() &&
            dayjs(values.date_of_birth).format('DD-MM-YYYY'),
          nationality:
            values.nationality === 'Other'
              ? values.other_nationality
              : values.nationality,
          passport: values.passport,
          phone: values.phone,
          occupation: values.occupation,
          education_level:
            values.education_level === 'Other'
              ? values.other_level
              : values.education_level,
          user_id: userId,
          nric_front: get(values, 'nric_front.img', 'false'),
          nric_back: get(values, 'nric_back.img', 'false'),
          additional_info,
        }
      }

      if (isAgbEducation) {
        body = {
          name: values.name,
          passport: values.passport,
          phone: values.phone,
          user_id: userId,
        }
      }

      if (firstMediaDomain) {
        body = {
          name: values.name,
          gender: values.gender,
          birthday:
            dayjs(values.date_of_birth).isValid() &&
            dayjs(values.date_of_birth).format('DD-MM-YYYY'),
          nationality:
            values.nationality === 'Other'
              ? values.other_nationality
              : values.nationality,
          passport: values.passport,
          phone: values.phone,
          designation: values.id_type,
          postal_address: values.address,
          postal_code: values.postTalCode,
          user_id: userId,
        }
      }

      dispatch(showGlobalLoading())

      body = {
        ...body,
        email:
          get(currentUser, 'username', '') ||
          get(currentUser, 'partner.email', ''),
        is_send: true,
        channel_id: channelId,
        partner_id: get(currentUser, 'partner_id', ''),
        schedule_id: get(selectedSchedule, 'id', ''),
        website_id: course?.website_id,
      }

      if (!get(selectedSchedule, 'id', '')) {
        delete body.schedule_id
      }

      try {
        const res = await fetch(
          `${config.baseUrl}/api/users/${userId}/profiles`,
          {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${getAccessToken()}`,
            },
            body: JSON.stringify(
              isEmpty(userInfo)
                ? { ...body }
                : { ...body, id: get(userInfo, 'id') }
            ),
          }
        )

        const data = await res.json()
        if (data.data.id) {
          showSuccess('Complete Your Profile!')

          await dispatch(getUserInfo())
          dispatch(hideGlobalLoading())

          if (isEnrol) {
            return history.push(`/checkout/${get(course, 'id')}/complete`)
          }
          if (
            (price === 0 ||
              (isAllowApplicationFee && applicationFee === 0) ||
              isDigitalWorkForceDomain) &&
            !firstMediaDomain &&
            !cyberQuoteDomain &&
            !isUnicornDomain
          ) {
            const setPayment = await handlePaymentMethodFreeCourse()
            if (setPayment) {
              dispatch(showGlobalLoading())
              setTimeout(() => {
                dispatch(
                  postEnroll({ schedule: selectedSchedule, ref, is_send: true })
                )
                dispatch(hideGlobalLoading())
              }, 1500)
            }
            return
          }

          return history.push(
            `/checkout/${get(course, 'id', '')}/billing?schedule=${schedule}`
          )
        }
        dispatch(hideGlobalLoading())
      } catch (err) {
        dispatch(hideGlobalLoading())
        console.log('err', err)
      }
    },
  })

  const handleNumberInput = (currentTarget, field) => {
    let regExp = /^[0-9]{1,15}$/

    if (field === 'year_exp') {
      regExp = /^[0-9]{1,3}$/
    }

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue(field, currentTarget.value)
    }
  }

  const handleGetFile = (img, name, field) => {
    formik.setFieldValue(field, { img, name })
  }

  const handleRemoveFile = (field) => {
    formik.setFieldValue(field, null)
  }

  const renderFieldProfile = () => {
    if (firstMediaDomain) {
      return (
        <ProfileFirstMedia
          isClassCompareDomain={isClassCompareDomain}
          formik={formik}
          handleNumberInput={handleNumberInput}
          setTermCondition={setTermCondition}
          termCondition={termCondition}
        />
      )
    }

    if (coursesDomain) {
      return (
        <ProfileCourses formik={formik} handleNumberInput={handleNumberInput} />
      )
    }

    if (isAgbEducation) {
      return (
        <ProfileAgbEducation
          formik={formik}
          handleNumberInput={handleNumberInput}
          attachments={attachments}
          handleGetFile={handleGetFile}
          handleRemoveFile={handleRemoveFile}
        />
      )
    }

    if (isUnicornDomain) {
      return (
        <ProfileUnicorn
          isClassCompareDomain={isClassCompareDomain}
          formik={formik}
          handleNumberInput={handleNumberInput}
          attachments={attachments}
          handleGetFile={handleGetFile}
          handleRemoveFile={handleRemoveFile}
          setTermCondition={setTermCondition}
          termCondition={termCondition}
        />
      )
    }

    if (isFlcDomain || isPortalDomain) {
      return (
        <ProfileFlc
          isClassCompareDomain={isClassCompareDomain}
          formik={formik}
          handleNumberInput={handleNumberInput}
          attachments={attachments}
          handleGetFile={handleGetFile}
          handleRemoveFile={handleRemoveFile}
          setTermCondition={setTermCondition}
          termCondition={termCondition}
          setCheckRule={setCheckRule}
          checkRule={checkRule}
          course={course}
        />
      )
    }

    if (isArtofnumbersDomain) {
      return (
        <ProfileArtofNumbers
          formik={formik}
          handleNumberInput={handleNumberInput}
        />
      )
    }

    if (cyberQuoteDomain) {
      return (
        <ProfileCyberQuote
          formik={formik}
          handleNumberInput={handleNumberInput}
        />
      )
    }

    return <ProfileDefault formik={formik} ascendoDomain={ascendoDomain} />
  }

  const renderIndex = () => {
    if (!requiredSchedule) {
      return 1
    }
    return 2
  }

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <CheckoutProgressBar
        complete={true}
        indexActive={renderIndex()}
        firstMediaDomain={firstMediaDomain}
        cyberQuoteDomain={cyberQuoteDomain}
        isUnicornDomain={isUnicornDomain}
        requiredSchedule={requiredSchedule}
        isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
      />
      {renderFieldProfile()}
      <Row className="hs-billing" gutter={70}>
        <Col xs={24} md={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 15,
            }}
          >
            <BtnPrimary
              name="Submit Profile"
              icon={images.course.arrow_right_active}
              style={{
                backgroundColor: '#00122A',
                fontWeight: 800,
                padding: '12px 40px',
              }}
              styleIcon={{ marginBottom: 2 }}
              type="submit"
            />
          </div>
        </Col>
      </Row>
    </form>
  )
}

export default CompleteProfile
