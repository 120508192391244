import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'

import images from 'Themes/Images'
import { SLIDE_TYPE } from 'utils/constants'
import { makeSelectAnswerTime } from 'container/Home/selectors'
import { setSelectedQuiz, loadUserAnswerSuccess } from 'container/Home/actions'
import classNames from 'classnames'

const SideBarList = ({
  slide,
  previews,
  dispatch,
  sidebarRef,
  useSelector,
  currentUser,
  handleToggle,
  selectedSlide,
  setPageNumber,
  handleClickQuiz,
  handleClickSlide,
}) => {
  const [quizSelect, setQuizSelect] = useState(false)
  const items = get(slide, 'items', [])
  const answerTime = useSelector(makeSelectAnswerTime())

  const { search } = useLocation()
  const { quiz } = parse(search)

  useEffect(() => {
    if (quiz) {
      setQuizSelect(true)
    } else {
      setQuizSelect(false)
    }
  }, [quiz])

  const renderSourceIcon = () => {
    return <img src={images.header.link} alt="" />
  }

  const renderSideBarIcon = (item, activeSlide, blocked, completedLesson) => {
    if (blocked) {
      return <img src={images.header.blocked_lesson} alt="" />
    }
    let sideBarIcon = null
    switch (item.slide_type) {
      case SLIDE_TYPE.INFOGRAPHIC:
        if (activeSlide || completedLesson) {
          sideBarIcon = images.header.image_active
        } else {
          sideBarIcon = images.header.image
        }
        break
      case SLIDE_TYPE.VIDEO:
        if (activeSlide || completedLesson) {
          sideBarIcon = images.header.play_active
        } else {
          sideBarIcon = images.header.play
        }
        break
      case SLIDE_TYPE.PRESENTATION:
        if (activeSlide || completedLesson) {
          sideBarIcon = images.header.file_pdf_active
        } else {
          sideBarIcon = images.header.file_pdf
        }
        break

      case SLIDE_TYPE.SCORM:
        if (activeSlide || completedLesson) {
          sideBarIcon = images.header.file_pdf_active
        } else {
          sideBarIcon = images.header.file_pdf
        }
        break

      default:
        break
    }
    return <img src={sideBarIcon} alt="" />
  }

  const renderTickComplete = (id) => {
    if (!Array.isArray(previews)) {
      return
    }
    const indexSlide = previews.findIndex((item) => item.slide_id === id)
    const isComplete = get(previews, `[${indexSlide}].completed`, false)

    return (
      currentUser &&
      isComplete && (
        <div className="sidebar__icon ml-auto">
          <img src={images.header.check} alt="" />
        </div>
      )
    )
  }

  const renderTickQuizComplete = (id) => {
    if (!Array.isArray(answerTime)) {
      return null
    }

    const indexSlide = answerTime.findIndex((item) => item.slide_id === id)
    const isComplete = get(answerTime, `[${indexSlide}].end_date`, false)

    return (
      currentUser &&
      isComplete && (
        <div className="sidebar__icon ml-auto">
          <img src={images.header.check} alt="" />
        </div>
      )
    )
  }

  const renderQuizIcon = (activeSlideQuiz, blocked, completedLesson) => {
    if (blocked) {
      return <img src={images.header.blocked_lesson} alt="" />
    }

    return (
      <img
        src={
          activeSlideQuiz || completedLesson
            ? images.header.poll_h_active_circle
            : images.header.poll_h_circle
        }
        alt=""
      />
    )
  }
  if (items.length === 0) {
    return null
  }

  return (
    <div className="sidebar__block">
      {slide.is_category && (
        <div
          className="sidebar__title"
          onClick={() => handleToggle(slide)}
          style={{ color: '#27284d' }}
        >
          {slide.name}
        </div>
      )}
      <ul
        className={`${
          slide.is_toggle ? 'toggle sidebar__list' : 'sidebar__list'
        }`}
      >
        {items.map((item) => {
          const activeSlide =
            selectedSlide && selectedSlide.id === item.id && !quizSelect
          const activeSlideQuiz =
            selectedSlide && selectedSlide.id === item.id && quizSelect
          const blocked = item?.blocked_lesson

          const completedLesson =
            Array.isArray(previews) &&
            previews.find(
              (preview) => preview.slide_id === item.id && preview.completed
            )

          const showQuizOnly = get(item, 'show_quiz_only', false)
          return (
            <li
              key={item.id}
              ref={(ref) => {
                if (selectedSlide?.id === item.id) {
                  sidebarRef.current[item.id] = ref
                }
              }}
            >
              {!showQuizOnly && (
                <div
                  className={classNames({
                    sidebar__topic: !blocked,
                    'sidebar__topic-blocked': blocked,
                    'sidebar__topic-active': activeSlide,
                  })}
                >
                  <div
                    className="sidebar__topic-wrap"
                    onClick={() => {
                      if (!blocked) {
                        handleClickSlide(item)
                        setQuizSelect(false)
                        dispatch(setSelectedQuiz(null))
                        dispatch(loadUserAnswerSuccess(null))
                        if (typeof setPageNumber === 'function')
                          setPageNumber(1)
                      }
                    }}
                  >
                    <div className="sidebar__icon">
                      {renderSideBarIcon(
                        item,
                        activeSlide,
                        blocked,
                        completedLesson
                      )}
                    </div>
                    <div
                      className={classNames('sidebar__topic__title', {
                        sidebar__topic__title__active: completedLesson,
                      })}
                    >
                      {item.name}
                    </div>

                    {renderTickComplete(item.id)}
                  </div>
                  <ul>
                    {item.links.map((link) => (
                      <a
                        href={link.link}
                        target="_blank"
                        key={link.id}
                        rel="noopener noreferrer"
                      >
                        <li className="sidebar__source">
                          <div className="sidebar__source__icon">
                            {renderSourceIcon(link)}
                          </div>
                          <div className="sidebar__source__title">
                            {link.name}
                          </div>
                        </li>
                      </a>
                    ))}
                  </ul>
                </div>
              )}

              {item.questions.length !== 0 && (
                <div
                  className={classNames({
                    sidebar__topic: !blocked,
                    'sidebar__topic-blocked': blocked,
                    'sidebar__topic-active': activeSlideQuiz,
                  })}
                  onClick={() => {
                    if (!blocked) {
                      handleClickQuiz(item)
                      setQuizSelect(true)
                    }
                  }}
                >
                  <div className="sidebar__topic-wrap">
                    <div className="sidebar__icon">
                      {renderQuizIcon(
                        activeSlideQuiz,
                        blocked,
                        completedLesson
                      )}
                    </div>
                    <div
                      className={classNames('sidebar__source__title', {
                        sidebar__source__title__active: activeSlideQuiz,
                        sidebar__source__title__completed: completedLesson,
                      })}
                    >
                      {showQuizOnly
                        ? item.name
                        : `Quiz (${item.questions.length} questions)`}
                    </div>
                    {renderTickQuizComplete(item.id)}
                  </div>
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SideBarList
