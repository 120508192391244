import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import ReactPlayer from 'react-player'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { get, isEmpty } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import Measure from 'react-measure'
import throttle from 'lodash/throttle'

import parseHtml from 'html-react-parser'

import images from 'Themes/Images'
import config from 'src/global-config'
import * as selectors from 'container/Home/selectors'
import QuizReviews from '../QuizReviews'
import { SLIDE_TYPE } from 'utils/constants'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { setSelectedQuiz } from 'container/Home/actions'
import { makeSelectSchedules } from '../../selectors'
import { getDescriptionData } from 'utils/helper'
import { makeSelectDomain } from 'container/Home/selectors'
import initialScorm from 'component/ChannelDetails/scorm'
import classNames from 'classnames'
import Attachments from 'component/ChannelDetails/components/Attachments'
import './styles.scss'
import { CKEditorWrapper } from '../../../CMS/screens/Article'

const ChannelViews = ({
  listUserAnswer,
  selectedSchedule,
  setSelectedSchedule,
}) => {
  const [height, setHeight] = useState(null)
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [pageWidth, setPageWidth] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const { search } = useLocation()
  const { quiz } = parse(search)
  const domain = useSelector(makeSelectDomain())
  const course = useSelector(selectors.makeSelectCourse())
  const slide = useSelector(selectors.makeSelectSelectedSlide())
  const slideDetails = useSelector(selectors.makeSelectSlideDetails())
  const schedules = useSelector(makeSelectSchedules())
  const description = getDescriptionData(get(slide, 'description', ''))
  const hideAdditionalInfo = useMemo(() => {
    return (
      description === '' ||
      (!isEmpty(description) && description?.blocks?.length === 0)
    )
  }, [description])

  const [hideDocs, setHideDocs] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const dispatch = useDispatch()

  const div = useCallback(
    (node) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height)
      }
    },
    [slideDetails]
  )

  const fitHorizontal = useMemo(() => {
    const wRatio = pageWidth / wrapperWidth
    const hRatio = pageHeight / wrapperHeight
    if (wRatio < hRatio) {
      return false
    }
    return true
  }, [pageHeight, pageWidth, wrapperHeight, wrapperWidth])

  const setWrapperDimensions = useCallback(
    throttle((w, h) => {
      setWrapperWidth(w)
      setWrapperHeight(h)
    }, 500),
    []
  )

  useEffect(() => {
    initialScorm()
  }, [])

  useEffect(() => {
    if (quiz) {
      dispatch(setSelectedQuiz(get(slideDetails, 'questions[0]', null)))
    }
  }, [quiz, slideDetails])

  useEffect(() => {
    setHideDocs(hideAdditionalInfo)
  }, [hideAdditionalInfo, slide])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const renderMainContent = () => {
    let content = null
    if (slideDetails) {
      switch (slideDetails.slide_type) {
        case SLIDE_TYPE.INFOGRAPHIC:
          const img = slideDetails.attachments.find(
            (item) => item.name === 'image_1920'
          )

          const lessonImg = img
            ? `${config.imageURL}/${img.res_model}/${img.res_id}/${img.res_field}`
            : get(slideDetails, 'image_url', '')
          content = <img className="channel__img" src={lessonImg} alt="" />

          if (domain.id === 34 && slideDetails.id === 2456) {
            content = (
              <>
                {hideDocs && (
                  <div
                    className="iframe__fullscreen__close"
                    onClick={() => setHideDocs(false)}
                  >
                    <img src={images.header.tv} alt="" /> Exit Fullscreen
                  </div>
                )}
                <iframe
                  title="frame"
                  width="100%"
                  src="https://taftc.hypeschools.com/website_scorm_elearning/static/media/scorm/scormcontent/index.html#/"
                  className={classNames('iframe__wrapper', {
                    iframe__fullscreen: hideDocs,
                  })}
                  frameBorder="0"
                />
              </>
            )
          }

          break
        case SLIDE_TYPE.STREAM:
          content = (
            <>
              <iframe
                title="frame2"
                width="100%"
                src={slideDetails.url}
                className="iframe__wrapper"
                frameBorder="0"
              />
            </>
          )

          break
        case SLIDE_TYPE.VIDEO:
          content = (
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={slideDetails.url}
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          )
          break
        case SLIDE_TYPE.PRESENTATION:
          const attachment = slideDetails.attachments[0]
          const pdf =
            slideDetails && slideDetails.document_url
              ? slideDetails.document_url
              : `${config.baseUrl}/web/content/${get(
                  attachment,
                  'res_model'
                )}/${get(attachment, 'res_id')}/datas`

          content = (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <div>
                  <span
                    style={{
                      cursor: 'pointer',
                      borderRight: '1px solid #5A6686',
                      paddingRight: 5,
                      marginRight: 5,
                    }}
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    Previous
                  </span>

                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    Next
                  </span>
                </div>
              </div>
              <Measure
                bounds
                onResize={(contentRect) =>
                  setWrapperDimensions(
                    contentRect.bounds.width,
                    contentRect.bounds.height
                  )
                }
              >
                {({ measureRef }) => (
                  <div className="pdf-wrapper" ref={measureRef}>
                    <Document
                      file={{ url: pdf }}
                      onLoadError={console.error}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className="pdf-custom"
                    >
                      <Page
                        onLoadSuccess={(page) => {
                          setPageWidth(page.width)
                          setPageHeight(page.height)
                        }}
                        pageNumber={pageNumber}
                        className="page-custom"
                        width={fitHorizontal ? wrapperWidth : null}
                        height={!fitHorizontal ? wrapperHeight : null}
                      />
                    </Document>
                  </div>
                )}
              </Measure>
            </>
          )
          break
        default:
          break
      }

      return content
    }
  }

  return (
    <>
      {quiz ? (
        slideDetails && (
          <QuizReviews
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
            schedules={schedules}
            listUserAnswer={listUserAnswer}
            slideDetails={slideDetails}
          />
        )
      ) : (
        <div>
          {slideDetails && course ? (
            <div
              className="channel"
              style={{ minHeight: 'calc(100vh + 244px)' }}
            >
              <div className="channel__head" ref={div}>
                <div className="channel__head__left">
                  <div className="channel__chapter">
                    {get(course, 'name', '')}
                  </div>
                  <div className="channel__title">
                    {get(slideDetails, 'name', '')}
                  </div>
                </div>
                <div className="channel__head__right">
                  <div
                    className="channel__head__right__docs"
                    onClick={() => setHideDocs(!hideDocs)}
                  >
                    <img src={images.channel.docs} alt="" />
                  </div>
                </div>
              </div>
              {!isMobile ? (
                <div
                  className="channel__body"
                  style={{ height: `calc(100% - ${height ? height : 84}px)` }}
                >
                  <div
                    className="channel__left"
                    style={{
                      width: hideDocs && '100%',
                      transition: 'all 0.3s',
                      overflow: 'hidden',
                    }}
                  >
                    {renderMainContent()}
                  </div>

                  {!hideDocs && (
                    <div
                      className="channel__right"
                      style={{ borderLeft: '1px solid #d0cad8', width: 300 }}
                    >
                      <div
                        className="channel__content"
                        style={{ margin: '18px 20px!important' }}
                      >
                        <Attachments selectedSlide={slideDetails} />
                        <div className="channel__content_head">
                          Additional Notes
                        </div>
                        <div className="channel__desc">
                          <CKEditorWrapper>
                            {parseHtml(description)}
                          </CKEditorWrapper>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="channel__body channel__mobile__body">
                  <div
                    className="channel__left"
                    style={{
                      width: '100%',
                      transition: 'all 0.3s',
                      overflow: 'hidden',
                    }}
                  >
                    {renderMainContent()}
                  </div>

                  <div className="channel__right channel__right__mobile">
                    <Attachments selectedSlide={slideDetails} />
                    <div className="channel__content">
                      <div className="channel__content_head">
                        Additional Notes
                      </div>
                      <div className="channel_desc">
                        <CKEditorWrapper>
                          {parseHtml(description)}
                        </CKEditorWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="channel"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh + 244px)',
              }}
            >
              <h3>
                There are no lessons in this course now, click below to start
                your first lesson!
              </h3>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ChannelViews
