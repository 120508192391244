import React, { useState } from 'react'
import { Input } from 'antd'
import { get } from 'lodash'

import { useClickOutside } from 'src/utils/hooks'
import './styles.scss'
import { useDispatch } from 'react-redux'
import { setAnswerCompetent } from '../../../../actions'
import { showError } from 'utils/notification'

const { TextArea } = Input

const Remarks = ({
  answer,
  remark,
  remarks,
  setRemarks,
  selectedSchedule,
  title = 'Add Remarks',
}) => {
  const [active, setActive] = useState(false)
  const remarksVisible = get(answer, 'competent', null) !== null
  const dispatch = useDispatch()
  const handleChange = ({ currentTarget }) => {
    setActive(true)
    let newValues = [...remarks]
    const value = currentTarget.value

    const itemIndex =
      Array.isArray(newValues) &&
      newValues?.findIndex((item) => item?.question_id === remark?.question_id)

    if (itemIndex > -1) {
      newValues[itemIndex]['remark'] = value
    }

    setRemarks(newValues)
  }

  const handleSaveRemark = (e) => {
    if (active) {
      if (remark.remark && remark.remark?.length >= 3) {
        dispatch(
          setAnswerCompetent({
            ...remark,
            schedule: selectedSchedule,
            preventReload: true,
          })
        )
        setActive(false)
      } else {
        showError('Remark must be minimum 3 characters.', 2000)
      }
    }
  }

  const { ref } = useClickOutside(handleSaveRemark)

  return (
    remarksVisible && (
      <div className="remarks">
        <p className="remarks__heading">{title}</p>
        <div ref={ref}>
          <TextArea
            rows={5}
            className="remarks__input"
            value={remark?.remark || ''}
            onChange={handleChange}
          />
        </div>
      </div>
    )
  )
}

export default Remarks
