import React, { useMemo } from 'react'
import styled from 'styled-components'
import CatItem from '../CatItem'

const ListCatWrap = styled.div`
  margin-bottom: ${(props) => `${props.mgBt}px`};

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  .list_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: ${(props) => (props.activeTitle ? '#ffffff' : '#666666')};
    margin-bottom: 12px;
  }
  .list_cat {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      overflow: hidden;
      overflow-x: auto;
      flex-wrap: nowrap;
      margin-right: -20px;
      margin-left: -20px;
      padding-left: 20px;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

const ListCat = ({ title, categories, mgBt, normalPage, activeTitle }) => {
  const listCat = useMemo(() => {
    return normalPage
      ? categories && categories.filter((cat) => cat.active)
      : categories
  }, [categories, normalPage])

  return (
    Array.isArray(listCat) &&
    listCat.length > 0 && (
      <ListCatWrap
        mgBt={mgBt}
        normalPage={normalPage}
        activeTitle={activeTitle}
      >
        <div className="list_title">{title}</div>
        <div className="list_cat">
          {listCat &&
            listCat.map((cat, index) => (
              <CatItem cat={cat} key={index} normalPage={normalPage} />
            ))}
        </div>
      </ListCatWrap>
    )
  )
}

export default ListCat
