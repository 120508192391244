import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import Dropdown from 'react-dropdown'
import { mapListClientToDropDown } from 'utils/helper'
import UserClientsManagement from '../UserClientsManagement'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/helper'
import { useDispatch } from 'react-redux'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { showError } from 'utils/notification'
import { getUserData } from 'container/Admin/actions'

const AssignClient = ({ clients, userClients, currentUserClients, user }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [selectedClient, setSelectedClient] = useState(null)

  const dispatch = useDispatch()

  const assignClientToAdmin = async () => {
    dispatch(showGlobalLoading())
    try {
      const res = await axiosInstance.put('/api/clients/users', {
        user_id: user?.id,
        client_id: selectedClient?.value,
      })
      const data = getResponseData(res)

      if (!data?.id) {
        showError('This client have assigned to this user!')
      }
      setSelectedClient(null)
      dispatch(getUserData(user?.id))
      dispatch(hideGlobalLoading())
    } catch (error) {
      setSelectedClient(null)
      dispatch(hideGlobalLoading())
      console.log('~ error', error)
    }
  }

  return (
    <div>
      <div
        className="u-manage__head"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <h1 className="u-manage__heading">Client Management</h1>
      </div>
      <div style={{ width: isMobile ? '100%' : '50%' }}>
        <div>
          <Dropdown
            options={mapListClientToDropDown(clients, currentUserClients)}
            onChange={(data) => setSelectedClient(data)}
            value={selectedClient}
            className="dropdownScheduleModal"
            placeholder="Select client"
          />
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}></div>
        {selectedClient && (
          <BtnPrimary
            name="Assign"
            style={{
              fontWeight: 'bold',
              color: '#fff',
              marginBottom: 15,
            }}
            handleClick={assignClientToAdmin}
            iconLeft={images.admin.plus}
          />
        )}
      </div>
      <UserClientsManagement
        userClients={userClients}
        getClients={() => dispatch(getUserData(user?.id))}
      />
    </div>
  )
}

export default AssignClient
