import React from 'react';
import { Modal } from 'antd';
import { get } from 'lodash';
import images from 'Themes/Images';
import { hideDeleteEnrollmentModal } from '../../actions';
import { deleteCourse } from '../../../Admin/actions';
import { deleteEnroll } from '../../../Home/actions';

const DeleteEnrollmentModal = ({
  enrollment,
  dispatch,
  showDeleteEnrollmentModal
}) => {
  const handleOk = () => {
    dispatch(deleteEnroll({ enrollment }));
    dispatch(hideDeleteEnrollmentModal());
  };
  const handleCancel = () => {
    dispatch(hideDeleteEnrollmentModal());
  };
  return (
    <Modal
      visible={showDeleteEnrollmentModal}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="ld-modal">
        <div className="ld-modal__icon">
          <img src={images.admin.trash_icon} alt="" width="25" height="29" />
        </div>
        <div className="ld-modal__warning">
          Are you sure you would like to remove this student from the schedule?
        </div>
        <div className="ld-modal__label">
          Student will be removed from this schedule
        </div>
        <div className="ld-modal__btn__ok">
          <button className="delete-btn" onClick={handleOk}>
            <img
              src={images.admin.trash_white_icon}
              alt=""
              style={{ fill: '#fff' }}
              className="trash-icon"
            />
            <span>Delete</span>
          </button>
        </div>
        <div className="ld-modal__btn__cancel">
          <button className="tertiary-btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteEnrollmentModal;
