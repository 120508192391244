// eslint-disable-next-line
import produce from 'immer'
import * as constants from './constants'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'

export const initialState = {
  showGlobalLoading: false,
  showMarkingModal: false,
  showFormSchedule: false,
  showDeleteModal: false,
  showDeleteScheduleModal: false,
  showDeleteLessonModal: false,
  showDeleteOutcomeModal: false,
  showDeleteEnrollmentModal: false,
  selectedSchedule: null,
  userAnswer: null,
  schedule: null,
  slide: null,
  resetForm: null,
  outcome: null,
  showFormOutcomeModal: false,
  showDetailsRegistration: false,
  partner: null,
  showMarkingOutComeModal: false,
  dataMarkingOutcome: {},
  currentUser: null,
  showFormUserModal: false,
  user: null,
  enrollment: null,
  showConfirmQuizModal: false,
  answerQuizId: null,
  isLastAnswer: false,
  userLogByCourse: null,
  showReorderQuizModal: false,
  showForgotPasswordModal: false,
  showExportRegistrations: false,
  showSelectPriceSFCModal: false,
  optionsPrice: null,
  submitRef: null,
  showSelectSchedule: null,
  page: null,
  isComplete: false,
  showVideoPlayer: false,
  showDeleteLearningOutcome: false,
  courseId: null,
  showExportUserModal: false,
  showExportCourseReport: false,
  courseSelected: null,
  showExportInvoices: false,
  showImportUsers: false,
  showProcessingModal: false,
  countUserImported: 0,
  countUserAssigned: 0,
  countUserAssignedFolder: 0,
  countUserEnrolled: 0,
  processingModalPayload: null,
  showOnboardingAdminModal: false,
  showPreviewModal: false,
  showFormClientModal: false,
  showBellsCourseReport: false,
  showExportQuizReport: false,
  clients: null,
  remarks: null,
}

const persistConfig = {
  key: 'modal',
  storage: localStorage,
  whitelist: [],
}

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.SHOW_GLOBAL_LOADING:
        newState.showGlobalLoading = true
        break
      case constants.HIDE_GLOBAL_LOADING:
        newState.showGlobalLoading = false
        break
      case constants.SHOW_MARKING_MODAL:
        newState.showMarkingModal = true
        newState.userAnswer = action.payload
        newState.selectedSchedule = action.schedule
        newState.currentUser = action.currentUser
        newState.remarks = action.remarks
        break
      case constants.HIDE_MARKING_MODAL:
        newState.showMarkingModal = false
        newState.userAnswer = null
        newState.selectedSchedule = null
        break
      case constants.SHOW_FORM_SCHEDULE:
        newState.schedule = action.payload
        newState.page = action.page
        newState.showFormSchedule = true
        break
      case constants.HIDE_FORM_SCHEDULE:
        newState.schedule = null
        newState.showFormSchedule = false
        break
      case constants.SHOW_DELETE_MODAL:
        newState.showDeleteModal = true
        break
      case constants.HIDE_DELETE_MODAL:
        newState.showDeleteModal = false
        break

      case constants.SHOW_DELETE_SCHEDULE:
        newState.schedule = action.payload
        newState.page = action.page
        newState.showDeleteScheduleModal = true
        break
      case constants.HIDE_DELETE_SCHEDULE:
        newState.schedule = null
        newState.showDeleteScheduleModal = false
        break

      case constants.SHOW_DELETE_LESSON_MODAL:
        newState.slide = action.payload
        newState.showDeleteLessonModal = true
        newState.resetForm = action.resetForm
        break
      case constants.HIDE_DELETE_LESSON_MODAL:
        newState.slide = null
        newState.resetForm = null
        newState.showDeleteLessonModal = false
        break

      case constants.SHOW_FORM_OUTCOME_MODAL:
        newState.outcome = action.payload
        newState.showFormOutcomeModal = true
        break
      case constants.HIDE_FORM_OUTCOME_MODAL:
        newState.outcome = null
        newState.showFormOutcomeModal = false
        break

      case constants.SHOW_DELETE_OUTCOME_MODAL:
        newState.outcome = action.payload
        newState.showDeleteOutcomeModal = true
        break
      case constants.HIDE_DELETE_OUTCOME_MODAL:
        newState.outcome = null
        newState.showDeleteOutcomeModal = false
        break

      case constants.SHOW_DETAILS_REGISTRATION:
        newState.partner = action.payload
        newState.showDetailsRegistration = true
        break
      case constants.HIDE_DETAILS_REGISTRATION:
        newState.partner = null
        newState.showDetailsRegistration = false
        break

      case constants.SHOW_MARKING_OUTCOME_MODAL:
        newState.showMarkingOutComeModal = true
        newState.dataMarkingOutcome = action.payload
        break
      case constants.HIDE_MARKING_OUTCOME_MODAL:
        newState.showMarkingOutComeModal = false
        newState.dataMarkingOutcome = {}
        break

      case constants.SHOW_FORM_USER_MODAL:
        newState.showFormUserModal = true
        newState.user = action.payload
        break
      case constants.HIDE_FORM_USER_MODAL:
        newState.showFormUserModal = false
        newState.user = null
        break

      case constants.SHOW_DELETE_ENROLLMENT_MODAL:
        newState.showDeleteEnrollmentModal = true
        newState.enrollment = action.payload
        break
      case constants.HIDE_DELETE_ENROLLMENT_MODAL:
        newState.showDeleteEnrollmentModal = false
        newState.enrollment = null
        break

      case constants.SHOW_CONFIRM_QUIZ_MODAL:
        newState.showConfirmQuizModal = true
        newState.answerQuizId = action.params.answerQuizId
        newState.isLastAnswer = action.params.isLastAnswer
        newState.userLogByCourse = action.params.userLogByCourse
        break
      case constants.HIDE_CONFIRM_QUIZ_MODAL:
        newState.showConfirmQuizModal = false
        newState.answerQuizId = null
        break

      case constants.SHOW_REORDER_QUIZ_MODAL:
        newState.showReorderQuizModal = true
        break
      case constants.HIDE_REORDER_QUIZ_MODAL:
        newState.showReorderQuizModal = false
        break

      case constants.SHOW_FORGOT_PASSWORD_MODAL:
        newState.showForgotPasswordModal = true
        break
      case constants.HIDE_FORGOT_PASSWORD_MODAL:
        newState.showForgotPasswordModal = false
        break

      case constants.SHOW_EXPORT_REGISTRATIONS:
        newState.showExportRegistrations = true
        break
      case constants.HIDE_EXPORT_REGISTRATIONS:
        newState.showExportRegistrations = false
        break

      case constants.SHOW_SELECT_PRICE_SFC_MODAL:
        newState.showSelectPriceSFCModal = true
        newState.isComplete = action.isComplete
        newState.submitRef = action.submitRef
        newState.optionsPrice = action.payload
        break
      case constants.HIDE_SELECT_PRICE_SFC_MODAL:
        newState.isComplete = false
        newState.showSelectPriceSFCModal = false
        break

      case constants.SHOW_SELECT_SCHEDULE_MODAL:
        newState.showSelectSchedule = true
        break
      case constants.HIDE_SELECT_SCHEDULE_MODAL:
        newState.showSelectSchedule = false
        break

      case constants.SHOW_VIDEO_PLAYER:
        newState.showVideoPlayer = true
        break
      case constants.HIDE_VIDEO_PLAYER:
        newState.showVideoPlayer = false
        break

      case constants.SHOW_DELETE_LEARNING_OUTCOME:
        newState.courseId = action.id
        newState.showDeleteLearningOutcome = true
        newState.selectedSchedule = action.selectedSchedule
        newState.outcome = action.payload
        break
      case constants.HIDE_DELETE_LEARNING_OUTCOME:
        newState.courseId = null
        newState.showDeleteLearningOutcome = false
        newState.selectedSchedule = null
        newState.outcome = null
        break
      case constants.SHOW_EXPORT_USERS:
        newState.showExportUserModal = true
        newState.clients = action.params
        break
      case constants.HIDE_EXPORT_USERS:
        newState.showExportUserModal = false
        break

      case constants.SHOW_EXPORT_COURSE_REPORT:
        newState.showExportCourseReport = true
        newState.courseSelected = action.payload?.course
        break
      case constants.HIDE_EXPORT_COURSE_REPORT:
        newState.showExportCourseReport = false
        newState.courseSelected = null
        break

      case constants.SHOW_EXPORT_QUIZ_REPORT:
        newState.showExportQuizReport = true
        newState.courseSelected = action.payload?.course
        break
      case constants.HIDE_EXPORT_QUIZ_REPORT:
        newState.showExportQuizReport = false
        newState.courseSelected = null
        break

      case constants.SHOW_EXPORT_INVOICES:
        newState.showExportInvoices = true

        break
      case constants.HIDE_EXPORT_INVOICES:
        newState.showExportInvoices = false
        break

      case constants.SHOW_IMPORT_USERS:
        newState.showImportUsers = true
        break
      case constants.HIDE_IMPORT_USERS:
        newState.showImportUsers = false
        break

      case constants.SHOW_PROCESSING_MODAL:
        newState.processingModalPayload = action.params
        newState.showProcessingModal = true
        break
      case constants.HIDE_PROCESSING_MODAL:
        newState.showProcessingModal = false
        newState.countUserImported = 0
        newState.countUserEnrolled = 0
        newState.countUserAssigned = 0
        newState.countUserAssignedFolder = 0
        break
      case constants.SET_COUNT_USER_IMPORTED:
        newState.countUserImported = newState.countUserImported + 1
        break
      case constants.SET_COUNT_USER_ASSIGNED_TO_COURSE:
        newState.countUserAssigned = newState.countUserAssigned + 1
        break
      case constants.SET_COUNT_USER_ENROLLED:
        newState.countUserEnrolled = newState.countUserEnrolled + 1
        break
      case constants.SET_COUNT_USER_ASSIGNED_TO_FOLDER:
        newState.countUserAssignedFolder = newState.countUserAssignedFolder + 1
        break

      case constants.SHOW_ONBOARDING_ADMIN_MODAL:
        newState.showOnboardingAdminModal = true
        break
      case constants.HIDE_ONBOARDING_ADMIN_MODAL:
        newState.showOnboardingAdminModal = false
        break

      case constants.SHOW_PREVIEW_MODAL:
        newState.showPreviewModal = action.params
        break
      case constants.HIDE_PREVIEW_MODAL:
        newState.showPreviewModal = false
        break

      case constants.SHOW_FORM_CLIENT_MODAL:
        newState.showFormClientModal = {
          open: true,
          client: action.params?.client,
          refetch: action.params.refetch,
        }
        break

      case constants.HIDE_FORM_CLIENT_MODAL:
        newState.showFormClientModal = {
          open: false,
          client: null,
          refetch: null,
        }
        break

      case constants.SHOW_BELLS_COURSE_REPORT:
        newState.showBellsCourseReport = true
        newState.courseSelected = action.payload?.course
        break
      case constants.HIDE_BELLS_COURSE_REPORT:
        newState.showBellsCourseReport = false
        newState.courseSelected = null
        break
    }
  })

export default persistReducer(persistConfig, reducer)
