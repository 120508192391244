import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import images from 'Themes/Images'
import { get } from 'lodash'
import { useClickOutside } from 'utils/hooks'
import { createAssessment, addOutcomeCourse } from '../../../../actions'

const { TextArea } = Input

const EditAssessment = ({ record, dispatch, setSelectedRow, matchRow }) => {
  const [assess, setAssess] = useState('')
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (record) {
      if (typeof record.assessment_criteria === 'string') {
        setAssess(get(record, 'assessment_criteria', ''))
      } else {
        setAssess(get(record, 'assessment_criteria.criteria', ''))
      }
    }
  }, [record])

  const handleChangeOutcome = async () => {
    setEdit(false)
    setSelectedRow(null)
    if (typeof record.assessment_criteria === 'string') {
      dispatch(addOutcomeCourse({ ...record, assessment_criteria: assess }))
    } else {
      dispatch(
        createAssessment({
          outComeId: get(record, 'assessment_criteria.learning_outcome_id', ''),
          criteria: assess,
          id: get(record, 'assessment_criteria_id')
        })
      )
    }
  }
  const { ref } = useClickOutside(handleChangeOutcome)

  return (
    <div className="outcome-table__column" style={{ whiteSpace: 'pre-wrap' }}>
      {edit && matchRow ? (
        <div ref={ref} style={{ width: '100%' }}>
          <TextArea
            rows={3}
            className="remarks__input"
            value={assess}
            onChange={({ currentTarget }) => setAssess(currentTarget.value)}
          />
        </div>
      ) : (
        <div>{assess}</div>
      )}

      {(!matchRow || !edit) && (
        <div className="outcome-table__edit">
          <img
            src={images.admin.pen_alt_active}
            alt=""
            onClick={() => setEdit(true)}
          />
        </div>
      )}
    </div>
  )
}

export default EditAssessment
