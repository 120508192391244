// eslint-disable-next-line
import produce from 'immer'
import * as constants from './constants'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'

export const initialState = {
  selectedSchedule: null,
  selectedPriceSFC: null,
  selectedModuleSFC: null,
  invoiceNumber: '',
  client: null
}

const persistConfig = {
  key: 'checkout',
  storage: localStorage,
  whitelist: ['selectedSchedule', 'client']
}

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.CHECKOUT_WITH_SFC_PAYMENT:
        break
      case constants.CHECKOUT_WITH_SFC_PAYMENT_SUCCESS:
        break
      case constants.CHECKOUT_WITH_SFC_PAYMENT_FAILED:
        break

      case constants.SET_SELECTED_SCHEDULE:
        newState.selectedSchedule = action.payload
        break

      case constants.SET_SELECTED_PRICE_SFC:
        newState.selectedPriceSFC = action.payload
        break
      case constants.SET_SELECTED_MODULE_SFC:
        newState.selectedModuleSFC = action.payload
        break

      case constants.GET_INVOICE_NUMBER:
        break
      case constants.GET_INVOICE_NUMBER_SUCCESS:
        newState.invoiceNumber = action.payload
        break
      case constants.GET_INVOICE_NUMBER_FAILED:
        break
      case constants.SET_CLIENT:
        newState.client = action.payload
        break
    }
  })

export default persistReducer(persistConfig, reducer)
