import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './mainReducer';
import createSagaMiddleware from 'redux-saga';
import { setAutoFreeze } from 'immer';
import { persistStore } from 'redux-persist';
import rootSaga from './store/saga';

setAutoFreeze(false);

const sagaMiddleware = createSagaMiddleware();

const composeMiddlewares =
  process.env.NODE_ENV === 'production' ? compose : composeWithDevTools;

export const history = createBrowserHistory();

function configureStore() {
  const middlewares = [routerMiddleware(history), sagaMiddleware];
  const store = createStore(
    createRootReducer(history),
    undefined,
    composeMiddlewares(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);
  return store;
}

const store = configureStore();
const persistor = persistStore(store);

export { store, persistor };
