import { all } from 'redux-saga/effects'
import homeSaga from 'container/Home/saga'
import authSaga from 'container/Auth/saga'
import adminSaga from 'container/Admin/saga'
import checkoutSaga from 'container/Checkout/saga'
import superAdmin from '../customs/SuperAdmin/container/AdminActor/saga'

export default function* rootSaga() {
  yield all([homeSaga(), authSaga(), adminSaga(), superAdmin(), checkoutSaga()])
}
