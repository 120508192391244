let currentUser = null;
export const setCurrentUser = user => {
  currentUser = user;
};

export const getCurrentUser = () => {
  return currentUser;
};

let domain = null;
export const setDomain = data => {
  domain = data;
};

export const getDomain = () => {
  return domain;
};
