import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import images from 'Themes/Images'
import SFCPriceItem from '../SFCPriceItem'

const OptionsPricingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .plus-icon {
    cursor: pointer;
    margin-top: 5px;
    align-self: flex-start;
  }
`

const SFCOptionsPrice = ({ prices, formik, course, saveRef }) => {
  const dispatch = useDispatch()
  const handleAddMorePrice = () => {
    let newList = []
    newList = [...prices, { course_code: '', is_edit: true }]
    formik.setFieldValue('sfc_prices', newList)
  }

  return (
    <OptionsPricingWrapper>
      {prices &&
        prices.map((item, index) => (
          <SFCPriceItem
            index={index}
            option={item}
            listOption={prices}
            formik={formik}
            key={index}
            course={course}
            dispatch={dispatch}
            saveRef={saveRef}
          />
        ))}
      <div onClick={handleAddMorePrice} className="plus-icon">
        <img
          src={images.admin.plus_normal}
          alt=""
          style={{ paddingBottom: 3, paddingRight: 3 }}
        />{' '}
        <span className="quiz-addnew__text">Add Course Code</span>
      </div>
    </OptionsPricingWrapper>
  )
}

export default SFCOptionsPrice
