import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import images from 'Themes/Images'
import { showPreviewModal } from '../../container/Modal/actions'
import { saveAs } from 'file-saver'

const PreviewFileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`
const PreviewFileItem = styled.div`
  width: 180px;
  height: 120px;
  border-radius: 4px;
  border: 1px solid #e7ebf4;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  position: relative;
  @media (max-width: 767px) {
    width: 150px;
    height: 100px;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  & .file__name {
    font-size: 11px;
    position: absolute;
    bottom: 4px;
    left: 10px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
    white-space: nowrap;
    background: #e7ebf4;
    padding: 2px 4px;
    border-radius: 4px;
    @media (max-width: 767px) {
      font-size: 10px;
      max-width: 130px;
    }
  }
  transition: all 0.3s ease;
  &:hover {
    background: #e7ebf4;
  }
  .download {
    font-size: 11px;
    position: absolute;
    right: 4px;
    top: 10px;
    background: #e7ebf4;
    padding: 4px 12px;
    border-radius: 4px;
    z-index: 10;
  }
  .attachment__file {
    padding: 60px;
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  margin-right: 10px;
  margin-bottom: 15px;
`
const PreviewFileItemImage = styled.div``
const PreviewFileItemFile = styled.div``

const PreviewwFilesUploadQuiz = ({ files, setFiles, isAdmin, isComplete }) => {
  const dispatch = useDispatch()

  const handleShowPreview = (file) => {
    dispatch(showPreviewModal({ ...file, isAdmin }))
  }

  const handleDownload = (file) => {
    saveAs(file.url, file.name)
  }

  const handleRemove = (file) => {
    if (typeof setFiles === 'function') {
      const clonseFiles = [...files]
      const newFiles = clonseFiles.filter((item) => item.name !== file.name)
      setFiles(newFiles)
    }
  }

  const renderFilesPreview = () => {
    return (
      files &&
      files.map((file) => {
        if (file.type === 'image') {
          return (
            <PreviewFileItem key={file.name}>
              <PreviewFileItemImage>
                {isAdmin ? (
                  <div
                    className="download"
                    onClick={() => handleDownload(file)}
                  >
                    <img src={images.admin.download} alt="" />
                  </div>
                ) : !isComplete ? (
                  <div className="download" onClick={() => handleRemove(file)}>
                    <img src={images.admin.trash_icon} alt="" />
                  </div>
                ) : null}
                <img src={file.url} onClick={() => handleShowPreview(file)} />
                <div className="file__name">{file.name}</div>
              </PreviewFileItemImage>
            </PreviewFileItem>
          )
        }

        if (file.type === 'attachment') {
          return (
            <PreviewFileItem key={file.name}>
              <PreviewFileItemFile>
                {isAdmin ? (
                  <div
                    className="download"
                    onClick={() => handleDownload(file)}
                  >
                    <img src={images.admin.download} alt="" />
                  </div>
                ) : !isComplete ? (
                  <div className="download" onClick={() => handleRemove(file)}>
                    <img src={images.admin.trash_icon} alt="" />
                  </div>
                ) : null}

                <img
                  src={images.admin.document_upload_active}
                  onClick={() => handleShowPreview(file)}
                  className="attachment__file"
                />
                <div className="file__name">{file.name}</div>
              </PreviewFileItemFile>
            </PreviewFileItem>
          )
        }
      })
    )
  }

  return <PreviewFileWrapper>{renderFilesPreview()}</PreviewFileWrapper>
}

export default PreviewwFilesUploadQuiz
