import React, { useState, useEffect } from 'react'
import { Row, Col, Empty } from 'antd'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useDispatch, useSelector } from 'react-redux'

import HeaderCourses from 'container/Home/screens/Courses/HeaderCourses'
import HeadFilter from 'component/HeadFilter'
import FilterCourses from 'component/FilterCourses'
import ListCourses from 'component/ListCourses'
import Pagination from 'component/Pagination'
import { loadListCourse } from 'container/Home/actions'
import { makeSelectListCourse } from 'container/Home/selectors'
import './styles.scss'

const SearchResult = () => {
  const dispatch = useDispatch()
  const courses = useSelector(makeSelectListCourse())
  const [filter, setFilter] = useState('sequence')
  const [filterByTags, setFilterByTags] = useState(null)

  const filterOptions = [
    {
      text: `All Courses (${(courses && courses.length) || 0})`,
      value: 'sequence'
    },
    { text: 'Popular Courses', value: 'total_votes' },
    { text: 'New Courses', value: 'create_date' }
  ]

  const { search } = useLocation()
  const { page, perpage } = parse(search)
  const parsedSearch = parse(search)
  const keyword = parsedSearch.keyword || ''

  useEffect(() => {
    if (filter && keyword) {
      dispatch(loadListCourse({ filter, keyword }))
    }
  }, [keyword, filter])

  const onSelect = (dataSelected) => {
    setFilterByTags(dataSelected)
  }

  return (
    <div className="list-courses__container">
      <HeaderCourses />
      <HeadFilter
        onSelect={onSelect}
        filterByTags={filterByTags}
        keyWord={keyword}
      />
      <div className="list-courses__wrap">
        <Row className="course__row">
          <Col xs={24} xl={4}>
            <FilterCourses
              setFilter={setFilter}
              filter={filter}
              filterOptions={filterOptions}
            />
          </Col>
          <Col xs={24} xl={20}>
            {courses && courses.length !== 0 ? (
              <ListCourses courses={courses} />
            ) : (
              <div style={{ marginBottom: '300px' }}>
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60
                  }}
                  description={
                    <span>
                      Not found any course with the "{keyword}" keyword
                    </span>
                  }
                />
              </div>
            )}
            {courses && courses.length !== 0 && (
              <Pagination page={page} perPage={perpage} courses={courses} />
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SearchResult
