import styled from 'styled-components'
export const Layout = styled.div`
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: ${(props) =>
      props.paddingBottom ? `${props.paddingBottom}px` : '60px'}
    60px;
`

export const Body = styled.div`
  position: relative;
  z-index: 1000;
  padding-bottom: ${(props) =>
    props.paddingBottom ? `${props.paddingBottom}px` : '0'};
  @media (max-width: 1280px) {
    width: 1020px;
  }
  @media (min-width: 1281px) {
    width: 990px;
  }
  @media (min-width: 1920px) {
    width: 1320px;
  }
  margin: 0 auto;
  padding-top: 28px;
  font-family: 'Montserrat', sans-serif;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #666666;
    padding-bottom: 15px;
    margin: 0;
  }
  @media (max-width: 1300px) {
    padding: 20px 15px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px 15px;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: ${(props) => (props.padding ? props.padding : '70px 20px;')};
    overflow: hidden;
  }
  .play_btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const ReactPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  margin-top: ${(props) => `${props.marginTop}px`};
  border-radius: 10px;
  overflow: hidden;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 767px) {
    padding-top: 85.25%;
  }
`

export const Banner = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => `${props.height}px`};
  left: 0px;
  top: ${(props) => (props.top ? `${props.top}px` : '0px')};
  z-index: 1;
  background: url(${(props) => props.img}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: ${(props) => (props.blur ? `blur(7px)` : '')};

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #000000;
    opacity: 0.2;
    @media (max-width: 767px) {
      height: 99%;
    }
  }
  @media (max-width: 767px) {
    height: ${(props) => `${props.heightMobile}px`}
`

export const BannerMobile = styled.div`
  width: 100%;
  height: auto;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: #000000;
    opacity: 0.2;
    @media (max-width: 767px) {
      height: 99%;
    }
  }
`

export const BackgroundDetail = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => `${props.height}px`};
  z-index: 1;
  overflow: hidden;
  .banner_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    filter: ${(props) => `${props.blur ? `${props.blur}px` : '0px'}`};
    -webkit-filter: ${(props) => `${props.blur ? `${props.blur}px` : '0px'}`};
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
  .blur {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
  }
`

export const BannerDetail = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 97%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1900px) {
    padding-bottom: 48.25%;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: 10px;
  }

  margin-top: ${(props) => `${props.mgT}px`};
`
