import React, { useState, useEffect, useMemo } from 'react'
import { get } from 'lodash'
import { parse } from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import parseHtml from 'html-react-parser'

import ReactPlayer from 'react-player'
import * as selector from 'container/Home/selectors'
import images from 'Themes/Images'
import { showError } from 'utils/notification'
import { isEnrollCourse, getDescriptionData } from 'utils/helper'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import {
  loadCourse,
  loadCourseSuccess,
  loadSlides,
  postEnroll,
  putUserLogSuccess,
} from '../../actions'
import { CKEditorWrapper } from 'container/CMS/screens/Article/index'
import {
  makeSelectDomain,
  makeSelectLessonLength,
  makeSelectListCategory,
  makeSelectUserInfo,
} from '../../selectors'
import { DOMAIN } from 'src/routes'
import { getUserInfo } from 'container/Home/actions'
import * as profileCheck from 'container/Checkout/components/ProfileBySchool/schema'
import { showGlobalLoading } from '../../../Modal/actions'
import { hideGlobalLoading } from 'container/Modal/actions'
import styled from 'styled-components'
import { CourseTitleSection } from 'ClassCompare/src/screens/CourseDetail/index'
import {
  BackgroundDetail,
  BannerDetail,
  Body,
  ReactPlayerWrapper,
} from 'ClassCompare/styles'
import { makeSelectCourseType } from 'container/Admin/selectors'
import CourseInfo from 'ClassCompare/src/components/CourseInfo'
import CourseEnroll from 'ClassCompare/src/components/CourseEnroll/index'
import Button from 'ClassCompare/src/components/Button'
import { getAccessToken } from 'utils/request'
import config from 'src/global-config'
import imagesClassCompare from '../../../../customs/ClassCompare/assets/images'

const MainDescription = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    display: block;
    margin-top: 80px;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #666666;
    margin: 0;
    padding: 0;
    padding-right: 20px;
    @media (max-width: 767px) {
      font-size: 34px;
      line-height: 43px;
    }
  }
`

const OldCoursePreview = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { search } = useLocation()
  const { ref } = parse(search)
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const domain = useSelector(makeSelectDomain())
  const [playVideo, setPlayVideo] = useState(false)
  const course = useSelector(selector.makeSelectCourse())
  const categories = useSelector(makeSelectListCategory())
  const currentUser = useSelector(makeSelectCurrentUser())

  const userInfo = useSelector(makeSelectUserInfo())
  const courseId = get(course, 'id', '')
  const courseTypes = useSelector(makeSelectCourseType())

  const categoryId = get(course, 'category_id')
  const courseTypeId = get(course, 'course_type')
  const courseType = useMemo(
    () => courseTypes && courseTypes.find((type) => type.id === courseTypeId),
    [courseTypeId, courseTypes]
  )

  const partners = get(course, 'partners', [])

  const isEnroll = isEnrollCourse(partners, course)

  const isUnicornDomain = domain && domain.domain === DOMAIN.UNICORN_DOMAIN

  const isFirstMediaDomain = domain && domain.domain === DOMAIN.FIRSTMEDIA

  const isArtOfNumbersDomain =
    domain && domain.domain === DOMAIN.ARTOFUNUMBERS_DOMAIN

  const isAgbEducationDomain = domain && domain.domain === DOMAIN.AGB_EDUCATION

  const isCoursesDomain = domain && domain.domain === DOMAIN.COURSES

  const isCyberQuoteDomain = domain && domain.domain === DOMAIN.CYBERQUOTE

  const isSingaporeSkillsAcademies =
    domain && domain.domain === DOMAIN.SINGAPORESKILLS

  const wfaDomain = domain && domain.domain === DOMAIN.WFA

  const isBellDomain = domain && domain.domain === DOMAIN.AGB_EDUCATION

  useEffect(() => {
    if (course && id) {
      dispatch(loadSlides(id, false, 0))
    }
  }, [id, course])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }, 1000)
  }, [])

  const prices = useMemo(
    () => get(course, 'prices', [])?.sort((a, b) => a?.price - b?.price),
    [course]
  )
  const applicationFee = get(course, 'application_fee', null)
  const enableApplicationFee = get(course, 'is_application_fee', false)
  const name = get(course, 'name', '')
  const description = getDescriptionData(get(course, 'description', ''))
  const shortDescription = get(course, 'short_description', '')
  const imageUrl = get(course, 'image_url', '')
  const previewUrl = get(course, 'preview_url', [])

  useEffect(() => {
    dispatch(loadCourse(id))
    dispatch(putUserLogSuccess(null))
  }, [id])

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserInfo())
    }
  }, [currentUser])

  const handleViewCourse = () => {
    if (isEnroll) {
      handleEnrolled()
    } else {
      handleNavigateEnroll()
    }
  }

  const handleNavigateEnroll = () => {
    dispatch(loadCourseSuccess(course))
    const requiredSchedule = get(course, 'is_schedule', false)

    if ((isSingaporeSkillsAcademies || wfaDomain) && currentUser) {
      return handleEnrolledForSingaporeSkills()
    }

    if (!currentUser) {
      return history.push(`/checkout/${get(course, 'id', '')}/auth`)
    }

    if (requiredSchedule) {
      return history.push(`/checkout/${get(course, 'id', '')}/confirm`)
    } else {
      return history.push(`/checkout/${get(course, 'id', '')}/complete-profile`)
    }
  }

  const handlePaymentMethodFreeCourse = async () => {
    const userId = get(currentUser, 'uid', '') || get(currentUser, 'id', '')

    const body = {
      user_id: userId,
      channel_id: get(course, 'id', ''),
      payment_method: 'free_payment',
      domain: get(domain, 'domain', ''),
    }

    try {
      const res = await fetch(`${config.baseUrl}/api/payments/payment-method`, {
        method: 'PUT',
        headers: {
          'Content-type': 'Application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      })

      if (res.status !== 200) {
        return history.push(`/checkout/${get(course, 'id')}/fail`)
      }
      const data = await res.json()
      const id = get(data, 'data.id', null)

      if (id) {
        return true
      }

      dispatch(loadCourse(get(course, 'id')))
      dispatch(hideGlobalLoading())
      return true
    } catch (err) {
      dispatch(hideGlobalLoading())
      return false
    }
  }

  const handleEnrolledForSingaporeSkills = async () => {
    dispatch(showGlobalLoading())
    const setPayment = await handlePaymentMethodFreeCourse()
    if (setPayment) {
      setTimeout(() => {
        dispatch(postEnroll({ ref, is_send: true }))
        dispatch(hideGlobalLoading())
      }, 1500)
    }
  }

  const handleEnrolled = () => {
    const urlProfile = `/checkout/${courseId}/complete-profile`

    if (userInfo) {
      if (
        isArtOfNumbersDomain &&
        !profileCheck.checkProfileArtOfNumbers(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (
        isFirstMediaDomain &&
        !profileCheck.checkProfileFirstMedia(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (
        isAgbEducationDomain &&
        !profileCheck.checkProfileAgbEducation(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (isCoursesDomain && !profileCheck.checkProfileCourses(userInfo)) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (
        isCyberQuoteDomain &&
        !profileCheck.checkProfileCyberQuote(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (isUnicornDomain && !profileCheck.checkProfileUnicorn(userInfo)) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }
    }

    return history.push(`/course/channel/${courseId}`)
  }

  const renderTitle = () => {
    return 'Enrol now'
  }

  const renderButtonText = () => {
    if (isSingaporeSkillsAcademies) {
      return isEnroll ? 'Interest Submitted' : `I'm interested. Tell me more.`
    }
    return isEnroll ? 'View Course' : 'Enrol'
  }

  return (
    <>
      <BackgroundDetail height={isMobile ? 290 : 584} blur>
        <div className="blur" />
        <div className="banner_img">
          <img src={imageUrl ? imageUrl : images.course.default_banner} />
        </div>
      </BackgroundDetail>
      <Body paddingBottom={70}>
        {!playVideo && (
          <BannerDetail mgT={30} showPlayButton={previewUrl}>
            <img
              src={
                imageUrl
                  ? imageUrl + '=s0'
                  : images.course.default_banner + '=s0'
              }
            />
            {previewUrl && (
              <div
                className="play_btn"
                onClick={() => setPlayVideo(true)}
                style={{ width: 80, height: 80 }}
              >
                <img src={imagesClassCompare.playBtn} alt="play button" />
              </div>
            )}
          </BannerDetail>
        )}
        {previewUrl && playVideo && (
          <ReactPlayerWrapper marginTop={60}>
            <ReactPlayer
              className="react-player"
              url={previewUrl}
              width="100%"
              height="100%"
              playing={true}
              onEnded={() => setPlayVideo(false)}
            />
          </ReactPlayerWrapper>
        )}

        <MainDescription>
          <CourseTitleSection>
            <h1 style={{ maxWidth: 560 }}>{name}</h1>
            <div className="course__payment">
              <div className="course__payment__text">{shortDescription}</div>
              {isBellDomain && isEnroll && (
                <Button
                  title={renderButtonText()}
                  color="#fff"
                  bgColor="#FF0D6B"
                  minWidth="352"
                  height="50"
                  handleClick={handleViewCourse}
                  fullWidth={isMobile}
                />
              )}
              {!isBellDomain && (
                <Button
                  title={renderButtonText()}
                  color="#fff"
                  bgColor="#FF0D6B"
                  minWidth="352"
                  height="50"
                  handleClick={handleViewCourse}
                  fullWidth={isMobile}
                />
              )}
            </div>
          </CourseTitleSection>
        </MainDescription>
        <CourseInfo
          course={course}
          optionPrices={prices}
          courseType={courseType}
        />
        <div style={{ marginTop: prices && prices.length === 0 ? 60 : 0 }}>
          <CKEditorWrapper>
            {description ? parseHtml(description + '') : ''}
          </CKEditorWrapper>
        </div>
        <CourseEnroll
          isMobile={isMobile}
          renderTitle={renderTitle}
          handleEnroll={handleViewCourse}
          isEnroll={isEnroll}
          renderButtonText={renderButtonText}
          isBellDomain={isBellDomain}
        />
      </Body>
    </>
  )
}

export default OldCoursePreview
