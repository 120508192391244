export const CHECKOUT_WITH_SFC_PAYMENT = 'CHECKOUT_WITH_SFC_PAYMENT'
export const CHECKOUT_WITH_SFC_PAYMENT_SUCCESS =
  'CHECKOUT_WITH_SFC_PAYMENT_SUCCESS'
export const CHECKOUT_WITH_SFC_PAYMENT_FAILED =
  'CHECKOUT_WITH_SFC_PAYMENT_FAILED'

export const SET_SELECTED_SCHEDULE = 'SET_SELECTED_SCHEDULE'

export const SET_SELECTED_PRICE_SFC = 'SET_SELECTED_PRICE_SFC'

export const SET_SELECTED_MODULE_SFC = 'SET_SELECTED_MODULE_SFC'

export const GET_INVOICE_NUMBER = 'GET_INVOICE_NUMBER'
export const GET_INVOICE_NUMBER_SUCCESS = 'GET_INVOICE_NUMBER_SUCCESS'
export const GET_INVOICE_NUMBER_FAILED = 'GET_INVOICE_NUMBER_FAILED'

export const SET_CLIENT = 'SET_CLIENT'
