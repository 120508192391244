import { createSelector } from 'reselect'

const selectHomeDomain = (state) => state.auth

export const makeSelectCurrentUser = () =>
  createSelector(selectHomeDomain, (subState) => subState.currentUser)

export const makeSelectError = () =>
  createSelector(selectHomeDomain, (subState) => subState.error)

export const makeSelectUserInfo = () =>
  createSelector(selectHomeDomain, (subState) => subState.userInfo)

export const makeSelectAccessToken = () =>
  createSelector(selectHomeDomain, (subState) => subState.accessToken)
