import React from 'react'
import styled from 'styled-components'
import images from 'Themes/Images'
import { useDispatch } from 'react-redux'
import { showSelectPriceSFCModal } from 'container/Modal/actions'
import { showError } from 'utils/notification'
import SFCPriceItem from '../SFCPriceItem'
import { get, isEmpty } from 'lodash'
import { setSelectedModuleSFC } from '../../actions'
import { parseOptionsPrice } from 'utils/helper'
const SFCPriceList = styled.div`
  margin: 12px 0;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  background-color: #fff;
  border-radius: 4px;
  margin: 14px 0;
  height: 80px;
  h3 {
    margin-left: 14px;
    line-height: 18px;
    color: #5a6686;
    font-weight: 500;
    font-size: 12px;
  }
`

const MultipleSFCPrice = ({
  sfcPrices,
  userPaymentByCourse,
  submitRef,
  allowSelectSFC,
  selectedSchedule,
  complete,
  setComplete,
  selectedPriceSFC,
}) => {
  const dispatch = useDispatch()

  const handleClaim = (price, i) => {
    if (!allowSelectSFC) {
      return showError('Enter NRIC then click claim sfc below')
    }

    dispatch(
      setSelectedModuleSFC(
        parseOptionsPrice(get(sfcPrices, `[${i}].price_options`, null))
      )
    )

    if (!isEmpty(selectedPriceSFC)) {
      if (submitRef && submitRef.current) {
        return submitRef.current.click()
      }
    }
    const isComplete = sfcPrices.length === complete
    dispatch(showSelectPriceSFCModal(price, submitRef, isComplete))
  }

  return (
    <>
      {sfcPrices && sfcPrices.length >= 2 && (
        <AlertWrapper>
          <img src={images.admin.exclamation_circle} alt="" />
          <h3>
            This programme is a bundle of {sfcPrices.length} courses. To proceed
            with this payment mode, please use your Skillsfuture Credit to
            offset both of the course fees after subsidies.
          </h3>
        </AlertWrapper>
      )}

      <SFCPriceList>
        {sfcPrices &&
          sfcPrices.map((price, i) => {
            const paymentMethod = userPaymentByCourse.find(
              (item) => item.course_code === price.course_code
            )
            let claimed = false
            const status = get(paymentMethod, 'status', '')
            if (
              paymentMethod &&
              (status === 'claimed' || status === 'Success')
            ) {
              claimed = true
            }

            return (
              <SFCPriceItem
                key={i}
                price={price}
                i={i}
                complete={complete}
                setComplete={setComplete}
                claimed={claimed}
                handleClaim={handleClaim}
              />
            )
          })}
      </SFCPriceList>
    </>
  )
}

export default MultipleSFCPrice
