import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import images from 'Themes/Images'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'
import { getSchedules } from '../../actions'
import { showSuccess, showError } from 'utils/notification'
import { makeSelectSchedules } from '../../selectors'
import { mapListCourseToDropDown, mapScheduleToDropDown } from 'utils/helper'
import { loadListCourse } from 'container/Home/actions'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { getAccessToken } from 'utils/request'
import { Select } from 'antd'

const CreateEnrollment = ({ listCourse, user }) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const schedules = useSelector(makeSelectSchedules())

  const postEnroll = async () => {
    const requestURL = `${config.baseUrl}/api/lms/courses/channels/partners`
    dispatch(showGlobalLoading())
    try {
      const request = await fetch(requestURL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          channel_id: selectedCourse,
          partner_id: user.partner_id,
          user_id: user.id,
          schedule_id: selectedSchedule || 0,
          is_send: true,
        }),
      })

      const res = await request.json()

      if (res.errors) {
        showError('This user has already enrolled this course!')
        setSelectedCourse(null)
        setSelectedSchedule(null)
        dispatch(hideGlobalLoading())
        return
      }
      if (res.data.id) {
        showSuccess('Create Enrollment Successful!')
        setSelectedCourse(null)
        setSelectedSchedule(null)
        dispatch(
          loadListCourse({
            partner: user.partner_id,
            admin: true,
            filter: 'sequence',
            newFilter: 'per_page=1000&ignore_per_page=true&include_delete=true',
          })
        )
      }
      dispatch(hideGlobalLoading())
    } catch (err) {
      console.log('err', err)
      dispatch(hideGlobalLoading())
    }
  }

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getSchedules(selectedCourse, true, null, false, true))
    }
  }, [selectedCourse])

  const onSelectCourse = (course) => {
    setSelectedCourse(course)
  }

  const onSelectSchedule = (schedule) => {
    setSelectedSchedule(schedule)
  }

  return (
    <div>
      <div
        className="u-manage__head"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <h1 className="u-manage__heading">Create Enrollment</h1>
      </div>
      <div style={{ width: isMobile ? '100%' : '70%' }}>
        <div>
          <Select
            className="selectSchedule"
            showSearch
            placeholder="Select a course"
            optionFilterProp="children"
            onChange={onSelectCourse}
            value={selectedCourse ? selectedCourse : null}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={mapListCourseToDropDown(listCourse)}
          />
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {selectedCourse && schedules ? (
            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={onSelectSchedule}
              value={selectedSchedule ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, true, true)}
            />
          ) : null}
        </div>
        {selectedCourse && (
          <BtnPrimary
            name="Create"
            style={{
              fontWeight: 'bold',
              color: '#fff',
              marginBottom: 15,
            }}
            handleClick={postEnroll}
            iconLeft={images.admin.plus}
          />
        )}
      </div>
    </div>
  )
}

export default CreateEnrollment
