import * as constant from './constants'

// LOAD LIST COURSE OF SALE
export function loadListCourseOfSeller(params) {
  return {
    type: constant.LOAD_LIST_COURSE_OF_SELLER,
    params
  }
}

export function loadListCourseOfSellerSuccess(payload) {
  return {
    type: constant.LOAD_LIST_COURSE_OF_SELLER_SUCCESS,
    payload
  }
}

export function loadListCourseOfSellerFailed(error) {
  return {
    type: constant.LOAD_LIST_COURSE_OF_SELLER_FAILED,
    error
  }
}

// ADD COURSE TO SELLER
export function addCourseToSeller(params) {
  return {
    type: constant.ADD_COURSE_TO_SELLER,
    params
  }
}

export function addCourseToSellerSuccess(payload) {
  return {
    type: constant.ADD_COURSE_TO_SELLER_SUCCESS,
    payload
  }
}

export function addCourseToSellerFailed(error) {
  return {
    type: constant.ADD_COURSE_TO_SELLER_FAILED,
    error
  }
}

// DELETE COURSE TO SELLER
export function deleteCourseOfSeller(params) {
  return {
    type: constant.DELETE_COURSE_OF_SELLER,
    params
  }
}

export function deleteCourseOfSellerSuccess(payload) {
  return {
    type: constant.DELETE_COURSE_OF_SELLER_SUCCESS,
    payload
  }
}

export function deleteCourseOfSellerFailed(error) {
  return {
    type: constant.DELETE_COURSE_OF_SELLER_FAILED,
    error
  }
}

// CREATE OR UPDATE USER
export function createOrUpdateUser(params, needToLoadUsers = true) {
  return {
    type: constant.CREATE_OR_UPDATE_USER,
    params,
    needToLoadUsers
  }
}

export function createOrUpdateUserSuccess(payload) {
  return {
    type: constant.CREATE_OR_UPDATE_USER_SUCCESS,
    payload
  }
}

export function createOrUpdateUserFailed(error) {
  return {
    type: constant.CREATE_OR_UPDATE_USER_FAILED,
    error
  }
}

// CREATE OR UPDATE USER
export function loadListCollection(params) {
  return {
    type: constant.LOAD_LIST_COLLECTION,
    params
  }
}

export function loadListCollectionSuccess(payload) {
  return {
    type: constant.LOAD_LIST_COLLECTION_SUCCESS,
    payload
  }
}

export function loadListCollectionFailed(error) {
  return {
    type: constant.LOAD_LIST_COLLECTION_FAILED,
    error
  }
}

// CREATE OR UPDATE USER
export function deleteLead(params) {
  return {
    type: constant.DELETE_LEAD,
    params
  }
}

export function deleteLeadSuccess(payload) {
  return {
    type: constant.DELETE_LEAD_SUCCESS,
    payload
  }
}

export function deleteLeadFailed(error) {
  return {
    type: constant.DELETE_LEAD_FAILED,
    error
  }
}

//

export function loadListCourse(params) {
  return {
    type: constant.LOAD_LIST_COURSE,
    params
  }
}

export function loadListCourseSuccess(payload) {
  return {
    type: constant.LOAD_LIST_COURSE_SUCCESS,
    payload
  }
}

export function loadListCourseFailed(error) {
  return {
    type: constant.LOAD_LIST_COURSE_FAILED,
    error
  }
}

// LOAD LIST COURSE
export function collectCustomerInterest(params, setAlert, resetForm) {
  return {
    type: constant.COLLECT_CUSTOMER_INTEREST,
    params,
    setAlert,
    resetForm
  }
}

export function collectCustomerInterestSuccess(payload) {
  return {
    type: constant.COLLECT_CUSTOMER_INTEREST_SUCCESS,
    payload
  }
}

export function collectCustomerInterestFailed(error) {
  return {
    type: constant.COLLECT_CUSTOMER_INTEREST_FAILED,
    error
  }
}
