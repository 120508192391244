import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, history } from './configureStore'
import { Router } from 'react-router-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'

import './index.css'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
