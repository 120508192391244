export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const GET_SESSION_INFO = 'GET_SESSION_INFO'
export const GET_SESSION_INFO_SUCCESS = 'GET_SESSION_INFO_SUCCESS'
export const GET_SESSION_INFO_FAILED = 'GET_SESSION_INFO_FAILED'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILED = 'SIGNUP_FAILED'

export const LOGOUT = 'LOGOUT'

export const GET_USER_INFO_BY_TOKEN = 'GET_USER_INFO_BY_TOKEN'
export const GET_USER_INFO_BY_TOKEN_SUCCESS = 'GET_USER_INFO_BY_TOKEN_SUCCESS'
export const GET_USER_INFO_BY_TOKEN_FAILED = 'GET_USER_INFO_BY_TOKEN_FAILED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'

export const LOGIN_SESSION = 'LOGIN_SESSION'
export const LOGIN_SESSION_SUCCESS = 'LOGIN_SESSION_SUCCESS'
export const LOGIN_SESSION_FAILED = 'LOGIN_SESSION_FAILED'
