import React, { useState } from 'react'
import CheckoutProgressBar from '../../components/CheckoutProgressBar'
import { Row, Col } from 'antd'
import Login from './components/Login'
import SignUp from './components/SignUp'
import './styles.scss'

const Login_SignUp = ({
  refId,
  course,
  history,
  dispatch,
  schedule,
  currentUser,
  isUnicornDomain,
  cyberQuoteDomain,
  firstMediaDomain,
  isArtofnumbersDomain,
  isSingaporeSkillsAcademies,
  requiredSchedule,
  handlePaymentMethodFreeCourse
}) => {
  const [switchLogin, setSwitchLogin] = useState(false)

  return (
    <div>
      <CheckoutProgressBar
        indexActive={0}
        isUnicornDomain={isUnicornDomain}
        cyberQuoteDomain={cyberQuoteDomain}
        firstMediaDomain={firstMediaDomain}
        isArtofnumbersDomain={isArtofnumbersDomain}
        requiredSchedule={requiredSchedule}
        isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
      />
      <Row className="hs-billing" justify="center">
        {switchLogin ? (
          <Col xs={24} md={12} className="checkout-auth__signup">
            <Login
              course={course}
              history={history}
              dispatch={dispatch}
              schedule={schedule}
              handleSwitchMode={() => setSwitchLogin(false)}
              requiredSchedule={requiredSchedule}
              isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
              handlePaymentMethodFreeCourse={handlePaymentMethodFreeCourse}
            />
          </Col>
        ) : (
          <Col xs={24} md={12} className="checkout-auth__login">
            <SignUp
              refId={refId}
              course={course}
              history={history}
              dispatch={dispatch}
              schedule={schedule}
              handleSwitchMode={() => setSwitchLogin(true)}
              requiredSchedule={requiredSchedule}
              isSingaporeSkillsAcademies={isSingaporeSkillsAcademies}
              handlePaymentMethodFreeCourse={handlePaymentMethodFreeCourse}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Login_SignUp
