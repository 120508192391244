import * as constants from './constants'

// LOGIN
export function Login(params) {
  return {
    type: constants.LOGIN,
    params
  }
}

export function LoginSuccess(payload) {
  return {
    type: constants.LOGIN_SUCCESS,
    payload
  }
}

export function LoginFailed(error) {
  return {
    type: constants.LOGIN_FAILED,
    error
  }
}

// SIGN UP
export function Signup(params) {
  return {
    type: constants.SIGNUP,
    params
  }
}

export function SignupSuccess(payload) {
  return {
    type: constants.SIGNUP_SUCCESS,
    payload
  }
}

export function SignupFailed(error) {
  return {
    type: constants.SIGNUP_FAILED,
    error
  }
}

// LOG OUT
export function Logout(params) {
  return {
    type: constants.LOGOUT,
    params
  }
}

// GET SESSION INFO
export function getSessionInfo(params) {
  return {
    type: constants.GET_SESSION_INFO,
    params
  }
}

export function getSessionInfoSuccess(payload) {
  return {
    type: constants.GET_SESSION_INFO_SUCCESS,
    payload
  }
}

export function getSessionInfoFailed(error) {
  return {
    type: constants.GET_SESSION_INFO_FAILED,
    error
  }
}

// GET USER INFO
export function getUserInfoByToken(params) {
  return {
    type: constants.GET_USER_INFO_BY_TOKEN,
    params
  }
}

export function getUserInfoByTokenSuccess(payload) {
  return {
    type: constants.GET_USER_INFO_BY_TOKEN_SUCCESS,
    payload
  }
}

export function getUserInfoByTokenFailed(error) {
  return {
    type: constants.GET_USER_INFO_BY_TOKEN_FAILED,
    error
  }
}
// RESET PASSWORD
export function resetPassword(params) {
  return {
    type: constants.RESET_PASSWORD,
    params
  }
}

export function resetPasswordSuccess(payload) {
  return {
    type: constants.RESET_PASSWORD_SUCCESS,
    payload
  }
}

export function resetPasswordFailed(error) {
  return {
    type: constants.RESET_PASSWORD_FAILED,
    error
  }
}

export function setAccessToken(payload) {
  return {
    type: constants.SET_ACCESS_TOKEN,
    payload
  }
}

export function loginSession(params) {
  return {
    type: constants.LOGIN_SESSION,
    params
  }
}

export function loginSessionSuccess(payload) {
  return {
    type: constants.LOGIN_SESSION_SUCCESS,
    payload
  }
}

export function loginSessionFailed(error) {
  return {
    type: constants.LOGIN_SESSION_FAILED,
    error
  }
}
