import React from 'react'
import { Modal } from 'antd'
import { get } from 'lodash'
import styled from 'styled-components'

import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import { formatMoney, parseOptionsPrice } from 'utils/helper'
import { hideSelectPriceSFCModal } from '../../actions'
import { setSelectedPriceSFC } from '../../../Checkout/actions'

const ModalWrapper = styled.div`
  h2 {
    margin: 25px 0;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #00122a;
  }
`

export const ListPriceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: center !important;
  }
`

export const PriceItemWrapper = styled.div`
  width: 220px;
  padding: 26px 30px;
  background: #ffffff;
  border: 1px solid #dee2ed;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-right: 20px;
  .price__title {
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    line-height: 24px;
    color: #5a6686;
    margin-bottom: 12px;
  }
  .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #00122a;
    margin-bottom: 16px;
  }
`

const SelectPriceSFCModal = ({
  dispatch,
  showSelectPriceSFCModal,
  price,
  submitRef,
  isComplete
}) => {
  const handleCancel = () => {
    dispatch(hideSelectPriceSFCModal())
  }
  const priceOptions = parseOptionsPrice(get(price, 'price_options'))

  const handleSelectPrice = (item) => {
    const courseCode = get(price, 'course_code')
    const title = get(item, 'title', '')
    const optionPrice = get(item, 'price')
    dispatch(
      setSelectedPriceSFC({
        course_code: courseCode,
        title,
        price: optionPrice,
        is_finished: isComplete
      })
    )
    if (submitRef && submitRef.current) {
      return submitRef.current.click()
    }
  }
  return (
    <Modal
      width={700}
      visible={showSelectPriceSFCModal}
      footer={null}
      onCancel={handleCancel}
      closeIcon={<img src={images.admin.x_normal} alt="x_icon" />}
    >
      <ModalWrapper className="ld-modal">
        <h2 className="ld-modal__label">
          Select which pricing you are eligible for
        </h2>
        <ListPriceWrapper>
          {priceOptions &&
            priceOptions.map((price) => (
              <PriceItemWrapper key={price.title}>
                <div className="price__title">{price.title}</div>
                <div className="price">{formatMoney(price.price)}</div>
                <div>
                  <BtnPrimary
                    name="Select"
                    style={{
                      fontWeight: 'bold',
                      padding: '12px 10px',
                      color: '#fff',
                      width: '100%'
                    }}
                    handleClick={() => handleSelectPrice(price)}
                  />
                </div>
              </PriceItemWrapper>
            ))}
        </ListPriceWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default SelectPriceSFCModal
