const images = {
  complete: require('../assets/images/complete-ic.svg'),
  zip_file: require('../assets/images/zip-file.svg'),
  logo_active: require('../assets/images/logo_active.svg'),
  firstMediaLogo: require('../assets/images/firstmedia_logo.png'),
  favicon_classcompare: require('../assets/images/favicon_classcompare.png'),
  favicon_classcompare_apple: require('../assets/images/apple-touch-icon.png'),
  logo: require('../assets/images/logo.svg'),
  logo_hypeschools: require('../assets/images/logo_hypeschools.svg'),
  logo_hypeschools_white: require('../assets/images/logo_hypeschools_white.svg'),
  logo_hypeschools_active: require('../assets/images/logo_hypeschools_active.svg'),
  hypeschool_bg: require('../assets/images/hypeschool_bg.svg'),
  super_admin_logo: require('../assets/images/super_admin_logo.svg'),
  logo_example: require('../assets/images/example_logo.png'),
  certificate_flc: require('../assets/images/certificate_flc.png'),
  certificate_ascendo_logo: require('../assets/images/certificate_ascendo_logo.png'),
  certificate_ascendo_background: require('../assets/images/certificate_ascendo_background.png'),
  certificate_ascendo_sign: require('../assets/images/certificate_ascendo_sign.png'),
  sign_flc: require('../assets/images/sign_flc.png'),
  f_logo: require('../assets/images/f_logo.png'),
  header: {
    back_button: require('../assets/images/back_button.svg'),
    bars: require('../assets/images/bars.svg'),
    search: require('../assets/images/search.svg'),
    bookmark: require('../assets/images/bookmark.svg'),
    tv: require('../assets/images/tv.svg'),
    caret_up_black: require('../assets/images/caret-up-black.svg'),
    caret_down_black: require('../assets/images/caret-down-black.svg'),
    caret_down: require('../assets/images/caret-down.svg'),
    caret_down_nav: require('../assets/images/caret_down.svg'),
    caret_right: require('../assets/images/caret_right.svg'),
    caret_down_active: require('../assets/images/caret_down_active.svg'),
    image: require('../assets/images/image.svg'),
    image_active: require('../assets/images/image_active.svg'),
    blocked_lesson: require('../assets/images/blocked_lesson.svg'),
    image_none_bg: require('../assets/images/image_none_bg.svg'),
    file_pdf: require('../assets/images/file-pdf.svg'),
    file_pdf_active: require('../assets/images/file-pdf_active.svg'),
    link: require('../assets/images/link.svg'),
    play: require('../assets/images/play.svg'),
    play_active: require('../assets/images/play-active.svg'),
    poll_h: require('../assets/images/poll-h.svg'),
    poll_h_active_circle: require('../assets/images/poll-h_active_circle.svg'),
    poll_h_circle: require('../assets/images/poll-h_circle.svg'),
    check: require('../assets/images/check.svg'),
    check_inactive: require('../assets/images/check_inactive.svg'),
    check_red: require('../assets/images/check_red.svg'),
    play_active: require('../assets/images/play_active.svg'),
    file_word: require('../assets/images/file-word.svg'),
  },
  channel: {
    bookmark_inactive: require('../assets/images/bookmark_inactive.svg'),
    highlighter: require('../assets/images/highlighter.svg'),
    docs: require('../assets/images/docs.svg'),
    docs_unactive: require('../assets/images/docs_unactive.svg'),
    angle_right: require('../assets/images/angle-right.svg'),
    angle_left: require('../assets/images/angle-left.svg'),
  },
  comments: {
    like: require('../assets/images/like.svg'),
    unlike: require('../assets/images/unlike.svg'),
    comments: require('../assets/images/comments.svg'),
    comments_active: require('../assets/images/comments_active.svg'),
    sub_comment: require('../assets/images/sub_comment.svg'),
    share: require('../assets/images/share.svg'),
    share_active: require('../assets/images/share_active.svg'),
  },
  share: {
    arrow_up: require('../assets/images/arrow_up.svg'),
    arrow_down: require('../assets/images/arrow_down.svg'),
    copy: require('../assets/images/copy.svg'),
    copy_normal: require('../assets/images/copy_normal.svg'),
  },
  course: {
    arrow_right_active: require('../assets/images/arrow_right_active.svg'),
    arrow_right_black: require('../assets/images/arrow_right_black.svg'),
    arrow_right: require('../assets/images/arrow_right.svg'),
    arrow_left: require('../assets/images/arrow-left.svg'),
    course_sample: require('../assets/images/course_sample.svg'),
    back: require('../assets/images/back.svg'),
    default_banner:
      'https://lh3.googleusercontent.com/IOak9_3zNx1-fuI5W5k_tb76CkMPs5o6kQVoIqE8UUHwzhjPy7661lkiziVNojrWZInb1Uq3P5vsjYGL3M82QCi1Rgg6qAfrZHe9g_BXduIHHAIt',
  },
  input: {
    eye_icon: require('../assets/images/eye.svg'),
    eye_inactive: require('../assets/images/eye_inactive.svg'),
    eye_closed_icon: require('../assets/images/eye_close.svg'),
  },
  admin: {
    logo: require('../assets/images/logo_example.jpeg'),
    save_icon: require('../assets/images/save.svg'),
    empty_course: require('../assets/images/empty_course.png'),
    trash_icon: require('../assets/images/trash.svg'),
    trash_white_icon: require('../assets/images/trash_white_icon.svg'),
    arrow_up_pink: require('../assets/images/arrow_up-pink.svg'),
    course_img_xample:
      'https://lh3.googleusercontent.com/2i8JlLOeGdo_NLx0cV-PBF4Stt93q9uYhKxi33N_l9rkgLhiMe8JlcM5-PI4JS5caHwBvkoq0H5krj08UvOekjCQgLG17otSTmb6-9fjzsLdFIhM',
    logo_example: require('../assets/images/logo_example.png'),
    zoom_icon: require('../assets/images/zoom_icon.svg'),
    upload_icon: require('../assets/images/upload_icon.svg'),
    menu_active_icon: require('../assets/images/menu_active_icon.svg'),
    menu_icon: require('../assets/images/menu_icon.svg'),
    pen_alt_active: require('../assets/images/pen-alt_active.svg'),
    pen_alt_active_red: require('../assets/images/pen-alt_active_red.svg'),
    pen_alt: require('../assets/images/pen-alt.svg'),
    check_with_bg: require('../assets/images/check_with_bg.svg'),
    check_with_bg_black: require('../assets/images/check_with_bg_black.svg'),
    check_with_bg_pink: require('../assets/images/check_with_bg_pink.svg'),
    uncheck_with_bg: require('../assets/images/uncheck_with_bg.svg'),
    pdf_active_black: require('../assets/images/pdf_active_black.svg'),
    pdf_black: require('../assets/images/pdf_black.svg'),
    image_active_black: require('../assets/images/image_active_black.svg'),
    image_black: require('../assets/images/image_black.svg'),
    link_active_black: require('../assets/images/link_active_black.svg'),
    link_black: require('../assets/images/link_black.svg'),
    ellipsis: require('../assets/images/ellipsis-v.svg'),
    plus: require('../assets/images/plus.svg'),
    plus_green: require('../assets/images/plus_green.svg'),
    plus_white: require('../assets/images/plus_white.svg'),
    plus_normal: require('../assets/images/plus_normal.svg'),
    document_upload: require('../assets/images/document_upload.svg'),
    document_upload_active: require('../assets/images/document_upload_active.svg'),
    sgd: require('../assets/images/sgd.svg'),
    download: require('../assets/images/download.svg'),
    group_search: require('../assets/images/group-search.svg'),
    filter: require('../assets/images/filter.svg'),
    add_crm: require('../assets/images/add_crm.svg'),
    x: require('../assets/images/x.svg'),
    x_active: require('../assets/images/x_active2.svg'),
    x_inactive: require('../assets/images/x_inactive.svg'),
    calender: require('../assets/images/calender.svg'),
    calender_sm: require('../assets/images/calender_sm.svg'),
    location_arrow: require('../assets/images/location-arrow.png'),
    location_arrow_sm: require('../assets/images/location-arrow_sm.png'),
    clock: require('../assets/images/clock.svg'),
    clock_sm: require('../assets/images/clock_sm.svg'),
    stripe: require('../assets/images/stripe.svg'),
    lock: require('../assets/images/lock.svg'),
    paypal: require('../assets/images/paypal.svg'),
    paypal_text: require('../assets/images/paypal_text.png'),
    paypal_button: require('../assets/images/paypal_button.png'),
    master_card: require('../assets/images/master_card.svg'),
    visa: require('../assets/images/visa.svg'),
    shopping_bag: require('../assets/images/shopping-bag.svg'),
    configuration: require('../assets/images/configuration.svg'),
    report_logo: require('../assets/images/report_logo.png'),
    success_icon: require('../assets/images/success_icon.svg'),
    failed_icon: require('../assets/images/failed_icon.svg'),
    warning: require('../assets/images/warning.svg'),
    qr_code: require('../assets/images/qr_code.jpg'),
    flc_paynow_qrcode: require('../assets/images/flc_paynow_qrcode.jpg'),
    upload: require('../assets/images/upload.svg'),
    watermark: require('../assets/images/watermark.png'),
    green_circle: require('../assets/images/green_circle.svg'),
    red_circle: require('../assets/images/red_circle.svg'),
    tick_icon: require('../assets/images/tick_icon.svg'),
    tick_icon_active: require('../assets/images/tick_icon_active.svg'),
    user: require('../assets/images/user.svg'),
    button_play: require('../assets/images/button.play.svg'),
    check_active: require('../assets/images/check_active.svg'),
    artofnumbers_qr_code: require('../assets/images/Art_of_numbers_qr_code.jpg'),
    firstmedia_paynow: require('../assets/images/firstmedia_paynow.png'),
    angle_down: require('../assets/images/angle-down.svg'),
    angle_up: require('../assets/images/angle-up.svg'),
    exclamation_circle: require('../assets/images/exclamation-circle.svg'),
    x_normal: require('../assets/images/x_normal.svg'),
    video_upload: require('../assets/images/video_upload.svg'),
  },
  sidebar: {
    zoom_copy: require('../assets/images/zoom_copy.svg'),
  },
  unicorn: {
    logo_black: require('../customs/Unicorn/assets/images/second_logo.png'),
  },
  link: {
    banner:
      'https://lh3.googleusercontent.com/gsC60COdu8wD2c2FrykJonYUmPdM4ZVfQ2dQGBOZJazE2-6g_1GJrnNHlMF0ThnGBIx_mUCodLZ-6LMsUfu_xYZ-bWC8EbG2CZtZGq9kh3LevPw',
    logo: require('../assets/images/logo_sample.svg'),
  },
  wrong_answer: require('../assets/images/wrong_answer.svg'),
  correct_choice: require('../assets/images/correct_choice.svg'),
  wrong_choice: require('../assets/images/wrong_choice.svg'),
  whatapp: require('../assets/images/whatapp.svg'),
  outline: require('../assets/images/outline.svg'),
  phone: require('../assets/images/phone.svg'),
  hypeschools: require('../assets/images/hypeschools.webp'),
  singapore: require('../assets/images/singapore.webp'),
  skillsg: require('../assets/images/skillsg.webp'),
  singapore_skills_logo: require('../assets/images/singapore_skills_logo.webp'),
  plus: require('../assets/images/plus.png'),
  minus: require('../assets/images/minus.png'),
  working: require('../assets/images/working.jpg'),
  upload: require('../assets/images/upload.png'),
  dot: require('../assets/images/dot.png'),
  active_dot: require('../assets/images/active_dot.png'),
  read_more: require('../assets/images/read_more.svg'),
  rating: require('../assets/images/rating.svg'),
  check_green: require('../assets/images/check_green.svg'),
  dot_green: require('../assets/images/dot_green.svg'),
  close: require('../assets/images/close.svg'),
  open: require('../assets/images/open.svg'),
  play_small: require('../assets/images/play_small.svg'),
  note: require('../assets/images/note.svg'),
  instructor_sample: require('../assets/images/instructor_sample.svg'),
  alert: require('../assets/images/alert.svg'),
  clock_small: require('../assets/images/clock_small.svg'),
  lectures: require('../assets/images/lectures.svg'),
  enrolled: require('../assets/images/enrolled.svg'),
  language: require('../assets/images/language.svg'),
  share_course: require('../assets/images/share_course.svg'),
  skill_level: require('../assets/images/skill_level.svg'),
  certificate: require('../assets/images/certificate.svg'),
  deadline: require('../assets/images/deadline.svg'),
  email: require('../assets/images/email.png'),
}

export default images
