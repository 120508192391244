import styled from 'styled-components'

export const SentenceListWrap = styled.div`
  display: flex;
  align-items: flex-start;

  .sentence__wrap {
    max-width: 620px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .sentence__add {
    padding-top: 26px;
    padding-left: 10px;
    cursor: pointer;
  }
  .sentence__delete {
    cursor: pointer;
    padding-left: 10px;
  }
  .sentence__input {
    width: 100%;
  }
`
