import React from 'react'
import TableClients from './TableClients'

const UserClientsManagement = ({ getClients, userClients }) => {
  return (
    <div>
      <TableClients listUser={userClients} refetch={getClients} />
    </div>
  )
}

export default UserClientsManagement
