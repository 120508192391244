import axios from 'axios'
import { getAccessToken } from 'utils/request'
import config from 'src/global-config'
import { get } from 'lodash'

export const patchSchoolSettings = async (payload, id) => {
  let requestURL = `${config.baseUrl}/api/websites/${id}`

  try {
    const res = await axios.patch(requestURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    })

    const id = get(res, 'data.data.id', null)
    return id
  } catch (error) {
    console.log('error', error)
  }
}
