import React from 'react';
import { Modal, Button } from 'antd';
import { get } from 'lodash';
import images from 'Themes/Images';
import { hideDeleteOutcomeModal } from '../../actions';
import { deleteLearningOutcome } from '../../../Admin/actions';

const DeleteOutcomeModal = ({ outCome, dispatch, showDeleteOutcomeModal }) => {
  const handleOk = () => {
    if (outCome) {
      dispatch(deleteLearningOutcome(outCome.id));
      dispatch(hideDeleteOutcomeModal());
    }
  };
  const handleCancel = () => {
    dispatch(hideDeleteOutcomeModal());
  };

  return (
    <Modal
      visible={showDeleteOutcomeModal}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="ld-modal">
        <div className="ld-modal__icon">
          <img src={images.admin.trash_icon} alt="" width="25" height="29" />
        </div>
        <div className="ld-modal__warning" style={{ lineHeight: 1.8 }}>
          Are you sure you want to delete <br />
          {get(outCome, 'outcome', '')} outcome?
        </div>
        <div className="ld-modal__label">
          You will not be able to recover this later.
        </div>
        <div className="ld-modal__btn__ok">
          <button className="delete-btn" onClick={handleOk}>
            <img
              src={images.admin.trash_white_icon}
              alt=""
              style={{ fill: '#fff' }}
              className="trash-icon"
            />
            <span>Delete forever</span>
          </button>
        </div>
        <div className="ld-modal__btn__cancel">
          <button className="tertiary-btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteOutcomeModal;
