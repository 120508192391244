import React from 'react'
import styled from 'styled-components'
import { alphabet } from '../ChoiceItem/index'

const FinalResultWrapper = styled.div`
  width: 100%;
  background-color: #f6f6f9;
  border: 1px solid #d0cad8;
  padding: 20px;
  font-size: 14px;
  line-height: 24px;
  .correct_answer {
    color: green;
    font-weight: bold;
  }
  .wrong_answer {
    color: red;
    font-weight: bold;
  }
  .alert_answer {
    font-weight: bold;
  }
`

const FinalResult = ({
  result,
  correctAnswers,
  isCorrectAnswer,
  hideAnswer,
}) => {
  if (hideAnswer && !isCorrectAnswer) {
    return null
  }

  const answerCorrectText =
    correctAnswers &&
    correctAnswers.map((item) => alphabet[item.order]).toString()

  return (
    <FinalResultWrapper>
      {result ? (
        <div className="correct_answer">This answer is correct</div>
      ) : isCorrectAnswer ? (
        <div className="wrong_answer">
          You have chosen the wrong answer, please try again
        </div>
      ) : (
        <div>
          <span className="wrong_answer">This answer is incorrect.</span>{' '}
          <span className="alert_answer">
            The correct answer is {answerCorrectText}
          </span>
        </div>
      )}
    </FinalResultWrapper>
  )
}

export default FinalResult
