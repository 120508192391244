import { get } from 'lodash'
import React from 'react'
import config from 'src/global-config'
import images from 'Themes/Images'
import PreviewFilesUploadQuiz from 'component/PreviewFilesUploadQuiz'
import { parseSafe } from 'utils/helper'

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

export const renderAnswer = (
  answer,
  item,
  index,
  isCorrectAnswer,
  correctChoices,
  isFeedBack
) => {
  if (!answer) {
    return ''
  }

  let content = []
  if (answer.url) {
    const isHasHttpsPrefix = answer?.url?.startsWith('https://')
    const item = (
      <div className="mgBt-15" key={answer.url}>
        Answer link:{' '}
        <a
          href={isHasHttpsPrefix ? answer.url : 'https://' + answer.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'red!important' }}
        >
          {answer.url}
        </a>
      </div>
    )
    content.push(item)
  }
  const multipleChoices = parseSafe(get(item, 'multiple_choices'))

  if (answer.value) {
    let item
    const listChoices = parseSafe(answer.value)

    if (listChoices && multipleChoices) {
      item = (
        <div key={answer.value}>
          {listChoices.map((option, i) => {
            const optionChoice = multipleChoices.find(
              (item) => item.order === option.order
            )
            return (
              <div className="mgBt-15 choice-option">
                <div className="choice-option__order">
                  {alphabet[get(optionChoice, 'order')]}.{' '}
                </div>
                <div>{get(optionChoice, 'text')}</div>
              </div>
            )
          })}
          {!isFeedBack ? (
            isCorrectAnswer ? (
              <div className="answer__result">
                <div className="answer__result__wrapper">
                  <div className="answer__result__icon">
                    <img src={images.admin.check_active} alt="" />
                  </div>
                  <div className="answer__result__text">
                    Correct answer selected
                  </div>
                </div>
              </div>
            ) : (
              <div className="answer__result">
                <div className="answer__result__wrapper">
                  <div className="answer__result__icon">
                    <img src={images.wrong_answer} alt="" />
                  </div>
                  <div className="answer__result__text">
                    Wrong answer selected
                  </div>
                </div>
                <div style={{ marginLeft: 25, marginTop: 3 }}>
                  <div className="answer__result__correct">Correct answer:</div>
                  {correctChoices.map((option) => (
                    <div className="answer__result__correct" key={option.order}>
                      {alphabet[option.order]}. {option.text}
                    </div>
                  ))}
                </div>
              </div>
            )
          ) : null}
        </div>
      )
    } else {
      item = (
        <div key={answer.value}>
          <div style={{ fontWeight: 'bold' }}>Answer:</div>
          <div className="mgBt-15">{answer.value}</div>
        </div>
      )
    }

    content.push(item)
  }

  let fileAttachments = parseSafe(answer.file_attachments) || []
  const attachment = answer.attachments
  if (fileAttachments) {
    let item = null

    if (attachment) {
      if (attachment.mimetype === 'image/png') {
        const img = `${config.baseUrl}/web/content/${attachment.id}`

        fileAttachments.push({
          name: attachment?.name || '',
          url: img,
          type: 'image',
        })
      } else {
        const url = `${config.baseUrl}/web/content/${attachment.id}?download=true`
        fileAttachments.push({
          name: attachment?.name || '',
          url: url,
          type: 'attachment',
        })
      }
    }

    item = (
      <PreviewFilesUploadQuiz files={fileAttachments} key={index} isAdmin />
    )

    content.push(item)
  }

  return content
}
