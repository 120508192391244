import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { parse } from 'query-string'
import { makeSelectListCourse } from 'container/Home/selectors'
import { getCourseEnrollment } from 'utils/helper'
import { getUserPaymentMethod } from 'container/Home/actions'
import {
  makeSelectUserPaymentMethod,
  makeSelectDomain,
} from 'container/Home/selectors'
import { get } from 'lodash'
import { DOMAIN } from 'src/routes'
import { getPaymentStatus } from 'utils/request'
import TablePaymentMethod from '../TablePaymentMethod/index'

const SFCClaims = ({ userProfile }) => {
  const dispatch = useDispatch()
  const [claim, setClaim] = useState(null)
  const domain = useSelector(makeSelectDomain())
  const isFLCDomain = get(domain, 'domain', '') === DOMAIN.FLC
  const isUnicornDomain = get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN
  const listCourse = useSelector(makeSelectListCourse())
  const listCourseEnrollment = getCourseEnrollment(listCourse)
  const { search } = useLocation()
  const { user } = parse(search)
  const userPaymentMethod = useSelector(makeSelectUserPaymentMethod())

  useEffect(() => {
    if (user) {
      dispatch(getUserPaymentMethod({ userId: user }))
    }
  }, [user])

  useEffect(() => {
    const getSFCData = async (data) => {
      if (!!data && data?.payment_method === 'credit') {
        const status = await getPaymentStatus(data)
        return { ...status, id: data.id }
      }
      return {}
    }
    const loadListPaymentStatusSfc = async () => {
      const data = []
      userPaymentMethod.forEach((item) => {
        data.push(getSFCData(item))
      })
      const allData = await Promise.all(data)
      setClaim(allData)
    }
    if (Array.isArray(userPaymentMethod) && userPaymentMethod.length > 0) {
      loadListPaymentStatusSfc()
    }
  }, [userPaymentMethod])

  return (
    <div>
      {userPaymentMethod && userPaymentMethod.length > 0 && (
        <div>
          <h1 className="u-manage__heading">Payment List</h1>
          <TablePaymentMethod
            user={user}
            claim={claim}
            userProfile={userProfile}
            isFLCDomain={isFLCDomain}
            isUnicornDomain={isUnicornDomain}
            listCourse={listCourse}
            listCourseEnrollment={listCourseEnrollment}
            userPaymentMethod={userPaymentMethod}
          />
        </div>
      )}
    </div>
  )
}

export default SFCClaims
