import React, { useState } from 'react'
import { Switch, Popover } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { get } from 'lodash'
import { message } from 'antd'

import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import { isCustomType } from 'utils/helper'
import { showFormSchedule, showDeleteSchedule } from '../../../Modal/actions'
import { getSchedulesSuccess, addSchedule } from '../../actions'

import useComponentVisible from 'utils/hooks'
import { formatDate } from 'utils/helper'
import './styles.scss'
import useGetTrainer from 'src/hooks/useGetTrainer'

const TableSchedules = ({
  schedules,
  page,
  perPage,
  isEmpty,
  dispatch,
  setPage,
  course,
}) => {
  const { isTrainer } = useGetTrainer()
  const [selectedSchedules, setSelectedSchedules] = useState([])
  const listMeeting = get(course, 'meeting', [])

  const renderClassName = (id) => {
    const isActive = selectedSchedules.find((course) => course.id === id)

    return isActive ? 'hs-table-sc-active' : ''
  }

  const handleBack = () => {
    setPage(page - 1)
  }

  const handleNext = () => {
    setPage(page + 1)
  }

  const handleClickTable = (schedule) => {
    const isActive = selectedSchedules.find((item) => item.id === schedule.id)

    if (isActive) {
      setSelectedSchedules(
        selectedSchedules.filter((item) => item.id !== schedule.id)
      )
    } else {
      setSelectedSchedules([...selectedSchedules, schedule])
    }
  }

  const handleEditCourse = (schedule) => {
    if (!isTrainer) {
      handleSetVisible()
      dispatch(showFormSchedule(schedule, page))
    }
  }

  const handleDeleteSchedule = (schedule) => {
    if (schedule.id) {
      handleSetVisible()
      dispatch(showDeleteSchedule(schedule, page))
    }
  }

  const formatTime = (start_time, end_time) => {
    return `${start_time} - ${end_time}`
  }

  const handleSetVisible = (schedule) => {
    const newSchedule = schedules.map((item) => {
      if (!schedule) {
        item.visible = false
        return item
      }
      if (item.id === schedule.id) {
        item.visible = true
      } else {
        item.visible = false
      }
      return item
    })

    dispatch(getSchedulesSuccess(newSchedule))
  }

  const renderScheduleType = (schedule) => {
    const isCustom = isCustomType(schedule.schedule_type)

    if (isCustom) {
      const arrayDay = schedule.schedule_type.split(',')
      return (
        <span>
          {arrayDay.length} selected <br />
          {schedule.schedule_type}
        </span>
      )
    }
    return schedule.schedule_type
  }

  const renderLocation = (schedule) => {
    return schedule.venue !== 'online' ? 'in-campus' : schedule.venue
  }

  const handleAddSchedule = () => {
    dispatch(showFormSchedule())
  }

  const handleSetActiveSchedule = (checked, schedule) => {
    if (schedule.id) {
      dispatch(
        addSchedule({ ...schedule, active: checked, id: schedule.id }, page)
      )
    }
  }

  const renderSize = (maxSizeSchedule, schedule) => {
    if (!maxSizeSchedule) {
      return ''
    }
    return `${maxSizeSchedule - schedule.seats}/${maxSizeSchedule}`
  }

  const { ref } = useComponentVisible(false, handleSetVisible)

  const renderDateBetween = (schedule) => {
    if (schedule && schedule.isAlwaysAvailable) {
      return 'Always Available'
    }

    return formatDate(schedule.start_date, schedule.end_date)
  }
  return (
    <>
      <div className={` ${isEmpty ? '' : 'hs-table-sc__wrap'}`}>
        <table className="hs-table-sc">
          <thead className="hs-thead-sc">
            <tr className="hs-row-sc">
              <th>Class code</th>
              <th>Course Run ID</th>
              <th>Scheduled dates</th>
              <th>Day</th>
              <th>Cohort</th>
              <th>Time</th>
              <th>Location</th>
              <th>Zoom URL</th>
              <th>Active</th>
              <th></th>
            </tr>
          </thead>

          {isEmpty ? (
            <tbody>
              <tr>
                <td colSpan={8}>
                  <div className="list-schedule__empty">
                    <div className="list-schedule__empty__heading">
                      There’s nothing scheduled yet.
                    </div>
                    <div className="list-schedule__empty__text">
                      Add and plan your course schedule here,
                      <br /> and publish them whenever you’re ready.
                    </div>
                    <BtnPrimary
                      name="Add a schedule"
                      style={{
                        backgroundColor: '#F6F6F9',
                        fontWeight: 'bold',
                        color: '#27284D',
                      }}
                      disabled={isTrainer}
                      handleClick={handleAddSchedule}
                      iconLeft={images.admin.plus}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="hs-tbody-sc">
              {schedules &&
                schedules.map((schedule) => {
                  const meeting =
                    listMeeting &&
                    listMeeting.find(
                      (item) =>
                        schedule &&
                        get(item, 'schedule_id') === get(schedule, 'id')
                    )
                  const maxSizeSchedule = get(schedule, 'max_size', 0)
                  return (
                    <tr
                      key={schedule.id}
                      className={`hs-row-sc ${renderClassName(schedule.id)}`}
                      onClick={() => {
                        handleClickTable(schedule)
                      }}
                    >
                      <td
                        className="hs-column-sc"
                        onClick={() => handleEditCourse(schedule)}
                      >
                        {schedule.class_code}
                      </td>
                      <td
                        className="hs-column-sc"
                        onClick={() => handleEditCourse(schedule)}
                      >
                        {schedule.course_run_id}
                      </td>
                      <td
                        className="hs-column-sc"
                        onClick={() => handleEditCourse(schedule)}
                      >
                        {renderDateBetween(schedule)}
                      </td>
                      <td
                        className="hs-column-sc"
                        style={{ maxWidth: 400 }}
                        onClick={() => handleEditCourse(schedule)}
                      >
                        {renderScheduleType(schedule)}
                      </td>
                      <td
                        className="hs-column-sc"
                        style={{ maxWidth: 400 }}
                        onClick={() => handleEditCourse(schedule)}
                      >
                        {renderSize(maxSizeSchedule, schedule)}
                      </td>
                      <td
                        className="hs-column-sc"
                        onClick={() => handleEditCourse(schedule)}
                      >
                        {formatTime(schedule.start_time, schedule.end_time)}
                      </td>
                      <td
                        className="hs-column-sc"
                        onClick={() => handleEditCourse(schedule)}
                      >
                        {renderLocation(schedule)}
                      </td>
                      <td className="hs-column-sc switch_wrap">
                        {meeting ? (
                          <>
                            YES
                            <CopyToClipboard
                              text={meeting.url}
                              onCopy={() => {
                                message.success('Copied Zoom Link')
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: '#f6f6f9',
                                  padding: 5,
                                  borderRadius: 3,
                                  marginLeft: 5,
                                }}
                              >
                                <img
                                  src={images.share.copy_normal}
                                  alt=""
                                  width="15"
                                />
                              </span>
                            </CopyToClipboard>
                          </>
                        ) : (
                          'No'
                        )}
                      </td>
                      <td
                        className={`hs-column-sc switch_wrap ${
                          isTrainer ? 'disabledbutton' : ''
                        }`}
                      >
                        <Switch
                          checked={schedule.active}
                          onChange={(checked) =>
                            handleSetActiveSchedule(checked, schedule)
                          }
                        />
                      </td>
                      <td
                        className={`hs-column-sc hs-column-cta-sc ${
                          isTrainer ? 'disabledbutton' : ''
                        }`}
                      >
                        <Popover
                          placement="rightTop"
                          overlayClassName="popup_confirm"
                          content={
                            <div className="popup-cta" ref={ref}>
                              <div
                                className="popup-cta__wrap"
                                onClick={() => handleEditCourse(schedule)}
                              >
                                <div className="popup-cta__text">Edit </div>
                                <div className="popup-cta__icon">
                                  <img src={images.admin.pen_alt} alt="" />
                                </div>
                              </div>
                              <div
                                className="popup-cta__wrap"
                                onClick={() => handleDeleteSchedule(schedule)}
                              >
                                <div className="popup-cta__delete">Delete</div>
                                <div className="popup-cta__icon">
                                  <img src={images.admin.trash_icon} alt="" />
                                </div>
                              </div>
                            </div>
                          }
                          visible={schedule.visible}
                          trigger="click"
                        >
                          <div
                            style={{
                              padding: '0 15px',
                            }}
                            onClick={() => handleSetVisible(schedule)}
                          >
                            <img
                              src={images.admin.ellipsis}
                              alt=""
                              style={{ visibility: 'visible', opacity: 1 }}
                            />
                          </div>
                        </Popover>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          )}
        </table>
      </div>

      {!isEmpty && schedules && (
        <div
          style={{
            padding: '10px 20px',
          }}
        >
          <div>Showing results 1 - 15</div>
          <div colSpan="4">
            <div
              className="pagination"
              style={{
                justifyContent: 'flex-end',
                margin: 0,
              }}
            >
              <button
                className="tertiary-btn"
                onClick={handleBack}
                disabled={Number(page) === 1}
              >
                BACK
              </button>
              <div className="pagination__page">Page</div>
              <input
                type="text"
                className="pagination__input"
                value={page}
                onChange={() => {}}
              />
              <button
                style={{ marginLeft: 20 }}
                className="tertiary-btn"
                onClick={handleNext}
                disabled={schedules.length < perPage}
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TableSchedules
