import React, { useMemo, memo } from 'react'
import { get } from 'lodash'

const CourseName = ({ id, listCourse }) => {
  const courseName = useMemo(
    () =>
      get(
        listCourse?.find((x) => x.id === id),
        'name',
        ''
      ),
    [listCourse, id]
  )
  return <span>{courseName}</span>
}

export default memo(CourseName)
