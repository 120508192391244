import React from 'react'
import styled from 'styled-components'
import CourseItem from '../CourseItem'

export const ListCourseWrap = styled.div`
  margin-bottom: ${(props) => `${props.mgBt}px`};
  margin-top: ${(props) => `${props.mgT}px`};
  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
  .list_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 12px;
    color: ${(props) => (props.activeTitle ? '#666666' : '#ffffff')};
  }
  .list_course {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      overflow: hidden;
      overflow-x: auto;
      margin-right: -20px;
      margin-left: -20px;
      padding-left: 20px;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

const AlertNonCourse = styled.div`
  height: 200px;
`

const ListCourse = ({ title, courses, mgBt, activeTitle, cmsArticle }) => {
  return (
    <ListCourseWrap mgBt={mgBt} activeTitle={activeTitle}>
      <div className="list_title">{title}</div>
      <div className="list_course">
        {Array.isArray(courses) && courses.length > 0 ? (
          courses.map((course, index) => (
            <CourseItem course={course} key={index} cmsArticle={cmsArticle} />
          ))
        ) : (
          <AlertNonCourse cmsArticle={cmsArticle} />
        )}
      </div>
    </ListCourseWrap>
  )
}

export default ListCourse
