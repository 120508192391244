import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import { CSVLink } from 'react-csv'

import BtnPrimary from 'component/BtnPrimary'
import {
  hideExportUsers,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../actions'
import { loadListCourse } from 'container/Home/actions'
import { useSelector } from 'react-redux'
import { makeSelectListCourse } from 'container/Home/selectors'
import {
  mapListCourseToDropDown,
  mapScheduleToDropDown,
  isScheduleAlwaysAvailable,
} from 'utils/helper'
import config from 'src/global-config'
import { getAccessToken, getUserInfo } from 'utils/request'
import { showError } from 'utils/notification'
import { getSchedules } from 'container/Admin/actions'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { parseSafe } from 'utils/helper'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes/index'
import * as XLSX from 'xlsx/xlsx.mjs'

const ExportUsers = ({ dispatch, clientsData, showExportUsersModal }) => {
  const [csvData, setCsvData] = useState([])
  const [salesUsers, setSalesUsers] = useState([])
  const exportRef = useRef(null)
  const domain = useSelector(makeSelectDomain())
  const isFlcDomain = get(domain, 'domain', '') === DOMAIN.FLC
  const isUnicornDomain = get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN

  const courses = useSelector(makeSelectListCourse())
  const schedules = useSelector(makeSelectSchedules())
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const isLMADomain = domain && domain.domain === DOMAIN.LMA

  useEffect(() => {
    const getSalesUsers = async () => {
      try {
        const requestUrl = `${config.baseUrl}/api/users?per_page=1000&ignore_company=true&group_ids=67`
        const res = await fetch(requestUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()
        if (Array.isArray(data.data) && data.data.length !== 0) {
          setSalesUsers(data.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
    getSalesUsers()
  }, [])

  const course = useMemo(() => {
    if (selectedCourse && courses) {
      return courses.find((course) => course.id === selectedCourse)
    }
  }, [selectedCourse, courses])

  const requireSchedule = get(course, 'is_schedule', false)

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getSchedules(selectedCourse, true, null, false, true))
    }
  }, [selectedCourse])

  useEffect(() => {
    dispatch(
      loadListCourse({
        admin: true,
        filter: 'sequence',
        newFilter: 'per_page=1000&ignore_per_page=true',
      })
    )
  }, [])

  const handleCancel = () => {
    dispatch(hideExportUsers())
  }

  const handleExport = async () => {
    if (selectedCourse) {
      let requestURL = `${
        config.baseUrl
      }/api/lms/courses/channels/${selectedCourse}/partners?schedule_id=${get(
        selectedSchedule,
        'value',
        0
      )}&per_page=1000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()

        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        if (Array.isArray(data.data) && data.data.length > 0) {
          const requests = []
          const requestsUserEnrollment = []
          for (let i = 0; i < data.data.length; i++) {
            const partner_id = get(data.data, `[${i}].partner_id`)
            const userId = get(data.data, `[${i}].user.id`)
            requests.push(getUserInfo(userId, selectedCourse?.website_id))
            requestsUserEnrollment.push(getUserEnrollment(partner_id))
          }

          const usersInfo = await Promise.all(requests)
          const userEnroll = await Promise.all(requestsUserEnrollment)
          const userData = []

          for (let i = 0; i < data.data.length; i++) {
            let flag = true
            let content = null
            const name =
              get(usersInfo, `[${i}].name`, '') ||
              get(data, `data[${i}].partner.name`, '')
            const email = get(data.data, `[${i}].user.login`, '')
            const id = get(data.data, `[${i}].create_uid`, '')

            const createDate = get(data.data, `[${i}].create_date`, '')
            const lastLogin = get(data.data, `[${i}].user_logs.create_date`, '')

            const dob = get(usersInfo, `[${i}].birthday`, '')
            const phoneNumber =
              get(usersInfo, `[${i}].phone`, '') ||
              get(data.data, `[${i}].user.partner.mobile`, '')
            const contact = get(usersInfo, `[${i}].contact`, '')
            const gender = get(usersInfo, `[${i}].gender`, '')
            const postalAddress = get(usersInfo, `[${i}].postal_address`, '')
            const postalCode = get(usersInfo, `[${i}].postal_code`, '')
            const experience = get(usersInfo, `[${i}].experience`, '')
            const race = get(usersInfo, `[${i}].race`, '')
            const national = get(usersInfo, `[${i}].nationality`, '')
            const occupation = get(usersInfo, `[${i}].occupation`, '')
            const passport = get(usersInfo, `[${i}].passport`, '')
            const educationLevel = get(usersInfo, `[${i}].education_level`, '')
            const additionalData = parseSafe(
              get(usersInfo, `[${i}].additional_info`, '')
            )
            const companyName = get(additionalData, 'company', '')
            const referredName = get(additionalData, 'referred_name', '')
            const referredMobile = get(additionalData, 'referred_mobile', '')
            const referredCo = get(additionalData, 'referred_co', '')
            const questionAbove40 = get(additionalData, 'question_above_40', '')

            const client = clientsData?.reduce((acc, curr) => {
              const existUser = curr?.user_clients?.find(
                (x) => x.user_id === id
              )
              return existUser?.id ? [...acc, curr] : acc
            }, [])

            const labelClient = client?.map((item) => item?.name)?.join(', ')

            content = [
              name,
              email,
              createDate ? dayjs(createDate).format('LL') : '',
              labelClient,
              lastLogin ? dayjs(lastLogin).format('LLL') : '',
              dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
              phoneNumber,
              gender,
            ]

            if (isFlcDomain) {
              content = [
                name,
                passport,
                national,
                questionAbove40,
                email,
                phoneNumber,
                gender,
                dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
                educationLevel,
                occupation,
                companyName,
                referredName,
                referredMobile,
                referredCo,
                '',
                '',
                '',
                '',
                createDate ? dayjs(createDate).format('LL') : '',
                lastLogin ? dayjs(lastLogin).format('LLL') : '',
                '',
              ]
            }

            if (isUnicornDomain) {
              content = [
                name,
                email,
                gender,
                dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
                national,
                passport,
                phoneNumber,
                contact,
                postalAddress,
                postalCode,
                occupation,
                educationLevel,
                experience,
                race,
                createDate ? dayjs(createDate).format('LL') : '',
                lastLogin ? dayjs(lastLogin).format('LLL') : '',
                '',
                '',
                '',
                '',
                '',
                '',
              ]
            }

            const courses = get(userEnroll, `[${i}]`, [])
            if (courses.length !== 0) {
              for (let j = 0; j < courses.length; j++) {
                const partners = get(courses, `[${j}].partners`, [])
                const courseName = get(courses, `[${j}].name`)
                const classCode = get(partners, `[0].schedule.class_code`, '')
                const startDate = get(partners, `[0].schedule.start_date`, '')
                const endDate = get(partners, `[0].schedule.end_date`, '')
                const validDate =
                  dayjs(startDate).isValid() && dayjs(endDate).isValid()
                const isAlwayAvailable = isScheduleAlwaysAvailable(
                  startDate,
                  endDate
                )
                const schedule = validDate
                  ? `${
                      isAlwayAvailable
                        ? 'Always Available'
                        : `${dayjs(startDate).format('DD MMM')} - ${dayjs(
                            endDate
                          ).format('DD MMM YYYY')}`
                    }`
                  : ''
                const dateEnrolled = get(partners, `[0].write_date`, '')
                const refId = get(partners, '[0].ref_id', '')
                const userSales =
                  salesUsers && salesUsers.find((user) => user.id === refId)
                if (partners.length !== 0) {
                  if (isFlcDomain) {
                    content = [
                      name,
                      passport,
                      national,
                      questionAbove40,
                      email,
                      phoneNumber,
                      gender,
                      dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
                      educationLevel,
                      occupation,
                      companyName,
                      referredName,
                      referredMobile,
                      referredCo,
                      get(userSales, 'partner.name', ''),
                      get(
                        parseSafe(get(userSales, 'additional_info', '')),
                        'company',
                        ''
                      ),
                      classCode,
                      courseName,
                      schedule,
                      createDate ? dayjs(createDate).format('LL') : '',
                      lastLogin ? dayjs(lastLogin).format('LLL') : '',
                      dateEnrolled ? dayjs(dateEnrolled).format('LLL') : '',
                    ]
                  } else if (isUnicornDomain) {
                    content = [
                      name,
                      email,
                      gender,
                      dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
                      national,
                      passport,
                      phoneNumber,
                      contact,
                      postalAddress,
                      postalCode,
                      occupation,
                      educationLevel,
                      experience,
                      race,
                      createDate ? dayjs(createDate).format('LL') : '',
                      lastLogin ? dayjs(lastLogin).format('LLL') : '',
                      classCode,
                      courseName,
                      schedule,
                      dateEnrolled ? dayjs(dateEnrolled).format('LLL') : '',
                      get(userSales, 'partner.name', ''),
                      get(
                        parseSafe(get(userSales, 'additional_info', '')),
                        'company',
                        ''
                      ),
                    ]
                  } else {
                    content = [
                      name,
                      email,
                      createDate ? dayjs(createDate).format('LL') : '',
                      labelClient,
                      lastLogin ? dayjs(lastLogin).format('LLL') : '',
                      dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
                      phoneNumber,
                      gender,
                      courseName,
                      classCode,
                      schedule,
                      dateEnrolled ? dayjs(dateEnrolled).format('LLL') : '',
                      get(userSales, 'partner.name', ''),
                      get(
                        parseSafe(get(userSales, 'additional_info', '')),
                        'company',
                        ''
                      ),
                    ]
                  }
                  userData.push(content)
                  flag = false
                }
              }
            }
            if (flag) {
              userData.push(content)
            }
          }

          if (isFlcDomain) {
            userData.unshift([
              'name as stated in NRIC',
              'NRIC',
              'nationality',
              'Are you a Singapore Citizen & age 40 years old and above?',
              'email',
              'phone number',
              'gender',
              'dob',
              'education level',
              'occupation',
              'company name',
              'referrer name',
              'referrer mobile',
              'referrer co',
              'sales agent name',
              'sales agent company',
              'class code',
              'course name',
              'schedule',
              'create date',
              'last login',
              'date enrolled',
            ])
          } else if (isUnicornDomain) {
            userData.unshift([
              'NAME (ACCORDING TO ID/PASSPORT):',
              'Email',
              'Gender',
              'Dob',
              'Nationality',
              'ID/Passport No. (eg. NRIC):',
              'Phone No',
              'Emergency Contact',
              'Postal Address',
              'Postal Code',
              'Occupation',
              'Highest Education Level',
              'Years Of Experience',
              'Race',
              'create date',
              'last login',
              'class code',
              'course name',
              'schedule',
              'date enrolled',
              'sales agent name',
              'sales agent company',
            ])
          } else {
            userData.unshift([
              'name',
              'email',
              'create date',
              'clients',
              'last login',
              'dob',
              'phone number',
              'gender',
              'course name',
              'class code',
              'schedule',
              'date enrolled',
              'sales agent name',
              'sales agent company',
            ])
          }
          await setCsvData(userData)
          exportRef.current.link.click()
        }

        handleCancel()
        dispatch(hideGlobalLoading())
      } catch (error) {
        handleCancel()
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const handleExportAll = async () => {
    let requestURL = `${config.baseUrl}/api/users?per_page=1000&ignore_per_page=true&order_by=id%20asc`

    dispatch(showGlobalLoading())
    try {
      const res = await fetch(requestURL, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })

      const data = await res.json()

      if (Array.isArray(data.data)) {
        const requests = []
        const requestsUserEnrollment = []
        for (let i = 0; i < data.data.length; i++) {
          const partner_id = get(data, `data[${i}].partner_id`)
          const userId = get(data, `data[${i}].id`)
          requests.push(getUserInfo(userId, domain?.id))
          requestsUserEnrollment.push(getUserEnrollment(partner_id))
        }

        const usersInfo = await Promise.all(requests)
        const userEnroll = await Promise.all(requestsUserEnrollment)
        const userData = []

        for (let i = 0; i < data.data.length; i++) {
          let flag = true
          let content = null

          const name =
            get(usersInfo, `[${i}].name`, '') ||
            get(data, `data[${i}].partner.name`, '')
          const email =
            get(usersInfo, `[${i}].email`, '') ||
            get(data, `data[${i}].login`, '')
          const id = get(data, `data[${i}].id`, '')
          const createDate = get(data, `data[${i}].create_date`, '')
          const lastLogin = get(data, `data[${i}].user_logs.create_date`, '')

          const dob = get(usersInfo, `[${i}].birthday`, '')
          const phoneNumber =
            get(usersInfo, `[${i}].phone`, '') ||
            get(data.data, `[${i}].user.partner.mobile`, '')
          const gender = get(usersInfo, `[${i}].gender`, '')

          const national = get(usersInfo, `[${i}].nationality`, '')
          const occupation = get(usersInfo, `[${i}].occupation`, '')
          const passport = get(usersInfo, `[${i}].passport`, '')
          const educationLevel = get(usersInfo, `[${i}].education_level`, '')
          const additionalData = parseSafe(
            get(usersInfo, `[${i}].additional_info`, '')
          )
          const companyName = get(additionalData, 'company', '')
          const referredName = get(additionalData, 'referred_name', '')
          const referredMobile = get(additionalData, 'referred_mobile', '')
          const referredCo = get(additionalData, 'referred_co', '')
          const questionAbove40 = get(additionalData, 'question_above_40', '')
          const client = clientsData?.reduce((acc, curr) => {
            const existUser = curr?.user_clients?.find((x) => x.user_id === id)
            return existUser?.id ? [...acc, curr] : acc
          }, [])

          const labelClient = client?.map((item) => item?.name)?.join(', ')

          content = [
            name,
            email,
            createDate ? dayjs(createDate).format('LL') : '',
            labelClient,
            lastLogin ? dayjs(lastLogin).format('LLL') : '',
            dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
            phoneNumber,
            gender,
          ]

          if (isFlcDomain) {
            content = [
              name,
              passport,
              national,
              questionAbove40,
              email,
              phoneNumber,
              gender,
              dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
              educationLevel,
              occupation,
              companyName,
              referredName,
              referredMobile,
              referredCo,
              '',
              '',
              '',
              '',
              createDate ? dayjs(createDate).format('LL') : '',
              lastLogin ? dayjs(lastLogin).format('LLL') : '',
              '',
            ]
          }

          const courses = get(userEnroll, `[${i}]`, [])
          if (courses.length !== 0) {
            for (let j = 0; j < courses.length; j++) {
              const courseName = get(courses, `[${j}].name`)
              const partners = get(courses, `[${j}].partners`, [])
              const classCode = get(partners, `[0].schedule.class_code`, '')
              const startDate = get(partners, `[0].schedule.start_date`, '')
              const endDate = get(partners, `[0].schedule.end_date`, '')
              const validDate =
                dayjs(startDate).isValid() && dayjs(endDate).isValid()
              const isAlwayAvailable = isScheduleAlwaysAvailable(
                startDate,
                endDate
              )
              const schedule = validDate
                ? `${
                    isAlwayAvailable
                      ? 'Always Available'
                      : `${dayjs(startDate).format('DD MMM')} - ${dayjs(
                          endDate
                        ).format('DD MMM YYYY')}`
                  }`
                : ''
              const dateEnrolled = get(partners, `[0].write_date`, '')
              const refId = get(partners, '[0].ref_id', '')
              const userSales =
                salesUsers && salesUsers.find((user) => user.id === refId)
              if (partners.length !== 0) {
                if (isFlcDomain) {
                  content = [
                    name,
                    passport,
                    national,
                    questionAbove40,
                    email,
                    phoneNumber,
                    gender,
                    dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
                    educationLevel,
                    occupation,
                    companyName,
                    referredName,
                    referredMobile,
                    referredCo,
                    get(userSales, 'partner.name', ''),
                    classCode,
                    courseName,
                    schedule,
                    createDate ? dayjs(createDate).format('LL') : '',
                    lastLogin ? dayjs(lastLogin).format('LLL') : '',
                    dateEnrolled ? dayjs(dateEnrolled).format('LLL') : '',
                  ]
                } else {
                  content = [
                    name,
                    email,
                    createDate ? dayjs(createDate).format('LL') : '',
                    labelClient,
                    lastLogin ? dayjs(lastLogin).format('LLL') : '',
                    dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
                    phoneNumber,
                    gender,
                    courseName,
                    classCode,
                    schedule,
                    dateEnrolled ? dayjs(dateEnrolled).format('LLL') : '',
                    get(userSales, 'partner.name', ''),
                  ]
                }

                userData.push(content)
                flag = false
              }
            }
          }
          if (flag) {
            userData.push(content)
          }
        }
        if (isFlcDomain) {
          userData.unshift([
            'name as stated in NRIC',
            'NRIC',
            'nationality',
            'Are you a Singapore Citizen & age 40 years old and above?',
            'email',
            'phone number',
            'gender',
            'dob',
            'education level',
            'occupation',
            'company name',
            'referrer name',
            'referrer mobile',
            'referrer co',
            'sales agent name',
            'class code',
            'course name',
            'schedule',
            'create date',
            'last login',
            'date enrolled',
          ])
        } else {
          userData.unshift([
            'name',
            'email',
            'create date',
            'clients',
            'last login',
            'dob',
            'phone number',
            'gender',
            'course name',
            'class code',
            'schedule',
            'date enrolled',
            'sales agent name',
          ])
        }

        await setCsvData(userData)
        exportRef.current.link.click()
      }
      handleCancel()
      dispatch(hideGlobalLoading())
    } catch (err) {
      handleCancel()
      dispatch(hideGlobalLoading())
      console.log('err', err)
    }
  }

  const getUserEnrollment = async (userId) => {
    try {
      const request = `${config.baseUrl}/api/lms/courses/channels?order_by=create_date%20desc&published=all&partner_id=${userId}`
      const res = await fetch(request, { method: 'GET' })
      const data = await res.json()
      return data.data
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleExportUsersLMA = async () => {
    if (selectedCourse) {
      let requestURL = `${
        config.baseUrl
      }/api/lms/courses/channels/${selectedCourse}/partners?schedule_id=${get(
        selectedSchedule,
        'value',
        0
      )}&per_page=1000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()

        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        if (Array.isArray(data.data) && data.data.length > 0) {
          const requests = []
          for (let i = 0; i < data.data.length; i++) {
            const userId = get(data.data, `[${i}].user.id`)
            requests.push(getUserInfo(userId, selectedCourse?.website_id))
          }

          const usersInfo = await Promise.all(requests)

          const userData = []

          for (let i = 0; i < data.data.length; i++) {
            let content = null
            const name =
              get(usersInfo, `[${i}].name`, '') ||
              get(data, `data[${i}].partner.name`, '')
            const email = get(data.data, `[${i}].user.login`, '')
            const id = get(data.data, `[${i}].create_uid`, '')

            const createDate = get(data.data, `[${i}].create_date`, '')
            const lastLogin = get(data.data, `[${i}].user_logs.create_date`, '')

            const dob = get(usersInfo, `[${i}].birthday`, '')
            const phoneNumber =
              get(usersInfo, `[${i}].phone`, '') ||
              get(data.data, `[${i}].user.partner.mobile`, '')
            const gender = get(usersInfo, `[${i}].gender`, '')

            const courseName = get(selectedCourse, `label`)
            const classCode = get(data?.data, `[${i}].schedule.class_code`, '')
            const startDate = get(data?.data, `[${i}].schedule.class_code`, '')
            const endDate = get(data?.data, `[${i}].schedule.class_code`, '')
            const validDate =
              dayjs(startDate).isValid() && dayjs(endDate).isValid()
            const isAlwayAvailable = isScheduleAlwaysAvailable(
              startDate,
              endDate
            )
            const schedule = validDate
              ? `${
                  isAlwayAvailable
                    ? 'Always Available'
                    : `${dayjs(startDate).format('DD MMM')} - ${dayjs(
                        endDate
                      ).format('DD MMM YYYY')}`
                }`
              : ''
            const dateEnrolled = get(data?.data, `[${i}].write_date`, '')

            content = {
              Name: name,
              Email: email,
              'Create Date': createDate ? dayjs(createDate).format('LL') : '',
              'Last login': lastLogin ? dayjs(lastLogin).format('LLL') : '',
              DOB: dob ? dayjs(dob, 'DD-MM-YYYY').format('LL') : '',
              'Phone Number': phoneNumber,
              Gender: gender,
              'Course Name': courseName,
              'Class Code': classCode,
              Schedule: schedule,
              'Date Enrolled': dateEnrolled
                ? dayjs(dateEnrolled).format('LLL')
                : '',
            }

            userData.push(content)
          }

          const ws = XLSX.utils.json_to_sheet(userData)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
          XLSX.writeFile(wb, `list_users.xlsx`)
        }

        handleCancel()
        dispatch(hideGlobalLoading())
      } catch (error) {
        handleCancel()
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const handleExportController = () => {
    if (isLMADomain) {
      handleExportUsersLMA()
    } else {
      handleExport()
    }
  }

  return (
    <Modal visible={showExportUsersModal} footer={null} onCancel={handleCancel}>
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Export Users
          <BtnPrimary
            name="Export All"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginLeft: 20,
            }}
            handleClick={handleExportAll}
          />
        </div>
        <div className="sc-modal__label">Courses</div>
        <Select
          className="selectSchedule"
          showSearch
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={(course) => {
            setSelectedCourse(course)
            setSelectedSchedule(null)
          }}
          value={selectedCourse ? selectedCourse : null}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={mapListCourseToDropDown(courses)}
        />

        {requireSchedule && schedules && (
          <>
            <div className="sc-modal__label" style={{ marginTop: 20 }}>
              Schedules
            </div>
            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={(_, data) => {
                setSelectedSchedule(data)
              }}
              value={!isEmpty(selectedSchedule) ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, false, true)}
            />
          </>
        )}

        {selectedCourse && (
          <BtnPrimary
            name="Export"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginTop: 20,
            }}
            handleClick={handleExportController}
          />
        )}

        <CSVLink
          filename="list_users.csv"
          data={csvData}
          ref={(r) => (exportRef.current = r)}
        />
      </div>
    </Modal>
  )
}

export default ExportUsers
