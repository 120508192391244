import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'

import images from 'Themes/Images'
import { createSection } from '../../../../actions'
import { makeSelectSelectedSection } from '../../../../selectors'
import { ERROR_MESSAGE } from 'utils/constants'
import { showDeleteLessonModal } from 'container/Modal/actions'
import './styles.scss'

const SectionSchema = Yup.object().shape({
  title: Yup.string().required(ERROR_MESSAGE)
})

const Sections = ({ setAddSection }) => {
  const selectedSection = useSelector(makeSelectSelectedSection())
  const dispatch = useDispatch()
  const { id } = useParams()
  const formik = useFormik({
    initialValues: {
      title: ''
    },
    validationSchema: SectionSchema,
    onSubmit: (values) => {
      let payload = {
        name: values.title,
        is_category: true
      }
      if (selectedSection) {
        payload = {
          ...payload,
          id: get(selectedSection, 'id'),
          sequence: get(selectedSection, 'sequence')
        }
      }

      dispatch(createSection(payload, id))
      setAddSection(false)
    }
  })

  useEffect(() => {
    if (selectedSection) {
      formik.setFieldValue('title', get(selectedSection, 'name'))
    } else {
      formik.setFieldValue('title', '')
    }
  }, [selectedSection])

  const handleDeleteSection = () => {
    if (get(selectedSection, 'id', null)) {
      dispatch(showDeleteLessonModal(selectedSection))
    }
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="channel__head channel__head__section">
        <div className="channel__head__left">
          <div className="channel__chapter">SECTION TITLE*</div>
          <div className="channel__title" style={{ width: 400, marginTop: 10 }}>
            <input
              id="title"
              name="title"
              placeholder="Section Title"
              type="text"
              autoComplete="off"
              className="add-course__input"
              value={formik.values.title}
              autoFocus
              onChange={formik.handleChange}
            />
          </div>
          {formik.touched.title && formik.errors.title && (
            <div className="form__error">{formik.errors.title}</div>
          )}
        </div>
        <div className="channel__head__right" style={{ marginTop: 10 }}>
          <div className="channel__head__right__button">
            <button
              className="btn btn-primary admin-btn-primary"
              type="submit"
              style={{ marginRight: 15 }}
            >
              <span className="btn__text">
                <img src={images.admin.save_icon} alt="" />{' '}
                {selectedSection ? 'Update' : 'Save'}
              </span>
            </button>
            <button
              className="btn btn-primary admin-btn-primary"
              type="button"
              onClick={handleDeleteSection}
            >
              <span className="btn__text">
                <img src={images.admin.trash_icon} alt="" /> Delete
              </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Sections
