import React, { useState, useEffect, useMemo } from 'react'
import images from 'Themes/Images'
import classNames from 'classnames'
import ScheduleItem from 'container/Home/screens/CoursePreview/components/ScheduleItem'
import { showError } from 'utils/notification'
import useComponentVisible from 'utils/hooks'
import './styles.scss'
import { setSelectedSchedule } from '../../actions'
import { useDispatch } from 'react-redux'

const SelectSchedule = ({ course, selectedSchedule, schedules }) => {
  const dispatch = useDispatch()
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false)

  const renderSelectedSchedule = () => {
    if (selectedSchedule) {
      return (
        <div className="hs-checkout__selected-schedule">
          <ScheduleItem
            item={selectedSchedule}
            isCheckout={true}
            setIsComponentVisible={setIsComponentVisible}
            course={course}
          />
        </div>
      )
    }

    return (
      <div
        className="hs-checkout__none-select"
        onClick={() => {
          if (Array.isArray(schedules) && schedules.length !== 0) {
            setIsComponentVisible(true)
          } else {
            showError('The schedule is not available now')
          }
        }}
      >
        <div className="hs-checkout__heading">Select Schedule</div>
        <div>
          <img src={images.header.caret_down_black} alt="" />
        </div>
      </div>
    )
  }
  return (
    <div className="hs-checkout__select" ref={ref}>
      {!isComponentVisible ? (
        renderSelectedSchedule()
      ) : (
        <div className="hs-checkout__schedules">
          {Array.isArray(schedules) &&
            schedules.map((item, i) => {
              return (
                <div
                  key={item.id}
                  className={classNames('hs-checkout__schedule-item', {
                    'hs-checkout__schedule-item-active':
                      selectedSchedule && selectedSchedule.id === item.id
                  })}
                  onClick={() => {
                    if (item.available) {
                      dispatch(setSelectedSchedule({ ...item, index: i }))
                      setIsComponentVisible(false)
                    } else {
                      showError(
                        'There are no classes available currently, please check back later.'
                      )
                    }
                  }}
                >
                  <ScheduleItem
                    course={course}
                    handleOpenEnrollForm={() => {}}
                    item={item}
                    isCheckout={true}
                  />
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default SelectSchedule
