import React from 'react'
import { Row, Col, Radio, DatePicker } from 'antd'

import Input from 'component/Input'

const ProfileArtofNumbers = ({ formik, handleNumberInput }) => {
  return (
    <>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Gender:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__name">
            <Radio.Group
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
              value={formik.values.gender}
              name="add-course__radio-type"
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>
      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Date of Birth:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__date-picker">
            <DatePicker
              onChange={(date, dateString) => {
                formik.setFieldValue('date_of_birth', date)
              }}
              allowClear
              value={formik.values.date_of_birth || ''}
              placeholder="Date of Birth"
              className={`sc-modal__datepicker ${
                formik.touched.date_of_birth &&
                formik.errors.date_of_birth &&
                'datepicker__error'
              }`}
            />
            {formik.touched.date_of_birth && formik.errors.date_of_birth && (
              <div className="form__error">{formik.errors.date_of_birth}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row" gutter={30} justify="center">
        <Col xs={24} md={8}>
          <div className="profile__name">Phone No.:</div>
        </Col>
        <Col xs={24} md={12}>
          <div className="profile__input">
            <Input
              name="phone"
              placeHolder="Enter Phone No."
              formik={formik}
              value={formik.values.phone}
              onChange={({ currentTarget }) =>
                handleNumberInput(currentTarget, 'phone')
              }
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProfileArtofNumbers
