import React, { useRef, useState } from 'react'
import { Modal } from 'antd'
import Slider from 'react-slick'
import { hideOnBoardingAdminModal } from '../../actions'
import images from 'Themes/Images'
import './styles.scss'

const OnBoardingAdminModal = ({ dispatch, showOnboardingAdminModal }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef(null)

  const handleCancel = () => {
    dispatch(hideOnBoardingAdminModal())
  }

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (current, next) => setCurrentSlide(next),
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        {i === currentSlide ? (
          <div>
            <img src={images.active_dot} />
          </div>
        ) : (
          <div>
            <img src={images.dot} />
          </div>
        )}
      </div>
    )
  }

  const handleNextSlide = () => {
    if (sliderRef && sliderRef.current) {
      console.log(sliderRef.current)
      sliderRef.current.slickNext()
    }
  }

  return (
    <>
      <Modal
        visible={showOnboardingAdminModal}
        footer={null}
        onCancel={handleCancel}
        width={800}
        closable={false}
      >
        <Slider {...settings} ref={sliderRef}>
          <div>
            <img
              src="https://longacademy.hypeschools.com/web/content/7732"
              className="onboarding__image"
            />
            <div className="onboarding__text">
              Setup your logo, banner, categories & copy to feature on your
              front page
            </div>
          </div>
          <div>
            <img
              src="https://longacademy.hypeschools.com/web/content/7733"
              className="onboarding__image"
            />
            <div className="onboarding__text">
              Add your colleagues or enrol new users
            </div>
          </div>
          <div>
            <img
              src="https://longacademy.hypeschools.com/web/content/7734"
              className="onboarding__image"
            />
            <div className="onboarding__text">Create your first course</div>
          </div>
        </Slider>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="mgT-20">
            <button
              className="btn btn-tertiary"
              onClick={currentSlide === 2 ? handleCancel : handleNextSlide}
              type="button"
            >
              {currentSlide === 2 ? 'Done' : 'Next'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default OnBoardingAdminModal
