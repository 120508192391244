import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import images from 'Themes/Images'
import config from '../../global-config'
import { get } from 'lodash'
import { fileToBase64 } from 'utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { makeSelectSelectedSlide } from 'container/Home/selectors'
import { getAccessToken, setAccessToken } from '../../utils/request'
import ReactDropdown from 'react-dropdown'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { Logout } from '../../container/Auth/actions'
import { showError } from 'utils/notification'

const Wrapper = styled.div`
  .cancel {
    margin-left: 20px;
    margin-right: 20px;
    color: #042580;
    cursor: pointer;
  }
`

const UploadVideoWrapper = styled.div`
  width: 218px;
  height: 130px;
  border-radius: 4px;
  border: 1px solid #e7ebf4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .head {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #5a6686;
  }
  .text {
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #8793b0;
  }
  .cancel {
    cursor: pointer;
    font-size: 18px;
    width: 100px;
  }
`

const UploadScormFile = ({
  setScormFileUrl,
  scormFileUrl,
  scormVersion,
  setScormVersion
}) => {
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const selectedSlide = useSelector(makeSelectSelectedSlide())
  const refFile = useRef(null)

  const handleClickFile = () => {
    if (refFile && refFile.current) {
      refFile.current.click()
    }
  }

  const handleUploadScormFile = async (event) => {
    setError(false)

    if (!scormVersion) {
      return setError(true)
    }

    dispatch(showGlobalLoading())
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      const name = get(fileUploaded, 'name', '')

      fileToBase64(fileUploaded).then(async (result) => {
        if (result) {
          const slideId = get(selectedSlide, 'id', '')
          let requestURL = `${config.uploadApi}/api/lms/courses/slides/${slideId}/attachment`

          try {
            const res = await fetch(requestURL, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getAccessToken()}`
              },

              method: 'PUT',
              body: JSON.stringify({
                name: name,
                attachment: result.split('base64,')[1]
              })
            })

            const data = await res.json()

            if (data?.data?.id) {
              await handleHostScormFile(data.data.id)
            }
          } catch (err) {
            dispatch(hideGlobalLoading())
            console.log('err', err)
          }
        }
      })
    }

    const handleHostScormFile = async (idScorm) => {
      try {
        const requestUrl = `${config.baseUrl}/web/dataset/call_kw/slide.slide/onchange`
        const res = await fetch(requestUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`
          },
          body: JSON.stringify({
            jsonrpc: '2.0',
            method: 'call',
            params: {
              args: [
                [],
                { scorm_data: [[6, false, [idScorm]]] },
                'scorm_data',
                { scorm_data: '1', filename: '' }
              ],
              model: 'slide.slide',
              method: 'onchange',
              kwargs: {}
            },
            id: 730104123
          })
        })
        const data = await res.json()
        const expiredError =
          get(data, 'error.data.message', '') === 'Session expired'

        if (expiredError) {
          setAccessToken('')
          dispatch(Logout())
          dispatch(hideGlobalLoading())
          return showError('Your session has expired. Please login again!')
        }

        const fileName = get(data, 'result.value.filename')
        if (fileName) {
          setScormFileUrl(`${config.baseUrl}${fileName}`)
        }
        dispatch(hideGlobalLoading())
      } catch (error) {
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const handleRemoveFileVideo = () => {
    setScormFileUrl('')
  }

  return (
    <Wrapper>
      <div style={{ width: 250, marginBottom: 5 }}>
        <ReactDropdown
          options={[
            { value: 'scorm11', label: 'Scorm 1.1/1.2' },
            { value: 'scorm2004', label: 'Scorm 2004 Edition' }
          ]}
          value={scormVersion}
          onChange={(value) => {
            setError(false)
            setScormVersion(value.value)
          }}
          placeholder="Scorm Versions"
          className="dropdownScheduleModal"
        />
      </div>
      {error && (
        <span className="red">Please select scorm version before upload</span>
      )}

      <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 15 }}>
        {scormFileUrl ? (
          <div style={{ height: 500, width: '100%' }}>
            <iframe
              width="100%"
              height="100%"
              src={scormFileUrl}
              frameBorder="0"
              title="iframe"
            />
          </div>
        ) : (
          <UploadVideoWrapper onClick={handleClickFile}>
            <img src={images.zip_file} alt="upload" width={30} />
            <div className="head">Upload scorm zip file</div>
            <div className="text">Only .Zip or .Rar accepted</div>
          </UploadVideoWrapper>
        )}

        <img
          src={images.admin.trash_icon}
          style={{ marginLeft: 10, marginTop: 10, cursor: 'pointer' }}
          onClick={handleRemoveFileVideo}
          alt="remove"
        />
      </div>
      <input
        type="file"
        accept=".zip,.rar,.7zip"
        style={{ display: 'none' }}
        ref={refFile}
        onChange={handleUploadScormFile}
      />
    </Wrapper>
  )
}

export default UploadScormFile
