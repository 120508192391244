import React from 'react'
import images from 'Themes/Images'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './styles.scss'

const HeaderAdminSettings = ({ option, formikRef }) => {
  const isBrandingSetting = !option || option === 'branding'
  const history = useHistory()
  const dispatch = useDispatch()
  const handleBackButton = () => {
    history.push('/admin')
  }
  const handleSubmit = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.submitForm()
    }
  }
  return (
    <div className="header__courses">
      <div className="header__intro">
        <div className="admin_settings_container">
          <div className="header__course">
            <div className="header__course__level">
              HOME{' '}
              <span
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  verticalAlign: '1px'
                }}
              >
                <img src={images.header.caret_right} />{' '}
              </span>
              SETTINGS
            </div>
            <div className="header__course__title__box">
              <div className="header__course__title admin-courses">
                <div
                  className="admin-courses__title"
                  onClick={handleBackButton}
                >
                  <img src={images.header.back_button} />
                </div>
                <div
                  className="admin-courses__title"
                  style={{ textTransform: 'capitalize' }}
                >
                  {option}
                </div>
              </div>
            </div>
          </div>
          <div>
            {isBrandingSetting && (
              <button
                className="btn btn-primary admin-btn-primary"
                onClick={handleSubmit}
              >
                <span className="btn__text">
                  <img src={images.admin.save_icon} alt="" />
                  Save
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderAdminSettings
