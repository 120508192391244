export const SHOW_GLOBAL_LOADING = 'SHOW_GLOBAL_LOADING'
export const HIDE_GLOBAL_LOADING = 'HIDE_GLOBAL_LOADING'

export const SHOW_MARKING_MODAL = 'SHOW_MARKING_MODAL'
export const HIDE_MARKING_MODAL = 'HIDE_MARKING_MODAL'

export const SHOW_FORM_SCHEDULE = 'SHOW_FORM_SCHEDULE'
export const HIDE_FORM_SCHEDULE = 'HIDE_FORM_SCHEDULE'

export const SHOW_DELETE_MODAL = 'SHOW_DELETE_MODAL'
export const HIDE_DELETE_MODAL = 'HIDE_DELETE_MODAL'

export const SHOW_DELETE_SCHEDULE = 'SHOW_DELETE_SCHEDULE'
export const HIDE_DELETE_SCHEDULE = 'HIDE_DELETE_SCHEDULE'

export const SHOW_DELETE_LESSON_MODAL = 'SHOW_DELETE_LESSON_MODAL'
export const HIDE_DELETE_LESSON_MODAL = 'HIDE_DELETE_LESSON_MODAL'

export const SHOW_FORM_OUTCOME_MODAL = 'SHOW_FORM_OUTCOME_MODAL'
export const HIDE_FORM_OUTCOME_MODAL = 'HIDE_FORM_OUTCOME_MODAL'

export const SHOW_DELETE_OUTCOME_MODAL = 'SHOW_DELETE_OUTCOME_MODAL'
export const HIDE_DELETE_OUTCOME_MODAL = 'HIDE_DELETE_OUTCOME_MODAL'

export const SHOW_DETAILS_REGISTRATION = 'SHOW_DETAILS_REGISTRATION'
export const HIDE_DETAILS_REGISTRATION = 'HIDE_DETAILS_REGISTRATION'

export const SHOW_MARKING_OUTCOME_MODAL = 'SHOW_MARKING_OUTCOME_MODAL'
export const HIDE_MARKING_OUTCOME_MODAL = 'HIDE_MARKING_OUTCOME_MODAL'

export const SHOW_FORM_USER_MODAL = 'SHOW_FORM_USER_MODAL'
export const HIDE_FORM_USER_MODAL = 'HIDE_FORM_USER_MODAL'

export const SHOW_DELETE_ENROLLMENT_MODAL = 'SHOW_DELETE_ENROLLMENT_MODAL'
export const HIDE_DELETE_ENROLLMENT_MODAL = 'HIDE_DELETE_ENROLLMENT_MODAL'

export const SHOW_CONFIRM_QUIZ_MODAL = 'SHOW_CONFIRM_QUIZ_MODAL'
export const HIDE_CONFIRM_QUIZ_MODAL = 'HIDE_CONFIRM_QUIZ_MODAL'

export const SHOW_REORDER_QUIZ_MODAL = 'SHOW_REORDER_QUIZ_MODAL'
export const HIDE_REORDER_QUIZ_MODAL = 'HIDE_REORDER_QUIZ_MODAL'

export const SHOW_FORGOT_PASSWORD_MODAL = 'SHOW_FORGOT_PASSWORD_MODAL'
export const HIDE_FORGOT_PASSWORD_MODAL = 'HIDE_FORGOT_PASSWORD_MODAL'

export const SHOW_EXPORT_REGISTRATIONS = 'SHOW_EXPORT_REGISTRATIONS'
export const HIDE_EXPORT_REGISTRATIONS = 'HIDE_EXPORT_REGISTRATIONS'

export const SHOW_SELECT_PRICE_SFC_MODAL = 'SHOW_SELECT_PRICE_SFC_MODAL'
export const HIDE_SELECT_PRICE_SFC_MODAL = 'HIDE_SELECT_PRICE_SFC_MODAL'

export const SHOW_SELECT_SCHEDULE_MODAL = 'SHOW_SELECT_SCHEDULE_MODAL'
export const HIDE_SELECT_SCHEDULE_MODAL = 'HIDE_SELECT_SCHEDULE_MODAL'

export const SHOW_VIDEO_PLAYER = 'SHOW_VIDEO_PLAYER'
export const HIDE_VIDEO_PLAYER = 'HIDE_VIDEO_PLAYER'

export const SHOW_DELETE_LEARNING_OUTCOME = 'SHOW_DELETE_LEARNING_OUTCOME'
export const HIDE_DELETE_LEARNING_OUTCOME = 'HIDE_DELETE_LEARNING_OUTCOME'

export const SHOW_EXPORT_USERS = 'SHOW_EXPORT_USERS'
export const HIDE_EXPORT_USERS = 'HIDE_EXPORT_USERS'

export const SHOW_EXPORT_COURSE_REPORT = 'SHOW_EXPORT_COURSE_REPORT'
export const HIDE_EXPORT_COURSE_REPORT = 'HIDE_EXPORT_COURSE_REPORT'

export const SHOW_EXPORT_INVOICES = 'SHOW_EXPORT_INVOICES'
export const HIDE_EXPORT_INVOICES = 'HIDE_EXPORT_INVOICES'

export const SHOW_IMPORT_USERS = 'SHOW_IMPORT_USERS'
export const HIDE_IMPORT_USERS = 'HIDE_IMPORT_USERS'

export const SHOW_PROCESSING_MODAL = 'SHOW_PROCESSING_MODAL'
export const HIDE_PROCESSING_MODAL = 'HIDE_PROCESSING_MODAL'

export const SET_COUNT_USER_IMPORTED = 'SET_COUNT_USER_IMPORTED'
export const SET_COUNT_USER_ASSIGNED_TO_COURSE =
  'SET_COUNT_USER_ASSIGNED_TO_COURSE'
export const SET_COUNT_USER_ASSIGNED_TO_FOLDER =
  'SET_COUNT_USER_ASSIGNED_TO_FOLDER'
export const SET_COUNT_USER_ENROLLED = 'SET_COUNT_USER_ENROLLED'

export const SHOW_ONBOARDING_ADMIN_MODAL = 'SHOW_ONBOARDING_ADMIN_MODAL'
export const HIDE_ONBOARDING_ADMIN_MODAL = 'HIDE_ONBOARDING_ADMIN_MODAL'

export const SHOW_PREVIEW_MODAL = 'SHOW_PREVIEW_MODAL'
export const HIDE_PREVIEW_MODAL = 'HIDE_PREVIEW_MODAL'

export const SHOW_FORM_CLIENT_MODAL = 'SHOW_FORM_CLIENT_MODAL'
export const HIDE_FORM_CLIENT_MODAL = 'HIDE_FORM_CLIENT_MODAL'

export const SHOW_BELLS_COURSE_REPORT = 'SHOW_BELLS_COURSE_REPORT'
export const HIDE_BELLS_COURSE_REPORT = 'HIDE_BELLS_COURSE_REPORT'

export const SHOW_EXPORT_QUIZ_REPORT = 'SHOW_EXPORT_QUIZ_REPORT'
export const HIDE_EXPORT_QUIZ_REPORT = 'HIDE_EXPORT_QUIZ_REPORT'
