import React, { useEffect, useState } from 'react'
import images from 'Themes/Images'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { stringify, parse } from 'query-string'
import { defaultStringifyOption } from '../../../../../utils/helper'
import { eliminateProperty } from '../../../components/TableCourses'
import './styles.scss'
// import UpdateInvoice from '../../../../../component/UpdateInvoices'

const HeaderAdminCourses = ({ handleCreateNewCourse, isClientAdmin }) => {
  const [searchValue, setSearchValue] = useState('')
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { name } = parse(search)

  const handleChangeSearchValue = (e) => setSearchValue(e.currentTarget.value)

  const handelSearch = () => {
    const params = stringify(
      eliminateProperty({
        name: searchValue,
      }),
      defaultStringifyOption
    )
    history.push(`${pathname}?${params}`)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handelSearch()
    }
  }

  useEffect(() => {
    if (name) {
      setSearchValue(name)
    }
  }, [name])

  return (
    <div className="header__courses">
      <div className="header__intro">
        <div className="header__course">
          <div className="header__course__title__box">
            <div
              className="header__course__title admin-courses"
              style={{ maxWidth: 'unset' }}
            >
              <div className="admin-courses__title">Manage Courses</div>
            </div>
            {/* <UpdateInvoice /> */}
            <Link to="admin/course/channel/new">
              <button
                className="primary-btn custom-btn"
                onClick={handleCreateNewCourse}
              >
                <img src={images.admin.plus_green} alt="" />
                <span>New Course</span>
              </button>
            </Link>

            {!isClientAdmin ? (
              <div className="head-filter__right">
                <div className="head-filter__search">
                  <img
                    src={images.header.search}
                    alt="search"
                    onClick={handelSearch}
                    style={{ top: 11 }}
                  />
                  <input
                    type="text"
                    placeholder="Search Courses"
                    onChange={handleChangeSearchValue}
                    value={searchValue}
                    onKeyPress={handleKeyPress}
                    style={{ height: 37, cursor: 'auto' }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderAdminCourses
