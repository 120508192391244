import config from 'src/global-config'
import { get, isEmpty } from 'lodash'
import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/helper'

import { LoginSuccess, LoginFailed } from './actions'
import request from 'utils/request'
import * as constants from './constants'
import * as navigation from 'utils/navigation'
import * as actions from './actions'
import * as modalActions from 'container/Modal/actions'
import { makeSelectRefId } from '../Home/selectors'
import {
  createUserInfo,
  setAccessToken,
  addClientInfoForUserSignUp,
} from 'utils/request'
import { getAdminFirstLogin, setAdminFirstLogin } from 'utils/localStorage'
import { showOnBoardingAdminModal } from '../Modal/actions'
import { isAdminRole, isTrainerRole } from 'utils/userUtils'

function* onLoginSession(action) {
  const values = action.params.values
  const requestURL = `${config.baseUrl}/web/session/authenticate`

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        jsonrpc: '2.0',
        params: {
          db: 'postgres',
          login: values.login,
          password: values.password
        }
      })
    })

    console.log('res', res)
  } catch (err) {
    console.log('err', err)
  }
}

const getClientByID = async (clientID) => {
  try {
    const res = await axiosInstance.get(`/api/clients?id=${clientID}`)
    const data = getResponseData(res)
    return get(data, '[0]', null)
  } catch (error) {
    console.log('error', error)
  }
}

function* onLogin(action) {
  yield put(modalActions.showGlobalLoading())
  const values = action.params.values

  const requestURL = `${config.baseUrl}/api/users/login`
  const body = {
    login: values.email,
    password: values.password
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(body)
    })

    const errors = get(res, 'errors', null)
    if (errors) {
      yield put(modalActions.hideGlobalLoading())
      if (errors.code === 422) {
        const error = get(errors, 'errors.login[0]')
        return yield put(LoginFailed(error))
      }
      if (errors.code === 400 || errors.code === 401) {
        const error = get(errors, 'message')
        return yield put(LoginFailed(error))
      }
      return
    }

    const result = get(res, 'data.result', null)
    const userGroups = get(result, 'user_groups', [])
    const accessToken = get(res, 'data.access_token', '')
    const isAdmin = isAdminRole(userGroups)
    const isTrainer = isTrainerRole(userGroups)

    setAccessToken(accessToken)
    yield put(actions.setAccessToken(accessToken))
    yield put(LoginSuccess({ ...result, is_trainer: !!isTrainer }))
    yield put(modalActions.hideGlobalLoading())

    if (isAdmin || isTrainer) {
      yield put(actions.loginSession({ values: body }))
    }

    const userClients = get(result, 'user_clients', null)
    if (!isEmpty(userClients)) {
      const link = `${config.baseUrl}/`
      if (userClients?.length > 1) {
        return window.location.replace(link)
      } else {
        const slug = userClients?.[0]?.client?.slug
        if (slug) {
          const url = `${link}${slug}`
          console.log('🚀 ~ url:', url)
          return window.location.replace(url)
        }
      }
    }

    if (isAdmin || isTrainer) {
      const adminFirstLogin = getAdminFirstLogin()
      if (!adminFirstLogin) {
        setAdminFirstLogin(accessToken)
        yield put(showOnBoardingAdminModal())
      }
      navigation.navigate('/admin?page=1&perpage=15')
    } else {
      navigation.navigate('/?page=1&perpage=15')
    }
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onSignUp(action) {
  yield put(modalActions.showGlobalLoading())
  const ref = yield select((state) => makeSelectRefId()(state))
  const requestURL = `${config.baseUrl}/api/users/signup`
  const clientID = localStorage.getItem('clientID') || null
  const body = {
    name: action.params.name,
    login: action.params.email,
    password: action.params.password,
    mobile: action.params.phone
  }

  if (ref) {
    body.ref_id = ref
  }

  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(body)
    })

    const error = get(res, 'errors', null)
    const result = get(res, 'data.id', null)

    if (error) {
      yield put(modalActions.hideGlobalLoading())
      const errorText = 'Oops! You can not have two users with the same email!'
      return yield put(LoginFailed(errorText))
    }

    if (result) {
      if (clientID) {
        yield call(addClientInfoForUserSignUp, {
          user_id: result,
          client_id: +clientID,
        })
      }

      yield call(createUserInfo, result, {
        name: action.params.name,
        email: action.params.email
      })

      yield put(
        actions.Login({
          values: {
            email: action.params.email,
            password: action.params.password
          }
        })
      )
      yield put(modalActions.hideGlobalLoading())
      navigation.navigate('/auth?register=success')
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onGetUserInfoByToken(action) {
  yield put(modalActions.showGlobalLoading())

  const requestURL = `${config.baseUrl}/api/users/${action.params}/tokens`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET'
    })

    const errors = get(res, 'errors')

    if (errors && errors.message) {
      yield put(modalActions.hideGlobalLoading())
      return yield put(LoginFailed(errors.message))
    }

    if (res.data) {
      yield put(actions.getUserInfoByTokenSuccess(res.data))
    }
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onResetPassword(action) {
  const requestURL = `${config.baseUrl}/api/users/change-password `
  const body = {
    name: action.params.name,
    login: action.params.email,
    password: action.params.password,
    token: action.params.token
  }
  yield put(modalActions.showGlobalLoading())
  try {
    yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(body)
    })

    yield put(
      actions.Login({
        values: { email: action.params.email, password: action.params.password }
      })
    )

    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onPersistRehydrate(action) {
  if (action.key === 'auth' && get(action, 'payload.accessToken')) {
    const accessToken = get(action, 'payload.accessToken')
    if (accessToken) {
      setAccessToken(accessToken)
    }
  }
  yield delay(100)
}

export default function* authSaga() {
  yield takeLatest(constants.LOGIN, onLogin)
  yield takeLatest(constants.SIGNUP, onSignUp)
  yield takeLatest(constants.LOGIN_SESSION, onLoginSession)
  yield takeLatest(constants.RESET_PASSWORD, onResetPassword)
  yield takeLatest(constants.GET_USER_INFO_BY_TOKEN, onGetUserInfoByToken)
  yield takeLatest('persist/REHYDRATE', onPersistRehydrate)
}
