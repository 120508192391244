import React, { useEffect, useState } from 'react'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FilterCourses from 'src/component/FilterCourses'
import HeaderLeadsPage from '../../../../components/HeaderLeadsPage'
import TableLeads from '../../../../components/TableLeads'
import { loadListCollection } from '../../actions'
import { makeSelectListCollection } from '../../selectors'
import { makeSelectListDomain } from 'container/Home/selectors'
import { AdminLayout } from 'src/styles'
import './styles.scss'

const filterOptions = [
  {
    text: `Leads`,
    value: 'leads'
  },
  { text: 'Archived', value: 'archive' },
  { text: 'Enrolled', value: 'create_date' }
]

const LeadsPage = () => {
  const { search } = useLocation()
  const { page = 1, perpage = 15, keyword = '' } = parse(search)
  const [filter, setFilter] = useState('leads')
  const dispatch = useDispatch()
  const listCollection = useSelector(makeSelectListCollection())
  const listDomain = useSelector(makeSelectListDomain())

  useEffect(() => {
    if (page || keyword || filter) {
      dispatch(loadListCollection({ page, keyword, filter }))
    }
  }, [page, keyword, filter])

  return (
    <>
      <HeaderLeadsPage title="Leads" />
      <AdminLayout>
        <div className="container_left">
          <FilterCourses
            isSuperAdmin
            setFilter={setFilter}
            filter={filter}
            filterOptions={filterOptions}
          />
        </div>
        <div className="container_right">
          <TableLeads
            dispatch={dispatch}
            listCollection={listCollection}
            page={page}
            perPage={perpage}
            filter={filter}
            listDomain={listDomain}
          />
        </div>
      </AdminLayout>
    </>
  )
}

export default LeadsPage
