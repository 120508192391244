import React from 'react'
import images from 'Themes/Images'
import { Switch } from 'antd'
import './styles.scss'

const TableCategories = ({
  categories,
  handleSwitch,
  handleDeleteCategory,
  handleEditCategory
}) => {
  return (
    <>
      <table className="hs-category-table inner_container_table">
        <thead className="hs-category-thead">
          <tr className="hs-category-row">
            <th>Category</th>
            <th>Description</th>
            <th>Image</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody className="hs-category-tbody">
          {categories &&
            categories.map((category, index) => {
              return (
                <tr key={index} className={`hs-category-row`}>
                  <td
                    className="hs-category-column"
                    onClick={() => handleEditCategory(category)}
                  >
                    {category.name}
                  </td>
                  <td
                    className="hs-category-column"
                    onClick={() => handleEditCategory(category)}
                  >
                    {category.description}
                  </td>
                  <td
                    className="hs-category-column"
                    onClick={() => handleEditCategory(category)}
                  >
                    <img className="hs-category-img" src={category.image} />
                  </td>
                  <td className="hs-category-column">
                    <div className="add-course__switch switch_wrap">
                      <Switch
                        size={25}
                        checked={category.active}
                        onChange={(checked) => handleSwitch(checked, category)}
                      />

                      <div style={{ paddingLeft: 15 }}>
                        <img
                          src={images.admin.pen_alt_active}
                          onClick={() => handleEditCategory(category)}
                        />
                      </div>
                      <div style={{ paddingLeft: 15 }}>
                        <img
                          src={images.admin.trash_icon}
                          onClick={() => handleDeleteCategory(category)}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )
}

export default TableCategories
