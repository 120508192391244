import React, { useState } from 'react'
import { formatMoney } from 'utils/helper'
import BtnPrimary from 'component/BtnPrimary'
import {
  ListPriceWrapper,
  PriceItemWrapper,
} from 'container/Modal/components/SelectPriceSFCModal'
import { parseOptionsPrice } from 'utils/helper'

const CoursePrices = ({ coursePrices, form }) => {
  const [selectedPrice, setSelectedPrice] = useState(null)

  return (
    <ListPriceWrapper style={{ justifyContent: 'flex-start' }}>
      {parseOptionsPrice(coursePrices?.[0]?.price_options)?.map((price) => {
        const active = selectedPrice?.price === price.price
        return (
          <PriceItemWrapper
            key={price.title}
            style={{ marginBottom: 0, width: 200 }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                height: '100%',
              }}
            >
              <div>
                <div className="price__title">{price.title}</div>
                <div className="price">{formatMoney(price.price)}</div>
              </div>
              <div style={{ marginTop: 'auto' }}>
                <BtnPrimary
                  selectedPrice={active}
                  name={active ? 'Selected' : 'Select'}
                  style={{
                    fontWeight: 'bold',
                    padding: '12px 10px',
                    color: '#fff',
                    width: '100%',
                    backgroundColor: active ? 'rgb(255, 13, 107)' : '',
                  }}
                  type="button"
                  handleClick={() => {
                    setSelectedPrice(price)
                    form.setFieldsValue({ price: price })
                  }}
                />
              </div>
            </div>
          </PriceItemWrapper>
        )
      })}
    </ListPriceWrapper>
  )
}

export default CoursePrices
