import React, { useState } from 'react'
import dayjs from 'dayjs'
import images from 'Themes/Images'
import { formatMoney } from 'utils/helper'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import { Popover } from 'antd'
import {
  loadListCourseSuccess,
  loadCourseSuccess,
} from 'container/Home/actions'
import { showDeleteModal } from 'container/Modal/actions'
import useComponentVisible from 'utils/hooks'
import TableActions from '../TableActions'
import './styles.scss'
import {
  showBellsCourseReport,
  showExportCourseReport,
  showExportQuizReport,
} from '../../../Modal/actions'
import { stringify, parse } from 'query-string'
import { useSelector } from 'react-redux'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes'
import useGetTrainer from '../../../../hooks/useGetTrainer'

export const initialSortedObj = {
  total_days: false,
  name: false,
  course_type: false,
  is_published: false,
  price: false,
  create_date: false,
}

export const eliminateProperty = (obj) =>
  Object.entries(obj).reduce(
    (prev, [k, v]) => (v ? { ...prev, [k]: v } : prev),
    {}
  )

const TableCourses = ({ courses, courseType, page, perPage, dispatch }) => {
  const [selectedCourses, setSelectedCourses] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const history = useHistory()
  const { pathname, search } = useLocation()
  const parsedSearch = parse(search)
  const domain = useSelector(makeSelectDomain())
  const isBellDomain = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION
  const isAscendoDomain =
    domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO

  const renderCategoryName = (id) => {
    const categoryName = courseType.find((item) => item.id === id)
    return get(categoryName, 'name', 'no-category')
  }

  const renderClassName = (id) => {
    const isActive = selectedCourses.find((course) => course.id === id)
    if (isActive) {
      return 'hs-table-active'
    }
    return ''
  }
  const handleBack = () => {
    const params = stringify(
      eliminateProperty({
        ...parsedSearch,
        page: Number(page) - 1,
      }),
      {
        skipNulls: true,
        encode: false,
        indices: false,
      }
    )

    history.push(`${pathname}?${params}`)
  }

  const handleNext = () => {
    const params = stringify(
      eliminateProperty({
        ...parsedSearch,
        page: Number(page) + 1,
      }),
      {
        skipNulls: true,
        encode: false,
        indices: false,
      }
    )

    history.push(`${pathname}?${params}`)
  }

  const handleClickTable = (course) => {
    const isActive = selectedCourses.find((item) => item.id === course.id)
    if (isActive) {
      setSelectedCourses(
        selectedCourses.filter((item) => item.id !== isActive.id)
      )
    } else {
      setSelectedCourses([...selectedCourses, course])
    }
  }

  const handleEditCourse = (course) => {
    history.push(`/admin/course/channel/${course.id}?route=general`)
  }

  const handleAssetStudent = (course) => {
    history.push(`/admin/course/outcome-views/${course.id}`)
  }
  const handleGenerateCertificate = (course) => {
    history.push(`/admin/course/certificates-views/${course.id}`)
  }

  const handleViewBadges = (course) => {
    history.push(`/admin/course/badges-views/${course.id}`)
  }

  const handleViewCourse = (course, isMarkQuiz, isPreview) => {
    if (isPreview) {
      return history.push(`/course/preview/${course.id}`)
    }
    if (isMarkQuiz) {
      return history.push(
        `/admin/course/channel-views/${course.id}?quiz=true&option=mark_quiz`
      )
    }
    history.push(`/admin/course/channel-views/${course.id}`)
  }
  const handleChange = () => {}

  const handleDeleteCourse = (course) => {
    if (course.id) {
      dispatch(loadCourseSuccess(course))
      dispatch(showDeleteModal(course))
    }
  }

  const handleShowMenu = (course = {}) => {
    const listCourses = [...courses]
    for (let i = 0; i < courses.length; i++) {
      if (courses[i].id === course.id) {
        listCourses[i].show = !listCourses[i].show
      } else {
        listCourses[i].show = false
      }
    }

    dispatch(loadListCourseSuccess(listCourses))
  }

  const handleSelectAll = () => {
    if (!isSelectAll) {
      const cloneAllCourses = [...courses]
      setSelectedCourses(cloneAllCourses)
      setIsSelectAll(true)
    } else {
      setSelectedCourses([])
      setIsSelectAll(false)
    }
  }
  const handleExportCourseReport = (course) => {
    handleShowMenu()
    dispatch(showExportCourseReport({ course }))
  }

  const handleExportQuizReport = (course) => {
    handleShowMenu()
    dispatch(showExportQuizReport({ course }))
  }

  const handleBellsCourseReport = (course) => {
    handleShowMenu()
    dispatch(showBellsCourseReport({ course }))
  }

  const { ref } = useComponentVisible(false, handleShowMenu)

  const handleSorting = (name) => {
    const params = stringify(
      eliminateProperty({
        page: 1,
        perpage: 15,
        ...parsedSearch,
        ...initialSortedObj,
        [name]: parsedSearch[name] === 'asc' ? 'desc' : 'asc',
      }),
      {
        skipNulls: true,
        encode: false,
        indices: false,
      }
    )

    history.push(`${pathname}?${params}`)
  }

  const renderCaret = (name) => {
    if (parsedSearch[name] === 'asc') {
      return <img src={images.header.caret_up_black} alt="" />
    } else {
      return <img src={images.header.caret_down_black} alt="" />
    }
  }

  const { isTrainer } = useGetTrainer()

  return (
    <>
      {selectedCourses && selectedCourses.length !== 0 && (
        <TableActions
          selectedCourses={selectedCourses}
          dispatch={dispatch}
          page={page}
          perPage={perPage}
          isSelectAll={isSelectAll}
          setSelectedCourses={setSelectedCourses}
          handleSelectAll={handleSelectAll}
        />
      )}
      <table className="hs-table">
        <thead className="hs-thead">
          <tr className="hs-row">
            <th onClick={() => handleSorting('name')}>
              Courses {renderCaret('name')}
            </th>
            <th onClick={() => handleSorting('course_type')}>
              Categories {renderCaret('course_type')}
            </th>
            <th onClick={() => handleSorting('total_days')}>
              Hours {renderCaret('total_days')}
            </th>
            <th onClick={() => handleSorting('price')}>
              Price (SGD) {renderCaret('price')}
            </th>
            <th onClick={() => handleSorting('create_date')}>
              Added on {renderCaret('create_date')}
            </th>
            <th onClick={() => handleSorting('is_published')}>
              Status {renderCaret('is_published')}
            </th>
            <th></th>
            <th style={{ paddingLeft: 0, paddingRight: 10 }}>Select</th>
          </tr>
        </thead>
        <tbody className="hs-tbody">
          {courses &&
            courseType &&
            courses.map((course) => {
              const isActive = selectedCourses.find(
                (item) => item.id === course.id
              )

              const courseLink = `/admin/course/channel/${course.id}?route=general`
              return (
                <tr
                  key={course.id}
                  className={`hs-row ${renderClassName(course.id)}`}
                >
                  <td
                    className="hs-column"
                    onClick={() => handleEditCourse(course)}
                  >
                    <Link className="table_link" to={courseLink}>
                      {course.name}
                    </Link>
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleEditCourse(course)}
                  >
                    <Link className="table_link" to={courseLink}>
                      {renderCategoryName(course.course_type)}
                    </Link>
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleEditCourse(course)}
                  >
                    <Link className="table_link" to={courseLink}>
                      {course.total_days + ' hrs'}
                    </Link>
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleEditCourse(course)}
                  >
                    <Link className="table_link" to={courseLink}>
                      {formatMoney(course.price)}
                    </Link>
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleEditCourse(course)}
                  >
                    <Link className="table_link" to={courseLink}>
                      {dayjs(course.create_date).format('L')}
                    </Link>
                  </td>
                  <td
                    className="hs-column"
                    onClick={() => handleEditCourse(course)}
                  >
                    <Link className="table_link" to={courseLink}>
                      {course.is_published ? 'Published' : 'Unpublished'}
                    </Link>
                  </td>
                  <td className="hs-column hs-column-cta">
                    <Popover
                      placement="rightTop"
                      visible={course.show}
                      overlayClassName="popup_confirm"
                      content={
                        isTrainer ? (
                          <div className="popup-cta" style={{ width: 200 }}>
                            <div
                              className="popup-cta__wrap"
                              onClick={() =>
                                handleViewCourse(course, false, true)
                              }
                            >
                              <div className="popup-cta__text">
                                View Course Preview
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.input.eye_inactive} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => handleViewCourse(course)}
                            >
                              <div className="popup-cta__text">View Course</div>
                              <div className="popup-cta__icon">
                                <img src={images.input.eye_inactive} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleEditCourse(course)
                              }}
                            >
                              <div className="popup-cta__text">Edit Course</div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleViewCourse(course, true)
                              }}
                            >
                              <div className="popup-cta__text">Mark Quiz</div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleAssetStudent(course)
                              }}
                            >
                              <div className="popup-cta__text">
                                Assess Learning Outcomes
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleGenerateCertificate(course)
                              }}
                            >
                              <div className="popup-cta__text">
                                Generate Certificates
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            {isAscendoDomain && (
                              <div
                                className="popup-cta__wrap"
                                onClick={() =>
                                  handleViewBadges(course, false, true)
                                }
                              >
                                <div className="popup-cta__text">
                                  View Badges
                                </div>
                                <div className="popup-cta__icon">
                                  <img src={images.input.eye_inactive} alt="" />
                                </div>
                              </div>
                            )}
                            <div
                              className="popup-cta__wrap"
                              onClick={() => handleExportCourseReport(course)}
                            >
                              <div className="popup-cta__text">
                                Export Course Report
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => handleExportQuizReport(course)}
                            >
                              <div className="popup-cta__text">
                                Export Quiz Report
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            {isBellDomain && (
                              <div
                                className="popup-cta__wrap"
                                onClick={() => handleBellsCourseReport(course)}
                              >
                                <div className="popup-cta__text">
                                  Assessment Database
                                </div>
                                <div className="popup-cta__icon">
                                  <img src={images.admin.pen_alt} alt="" />
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="popup-cta" style={{ width: 200 }}>
                            <div
                              className="popup-cta__wrap"
                              onClick={() =>
                                handleViewCourse(course, false, true)
                              }
                            >
                              <div className="popup-cta__text">
                                View Course Preview
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.input.eye_inactive} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => handleViewCourse(course)}
                            >
                              <div className="popup-cta__text">View Course</div>
                              <div className="popup-cta__icon">
                                <img src={images.input.eye_inactive} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleEditCourse(course)
                              }}
                            >
                              <div className="popup-cta__text">Edit Course</div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleViewCourse(course, true)
                              }}
                            >
                              <div className="popup-cta__text">Mark Quiz</div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleAssetStudent(course)
                              }}
                            >
                              <div className="popup-cta__text">
                                Assess Learning Outcomes
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleGenerateCertificate(course)
                              }}
                            >
                              <div className="popup-cta__text">
                                Generate Certificates
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            {isAscendoDomain && (
                              <div
                                className="popup-cta__wrap"
                                onClick={() =>
                                  handleViewBadges(course, false, true)
                                }
                              >
                                <div className="popup-cta__text">
                                  View Badges
                                </div>
                                <div className="popup-cta__icon">
                                  <img src={images.input.eye_inactive} alt="" />
                                </div>
                              </div>
                            )}
                            <div
                              className="popup-cta__wrap"
                              onClick={() => handleExportCourseReport(course)}
                            >
                              <div className="popup-cta__text">
                                Export Course Report
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            <div
                              className="popup-cta__wrap"
                              onClick={() => handleExportQuizReport(course)}
                            >
                              <div className="popup-cta__text">
                                Export Quiz Report
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.pen_alt} alt="" />
                              </div>
                            </div>
                            {isBellDomain && (
                              <div
                                className="popup-cta__wrap"
                                onClick={() => handleBellsCourseReport(course)}
                              >
                                <div className="popup-cta__text">
                                  Assessment Database
                                </div>
                                <div className="popup-cta__icon">
                                  <img src={images.admin.pen_alt} alt="" />
                                </div>
                              </div>
                            )}
                            <div
                              className="popup-cta__wrap"
                              onClick={() => {
                                handleShowMenu()
                                handleDeleteCourse(course)
                              }}
                            >
                              <div className="popup-cta__delete">
                                Delete Course
                              </div>
                              <div className="popup-cta__icon">
                                <img src={images.admin.trash_icon} alt="" />
                              </div>
                            </div>
                          </div>
                        )
                      }
                      trigger="click"
                    >
                      <div
                        ref={ref}
                        style={{ padding: '0 15px' }}
                        onClick={() => handleShowMenu(course)}
                      >
                        Actions
                        <img
                          src={images.share.arrow_down}
                          style={{ paddingLeft: 8 }}
                        />
                      </div>
                    </Popover>
                  </td>
                  <td
                    onClick={() => {
                      handleClickTable(course)
                    }}
                    style={{ paddingLeft: 10 }}
                  >
                    {isActive ? (
                      <img src={images.admin.check_with_bg_black} />
                    ) : (
                      <img src={images.admin.uncheck_with_bg} />
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
        <tfoot>
          {courses && (
            <tr>
              <td style={{ paddingLeft: '20px' }}>Showing results 1 - 15</td>
              <td colSpan="4">
                <div
                  className="pagination"
                  style={{
                    justifyContent: 'flex-end',
                    margin: '14px 0',
                  }}
                >
                  <button
                    className="tertiary-btn"
                    onClick={handleBack}
                    disabled={Number(page) === 1}
                  >
                    BACK
                  </button>
                  <div className="pagination__page">Page</div>
                  <input
                    type="text"
                    className="pagination__input"
                    value={page}
                    onChange={handleChange}
                  />
                  <div className="pagination__total-course">
                    {/* of {courses.length} */}
                  </div>
                  <button
                    className="tertiary-btn"
                    onClick={handleNext}
                    disabled={courses.length < perPage}
                  >
                    NEXT
                  </button>
                </div>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    </>
  )
}

export default TableCourses
