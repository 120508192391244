import React, { useState, useEffect, useMemo } from 'react'
import { get } from 'lodash'
import { BackTop } from 'antd'
import { parse } from 'query-string'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import parseHtml from 'html-react-parser'
import { validSchedules } from 'utils/helper'

import * as selector from 'container/Home/selectors'
import images from 'Themes/Images'
import { showError } from 'utils/notification'
import { getSchedules } from 'container/Admin/actions'
import { isEnrollCourse, parseSafe, getDescriptionData } from 'utils/helper'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { makeSelectSchedules } from 'container/Admin/selectors'
import Schedules from 'container/Home/screens/CoursePreview/components/Schedules'
import {
  loadCourse,
  loadCourseSuccess,
  loadSlides,
  postEnroll,
  putUserLogSuccess,
} from '../../actions'
import { CKEditorWrapper } from 'container/CMS/screens/Article/index'
import {
  makeSelectDomain,
  makeSelectLessonLength,
  makeSelectListCategory,
  makeSelectUserInfo,
} from '../../selectors'
import { DOMAIN } from 'src/routes'
import { getUserInfo } from 'container/Home/actions'
import * as profileCheck from 'container/Checkout/components/ProfileBySchool/schema'
import './styles.scss'
import { showGlobalLoading } from '../../../Modal/actions'
import { hideGlobalLoading } from 'container/Modal/actions'
import { makeSelectCourseType } from 'container/Admin/selectors'
import { getAccessToken } from 'utils/request'
import config from 'src/global-config'
import { PreviewPageLayout, BannerImage } from './styles'
import CourseCurriculum from './components/CourseCurriculum'
import AboutCourse from './components/AboutCourse'

const CoursePreview = ({ isClassCompare }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { search } = useLocation()
  const { ref } = parse(search)
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const domain = useSelector(makeSelectDomain())
  const course = useSelector(selector.makeSelectCourse())
  const categories = useSelector(makeSelectListCategory())
  const currentUser = useSelector(makeSelectCurrentUser())
  const schedules = useSelector(makeSelectSchedules())
  const userInfo = useSelector(makeSelectUserInfo())
  const courseId = get(course, 'id', '')
  const courseTypes = useSelector(makeSelectCourseType())
  const slides = useSelector(selector.makeSelectSlides())
  const lessonLength = useSelector(makeSelectLessonLength())
  const [fixedAbout, setFixedAbout] = useState(false)

  useEffect(() => {
    const onScroll = (e) => {
      setFixedAbout(e.target.documentElement.scrollTop > 150)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const categoryId = get(course, 'category_id')
  const courseCategory = useMemo(
    () => categories && categories.find((type) => type.id === categoryId),
    [categoryId, categories]
  )
  const courseTypeId = get(course, 'course_type')
  const courseType = useMemo(
    () => courseTypes && courseTypes.find((type) => type.id === courseTypeId),
    [courseTypeId, courseTypes]
  )

  const partners = get(course, 'partners', [])

  const isEnroll = isEnrollCourse(partners, course)

  const isUnicornDomain = domain && domain.domain === DOMAIN.UNICORN_DOMAIN

  const isFirstMediaDomain = domain && domain.domain === DOMAIN.FIRSTMEDIA

  const isArtOfNumbersDomain =
    domain && domain.domain === DOMAIN.ARTOFUNUMBERS_DOMAIN

  const isAgbEducationDomain = domain && domain.domain === DOMAIN.AGB_EDUCATION

  const isCoursesDomain = domain && domain.domain === DOMAIN.COURSES

  const isCyberQuoteDomain = domain && domain.domain === DOMAIN.CYBERQUOTE

  const isAscendoDomain =
    domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO

  const isSingaporeSkillsAcademies =
    domain && domain.domain === DOMAIN.SINGAPORESKILLS

  const wfaDomain = domain && domain.domain === DOMAIN.WFA

  const isFlcDomain = get(domain, 'domain', '') === DOMAIN.FLC

  useEffect(() => {
    if (course && id) {
      dispatch(getSchedules(id))
      dispatch(loadSlides(id, false, 0))
    }
  }, [id, course])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }, 1000)
  }, [])

  const price = get(course, 'price', 0)
  const allowSpecialPrice = get(course, 'is_allow_special_price', false)
  const specialPrice = get(course, 'special_price', 0)
  const discountPrice = Math.round(((price - specialPrice) / price) * 100)

  const courseBenefits = parseSafe(get(course, 'course_benefits', ''))
  const courseRequirements = parseSafe(get(course, 'course_requirements', ''))

  const prices = get(course, 'prices', [])
  const applicationFee = get(course, 'application_fee', null)
  const enableApplicationFee = get(course, 'is_application_fee', false)
  const name = get(course, 'name', '')
  const description = getDescriptionData(get(course, 'description', ''))
  const shortDescription = get(course, 'short_description', '')
  const imageUrl = get(course, 'image_url', '')
  const previewUrl = get(course, 'preview_url', [])
  const isAllowApplicationFee = enableApplicationFee && applicationFee !== null

  useEffect(() => {
    dispatch(loadCourse(id))
    dispatch(putUserLogSuccess(null))
  }, [id])

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserInfo())
    }
  }, [currentUser])

  const handleViewCourse = () => {
    if (isEnroll) {
      handleEnrolled()
    } else {
      handleNavigateEnroll()
    }
  }

  const handleNavigateEnroll = () => {
    dispatch(loadCourseSuccess(course))
    const requiredSchedule = get(course, 'is_schedule', false)

    if ((isSingaporeSkillsAcademies || wfaDomain) && currentUser) {
      return handleEnrolledForSingaporeSkills()
    }

    if (!currentUser) {
      return history.push(`/checkout/${get(course, 'id', '')}/auth`)
    }

    if (requiredSchedule) {
      return history.push(`/checkout/${get(course, 'id', '')}/confirm`)
    } else {
      return history.push(`/checkout/${get(course, 'id', '')}/complete-profile`)
    }
  }

  const handlePaymentMethodFreeCourse = async () => {
    const userId = get(currentUser, 'uid', '') || get(currentUser, 'id', '')

    const body = {
      user_id: userId,
      channel_id: get(course, 'id', ''),
      payment_method: 'free_payment',
      domain: get(domain, 'domain', ''),
    }

    try {
      const res = await fetch(`${config.baseUrl}/api/payments/payment-method`, {
        method: 'PUT',
        headers: {
          'Content-type': 'Application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      })

      if (res.status !== 200) {
        return history.push(`/checkout/${get(course, 'id')}/fail`)
      }
      const data = await res.json()
      const id = get(data, 'data.id', null)

      if (id) {
        return true
      }

      dispatch(loadCourse(get(course, 'id')))
      dispatch(hideGlobalLoading())
      return true
    } catch (err) {
      dispatch(hideGlobalLoading())
      return false
    }
  }

  const handleEnrolledForSingaporeSkills = async () => {
    dispatch(showGlobalLoading())
    const setPayment = await handlePaymentMethodFreeCourse()
    if (setPayment) {
      setTimeout(() => {
        dispatch(postEnroll({ ref, is_send: true }))
        dispatch(hideGlobalLoading())
      }, 1500)
    }
  }

  const handleEnrolled = () => {
    const urlProfile = `/checkout/${courseId}/complete-profile`

    if (userInfo) {
      if (
        isArtOfNumbersDomain &&
        !profileCheck.checkProfileArtOfNumbers(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (
        isFirstMediaDomain &&
        !profileCheck.checkProfileFirstMedia(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (
        isAgbEducationDomain &&
        !profileCheck.checkProfileAgbEducation(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (isCoursesDomain && !profileCheck.checkProfileCourses(userInfo)) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (
        isCyberQuoteDomain &&
        !profileCheck.checkProfileCyberQuote(userInfo)
      ) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }

      if (isUnicornDomain && !profileCheck.checkProfileUnicorn(userInfo)) {
        showError('Please complete your profile before continuing')
        return history.push(urlProfile)
      }
    }

    return history.push(`/course/channel/${courseId}`)
  }

  const renderTitle = () => {
    return 'Enrol now'
  }

  const renderButtonText = () => {
    if (isClassCompare) {
      return isEnroll ? 'Enrolled' : `Enrol`
    }
    if (isSingaporeSkillsAcademies) {
      return isEnroll ? 'Interest Submitted' : `I'm interested. Tell me more.`
    }
    return isEnroll ? 'View Course' : 'Enrol'
  }

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <PreviewPageLayout>
        <BackTop style={{ right: isClassCompare ? 150 : 100 }}>
          <div className="back-to-top">Top</div>
        </BackTop>
        <BannerImage height={440} opacity={isFlcDomain ? 0.9 : null}>
          <div className="blur" />
          <img
            src={imageUrl ? imageUrl : images.course.default_banner}
            alt="main banner"
          />
        </BannerImage>

        <div className="preview__container">
          <div className="preview__container__left">
            <div className="preview__header__wrapper">
              <h1 className="preview__course-title">{name}</h1>
              <p className="preview__course-desc">{shortDescription}</p>
              <div className="preview__flex">
                <div>
                  <div className="preview__course-cat">Category</div>
                  <div className="preview__course-cat-title">
                    {get(courseCategory, 'name', '')}
                  </div>
                </div>
                <div>
                  <div className="preview__course-review">Course Type</div>
                  <div className="preview__course-rating">
                    {get(courseType, 'name', '')}
                  </div>
                </div>
              </div>
            </div>

            <div className="preview__course-description">
              <h3 className="preview__course-heading">Course Description</h3>
              <CKEditorWrapper pColor="#77838F">
                {description ? parseHtml(description + '') : ''}
              </CKEditorWrapper>
            </div>

            {Array.isArray(courseBenefits) &&
              courseBenefits.length > 0 &&
              !!get(courseBenefits, '[0].text', '') && (
                <div className="preview__course-benefits">
                  <h3 className="preview__course-heading">What you'll learn</h3>
                  <div className="preview__course-benefit">
                    {courseBenefits.map((item) => (
                      <div className="preview__course-benefit-item">
                        <div>
                          <img src={images.check_green} />
                        </div>
                        <div>{item.text}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {Array.isArray(courseRequirements) &&
              courseRequirements.length > 0 &&
              !!get(courseRequirements, '[0].text', '') && (
                <div className="preview__course-benefits">
                  <h3 className="preview__course-heading">Requirements</h3>
                  {courseRequirements.map((item) => (
                    <div className="preview__course-requirement-wrapper">
                      <div className="preview__course-require-item">
                        <div>
                          <img src={images.dot_green} />
                        </div>
                        <div>{item.text}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

            {Array.isArray(slides) && slides.length > 0 && (
              <div className="preview__course-curriculum">
                <h3 className="preview__course-heading">Curriculum</h3>
                <CourseCurriculum slides={slides} />
              </div>
            )}

            {Array.isArray(schedules) && schedules.length > 0 && (
              <div className="preview__course-curriculum">
                <h3 className="preview__course-heading">Schedule</h3>
                <Schedules
                  currentUser={currentUser}
                  schedules={validSchedules(schedules)}
                  dispatch={dispatch}
                  history={history}
                  course={course}
                  previewPage
                />
              </div>
            )}
          </div>
          <div className="preview__container__right">
            <AboutCourse
              imageUrl={imageUrl}
              course={course}
              lessonLength={lessonLength}
              isMobile={isMobile}
              renderTitle={renderTitle}
              handleEnroll={handleViewCourse}
              isEnroll={isEnroll}
              renderButtonText={renderButtonText}
              fixedAbout={fixedAbout}
              schedules={validSchedules(schedules)}
              isAscendoDomain={isAscendoDomain}
            />
          </div>
        </div>
      </PreviewPageLayout>
    </>
  )
}

export default CoursePreview
