import React, { useRef } from 'react'
import { get } from 'lodash'

import images from 'Themes/Images'
import { useDispatch } from 'react-redux'
import { fileToBase64 } from 'utils/helper'
import { createAttachment, deleteAttachment } from '../../../../actions'
import config from 'src/global-config'
import './styles.scss'

const FileComponent = ({ item }) => {
  const dispatch = useDispatch()
  const refFile = useRef(null)
  const name = get(item, 'name', '')
  const attachmentId = get(item, 'attachments.id', '')
  const url = `${config.baseUrl}/web/content/${attachmentId}?download=true`
  let hasFile = false

  if (name && attachmentId) {
    hasFile = true
  }

  const handleClickFile = () => {
    if (refFile && refFile.current) {
      refFile.current.click()
    }
  }

  const handleDownloadFile = () => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  }
  const handleChangeFileDoc = (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      const name = get(fileUploaded, 'name', '')
      const type = get(fileUploaded, 'type', '')

      fileToBase64(fileUploaded).then((result) => {
        if (result) {
          dispatch(deleteAttachment(get(item, 'id')))
          dispatch(
            createAttachment({
              name,
              attachment: result.split('base64,')[1]
            })
          )
        }
      })
    }
  }

  const handleRemoveAttachment = () => {
    if (item) {
      dispatch(deleteAttachment(get(item, 'id'), true))
    }
  }
  return (
    <div className="attachment" style={{ marginBottom: 20, width: '100%' }}>
      <div className="attachment__upload" onClick={handleClickFile}>
        <img
          src={
            hasFile
              ? images.admin.document_upload_active
              : images.admin.document_upload
          }
          alt=""
        />

        {hasFile ? (
          <div className="attachment__text" style={{ color: '#27284d' }}>
            {name}
          </div>
        ) : (
          <>
            <div
              className="attachment__text"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: 220
              }}
            >
              No file uploaded
            </div>
          </>
        )}
      </div>

      <input
        type="file"
        style={{ display: 'none' }}
        ref={refFile}
        onChange={handleChangeFileDoc}
      />
      <div className="attachment__btn__wrapper">
        {hasFile && (
          <div className="attachment__button-2" style={{ marginRight: 15 }}>
            <a
              className="btn__download"
              type="button"
              onClick={handleDownloadFile}
            >
              <img src={images.admin.download} alt="" />
              <span>Download</span>
            </a>
          </div>
        )}

        <div className="attachment__button" style={{ marginRight: 15 }}>
          <button
            className="tertiary-btn"
            style={{ width: 90, padding: '8px 20px' }}
            type="button"
            onClick={handleClickFile}
          >
            Browse
          </button>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={handleRemoveAttachment}>
          <img src={images.admin.trash_icon} alt="" />
        </div>
      </div>
    </div>
  )
}

export default FileComponent
