import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import React from 'react'
import { Radio } from 'antd'
import classNames from 'classnames'
const MediaStripePay = ({ paymentRequest, formik }) => {
  return (
    paymentRequest && (
      <div className="card__main" style={{ borderBottom: '1px solid #dee2ed' }}>
        <Radio value="google_apple_pay">
          <span className="hs-checkout__heading-2">
            Pay with Google Pay/ Apple Pay
          </span>
        </Radio>
        <div
          className={classNames('payment_method', {
            toggle_payment_method:
              formik.values.payment_method === 'google_apple_pay',
          })}
        >
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      </div>
    )
  )
}

export default MediaStripePay
