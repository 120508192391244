import React from 'react'
import { useMediaQuery } from 'react-responsive'

const SwitchPagePdf = ({
  pageNumber,
  numPages,
  handleNextPage,
  handlePreviousPage,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
      }}
    >
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div
        style={{
          zIndex: 1000,
          position: 'relative',
          fontWeight: 'bold',
          marginRight: isMobile ? 0 : 140,
        }}
      >
        <span onClick={handlePreviousPage} style={{ cursor: 'pointer' }}>
          Previous
        </span>{' '}
        |{' '}
        <span style={{ cursor: 'pointer' }} onClick={handleNextPage}>
          Next
        </span>
      </div>
    </div>
  )
}

export default SwitchPagePdf
