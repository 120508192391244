import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import CourseAffiliateItem from '../CourseAffiliateItem';
import { makeSelectListDomain } from '../../container/Home/selectors';

const CourseAffiliateSources = ({ coursesEnrolled }) => {
  const listDomain = useSelector(makeSelectListDomain());
  return (
    <div>
      {coursesEnrolled &&
        coursesEnrolled.map(item => {
          const partners = get(item, 'partners', []);
          const refId = get(partners, '[0].ref_id');
          if (!refId) {
            return null;
          }
          return (
            <CourseAffiliateItem
              key={item.id}
              item={item}
              refId={refId}
              listDomain={listDomain}
            />
          );
        })}
    </div>
  );
};

export default CourseAffiliateSources;
