import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { get } from 'lodash'

import ListCourses from 'src/component/ListCourses'
import {
  makeSelectDomain,
  makeSelectListCategory,
  makeSelectListCourse,
} from '../../selectors'
import {
  setSelectedSlide,
  loadListCourse,
  setSelectedQuiz,
  loadCourseSuccess,
  putUserLogSuccess,
} from '../../actions'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { setSelectedSchedule } from '../../../Checkout/actions'
import styled from 'styled-components'
import images from 'Themes/Images'
import { useMediaQuery } from 'react-responsive'
import './styles.scss'
import {
  Layout,
  Banner,
  BannerMobile,
  Body,
} from '../../../../customs/ClassCompare/styles'
import ListCat from '../../../../customs/ClassCompare/src/components/ListCat/index'
import { sortCourses, isEnrollCourse } from 'utils/helper'
import useElementSize from 'src/hooks/useElementSize'
import { Helmet } from 'react-helmet'

export const MainDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  min-height: ${(props) => `${props.minHeight}px`};

  .main_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 0px;
      display: block;
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    margin-top: 0px;
    display: block;
    img {
      width: 100%;
    }
  }
  section {
    flex: 1;
  }
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 27px;
    }
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 145.6%;
    font-size: 18px;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .school {
    &__left {
      max-width: 767px;
      @media (min-width: 1920px) {
        max-width: 650px;
      }
      @media (max-width: 767px) {
        margin-bottom: 20px;
        & h1 {
          font-size: 42px;
        }
        & h2 {
          font-size: 18px;
        }
      }
    }
    &__right {
      flex: 1;

      .school__logo__2 {
        margin-left: auto;
        padding-right: 30px;
      }

      @media (min-width: 1920px) {
        .school__logo__2 {
          margin-left: auto;
        }
      }
      @media (max-width: 767px) {
        .school__logo__2 {
          margin-right: auto;
          margin-left: 0;
          padding-right: 0;
        }
      }
    }
    &__logo {
      width: 240px;
      height: 240px;
      border-radius: 50%;
      background: #5a6686;
      border: 3px solid #fff;
      overflow: hidden;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      @media (max-width: 767px) {
        width: 220px;
        height: 220px;
        margin-left: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
      }
    }
    &__logo__2 {
      width: 400px;
      height: 320px;
      @media (min-width: 1920px) {
        width: 460px;
      }
      @media (max-width: 767px) {
        width: 280px;
        height: 180px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
      }
    }
  }
`

const Courses = () => {
  const { search } = useLocation()
  const { page = 1, perpage = 15, category } = parse(search)
  const [squareRef, { height }] = useElementSize()
  const [mainRef, { height: heightMain }] = useElementSize()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isLargeDesktop = useMediaQuery({ minWidth: 1920 })
  const domain = useSelector(makeSelectDomain())
  const logo = get(domain, 'logo', '')
  const banner = get(domain, 'banner', '')
  const name = get(domain, 'name', '')
  const pageTitle = get(domain, 'page_title', '')
  const pageDesc = get(domain, 'page_description', '')

  const dispatch = useDispatch()
  const currentUser = useSelector(makeSelectCurrentUser())
  const listCourses = useSelector(makeSelectListCourse())
  const categories = useSelector(makeSelectListCategory())?.filter(
    (cat) => cat?.active
  )

  const isSmallerBanner = heightMain > height

  const coursesFiltered = useMemo(() => {
    if (listCourses) {
      if (
        get(currentUser, 'is_admin', false) ||
        get(currentUser, 'is_trainer', false)
      ) {
        return listCourses
      }
      return listCourses.filter((course) => {
        const isEnroll = isEnrollCourse(get(course, 'partners', []), course)

        return course?.course_visibility ? (isEnroll ? course : null) : course
      })
    }
  }, [currentUser, listCourses])

  const courseHasLastPublished =
    coursesFiltered && coursesFiltered.filter((item) => item.last_published)

  const coursesLatest = sortCourses(courseHasLastPublished, 'last_published')

  const latestCourse = useMemo(() => {
    return isLargeDesktop
      ? coursesLatest && coursesLatest.slice(0, 4)
      : coursesLatest && coursesLatest.slice(0, 3)
  }, [coursesLatest, isLargeDesktop])

  const categoryItem = useMemo(() => {
    return (
      categories &&
      categories.find((cat) => get(cat, 'name', '').toLowerCase() === category)
    )
  }, [categories, category])

  const categoryId = get(categoryItem, 'id')

  useEffect(() => {
    dispatch(loadCourseSuccess(null))
    dispatch(setSelectedSlide(null))
    dispatch(setSelectedSchedule(null))
    dispatch(setSelectedQuiz(null))
    dispatch(putUserLogSuccess(null))
  }, [])

  useEffect(() => {
    if (category) {
      dispatch(
        loadListCourse({
          page,
          perpage,
          catId: category === 'all' ? 0 : categoryId,
          partner: get(currentUser, 'partner_id', ''),
        })
      )
    }
  }, [categoryItem, page, perpage, category])

  useEffect(() => {
    dispatch(loadListCourse())
  }, [])

  return (
    <>
      <Helmet>
        <title>{get(domain, 'name', '')}</title>
      </Helmet>
      <Layout>
        {isMobile ? (
          isSmallerBanner ? (
            <Banner
              img={banner ? banner + '=s0' : images.link.banner + '=s0'}
              height={560}
              heightMobile={heightMain + 30}
              top={50}
            />
          ) : (
            <BannerMobile>
              <img
                ref={squareRef}
                src={banner ? banner + '=s0' : images.link.banner + '=s0'}
                style={{ marginTop: 50 }}
                alt="banner"
              />
            </BannerMobile>
          )
        ) : (
          <Banner
            img={banner ? banner + '=s0' : images.link.banner + '=s0'}
            height={560}
            heightMobile={120}
            top={50}
          />
        )}
        <Body>
          <div>
            <MainDescription
              minHeight={
                isMobile ? (isSmallerBanner ? heightMain + 20 : height) : 400
              }
            >
              <div ref={mainRef} style={{ width: '100%' }}>
                <div className="main_wrapper">
                  {pageTitle || pageDesc ? (
                    <div className="school__left">
                      <h1>{pageTitle}</h1>
                      <h2>{pageDesc}</h2>
                    </div>
                  ) : null}

                  {logo && (
                    <div className="school__right">
                      <div className="school__logo__2">
                        <img src={logo} alt={name + ' logo'} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </MainDescription>
          </div>
          {Array.isArray(latestCourse) && latestCourse.length > 0 && (
            <ListCourses
              courses={latestCourse}
              title="Latest"
              activeTitle={isMobile}
            />
          )}
          <ListCat
            title="Categories"
            categories={categories}
            activeTitle={latestCourse && latestCourse.length === 0}
            mgBt={60}
            normalPage
          />
          {Array.isArray(coursesFiltered) && coursesFiltered.length > 0 && (
            <ListCourses
              courses={coursesFiltered}
              title="Courses"
              activeTitle={
                (categories && categories.length !== 0) ||
                (latestCourse && latestCourse.length !== 0) ||
                isMobile
              }
            />
          )}
        </Body>
      </Layout>
    </>
  )
}

export default Courses
