import React from 'react'
import { toast } from 'react-toastify'
import './notification.styles.scss'

export const showError = (message, time = 10000) => {
  const toastOptions = {
    position: 'top-right',
    autoClose: time,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-container',
  }
  // toast.error(msg, toastOptions);
  toast(<ToastContainer message={message} />, toastOptions)
}

export const showSuccess = (message, autoClose = 10000) => {
  const toastOptions = {
    position: 'top-right',
    autoClose: autoClose,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-container',
  }
  // toast.success(msg, toastOptions);
  toast(<ToastContainer message={message} />, toastOptions)
}

function ToastContainer(props) {
  const { message } = props
  return (
    <>
      <span className="message">{message}</span>
    </>
  )
}
