import React, { useEffect, useState } from 'react'
import _, { get } from 'lodash'
import dayjs from 'dayjs'
import images from 'Themes/Images'
import config from 'src/global-config'

const TableBody = ({ item, handleViewDetails, listDomain }) => {
  const [userEnrollCourse, setUserEnrollCourse] = useState(null)
  const [userInfo, setUserInfo] = useState(null)
  const userId = get(item, 'user_id')
  const channelId = item.channel_id
  const partnerId = get(item, 'user.partner_id')
  const courseName = get(userEnrollCourse, 'name', '')
  const partners = get(userEnrollCourse, 'partners[0]')
  const classCode = get(partners, 'schedule.class_code')
  const startDate = get(partners, 'schedule.start_date', '')
  const endDate = get(partners, 'schedule.end_date', '')
  const schedule = `${dayjs(startDate).format('DD MMM')} - ${dayjs(
    endDate
  ).format('DD MMM YYYY')}`
  const dateEnrolled = get(partners, 'write_date')
    ? dayjs(get(partners, 'write_date')).format('DD-MM-YYYY')
    : ''
  const sales = get(item, 'create_user.partner.name')
  const schoolId = get(userEnrollCourse, 'website_id')
  const courseDomain =
    listDomain && listDomain.find((domain) => domain.id === schoolId)
  const url = `${get(
    courseDomain,
    'domain'
  )}admin/user-management/create?user=${get(item, 'user_id')}`

  useEffect(() => {
    let isCancelled = false
    const getUserPartner = async (uid, courseId) => {
      try {
        const res = await fetch(
          `${config.baseUrl}/api/lms/courses/channels/${courseId}?partner_id=${uid}`,
          { method: 'GET' }
        )

        const data = await res.json()

        setUserEnrollCourse(get(data, 'data'))
        isCancelled = true
      } catch (error) {
        console.log('err', error)
      }
    }
    if (partnerId && channelId && !isCancelled) {
      getUserPartner(partnerId, channelId)
    }

    return () => {
      isCancelled = true
    }
  }, [partnerId, channelId])

  useEffect(() => {
    let isCancelled = false
    const getUserInfo = async (userId) => {
      try {
        const request = `${config.baseUrl}/api/users/${userId}/profiles`
        const res = await fetch(request, { method: 'GET' })
        const data = await res.json()
        setUserInfo(get(data, 'data'))
        isCancelled = true
      } catch (error) {
        console.log('error', error)
      }
    }

    if (userId && !isCancelled) {
      getUserInfo(userId)
    }

    return () => {
      isCancelled = true
    }
  }, [userId])

  const handleViewUserProfile = () => {
    if (courseDomain) {
      window.open(url, '_blank')
    }
  }

  const handleViewRegistration = () => {
    if (courseDomain) {
      window.open(`${url}&course_id=${courseDomain.id}`, '_blank')
    }
  }
  return (
    <tr key={item.id} className="hs-row">
      <td className="hs-column-custom" onClick={() => handleViewDetails(item)}>
        {get(userInfo, '[0].name', '')}
      </td>
      <td
        className="hs-column-custom hs-column-text-overflow"
        onClick={() => handleViewDetails(item)}
      >
        {courseName}
      </td>
      <td className="hs-column-custom" onClick={() => handleViewDetails(item)}>
        {classCode}
      </td>
      <td className="hs-column-custom" onClick={() => handleViewDetails(item)}>
        {schedule}
      </td>
      <td className="hs-column-custom" onClick={() => handleViewDetails(item)}>
        {dateEnrolled}
      </td>
      <td className="hs-column-custom" onClick={() => handleViewDetails(item)}>
        {get(userInfo, '[0].email', '')}
      </td>
      <td className="hs-column-custom" onClick={() => handleViewDetails(item)}>
        {get(userInfo, '[0].phone', '')}
      </td>
      <td className="hs-column-custom" onClick={() => handleViewDetails(item)}>
        {sales}
      </td>
      <td
        className="hs-column-custom"
        onClick={() => handleViewUserProfile()}
        style={{ textAlign: 'center' }}
      >
        <img src={images.input.eye_icon} />
      </td>
      <td
        className="hs-column-custom"
        onClick={() => handleViewRegistration()}
        style={{ textAlign: 'center' }}
      >
        <img src={images.input.eye_icon} />
      </td>
    </tr>
  )
}

export default TableBody
