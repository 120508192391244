import React from 'react'
import { Row, Col, Radio, DatePicker } from 'antd'
import Dropdown from 'react-dropdown'
import { initialNational, initialLevel } from 'utils/constants'
import { get, isEmpty } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import Input from 'component/Input'
import config from 'src/global-config'
import { getAttachmentByField, renderColValue } from 'utils/helper'
import FileUploadComponent from 'component/FileUploadComponent'
import { hideGlobalLoading, showGlobalLoading } from 'container/Modal/actions'
import { showSuccess } from 'utils/notification'
import { getAccessToken } from 'utils/request'
import { parseSafe } from 'utils/helper'

const Flc = ({
  formik,
  editable,
  userProfile,
  userData,
  dispatch,
  userId,
  setEditable
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const name = get(userProfile, 'name', '') || get(userData, 'partner.name', '')
  const birthDay = get(userProfile, 'birthday', '')
  const educationLevel = get(userProfile, 'education_level', '')
  const gender = get(userProfile, 'gender', '')
  const nationality = get(userProfile, 'nationality', '')
  const occupation = get(userProfile, 'occupation', '')
  const passport = get(userProfile, 'passport', '')
  const phone = get(userProfile, 'phone', '')
  const attachments = get(userProfile, 'attachments', [])

  const additionData = parseSafe(get(userProfile, 'additional_info', ''))
  const company = get(additionData, 'company', '')
  const referred_name = get(additionData, 'referred_name', '')
  const referred_mobile = get(additionData, 'referred_mobile', '')
  const referred_co = get(additionData, 'referred_co', '')

  const companyEmail = get(additionData, 'company_email', '')
  const personalEmail = get(additionData, 'personal_email', '')
  const maritalStatus = get(additionData, 'marital_status', '')
  const professional_designation = get(
    additionData,
    'professional_designation',
    ''
  )
  const highest_academic_qualifications = get(
    additionData,
    'highest_academic_qualifications',
    ''
  )
  const title = get(additionData, 'title', '')
  const question_1 = get(additionData, 'question_1', '')
  const question_2 = get(additionData, 'question_2', '')
  const question_3 = get(additionData, 'question_3', '')

  const question_4 = get(additionData, 'question_4', '')
  const question_5 = get(additionData, 'question_5', '')
  const question_6 = get(additionData, 'question_6', '')
  const question_6_2 = get(additionData, 'question_6_2', '')
  const question_7 = get(additionData, 'question_7', '')
  const question_above_40 = get(additionData, 'question_above_40', '')
  const user_billing_address = get(additionData, 'user_billing_address', '')

  const handleRemoveFileServer = async (field) => {
    const body = {
      ...userProfile
    }

    if (field === 'nric_front') {
      body.nric_front = 'false'
    }

    if (field === 'nric_back') {
      body.nric_back = 'false'
    }

    delete body.attachments

    dispatch(showGlobalLoading())
    try {
      const res = await fetch(
        `${config.baseUrl}/api/users/${userId}/profiles`,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`
          },
          body: JSON.stringify(
            isEmpty(userProfile)
              ? body
              : { ...body, id: get(userProfile, 'id') }
          )
        }
      )

      const data = await res.json()
      if (data.data.id) {
        showSuccess('Update Profile Success!')
        setEditable(false)
      }
      dispatch(hideGlobalLoading())
    } catch (err) {
      dispatch(hideGlobalLoading())
      console.log('err', err)
    }
  }

  const handleChangePhoneNum = ({ currentTarget }) => {
    var regExp = /^[0-9]{1,13}$/

    if (regExp.test(currentTarget.value) || currentTarget.value === '') {
      formik.setFieldValue('phone', currentTarget.value)
    }
  }

  const handleGetFile = (img, name, field) => {
    formik.setFieldValue(field, { img, name })
  }

  const handleRemoveFile = (field) => {
    formik.setFieldValue(field, null)
  }

  return (
    <div>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          NAME (ACCORDING TO NRIC):
        </Col>
        <Col xs={renderColValue(24, 10)} md={6} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="name"
                placeHolder="Enter your full name"
                formik={formik}
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            name
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Gender:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <Radio.Group
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
              value={formik.values.gender}
              name="add-course__radio-type"
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          ) : (
            gender
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Date of Birth:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <DatePicker
                onChange={(date, dateString) => {
                  formik.setFieldValue('date_of_birth', date)
                }}
                allowClear
                value={formik.values.date_of_birth}
                placeholder="Date of Birth"
                className="sc-modal__datepicker"
                style={{ width: '100%' }}
              />
            </div>
          ) : (
            birthDay
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Nationality:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Dropdown
                options={initialNational}
                onChange={(value) =>
                  formik.setFieldValue('nationality', value.label)
                }
                value={formik.values.nationality}
                placeholder="Select Nationality"
                className="dropdownScheduleModal"
              />

              {formik.values.nationality === 'Other' && (
                <div style={{ marginTop: 20 }}>
                  <Input
                    name="other_nationality"
                    placeHolder="Type another nationality here"
                    formik={formik}
                    value={formik.values.other_nationality}
                    onChange={formik.handleChange}
                    style={{ padding: 8 }}
                  />
                </div>
              )}
            </div>
          ) : (
            nationality
          )}
        </Col>
      </Row>
      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          ID/Passport No. (eg. NRIC):
        </Col>
        <Col md={6} xs={10} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="passport"
                placeHolder="Enter ID/Passport"
                formik={formik}
                value={formik.values.passport}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            passport
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Phone No.:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="phone"
                placeHolder="Enter Phone No."
                formik={formik}
                value={formik.values.phone}
                onChange={handleChangePhoneNum}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            phone
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Billing Address:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="user_billing_address"
                placeHolder="Enter Billing Address"
                formik={formik}
                value={formik.values.user_billing_address}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            user_billing_address
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Occupation:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="occupation"
                placeHolder="Enter Occupation"
                formik={formik}
                value={formik.values.occupation}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            occupation
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Highest Education Level:
        </Col>
        <Col md={6} xs={renderColValue(24, 10)} className="profile__text">
          {editable ? (
            <div style={{ marginRight: 35 }}>
              <Dropdown
                options={initialLevel}
                onChange={(value) =>
                  formik.setFieldValue('education_level', value.label)
                }
                value={formik.values.education_level}
                placeholder="Select Education Level"
                className="dropdownScheduleModal"
              />

              {formik.values.education_level === 'Other' && (
                <div style={{ marginTop: 20 }}>
                  <Input
                    name="other_level"
                    placeHolder="Type another education level here"
                    formik={formik}
                    value={formik.values.other_level}
                    onChange={formik.handleChange}
                    style={{ padding: 8 }}
                  />
                </div>
              )}
            </div>
          ) : (
            educationLevel
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Company:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="company"
                placeHolder="Enter Company"
                formik={formik}
                value={formik.values.company}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            company
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Referrer Full Name:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="referred_name"
                placeHolder="Enter Referrer Full Name"
                formik={formik}
                value={formik.values.referred_name}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            referred_name
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Referrer Mobile:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="referred_mobile"
                placeHolder="Enter Referrer Mobile"
                formik={formik}
                value={formik.values.referred_mobile}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            referred_mobile
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Referrer Co:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="referred_co"
                placeHolder="Enter Referrer Co"
                formik={formik}
                value={formik.values.referred_co}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            referred_co
          )}
        </Col>
      </Row>

      <Row className="profile__row" justify="center">
        <Col xs={24} md={6}>
          <div
            className="profile__name"
            style={{ marginBottom: isMobile ? 10 : 0 }}
          >
            nric front:
          </div>
        </Col>
        <Col xs={24} md={6} style={{ marginBottom: isMobile ? 10 : 0 }}>
          <div className="profile__input" style={{ marginRight: 30 }}>
            <FileUploadComponent
              item={getAttachmentByField(attachments, 'nric_front')}
              field="nric_front"
              handleGetFile={handleGetFile}
              handleRemoveFile={handleRemoveFile}
              file={formik.values.nric_front}
              admin={!editable}
              handleRemoveFileServer={handleRemoveFileServer}
            />
            {formik.touched.nric_front && formik.errors.nric_front && (
              <div className="form__error">{formik.errors.nric_front}</div>
            )}
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="profile__name" style={{ marginBottom: 10 }}>
            nric back:
          </div>
        </Col>
        <Col xs={24} md={6}>
          <div className="profile__input" style={{ marginRight: 30 }}>
            <FileUploadComponent
              item={getAttachmentByField(attachments, 'nric_back')}
              handleGetFile={handleGetFile}
              handleRemoveFile={handleRemoveFile}
              file={formik.values.nric_back}
              field="nric_back"
              admin={!editable}
              handleRemoveFileServer={handleRemoveFileServer}
            />
            {formik.touched.nric_back && formik.errors.nric_back && (
              <div className="form__error">{formik.errors.nric_back}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Company Email:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="company_email"
                placeHolder="Enter company email"
                formik={formik}
                value={formik.values.company_email}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            companyEmail
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Personal Email:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="personal_email"
                placeHolder="Enter your personal email"
                formik={formik}
                value={formik.values.personal_email}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            personalEmail
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Marital Status:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="marital_status"
                placeHolder="Enter Marital Status"
                formik={formik}
                value={formik.values.marital_status}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            maritalStatus
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Highest Academic Qualifications:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="highest_academic_qualifications"
                placeHolder="Enter Your Highest Academic Qualifications"
                formik={formik}
                value={formik.values.highest_academic_qualifications}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            highest_academic_qualifications
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Professional Designation:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="professional_designation"
                placeHolder="Enter Your Professional Designation"
                formik={formik}
                value={formik.values.professional_designation}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            professional_designation
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Title:
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="title"
                placeHolder="Enter Your title"
                formik={formik}
                value={formik.values.title}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            title
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={24}
          xs={renderColValue(24, 12)}
        >
          Pre-Workshop Questionaire
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          <div style={{ width: '90%' }}>
            1. What were you doing prior to joining the financial advisory
            industry?
          </div>
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="question_1"
                placeHolder="Enter Your Answer"
                formik={formik}
                value={formik.values.question_1}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            question_1
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          2. How long have you been a Financial Adviser?
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="question_2"
                placeHolder="Enter Your Answer"
                formik={formik}
                value={formik.values.question_2}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            question_2
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          3. Have you written your Will?
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_3', e.target.value)
                }
                value={formik.values.question_3}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          ) : (
            question_3
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          4. Have you ever helped your client to draw up a will?
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_4', e.target.value)
                }
                value={formik.values.question_4}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          ) : (
            question_4
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          <div style={{ width: '90%' }}>
            5. Have you done any key-man insurance or buy-sell agreement for
            businessmen?
          </div>
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_5', e.target.value)
                }
                value={formik.values.question_5}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          ) : (
            question_5
          )}
        </Col>
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          <div style={{ width: '95%' }}>
            6. Do you charge a fee for your financial planning services? If yes,
            how much do you typically charge for each case?
          </div>
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_6', e.target.value)
                }
                value={formik.values.question_6}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          ) : (
            question_6
          )}

          {formik.values.question_6 === 'yes' && (
            <div>
              {editable ? (
                <div className="profile__field__wrapper">
                  <Input
                    name="question_6_2"
                    placeHolder="Enter Answer"
                    formik={formik}
                    value={formik.values.question_6_2}
                    onChange={formik.handleChange}
                    style={{ padding: 8 }}
                  />
                </div>
              ) : (
                <div>Answer: {question_6_2}</div>
              )}
            </div>
          )}
        </Col>
      </Row>

      <Row className="profile__row">
        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          7. What do you expect to learn from this course?
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Input
                name="question_7"
                placeHolder="Enter Answer"
                formik={formik}
                value={formik.values.question_7}
                onChange={formik.handleChange}
                style={{ padding: 8 }}
              />
            </div>
          ) : (
            question_7
          )}
        </Col>

        <Col
          className="profile__name-2 profile__col"
          md={6}
          xs={renderColValue(24, 12)}
        >
          Are you a Singapore Citizen & age 40 years old and above?
        </Col>
        <Col md={6} className="profile__text" xs={renderColValue(24, 10)}>
          {editable ? (
            <div className="profile__field__wrapper">
              <Radio.Group
                onChange={(e) =>
                  formik.setFieldValue('question_above_40', e.target.value)
                }
                value={formik.values.question_above_40}
                name="add-course__radio-type"
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          ) : (
            question_above_40
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Flc
