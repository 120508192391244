import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import images from 'Themes/Images';
import BtnPrimary from '../../../../component/BtnPrimary';
import './styles.scss';

const RegisterSuccess = () => {
  const history = useHistory();
  const goToHome = () => {
    history.push('/');
  };
  return (
    <div className="login-form" style={{ position: 'unset' }}>
      <div className="login-form__head">
        <div className="login-form__text">
          Verify your account before continuing
        </div>
      </div>

      <div className="login-form__wrap" style={{ height: 400 }}>
        <div>
          <div className="register__success__main">
            <div className="register__success__main-text">
              Please check your email inbox for the verification email and click
              on Accept Invite to complete registration.
            </div>
          </div>

          <BtnPrimary
            name="Back to Home"
            style={{
              width: '100%',
              backgroundColor: '#FF0D6B',
              fontWeight: 'bold'
            }}
            handleClick={goToHome}
            icon={images.course.arrow_right}
          />
        </div>
      </div>
      <div
        className="login-form__register"
        style={{ position: 'absolute', bottom: 0 }}
      >
        <div className="login-form__register__wrap">
          <div className="login-form__register__question">
            Already have an account?
          </div>
          <div className="login-form__register__action">
            Login below to start learning.
          </div>
          <Link to="/auth?login=true&student=true">
            <button className="quaternary-btn">LOGIN</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
