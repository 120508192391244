import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { Input } from 'antd'

import images from 'Themes/Images'
import { useClickOutside } from 'utils/hooks'
import { createLearningOutcome, addOutcomeCourse } from '../../../../actions'

const { TextArea } = Input

const EditOutcome = ({
  specificOutCome,
  dispatch,
  setSelectedRow,
  matchRow,
  isCustom,
  record
}) => {
  const [outcome, setOutcome] = useState('')
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (specificOutCome) {
      setOutcome(get(specificOutCome, 'outcome', ''))
    } else if (isCustom) {
      setOutcome(get(record, 'learning_outcome', ''))
    }
  }, [specificOutCome, isCustom])

  const handleChangeOutcome = async () => {
    if (specificOutCome) {
      dispatch(createLearningOutcome({ ...specificOutCome, outcome: outcome }))
    } else if (isCustom) {
      dispatch(addOutcomeCourse({ ...record, learning_outcome: outcome }))
    }

    setEdit(false)
    setSelectedRow(null)
  }
  const { ref } = useClickOutside(handleChangeOutcome)

  return (
    <div className="outcome-table__column" style={{ whiteSpace: 'pre-wrap' }}>
      {edit && matchRow ? (
        <div ref={ref} style={{ width: '100%' }}>
          <TextArea
            rows={3}
            className="remarks__input"
            value={outcome}
            onChange={({ currentTarget }) => setOutcome(currentTarget.value)}
          />
        </div>
      ) : (
        <div>{outcome}</div>
      )}

      {(!matchRow || !edit) && (
        <div className="outcome-table__edit">
          <img
            src={images.admin.pen_alt_active}
            alt=""
            onClick={() => setEdit(true)}
          />
        </div>
      )}
    </div>
  )
}

export default EditOutcome
