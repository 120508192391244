import React, { useEffect } from 'react'
import images from 'Themes/Images'
import BtnPrimary from 'component/BtnPrimary'
import { formatDate } from 'utils/helper'
import { get } from 'lodash'
import styled from 'styled-components'
import { loadCourseSuccess, setRef } from '../../../../actions'
import Button from 'src/customs/ClassCompare/src/components/Button'
import { showError } from 'utils/notification'
import { useMediaQuery } from 'react-responsive'
import { hideSelectScheduleModule } from 'container/Modal/actions'

const SEAT_WRAPPER_TEXT = styled.div`
  font-size: 12px;
  color: ${(props) => (props.red ? '#ff0d6b' : '#77838F')};
`

const ScheduleItem = ({
  item,
  course,
  history,
  dispatch,
  isCheckout,
  currentUser,
  classCompare,
  setIsComponentVisible = () => {}
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const maxSizeSchedule = get(item, 'max_size', 0)
  const isAvailable = get(item, 'available')

  const renderSeatsLeft = () => {
    if (!maxSizeSchedule) {
      return
    }
    if (item.seats < 5) {
      return <SEAT_WRAPPER_TEXT red>{item.seats} Seats left</SEAT_WRAPPER_TEXT>
    }

    return <SEAT_WRAPPER_TEXT>{item.seats} Seats left</SEAT_WRAPPER_TEXT>
  }

  const handleOpenEnrollForm = (item) => {
    dispatch(loadCourseSuccess(course))
    const idSchedule = get(item, 'id', '')

    if (classCompare) {
      dispatch(hideSelectScheduleModule())
    }
    if (!isAvailable) {
      return showError(
        'There are no classes available currently, please check back later.'
      )
    }

    if (currentUser) {
      return history.push(
        `/checkout/${get(course, 'id', '')}/confirm?schedule=${idSchedule}`
      )
    } else {
      return history.push(
        `/checkout/${get(course, 'id', '')}/auth?schedule=${idSchedule}`
      )
    }
  }

  const renderDateBetween = () => {
    if (item && item.isAlwaysAvailable) {
      return 'Always Available'
    }

    return formatDate(item.start_date, item.end_date)
  }
  return (
    <div
      className="course-preview__item"
      key={item.id}
      onClick={() => setIsComponentVisible(true)}
      style={{ cursor: 'pointer' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          className="course-preview__item__highlight"
          style={{ marginBottom: 18 }}
        >
          {renderDateBetween()} / CODE: {item.class_code}
        </div>
        <span>{renderSeatsLeft()}</span>
      </div>
      <div className="course-preview__summary">
        <div className="course-preview__summary__left" style={{ flex: 0.8 }}>
          <div className="course-preview__summary__top">
            <div className="course-preview__summary__top__left">
              <div className="course-preview__summary__top__icon">
                <img src={images.admin.clock_sm} alt="" />
              </div>
              <div className="course-preview__summary__text">
                {item.start_time} - {item.end_time}
              </div>
            </div>
            <div className="course-preview__summary__top__right">
              <div className="course-preview__summary__top__icon">
                <img src={images.admin.calender_sm} alt="" />
              </div>
              <div className="course-preview__summary__text">
                {item.schedule_type}
              </div>
            </div>
          </div>

          <div className="course-preview__summary__bottom">
            <div>
              <img src={images.admin.location_arrow_sm} alt="" />
            </div>
            <div className="course-preview__summary__text">
              {item.venue === 'online' ? 'online' : 'on- campus'}
              <span>{item.venue !== 'online' ? item.venue : ''}</span>
            </div>
          </div>
        </div>
        <div className="course-preview__summary__right">
          {isCheckout ? (
            <img src={images.header.caret_down_black} alt="" />
          ) : !classCompare ? (
            <BtnPrimary
              name="Enrol"
              icon={
                isAvailable
                  ? images.course.arrow_right_active
                  : images.admin.x_inactive
              }
              style={{
                backgroundColor: isAvailable ? '#F6F6F9' : '#f7f8fa',
                fontWeight: 'bold',
                color: isAvailable ? '#27284D' : '#a4a5b4',
                padding: '8px 10px',
                cursor: isAvailable ? 'pointer' : 'not-allowed'
              }}
              handleClick={() => handleOpenEnrollForm(item)}
              styleIcon={{ marginBottom: 2 }}
            />
          ) : (
            <Button
              title="Enrol"
              color="#fff"
              bgColor="#FF0D6B"
              minWidth="112"
              height={isMobile ? 35 : 50}
              fullWidth={isMobile}
              handleClick={() => handleOpenEnrollForm(item)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ScheduleItem
