import React, { useEffect, useMemo } from 'react'
import { get, isEmpty } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import Lessons from 'components/Lessons'
import HeaderCourseDetails from './HeaderCourseDetails'
import * as selector from 'container/Home/selectors'
import * as actions from '../../actions'
import { isEnrollCourse } from 'utils/helper'
import { showError } from 'utils/notification'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { makeSelectUserInfo } from 'container/Home/selectors'
import { setSelectedSchedule } from 'container/Checkout/actions'
import './styles.scss'
import {
  makeSelectDomain,
  makeSelectPreventLoadRelatedApi,
  makeSelectSlidesOriginal,
} from '../../selectors'
import { DOMAIN } from 'src/routes'
import { mapSlidesToSideBarBlock } from 'utils/mapper'
import dayjs from 'dayjs'
import { isTrainerRole, isAdminRole } from 'utils/userUtils'

const CourseDetails = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { preview } = parse(search)
  const { id } = useParams()
  const domain = useSelector(makeSelectDomain())
  const isSingaporeSkillsAcademies =
    domain && domain.domain === DOMAIN.SINGAPORESKILLS
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  const isLmaDomain = domain && domain.domain === DOMAIN.LMA

  const course = useSelector(selector.makeSelectCourse())
  const isBlockedLessons = get(course, 'locked_lessons', false)
  const slides = useSelector(selector.makeSelectSlides())
  const currentUser = useSelector(makeSelectCurrentUser())
  const userInfo = useSelector(makeSelectUserInfo())
  const previews = useSelector(selector.makeSelectPreviewBySlideId())
  const selectedSlide = useSelector(selector.makeSelectSelectedSlide())
  const userLog = useSelector(selector.makeSelectUserLog())
  const userLogCurrentState = useSelector(
    selector.makeSelectUserLogCurrentState()
  )
  const preventLoadRelatedApi = useSelector(makeSelectPreventLoadRelatedApi())

  const userGroups = get(currentUser, 'user_groups', [])
  const isTrainer = isTrainerRole(userGroups)
  const isAdmin = isAdminRole(userGroups)
  const isAdminOrTrainer = isAdmin || isTrainer

  const partners = get(course, 'partners[0]', null)
  const schedule_id = get(partners, 'schedule.id', 0)

  const userLogByCourse = useMemo(
    () =>
      Array.isArray(userLog) &&
      userLog.find(
        (log) =>
          log.channel_id === parseInt(id) &&
          log.schedule_id === parseInt(schedule_id)
      ),
    [partners, userLog]
  )

  const slidesOriginal = useSelector(makeSelectSlidesOriginal())
  const slidesBlock = useMemo(
    () => mapSlidesToSideBarBlock(slidesOriginal, isBlockedLessons, previews),
    [isBlockedLessons, previews, slides]
  )

  useEffect(() => {
    if (id && currentUser) {
      dispatch(actions.getUserInfo())
      dispatch(actions.loadCourse(id))
      dispatch(actions.loadSlideDetailsSuccess(null))
      dispatch(actions.putUserLogSuccess(null))
      dispatch(setSelectedSchedule(null))
    }
  }, [id, currentUser])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }, 1000)
  }, [])

  useEffect(() => {
    if (!preventLoadRelatedApi && (partners || isAdminOrTrainer)) {
      dispatch(actions.loadSlides(id, false, 0, schedule_id, preview))
      dispatch(actions.getUserLog({ channel_id: id, schedule_id }))
    }
  }, [partners])

  useEffect(() => {
    if (currentUser && course) {
      dispatch(actions.loadPreviewByChannelId(id))
    }
  }, [currentUser, course])

  useEffect(() => {
    if (Array.isArray(previews) && previews?.length >= 0 && selectedSlide) {
      putStartDate()
    }
  }, [previews, selectedSlide])

  const putStartDate = () => {
    const matchLesson = previews.find(
      (item) => item.slide_id === selectedSlide.id
    )

    if (matchLesson) {
      if (!matchLesson.completed && !matchLesson.start_date) {
        dispatch(actions.setPreviewComplete({ start_date: dayjs() }))
      }
    } else {
      dispatch(actions.setPreviewComplete({ start_date: dayjs() }))
    }
  }

  useEffect(() => {
    if (!preventLoadRelatedApi && selectedSlide) {
      dispatch(actions.loadSlideDetails({ id: get(selectedSlide, 'id') }))
    }
  }, [selectedSlide])

  useEffect(() => {
    if (!preventLoadRelatedApi && selectedSlide && course && userLog) {
      dispatch(
        actions.putUserLog(
          !isEmpty(userLogByCourse)
            ? {
                ...userLogByCourse,
                channel_id: id,
                schedule_id,
                access_count: userLogCurrentState
                  ? parseInt(get(userLogCurrentState, 'access_count', '')) + 1
                  : parseInt(userLogByCourse?.access_count) + 1,
              }
            : {
                id: get(userLogCurrentState, 'id', ''),
                channel_id: id,
                schedule_id,
                access_count: userLogCurrentState
                  ? parseInt(get(userLogCurrentState, 'access_count', '')) + 1
                  : 1,
                course_start: userLogCurrentState
                  ? get(userLogCurrentState, 'course_start', '')
                  : dayjs(),
                course_duration: userLogCurrentState?.course_duration
                  ? get(userLogCurrentState, 'course_duration', '')
                  : get(userLogByCourse, 'course_duration', ''),
              }
        )
      )
    }
  }, [selectedSlide, course, userLog])

  useEffect(() => {
    const putUserDuration = async () => {
      const courseDuration = userLogCurrentState?.course_duration
        ? get(userLogCurrentState, 'course_duration')
        : get(userLogByCourse, 'course_duration')
      if (courseDuration) {
        const nextTime = dayjs(courseDuration).add(5, 'm')
        dispatch(
          actions.putUserLog({
            ...userLogCurrentState,
            course_duration: nextTime,
          })
        )
      } else {
        dispatch(
          actions.putUserLog({
            ...userLogCurrentState,
            course_duration: dayjs(),
          })
        )
      }
    }

    const time = 180000
    const interval = setInterval(() => {
      putUserDuration()
    }, time)

    return () => clearInterval(interval)
  }, [userLogByCourse, userLogCurrentState])

  useEffect(() => {
    if ((!isAdminOrTrainer && course && userInfo) || !currentUser) {
      preventUserAccessCourse()
    }
  }, [
    course,
    userInfo,
    isAdminOrTrainer,
    currentUser,
    isSingaporeSkillsAcademies,
    wfaDomain,
  ])

  const preventUserAccessCourse = () => {
    const partners = get(course, 'partners', [])
    const isEnroll = isEnrollCourse(partners, course)
    if (currentUser === null) {
      showError('You are not allowed to access this page!')
      return history.push('/auth?login=true&student=true')
    }

    if (
      isEnroll &&
      userInfo &&
      isEmpty(userInfo) &&
      !isSingaporeSkillsAcademies &&
      !wfaDomain &&
      !isLmaDomain
    ) {
      showError('Please complete your profile before continuing')
      return history.push(`/checkout/${get(course, 'id', '')}/complete-profile`)
    }

    if (!isEnroll) {
      showError('You are not enrolled to this course!')
      return history.push('/')
    }
  }

  return (
    <>
      <HeaderCourseDetails course={course} />
      <Lessons
        slides={slidesBlock}
        selectedSlide={selectedSlide}
        previews={previews}
        course={course}
        dispatch={dispatch}
        userLogByCourse={userLogByCourse}
        userLogCurrentState={userLogCurrentState}
        id={id}
        schedule_id={schedule_id}
        preview={preview}
      />
    </>
  )
}

export default CourseDetails
