import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Modal, Select } from 'antd'
import Dropdown from 'react-dropdown'
import { get, isEmpty } from 'lodash'
import { CSVLink } from 'react-csv'

import BtnPrimary from 'component/BtnPrimary'
import {
  hideBellsCourseReport,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../actions'
import { useSelector } from 'react-redux'
import {
  mapListCourseToDropDown,
  mapScheduleToDropDown,
  encodedSlideByQuiz,
} from 'utils/helper'
import config from 'src/global-config'
import { getAccessToken } from 'utils/request'
import { showError } from 'utils/notification'
import { getSchedules } from 'container/Admin/actions'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { loadSlides } from 'container/Home/actions'
import { makeSelectSlides } from 'container/Home/selectors'
import { getUserInfo } from 'utils/request'
import dayjs from 'dayjs'
import useLoadCourses from '../../../../hooks/useLoadCourses'

const BellsCourseReport = ({
  dispatch,
  courseSelected,
  showBellsCourseReport,
}) => {
  const [csvData, setCsvData] = useState([])
  const exportRef = useRef(null)
  const slides = useSelector(makeSelectSlides())

  const slidesRemoveSection = useMemo(() => {
    const arr = []
    Array.isArray(slides) &&
      slides.forEach((slide) => {
        arr.push(...slide.items)
      })
    return arr
  }, [slides])

  const slidesQuizz = useMemo(() => {
    const arr = []
    Array.isArray(slidesRemoveSection) &&
      slidesRemoveSection.forEach((slide) => {
        if (slide?.questions && slide?.questions?.length) arr.push(slide)
      })
    return arr
  }, [slidesRemoveSection])

  const courses = useLoadCourses()

  const schedules = useSelector(makeSelectSchedules())
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [isExportAll, setIsExportAll] = useState(false)
  const scheduleData = useMemo(
    () => schedules?.find((item) => item?.id === selectedSchedule?.value),
    [selectedSchedule, schedules]
  )

  const course = useMemo(() => {
    if (selectedCourse?.value && courses) {
      return courses.find((course) => course.id === selectedCourse.value)
    }
  }, [selectedCourse?.value, courses])

  const requireSchedule = get(course, 'is_schedule', false)
  const courseName = get(selectedCourse, 'label', '')
  const channelID = get(selectedCourse, 'value', '')
  const scheduleLabel = get(selectedSchedule, 'label', '')
  const scheduleID = get(selectedSchedule, 'value', 0)

  useEffect(() => {
    if (courseSelected) {
      setSelectedCourse({
        value: courseSelected.id,
        label: courseSelected.name,
      })
    }
  }, [courseSelected])

  useEffect(() => {
    if (channelID) {
      dispatch(loadSlides(channelID, false, 0))
      dispatch(getSchedules(channelID, true, null, false, true))
    }
  }, [channelID])

  const handleCancel = () => {
    dispatch(hideBellsCourseReport())
  }

  const handleExport = async (isExportAll = false) => {
    setIsExportAll(isExportAll)
    if (selectedCourse) {
      let requestURL = `${config.baseUrl}/api/lms/courses/channels/${
        selectedCourse.value
      }/partners?schedule_id=${
        !isExportAll ? get(selectedSchedule, 'value', 0) : 0
      }&per_page=10000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()
        const dataCSV = []

        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        if (Array.isArray(data.data) && data.data.length > 0) {
          const requests = []
          const requestsUserLogs = []
          const requestsUserPreviews = []
          const requestsUserQuizPassRate = []
          for (let i = 0; i < data.data.length; i++) {
            const partner_id = get(data.data, `[${i}].partner_id`)
            const userId = get(data.data, `[${i}].user.id`)
            const scheduleId = get(data.data, `[${i}].schedule.id`)
            requests.push(getUserInfo(userId, selectedCourse?.website_id))
            requestsUserLogs.push(getUserCourseLog(userId, scheduleId))
            requestsUserPreviews.push(getUserPreviews(partner_id))
            requestsUserQuizPassRate.push(fetchAllAnswerByUser(userId))
          }

          const usersInfo = await Promise.all(requests)
          const userQuizPassRate = await Promise.all(requestsUserQuizPassRate)
          const userLogs = await Promise.all(requestsUserLogs)
          const userPreviews = await Promise.all(requestsUserPreviews)

          for (let i = 0; i < data.data.length; i++) {
            let content = null
            const name =
              get(usersInfo, `[${i}].name`, '') ||
              get(data, `data[${i}].user.partner.name`, '')
            // const cohortID = get(data.data, `[${i}].user.cohort_signup_id`, '')
            const passport = get(usersInfo, `[${i}].passport`, '')

            const validCompetent =
              userQuizPassRate[i] &&
              userQuizPassRate[i].filter((item) => !item.competent)

            const validRemarks =
              userQuizPassRate[i] &&
              userQuizPassRate[i].filter((item) => item.remark)

            const completionDate = get(
              userLogs,
              `[${i}].course_completion_date`,
              ''
            )
            const schedule = data?.data?.[i]?.schedule
            const codeSchedule = schedule?.class_code || ''

            const assessmentsValid =
              Array.isArray(slidesQuizz) &&
              slidesQuizz?.map((item, index) => {
                const validQuiz = get(userPreviews, `[${i}]`)?.find(
                  (item2) => item2?.slide_id === item?.id
                )

                return validQuiz && validQuiz?.completed ? 'Y' : 'N'
              })
            const isValid =
              validRemarks?.length >= 3 && userQuizPassRate[i]?.length
            const checkRemarks =
              validRemarks?.length &&
              validRemarks?.every((i) => i?.remark?.trim()?.length >= 3)

            content = [
              completionDate
                ? dayjs(completionDate).format('DD/MM/YYYY HH:mm:ss')
                : '',
              courseName,
              codeSchedule,
              // cohortID,
              name,
              passport,
              // Here
              ...assessmentsValid,
              // userQuizPassRate[i]?.length > 0 && validCompetent?.length === 0
              //   ? 'Y'
              //   : 'N',
              isValid ? 'Y' : 'N',
              isValid ? 'Y' : 'N',
              isValid ? 'Y' : 'N',
              isValid ? 'Y' : 'N',
              checkRemarks ? 'Y' : 'N',
              '',
            ]

            dataCSV.push(content)
          }

          const assessments =
            Array.isArray(slidesQuizz) &&
            slidesQuizz?.map((item, index) => `Asessment ${++index}`)

          const assessmentsBlankSpace =
            Array.isArray(slidesQuizz) && slidesQuizz?.map((item) => '')

          console.log('assessments', assessments)
          let label = [
            'Completion Date',
            'Module',
            'Class Code',
            // 'Cohort Signup ID',
            'Full Name',
            'NRIC',
            // Here to show Assessment 1,2,3 etc
            ...assessments,
            'Trainer Marked',
            'PP (Submission)',
            'Trainer Marked',
            'C/NYC',
            'Remarks input',
            'Evaluation',
          ]

          dataCSV.unshift(label)
          dataCSV.unshift([
            'Class Date: ',
            scheduleLabel?.replace(/ *\([^)]*\) */g, ''),
            ...assessmentsBlankSpace,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'Min 3 Remarks Input',
            '',
          ])
          dataCSV.unshift([
            'Course Name: ',
            courseName,
            ...assessmentsBlankSpace,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ])
          dataCSV.push([])
          dataCSV.push(['Total Count: ', data?.data?.length])
          await setCsvData(dataCSV)
          exportRef.current.link.click()
        }
        setIsExportAll(false)
        handleCancel()
        dispatch(hideGlobalLoading())
      } catch (error) {
        setIsExportAll(false)
        handleCancel()
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const getUserCourseLog = async (userId, scheduleId) => {
    try {
      const request = `${
        config.baseUrl
      }/api/users/${userId}/reports/channels?channel_id=${channelID}&schedule_id=${
        scheduleId ? scheduleId : scheduleID
      }&order_by=id%20asc`

      const res = await fetch(request, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })

      const data = await res.json()
      return get(data, 'data[0]', {})
    } catch (error) {
      console.log('error', error)
    }
  }

  const getUserPreviews = async (userId) => {
    try {
      const request = `${config.baseUrl}/api/lms/courses/slides/partners?channel_id=${channelID}&partner_id=${userId}&per_page=1000&ignore_per_page=true`

      const res = await fetch(request, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })

      const data = await res.json()
      return get(data, 'data', [])
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchAllAnswerByUser = async (userId) => {
    const encodedSlideId = encodedSlideByQuiz(slidesRemoveSection)
    try {
      const res = await fetch(
        `${config.baseUrl}/api/users/${userId}/slides/answers?schedule_id=${scheduleID}${encodedSlideId}&order_by=id%20asc&per_page=1000&ignore_per_page=true`,
        {
          method: 'GET',
        }
      )
      const data = await res.json()
      return data.data
    } catch (error) {
      console.log('err', error)
    }
  }

  return (
    <Modal
      visible={showBellsCourseReport}
      footer={null}
      onCancel={handleCancel}
    >
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Assessment Database
          <BtnPrimary
            name="Export All"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginLeft: 20,
            }}
            handleClick={() => handleExport(true)}
          />
        </div>
        <div className="sc-modal__label">Courses</div>
        <Select
          className="selectSchedule"
          showSearch
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={(_, course) => {
            setSelectedCourse(course)
            setSelectedSchedule(null)
          }}
          value={selectedCourse ? selectedCourse : null}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={mapListCourseToDropDown(courses)}
        />

        {requireSchedule && schedules && (
          <>
            <div className="sc-modal__label" style={{ marginTop: 20 }}>
              Schedules
            </div>
            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={(_, data) => {
                setSelectedSchedule(data)
              }}
              value={!isEmpty(selectedSchedule) ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, false, true)}
            />
          </>
        )}

        {(selectedCourse && selectedSchedule) || !requireSchedule ? (
          <BtnPrimary
            name="Export"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginTop: 20,
            }}
            handleClick={() => handleExport(false)}
          />
        ) : null}

        <CSVLink
          filename={`${course?.name}_Assessment Database${
            requireSchedule
              ? ` (${
                  isExportAll ? 'All' : `${scheduleData?.class_code || ''}`
                })`
              : ''
          }.csv`}
          data={csvData}
          ref={(r) => (exportRef.current = r)}
        />
      </div>
    </Modal>
  )
}

export default BellsCourseReport
