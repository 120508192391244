import React from 'react'
import images from 'Themes/Images'
import { CourseCurriculumItemWrap } from './styles'
import { SLIDE_TYPE } from 'utils/constants'

const CourseCurriculumItem = ({ lesson }) => {
  const renderSideBarIcon = () => {
    let sideBarIcon = null
    switch (lesson.slide_type) {
      case SLIDE_TYPE.INFOGRAPHIC:
        sideBarIcon = images.header.image
        break
      case SLIDE_TYPE.VIDEO:
        sideBarIcon = images.header.play
        break
      case SLIDE_TYPE.PRESENTATION:
        sideBarIcon = images.header.file_pdf
        break
      default:
        break
    }
    return <img src={sideBarIcon} alt="" width={40} />
  }

  return (
    <CourseCurriculumItemWrap>
      <div className="curriculum__item-left">
        <div>{renderSideBarIcon()}</div>
        <div>{lesson.name}</div>
      </div>
      <div className="curriculum__item-right">
        {/* {lesson.questions.length !== 0 && (
          <div className="curriculum__item-question">
            <div>{lesson.questions.length} question</div>
          </div>
        )}

        <div className="curriculum__item-total-hours">
          <div>30 min</div>
        </div>
        <div>
          <img src={images.play_small} />
        </div> */}
      </div>
    </CourseCurriculumItemWrap>
  )
}

export default CourseCurriculumItem
