import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { cloneDeep, get } from 'lodash'
import images from 'Themes/Images'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useClickOutside } from 'utils/hooks'

import SideBarList from '../SideBarList'
import config from 'src/global-config'
import { createSection, setQuestions } from '../../../../actions'
import { Checkbox } from 'antd'
import { loadSlides } from 'container/Home/actions'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { loadSlidesSuccess, setSelectedSlide } from 'container/Home/actions'
import { getAccessToken } from 'utils/request'
import './styles.scss'

const SideBar = ({
  slides,
  selectedSlide,
  resetForm,
  setAddSection,
  currentRef,
  isActive,
  setIsActive,
  setListSchedules,
  formik,
  setListImages,
  saveBtnRef,
}) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const [textBox, setTextBox] = useState(false)
  const [text, setText] = useState('')
  const { id } = useParams()
  const handleAddSection = () => {
    setTextBox(true)
  }

  const handleClickSlide = (item) => {
    if (item.name === 'New Lesson') {
      return null
    }

    setListImages([])
    dispatch(setSelectedSlide(item))
  }

  const handleToggle = (slide) => {
    const cloneSlides = cloneDeep(slides)
    const data = cloneSlides.map((item) => ({
      ...item,
      is_toggle: item.id === slide.id ? !item.is_toggle : item.is_toggle,
    }))
    dispatch(loadSlidesSuccess(data))
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && text) {
      handleCreateSection()
    }
  }

  const handleCreateSection = () => {
    let payload = getPayload()
    dispatch(createSection(payload, id))
    setTextBox(false)
    setText('')
  }

  const getPayload = () => {
    return {
      name: text,
      is_category: true,
    }
  }

  const handleClickQuiz = (item) => {
    if (selectedSlide && item && item.id !== selectedSlide.id) {
      dispatch(setQuestions([]))
    }
    dispatch(setSelectedSlide(item))
    history.push(`${pathname}?route=lessons&quiz=true`)
  }

  const handleToggleAll = ({ target }) => {
    const cloneSlides = cloneDeep(slides)
    const data = cloneSlides.map((item) => ({
      ...item,
      is_toggle: target.checked,
    }))
    dispatch(loadSlidesSuccess(data))
  }

  const updateSlide = async (slide, sequence = null, sub_sequence = null) => {
    const body = { ...slide, sequence, sub_sequence }
    if (sequence === null) {
      body.sequence = slide.sequence
    }
    if (sub_sequence === null) {
      body.sub_sequence = slide.sub_sequence
    }
    try {
      let requestURL = `${config.baseUrl}/api/lms/courses/channels/${id}/slides`

      const res = await fetch(requestURL, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      })
      const data = await res.json()
      return data.data
    } catch (error) {
      console.log('error', error)
    }
  }

  const reorderSection = async (
    source,
    des,
    sequenceSource,
    sequenceDes,
    sourceIndex,
    desIndex
  ) => {
    const subtractionIndex = sourceIndex - desIndex
    const requests = []
    dispatch(showGlobalLoading())
    if (Math.abs(subtractionIndex) === 1) {
      if (source) {
        requests.push(updateSlide(source, sequenceDes))
        if (Array.isArray(source.items) && source.items.length > 0) {
          for (let i = 0; i < source.items.length; i++) {
            if (source.items[i]) {
              requests.push(updateSlide(source.items[i], sequenceDes))
            }
          }
        }
      }

      if (des) {
        requests.push(updateSlide(des, sequenceSource))
        if (Array.isArray(des.items) && des.items.length > 0) {
          for (let i = 0; i < des.items.length; i++) {
            if (des.items[i]) {
              requests.push(updateSlide(des.items[i], sequenceSource))
            }
          }
        }
      }
    } else if (subtractionIndex < -1) {
      if (source) {
        requests.push(updateSlide(source, sequenceDes))
        if (Array.isArray(source.items) && source.items.length > 0) {
          for (let i = 0; i < source.items.length; i++) {
            if (source.items[i]) {
              requests.push(updateSlide(source.items[i], sequenceDes))
            }
          }
        }
      }

      for (let i = sequenceDes; i > sequenceSource; i--) {
        const section = slides[i]
        if (section) {
          requests.push(updateSlide(section, i - 1))
          if (Array.isArray(section.items) && section.items.length > 0) {
            for (let j = 0; j < section.items.length; j++) {
              if (section.items[j]) {
                requests.push(updateSlide(section.items[j], i - 1))
              }
            }
          }
        }
      }
    } else if (subtractionIndex > 1) {
      if (source) {
        requests.push(updateSlide(source, sequenceDes))
        if (Array.isArray(source.items) && source.items.length > 0) {
          for (let i = 0; i < source.items.length; i++) {
            if (source.items[i]) {
              requests.push(updateSlide(source.items[i], sequenceDes))
            }
          }
        }
      }

      for (let i = sequenceDes; i < sequenceSource; i++) {
        const section = slides[i]
        if (section) {
          requests.push(updateSlide(section, i + 1))
          if (Array.isArray(section.items) && section.items.length > 0) {
            for (let j = 0; j < section.items.length; j++) {
              if (section.items[j]) {
                requests.push(updateSlide(section.items[j], i + 1))
              }
            }
          }
        }
      }
    }

    await Promise.all(requests)

    dispatch(loadSlides(id, true))
    dispatch(hideGlobalLoading())
  }

  const reorderLesson = async (
    source,
    des,
    sequenceSource,
    sequenceDes,
    indexSource,
    indexDes
  ) => {
    dispatch(showGlobalLoading())
    const subtractionIndex = indexSource - indexDes
    let requests = []

    if (sequenceSource !== sequenceDes) {
      requests.push(updateSlide(source, sequenceDes, indexDes))

      for (let i = indexDes; i < slides[sequenceDes].items.length; i++) {
        if (slides[sequenceDes].items[i]) {
          requests.push(updateSlide(slides[sequenceDes].items[i], null, i + 1))
        }
      }

      for (
        let i = indexSource + 1;
        i < slides[sequenceSource].items.length;
        i++
      ) {
        if (slides[sequenceSource].items[i]) {
          requests.push(
            updateSlide(slides[sequenceSource].items[i], null, i - 1)
          )
        }
      }
    } else {
      if (Math.abs(subtractionIndex) === 1) {
        requests.push(updateSlide(source, null, indexDes))
        requests.push(updateSlide(des, null, indexSource))
      } else if (subtractionIndex < -1) {
        requests.push(updateSlide(source, null, indexDes))
        for (let i = indexDes; i > indexSource; i--) {
          if (slides[sequenceSource].items[i]) {
            requests.push(
              updateSlide(slides[sequenceSource].items[i], null, i - 1)
            )
          }
        }
      } else if (subtractionIndex > 1) {
        requests.push(updateSlide(source, null, indexDes))
        for (let i = indexDes; i < indexSource; i++) {
          if (slides[sequenceSource].items[i]) {
            requests.push(
              updateSlide(slides[sequenceSource].items[i], null, i + 1)
            )
          }
        }
      }
    }
    await Promise.all(requests)
    const idSlide = get(source, 'id', '')
    dispatch(loadSlides(id, true, idSlide))
    dispatch(hideGlobalLoading())
  }

  const handleDragEnd = (result) => {
    if (!result.destination || !result.source) {
      return
    }

    if (
      result.type === 'droppableSection' &&
      result.destination.index !== result.source.index
    ) {
      const sourceIndex = result.source.index
      const desIndex = result.destination.index

      const source = slides[sourceIndex]
      const des = slides[desIndex]
      const sequenceSource = get(source, 'sequence')
      const sequenceDes = get(des, 'sequence')
      reorderSection(
        source,
        des,
        sequenceSource,
        sequenceDes,
        sourceIndex,
        desIndex
      )
    }
    if (result.type === 'droppableLesson') {
      const idSource = result.source.droppableId
      const idDes = result.destination.droppableId
      const indexSource = result.source.index
      const indexDes = result.destination.index

      let sectionSource = slides.find((item) => item.id === +idSource)
      let sectionDes = slides.find((item) => item.id === +idDes)

      const lessonSource = sectionSource.items[indexSource]
      const lessonDes = sectionDes.items[indexDes]

      const sequenceSource = get(lessonSource, 'sequence')
      const sequenceDes = get(lessonDes, 'sequence')
        ? get(lessonDes, 'sequence')
        : get(sectionDes, 'sequence')

      reorderLesson(
        lessonSource,
        lessonDes,
        sequenceSource,
        sequenceDes,
        indexSource,
        indexDes
      )
    }
  }

  const autoUpdateSubSequence = async () => {
    const requests = []
    slides.forEach((section) => {
      if (Array.isArray(section.items) && section.items.length > 0) {
        for (let i = 0; i < section.items.length; i++) {
          if (section.items[i]) {
            requests.push(updateSlide(section.items[i], null, i))
          }
        }
      }
    })
    await Promise.all(requests)
    dispatch(loadSlides(id, true))
  }

  const { ref } = useClickOutside(handleCreateSection)
  return (
    <div className="lessons-sidebar">
      <div className="lessons-sidebar__heading">
        <div>OVERVIEW</div>
        <div>
          <span style={{ marginRight: 8 }}>Collapse</span>
          <Checkbox onChange={handleToggleAll} />
        </div>
      </div>
      {/* <div onClick={autoUpdateSubSequence}>Auto-update-subsequence</div> */}

      {slides && slides.length === 0 ? (
        textBox ? (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <span style={{ marginRight: 3 }}>
              <img src={images.admin.menu_active_icon} alt="" />
            </span>
            <input
              ref={ref}
              type="text"
              className="input__section__add"
              placeholder="Name this section"
              onChange={(e) => setText(e.currentTarget.value)}
              value={text}
              onKeyPress={handleKeyPress}
              autoFocus
            />
          </div>
        ) : (
          <li className="sidebar__add">
            <div className="sidebar__cta">
              <div
                className="sidebar__cta__text-custom"
                style={{
                  visibility: 'visible',
                  opacity: '1',
                  marginTop: 10,
                  textAlign: 'center',
                  position: 'relative',
                }}
                onClick={() => handleAddSection(true)}
              >
                &nbsp;
              </div>
            </div>
          </li>
        )
      ) : null}
      <div className="sidebar__menu__wrapper">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" type="droppableSection">
            {(provided) => (
              <div ref={provided.innerRef}>
                {slides &&
                  slides.map((slide, index) => {
                    const lastIndex = index === slides.length - 1
                    return (
                      <Draggable
                        key={slide.id}
                        draggableId={slide.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SideBarList
                              setListSchedules={setListSchedules}
                              currentRef={currentRef}
                              isActive={isActive}
                              setIsActive={setIsActive}
                              slides={slides}
                              key={slide.id}
                              slide={slide}
                              formik={formik}
                              selectedSlide={selectedSlide}
                              handleToggle={handleToggle}
                              handleClickSlide={handleClickSlide}
                              resetForm={resetForm}
                              setAddSection={setAddSection}
                              history={history}
                              pathname={pathname}
                              handleClickQuiz={handleClickQuiz}
                              lastIndex={lastIndex}
                              saveBtnRef={saveBtnRef}
                            />
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}

export default SideBar
