import React, { useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import Lessons from './components/Lessons'
import AddCourse from './components/AddCourse'
import Schedules from './components/Schedules'
import './styles.scss'

const ChannelDetails = ({ filter, setFilter, course, saveRef, saveGo }) => {
  const { id } = useParams()
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    if (id === 'new' && pathname) {
      history.push(pathname + '?route=general')
    }
  }, [id, pathname])

  const saveFunction = (formik) => {
    saveRef.current = formik
  }

  const renderMainContentByFilter = () => {
    let content = null

    switch (filter) {
      case 'general':
        content = (
          <AddCourse
            setFilter={setFilter}
            saveFunction={saveFunction}
            saveGo={saveGo}
            saveRef={saveRef}
          />
        )
        break
      case 'lessons':
        content = <Lessons course={course} />
        break
      case 'schedules':
        content = <Schedules filter={filter} />
        break
    }
    return content
  }

  return <div className="admin_channel">{renderMainContentByFilter()}</div>
}

export default ChannelDetails
