import React from 'react'
import styled from 'styled-components'

const InputWrap = styled.input`
  width: 100%;
  background: #ffffff;
  border: 1px solid #b8c0d2;
  border-radius: 4px;
  height: ${(props) => `${props.height}px`};
  padding: 0 10px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  outline: none;
`

const InputCMS = ({ height, handleChange, name, value }) => {
  return (
    <InputWrap
      height={height}
      onChange={handleChange}
      name={name}
      value={value}
    />
  )
}

export default InputCMS
