import React from 'react'
import { Modal } from 'antd'

import images from 'Themes/Images'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'
import { hideMarkingOutComeModal } from '../../actions'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { makeSelectCurrentUser } from '../../../Auth/selectos'
import { getUserByCourseAndSchedule } from '../../../Admin/actions'
import './styles.scss'
import { getAccessToken } from 'utils/request'
import axiosInstance from 'src/utils/axiosInstance'

const MarkingOutComeModal = ({
  dispatch,
  showMarkingOutComeModal,
  dataMarkingOutcome = {},
}) => {
  const {
    mergeListLearningOutcomeUser,
    currentUser,
    listLearningOutCome,
    channel_id,
    userStatus,
    selectedSchedule,
    signature,
  } = dataMarkingOutcome

  const adminUser = useSelector(makeSelectCurrentUser())
  const handleCancel = () => {
    dispatch(hideMarkingOutComeModal())
  }

  let wrongAnswer = ''
  let correctAnswer = ''
  let isCompetent = false
  if (
    Array.isArray(mergeListLearningOutcomeUser) &&
    mergeListLearningOutcomeUser.length > 0
  ) {
    wrongAnswer = mergeListLearningOutcomeUser.reduce(
      (acc, curr) => acc + (curr.competent === false ? 1 : 0),
      0
    )
    correctAnswer = mergeListLearningOutcomeUser.reduce(
      (acc, curr) => acc + (curr.competent === true ? 1 : 0),
      0
    )
    if (wrongAnswer === 0) {
      isCompetent = true
    }
  }

  const handleOk = async () => {
    try {
      const requestUrl = `${config.baseUrl}/api/learning-outcome/channels/${channel_id}/users`
      const body = {
        competent: isCompetent,
        user_id: get(currentUser, 'create_uid', ''),
        create_uid: get(adminUser, 'uid', ''),
        signature_student_name: signature?.student ? signature?.student : '',
        signature_trainer_name: signature?.trainer ? signature?.trainer : '',
        signature_student_date: signature?.signStudentDate
          ? signature?.signStudentDate
          : null,
        signature_trainer_date: signature?.signTrainerDate
          ? signature?.signStudentDate
          : null,
      }

      await fetch(requestUrl, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(
          userStatus ? { ...body, id: get(userStatus, 'id', '') } : body
        ),
      })

      dispatch(
        getUserByCourseAndSchedule({
          id: channel_id,
          schedule: selectedSchedule,
        })
      )
    } catch (error) {
      console.log('error', error)
    }

    const requests = []
    for (let i = 0; i < mergeListLearningOutcomeUser?.length; i++) {
      requests.push(handleSetRemarksOutcome(mergeListLearningOutcomeUser[i]))
    }

    await Promise.all(requests)

    dispatch(hideMarkingOutComeModal())
  }

  const handleSetRemarksOutcome = async (item) => {
    try {
      const requestURL = `${config.baseUrl}/api/learning-outcome/custom-channels/users`
      await axiosInstance.put(requestURL, {
        ...item,
        create_uid: get(adminUser, 'uid', ''),
      })
    } catch (error) {
      console.log('~ error', error)
    }
  }

  return (
    <Modal
      visible={showMarkingOutComeModal}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="ld-modal">
        <div className="ld-modal__warning">
          {get(currentUser, 'user.partner.name', '')} scored:
        </div>
        <div className="ld-modal__content-wrap">
          <div className="ld-modal__content">
            <div className="ld-modal__number">{wrongAnswer}</div>
            <div className="ld-modal__result">Wrong</div>
          </div>
          <div className="ld-modal__content">
            <div className="ld-modal__number">{correctAnswer}</div>
            <div className="ld-modal__result">Correct</div>
          </div>
        </div>
        {isCompetent ? (
          <div className="quiz__item-status quiz__item-status-c">COMPETENT</div>
        ) : (
          <div className="quiz__item-status quiz__item-status-nc">
            NOT COMPETENT
          </div>
        )}

        <div className="ld-modal__confirm">
          <div className="ld-modal__confirm__left">Confirm this score?</div>
          <div className="ld-modal__confirm__right">
            <BtnPrimary
              name="Submit"
              handleClick={handleOk}
              style={{ fontWeight: 'bold', padding: '12px 20px' }}
              icon={images.header.check}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MarkingOutComeModal
