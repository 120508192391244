import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import config from 'src/global-config'

const ListUser = ({
  item,
  handleSelectUser,
  currentUser,
  id,
  listUserStatus,
  setListUserStatus,
}) => {
  const [userStatus, setUserStatus] = useState(null)
  useEffect(() => {
    const getUserStatus = async (userId) => {
      try {
        const requestUrl = `${config.baseUrl}/api/learning-outcome/channels/users/${userId}`
        const res = await fetch(requestUrl, { method: 'GET' })
        const data = await res.json()

        if (data.data) {
          const status = data.data.find((item) => item.channel_id == id)
          setUserStatus(status)
          if (status) {
            setListUserStatus([...listUserStatus, status])
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    if (item && id) {
      getUserStatus(get(item, 'user.id', ''))
    }
  }, [item, id])

  useEffect(() => {
    if (userStatus) {
      const nextListUserStatus = listUserStatus.filter(
        (item) => item.user_id !== userStatus.user_id
      )
      setListUserStatus([...nextListUserStatus, userStatus])
    }
  }, [userStatus])

  const competent = get(userStatus, 'competent', null)
  return (
    <div
      className={`quiz__item-user ${
        currentUser && currentUser.id === item.id
          ? 'quiz__item-user-active'
          : ''
      }`}
      onClick={() => handleSelectUser(item)}
    >
      <div className="quiz__item-name">
        {get(item, 'user.partner.name', '')}
      </div>
      {competent !== null &&
        (competent === false ? (
          <div className="quiz__item-status quiz__item-status-nc">NC</div>
        ) : (
          <div className="quiz__item-status quiz__item-status-c">C</div>
        ))}
    </div>
  )
}

export default ListUser
