export const LOAD_LIST_COURSE_OF_SELLER = 'LOAD_LIST_COURSE_OF_SELLER'
export const LOAD_LIST_COURSE_OF_SELLER_SUCCESS =
  'LOAD_LIST_COURSE_OF_SELLER_SUCCESS'
export const LOAD_LIST_COURSE_OF_SELLER_FAILED =
  'LOAD_LIST_COURSE_OF_SELLER_FAILED'

export const ADD_COURSE_TO_SELLER = 'ADD_COURSE_TO_SELLER'
export const ADD_COURSE_TO_SELLER_SUCCESS = 'ADD_COURSE_TO_SELLER_SUCCESS'
export const ADD_COURSE_TO_SELLER_FAILED = 'ADD_COURSE_TO_SELLER_FAILED'

export const DELETE_COURSE_OF_SELLER = 'DELETE_COURSE_OF_SELLER'
export const DELETE_COURSE_OF_SELLER_SUCCESS = 'DELETE_COURSE_OF_SELLER_SUCCESS'
export const DELETE_COURSE_OF_SELLER_FAILED = 'DELETE_COURSE_OF_SELLER_FAILED'

export const CREATE_OR_UPDATE_USER = 'CREATE_OR_UPDATE_USER'
export const CREATE_OR_UPDATE_USER_SUCCESS = 'CREATE_OR_UPDATE_USER_SUCCESS'
export const CREATE_OR_UPDATE_USER_FAILED = 'CREATE_OR_UPDATE_USER_FAILED'

export const LOAD_LIST_COLLECTION = 'LOAD_LIST_COLLECTION'
export const LOAD_LIST_COLLECTION_SUCCESS = 'LOAD_LIST_COLLECTION_SUCCESS'
export const LOAD_LIST_COLLECTION_FAILED = 'LOAD_LIST_COLLECTION_FAILED'

export const DELETE_LEAD = 'DELETE_LEAD'
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS'
export const DELETE_LEAD_FAILED = 'DELETE_LEAD_FAILED'

export const LOAD_LIST_COURSE = 'LOAD_LIST_COURSE'
export const LOAD_LIST_COURSE_SUCCESS = 'LOAD_LIST_COURSE_SUCCESS'
export const LOAD_LIST_COURSE_FAILED = 'LOAD_LIST_COURSE_FAILED'

export const COLLECT_CUSTOMER_INTEREST = 'COLLECT_CUSTOMER_INTEREST'
export const COLLECT_CUSTOMER_INTEREST_SUCCESS =
  'COLLECT_CUSTOMER_INTEREST_SUCCESS'
export const COLLECT_CUSTOMER_INTEREST_FAILED =
  'COLLECT_CUSTOMER_INTEREST_FAILED'
