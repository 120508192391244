import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

const ChoiceItemWrapper = styled.div``

const Item = styled.div`
  width: 100%;
  background: ${(props) => (props.correct ? '#A8A8C7' : '#fff')};
  border: 1px solid #e7ebf4;
  border-radius: 4px;
  padding-left: 40px;
  padding-right: 15px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
  display: flex;
  align-items: baseline;
  .order {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-right: 10px;
    color: ${(props) => (props.correct ? '#ff0d6b' : '#6f84a0')};
  }

  .choice_input {
    width: 100%;
    padding: 12px 0;
    border: none;
    outline: none;
    background: none;
    color: #5a6686;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    cursor: pointer;
    color: ${(props) => (props.correct ? '#39041E' : '#00122a')};
    &:hover {
      color: #39041e;
    }
  }
  @media (hover: hover) {
    &:hover {
      background-color: #a8a8c7;
      transform: ${(props) => (props.correct ? 'unset' : 'scale(1.03)')};
      color: #39041e;
    }
  }

  &:active {
    background-color: #a8a8c7;
    box-shadow: 0px 4px 8px rgba(darken(dodgerblue, 30%));
    transform: scale(0.98);
  }
  &:hover .order {
    color: #ff0d6b;
  }
`

const OptionStatus = styled.div`
  .y_wrap {
    background: #35d15c;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .x_wrap {
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

const ChoiceItemStudent = ({
  choice,
  index,
  chooseOptions,
  handlePickOption,
  chooseCorrectOptions,
  correctAnswers,
  hideAnswer,
  isCorrectAnswer,
}) => {
  const isActive =
    chooseOptions && chooseOptions.find((item) => item.order === choice.order)
  const optionStatus =
    correctAnswers && correctAnswers.find((item) => item.order === choice.order)

  const handleClick = () => {}
  if (!choice.text) {
    return null
  }

  return (
    <ChoiceItemWrapper>
      <Row>
        <Col sm={24} xs={24}>
          <Item correct={isActive} onClick={() => handlePickOption(choice)}>
            <span className="order">{alphabet[index]}.</span>
            <div
              placeholder="Type a choice"
              className="choice_input"
              onClick={handleClick}
            >
              {choice.text}
            </div>
            {!hideAnswer &&
              !isCorrectAnswer &&
              chooseCorrectOptions !== null && (
                <OptionStatus>
                  {optionStatus && optionStatus.is_correct ? (
                    <div className="y_wrap">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.7168 8.82812C4.93164 9.04297 5.29688 9.04297 5.51172 8.82812L11.8281 2.51172C12.043 2.29688 12.043 1.93164 11.8281 1.7168L11.0547 0.943359C10.8398 0.728516 10.4961 0.728516 10.2812 0.943359L5.125 6.09961L2.69727 3.69336C2.48242 3.47852 2.13867 3.47852 1.92383 3.69336L1.15039 4.4668C0.935547 4.68164 0.935547 5.04688 1.15039 5.26172L4.7168 8.82812Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className="x_wrap">
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.80469 5.75L9.53906 3.01562C9.89453 2.6875 9.89453 2.14062 9.53906 1.8125L8.9375 1.21094C8.60938 0.855469 8.0625 0.855469 7.73438 1.21094L5 3.94531L2.23828 1.21094C1.91016 0.855469 1.36328 0.855469 1.03516 1.21094L0.433594 1.8125C0.078125 2.14062 0.078125 2.6875 0.433594 3.01562L3.16797 5.75L0.433594 8.51172C0.078125 8.83984 0.078125 9.38672 0.433594 9.71484L1.03516 10.3164C1.36328 10.6719 1.91016 10.6719 2.23828 10.3164L5 7.58203L7.73438 10.3164C8.0625 10.6719 8.60938 10.6719 8.9375 10.3164L9.53906 9.71484C9.89453 9.38672 9.89453 8.83984 9.53906 8.51172L6.80469 5.75Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  )}
                </OptionStatus>
              )}
          </Item>
        </Col>
      </Row>
    </ChoiceItemWrapper>
  )
}

export default ChoiceItemStudent
