import React, { useEffect, useState, useMemo } from 'react'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import Dropdown from 'react-dropdown'
import { get } from 'lodash'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { Row, Col, Modal, AutoComplete, Checkbox } from 'antd'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import images from 'Themes/Images'
import Input from 'component/Input'
import BtnPrimary from 'component/BtnPrimary'
import InputTypePassword from 'component/InputTypePassword'
import UserCourseSelling from 'src/customs/SuperAdmin/components/UserCourseSelling'
import { userType } from 'utils/helper'
import { ERROR_MESSAGE } from 'utils/constants'
import { hideFormUserModal } from '../../../actions'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { mapListCourseToSearch, checkInput } from 'utils/helper'
import { showError } from 'utils/notification'
import { loadListCourse } from 'container/Home/actions'
import { makeSelectListCourseOfSeller } from 'src/customs/SuperAdmin/container/AdminActor/selectors'
import {
  makeSelectListCourse,
  makeSelectListDomain
} from 'container/Home/selectors'
import config from 'src/global-config'
import { getAccessToken } from 'utils/request'
import { getUserData } from 'container/Admin/actions'
import {
  loadListCourseOfSeller,
  addCourseToSeller,
  createOrUpdateUser
} from 'src/customs/SuperAdmin/container/AdminActor/actions'
import { loadListUser } from 'container/Admin/actions'
import { parseSafe } from 'utils/helper'

const UserSchema = Yup.object().shape({
  create_name: Yup.string().required(ERROR_MESSAGE),
  create: Yup.string().email().required(ERROR_MESSAGE),
  mobile: Yup.string().required(ERROR_MESSAGE)
})

const INITIAL_USER_TYPE = [
  { value: Number(process.env.REACT_APP_SALES_GROUP_ID), label: 'Sales Agent' },
  { value: 3, label: 'Super Admin' }
]

const FormUserModal = ({ showFormUserModal, dispatch, user }) => {
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [textSearch, setTextSearch] = useState('')
  const listCourses = useSelector(makeSelectListCourse())
  const listCourseOfSeller = useSelector(makeSelectListCourseOfSeller())
  const currentUser = useSelector(makeSelectCurrentUser())
  const listDomain = useSelector(makeSelectListDomain())
  const { search } = useLocation()
  const { page } = parse(search)

  useEffect(() => {
    if (Array.isArray(listCourses) && listCourses.length === 0) {
      dispatch(loadListCourse({ superAdmin: true }))
    }
  }, [listCourses])

  const formik = useFormik({
    initialValues: {
      create_name: '',
      mobile: '',
      create: '',
      role: null,
      sales: null,
      admin: null,
      password: '',
      company: ''
    },
    validationSchema: UserSchema,
    onSubmit: (values) => {
      if (!user) {
        if (!values.role) {
          return showError('You need to select at least one role')
        }
      }
      const params = {
        name: values.create_name,
        login: values.create,
        password: values.password,
        mobile: values.mobile,
        role: values.role?.value,
        additional_info: JSON.stringify({ company: values.company })
      }

      if (user) {
        params.role = generateRole(values)
      }

      if (!params.password) {
        delete params.password
      }

      if (!user) {
        dispatch(createOrUpdateUser(params))
      } else {
        dispatch(createOrUpdateUser({ ...params, id: get(user, 'id') }))
      }
    }
  })

  const generateRole = (values) => {
    if (values.sales.value && !values.admin.value) {
      return [Number(process.env.REACT_APP_SALES_GROUP_ID)]
    }
    if (!values.sales.value && values.admin.value) {
      return [3]
    }
    return [
      values.sales?.value ? Number(process.env.REACT_APP_SALES_GROUP_ID) : '',
      values.admin?.value ? 3 : ''
    ]
  }

  useEffect(() => {
    if (user) {
      dispatch(loadListCourseOfSeller({ userId: get(user, 'id', '') }))
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const additionalData = parseSafe(get(user, 'additional_info', ''))
      const company = get(additionalData, 'company', '')
      formik.setFieldValue('create_name', user.partner.name)
      formik.setFieldValue('mobile', user.partner.mobile)
      formik.setFieldValue('create', user.login)
      formik.setFieldValue('company', company)
      const groups = get(user, 'user_groups', [])
      const adminGid = userType(3, groups)
      const saleGid = userType(
        Number(process.env.REACT_APP_SALES_GROUP_ID),
        groups
      )
      if (saleGid) {
        formik.setFieldValue('sales', {
          value: Number(process.env.REACT_APP_SALES_GROUP_ID),
          label: 'Sales Agent'
        })
      } else {
        formik.setFieldValue('sales', { value: 0 })
      }

      if (adminGid) {
        formik.setFieldValue('admin', { value: 3 })
      } else {
        formik.setFieldValue('admin', { value: 0 })
      }
    } else {
      handleResetField()
    }
  }, [user])

  const handleResetField = () => {
    formik.resetForm({})
  }

  const handleCancel = () => {
    if (user) {
      handleResetField()
    }
    dispatch(hideFormUserModal())
  }

  const onSelect = (data) => {
    formik.setFieldValue('role', data)
  }

  const onSelectCourse = (value, option) => {
    setSelectedCourse(option.data)
  }

  const handleAddCourse = () => {
    if (!selectedCourse) {
      return
    }
    const params = {
      channelId: get(selectedCourse, 'id'),
      userId: get(user, 'id'),
      create_uid: get(currentUser, 'uid')
    }

    dispatch(addCourseToSeller(params))
    setSelectedCourse(null)
    setTextSearch('')
  }

  const handleCheckSalesAgent = async ({ target }) => {
    await updateSales(target.checked, user?.id)
  }

  const handleCheckAdmin = async ({ target }) => {
    await updateUserAdmin(target.checked, user?.id)
  }

  const updateUserAdmin = async (checked, userId) => {
    try {
      const res = await fetch(`${config.baseUrl}/api/users/${userId}/group`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify({ group_id: generateGroupAdmin(checked) })
      })

      await res.json()

      dispatch(getUserData(userId, true, true))
      dispatch(loadListUser({ current: page, superAdmin: true }))
    } catch (error) {
      console.log('err', error)
    }
  }
  const generateGroupAdmin = (checked) => {
    if (checked) {
      if (formik.values.sales?.value) {
        return [formik.values.sales?.value, 3]
      }
      return [3]
    } else {
      if (formik.values.sales?.value) {
        return [formik.values.sales?.value]
      }
      return [8]
    }
  }

  const updateSales = async (checked, userId) => {
    try {
      const res = await fetch(`${config.baseUrl}/api/users/${userId}/group`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify({
          group_id: generateGroupSales(checked)
        })
      })

      await res.json()
      dispatch(getUserData(userId, true, true))
      dispatch(loadListUser({ current: page, superAdmin: true }))
    } catch (error) {
      console.log('err', error)
    }
  }

  const generateGroupSales = (checked) => {
    if (checked) {
      if (formik.values.admin?.value) {
        return [
          formik.values.admin?.value,
          Number(process.env.REACT_APP_SALES_GROUP_ID)
        ]
      }
      return [Number(process.env.REACT_APP_SALES_GROUP_ID)]
    } else {
      if (formik.values.admin?.value) {
        return [formik.values.admin?.value]
      }
      return [8]
    }
  }

  return (
    <Modal
      visible={showFormUserModal}
      footer={null}
      onCancel={handleCancel}
      className="sc-modal sc-modal-user"
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div
          className="sc-modal__header"
          style={{ backgroundColor: '#f9f9fb' }}
        >
          <div className="sc-modal__heading">
            {user ? 'User' : 'Add new user'}
          </div>
        </div>

        <div className="sc-modal__main">
          <Row>
            <Col xs={24} sm={10} style={{ marginRight: 40 }}>
              <Input
                noScroll
                label="NAME"
                value={formik.values.create_name}
                onChange={formik.handleChange}
                name="create_name"
                autoComplete="off"
                placeHolder="Enter username"
                formik={formik}
              />
            </Col>
            <Col xs={24} sm={10}>
              <Input
                noScroll
                label="MOBILE"
                value={formik.values.mobile}
                onChange={(e) => {
                  if (checkInput(e)) {
                    formik.setFieldValue('mobile', e.currentTarget.value)
                  }
                }}
                name="mobile"
                autoComplete="off"
                placeHolder="Enter mobile"
                formik={formik}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={10} style={{ marginRight: 40 }}>
              <Input
                noScroll
                label="EMAIL"
                value={formik.values.create}
                onChange={formik.handleChange}
                name="create"
                autoComplete="off"
                placeHolder="Enter email"
                formik={formik}
              />
            </Col>

            <Col xs={24} sm={10}>
              <div className="field__label">ROLE(S)</div>
              {user ? (
                <>
                  <div style={{ marginBottom: 20 }}>
                    <Checkbox
                      onChange={handleCheckAdmin}
                      checked={formik.values.admin?.value === 3 ? true : false}
                    >
                      Admin
                    </Checkbox>
                  </div>

                  <div>
                    <Checkbox
                      onChange={handleCheckSalesAgent}
                      checked={
                        formik.values.sales?.value ===
                        Number(process.env.REACT_APP_SALES_GROUP_ID)
                      }
                    >
                      Sales Agent
                    </Checkbox>
                  </div>
                </>
              ) : (
                <Dropdown
                  options={INITIAL_USER_TYPE}
                  onChange={onSelect}
                  value={formik.values.role}
                  placeholder="All Roles"
                  className="dropdownScheduleModal dropdown"
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={10} style={{ marginRight: 40 }}>
              <Input
                noScroll
                label="Company"
                value={formik.values.company}
                onChange={formik.handleChange}
                name="company"
                placeHolder="Enter Company"
                formik={formik}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={10} style={{ marginRight: 40 }}>
              <div className="field__label">Password</div>
              <InputTypePassword
                placeholder="Enter password"
                onChange={formik.handleChange}
                name="password"
                className="form__input form__admin__input"
                value={formik.values.password}
                superAdminClass="eye"
                formik={formik}
                autoComplete="new-password"
                error={formik.touched.password && formik.errors.password}
              />
              {formik && formik.touched.password && formik.errors.password && (
                <div className="form__error">{formik.errors.password}</div>
              )}
            </Col>
            <Col sm={10}></Col>
          </Row>

          {user && (
            <Row style={{ marginBottom: 30, marginTop: 24 }}>
              <Col>
                <div className="added_on">Added on</div>
              </Col>
              <Col>
                <div className="added_on_date">
                  {dayjs(user.write_date).format('DD/MM/YYYY')}
                </div>
              </Col>
            </Row>
          )}

          {user &&
            formik.values.sales &&
            formik.values.sales.value ===
              Number(process.env.REACT_APP_SALES_GROUP_ID) && (
              <>
                <h2 className="sc-modal__heading">Courses to sell</h2>
                <Row>
                  <Col xs={16} sm={24}>
                    {Array.isArray(listCourses) &&
                      listCourses.length !== 0 &&
                      listCourseOfSeller && (
                        <AutoComplete
                          className="search_course"
                          options={mapListCourseToSearch(
                            listCourses,
                            listCourseOfSeller
                          )}
                          placeholder="Search a course name"
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          value={textSearch}
                          onChange={(value) => setTextSearch(value)}
                          onSelect={onSelectCourse}
                        />
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={16} sm={20} className="mgT-10">
                    <BtnPrimary
                      name="Add course"
                      style={{
                        padding: '12px 16px',
                        transition: 'all 0.2s',
                        backgroundColor: '#E7EBF4',
                        fontWeight: 'bold',
                        color: '#6F84A0',
                        marginBottom: 2
                      }}
                      iconLeft={images.admin.plus}
                      type="button"
                      handleClick={handleAddCourse}
                    />
                  </Col>
                </Row>
                <div className="field__label">SELLING</div>
                {listCourseOfSeller && listCourses && (
                  <UserCourseSelling
                    listDomain={listDomain}
                    dispatch={dispatch}
                    user={user}
                    listCourseOfSeller={listCourseOfSeller}
                    listCourses={listCourses}
                  />
                )}
              </>
            )}
        </div>

        <div
          className="sc-modal__footer"
          style={{
            justifyContent: 'flex-end',
            backgroundColor: '#f9f9fb',
            paddingRight: 53
          }}
        >
          <BtnPrimary
            name="Cancel"
            style={{
              padding: 12,
              transition: 'all 0.2s',
              marginRight: 20,
              backgroundColor: '#E7EBF4',
              fontWeight: 'bold',
              color: '#6F84A0'
            }}
            iconLeft={images.admin.x_inactive}
            type="button"
            handleClick={handleCancel}
          />
          <BtnPrimary
            name={`${user ? 'Save changes' : 'Add user'}`}
            style={{
              padding: '12px 40px',
              fontWeight: 'bold',
              transition: 'all 0.2s'
            }}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  )
}

export default FormUserModal
